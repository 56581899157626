import { useEffect } from 'react';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';

export const useGetSeasonalData = ({
    simulationData,
    packagingType,
    setProfileData,
    packagingTypesLoading,
}) => {
    const {
        FlexibleRequest: getTemperatureSimulationRequest,
    } = useSecureBackendEndpoints('').requests;

    useEffect(() => {
        if (!simulationData || !packagingType || packagingTypesLoading) return;

        (async () => {
            const summerResult = await getTemperatureSimulationRequest(
                'POST',
                `/packaging-types/${packagingType.type}/temperature-simulation`,
                simulationData.summer,
            );
            const winterResult = await getTemperatureSimulationRequest(
                'POST',
                `/packaging-types/${packagingType.type}/temperature-simulation`,
                simulationData.winter,
            );

            setProfileData((prev) => ({
                ...prev,
                [packagingType.type]: {
                    summer: summerResult?.data || null,
                    winter: winterResult?.data || null,
                },
            }));
        })();
    }, [simulationData, packagingType, packagingTypesLoading]);
    return {

    };
};
