import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    title: {
        textAlign: 'right',
        font: 'normal normal 300 56px/67px Roboto',
        letterSpacing: '-0.49px',
        color: '#000000DE',
        opacity: 1,
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    picture: {
        paddingTop: '30px',
        width: '65%',
        background: 'transparent 0% 0% no-repeat padding-box',
        opacity: 1,
    },
    text: {
        textAlign: 'right',
        width: '50%',
        float: 'right',
        font: 'normal normal 300 36px/43px Roboto',
        letterSpacing: '-0.32px',
        color: '#000000DE',
        opacity: 1,
    },
});

export default useStyles;
