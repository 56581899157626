/* eslint-disable no-unused-vars */
import React from 'react';
import icons from 'shared-components/icons';

type Props = {
    containerStatusIcon: string,
    palletStatus1Icon: string,
    palletStatus2Icon: string,
    sealStatusIcon: string,
}

const ShipmentEvidenceCheckTableIcons = ({
    containerStatusIcon,
    palletStatus1Icon,
    palletStatus2Icon,
    sealStatusIcon,
} : Props) => {
    return (
        <div style={{ display: 'flex' }}>
            {
                containerStatusIcon && (
                    <div style={{ display: 'flex', marginRight: '28px' }}>
                        <img
                            alt="Container Status"
                            src={containerStatusIcon}
                            style={{ width: '22px', marginRight: '6px' }}
                        />
                        <img
                            alt="Container"
                            src={icons.container_grey}
                            style={{ width: '25px' }}
                        />
                    </div>
                )
            }
            {
                palletStatus1Icon && (
                    <div style={{ display: 'flex', marginRight: '23px' }}>
                        <img
                            alt="Pallet1 Status"
                            src={palletStatus1Icon}
                            style={{ width: '22px', marginRight: '6px' }}
                        />
                        <img
                            alt="Pallet1"
                            src={icons.pallet}
                            style={{ height: '27px', width: '35px' }}
                        />
                    </div>
                )
            }
            {
                palletStatus2Icon && (
                    <div style={{ display: 'flex', marginRight: '23px' }}>
                        <img
                            alt="Pallet2 Status"
                            src={palletStatus2Icon}
                            style={{ width: '22px', marginRight: '6px' }}
                        />
                        <img
                            alt="Pallet2"
                            src={icons.pallet}
                            style={{ height: '27px', width: '35px' }}
                        />
                    </div>
                )
            }
            {
                sealStatusIcon && (
                    <div style={{ display: 'flex', marginRight: '28px' }}>
                        <img
                            alt="Container Status"
                            src={sealStatusIcon}
                            style={{ width: '22px', marginRight: '6px' }}
                        />
                        <img
                            alt="Container"
                            src={icons.prize}
                        />
                    </div>
                )
            }
        </div>
    );
};

export default ShipmentEvidenceCheckTableIcons;
