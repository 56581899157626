import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import LyingSince from 'shared-components/LyingSince/LyingSince';
import LastConnect from 'shared-components/LastConnect';
import { ExtractedAssetData } from 'TrackAndTrace/Assets/lib';

const tableColumns: (t) => ColumnsType<ExtractedAssetData>[] = (t = w => w) => [
    {
        Header: t('TRACK_AND_TRACE.ASSET'),
        accessor: 'assetNumber',
        dataType: 'highlightedText',
    },
    {
        Header: t('TRACK_AND_TRACE.ASSET_TYPE'),
        accessor: ({ assetTypeCode }) => (assetTypeCode ? t(`ASSET_TYPE_LABEL.${assetTypeCode}`) : ''),
        id: 'assetTypeCode',
        dataType: 'text',
    },
    {
        Header: t('LANE_MANAGEMENT.ADDRESS.AIRPORT'),
        accessor: 'iataCode',
        dataType: 'text',
    },
    {
        Header: 'Airport Name',
        accessor: 'locationName',
        id: 'locationName',
        dataType: 'text',
    },
    {
        Header: t('TRACK_AND_TRACE.AREA'),
        accessor: 'locationRemark',
        id: 'area',
        dataType: 'text',
    },
    {
        Header: t('TRACK_AND_TRACE.AIRLINE'),
        accessor: 'airlineCode',
        id: 'airlineCode',
        dataType: 'text',
    },
    {
        Header: t('TRACK_AND_TRACE.LYING_TIME'),
        accessor: (row) => (!row.lyingSinceTimestamp ? ' '
            : <LyingSince timestamp={row.lyingSinceTimestamp} />),
        dataType: 'custom',
        id: 'lyingSinceTimestamp',
        sortType: (rowA, rowB) => rowB.original.lyingSinceTimestamp - rowA.original.lyingSinceTimestamp,
    },
    {
        Header: t('COMMON.TEMPERATURE'),
        accessor: (row) => (row.temperature ? `${Number(row?.temperature?.toFixed(1))}°C` : ''),
        dataType: 'text',
        id: 'temperature',
    },
    {
        Header: t('TRACK_AND_TRACE.LAST_UPDATED'),
        accessor: (row) => (!row.lastMeasuredTimestamp ? ' '
            : <LastConnect timestamp={row.lastMeasuredTimestamp} />),
        dataType: 'custom',
        id: 'temperatureGeolocationTimestamp',
        sortType: (rowA, rowB) => rowB.original.lastMeasuredTimestamp - rowA.original.lastMeasuredTimestamp,
    },
    {
        Header: t('COMMON.LOGGER_NUMBER'),
        accessor: 'loggerNumber',
        dataType: 'highlightedText',
    },
];

export default tableColumns;
