import React, {
    useState,
    useEffect, useMemo,
} from 'react';
import { useParams } from 'react-router-dom';
import {
    ServiceProviderSite,
    ContactInfo as ContactInfoType,
    OperatingHours,
    servicesArr,
    commoditiesArr,
} from 'dataTypes/SecureBackend/apiResponse';
import {
    AddServiceProviderSiteRequestBody,
    Address,
} from 'dataTypes/SecureBackend/processedData';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import useBreadcrumbsContext from 'hooks/useBreadcrumbsContext';
import useCustomTitle from 'hooks/useCustomTitle';

import AddressCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/AddressCard';
// eslint-disable-next-line max-len
import ServiceProviderSiteCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/ServiceProviderSiteCard';
// eslint-disable-next-line max-len
import ContactInformationCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/ContactInformationCard';
import OperatingHoursCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/OperatingHoursCard';
import OfferingsCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/OfferingsCard';
import useHasAccess, { userRoles } from 'hooks/useHasAccess';
import useStyles from '../../ServiceProviderSites.style';

type PathParams = {
    serviceProviderSiteId: string,
}

const EditServiceProviderSite = () => {
    const classes = useStyles();
    const { serviceProviderSiteId } = useParams<PathParams>();
    const { Get: getServiceProviderSite } = useSecureBackendEndpoints('service-provider-sites').requests;
    const hasAccess = useHasAccess();
    const isAdmin = useMemo(() => hasAccess(userRoles.SKYCELL_ADMIN), [hasAccess]);
    const {
        FlexibleRequest: editPharmaSite,
    } = useSecureBackendEndpoints(
        isAdmin ? 'admin/service-provider-sites' : 'service-provider-sites',
    ).statusNotificationRequests;
    const { setBreadcrumbData } = useBreadcrumbsContext();
    const { setTitle } = useCustomTitle();

    const [address, setAddress] = useState<Address>(null);
    const [availableData, setAvailableData] = useState<ServiceProviderSite>(null);
    const [contactInfo, setContactInfo] = useState<ContactInfoType>(null);
    const [currentEditedCard, setCurrentEditedCard] = useState<string>(null);
    const [editRequestBody, setEditRequestBody] = useState<AddServiceProviderSiteRequestBody>(null);
    const [offerings, setOfferings] = useState<string[]>([]);
    const [operatingHours, setOperatingHours] = useState<OperatingHours[]>([]);
    const [serviceProviderSiteInfo, setServiceProviderSiteInfo] = useState(null);
    const [requiredUpdateData, setRequiredUpdateData] = useState(true);

    const handleClickEdit = async () => {
        try {
            const {
                status = null,
            } = await editPharmaSite('PATCH', `/${serviceProviderSiteId}/overview-info`, editRequestBody);

            if (status === 200) {
                setRequiredUpdateData(true);
            }
        } catch (error) {
            global.console.log(error);
        }
    };

    const updateOfferings = async () => {
        try {
            await editPharmaSite('PATCH', `/${serviceProviderSiteId}/offer`, offerings);
        } catch (error) {
            global.console.log(error);
        }
    };

    const updateOperatingHours = async () => {
        try {
            const {
                status = null,
            } = await editPharmaSite('PATCH', `/${serviceProviderSiteId}/operating-hours`, operatingHours);

            if (status === 200) {
                setRequiredUpdateData(true);
            }
        } catch (error) {
            global.console.log(error);
        }
    };

    useEffect(() => {
        if (address && contactInfo && serviceProviderSiteInfo) {
            setEditRequestBody({
                ...serviceProviderSiteInfo,
                address,
                contactInfo,
            });
        }
    }, [
        address,
        contactInfo,
        serviceProviderSiteInfo,
    ]);

    useEffect(() => {
        if (requiredUpdateData) {
            (async () => {
                try {
                    const {
                        data: response = null,
                    } : { data: ServiceProviderSite } = await getServiceProviderSite(serviceProviderSiteId);

                    if (response) {
                        setAvailableData(response);
                        setContactInfo(response.contactInfo);
                        setServiceProviderSiteInfo({
                            name: response.name,
                        });
                        setAddress({
                            addressLine1: response.address.addressLine1,
                            addressLine2: response.address.addressLine2,
                            addressLine3: response.address.addressLine3,
                            city: response.address.city,
                            countryCode: response.address.country.code,
                            geolocation: response.address.geolocation,
                            zipCode: response.address.zipCode,
                        });
                        setOfferings(response?.offers || []);
                        setBreadcrumbData('customBreadcrumb', `${response.serviceProviderName} / ${response.name}`);
                        setTitle(`Ser. Provider Site Details: ${response.name}`);
                    }
                } catch (error) {
                    global.console.log(error);
                } finally {
                    setRequiredUpdateData(false);
                }
            })();
        }
    }, [requiredUpdateData]);

    return (
        availableData
            ? (
                <div className={classes.editCompanyCardContentData}>
                    <ServiceProviderSiteCard
                        currentCompanyLogo={availableData.logoImagePath}
                        currentSiteName={availableData?.name}
                        currentEditedCard={currentEditedCard}
                        type="editable"
                        saveInfo={handleClickEdit}
                        setCurrentEditedCard={setCurrentEditedCard}
                        setFilter={setServiceProviderSiteInfo}
                    />
                    <AddressCard
                        currentAddressLine1={availableData?.address?.addressLine1}
                        currentAddressLine2={availableData?.address?.addressLine2}
                        currentAddressLine3={availableData?.address?.addressLine3}
                        currentCity={availableData?.address?.city}
                        currentZipCode={availableData?.address?.zipCode}
                        currentCountry={availableData?.address?.country.name}
                        currentCountryCode={availableData?.address?.country.code}
                        currentGeolocation={availableData?.address?.geolocation}
                        currentEditedCard={currentEditedCard}
                        type="editable"
                        setFilter={setAddress}
                        saveInfo={handleClickEdit}
                        setCurrentEditedCard={setCurrentEditedCard}
                    />
                    <ContactInformationCard
                        currentContactPerson={availableData?.contactInfo?.contactPerson}
                        currentPhoneNumber={availableData?.contactInfo?.phoneNumber}
                        currentEmail={availableData?.contactInfo?.email}
                        currentEditedCard={currentEditedCard}
                        type="editable"
                        setFilter={setContactInfo}
                        saveInfo={handleClickEdit}
                        setCurrentEditedCard={setCurrentEditedCard}
                    />
                    <OperatingHoursCard
                        currentOperatingHours={availableData?.operatingHours}
                        currentEditedCard={currentEditedCard}
                        type="editable"
                        setData={setOperatingHours}
                        saveInfo={updateOperatingHours}
                        setCurrentEditedCard={setCurrentEditedCard}
                    />
                    <OfferingsCard
                        currentCommodities={offerings.filter(offer => commoditiesArr.includes(offer))}
                        currentServices={offerings.filter(offer => servicesArr.includes(offer))}
                        currentEditedCard={currentEditedCard}
                        type="editable"
                        setOfferings={setOfferings}
                        saveInfo={updateOfferings}
                        setCurrentEditedCard={setCurrentEditedCard}
                    />
                </div>
            )
            : null
    );
};

export default EditServiceProviderSite;
