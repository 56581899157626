import React from 'react';
import {
    TIME_IN_MS,
} from 'shared-components/constants';
import useCustomTranslation from 'hooks/useCustomTranslation';
import moment from 'moment';
import useStyles from './LyingSince.style';

type Props = {
    fontSize?: 'small' | 'medium' | 'large',
    timestamp?: number,
    fullText?: boolean,
}

const LyingSince = ({
    fontSize = 'large',
    timestamp = null,
    fullText = false,
}: Props) => {
    const classes = useStyles();
    const { t } = useCustomTranslation();

    const now = moment().utcOffset(0, false).valueOf();

    const timePassed = Math.abs(moment(now).valueOf() - moment(timestamp).valueOf());

    const days = Math.round(timePassed / TIME_IN_MS.day);
    const dPlural = days > 1;

    return (
        <div className={`${classes.background} ${days < 7 ? classes.backgroundGreen
            : days < 14 ? classes.backgroundBlue : days < 30 ? classes.backgroundYellow : classes.backgroundRed}`}
        >
            <span className={`${classes.statusDot} ${days < 7 ? classes.statusDotBackgroundGreen
                : days < 14 ? classes.statusDotBackgroundBlue
                    : days < 30 ? classes.statusDotBackgroundYellow : classes.statusDotBackgroundRed}`}
            />
            <div className={classes[fontSize]}>
                {
                    fullText
                        ? `${t('SENSOR_DATA.MEASURED')} ${days} ${t(dPlural
                            ? 'SENSOR_DATA.DAYS_AGO' : 'SENSOR_DATA.DAY_AGO')}`
                        : `${days}d`
                }
            </div>
        </div>
    );
};

export default LyingSince;
