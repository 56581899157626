import React from 'react';
import { RouteItem } from 'dataTypes/common';
import { userRoles } from 'hooks/useHasAccess';
import ProductReleaseDetails from 'ProductReleaseDetails';

import ProductRelease from './ProductRelease';

const ProductReleaseRouter: RouteItem[] = [
    {
        necessaryRoles: [userRoles.PRODUCT_RELEASE],
        exact: true,
        path: '/product-release',
        component: <ProductRelease />,
    },
    {
        necessaryRoles: [userRoles.PRODUCT_RELEASE],
        exact: false,
        path: '/product-release/:shipmentNumber/:productReleaseId',
        component: <ProductReleaseDetails />,
    },
];

export default ProductReleaseRouter;
