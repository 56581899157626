import { makeStyles } from '@mui/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

export const useStyles = makeStyles((theme:SkycellThemeInterface) => ({
    pagination: {
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        boxSizing: 'border-box',
        boxShadow: '0px 1px 3px #00000033',
        display: 'flex',
        height: '70px',
        padding: '0 9px',
        marginTop: '20px',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            justifyContent: 'center',
            flexWrap: 'wrap',
            padding: '5px',
            flex: '1',
        },
    },
    muiPagination: {
        margin: '3px 0',
    },
    root: {
        '& .MuiPaginationItem-root': {
            color: '#009CCF',
            border: '1px solid #009CCF',
            borderRadius: '0!important',
            backgroundColor: 'transparent',
            height: '100%',
            width: '20px',
            font: 'normal normal normal 14px/17px Roboto',
            margin: '3px 1.5px',
            padding: '0',
        },
        '& .Mui-selected': {
            backgroundColor: '#009CCF!important',
            color: '#FFFFFF',
        },
    },
    label: {
        marginRight: '19.5px',
        textAlign: 'left',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        color: 'black',
        opacity: 1,
        display: 'inline-flex',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            flex: '100%',
        },
    },
    labelBlue: {
        color: '#009CCF',
        display: 'inline-flex',
        paddingLeft: '4px',
        paddingRight: '4px',
    },
}));
