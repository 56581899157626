import { Asset, Logger } from 'dataTypes/SecureBackend/apiResponse';
import { Shipment } from 'dataTypes/SecureBackend/apiResponse/Shipment';
import useGetCommonData from 'hooks/useGetCommonData';
import useGetTranslationGroup from 'hooks/useGetTranslationGroup';
import { useEffect, useMemo, useState } from 'react';
import { EntitySelectorItem } from 'SensorDataCommon/dataTypes';
import {
    fetchAssets,
    fetchEntitiesFromLoggers,
    // fetchEntitiesFromPackagings,
    fetchEntitiesFromShipments,
    initializeEntityList,
    requiredStatuses,
} from 'SensorDataCommon/lib';

const useLoadSensorData: (
    entitySelectorItem: EntitySelectorItem,
    exclusiveFor?: 'loggers' | 'shipments' | 'assets' | null,
) => {
    isFetchComplete:boolean,
    shipmentList: EntitySelectorItem[],
    // packagingList: EntitySelectorItem[],
    loggerList: EntitySelectorItem[],
    assetList: EntitySelectorItem[],
    shipmentsRequestStatus:string,
    // packagingRequestStatus:string,
    loggerRequestStatus:string,
    assetRequestStatus:string,
} = (entitySelectorItem, exclusiveFor) => {
    const packagingCodeLabels = useGetTranslationGroup('PACKAGING_CODE_LABEL');
    const loggerTypeLabels = useGetTranslationGroup('LOGGER_TYPE_LABEL');
    const assetTypeLabels = useGetTranslationGroup('ASSET_TYPE_LABEL');

    const isExclusive = useMemo(() => {
        return !!exclusiveFor;
    }, [exclusiveFor]);
    const {
        data: rawShipments, status: shipmentsRequestStatus,
    } = useGetCommonData<Shipment[]>('shipment', {
        postProcess: it => it.resultList,
        enabled: !isExclusive || exclusiveFor === 'shipments',
    });
    // const {
    //     data: rawPackagings, status: packagingRequestStatus,
    // } = useGetCommonData<Packaging[]>('packagings');
    const {
        data: rawLoggers, status: loggerRequestStatus,
    } = useGetCommonData<Logger[]>('loggers', {
        postProcess: it => it.resultList,
        enabled: !isExclusive || exclusiveFor === 'loggers',
    });
    const {
        data: rawAssets, status: assetRequestStatus,
    } = useGetCommonData<Asset[]>('assets', {
        postProcess: it => it.resultList,
        enabled: !isExclusive || exclusiveFor === 'assets',
    });

    const [
        shipmentList,
        setShipmentList,
    ] = useState<EntitySelectorItem[]>(initializeEntityList(entitySelectorItem, 'shipment'));
    // const [
    //     packagingList,
    //     setPackagingList,
    // ] = useState<EntitySelectorItem[]>(initializeEntityList(entitySelectorItem, 'packagings'));
    const [
        loggerList,
        setLoggerList,
    ] = useState<EntitySelectorItem[]>(initializeEntityList(entitySelectorItem, 'loggers'));
    const [
        assetList,
        setAssetList,
    ] = useState<EntitySelectorItem[]>(initializeEntityList(entitySelectorItem, 'assets'));

    const isFetchComplete = useMemo(() => requiredStatuses.includes(shipmentsRequestStatus)
            // && requiredStatuses.includes(packagingRequestStatus)
            && requiredStatuses.includes(loggerRequestStatus)
            && requiredStatuses.includes(assetRequestStatus),
    [
        shipmentsRequestStatus,
        // packagingRequestStatus,
        loggerRequestStatus,
        assetRequestStatus,
    ]);

    useEffect(() => {
        if (
            Object.keys(packagingCodeLabels).length
                && shipmentsRequestStatus === 'SUCCESS'
        ) {
            const processedData = fetchEntitiesFromShipments(rawShipments,
                packagingCodeLabels);

            setShipmentList(processedData);
        }
    },
    [
        packagingCodeLabels,
        rawShipments,
        shipmentsRequestStatus,
    ]);

    /*
    useEffect(() => {
        if (Object.keys(packagingCodeLabels).length && packagingRequestStatus === 'SUCCESS') {
            const processedData = fetchEntitiesFromPackagings(rawPackagings, packagingCodeLabels);

            setPackagingList(processedData);
        }
    }, [packagingCodeLabels, rawPackagings, packagingRequestStatus]);
    */

    useEffect(() => {
        if (Object.keys(loggerTypeLabels).length && loggerRequestStatus === 'SUCCESS') {
            const processedLoggerData = fetchEntitiesFromLoggers(rawLoggers, loggerTypeLabels);

            setLoggerList(processedLoggerData);
        }
    }, [loggerTypeLabels, rawLoggers, loggerRequestStatus]);

    useEffect(() => {
        if (Object.keys(assetTypeLabels).length && assetRequestStatus === 'SUCCESS') {
            const processedAssetData = fetchAssets(rawAssets, assetTypeLabels);

            setAssetList(processedAssetData);
        }
    }, [assetTypeLabels, rawAssets, assetRequestStatus]);

    return {
        isFetchComplete,
        shipmentList,
        // packagingList,
        loggerList,
        assetList,
        shipmentsRequestStatus,
        // packagingRequestStatus,
        loggerRequestStatus,
        assetRequestStatus,
    };
};

export default useLoadSensorData;
