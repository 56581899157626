import React, {
    useCallback,
    useEffect, useMemo,
    useState,
} from 'react';
import { RequestStatus } from 'dataTypes/common';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import useGetTranslationGroup from 'hooks/useGetTranslationGroup';
import TitleWithMapTableSwitch from 'shared-components/TitleWithMapTableSwitch';
import Table from 'shared-components/Table';
import { PageWithFilter } from 'Layout';
import useGetViewTypeFromQuery from 'TrackAndTrace/localHooks/useGetViewTypeFromQuery';
import useCustomTranslation from 'hooks/useCustomTranslation';
import MapView from './MapView';
import ShipmentClientSideFilter from './components/ShipmentClientSideFilter';
import {
    AvailableFilterOptions,
    getAvailableFilterOptions,
    initialAvailableFilterOptions,
    initialFilterOptionsCount,
    ClientSideFilter,
    FilterOptionsCount,
    getPackagingsCount,
    GenericShipmentData,
    initialClientSideFilter,
    getShipmentData,
    getFilteredShipmentsByAirports,
    getShipmentStatusCount,
    getFilteredShipmentsByShipmentStatus,
    getFilteredShipmentsByTemperatureStatus,
} from './lib';
import tableColumns from './tableColumns';
import { useStyles } from './GenericShipments.style';
import useHasAccess from '../../hooks/useHasAccess';

const GenericShipments = () => {
    const view = useGetViewTypeFromQuery();
    const { t } = useCustomTranslation();
    const classes = useStyles();
    const hasAccess = useHasAccess();
    const { GetAll: getShipments } = useSecureBackendEndpoints('v2/shipments/search').requests;
    const [highlightedText, setHighlightedText] = useState('');
    const changeHighlightedText = useCallback((newHighlightedText) => {
        setHighlightedText(newHighlightedText);
    }, []);

    const packagingCodeLabels = useGetTranslationGroup('PACKAGING_CODE_LABEL');
    const shipmentStatusLabels = useGetTranslationGroup('SHIPMENT_STATUS');
    // const approvalStatusLabels = useGetTranslationGroup('APPROVAL_STATUS');
    const temperatureStatusLabels = useGetTranslationGroup('TEMPERATURE_STATUS');
    const shipmentStatusDescriptions = useGetTranslationGroup('SHIPMENT_STATUS_TOOLTIP_DESCRIPTION');
    // const approvalStatusDescriptions = useGetTranslationGroup('APPROVAL_STATUS_TOOLTIP_DESCRIPTION');
    const temperatureStatusDescriptions = useGetTranslationGroup('TEMPERATURE_STATUS_TOOLTIP_DESCRIPTION');
    const [
        availableFilterOptions,
        setAvailableFilterOptions,
    ] = useState<AvailableFilterOptions>(initialAvailableFilterOptions);
    const [
        filterOptionsCount,
        setFilterOptionsCount,
    ] = useState<FilterOptionsCount>(initialFilterOptionsCount);
    const [currentPage, setCurrentPage] = useState(1);
    const [error, setError] = useState('');
    const [filters, setFilters] = useState<ClientSideFilter>(initialClientSideFilter);
    const [shipments, setShipments] = useState<GenericShipmentData[]>([]);
    const [filteredShipments, setFilteredShipments] = useState<GenericShipmentData[]>([]);
    const [shipmentsFromPreviewTable, setShipmentsFromPreviewTable] = useState<GenericShipmentData[]>([]);
    const [status, setStatus] = useState<RequestStatus>('INIT');
    const [updateTrigger, updateMap] = useState(0);
    const [showAirportsInfo, setShowAirportsInfo] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setStatus('PENDING');
                const { resultList: rawShipments = [] } = (await getShipments()).data;
                const newShipments = getShipmentData(rawShipments);

                if (JSON.stringify(newShipments) !== JSON.stringify(shipments)) {
                    setShipments(newShipments);
                }
                setStatus('SUCCESS');
            } catch (err) {
                global.console.log(err);
                setError('Something wrong with data loading');
                setStatus('FAILURE');
            }
        })();
    }, [packagingCodeLabels, updateTrigger]);

    const availableFilterOptionsMemo = useMemo(() => {
        return getAvailableFilterOptions(
            shipmentsFromPreviewTable.length > 0 ? shipmentsFromPreviewTable : shipments,
            shipmentStatusLabels,
            temperatureStatusLabels,
            shipmentStatusDescriptions,
            temperatureStatusDescriptions,
        );
    }, [
        shipments,
        shipmentsFromPreviewTable,
        shipmentStatusLabels,
        temperatureStatusLabels,
        shipmentStatusDescriptions,
        temperatureStatusDescriptions,
    ]);

    useEffect(() => {
        setAvailableFilterOptions(availableFilterOptionsMemo);
    }, [availableFilterOptionsMemo]);

    useEffect(() => {
        const preview = shipmentsFromPreviewTable.length;
        const data = preview ? shipmentsFromPreviewTable : shipments;
        const filteredByAirports = getFilteredShipmentsByAirports(data, filters);
        const filteredByShipmentStatuses = getFilteredShipmentsByShipmentStatus(filteredByAirports, filters);
        const filteredByTempStatuses = getFilteredShipmentsByTemperatureStatus(filteredByShipmentStatuses, filters);

        const newFilterOptionsCount = {
            shipmentStatusCount: getShipmentStatusCount(filteredByAirports),
            temperatureStatusCount: getPackagingsCount(filteredByShipmentStatuses),
        };

        if (JSON.stringify(filteredByTempStatuses) !== JSON.stringify(filteredShipments)) {
            setFilteredShipments(filteredByTempStatuses);
        }
        if (JSON.stringify(newFilterOptionsCount) !== JSON.stringify(filterOptionsCount)) {
            setFilterOptionsCount(newFilterOptionsCount);
        }
        setCurrentPage(1);
    }, [filters, shipments, shipmentsFromPreviewTable]);

    const minMaxTempArray = useMemo<[number, number]>(() => {
        const allTemps = filteredShipments.map(({ packagings }) => packagings.map((p) => [
            p?.temperatureCheckResult?.temperatureMax,
            p?.temperatureCheckResult?.temperatureMin,
        ]))
            .flat().flat()
            .filter(it => it)
            .sort((a, b) => a - b);

        return [allTemps[0], allTemps[allTemps.length - 1]];
    }, [filteredShipments]);

    const columns = useMemo(() => {
        return hasAccess('INTELLIGENT_MONITORING') ? tableColumns(t, minMaxTempArray)
            : tableColumns(t, minMaxTempArray)
                .filter(item => item.Header !== t('COMMON.ENERGY'));
    }, [minMaxTempArray, filteredShipments]);

    return (
        <PageWithFilter>
            <ShipmentClientSideFilter
                availableFilterOptions={availableFilterOptions}
                filterOptionsCount={filterOptionsCount}
                isTableView={view === 'table'}
                setCurrentPage={setCurrentPage}
                setFilters={setFilters}
                setShipmentsFromPreviewTable={setShipmentsFromPreviewTable}
                setShowAirportsInfo={setShowAirportsInfo}
                shipments={filteredShipments}
                changeHighlightedText={changeHighlightedText}
                shipmentsFromPreviewTable={shipmentsFromPreviewTable}
            />
            <div style={{ display: 'block', width: '100%', overflow: 'auto' }}>
                <TitleWithMapTableSwitch
                    isTableView={view === 'table'}
                    pathname="/track-and-trace/generic-shipments"
                />
                {
                    view === 'table'
                        ? (
                            <Table
                                columns={columns}
                                data={filteredShipments}
                                title={t('MENU_ITEMS.TRACK_AND_TRACE_SHIPMENTS')}
                                rowLinkTemplate="/track-and-trace/generic-shipments/:id"
                                maskForHighlight={highlightedText}
                                classNames={{
                                    tableContainerClassName: classes.filteredTable,
                                }}
                            />
                        )
                        : (
                            <MapView
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                error={error}
                                updateMap={updateMap}
                                showAirportsInfo={showAirportsInfo}
                                shipments={filteredShipments}
                                status={status}
                            />
                        )
                }
            </div>
        </PageWithFilter>
    );
};

export default GenericShipments;
