import React, { MouseEventHandler, ReactNode } from 'react';
import {
    Card as MaterialCard,
    CardContent,
    Typography,
    Icon,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import useStyles from './Card.style';

export type Props = {
    /**
     * Components/content, included to card
     */
    children?: ReactNode,
    /**
     * The title of card
     */
    title?: string,
    icon?: string,
    /**
     * Additional className for add specified style
     */
    className?: string,
    /**
     * Additional className for content
     */
    contentClass?: string,
    /**
     * Additional className for the title
     */
    titleClass?: string,
    /**
     * Full height or dependent on content?
     */
    fullHeight?: boolean,
    /**
     * Additional style object, passed to component
     */
    style?: any,
    /**
    * Optional scrool handler
    */
    onScroll?: any,
    /**
    * Optional click handler
    */
   onClick?: MouseEventHandler<HTMLDivElement>,
   /**
   * Optionally disables side padding (used for tables)
   */
    zeroSidePadding?: boolean,
   /**
   * Optionally disables vertical padding (used for tables)
   */
   zeroVerticalPadding?: boolean,
    /**
     * Disables interaction with the card
     */
   disabled?: boolean,
    /**
     * Highlights the card
     */
   highlight?: boolean,
}

/**
 * Cards are surfaces that display content and actions on a single topic.
 */
const Card = ({
    children,
    title = '',
    icon = '',
    className = '',
    contentClass = '',
    titleClass = '',
    style = null,
    fullHeight = false,
    onScroll = null,
    onClick = null,
    zeroSidePadding = false,
    zeroVerticalPadding = false,
    disabled = false,
    highlight = false,
    ...rest
} : Props) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <MaterialCard
            className={
                [
                    classes.card,
                    className,
                    fullHeight ? classes.fullHeight : '',
                    onClick ? classes.hasHover : '',
                    disabled ? classes.disabled : '',
                    highlight ? classes.highlighted : '',
                ].join(' ')
            }
            onClick={onClick}
            onScroll={onScroll}
            style={style}
            {...rest}
        >
            <CardContent className={[
                contentClass,
                zeroSidePadding ? classes.zeroSidePadding : '',
                zeroVerticalPadding ? classes.zeroVerticalPadding : '',
            ].join(' ')}
            >
                {
                    title && (
                        <Typography className={[
                            classes.title,
                            titleClass,
                            zeroSidePadding ? classes.cardTitleWithZeroSidePadding : '',
                        ].join(' ')}
                        >
                            {title}
                            { icon && <Icon className={classes.icon}>{icon}</Icon> }
                        </Typography>
                    )
                }
                {children}
            </CardContent>
        </MaterialCard>
    );
};

export default Card;
