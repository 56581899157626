import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    addCompanyPageContent: {
        display: 'grid',
        gridTemplateColumns: '4fr 2fr',
        padding: '12px',
        gridGap: '12px',
        margin: 'auto',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    editCompanyPageContent: {
        display: 'grid',
        gridTemplateColumns: '4fr 2fr',
        padding: '12px',
        gridGap: '12px',
    },
    addEditCompanyCardContentData: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr 1fr',
        gridGap: '12px',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    editCompanyCardContentData: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr 1fr 1fr 1fr',
        gridGap: '12px',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    addEditCompanyFieldTitle: {
        marginTop: '10px',
        marginBottom: '5px',
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: '#939393',
        opacity: 1,
    },
    addEditCompanyTextField: {
        marginTop: '12px',
        width: '100%',
    },
    addEditCompanyTextFieldText: {
        paddingRight: '0!important',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    addEditCompanyMandatoryField: {
        background: '#E0F7FF 0% 0% no-repeat padding-box',
        borderRadius: '3px',
        borderLeft: '6px solid #8BD1E8',
        opacity: 1,
    },
    addEditCompanyButtonContainer: {
        display: 'flex',
        marginTop: '28px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    addEditCompanyApproveButton: {
        width: '90px',
        height: '36px',
        margin: '0 7px',
        textTransform: 'uppercase',
    },
    addEditCompanyRejectButton: {
        width: '90px',
        height: '36px',
        margin: '0 7px',
        textTransform: 'uppercase',
    },
    totalElements: {
        marginBottom: '5px',
        textAlign: 'left',
        font: 'normal normal normal 16px/21px Roboto',
        letterSpacing: '0.49px',
        color: '#747474',
        opacity: 1,
    },
}));

export default useStyles;
