import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    addCompanyPageContent: {
        display: 'grid',
        gridTemplateColumns: '4fr 2fr',
        gridGap: '12px',
        padding: '12px',
        margin: 'auto',
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr',
        },
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr',
        },
    },
    editCompanyPageContent: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        padding: '12px',
        gridGap: '12px',
    },
    addEditCompanyCardContentData: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr 1fr',
        gridGap: '12px',
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr 1fr',
        },
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr',
        },
    },
    addEditCompanyFieldTitle: {
        marginTop: '10px',
        marginBottom: '5px',
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: '#939393',
        opacity: 1,
    },
    addEditCompanyTextField: {
        marginTop: '12px',
        width: '100%',
    },
    addEditCompanyButtonContainer: {
        display: 'flex',
        marginTop: '28px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    addEditCompanyApproveButton: {
        width: '90px',
        height: '36px',
        margin: '0 7px',
        textTransform: 'uppercase',
    },
    addEditCompanyRejectButton: {
        width: '90px',
        height: '36px',
        margin: '0 7px',
        textTransform: 'uppercase',
    },
    paddedTable: {
        padding: '0 15px 15px 15px',
    },
    cell: {
        padding: '15px',
    },
    hcell: {
        padding: '0 0 0 15px',
    },
}));

export default useStyles;
