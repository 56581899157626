import React, {
    createContext,
    useContext,
    useState,
    ReactChild,
} from 'react';
import { Company } from 'dataTypes/SecureBackend/apiResponse';
import { AuthContext, UserInfo, initialUserInfo } from 'Contexts/AuthContext';

const companyInitialState: Company = {
    address: {
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        country: {
            code: '',
            name: '',
        },
        geolocation: {
            latitude: null,
            longitude: null,
        },
        zipCode: '',
    },
    companyType: null,
    companyRoles: [],
    energyLevelThreshold: null,
    contactInfo: {
        contactPerson: '',
        email: '',
        phoneNumber: '',
    },
    id: null,
    logoImagePath: '',
    name: 'No company info',
    website: '',
    serviceProviderCompanies: [],
};

export interface CurrentUserContextInterface {
    company: Company,
    companyInitialState: Company,
    logout: () => void,
    setCompany: (company: Company) => void,
    userInfo: UserInfo,
}

export const CurrentUserContext = createContext<CurrentUserContextInterface>({
    company: companyInitialState,
    companyInitialState,
    logout: () => {},
    setCompany: null,
    userInfo: initialUserInfo,
});

type Props = {
    children: ReactChild,
}

const CurrentUserProvider = ({ children }: Props) => {
    const { userInfo, logout } = useContext(AuthContext);
    const [company, setCompany] = useState<Company>(companyInitialState);

    return (
        <CurrentUserContext.Provider
            value={{
                company,
                companyInitialState,
                logout,
                setCompany,
                userInfo,
            }}
        >
            { children }
        </CurrentUserContext.Provider>
    );
};

export default CurrentUserProvider;
