import React, { ReactElement } from 'react';
import Tooltip from '@mui/material/Tooltip';

import CustomTitle from './CustomTitle';

const borderColors = {
    blue: '#C3E9F7',
    red: '#D44848',
};

type Props = {
    borderColor?: 'blue' | 'red',
    className?: string,
    children?: ReactElement,
    description: string | ReactElement,
    descriptionTitle?: string,
    iconSrc?: string,
    open?: boolean,
    placement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top',
}

const ControlledTooltip = ({
    borderColor = 'blue',
    className = '',
    children = null,
    description,
    descriptionTitle = null,
    iconSrc = null,
    open = false,
    placement = 'bottom-start',
} : Props) => {
    return (
        <Tooltip
            classes={{
                tooltip: className,
            }}
            disableFocusListener
            disableTouchListener
            placement={placement}
            open={open}
            componentsProps={{
                tooltip: {
                    sx: {
                        borderTop: '10px solid transparent',
                        borderBottom: '10px solid transparent',
                        borderColor: borderColors[borderColor],
                        width: '288px',
                        background: '#F7F7F7 0% 0% no-repeat padding-box',
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: '10px',
                        opacity: 1,
                    },
                },
            }}
            title={(
                <CustomTitle
                    iconSrc={iconSrc}
                    description={description}
                    descriptionTitle={descriptionTitle}
                />
            )}
        >
            { children }
        </Tooltip>
    );
};

export default ControlledTooltip;
