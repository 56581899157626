import React from 'react';
import { RouteItem } from 'dataTypes/common';
import { userRoles } from 'hooks/useHasAccess';
import LoggerSensorDataContainer from './LoggerSensorDataContainer';
import AssetSensorDataContainer from './AssetSensorDataContainer';
import AssetPairings from './AssetPairings';

const SensorDataRouter: RouteItem[] = [
    {
        sufficientRoles: [
            userRoles.LOGGER_MANAGEMENT,
            userRoles.ASSET_MANAGEMENT,
        ],
        exact: true,
        path: [
            '/track-and-trace/loggers/:entityNumber',
        ],
        component: <LoggerSensorDataContainer />,
    },
    {
        sufficientRoles: [
            userRoles.LOGGER_MANAGEMENT,
            userRoles.ASSET_MANAGEMENT,
        ],
        exact: true,
        path: [
            '/asset-management/loggers/:entityNumber',
        ],
        component: <LoggerSensorDataContainer core="asset-management" />,
    },
    {
        necessaryRoles: [
            userRoles.ASSET_MANAGEMENT,
        ],
        exact: true,
        path: [
            '/asset-management/assets/readout/',
            '/asset-management/assets/readout/:entityNumber',
        ],
        component: <AssetSensorDataContainer />,
    },
    {
        necessaryRoles: [
            userRoles.ASSET_MANAGEMENT,
        ],
        exact: true,
        path: [
            '/asset-management/assets/pairings/',
            '/asset-management/assets/pairings/:entityNumber',
        ],
        component: <AssetPairings />,
    },
];

export default SensorDataRouter;
