import React from 'react';

type Props = {
    temperature: number,
    tempRange: string | number[],
    colorInRange?: string,
    colorOutOfRange?: string,
}

const TemperatureInTempRange = ({
    temperature = null,
    tempRange,
    colorInRange = '#17921F',
    colorOutOfRange = '#D44848',
} : Props) => {
    if (!temperature) {
        return null;
    }

    const inRange = temperature > Number(tempRange[0]) && temperature < Number(tempRange[1]);

    return (
        <div
            style={{
                color: inRange ? colorInRange : colorOutOfRange,
                marginRight: '2px',
            }}
        >
            { `${temperature.toFixed(1)}°C ` }
        </div>
    );
};

export default TemperatureInTempRange;
