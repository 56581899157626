import { useEffect, useState } from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';

interface Translation {
    [key: string]: {
        [key: string]: {
            [key: string]: string;
        };
    };
}

/**
 * Hook to get group of translations from POEditor depending on translate context.
 * @param dataGroupName context or context array (for nested data) of required translations key:value pairs
 * */
const useGetTranslationGroup = (dataGroupName: string | string[]) => {
    const { i18n, ready } = useCustomTranslation();
    const [translationGroup, setTranslationGroup] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        if (ready) {
            try {
                // @ts-ignore
                const { translation = {} }: Translation = i18n.getDataByLanguage(i18n.language);

                if (typeof dataGroupName === 'string' && translation[dataGroupName]) {
                    setTranslationGroup(translation[dataGroupName]);
                } else if (Array.isArray(dataGroupName) && dataGroupName.length > 0) {
                    const requiredGroup = dataGroupName.reduce((data, key, index) => {
                        if (index === 0) {
                            return translation[key];
                        }
                        return data[key];
                    }, {});

                    setTranslationGroup(requiredGroup);
                }
            } catch {
                setTranslationGroup({});
            }
        }
    }, [ready]);

    return translationGroup;
};

export default useGetTranslationGroup;
