import { makeStyles } from '@mui/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

export const useStyles = makeStyles((theme:SkycellThemeInterface) => ({
    cardRow: {
        display: 'grid',
        gridGap: '14px',
        marginBottom: '14px',
    },
    first: {
        gridTemplateColumns: 'repeat(6, 1fr)',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr',
        },
    },
    cardRowWithFourColumns: {
        gridTemplateColumns: 'repeat(6, 1fr)',
    },
    second: {
        gridTemplateColumns: '1fr 4fr 1fr',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr',
        },
    },
    fullHeight: {
        height: '100%',
    },
    cardBody: {
        paddingTop: '14px',
    },
    createButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: '20px 5px',
        gridColumn: 'span 2',
    },
    flightsCard: {
        gridColumn: 'span 1',
    },
    flightsCardContent: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '10px',
        height: '100%',
    },
    flightsCardRoot: {
        height: '100%',
    },
    noLocation: {
        display: 'flex',
        height: '100%',
        alignItems: 'flex-start',
        textAlign: 'left',
        width: '100%',
        color: theme.palette.common.black,
        font: '14px Roboto',
    },
    nonPaddedCard: {
        '& > div': {
            paddingLeft: '0px',
            paddingRight: '0px',
        },
    },
    customPad: {
        paddingLeft: '16px',
        paddingRight: '16px',
    },
    centered: {
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',

    },
    cardBodyDivided: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '20px',
    },
    textField: {
        width: '100%',
        '& label': {
            color: theme.palette.common.black,
        },
    },
    textFieldText: {
        textAlign: 'left',
        font: 'normal normal normal 14px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.common.black,
        opacity: 1,

    },
    division: {
        flex: 1,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '10px',
        gridAutoRows: 40,
    },
    selectText: {
        color: theme.palette.common.black,
    },
    expandSecond: {
        gridAutoRows: '40px auto',
    },
    simpleText: {
        color: theme.palette.common.black,
        font: 'Roboto',
        fontSize: '14px',
    },
    select: {
        color: theme.palette.common.black,
        '& label': {
            color: theme.palette.common.black,
        },
    },
    tempIcon: {
        marginLeft: '4px',
        marginRight: '6px',
        filter: `
         drop-shadow(0px -1px 0px white)
         drop-shadow(-1px 0px 0px white) 
         drop-shadow(0px 1px 0px white)
         drop-shadow(1px 0px 0px white)`,
    },
    button: {
        background: theme.palette.primary.deepBlue,
        color: theme.palette.common.white,
        textTransform: 'uppercase',
        height: '35px',
        '&:hover': {
            background: theme.palette.primary.deepBlue,
            opacity: 0.8,
        },
        '& svg': {
            maxWidth: '36px',
        },
    },
    spaced: {
        marginLeft: '8px',
        marginRight: '8px',
    },
    buttonText: {
        marginLeft: '10px',
        marginRight: '10px',
    },
    imgWhite: {
        filter: 'brightness(3.5)',
        height: '24px',
    },
    iconWhite: {
        filter: 'brightness(3.5)',
        height: '22px',
    },
    buttonContainer: {
        display: 'flex',
        marginTop: '28px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonRow: {
        paddingTop: '35px',
        paddingBottom: '35px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    topText: {
        color: theme.palette.common.black,
        fontSize: '12px',
        letterSpacing: 0.4,
        fontFamily: 'Roboto',
    },
    stepCard: {
        transition: '200ms ease',
    },
    draggedCard: {
        boxShadow: `0 8px 14px ${theme.palette.secondary[400]}, 0 10px 10px ${theme.palette.secondary[400]}`,
        transform: 'scale(1.01)',
    },
    stepCardRoot: {
        padding: '0!important',
        minHeight: '120px',
        backgroundColor: theme.palette.primary[30],
        display: 'grid',
        gridTemplateColumns: 'minmax(0, 1fr) 3fr 2fr',
        gridGap: '14px',
        '&.color0': {
            backgroundColor: theme.palette.primary[30],
        },
        '&.color1': {
            backgroundColor: theme.palette.primary[50],
        },
        '&.color2': {
            backgroundColor: theme.palette.primary[100],
        },
        '&.color3': {
            backgroundColor: theme.palette.primary[50],
        },
    },
    mandatory: {
        background: '#E0F7FF 0% 0% no-repeat padding-box',
        borderRadius: '3px',
        borderLeft: '6px solid #8BD1E8',
        opacity: 1,
    },
    stepCardLoading: {
        backgroundColor: `${theme.palette.secondary[100]}!important`,
        '& *': {
            '&:after': {
                content: 'none!important',
            },
            color: `${theme.palette.secondary[50]}!important`,
            borderColor: `${theme.palette.secondary[50]}!important`,
            backgroundColor: `${theme.palette.secondary[50]}!important`,
        },
        '& span': {
            backgroundImage: `linear-gradient(90deg, ${theme.palette.secondary[100]},\
            ${theme.palette.secondary[200]},\
            ${theme.palette.secondary[100]},\
            ${theme.palette.secondary[200]},\
            ${theme.palette.secondary[100]})!important`,
            backgroundSize: '400% 400%!important',
            animation: '$loading 2s ease infinite alternate',
            color: `${theme.palette.common.white}!important`,
            fontSize: '0px',
        },
        '& img': {
            opacity: 0,
        },
    },
    '@keyframes loading': {
        '0%': {
            backgroundPositionX: -468,
        },
        '100%': {
            backgroundPositionX: 468,
        },
    },
    radio: {
        color: theme.palette.secondary[400],
        '&$checked': {
            color: '#61C6E9',
        },
        '& .MuiSvgIcon-root': {
            height: '18px',
            width: '18px',
        },
    },
    checked: {},

    label: {
        fontSize: '12px',
        color: theme.palette.common.black,
        fontFamily: 'Roboto',
        fontWeight: 400,
        lineHeight: '1.4375em',
    },
    valueText: {
        fontSize: '14px',
        marginTop: '2px',
        color: theme.palette.common.black,
        minHeight: '14px',
        '& label span:last-child': {
            color: theme.palette.common.black,
        },
    },

    hoverIconsWrapper: {
        position: 'absolute',
        top: '0px',
        right: '0px',
        padding: '5px',

    },
    hoverIconsRow: {
        display: 'flex',
        transition: '200ms ease-in-out',
        pointerEvents: 'none',
        opacity: 0,
        whiteSpace: 'nowrap',
    },
    icon: {
        color: theme.palette.secondary[600],
        cursor: 'pointer',
        fontSize: '20px',
        height: '20px',
        '&:hover': {
            color: theme.palette.secondary[500],
        },
    },
    visible: {
        opacity: 1,
        pointerEvents: 'auto',
    },
    loading: {
        transition: '300ms ease',
        filter: 'blur(2px)',
        pointerEvents: 'none',
    },
    loadingMilestones: {
        transition: '200ms ease',
        filter: 'blur(2px)',
        pointerEvents: 'none',
    },
    summaryCardsWrapper: {
        display: 'grid',
        gridTemplateColumns: 'calc(50% - 7px) calc(50% - 7px)',
        gridGap: '14px',
        gridColumn: 'span 2',
    },
    summary: {
        display: 'grid',
        gridTemplateColumns: '1fr',
    },
    radioRow: {
        '& label span:last-child': {
            color: theme.palette.common.black,
        },
    },
}));
