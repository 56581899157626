import React from 'react';
import useStyles from '../SideFilter.style';

type Props = {
    title: string,
}

const Title = ({ title } : Props) => {
    const classes = useStyles();

    return <div className={classes.titleComponent}>{title}</div>;
};

export default Title;
