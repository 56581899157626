import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { InputAdornment, TextField, useTheme } from '@mui/material';
import Search from '@mui/icons-material/Search';
import useCustomTranslation from 'hooks/useCustomTranslation';
import QuickHelpTooltip from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import PreviewTable from 'TrackAndTrace/commonComponents/PreviewTable';
import useGetCommonData from 'hooks/useGetCommonData';
import { LoggersDTO } from 'dataTypes/SecureBackend/apiResponse';
import tableColumns from './tableColumns';
import { ExtractedLoggerData, extractLoggerData } from '../../../lib';
import useStyles from './SearchPreviewTable.style';

type Props = {
    isTableView: boolean,
    loggersFromPreviewTable: ExtractedLoggerData[],
    setLoggersFromPreviewTable: Dispatch<SetStateAction<ExtractedLoggerData[]>>,
    changeHighlightedText: (newHighlightedText: any) => void,
    setQuery?: Dispatch<SetStateAction<string>>,
    query?: string,
    core?:string,
}

const SearchPreviewTable = ({
    isTableView,
    changeHighlightedText,
    loggersFromPreviewTable = null,
    setLoggersFromPreviewTable,
    setQuery,
    core = 'track-and-trace',
} : Props) => {
    const textRef = useRef(null);
    const { t } = useCustomTranslation();
    const classes = useStyles({
        searchBoxLeft: textRef.current?.getBoundingClientRect()?.x || 0,
    });
    const theme = useTheme();
    const [searchString, setSearchString] = useState('');
    const [open, setOpen] = useState(false);
    const [data, setData] = useState<ExtractedLoggerData[]>([]);
    const {
        data: rawLoggers,
    } = useGetCommonData<LoggersDTO>('loggers/search',
        {
            query: {
                page: 0,
                pageSize: 9999,
                q: searchString,
            },
            enabled: searchString?.length > 0 && !isTableView,
        });

    useEffect(() => {
        const toggle = () => setOpen(false);

        document.addEventListener('click', toggle);
        return () => {
            document.removeEventListener('click', toggle);
        };
    }, []);

    const searchStringChanged = (event) => {
        if (isTableView) {
            setQuery(event.target.value);
        }
        setSearchString(event.target.value);
        if (event.target.value) {
            changeHighlightedText(event.target.value.replace(/[\\/#!$%^&*;:{}=\-_`~()]/g, ''));
            setOpen(event.target.value.length !== 0);
        } else if (!event.target.value && isTableView) {
            setLoggersFromPreviewTable([]);
        }
    };

    const handleFocus = () => {
        setOpen(prev => prev || searchString.length > 0 || loggersFromPreviewTable?.length > 0);
    };
    const handleClick = useCallback((e) => e.stopPropagation(), []);
    const handleClickShowResults = () => {
        setQuery(searchString);
        setOpen(false);
    };
    const handleClickClearSearch = useCallback(() => {
        setLoggersFromPreviewTable([]);
        setOpen(false);
        setSearchString('');
        setQuery('');
    }, []);

    useEffect(() => {
        if (rawLoggers?.resultList) {
            const extractedAssetData = extractLoggerData(rawLoggers.resultList);

            const processedAssetData = extractedAssetData.map(obj => {
                return { ...obj, loggerFamilyLabel: t(`LOGGER_TYPE_LABEL.${obj.loggerFamilyLabel}`) };
            });

            global.console.log('extractedAssetData', processedAssetData);
            setData(processedAssetData);
            if (isTableView) {
                setLoggersFromPreviewTable(processedAssetData);
            }
        }
    }, [rawLoggers]);

    const columns = useMemo(() => tableColumns(t), [t]);

    return (
        <div>
            <QuickHelpTooltip
                tooltipInfo={{
                    text: t('QUICK_HELP.TRACK_AND_TRACE.SEARCH_PACKAGING_BY_SERIAL'),
                    order: 2,
                    position: 'right',
                    uid: 'searchBySerialLoggersTooltip',
                }}
            >
                <TextField
                    className={classes.input}
                    id="TextFieldInput"
                    onInput={searchStringChanged}
                    value={searchString}
                    variant="outlined"
                    placeholder={t('TRACK_AND_TRACE.SEARCH_LOGGER')}
                    ref={textRef}
                    onFocus={handleFocus}
                    onClick={handleClick}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search style={{ color: theme.palette.common.black }} />
                            </InputAdornment>
                        ),
                        className: classes.input,
                    }}
                />
            </QuickHelpTooltip>
            {
                !isTableView && (
                    <PreviewTable
                        data={data}
                        columns={columns}
                        maskForHighlight={searchString}
                        onClickButtonClearSearch={handleClickClearSearch}
                        rowLinkTemplate={`/${core}/loggers/:loggerNumber`}
                        onCardClick={handleClick}
                        onClickButtonShowResults={handleClickShowResults}
                        isCardOpen={open}
                        textRef={textRef}
                    />
                )
            }
        </div>
    );
};

export default SearchPreviewTable;
