/* eslint-disable react/no-danger */
import React from 'react';
import Card from 'shared-components/Card';
import { UserInfo } from 'Contexts/AuthContext';
import { ProcessedNotification } from '../lib';

import useStyles from '../Notifications.style';

type Props = {
    notification: ProcessedNotification,
    userInfo: UserInfo,
}
const NotificationContent = ({ notification, userInfo } : Props) => {
    const classes = useStyles();
    const { body } = notification;
    const { name } = userInfo;

    if (name) {
        return (
            <div className={classes.expansion}>
                <Card className={classes.contentCard}>
                    <div className={classes.messageContent}>
                        <div className={classes.messageContent}>
                            {/* eslint-disable-next-line react/no-danger */}
                            <div
                                style={{
                                    whiteSpace: 'pre-wrap',
                                }} dangerouslySetInnerHTML={{ __html: body }}
                            />
                        </div>
                        <div className={classes.goodbye}>
                            <div>Best regards,</div>
                            <div>Your SkyMind team</div>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }

    return null;
};

export default NotificationContent;
