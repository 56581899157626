import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    percentageScaleContainer: {
        alignItems: 'center',
        display: 'flex',
    },
    percentageScaleLabel: {
        marginLeft: '11px',
        textAlign: 'right',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    percentageScaleTitle: {
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: theme.palette.secondary[500],
        opacity: 1,
    },
    blank: {
        width: '8px',
        height: '23px',
        marginRight: '2px',
        // @ts-ignore
        background: `${theme.palette.common.contrastWhite} 0% 0% no-repeat padding-box`,
        border: `0.5px solid ${theme.palette.secondary[400]}`,
        borderRadius: '4px',
        opacity: 1,
    },
    blue: {
        width: '8px',
        height: '23px',
        marginRight: '2px',
        background: `${theme.palette.primary[400]} 0% 0% no-repeat padding-box`,
        border: `0.5px solid ${theme.palette.primary[500]}`,
        borderRadius: '4px',
        opacity: 1,
    },
    green: {
        width: '8px',
        height: '23px',
        marginRight: '2px',
        background: '#8CCEA3 0% 0% no-repeat padding-box',
        border: '0.5px solid #17921F',
        borderRadius: '4px',
        opacity: 1,
    },
    pink: {
        width: '8px',
        height: '23px',
        marginRight: '2px',
        background: '#FFE5E5 0% 0% no-repeat padding-box',
        border: '0.5px solid #D44848',
        borderRadius: '4px',
        opacity: 1,
    },
    yellow: {
        width: '8px',
        height: '23px',
        marginRight: '2px',
        background: '#F0DEBF 0% 0% no-repeat padding-box',
        border: '0.5px solid #EDAE49',
        borderRadius: '4px',
        opacity: 1,
    },
}));

export default useStyles;
