import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import useCacheContext from 'hooks/useCacheContext';
import { Typography } from '@mui/material';
import Card from 'shared-components/Card';
import {
    CompositePolyline,
    LatLngTimeTimestamp,
    TimeRange,
} from 'dataTypes/common';
import { SensorDataRequestBody } from 'dataTypes/SecureBackend/processedData';
import ApexTemperatureChart, {
    ZoomedDataLimits,
    getPolylinePath,
    defaultPolylineOptions,
} from 'shared-components/ApexTemperatureChart';
import icons from 'shared-components/icons';
import SwitchWithLabel from 'shared-components/SwitchWithLabel';
import NoSensorInfo from 'shared-components/NoSensorInfo';
import Map from 'shared-components/Map';
import RectangleContentLoader from 'shared-components/RectangleContentLoader';
import useCustomTranslation from 'hooks/useCustomTranslation';
import moment from 'moment';
import { initialRequestBody } from '../../dataTypes';

import useStyles from './AssetUnit.style';
import BackToLink from '../../../shared-components/BackToLink';

type TempRangeType = { tempRangeMin: number, tempRangeMax: number }
const defaultTempRange = { tempRangeMin: null, tempRangeMax: null };

type Props = {
    localTimezone?: boolean,
    loggerNumber: string,
    options: SensorDataRequestBody,
    showMap?: boolean,
    timeRange: TimeRange,
    entityId: number,
    entityType?: string,
}

const AssetUnit = ({
    localTimezone = false,
    loggerNumber = '',
    options = initialRequestBody,
    showMap = false,
    timeRange,
    entityType = '',
    entityId,
}: Props) => {
    const classes = useStyles();
    const [showGeolocationContentLoader, setShowGeolocationContentLoader] = useState(false);
    const [showMarkers, setShowMarkers] = useState(false);
    const [zoomedDataLimits, setZoomedDataLimits] = useState<ZoomedDataLimits>({ min: null, max: null });
    const [tempRange] = useState<TempRangeType>(defaultTempRange);
    const [coordinates, setCoordinates] = useState<LatLngTimeTimestamp[]>([]);
    const [gMap, setGMap] = useState(null);
    const [mouseMoveDataIndex, setMouseMoveDataIndex] = useState(0);
    const { getCacheValue } = useCacheContext();

    const { t } = useCustomTranslation();

    useEffect(() => {
        setZoomedDataLimits({ min: null, max: null });
    }, [coordinates]);

    const polylines = useMemo((): CompositePolyline => {
        if (!coordinates || coordinates.length === 0) {
            return {
                path: [],
                options: null,
            };
        }

        const path = getPolylinePath(coordinates, zoomedDataLimits);

        return {
            path,
            options: defaultPolylineOptions,
        };
    }, [coordinates, zoomedDataLimits]);

    const markerData = useMemo(() => {
        if (!coordinates || coordinates.length === 0 || mouseMoveDataIndex < 0 || !coordinates[mouseMoveDataIndex]) {
            return {
                position: null,
                icon: null,
            };
        }
        return {
            position: coordinates[mouseMoveDataIndex].location,
            icon: icons.hex_with_cross,
        };
    }, [
        coordinates,
        mouseMoveDataIndex,
    ]);

    const tooltipData = useMemo(() => {
        if (!coordinates || coordinates.length === 0 || !coordinates[mouseMoveDataIndex]) {
            return {
                location: null,
                time: '',
            };
        }
        return coordinates[mouseMoveDataIndex];
    }, [
        coordinates,
        mouseMoveDataIndex,
    ]);

    const bounds = useMemo(() => {
        const lats = polylines.path.map((item) => item.lat);
        const lngs = polylines.path.map((item) => item.lng);

        return {
            south: lats.length ? Math.min(...lats) : null,
            west: lngs.length ? Math.min(...lngs) : null,
            north: lats.length ? Math.max(...lats) : null,
            east: lngs.length ? Math.max(...lngs) : null,
        };
    }, [polylines]);

    const mapConfig = useMemo(() => {
        return {
            containerStyle: {
                height: '45vh',
                width: '100%',
            },
            zoom: 7,
            backgroundColor: 'unset',
        };
    }, []);

    useEffect(() => {
        if (gMap && polylines.path.length > 0 && bounds.east && bounds.north && bounds.south && bounds.west) {
            gMap.fitBounds(bounds);
            gMap.setZoom(gMap.getZoom() - 1);
            setTimeout(() => {
                gMap.setZoom(gMap.getZoom() + 1);
            }, 0);
        }
    }, [bounds, gMap]);

    useEffect(() => {
        if (showMap && polylines.path.length === 0) {
            setShowGeolocationContentLoader(true);
            setTimeout(() => setShowGeolocationContentLoader(false), 7000);
        }
    }, [showMap, polylines]);

    const handleLoad = (map) => {
        setGMap(map);
    };

    const handleChangeMarkers = useCallback(() => setShowMarkers(prev => !prev), []);

    return (
        <div>
            <BackToLink
                to={`/asset-management/assets?view=${getCacheValue('VIEW_TYPE') || 'table'}`}
                title={t('COMMON.ASSETS_LIST')}
                marginRight={12}
                marginLeft={0}
                marginBottom={10}
                marginTop={10}
            />
            <Card>
                <Typography variant="h3" className={classes.loggerTitle}>
                    <div>
                        {
                            localTimezone
                                ? `${t(`ASSET_TYPE_LABEL.${entityType}`)} ${loggerNumber}
                                | ${t('SENSOR_DATA.TEMPERATURE_IN_C')}`
                                : `${t(`ASSET_TYPE_LABEL.${entityType}`)} ${loggerNumber}
                                | ${t('SENSOR_DATA.TEMPERATURE_UTC_TIME')}`
                        }
                    </div>
                    <div className={classes.loggerTitle}>
                        <SwitchWithLabel
                            title={t('SENSOR_DATA.MARKERS')}
                            value={showMarkers}
                            onChange={handleChangeMarkers}
                            // setValue={setShowMarkers}
                        />
                    </div>
                </Typography>
                <ApexTemperatureChart
                    serialNumber={loggerNumber}
                    dateTimeFrom={localTimezone
                        ? moment(timeRange.from).local().format('YYYY-MM-DDTHH:mm') : timeRange.from}
                    dateTimeTo={localTimezone ? moment(timeRange.to).local().format('YYYY-MM-DDTHH:mm') : timeRange.to}
                    temperatureRangeMax={tempRange.tempRangeMax}
                    temperatureRangeMin={tempRange.tempRangeMin}
                    showMarkers={showMarkers}
                    setCoordinates={setCoordinates}
                    errorPictureSize="large"
                    requestOptions={options}
                    height={showMap ? 300 : 600}
                    setZoomedDataLimits={showMap ? setZoomedDataLimits : null}
                    setMouseMoveDataIndex={showMap ? setMouseMoveDataIndex : () => {}}
                    requestType="assets"
                    inLocalTimeZone={localTimezone}
                    entityId={entityId}
                />
            </Card>
            {
                showMap
                    ? (
                        <Card title={t('SENSOR_DATA.GEOLOCATION')} className={classes.mapCard}>
                            {
                                polylines.path.length
                                    ? (
                                        <Map
                                            mapConfig={mapConfig}
                                            separatePolyline={polylines}
                                            separateMarker={markerData.position}
                                            separateMarkerIcon={markerData.icon}
                                            separateTooltip={tooltipData}
                                            handleLoad={handleLoad}
                                            showCenterToPosition
                                        />
                                    )
                                    : (
                                        showGeolocationContentLoader
                                            ? <RectangleContentLoader height="45vh" />
                                            : (
                                                <NoSensorInfo
                                                    picture="noDataFound"
                                                    text={t('SENSOR_DATA.NO_GEOLOCATION')}
                                                />
                                            )
                                    )
                            }
                        </Card>
                    )
                    : null
            }
        </div>

    );
};

export default AssetUnit;
