import { useEffect } from 'react';
import { useTranslation as originalUseTranslation } from 'react-i18next';

const useCustomTranslation = () => {
    const { i18n, ...rest } = originalUseTranslation();

    useEffect(() => {
        const refreshTranslations = async () => {
            await i18n.reloadResources(i18n.languages);
        };

        i18n.on('languageChanged', refreshTranslations);

        return () => {
            i18n.off('languageChanged', refreshTranslations);
        };
    }, [i18n]);

    return { i18n, ...rest };
};

export default useCustomTranslation;
