import { StandardItem } from 'shared-components/menuItems';

// const itemsWithCustomTitle = ['serviceProviders', 'myCompanyLocations'];

export const hasTabs = (currentPage: StandardItem, pathname: string): boolean => {
    if (!currentPage?.tabs || !pathname) {
        return false;
    }

    const currentPageLink = `/${currentPage?.link}`;

    return pathname === currentPageLink
        || currentPage.tabs.some(tab => pathname === `${currentPageLink}/${tab.link}`);
};

export const getTitle = (
    pageTitle = '',
    pathname = '/',
    companyName = '',
    // breadcrumbsNamespace = {},
) => {
    if (pathname.includes('my-company')) {
        return companyName;
    }

    // return itemsWithCustomTitle.includes(pageId) && isCustomPage
    //     ? breadcrumbsNamespace['CUSTOM_TITLE'] || pageTitle
    //     : pageTitle;

    return pageTitle;
};
