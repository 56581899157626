import React from 'react';
import useStyles from './PercentageScale.style';

type Props = {
    color?: 'blue' | 'green' | 'pink' | 'yellow',
    label?: string,
    size?: 10 | 20 | 30,
    title?: string,
    value: number,
}

const PercentageScale = ({
    color = 'blue',
    value,
    size = 20,
    label = '',
    title = '',
} : Props) => {
    const classes = useStyles();
    const step = 100 / size;
    const classNames = [];
    const roundedValue = size === 10 ? Math.round(value / 10) * 10 : value;

    for (let i = 0; i < 100; i += step) {
        if (roundedValue > 0 && i < roundedValue) {
            classNames.push(classes[color]);
        } else {
            classNames.push(classes.blank);
        }
    }

    return (
        <div>
            {
                title && <div className={classes.percentageScaleTitle}>{title}</div>
            }
            <div className={classes.percentageScaleContainer}>
                <div className={classes.percentageScaleContainer}>
                    {
                        classNames.map((className, index) => {
                            return <div className={className} key={`step${index}`} />;
                        })
                    }
                </div>
                {
                    label && <div className={classes.percentageScaleLabel}>{label}</div>
                }
            </div>
        </div>
    );
};

export default PercentageScale;
