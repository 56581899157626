import React from 'react';
import { Redirect } from 'react-router-dom';
import { RouteItem } from 'dataTypes/common';
import { userRoles } from 'hooks/useHasAccess';

import Lanes from './Lanes';
import TemperatureSimulation from './Lanes/tabs/TemperatureSimulation';
import AddLane from './AddLane';

const LaneManagementRouter: RouteItem[] = [
    {
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        exact: true,
        path: '/lane-management',
        component: <Redirect push to="/lane-management/lanes" />,
    },
    {
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        exact: true,
        path: '/lane-management/lanes',
        component: <Lanes />,
    },
    {
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        exact: true,
        path: '/lane-management/lanes/add',
        component: <AddLane />,
    },

    {
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        exact: true,
        path: '/lane-management/products',
        component: <div>Lane Products</div>,
    },
    {
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        exact: true,
        path: '/lane-management/lane-comparisons',
        component: <div>Lane Comparisons</div>,
    },
    {
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        exact: true,
        path: '/lane-management/lanes/:laneId/temperature-simulation',
        component: <TemperatureSimulation />,
    },
    {
        necessaryRoles: [userRoles.LANE_MANAGEMENT],
        exact: true,
        path: '/lane-management/lanes/:laneId/definition',
        component: <AddLane />,
    },
];

export default LaneManagementRouter;
