import { ThemeOptions } from '@mui/material';
import { Spacing } from '@mui/system';

const skycellPrimaryPalette = {
    30: '#E0F7FF',
    50: '#C3E9F7',
    100: '#AADBEC',
    200: '#8BD1E8',
    300: '#6FCAE8',
    400: '#61C6E9',
    500: '#57B1CF',
    600: '#4C9BB5',
    700: '#4790A8',
    800: '#2C5969',
    main: '#61C6E9',
    light: '#00739A',
    dark: '#4C9BB5',
    deepBlue: '#009CCF',
    darkBlue: '#00739A',
    contrastText: '#393939',
};

const skycellSecondaryPalette = {
    50: '#F7F7F7',
    100: '#EFEFEF',
    200: '#DFDFDF',
    300: '#D7D7D7',
    400: '#B7B7B7',
    500: '#939393',
    600: '#747474',
    700: '#6B6B6B',
    800: '#393939',
    900: '#191919',
    main: '#939393',
    light: '#D7D7D7',
    dark: '#393939',
    gray: '#7C7C7C',
};

// Please use this interface for all theme types.
export interface SkycellThemeInterface extends ThemeOptions {
    palette: {
        common: {
            white: string,
            black: string,
            purple: string,
            yellow: string,
            lightYellow: string,
            beige: string,
            green: string,
            red: string,
            yellowText: string,
            lightYellowText: string,
            greenText: string,
            redText: string,
            contrastWhite: string,
            contrastWhite50: string,
            contrastBlue: string,
            menuTextWhite: string,
            titleBlackText: string,
            primaryHighlight30: string,
            contrastGray600: string,
            dividerColor: string,
            firstShadeOfGray: string,
            secondShadeOfGray: string,
            thirdShadeOfGray: string,
            fourthShadeOfGray: string,
            constants: {
                white: string,
                gray900: string,
            },
        },
        action: {
            selected: string,
        },
        info: {
            main: string,
        },
        success: {
            main: string,
        },
        error: {
            main: string,
        },
        primary: ThemeOptions['palette']['primary'] & {
            deepBlue: string,
            darkBlue: string,
            contrastText: string,
            main: string,
        },
        secondary: ThemeOptions['palette']['secondary'],
        [key: string]: string | number | object;
    };
    spacing?: Spacing;
    shape: ThemeOptions['shape'] & {
        tabHeight: number
    };
    typography: ThemeOptions['typography'] & {
        title: {
            fontSize: string | number,
        }
    };
    [key: string]: any,
}

const theme: SkycellThemeInterface = {
    palette: {
        primary: skycellPrimaryPalette,
        secondary: skycellSecondaryPalette,
        common: {
            white: '#FFFFFF',
            black: '#000000',
            purple: '#CF3B8A',
            yellow: '#EDAE49',
            lightYellow: '#EEE172',
            beige: '#efe0c1',
            green: '#17921F',
            red: '#D44848',
            yellowText: '#747474',
            lightYellowText: '#747474',
            greenText: '#747474',
            redText: '#747474',
            contrastWhite: '#FFF',
            contrastWhite50: '#FFF',
            contrastBlue: '#FFF',
            menuTextWhite: '#FFF',
            titleBlackText: '#000',
            primaryHighlight30: '#E0F7FF',
            contrastGray600: '#747474',
            dividerColor: '#939393',
            firstShadeOfGray: '#b7b7b7',
            secondShadeOfGray: '#dfdfdf',
            thirdShadeOfGray: '#a7a7a7',
            fourthShadeOfGray: '#747474',
            constants: {
                white: '#FFF',
                gray900: '#191919',
            },
        },
        action: {
            selected: '#6FCAE8',
        },
        info: {
            main: '#61C6E9',
        },
        success: {
            main: '#43A047',
        },
        error: {
            main: '#E22C2C',
        },
    },
    shape: {
        tabHeight: 41,
    },
    typography: {
        fontFamily: 'Roboto',
        h1: {
            fontSize: '36px',
            fontWeight: 200,
            lineHeight: '43px',
            color: skycellSecondaryPalette[900],
        },
        h2: {
            fontSize: '24px',
            fontWeight: 400,
            letterSpacing: 0.26,
            lineHeight: '36px',
        },
        h3: {
            fontSize: '20px',
            fontWeight: 400,
            letterSpacing: 0.26,
            lineHeight: '24px',
            color: skycellSecondaryPalette[900],
        },
        h4: {
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '19px',
            color: skycellSecondaryPalette[600],
        },
        h5: {
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: 'none',
        },
        subtitle1: {
            fontSize: '16px',
            fontWeight: 300,
        },
        body1: {
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '19px',
            color: skycellSecondaryPalette[600],
        },
        caption: {
            fontSize: '12px',
            fontWeight: 400,
            letterSpacing: 0.4,
            lineHeight: '14px',
            color: skycellSecondaryPalette[500],
        },
        button: {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '14px',
            letterSpacing: 1,
        },
        title: {
            fontSize: '72px',
        },
    },
    overrides: {
        MuiInputBase: {
            multiline: {
                padding: '6px 0 3px',
            },
            input: {
                padding: '3px 0 3px',
            },
        },
        MuiInput: {
            root: {
                '&:after': {
                    borderColor: `${skycellPrimaryPalette[400]}!important`,
                },
            },
        },
        MuiSwitch: {
            colorPrimary: {
                color: 'white',
                '&$checked': {
                    color: skycellPrimaryPalette.deepBlue,
                },
                '&$checked+$track': {
                    backgroundColor: 'white',
                    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.26)',
                    opacity: 1,
                },
            },
            colorSecondary: {
                color: skycellSecondaryPalette.main,
                '&$checked': {
                    color: skycellSecondaryPalette.main,
                },
                '&$checked+$track': {
                    backgroundColor: 'white',
                    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.26)',
                    opacity: 1,
                },
            },
            track: {
                backgroundColor: skycellSecondaryPalette.gray,
                boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.26)',
                opacity: 1,
            },
        },
        MuiInputLabel: {
            root: {
                color: skycellSecondaryPalette[500],
            },
        },
        MuiSlider: {
            rail: {
                color: skycellSecondaryPalette[800],
            },
        },
        MuiMenuItem: {
            root: {
                '&$selected': { color: skycellSecondaryPalette[50] },
            },
        },
        MuiDrawer: {
            paper: {
                zIndex: 30,
            },
        },
        MuiButton: {
            containedPrimary: {
                font: 'normal normal normal 14px/19px Roboto!important',
                letterSpacing: '0.43px!important',
                borderRadius: '0!important',
                background: `${skycellPrimaryPalette.deepBlue} 0% 0% no-repeat padding-box!important`,
                backgroundColor: `${skycellPrimaryPalette.deepBlue}!important`,
                color: '#FFFFFF!important',
                textAlign: 'center!important',
                textTransform: 'capitalize!important',
                '&:hover': {
                    backgroundColor: `${skycellPrimaryPalette.light}!important`,
                },
                '&.Mui-disabled': {
                    background: `${skycellSecondaryPalette.light} 0% 0% no-repeat padding-box!important`,
                    backgroundColor: `${skycellSecondaryPalette.light}!important`,
                    color: '#FFFFFF',
                },
            },
            outlinedSecondary: {
                font: 'normal normal normal 14px/19px Roboto!important',
                letterSpacing: '0.43px!important',
                borderRadius: '0!important',
                border: `1px solid ${skycellPrimaryPalette.deepBlue}!important`,
                color: `${skycellPrimaryPalette.deepBlue}!important`,
                textAlign: 'center!important',
                textTransform: 'capitalize!important',
                '&:hover': {
                    backgroundColor: `${skycellSecondaryPalette.light}!important`,
                },
                '&.Mui-disabled': {
                    background: `${skycellSecondaryPalette.light} 0% 0% no-repeat padding-box!important`,
                    backgroundColor: `${skycellSecondaryPalette.light}!important`,
                    color: '#FFFFFF!important',
                },
            },
            textPrimary: {
                font: 'normal normal normal 14px/19px Roboto!important',
                letterSpacing: '0.43px!important',
                borderRadius: '0!important',
                color: `${skycellPrimaryPalette.deepBlue}!important`,
                backgroundColor: '#FFFFFF!important',
                textAlign: 'center!important',
                textTransform: 'capitalize!important',
                '&:hover': {
                    backgroundColor: `${skycellSecondaryPalette.light}!important`,
                },
                '&.Mui-disabled': {
                    background: `${skycellSecondaryPalette.light} 0% 0% no-repeat padding-box!important`,
                    backgroundColor: `${skycellSecondaryPalette.light}!important`,
                    color: '#FFFFFF!important',
                },
            },
        },
    },

};

export default theme;
