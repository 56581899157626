import React, {
    Dispatch,
    ChangeEvent,
    SetStateAction,
    useCallback,
} from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import SideFilter, {
    AirportOptions,
    AccordionOptionsGroup,
    CheckboxOptionItem,
    FilterBlock,
    SwitchOptionItem,
} from 'shared-components/SideFilter';
import {
    AvailableFilterOptions,
    GenericShipmentData,
    FilterOptionsCount,
} from 'TrackAndTrace/GenericShipments/lib';
import useStyles from 'TrackAndTrace/commonComponents/SideFilterComponents.style';
import SearchPreviewTable from './components/SearchPreviewTable';
import ShipmentFromPreviewTable from './components/ShipmentFromPreviewTable';

type Props = {
    availableFilterOptions: AvailableFilterOptions,
    destinationAirports: string[],
    filterOptionsCount: FilterOptionsCount,
    changeHighlightedText: (newHighlightedText: any) => void,
    originAirports: string[],
    setDestinationAirports: (destinationAirports: string[]) => void,
    setOriginAirports: (originAirports: string[]) => void,
    setShipmentStatus: (shipmentStatus: string[]) => void,
    shipmentStatus: string[],
    setShipmentsFromPreviewTable: Dispatch<SetStateAction<GenericShipmentData[]>>,
    setShowAirportsInfo: (showAirportsInfo: boolean) => void,
    setTemperatureStatus: (temperatureStatus: string[]) => void,
    temperatureStatus: string[],
    shipments: GenericShipmentData[],
    shipmentsFromPreviewTable: GenericShipmentData[],
    isTableView: boolean,
}

const ShipmentClientSideFilter = ({
    availableFilterOptions,
    changeHighlightedText,
    filterOptionsCount,
    setShipmentStatus,
    shipmentStatus,
    setTemperatureStatus,
    setOriginAirports,
    originAirports,
    setDestinationAirports,
    destinationAirports,
    setShipmentsFromPreviewTable,
    setShowAirportsInfo,
    shipments = [],
    shipmentsFromPreviewTable = [],
    temperatureStatus,
    isTableView,
} : Props) => {
    const classes = useStyles();
    const { t } = useCustomTranslation();

    const handleChangeShowAirports = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setShowAirportsInfo(event.target.checked);
    }, []);

    const onSelectDeselectShipmentStatus = useCallback(() => {
        if (shipmentStatus.length === 0) {
            setShipmentStatus(
                availableFilterOptions.shipmentStatus.map(it => it.value),
            );
        } else {
            setShipmentStatus([]);
        }
    }, [shipmentStatus, availableFilterOptions]);

    const onSelectDeselectTemperatureExcursions = useCallback(() => {
        if (!temperatureStatus || temperatureStatus.length === 0) {
            setTemperatureStatus(
                availableFilterOptions.temperatureStatus.map(it => it.value),
            );
        } else {
            setTemperatureStatus([]);
        }
    }, [temperatureStatus, availableFilterOptions]);

    return (
        <SideFilter>
            <FilterBlock>
                <div className={classes.title}>{t('TRACK_AND_TRACE.FILTERS')}</div>
                <SearchPreviewTable
                    setShipmentsFromPreviewTable={setShipmentsFromPreviewTable}
                    shipments={shipments}
                    shipmentsFromPreviewTable={shipmentsFromPreviewTable}
                    changeHighlightedText={changeHighlightedText}
                    isTableView={isTableView}
                />
            </FilterBlock>
            {
                shipmentsFromPreviewTable.length > 0 && (
                    <FilterBlock>
                        <div className={classes.searchPreviewShipmentContainer}>
                            <div className={classes.searchPreviewShipmentTitle}>
                                Some results are filtered out, clear search to see all results
                            </div>
                            <div className={classes.shipmentsContainer}>
                                {
                                    shipmentsFromPreviewTable.map(({
                                        externalId,
                                        packagingsSerialNumberList,
                                    }, index) => {
                                        const handleClickRemoveFromList = () => {
                                            setShipmentsFromPreviewTable(prev => prev.filter(shipment => {
                                                return shipment.externalId !== externalId;
                                            }));
                                        };

                                        packagingsSerialNumberList = shipments
                                            .find(shipment => shipment.externalId === externalId)
                                            ?.cargo?.map(cargo => cargo.packaging.serialNumber) || [];

                                        return (
                                            <ShipmentFromPreviewTable
                                                onClick={handleClickRemoveFromList}
                                                packagingsSerialNumberList={packagingsSerialNumberList}
                                                shipmentNumber={externalId}
                                                key={`shipment${index}`}
                                            />
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </FilterBlock>
                )
            }
            {
                availableFilterOptions.shipmentStatus.length > 0 && (
                    <FilterBlock>
                        <AccordionOptionsGroup
                            title={
                                `${t('TRACK_AND_TRACE.TITLE_SHIPMENT_STATUS')} (${
                                    Object.keys(filterOptionsCount.shipmentStatusCount)
                                        .reduce((acc, cur) => acc + filterOptionsCount.shipmentStatusCount[cur], 0)
                                })`
                            }
                            onSelectDeselect={onSelectDeselectShipmentStatus}
                        >
                            {
                                [...availableFilterOptions.shipmentStatus]
                                    .sort((a, b) => {
                                        const { shipmentStatusCount = null } = filterOptionsCount || {};
                                        const { [a.value]: aCount = 0 } = shipmentStatusCount || {};
                                        const { [b.value]: bCount = 0 } = shipmentStatusCount || {};

                                        return bCount - aCount || a.label.localeCompare(b.label);
                                    })
                                    .map((option) => (
                                        <div style={{ marginBottom: '12px' }} key={option.value}>
                                            <CheckboxOptionItem
                                                count={filterOptionsCount.shipmentStatusCount[option.value] || 0}
                                                // count={option.count}
                                                checked={shipmentStatus.includes(option.value)}
                                                label={option.label}
                                                optionKey={option.value}
                                                setCheckboxGroupOptions={setShipmentStatus}
                                                showTooltip
                                                tooltipDescription={option.description}
                                            />
                                        </div>
                                    ))
                            }
                        </AccordionOptionsGroup>
                    </FilterBlock>
                )
            }
            {
                availableFilterOptions.temperatureStatus && availableFilterOptions.temperatureStatus.length > 0 && (
                    <FilterBlock>
                        <AccordionOptionsGroup
                            title={
                                `${t('TRACK_AND_TRACE.TITLE_PACKAGING_STATUS')} (${
                                    Object.values(filterOptionsCount.temperatureStatusCount)
                                        .reduce((acc, cur) => acc + cur, 0)
                                })`
                            }
                            onSelectDeselect={onSelectDeselectTemperatureExcursions}

                        >
                            {
                                [...availableFilterOptions.temperatureStatus]
                                    .sort((a, b) => {
                                        const { temperatureStatusCount = null } = filterOptionsCount || {};
                                        const { [a.value]: aCount = 0 } = temperatureStatusCount || {};
                                        const { [b.value]: bCount = 0 } = temperatureStatusCount || {};

                                        return bCount - aCount || a.label.localeCompare(b.label);
                                    })
                                    .map((option) => (
                                        <div style={{ marginBottom: '12px' }} key={option.value}>
                                            <CheckboxOptionItem
                                                count={filterOptionsCount.temperatureStatusCount[option.value] || 0}
                                                // count={option.count}
                                                checked={temperatureStatus.includes(option.value)}
                                                label={option.label}
                                                optionKey={option.value}
                                                setCheckboxGroupOptions={setTemperatureStatus}
                                                showTooltip
                                                tooltipDescription={option.description}
                                            />
                                        </div>
                                    ))
                            }
                        </AccordionOptionsGroup>
                    </FilterBlock>
                )
            }
            {
                (availableFilterOptions.destinationAirports.length > 0
                    || availableFilterOptions.originAirports.length > 0) && (
                    <FilterBlock>
                        <AccordionOptionsGroup title={t('LANE_MANAGEMENT.ADDRESS.AIRPORT')}>
                            {
                                !isTableView && (
                                    <SwitchOptionItem
                                        label={t('TRACK_AND_TRACE.SHOW_AIRPORTS')}
                                        onChange={handleChangeShowAirports}
                                        tooltipDescription={t('EXPLANATION_TOOLTIPS.SHOW_AIRPORTS')}
                                        showTooltip
                                    />
                                )
                            }
                            <AirportOptions
                                availableDestinationAirports={availableFilterOptions.destinationAirports}
                                availableOriginAirports={availableFilterOptions.originAirports}
                                selectedDestinationAirports={destinationAirports}
                                selectedOriginAirports={originAirports}
                                setDestinationAirports={setDestinationAirports}
                                setOriginAirports={setOriginAirports}
                            />
                        </AccordionOptionsGroup>
                    </FilterBlock>
                )
            }
        </SideFilter>
    );
};

export default ShipmentClientSideFilter;
