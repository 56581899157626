import React, { createContext, CSSProperties, Dispatch, SetStateAction, useMemo, useState, useCallback } from 'react';
import { QuickHelpTooltipInfo } from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import QuickHelpElement from 'Contexts/QuickHelpContext/QuickHelpElement';
import UserGuide from './UserGuide';
import { SvgWithCutouts } from './generateSvgWithCutouts';

export interface QuickHelpContextInterface {
    enableTooltips: boolean,
    setEnableTooltips: Dispatch<SetStateAction<boolean>>,
    enableGuide: boolean,
    setEnableGuide: Dispatch<SetStateAction<boolean>>,
    hasUserGuide: boolean,
    setHasUserGuide: Dispatch<SetStateAction<boolean>>,
    quickHelpTooltipsBag: string[],
    setTooltipInfos: Dispatch<SetStateAction<{ [key: string]: QuickHelpTooltipInfo; }>>,
}

export const QuickHelpContext = createContext<QuickHelpContextInterface>({
    enableTooltips: false,
    setEnableTooltips: () => false,
    enableGuide: false,
    setEnableGuide: () => false,
    hasUserGuide: false,
    setHasUserGuide: () => false,
    quickHelpTooltipsBag: [],
    setTooltipInfos: () => false,
});

const QuickHelpContextProvider = ({ children }) => {
    const [enableTooltips, setEnableTooltips] = useState<boolean>(false);
    const [enableGuide, setEnableGuide] = useState<boolean>(false);
    const [hasUserGuide, setHasUserGuide] = useState<boolean>(false);
    const [tooltipInfos, setTooltipInfos] = useState<{
        [key:string]: QuickHelpTooltipInfo
    }>({ });
    const quickHelpTooltipsBag = useMemo<string[]>(() => Object.keys(tooltipInfos), [tooltipInfos]);
    const backStyle = useMemo<CSSProperties>(() => ({
        transition: 'opacity 200ms ease',
        pointerEvents: (enableTooltips || enableGuide) ? 'all' : 'none',
        zIndex: 10000,
        background: 'transparent',
        opacity: (enableTooltips || enableGuide) ? 0.3 : 0,
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        cursor: (enableTooltips || enableGuide) ? 'pointer' : 'none',
    }), [enableTooltips, enableGuide]);

    const handleBackgroundClick = useCallback(() => {
        setEnableTooltips(false);
        setEnableGuide(false);
    }, []);

    return (
        <QuickHelpContext.Provider value={{
            enableTooltips,
            setEnableTooltips,
            enableGuide,
            setEnableGuide,
            hasUserGuide,
            setHasUserGuide,
            quickHelpTooltipsBag,
            setTooltipInfos,
        }}
        >
            <SvgWithCutouts
                tooltipInfos={enableGuide ? [] : Object.values(tooltipInfos)}
                style={backStyle}
                onClick={handleBackgroundClick}
            />
            <UserGuide />
            { children }
            { enableTooltips && Object.values(tooltipInfos)
                .map(tooltipInfo => <QuickHelpElement tooltipInfo={tooltipInfo} />)}
        </QuickHelpContext.Provider>
    );
};

export default QuickHelpContextProvider;
