import React, { useCallback, useContext, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import LaneCardsContext from 'Contexts/LaneCardsContext';
import { BUTTON_MAP } from 'LaneManagement/AddLane/Cards/AddLocation/BUTTON_MAP';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { Milestone } from 'shared-components/dataTypes';
import { useStyles } from './StepsWrapper.style';
import { useStyles as rootStyles } from '../AddLane.style';
import { reorderElement } from '../tools';

type Props = {
    milestonesLoading?: boolean,
    milestonesUpdating?: boolean,
    laneId: any
}
const dummyLoadingMilestones = (bones = 6) => new Array(bones).fill(0).map((it, index) => ({
    id: index + 1,
    type: 'ROAD',
    pickupLocation: null,
    deliveryLocation: null,
    location: null,
}));

const StepsWrapper = ({
    milestonesLoading = false,
    milestonesUpdating = false,
    laneId = null,
}: Props) => {
    const classes = useStyles();
    const rootClasses = rootStyles();
    const { steps, setSteps } = useContext(LaneCardsContext);
    const { t } = useCustomTranslation();
    const [reorderLoading, setReorderLoading] = useState(false);
    const {
        FlexibleRequest: updateMilestonesOrderRequest,
    } = useSecureBackendEndpoints(`lanes/${laneId}/milestones/action/reorder`).statusNotificationRequests;

    const onDragEnd = useCallback((params) => {
        const { source, destination } = params;
        const sourceIndex = source?.index;
        const destinationIndex = destination?.index;

        if (sourceIndex === destinationIndex) return;

        const prevSteps = [...steps];
        const reorderedArray = reorderElement(steps, sourceIndex, destinationIndex);

        const updatedIndicesMap = reorderedArray
            .map((m, i) => ({
                [m.index]: i,
            }))
            .reduce((a, b) => ({ ...a, ...b }), {});

        setSteps(() => reorderedArray.map((it, index) => ({ ...it, index })));

        setReorderLoading(true);

        (async () => {
            const response = await updateMilestonesOrderRequest(
                'POST',
                '',
                updatedIndicesMap,
            );

            if (response.status !== 200) {
                setSteps(() => prevSteps);
            }

            setReorderLoading(false);
        })();
    }, [steps]);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="StepComponentsDroppable">
                {/* eslint-disable-next-line no-unused-vars */}
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={[
                            classes.stepsWrapper,
                            (milestonesUpdating || reorderLoading)
                                ? rootClasses.loadingMilestones : '',
                        ].join(' ')}
                    >
                        {(milestonesLoading && !steps?.length
                            ? dummyLoadingMilestones()
                            : steps).map((step, index) => {
                            const StepComponent: any = BUTTON_MAP(t)
                                .find(it => it.milestoneType === step.type).component;

                            return (
                                <StepComponent
                                    key={step.uniqueId || `loading_milestone_${index}`}
                                    stepData={step}
                                    index={index}
                                    className={milestonesLoading
                                        ? rootClasses.stepCardLoading : ''}
                                    onChange={(newData) => setSteps((prev: Milestone[]) => {
                                        const index = prev.findIndex(prevStep => prevStep.index === step.index);
                                        const newDataArray = [...prev];

                                        newDataArray[index] = newData;
                                        return newDataArray
                                            .sort((a, b) => (a?.index > b?.index ? 1 : -1));
                                    })}
                                />
                            );
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default StepsWrapper;
