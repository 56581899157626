import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    cardContent: {
        '& tr>td:last-child': {
            padding: '0px',
        },
    },
    cardRoot: {
        paddingTop: '12px',
    },
    tableCard: {
        padding: '0 15px 15px 15px',
    },
    noDataContainer: {
        padding: '15px',
        borderRadius: '6px',
        background: theme.palette.common.white,
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    },
}));
