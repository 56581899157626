import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'left',
        font: 'normal normal normal 20px/24px Roboto',
        letterSpacing: '0.26px',
        color: theme.palette.secondary[900],
        opacity: 1,
    },
    container: {
        marginTop: '30%',
    },
    flex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        width: '36px',
        height: '36px',
        marginBottom: '15px',
        opacity: 1,
    },
    statusMessage: {
        textAlign: 'center',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0px',
        color: theme.palette.secondary[900],
        opacity: 1,
    },
}));

export default useStyles;
