import React from 'react';
import useGetServiceProvider from 'hooks/useGetServiceProvider';
import Gallery from 'shared-components/CompanyInfoComponents/Gallery';

function GalleryContainer() {
    const provider = useGetServiceProvider();

    return (
        <Gallery
            accessToChange
            gallery={provider?.gallery}
        />
    );
}

export default GalleryContainer;
