import React, {
    ReactElement,
    useContext,
    useEffect, useRef, useMemo, RefObject,
} from 'react';
import { QuickHelpContext } from '../QuickHelpContext';

export type QuickHelpTooltipInfo = {
    text: string,
    order?: number,
    link?: string,
    position?: 'left' | 'right' | 'auto',
    childOffsetPercent?: number[],
    offsetPx?: number[],
    explicitChildRef?: RefObject<any>,
    childRef?: RefObject<any>,
    backgroundOpacity?: number,
    customBackground?: string,
    customRectSize?: number[],
    customBoundingRect?: DOMRect,
    clone?: ReactElement,
    lastUpdated?: object,
    uid: string,
}
type Props = {
    tooltipInfo?: QuickHelpTooltipInfo,
    children?: ReactElement,
    updateFlag?: any,
}

const QuickHelpTooltip = ({
    tooltipInfo,
    children,
}: Props) => {
    const { setTooltipInfos, enableTooltips } = useContext(QuickHelpContext);
    const childRef = useRef(null);
    const clone = useMemo(() => {
        return tooltipInfo?.explicitChildRef ? children : React.cloneElement(children, { ref: childRef });
    }, [tooltipInfo?.explicitChildRef, children]);

    useEffect(() => {
        if (tooltipInfo?.uid && tooltipInfo?.text && (childRef.current || tooltipInfo?.explicitChildRef?.current)) {
            const uniqueId = `${tooltipInfo?.uid}_${tooltipInfo?.text.replaceAll(' ', '')}`;

            setTooltipInfos(prev => ({
                ...prev,
                [uniqueId]: { ...tooltipInfo, childRef: tooltipInfo?.explicitChildRef || childRef, clone },
            }));
            return () => {
                setTooltipInfos(prev => {
                    const { [uniqueId]: _, ...newInfos } = prev;

                    return newInfos;
                });
            };
        }
    }, [tooltipInfo?.uid, tooltipInfo?.text, tooltipInfo?.explicitChildRef, enableTooltips, childRef]);

    return clone;
};

export default QuickHelpTooltip;
