import React from 'react';
import IconButton from '@mui/material/IconButton';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import useCustomTranslation from 'hooks/useCustomTranslation';
import StyledTextField from '../StyledTextField';

type Props = {
    textFieldSample: string,
    setTextFieldSample: (textFieldSample: string) => void,
}

const TextFilter = ({
    textFieldSample,
    setTextFieldSample,
} : Props) => {
    const handleChange = (event) => setTextFieldSample(event.target.value);
    const handleOnClickClearFilter = () => setTextFieldSample('');

    const { t } = useCustomTranslation();

    return (
        <div
            style={{
                display: 'flex',
                width: '242px',
                padding: '8px',
            }}
        >
            <StyledTextField
                onChange={handleChange}
                label={t('COMMON.FILTER_SEARCH')}
                size="small"
                value={textFieldSample}
                autoFocus
            />
            <IconButton
                disabled={textFieldSample.length === 0}
                style={{ marginLeft: '5px', padding: '0' }}
                size="small"
                onClick={handleOnClickClearFilter}
            >
                <FilterAltOffIcon color="action" fontSize="small" />
            </IconButton>
        </div>
    );
};

export default TextFilter;
