import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
    .use(Backend)
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        debug: process.env.NODE_ENV === 'development',
        fallbackLng: 'en',
        lng: 'en',
        supportedLngs: ['en', 'de'],
        interpolation: { escapeValue: false },
        ns: ['translation'],
        react: {
            useSuspense: false,
        },
    });

export default i18n;
