import React from 'react';
import { QmApprovalInfo } from 'dataTypes/SecureBackend/processedData';
import useHasAccess, { userRoles } from 'hooks/useHasAccess';

import Card from 'shared-components/Card';
import { IconTooltipInfo } from 'shared-components/dataTypes';
import useCustomTranslation from 'hooks/useCustomTranslation';
import InfoContainer from './InfoContainer';
import StatusesContainer from './StatusesContainer';
import ButtonsContainer from './ButtonsContainer';
import useStyles from './ApprovalSummary.style';

type Props = {
    // container: Container,
    dataUpdateTrigger: (requiredDataUpdate: boolean) => void,
    orderNumber: string,
    serialNumber: string,
    temperatureIcon: string,
    sealApprovalsIcon: string,
    containerApprovalsIcon: string,
    palletCode: string,
    palletCode1: string,
    palletCode2: string,
    palletApprovalsIcon: string,
    pallet1ApprovalsIcon: string,
    pallet2ApprovalsIcon: string,
    sealApprovalsIconTooltipInfo: IconTooltipInfo,
    containerApprovalsIconTooltipInfo: IconTooltipInfo,
    temperatureReadoutTooltipInfo: IconTooltipInfo,
    palletApprovalsIconTooltipInfo: IconTooltipInfo,
    pallet1ApprovalsIconTooltipInfo: IconTooltipInfo,
    pallet2ApprovalsIconTooltipInfo: IconTooltipInfo,
    productReleaseId: number,
    qmApproval: QmApprovalInfo,
}

const ApprovalSummary = ({
    dataUpdateTrigger,
    orderNumber,
    serialNumber,
    temperatureIcon = '',
    sealApprovalsIcon = '',
    containerApprovalsIcon = '',
    palletCode = '',
    palletCode1 = '',
    palletCode2 = '',
    palletApprovalsIcon = '',
    pallet1ApprovalsIcon = '',
    pallet2ApprovalsIcon = '',
    containerApprovalsIconTooltipInfo,
    sealApprovalsIconTooltipInfo,
    temperatureReadoutTooltipInfo,
    palletApprovalsIconTooltipInfo,
    pallet1ApprovalsIconTooltipInfo,
    pallet2ApprovalsIconTooltipInfo,
    productReleaseId,
    qmApproval,
} : Props) => {
    const classes = useStyles();
    const hasAccess = useHasAccess();
    const displayApproveAndRejectButtons = hasAccess(userRoles.PRODUCT_RELEASE);
    const { t } = useCustomTranslation();

    return (
        <Card fullHeight>
            <div className={classes.cardTitle}>{t('COMMON.APPROVAL_SUMMARY')}</div>
            <div className={classes.flexContainer}>
                <InfoContainer
                    containerSerialNumber={serialNumber}
                    orderNumber={orderNumber}
                />
                <StatusesContainer
                    palletCode={palletCode}
                    palletCode1={palletCode1}
                    palletCode2={palletCode2}
                    // temperatureIcon={temperatureReadout.icon}
                    temperatureIcon={temperatureIcon}
                    sealIcon={sealApprovalsIcon}
                    containerIcon={containerApprovalsIcon}
                    // pallet1Icon={pallet1ApprovalsIcon}
                    // pallet2Icon={pallet2ApprovalsIcon}
                    palletIcon={palletApprovalsIcon}
                    pallet1Icon={pallet1ApprovalsIcon}
                    pallet2Icon={pallet2ApprovalsIcon}
                    containerApprovalsIconTooltipInfo={containerApprovalsIconTooltipInfo}
                    sealApprovalsIconTooltipInfo={sealApprovalsIconTooltipInfo}
                    temperatureReadoutTooltipInfo={temperatureReadoutTooltipInfo}
                    palletApprovalsIconTooltipInfo={palletApprovalsIconTooltipInfo}
                    pallet1ApprovalsIconTooltipInfo={pallet1ApprovalsIconTooltipInfo}
                    pallet2ApprovalsIconTooltipInfo={pallet2ApprovalsIconTooltipInfo}
                />
                {
                    displayApproveAndRejectButtons
                        && (
                            <ButtonsContainer
                                orderNumber={orderNumber}
                                evidenceId={productReleaseId}
                                qmApproval={qmApproval}
                                dataUpdateTrigger={dataUpdateTrigger}
                            />
                        )
                }
            </div>
        </Card>
    );
};

export default ApprovalSummary;
