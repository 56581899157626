import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    packagingItemContent: {
        padding: '16px!important',
    },
    minimizedPackagingItemContent: {
        padding: '7px 12px 8px!important',
    },
    packagingItemCard: {
        marginBottom: '12px',
    },
    packagingTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        color: theme.palette.secondary[800],
        fontSize: '18px !important',
        letterSpacing: '0.26px',
    },
    mapCard: {
        marginBottom: '12px',
    },
}));

export default useStyles;
