import React from 'react';
import { Redirect } from 'react-router-dom';
import { RouteItem } from 'dataTypes/common';
import { userRoles } from 'hooks/useHasAccess';

import EditUser from 'Administration/Users/tabs/EditUser';
import CompanyInfoOverview from './CompanyInfo/tabs/Overview';

import Sites from './Sites';
import Users from './Users';
import AddUser from './Users/tabs/AddUser';
import AddPharmaSite from './Sites/tabs/AddPharmaSite';
import EditPharmaSite from './Sites/tabs/EditPharmaSite';
import LocationCapabilities from './Sites/tabs/Capabilities';
import LocationQuestionnaire from './Sites/tabs/Questionnaire';
import LocationGallery from './Sites/tabs/Gallery';

const MyCompanyRouter: RouteItem[] = [
    {
        necessaryRoles: [],
        exact: true,
        path: '/my-company',
        component: <Redirect push to="/my-company/company/overview" />,
    },
    {
        necessaryRoles: [],
        exact: true,
        path: '/my-company/company',
        component: <Redirect push to="/my-company/company/overview" />,
    },
    {
        necessaryRoles: [],
        exact: false,
        path: '/my-company/company/overview',
        component: <CompanyInfoOverview />,
    },
    {
        sufficientRoles: [
            userRoles.CUSTOMER_ADMIN,
            userRoles.LANE_MANAGEMENT,
            userRoles.SHIPMENT_MANAGEMENT,
        ],
        exact: false,
        path: '/my-company/company/capabilities',
        component: <div>Capabilities</div>,
    },
    {
        sufficientRoles: [
            userRoles.CUSTOMER_ADMIN,
            userRoles.LANE_MANAGEMENT,
            userRoles.SHIPMENT_MANAGEMENT,
        ],
        exact: false,
        path: '/my-company/company/questionnaire',
        component: <div>Questionnaire</div>,
    },
    {
        sufficientRoles: [
            userRoles.CUSTOMER_ADMIN,
            userRoles.LANE_MANAGEMENT,
            userRoles.SHIPMENT_MANAGEMENT,
        ],
        exact: false,
        path: '/my-company/company/monitoring',
        component: <div>Monitoring</div>,
    },
    {
        sufficientRoles: [
            userRoles.SITE_MANAGEMENT,
            userRoles.CUSTOMER_ADMIN,
        ],
        exact: true,
        path: '/my-company/sites',
        component: <Sites />,
    },
    {
        necessaryRoles: [
            userRoles.CUSTOMER_ADMIN,
        ],
        exact: true,
        path: '/my-company/users',
        component: <Users />,
    },
    {
        necessaryRoles: [
            userRoles.CUSTOMER_ADMIN,
        ],
        exact: true,
        path: '/my-company/users/new',
        component: <AddUser />,
    },
    {
        necessaryRoles: [
            userRoles.CUSTOMER_ADMIN,
        ],
        exact: true,
        path: '/my-company/users/:userId',
        component: <EditUser />,
    },
    {
        sufficientRoles: [
            userRoles.CUSTOMER_ADMIN,
            userRoles.SITE_MANAGEMENT,
        ],
        exact: false,
        path: '/my-company/sites/new',
        component: <AddPharmaSite />,
    },
    {
        sufficientRoles: [
            userRoles.CUSTOMER_ADMIN,
            userRoles.SITE_MANAGEMENT,
        ],
        exact: false,
        path: '/my-company/sites/:siteId/overview',
        component: <EditPharmaSite />,
    },
    {
        necessaryRoles: [
            userRoles.SITE_MANAGEMENT,
        ],
        exact: false,
        path: '/my-company/sites/:providerId/capabilities',
        component: <LocationCapabilities />,
    },
    {
        necessaryRoles: [
            userRoles.SITE_MANAGEMENT,
        ],
        exact: false,
        path: '/my-company/sites/:providerId/questionnaire',
        component: <LocationQuestionnaire />,
    },
    {
        necessaryRoles: [
            userRoles.SITE_MANAGEMENT,
        ],
        exact: false,
        path: '/my-company/sites/:providerId/gallery',
        component: <LocationGallery />,
    },
    {
        necessaryRoles: [
            userRoles.SITE_MANAGEMENT,
        ],
        exact: false,
        path: '/my-company/sites/:providerId/documents',
        component: <div>Documents</div>,
    },
    {
        necessaryRoles: [
            userRoles.SITE_MANAGEMENT,
        ],
        exact: false,
        path: '/my-company/sites/:providerId/information-sharing',
        component: <div>Information Sharing</div>,
    },
    {
        necessaryRoles: [
            userRoles.SITE_MANAGEMENT,
        ],
        exact: false,
        path: '/my-company/sites/:providerId/lanes',
        component: <div>Lanes</div>,
    },
];

export default MyCompanyRouter;
