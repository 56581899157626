/* eslint-disable  */
export const downloadIcon = '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M220 896q-24 0-42-18t-18-42V693h60v143h520V693h60v143q0 24-18 42t-42 18H220Zm260-153L287 550l43-43 120 120V256h60v371l120-120 43 43-193 193Z" /></svg>';
export const downloadIconSVG = `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">\
<text x="50%" y="12px" dominant-baseline="middle" text-anchor="middle" font-family="Arial" font-size="8px">PNG</text>\
</svg>`;
export const downloadIconCSV = `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">\
<text x="50%" y="12px" dominant-baseline="middle" text-anchor="middle" font-family="Arial" font-size="8px">CSV</text>\
</svg>`;
export const downloadIcoSVG = `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">\
<text x="50%" y="12px" dominant-baseline="middle" text-anchor="middle" font-family="Arial" font-size="8px">SVG</text>\
</svg>`;
export const DownloadComponentIcon = (
    {
        color = 'inherit',
        size = 24,
    }
) => {
    return <svg xmlns="http://www.w3.org/2000/svg" height={size.toString()} viewBox="0 96 960 960"
                width={size.toString()} style={{
        fill: color,
    }}>
        <path
            d="M220 896q-24 0-42-18t-18-42V693h60v143h520V693h60v143q0 24-18 42t-42 18H220Zm260-153L287 550l43-43 120 120V256h60v371l120-120 43 43-193 193Z"/>
    </svg>
}
