import moment from 'moment';
import { ShipmentProductReleaseResponse } from 'dataTypes/SecureBackend/apiResponse';
import { QmApprovalInfo } from 'dataTypes/SecureBackend/processedData';
import { getIconByStatus } from 'utils/shipmentsDataProcessing';

export interface RequiredTableData {
    cellColor: string,
    shipmentNumber: string,
    packagingSerialNumber: string,
    shipmentEnd: string,
    shipmentFrom: string,
    shipmentTo: string,
    id: number,
    destinationLocation: string,
    temperatureMax: number,
    temperatureMin: number,
    temperatureRange: string,
    temperatureRangeMax: number,
    temperatureRangeMin: number,
    qmApproval: QmApprovalInfo,
    qmApprovalStatusIcon: string,
    containerStatusIcon: string,
    palletStatusIcon: string,
    palletStatus1Icon: string,
    palletStatus2Icon: string,
    sealStatusIcon: string,
    temperatureStatus: string,
    temperatureStatusIcon: string,
}

const shadesOfBlue = {
    light: '#E0F7FF',
    medium: '#C3E9F7',
    dark: '#AADBEC',
};

const getColor = (currentShipmentNumber, previousShipmentNumber, previousColor, lastNonMediumColor) => {
    if (currentShipmentNumber === previousShipmentNumber) {
        return previousColor;
    }

    if (previousColor === shadesOfBlue.light || previousColor === shadesOfBlue.dark) {
        return shadesOfBlue.medium;
    }

    return lastNonMediumColor === shadesOfBlue.light
        ? shadesOfBlue.dark
        : shadesOfBlue.light;
};

export const fetchTableData = (rawData: ShipmentProductReleaseResponse[] = []): RequiredTableData[] => {
    let lastNonMediumColor = '';

    return rawData
        .sort((a, b) => b.shipmentNumber.localeCompare(a.shipmentNumber))
        .reduce((data, rawItem) => {
            const {
                shipmentNumber = '',
                packagingSerialNumber = '',
                skyMindInfo,
                skyCoreProductRelease,
            } = rawItem;

            const {
                shipmentEnd = null,
                from: shipmentFrom = '',
                to: shipmentTo = '',
                destinationLocation = '',
                temperatureRange = '',
            } = skyMindInfo || {};

            const {
                temperatureMax = null,
                temperatureMin = null,
                temperatureRangeMax = null,
                temperatureRangeMin = null,
                containerStatus = '',
                palletStatus = '',
                palletStatus1 = '',
                palletStatus2 = '',
                qmApprovalStatus = '',
                sealStatus = '',
                temperatureStatus = '',
                skyMindId,
                qmApprovalDetails = [],
            } = skyCoreProductRelease || {};

            const cellColor = data.length === 0
                ? shadesOfBlue.light
                : getColor(
                    shipmentNumber,
                    data[data.length - 1].shipmentNumber,
                    data[data.length - 1].cellColor,
                    lastNonMediumColor,
                );

            if (cellColor !== shadesOfBlue.medium) {
                lastNonMediumColor = cellColor;
            }

            return [
                ...data,
                {
                    cellColor,
                    shipmentNumber,
                    packagingSerialNumber,
                    shipmentEnd: shipmentEnd ? moment(shipmentEnd).format('DD.MM.YYYY') : '',
                    shipmentFrom,
                    shipmentTo,
                    destinationLocation,
                    containerStatusIcon: containerStatus ? getIconByStatus(containerStatus) : null,
                    id: skyMindId,
                    palletStatusIcon: palletStatus ? getIconByStatus(palletStatus) : null,
                    palletStatus1Icon: palletStatus1 ? getIconByStatus(palletStatus1) : null,
                    palletStatus2Icon: palletStatus2 ? getIconByStatus(palletStatus2) : null,
                    qmApproval: {
                        qmApprovalComment: '',
                        qmApprovalDetails,
                        qmApprovalIcon: qmApprovalStatus ? getIconByStatus(qmApprovalStatus) : null,
                        qmApprovalStatus: '',
                        qmApprovedBy: '',
                        qmApprovedOn: '',
                    },
                    qmApprovalStatusIcon: qmApprovalStatus ? getIconByStatus(qmApprovalStatus) : null,
                    sealStatusIcon: sealStatus ? getIconByStatus(sealStatus) : null,
                    temperatureMax,
                    temperatureMin,
                    temperatureRange,
                    temperatureRangeMax,
                    temperatureRangeMin,
                    temperatureStatus,
                    temperatureStatusIcon: getIconByStatus(temperatureStatus),
                },
            ];
        }, []);
};

export const getIntermediateFilteredData = (data: RequiredTableData[], searchString = ''): RequiredTableData[] => {
    if (data.length === 0 || searchString.length === 0) {
        return data;
    }
    const searchStringLowerCase = searchString.toLowerCase();

    return data.filter(item => {
        const {
            shipmentNumber = '',
            packagingSerialNumber = '',
            shipmentFrom = '',
            shipmentTo = '',
            destinationLocation = '',
            temperatureRange = '',
        } = item;

        return shipmentNumber.toLowerCase().includes(searchStringLowerCase)
            || packagingSerialNumber.toLowerCase().includes(searchStringLowerCase)
            || shipmentFrom.toLowerCase().includes(searchStringLowerCase)
            || shipmentTo.toLowerCase().includes(searchStringLowerCase)
            || destinationLocation.toLowerCase().includes(searchStringLowerCase)
            || temperatureRange.toLowerCase().includes(searchStringLowerCase);
    });
};
