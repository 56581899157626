import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import { Geolocation } from 'dataTypes/SecureBackend/apiResponse';
import { TIME_IN_MS } from 'shared-components/constants';
import { useMemo } from 'react';
import {
    getGeolocationRequestLink,
    getTimeZoneStr,
    initialValue,
    TimeZoneInfo,
} from './lib';
import useCachedQueryRequest from '../useCachedQueryRequest';

type Loading = {
    loading: boolean,
}

type RequestOptions = {
    cacheKey: string,
    link: string,
} | null

const useGetTimeZoneInfoByGeolocation = (
    geolocation: Geolocation = null,
    time: string = null,
    utc: boolean = false,
): TimeZoneInfo & Loading => {
    const { FlexibleRequest: getTimeZoneInfo } = useSecureBackendEndpoints('timezone').requests;
    const requestOptions: RequestOptions = useMemo(() => {
        if (geolocation && time) {
            return {
                cacheKey: `lat${geolocation.latitude}lng${geolocation.longitude}\
                time${time.split('T')[0]}utc${utc ? 0 : 1}`,
                link: getGeolocationRequestLink(geolocation, time, utc),
            };
        } else return null;
    }, [geolocation, time, utc]);

    const {
        data: timeZoneInfo = initialValue,
        isLoading: loading,
    } = useCachedQueryRequest({
        key: requestOptions?.cacheKey,
        options: {
            enabled: requestOptions !== null,
            retry: true,
        },
        request: getTimeZoneInfo.bind(null, 'GET', requestOptions?.link),
        onCompleted: ({ data: result = null }) => {
            if (result) {
                const { gmtOffset = null } = result;

                return {
                    timeZoneStr: getTimeZoneStr(gmtOffset),
                    gmtOffsetInMs: gmtOffset !== null
                        ? gmtOffset * TIME_IN_MS.second
                        : null,
                };
            } else return null;
        },
    });

    return { ...timeZoneInfo, loading };
};

export default useGetTimeZoneInfoByGeolocation;
