import React, { ChangeEvent, Dispatch, SetStateAction, useCallback, useMemo, useState, useEffect } from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { FilterPanelCheckboxOptionsGroup } from 'shared-components/dataTypes';
import SideFilter, {
    AccordionOptionsGroup,
    CheckboxOption,
    FilterBlock,
    SwitchOptionItem,
} from 'shared-components/SideFilter';
import useStyles from 'TrackAndTrace/commonComponents/SideFilterComponents.style';
import { FacetDTO } from 'dataTypes/SecureBackend/apiResponse';
import { getFieldCheckboxData } from 'TrackAndTrace/Assets/lib';
import SearchPreviewTable from './SearchPreviewTable';
import { ExtractedLoggerData } from '../../lib';

type Props = {
    filterOptions: { [optionsGroupKey: string]: string[] },
    changeHighlightedText: (newHighlightedText: any) => void,
    isTableView: boolean,
    loggersFromPreviewTable: ExtractedLoggerData[],
    setLoggersFromPreviewTable: Dispatch<SetStateAction<ExtractedLoggerData[]>>,
    setShowLoggerTypes: Dispatch<SetStateAction<boolean>>,
    setShowLastConnection: Dispatch<SetStateAction<boolean>>,
    setShowAirportsInfo: Dispatch<SetStateAction<boolean>>,
    setFilterOptions: Dispatch<SetStateAction<{ [optionsGroupKey: string]: string[] }>>,
    showLastConnection?: boolean,
    showAirports?: boolean,
    showLoggerTypes?: boolean,
    setQuery: Dispatch<SetStateAction<string>>,
    facets: FacetDTO[],
    countsLoading: boolean,
    core?: string,
}

const LoggerFilterPanel = ({
    filterOptions,
    isTableView,
    changeHighlightedText,
    loggersFromPreviewTable,
    setLoggersFromPreviewTable,
    setShowLoggerTypes,
    setShowLastConnection,
    setShowAirportsInfo,
    setFilterOptions,
    showLoggerTypes,
    showLastConnection,
    showAirports,
    facets,
    countsLoading,
    setQuery,
    core = 'track-and-trace',
}: Props) => {
    const classes = useStyles();
    const { t } = useCustomTranslation();
    const [loadedFacets, setLoadedFacets] = useState<FacetDTO[]>([]);

    useEffect(() => {
        if (facets?.length > 0) {
            setLoadedFacets(facets);
        }
    }, [facets]);

    const handleChangeShowLoggerTypes = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setShowLoggerTypes(event.target.checked);
    }, []);

    const handleChangeShowLastConnection = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setShowLastConnection(event.target.checked);
    }, []);

    const handleChangeShowAirports = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setShowAirportsInfo(event.target.checked);
    }, []);

    const filterData: FilterPanelCheckboxOptionsGroup[] = useMemo<FilterPanelCheckboxOptionsGroup[]>(() => {
        return [{
            title: t('TRACK_AND_TRACE.LOGGER_TYPE'),
            options: getFieldCheckboxData(loadedFacets, 'loggerTypeCodes', t, 'LOGGER_TYPE_LABEL'),
            optionsGroupKey: 'loggerTypeCodes',
            tooltipInfo: {
                text: t('QUICK_HELP.TRACK_AND_TRACE.SELECT_PACKAGING_TYPE'),
                order: 3,
                position: 'right',
                uid: 'loggersFamilyTooltip',
            },
        }];
    }, [loadedFacets]);

    return (
        <SideFilter>
            <FilterBlock>
                <div className={classes.title}>{t('TRACK_AND_TRACE.FILTERS')}</div>

                <SearchPreviewTable
                    changeHighlightedText={changeHighlightedText}
                    isTableView={isTableView}
                    loggersFromPreviewTable={loggersFromPreviewTable || []}
                    setLoggersFromPreviewTable={setLoggersFromPreviewTable}
                    setQuery={setQuery}
                    core={core}
                />

            </FilterBlock>
            {
                filterData.map((checkboxGroup) => {
                    const { optionsGroupKey, options } = checkboxGroup;
                    const handleSelectDeselect = () => {
                        setFilterOptions(prev => {
                            const { [optionsGroupKey]: selectedOptions } = prev;

                            return selectedOptions.length === 0
                                ? { ...prev, [optionsGroupKey]: options.map(option => option.value) }
                                : { ...prev, [optionsGroupKey]: [] };
                        });
                    };

                    return (
                        <FilterBlock
                            key={checkboxGroup.title}
                        >
                            <AccordionOptionsGroup
                                title={checkboxGroup.title}
                                tooltipInfo={checkboxGroup.tooltipInfo}
                                onSelectDeselect={handleSelectDeselect}
                            >
                                {
                                    checkboxGroup.options
                                        .sort((a, b) => b.count - a.count || a.label.localeCompare(b.label))
                                        .map((option) => (
                                            <div
                                                key={`${checkboxGroup.title}_${option.label}`}
                                                style={{ marginBottom: '12px' }}
                                            >
                                                <CheckboxOption
                                                    count={option.count}
                                                    checked={filterOptions[optionsGroupKey]?.includes(option.value)}
                                                    key={option.value}
                                                    label={option.label}
                                                    optionsGroupKey={optionsGroupKey}
                                                    optionKey={option.value}
                                                    setCheckboxGroupOptions={setFilterOptions}
                                                    tooltipDescription={option.description}
                                                    countsLoading={countsLoading}
                                                />
                                            </div>
                                        ))
                                }
                            </AccordionOptionsGroup>
                        </FilterBlock>
                    );
                })
            }
            { !isTableView
            && (
                <FilterBlock>
                    <AccordionOptionsGroup
                        title={t('TRACK_AND_TRACE.DISPLAY_OPTIONS')}
                        tooltipInfo={{
                            text: t('QUICK_HELP.TRACK_AND_TRACE.DISPLAY_PACKAGING_TYPES'),
                            order: 4,
                            position: 'right',
                            uid: 'loggersFilterPanelAccordionTooltip',
                            lastUpdated: filterData,
                        }}
                    >
                        <SwitchOptionItem
                            label={t('TRACK_AND_TRACE.LOGGER_TYPE')}
                            onChange={handleChangeShowLoggerTypes}
                            defaultChecked={showLoggerTypes}
                        />
                        <SwitchOptionItem
                            label={t('TRACK_AND_TRACE.LAST_UPDATED')}
                            onChange={handleChangeShowLastConnection}
                            defaultChecked={showLastConnection}
                        />
                        <SwitchOptionItem
                            label={t('TRACK_AND_TRACE.AIRPORTS')}
                            onChange={handleChangeShowAirports}
                            defaultChecked={showAirports}
                        />
                    </AccordionOptionsGroup>
                </FilterBlock>
            )}
        </SideFilter>
    );
};

export default LoggerFilterPanel;
