import { ColumnDataType } from 'shared-components/Table/dataTypes';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { highlight } from 'utils/stringTool';

type Props = {
    data?: any,
    dataType: ColumnDataType,
    maskForHighlight?: string,
}

const DataTypes = ({
    data = null,
    dataType,
    maskForHighlight = '',
} : Props) => {
    const { t } = useCustomTranslation();

    switch (dataType) {
    case 'number':
        return parseFloat(data);
    case 'label':
        return t(data);
    case 'custom':
    case 'stretchedCell':
    case 'date':
    case 'text':
        return data;
    case 'highlightedText':
        return (data && maskForHighlight) ? highlight(data, maskForHighlight) : data;
    default:
        return null;
    }
};

export default DataTypes;
