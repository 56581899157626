import React from 'react';
import Button from '@mui/material/Button';
import { QuestionnaireItemAnswer } from 'shared-components/dataTypes';
import useCustomTranslation from 'hooks/useCustomTranslation';
import QuestionGroup from './components/QuestionGroup';
import Audits from './components/Audits';
import QuestionnaireCard from './components/QuestionnaireCard';

import useStyles from './Questionnaire.style';

type Props = {
    companyInfo: any,
    answers: QuestionnaireItemAnswer[],
    setAnswers: (answers: QuestionnaireItemAnswer[]) => void,
    questionnaireBasedOnAudit: string[],
    setQuestionnaireBasedOnAudit: (auditAnswer: string[]) => void,
}

const Questionnaire = ({
    companyInfo,
    answers,
    setAnswers,
    questionnaireBasedOnAudit,
    setQuestionnaireBasedOnAudit,
} : Props) => {
    const classes = useStyles();
    const { t } = useCustomTranslation();
    const {
        byCategoryAndSubCategory = null,
        byCategoryAndSubCategoryLimited = null,
        statisticsByCategory = null,
        statisticsBySubCategory = null,
    } = companyInfo.questionnaire;

    if (companyInfo.questionnaire) {
        return (
            <div>

                <div className={classes.topCards}>
                    <Audits
                        audited={companyInfo.audited}
                        auditType={companyInfo.auditType}
                        auditedBy={companyInfo.auditedBy.fullName}
                        auditedOn={companyInfo.auditedOn}
                        auditStatusHistory={companyInfo.auditStatusHistory}
                        setQuestionnaireBasedOnAudit={setQuestionnaireBasedOnAudit}
                    />
                    <QuestionnaireCard
                        answers={answers}
                        companyId={companyInfo.id}
                        statusHistory={companyInfo.questionnaireStatusHistory}
                        questionnaireBasedOnAudit={questionnaireBasedOnAudit}
                        questionnaireStatus={companyInfo.questionnaireStatus}
                    />
                </div>
                {
                    Object.keys(byCategoryAndSubCategory).map((category) => {
                        return (
                            <QuestionGroup
                                answers={answers}
                                key={category}
                                mainCategory={category}
                                mainCategoryStatistic={statisticsByCategory[category]}
                                setAnswers={setAnswers}
                                subCategoriesLimited={byCategoryAndSubCategoryLimited[category]}
                                statisticsBySubCategory={statisticsBySubCategory[category]}
                                subCategories={byCategoryAndSubCategory[category]}
                            />
                        );
                    })
                }
                <div className={classes.buttonContainer}>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.submitButton}
                    >
                        {t('MY_COMPANY.SUBMIT_FOR_REVIEW')}
                    </Button>
                </div>
            </div>
        );
    }

    return null;
};

export default Questionnaire;
