import { LayoutContext } from 'Contexts/LayoutContext';
import { useContext } from 'react';

const useAvailableHeight = () => {
    const {
        availableHeight,
    } = useContext(LayoutContext);

    return availableHeight;
};

export default useAvailableHeight;
