import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    pageContent: {
        display: 'grid',
        gridTemplateColumns: '2fr 1fr 3fr',
        padding: '12px',
        gridGap: '12px',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    pageContentButtons: {
        display: 'grid',
        gridTemplateColumns: '3fr 3fr',
        gridGap: '12px',
    },
    buttonContainer: {
        display: 'flex',
        marginTop: '28px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    approveButton: {
        minWidth: '90px',
        height: '36px',
        margin: '0 7px',
        textTransform: 'uppercase',
    },
    rejectButton: {
        width: '90px',
        height: '36px',
        margin: '0 7px',
        textTransform: 'uppercase',
    },
}));

export default useStyles;
