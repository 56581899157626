import React, {
    useState, useMemo, useCallback, useRef, useEffect,
} from 'react';
import useStyles from 'shared-components/CompanyInfoComponents/AdministrationCompany/GeofencesCard/GeofencesCard.style';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { LatLng } from 'dataTypes/common';
import useScreenSize from 'hooks/useScreenSize';
import CustomModal from 'shared-components/CustomModal';
import { LoadScriptNext, GoogleMap, DrawingManager, Polygon } from '@react-google-maps/api';
import { LIBRARIES, MAP_CONFIG_DEFAULT, ZOOM_DEFAULTS } from 'shared-components/constants';
import Button from '@mui/material/Button';
import { TextField, useTheme } from '@mui/material';
import { Geofence } from 'shared-components/CompanyInfoComponents/AdministrationCompany/GeofencesCard/GeofencesCard';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import useStatusStateProcessOptions from 'hooks/useStatusStateProcessOptions';
import { validatePolygon } from './lib';

type Props = {
    open: boolean,
    setOpen: (arg: boolean) => void,
    onGeofenceAdded: (path: LatLng[], name: string, id?: number) => void,
    geofenceData: Geofence,
}
const {
    REACT_APP_GOOGLE_MAP_KEY: googleMapKey,
} = process.env;

const GeofenceModal = ({
    open = false,
    setOpen,
    onGeofenceAdded,
    geofenceData = null,
}: Props) => {
    const classes = useStyles();
    const { t } = useCustomTranslation();
    const [polygonPaths, setPolygonPaths] = useState<LatLng[]>([]);
    const { currentSize } = useScreenSize();
    const polygonRef = useRef(null);
    const [gMap, setGMap] = useState(null);
    const {
        setErrorStatus,
    } = useStatusStateProcessOptions();
    const listenersRef = useRef([]);
    const [geofenceName, setGeofenceName] = useState('');
    const theme = useTheme<SkycellThemeInterface>();
    const handleClose = useCallback(() => {
        setPolygonPaths([]);
        setGeofenceName('');
        setOpen(false);
    }, [setOpen]);
    const handleClickAdd = useCallback(() => {
        onGeofenceAdded(polygonPaths, geofenceName);
        handleClose();
    }, [setOpen, handleClose, polygonPaths, geofenceName]);

    const defaultMapConfig = useMemo(() => {
        return {
            containerStyle: {
                height: '50vh',
                width: '100%',
            },
            zoom: 5,
            backgroundColor: 'unset',
        };
    }, []);
    const pathValidated = useMemo(() => {
        return polygonPaths.length !== 0 ? validatePolygon(polygonPaths, setErrorStatus) : true;
    }, [polygonPaths]);
    const polygonOptions = useMemo(() => ({
        editable: true,
        draggable: true,
        fillColor: pathValidated ? theme.palette.primary.deepBlue : theme.palette.common.red,
        fillOpacity: 0.4,
        strokeWeight: 3,
        strokeColor: pathValidated ? theme.palette.primary.deepBlue : theme.palette.common.red,
    }), [theme, pathValidated]);

    useEffect(() => {
        if (!open) {
            setPolygonPaths([]);
        }
    }, [open]);
    useEffect(() => {
        if (geofenceData && gMap) {
            const lats = geofenceData.coordinates.map(it => it.latitude);
            const lngs = geofenceData.coordinates.map(it => it.longitude);
            const bounds = {
                south: lats.length ? Math.min(...lats) : null,
                west: lngs.length ? Math.min(...lngs) : null,
                north: lats.length ? Math.max(...lats) : null,
                east: lngs.length ? Math.max(...lngs) : null,
            };

            gMap.fitBounds(bounds);

            setGeofenceName(geofenceData.name);
            setPolygonPaths(geofenceData?.coordinates
                ?.map(({ latitude: lat, longitude: lng }) => ({ lat, lng })));
        } else {
            setPolygonPaths([]);
            setGeofenceName('');
        }
    }, [geofenceData, gMap]);
    const onPolygonComplete = (polygon) => {
        const pathArray = polygon.getPath().getArray();
        const path: LatLng[] = pathArray.map((latLng) => {
            return { lat: latLng.lat(), lng: latLng.lng() };
        });

        if (validatePolygon(path, setErrorStatus)) {
            setPolygonPaths(path);
        }

        polygon.setMap(null);
    };
    const onDeletePolygon = () => {
        setPolygonPaths([]);
    };

    const onEdit = useCallback(() => {
        if (polygonRef.current) {
            const nextPath = polygonRef.current
                .getPath()
                .getArray()
                .map(latLng => {
                    return { lat: latLng.lat(), lng: latLng.lng() };
                });

            setPolygonPaths(nextPath);
        }
    }, [setPolygonPaths, polygonRef.current]);

    const onLoad = useCallback(nextPolygon => {
        polygonRef.current = nextPolygon;
        const path = nextPolygon.getPath();

        listenersRef?.current?.push(
            path.addListener('set_at', onEdit),
            path.addListener('insert_at', onEdit),
            path.addListener('remove_at', onEdit),
        );
    },
    [onEdit]);

    const onUnmount = useCallback(() => {
        listenersRef.current.forEach(lis => lis.remove());
        polygonRef.current = null;
    }, []);

    const handleLoad = useCallback((gMap) => {
        setGMap(gMap);
    }, []);

    return (
        <CustomModal
            backdrop
            className={classes.modalWidth}
            open={open}
            onClose={handleClose}
            title={t('COMMON.ADD_A_GEOFENCE')}
        >
            <div className={classes.textFieldWrapper}>
                <TextField
                    className={classes.textField}
                    fullWidth
                    label={t('COMMON.NAME')}
                    value={geofenceName}
                    onChange={(event) => setGeofenceName(event.target.value)}
                    variant="standard"
                    size="small"
                    inputProps={{
                        className: classes.textFieldText,
                    }}
                />
            </div>
            <LoadScriptNext
                googleMapsApiKey={googleMapKey}
                libraries={LIBRARIES}
                mapIds={['c5af2a2e4049bce5']}
            >
                <GoogleMap
                    key="GoogleMapGeofence"
                    mapContainerStyle={defaultMapConfig.containerStyle}
                    zoom={MAP_CONFIG_DEFAULT.zoom}
                    center={MAP_CONFIG_DEFAULT?.center || null}
                    onLoad={handleLoad}
                    options={{
                        mapId: 'c5af2a2e4049bce5',
                        disableDefaultUI: true,
                        fullscreenControl: true,
                        backgroundColor: MAP_CONFIG_DEFAULT.backgroundColor,
                        minZoom: ZOOM_DEFAULTS[currentSize], // This will probably change,
                    }}
                >
                    {
                        polygonPaths.length === 0 && (
                            <DrawingManager
                                onPolygonComplete={onPolygonComplete}
                                options={{
                                    drawingControl: true,
                                    drawingControlOptions: {
                                        // @ts-ignore
                                        position: window.google.maps.ControlPosition.TOP_CENTER,
                                        // @ts-ignore
                                        drawingModes: ['polygon'],
                                    },
                                    polygonOptions,
                                }}
                            />
                        )
                    }
                    {
                        polygonPaths.length > 0 && (
                            <Polygon
                                paths={polygonPaths}
                                options={polygonOptions}
                                onMouseUp={onEdit}
                                onDragEnd={onEdit}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                            />
                        )
                    }
                    {
                        polygonPaths.length !== 0 && (
                            <Button
                                className={[classes.rejectButton, classes.mapButton].join(' ')}
                                onClick={onDeletePolygon}
                            >
                                {t('COMMON.RESET')}
                            </Button>
                        )
                    }

                </GoogleMap>

            </LoadScriptNext>
            <div className={classes.buttonContainer}>
                <Button
                    className={classes.approveButton}
                    onClick={handleClickAdd}
                    disabled={geofenceName.length < 1 || polygonPaths.length < 3 || !pathValidated}
                >
                    {geofenceData ? t('COMMON.APPLY') : t('MY_COMPANY.ADD')}
                </Button>
                <Button
                    className={classes.rejectButton}
                    onClick={handleClose}
                >
                    {t('COMMON.CANCEL')}
                </Button>
            </div>
        </CustomModal>
    );
};

export default GeofenceModal;
