import React, { ChangeEvent } from 'react';
import Switch from '@mui/material/Switch';
import QuickHelpTooltip, { QuickHelpTooltipInfo } from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import useStyles from './TitleWithSwitch.style';

type Props = {
    isRightSwitchPosition?: boolean,
    leftSwitchPositionTitle: string,
    onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void,
    rightSwitchPositionTitle: string,
    title?: string,
    tooltipInfo?: QuickHelpTooltipInfo,
}

const TitleWithSwitch = ({
    isRightSwitchPosition = false,
    leftSwitchPositionTitle = '',
    onChange,
    rightSwitchPositionTitle = '',
    title = null,
    tooltipInfo,
} : Props) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            {
                title && <div>{ title }</div>
            }
            <QuickHelpTooltip
                tooltipInfo={tooltipInfo}
            >
                <div className={classes.switchContainer}>
                    <div className={isRightSwitchPosition ? classes.inactive : classes.active}>
                        {leftSwitchPositionTitle}
                    </div>
                    <Switch
                        checked={isRightSwitchPosition}
                        color="primary"
                        classes={{
                            track: classes.switch_track,
                            switchBase: classes.switch_base,
                        }}
                        onChange={onChange}
                        size="medium"
                    />
                    <div className={isRightSwitchPosition ? classes.active : classes.inactive}>
                        {rightSwitchPositionTitle}
                    </div>
                </div>
            </QuickHelpTooltip>
        </div>
    );
};

export default TitleWithSwitch;
