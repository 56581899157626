import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    preview: (props: { searchBoxLeft:number }) => ({
        position: 'absolute',
        opacity: 0,
        pointerEvents: 'none',
        zIndex: 200,
        transition: '100ms ease',
        width: 'auto',
        maxWidth: `calc(100vw - ${props.searchBoxLeft + 40}px)`,
        marginTop: '-40px',
        marginLeft: '270px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 'calc( 100vw - 80px )',
        },
    }),
    open: () => ({
        opacity: '1!important',
        pointerEvents: 'unset',
    }),
    paddedTable: {
        padding: '0 10px 10px 10px',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '10px 10px 0 0',
    },
    clearSearchButton: {
        textTransform: 'none',
    },
}));

export default useStyles;
