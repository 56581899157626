import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.secondary[600],
        cursor: 'pointer',
        fontSize: '23px',
        height: '23px',
        width: '23px',
        '&:hover': {
            color: theme.palette.secondary[800],
        },
    },
    tooltipText: {
        display: 'flex',
        flexDirection: 'column',
        '& img': {
            width: '30px',
            marginLeft: '-4px',
            marginRight: '6px',
        },
        '& span': {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginBottom: '4px',
        },
    },
    cardBody: {
        paddingTop: '14px',
    },
    cardBodyDivided: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '20px',
        '&>div': {
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridGap: '20px',
        },
    },
    informationCard: {
        gridColumn: 'span 3',
    },

}));
