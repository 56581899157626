import React, {
    Dispatch,
    SetStateAction,
    useCallback,
} from 'react';
import { InputAdornment, TextField, useTheme } from '@mui/material';
import Search from '@mui/icons-material/Search';
import useStyles from '../SideFilter.style';

type Props = {
    placeholder?: string,
    searchString: string,
    setSearchString: Dispatch<SetStateAction<string>>,
}

const SearchTextField = ({
    placeholder = '',
    searchString = '',
    setSearchString,
} : Props) => {
    const classes = useStyles();
    const theme = useTheme();

    const handleClick = useCallback((e) => e.stopPropagation(), []);

    const searchStringChanged = (event) => {
        setSearchString(event.target.value);
    };

    return (
        <TextField
            className={classes.searchTextFieldInput}
            id="TextFieldInput"
            onInput={searchStringChanged}
            value={searchString}
            placeholder={placeholder}
            variant="outlined"
            onClick={handleClick}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search style={{ color: theme.palette.common.black }} />
                    </InputAdornment>
                ),
                className: classes.searchTextFieldInput,
            }}
        />
    );
};

export default SearchTextField;
