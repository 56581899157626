import React from 'react';
import Card from 'shared-components/Card';
import { AccordionDetails, Accordion, AccordionSummary } from '@mui/material';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { Milestones } from 'dataTypes/SecureBackend/apiResponse/Shipment';
import ShipmentStepHeader from './ShipmentStepHeader';
import ShipmentStepLocation from './ShipmentStepLocation';
import useStyles from './ShipmentSteps.style';

type Props = {
    className?: string,
    milestones?: Milestones[],
}

const ShipmentSteps = ({
    milestones = [],
    className = '',
}: Props) => {
    const classes = useStyles();
    const { t } = useCustomTranslation();
    const sortedMilestones = [...milestones].sort((a, b) => Number(a.index) - Number(b.index));

    return (
        <Card
            className={`${classes.card} ${className}`}
            title={t('TRACK_AND_TRACE.SHIPMENT_TIMELINE')}
            zeroSidePadding
            zeroVerticalPadding
            titleClass={classes.title}
        >
            <div className={classes.stepsWrapper}>
                {
                    sortedMilestones.map((milestone, index) => {
                        const key = `orderStepCard-${index}`;

                        return (
                            <div key={key}>
                                <Accordion
                                    elevation={0}
                                    classes={{
                                        root: classes.accordionRoot,
                                    }}
                                    defaultExpanded={
                                        sortedMilestones
                                            .find((item, i, milestones) => milestones.length !== 0
                                                && item.progress.status !== 'COMPLETED') === milestone
                                    }
                                >
                                    <AccordionSummary classes={{
                                        content: classes.accordionTitle,
                                        root: classes.accordionTitleRoot,
                                        expanded: classes.accordionTitleExpanded,
                                    }}
                                    >
                                        <ShipmentStepHeader
                                            stepType={milestone.type}
                                            stepStatus={milestone.progress.status}
                                        />
                                    </AccordionSummary>
                                    <AccordionDetails
                                        classes={{
                                            root: classes.accordionDetailsRoot,
                                        }}
                                    >
                                        <div className={classes.orderStepLocationsWrapper}>
                                            <ShipmentStepLocation
                                                key={`milestone-${0}`}
                                                dash={false}
                                                milestoneInfo={milestone}
                                                stepStatus={milestone.progress.status}
                                            />
                                            <ShipmentStepLocation
                                                key={`milestone-${1}`}
                                                dash
                                                milestoneInfo={milestone}
                                                stepStatus={milestone.progress.status}
                                            />
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        );
                    })
                }
            </div>
        </Card>

    );
};

export default ShipmentSteps;
