import React, {
    useEffect,
    useState,
} from 'react';
import { PharmaSite, ServiceProviderSite } from 'dataTypes/SecureBackend/apiResponse';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';

import PharmaSiteCard from './components/PharmaSite';
import ServiceProviderSiteCard from './components/ServiceProviderSite';

const Sites = () => {
    const { FlexibleRequest: getPharmaSites } = useSecureBackendEndpoints('pharma-companies').requests;
    const { FlexibleRequest: getServiceProviderSites } = useSecureBackendEndpoints('service-provider-companies')
        .requests;

    const [sites, setSites] = useState<(PharmaSite | ServiceProviderSite)[]>([]);
    const { company } = useCurrentUserContext();

    useEffect(() => {
        if (company?.companyType) {
            (async () => {
                try {
                    const response = company.companyType === 'PHARMA'
                        ? (await getPharmaSites('GET', `/${company.id}/pharma-sites`)).data
                        : (await getServiceProviderSites('GET', `/${company.id}/service-provider-sites`)).data;

                    setSites(response);
                } catch (error) {
                    setSites([]);
                    global.console.log(error);
                }
            })();
        }
    }, [company?.companyType]);

    if (company.companyType === 'PHARMA') {
        return (
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gridGap: '12px',
                    padding: '12px',
                }}
            >
                {
                    sites.map(site => {
                        return (
                            <PharmaSiteCard
                                address={site.address}
                                key={site.id}
                                link={`/my-company/sites/${site.id}/overview`}
                                name={site.name}
                            />
                        );
                    })

                }
            </div>
        );
    }

    if (company.companyType === 'SERVICE_PROVIDER') {
        return (
            <div style={{ padding: '12px' }}>
                {
                    sites.map(site => {
                        return (
                            <ServiceProviderSiteCard
                                address={site.address}
                                certificates={[]}
                                key={site.id}
                                link={`/my-company/sites/${site.id}/overview`}
                                name={site.name}
                            />
                        );
                    })

                }
            </div>
        );
    }

    return null;
};

export default Sites;
