import React, { ReactNode, useMemo } from 'react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import QuickHelpTooltip, { QuickHelpTooltipInfo } from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useStyles from '../SideFilter.style';

type Props = {
    children: ReactNode,
    tooltipInfo?: QuickHelpTooltipInfo,
    title?: string,
    onSelectDeselect?: () => void,
    className?: string,
    alwaysOpen?: boolean,
}

const AccordionOptionsGroup = ({
    children = null,
    tooltipInfo = { text: '', uid: 'accordionTooltip' },
    title = '',
    onSelectDeselect = null,
    className,
    alwaysOpen = false,
} : Props) => {
    const classes = useStyles();
    const { t } = useCustomTranslation();

    const additionalProps = useMemo(() => ({
        ...(alwaysOpen ? { expanded: true } : {}),
    }), [alwaysOpen]);

    return (
        <QuickHelpTooltip
            tooltipInfo={tooltipInfo}
        >

            <Accordion
                className={classes.accordionStyle}
                defaultExpanded
                {...additionalProps}
            >
                <AccordionSummary
                    className={classes.accordionSummaryStyle}
                    expandIcon={alwaysOpen ? <></> : <ExpandMore className={classes.expandMoreStyle} />}
                    style={{ minHeight: '16px' }}
                >
                    <div className={classes.title}>{title}</div>
                </AccordionSummary>
                <AccordionDetails className={[classes.accordionDetailsStyle, className || ''].join(' ')}>
                    {children}
                    {
                        onSelectDeselect && (
                            <div className={classes.selectDeselect} onClick={onSelectDeselect}>
                                {t('COMMON.SELECT_DESELECT_ALL')}
                            </div>
                        )
                    }
                </AccordionDetails>
            </Accordion>
        </QuickHelpTooltip>

    );
};

export default AccordionOptionsGroup;
