import { makeStyles } from '@mui/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const useStyles = makeStyles((theme:SkycellThemeInterface) => ({
    formControlLabelContainer: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '-7px',
    },
    formControlLabelStyle: {
        font: 'normal 14px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.common.black,
        opacity: 1,
    },
    formControlLabelStyleSelected: {
        fontWeight: 'bold',
    },
    checkboxStyle: {
        margin: '0 9px',
        padding: '0',
    },
    root: {
        '&$checked': {
            color: theme.palette.primary[400],
        },
    },
    checked: {},
    controlHeaderTableCell: {
        paddingLeft: '12px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        height: '18px',
        color: theme.palette.secondary[600],
        opacity: 1,
        alignItems: 'center',
        whiteSpace: 'nowrap',
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '-11px',
        paddingRight: '16px',
        marginBottom: '7px',
    },
    controlHeaderButton: {
        color: theme.palette.secondary[600],
        padding: 0,
        width: '25px',
        minWidth: '0',
        marginRight: '6px',
    },
    headerIcon: {
        marginRight: '0',
    },
    expansion: {
        padding: '14px 0',
        cursor: 'auto',
        // marginLeft: '10%',
    },
    greeting: {
        marginBottom: '20px',
    },
    goodbye: {
        marginTop: '20px',
    },
    a: {
        textDecoration: 'none',
    },
    link: {
        margin: '0 5px',
        color: theme.palette.primary.main,
        textDecoration: 'underline',
    },
    messageContent: {
        display: 'block',
    },
    expansionRoot: {
        background: theme.palette.secondary['50'],
    },
    contentCard: {
        margin: '-7px 12px -7px 0',
    },
    activeHeaderIcon: {
        color: theme.palette.primary.deepBlue,
    },
    inactiveHeaderIcon: {
        color: theme.palette.secondary[400],
    },
    headerTitle: {
        textAlign: 'left',
        textTransform: 'inherit',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    selectShade: {
        background: theme.palette.primary[0],
        marginTop: '7px',
        marginBottom: '7px',
    },
    selectOption: {
        cursor: 'pointer!important',
    },
    tableRow: {
        width: '100%',
    },
    dropdownContainer: {
        marginRight: theme.spacing(2),
    },
    selectDropdown: {
        minWidth: 120,
        cursor: 'pointer',
        '& .MuiSelect-menu': {
            cursor: 'pointer',
        },
    },
    selectMenu: {
        cursor: 'pointer',
    },
    selectIcon: {
        color: theme.palette.primary.main,
    },
    noBars: {
        '&:after': {
            display: 'none',
        },
    },
    button: {
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: '14px',
        letterSpacing: '0.09em',
        textAlign: 'left',
        color: theme.palette.primary.deepBlue,
        background: 'transparent',
        '&:hover': {
            background: 'transparent',
            opacity: 1,
        },
        borderRadius: '0!important',
        padding: '8px 16px ',
        textTransform: 'uppercase',
    },
    buttonPrimary: {
        color: theme.palette.common.white,
        background: theme.palette.primary.deepBlue,
        '&:hover': {
            background: theme.palette.primary.deepBlue,
        },
    },
    buttonGhost: {
        boxShadow: 'none',
        '&:hover': {
            background: 'rgba(25, 118, 210, 0.04)',
            boxShadow: 'none',
        },
    },
    dialogContainer: {
        padding: '14px',
    },
    dialogTitle: {
        fontFamily: 'Roboto',
        fontSize: '16px',
        '& h2': {
            fontSize: '1rem',
            fontWeight: 'bold',
        },
        letterSpacing: '0.03em',
        textAlign: 'left',
        color: theme.palette.secondary[600],
    },
    dialogText: {
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        fontSize: '16px',
        letterSpacing: '0.03em',
        textAlign: 'left',
        color: theme.palette.secondary[600],
    },
    dialogWidthSm: {
        maxWidth: '400px',
    },
}));

export default useStyles;
