import React from 'react';
import useGetServiceProvider from 'hooks/useGetServiceProvider';
import Capabilities from 'shared-components/CompanyInfoComponents/Capabilities';

function CapabilitiesContainer() {
    const provider = useGetServiceProvider();

    return (
        <Capabilities
            buttonBack={{
                label: 'Back to My company location',
                to: '/my-company/locations',
            }}
            companyInfo={provider}
            showTooltip
        />
    );
}

export default CapabilitiesContainer;
