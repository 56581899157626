import React from 'react';
import { Redirect } from 'react-router-dom';
import { RouteItem } from 'dataTypes/common';
import { userRoles } from 'hooks/useHasAccess';

import Companies from './Companies';
import AddCompany from './Companies/tabs/AddCompany';
import EditCompany from './Companies/tabs/EditCompany';
import ServiceProviderSites from './ServiceProviderSites';
import AddServiceProviderSite from './ServiceProviderSites/tabs/AddServiceProviderSite';
import EditServiceProviderSite from './ServiceProviderSites/tabs/EditServiceProviderSite';
import PharmaSites from './PharmaSites';
import AddPharmaSite from './PharmaSites/tabs/AddPharmaSite';
import EditPharmaSite from './PharmaSites/tabs/EditPharmaSite';
import Users from './Users';
import AddUser from './Users/tabs/AddUser';
import EditUser from './Users/tabs/EditUser';

const base = '/administration';

const AdministrationRouter: RouteItem[] = [
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        exact: true,
        path: base,
        component: <Redirect push to="/administration/companies" />,
    },
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        exact: true,
        path: `${base}/companies`,
        component: <Companies />,
    },
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        exact: false,
        path: `${base}/companies/add`,
        component: <AddCompany />,
    },
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        exact: false,
        path: `${base}/companies/:companyId`,
        component: <EditCompany />,
    },
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        exact: true,
        path: `${base}/service-provider-sites`,
        component: <ServiceProviderSites />,
    },
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        exact: true,
        path: `${base}/service-provider-sites/add`,
        component: <AddServiceProviderSite />,
    },
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        exact: true,
        path: `${base}/service-provider-sites/:serviceProviderSiteId`,
        component: <EditServiceProviderSite />,
    },
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        exact: true,
        path: `${base}/pharma-sites`,
        component: <PharmaSites />,
    },
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        exact: true,
        path: `${base}/pharma-sites/add`,
        component: <AddPharmaSite />,
    },
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        exact: false,
        path: `${base}/pharma-sites/:pharmaSiteId`,
        component: <EditPharmaSite />,
    },
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        exact: true,
        path: `${base}/users`,
        component: <Users />,
    },
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        exact: false,
        path: `${base}/users/add`,
        component: <AddUser />,
    },
    {
        necessaryRoles: [userRoles.SKYCELL_ADMIN],
        exact: false,
        path: `${base}/users/:userId`,
        component: <EditUser admin />,
    },
];

export default AdministrationRouter;
