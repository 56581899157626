import useGetCommonData from 'hooks/useGetCommonData';
import React, { useMemo, useState, useEffect } from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import Card from 'shared-components/Card';
import { LaneObject, Milestone } from 'shared-components/dataTypes';
import { nonNullKey, nonNullObject } from 'shared-components/common';
import StatusInfo from 'shared-components/StatusInfo';
import Table, { useTableSideFilter } from 'shared-components/Table';
import { PageWithFilter } from 'Layout';
import moment from 'moment';
import { getRequestActualStatus } from 'utils/stringTool';
import { useStyles } from 'LaneManagement/Lanes/Lanes.style';
import useGetEntity from 'hooks/useGetEntity';
import { Company } from 'dataTypes/SecureBackend/apiResponse';
import { ENTITY_TYPE } from 'hooks/useGetEntity/useGetEntity';
import CommonPagination from 'shared-components/CommonPagination';
import lanesTableColumns from './lanesTableColumns';

export const extractLocationName = (location) => {
    if (location) {
        const locationData = {
            ...nonNullObject(location),
            type: nonNullKey(location),
        };

        return locationData?.serviceProviderSite
            || locationData?.name
            || locationData?.addressLine1
            || (locationData?.airport ? `${locationData?.airport?.code} | ${locationData?.airport?.name}` : '')
            || (locationData?.seaport ? `${locationData?.seaport?.code} | ${locationData?.seaport?.name}` : '');
    } else {
        return null;
    }
};

export const sumMilestonesDurations = (milestones: Milestone[], t = word => word) => {
    const sum = milestones
        .map(m => m.activities)
        .flat()
        .map(activity => activity?.duration || 0)
        .reduce((a, b) => a + b, 0);

    if (sum === 0) return 'n/a';
    const rawMinutes = sum / 60;
    const rawHours = Math.floor(rawMinutes / 60);
    const hours = rawHours !== 0 ? `${rawHours}${t('TIME.H')} ` : '';
    const minutes = rawMinutes % 60 !== 0 ? `${rawMinutes % 60}${t('TIME.M')}` : '';

    return `${hours}${minutes}`;
};
export const bakeLanes = (lanes, t, getEntity, entityFromAddressId) => {
    const rawLanes: LaneObject[] = (lanes || [])
        .sort((a, b) => {
            const aDate = moment(a.modifiedOn, 'YYYY-MM-DDTHH:mm:ss').valueOf();
            const bDate = moment(b.modifiedOn, 'YYYY-MM-DDTHH:mm:ss').valueOf();

            return bDate - aDate;
        });

    return rawLanes.map(lane => {
        const {
            loadingLocation,
            unloadingLocation,
            milestones,
            originAirportCode,
            destinationAirportCode,
            skyMindId,
            fourthPartyLogisticsCompanyId,
            skyMindInfo,
            status,
            ...restInfo
        } = lane;

        const fourthPartyLogisticsCompany: Company = getEntity(
            fourthPartyLogisticsCompanyId,
            ENTITY_TYPE.COMPANY,
        );
        const loadingLocationEntity = entityFromAddressId(loadingLocation);
        const unloadingLocationEntity = entityFromAddressId(unloadingLocation);
        const handoverPoint = skyMindInfo?.handoverPoint?.addressLine1;
        const collectionDropoffPoint = skyMindInfo?.collectionDropoffPoint?.addressLine1;

        return {
            ...restInfo,
            fourthPartyLogistics: fourthPartyLogisticsCompany?.name
                || skyMindInfo?.customerExecution?.addressLine1 || ' ',
            isSkycellManaged: skyMindId
                ? 'Yes' : 'No',
            originAirportCode,
            status: t(`LANE_STATUS.${status}`),
            destinationAirportCode,
            loadingLocation: extractLocationName(loadingLocationEntity) || handoverPoint,
            unloadingLocation: extractLocationName(unloadingLocationEntity)
                || collectionDropoffPoint,
            handoverPoint,
            collectionDropoffPoint,
            milestones: sumMilestonesDurations(milestones, t),
        };
    });
};

const PER_PAGE = 15;
const Lanes = () => {
    const { t } = useCustomTranslation();
    const classes = useStyles();
    const [currentPage, setCurrentPage] = useState(1);

    const {
        data: rawLanes = [],
        status: rawLanesStatus,
    } = useGetCommonData('lanes', { postProcess: it => it.resultList, cache: false });
    const {
        getEntity,
        dataLoaded,
        entityFromAddressId,
    } = useGetEntity();
    const [filteredLanes, setFilteredLanes] = useState([]);

    const status = useMemo(() => getRequestActualStatus(rawLanesStatus),
        [rawLanesStatus]);
    const lanes = useMemo(
        () => (dataLoaded ? bakeLanes([...rawLanes], t, getEntity, entityFromAddressId) : []),
        [rawLanes, dataLoaded],
    );

    const columns = useMemo(() => lanesTableColumns(t), [status]);

    useEffect(() => {
        setCurrentPage(1);
    }, [filteredLanes]);
    const {
        component: FilterComponent,
        selectedFilterOptions,
    } = useTableSideFilter({
        columns,
        data: lanes,
        setFilteredData: setFilteredLanes,
    });

    return (
        <PageWithFilter>
            {FilterComponent}
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: '10px' }}>
                {
                    status === 'SUCCESS'
                        ? (
                            <Table
                                noDataText={t('LANE_MANAGEMENT.NO_LANES')}
                                tableMaxHeight="100%"
                                columns={columns}
                                data={filteredLanes.filter((it, i) => {
                                    const page = currentPage - 1;

                                    return i >= page * PER_PAGE && i < page * PER_PAGE + PER_PAGE;
                                })}
                                maskForHighlight={`${selectedFilterOptions?.laneNumber?.[0] }`}
                                rowLinkTemplate="/lane-management/lanes/:id/definition"
                                classNames={{
                                    noDataContainer: classes.noDataContainer,
                                }}
                                title={t('MENU_ITEMS.LANES')}
                            />
                        )
                        : (
                            <Card
                                zeroVerticalPadding
                                zeroSidePadding
                                className={classes.cardContent}
                                contentClass={classes.cardRoot}
                            >
                                <StatusInfo status={status} />
                            </Card>
                        )
                }
                {
                    filteredLanes?.length > 0 && (
                        <div style={{ flex: 1, display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                            <CommonPagination
                                currentPage={currentPage}
                                shipmentsCount={filteredLanes?.length}
                                setCurrentPage={setCurrentPage}
                                objectNamePlural={t('MENU_ITEMS.LANES')}
                                pageSize={PER_PAGE}
                            />
                        </div>
                    )
                }
            </div>
        </PageWithFilter>
    );
};

export default Lanes;
