import { RouteItem } from 'dataTypes/common';
import React, { useEffect, useMemo } from 'react';
import NotificationTemplateTester from 'Notifications/NotificationTemplateTester';
import {
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import useHasAccess, { userRoles } from 'hooks/useHasAccess';

import AdministrationRouter from 'Administration/AdministrationRouter';
import Dashboard from 'Dashboard';
import Notifications from 'Notifications';
import ProductReleaseRouter from 'ProductRelease/ProductReleaseRouter';
import ServiceProvidersRouter from 'ServiceProviders/ServiceProvidersRouter';
import MyProfile from 'MyProfile/MyProfile';
import Insights from 'Insights';
import NoAccessRights from 'shared-components/NoAccessRights';
import MyCompanyRouter from 'MyCompany/MyCompanyRouter';

import SensorDataCommonRouter from 'SensorDataCommon/SensorDataRouter';
import getTrackAndTraceRouter from 'TrackAndTrace/TrackAndTraceRouter';
import LaneManagementRouter from 'LaneManagement/LaneManagementRouter';

const getEnvironment = () => {
    const url = document.location;

    if (url.host.includes('test')) {
        return ' (TEST)';
    }
    if (url.host.includes('dev')) {
        return ' (DEV)';
    }
    return null;
};

const Root = () => {
    const hasAccess = useHasAccess();

    useEffect(() => {
        const env = getEnvironment();

        if (env) {
            document.title = `SkyMind ${env}`;
        }
    }, []);

    const displayStatistics = hasAccess(userRoles.INSIGHTS);

    const routerCollection = useMemo<RouteItem[]>(() => {
        return [
            ...AdministrationRouter,
            ...LaneManagementRouter,
            ...MyCompanyRouter,
            ...ServiceProvidersRouter,
            ...SensorDataCommonRouter,
            ...getTrackAndTraceRouter(hasAccess),
            ...ProductReleaseRouter,
        ];
    }, [
        AdministrationRouter,
        LaneManagementRouter,
        MyCompanyRouter,
        ServiceProvidersRouter,
        SensorDataCommonRouter,
        getTrackAndTraceRouter,
    ]);

    return (
        <Switch>
            <Route exact path="/">
                <Dashboard />
            </Route>
            <Route path="/welcome">
                <Dashboard />
            </Route>
            {
                routerCollection.map((item, index) => (
                    <Route exact={item.exact} key={`routerCollection-${index}`} path={item.path}>
                        {
                            (item.sufficientRoles ? (item.sufficientRoles || []).some(it => hasAccess(it))
                                : (item.necessaryRoles || []).every(it => hasAccess(it)))
                                ? item.component
                                : <NoAccessRights />
                        }
                    </Route>
                ))
            }
            <Route path="/invoices">
                <div>invoices</div>
            </Route>
            <Route path="/documents">
                <div>Documents</div>
            </Route>
            <Route path="/notifications/test-template" exact>
                {
                    process.env.REACT_APP_ENVIRONMENT === 'dev' && (
                        <NotificationTemplateTester />
                    )
                }
            </Route>
            <Route path="/notifications">
                <Notifications />
            </Route>
            <Route path="/my-profile">
                <MyProfile />
            </Route>
            <Route path="/insights">
                {
                    displayStatistics ? <Insights /> : <NoAccessRights />
                }
            </Route>
            <Redirect
                path="/"
                to="/welcome"
            />
        </Switch>
    );
};

export default Root;
