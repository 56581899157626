import React from 'react';
import { PICTURES } from '../constants';
import useStyles from './NoAccessRights.style';

type Props = {
    text?: string,
}

const NoAccessRights = ({
    text = 'Please reach out to your contact in SkyCell or drop a note to support@skycell.ch to get this fixed.',
}: Props) => {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.title}>Ouuups! It seems you do not have access.</div>
            <div className={classes.text}>
                {text}
            </div>
            <img
                className={classes.picture}
                src={PICTURES.noAccessRightsWithout404Bubble}
                alt="No Access Rights"
            />
        </div>
    );
};

export default NoAccessRights;
