import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    contentData: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '12px',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    contentDataMargin: {
        marginTop: '15px',
    },
    textField: {
        marginTop: '12px',
    },
    textFieldLatitude: {
        width: '105%',
    },
    textFieldLongitude: {
        width: '115%',
    },
    fieldTitle: {
        margin: '12px 0 2px 0',
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: '#939393',
        opacity: 1,
    },
    coordinatesFieldTitle: {
        marginTop: '-4px',
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: '#939393',
        opacity: 1,
    },
    textFieldText: {
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    mandatory: {
        background: '#E0F7FF 0% 0% no-repeat padding-box',
        borderRadius: '3px',
        borderLeft: '6px solid #8BD1E8',
        opacity: 1,
    },
    selectStyle: {
        bakcground: 'purple!important',
    },
    flexContent: {
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
    },
    mapButtonContainer: {
        marginRight: '20px',
    },
    infoIcon: {
        width: '26px',
        height: '26px',
        marginRight: '7px',
        opacity: 1,
    },
    infoText: {
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: '#747474',
        opacity: 1,
    },
    mapButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '19px',
    },
    latLngTitle: {
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: '#939393',
        opacity: 1,
    },
    addGeolocationDescription: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '16px',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: '#747474',
        opacity: 1,
    },
    addGeolocationDescriptionIcon: {
        marginRight: '8px',
    },
    buttonContainer: {
        display: 'flex',
        marginTop: '26px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    approveButton: {
        width: '90px',
        height: '36px',
        margin: '0 7px',
    },
    rejectButton: {
        width: '90px',
        height: '36px',
        margin: '0 7px',
        textTransform: 'uppercase',
    },
    modalWidth: {
        width: '75%',
    },
    listBox: {
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        '& .MuiAutocomplete-option[aria-selected="true"]': { // works
            backgroundColor: '#FFF!important',
        },
        '& .MuiAutocomplete-option.Mui-focused': { // works
            backgroundColor: 'rgba(25, 118, 210, 0.12)!important',
        },
    },
}));

export default useStyles;
