import React, { useState, Dispatch, SetStateAction, useCallback } from 'react';
import { Geofence } from 'shared-components/CompanyInfoComponents/AdministrationCompany/GeofencesCard/GeofencesCard';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { Delete, AddCircle, Edit } from '@mui/icons-material';
import useStyles from 'shared-components/CompanyInfoComponents/AdministrationCompany/GeofencesCard/GeofencesCard.style';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import { LatLng } from 'dataTypes/common';
import useCustomTranslation from 'hooks/useCustomTranslation';
import GeofenceModal from '../GeofenceModal';

type Props = {
    geofences: Geofence[],
    setGeofences: Dispatch<SetStateAction<Geofence[]>>,
    edit: boolean,
    siteId: string
}
const GeofencesTable = ({
    geofences = [],
    setGeofences,
    edit,
    siteId,
}: Props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const {
        Delete: deleteGeofence,
        FlexibleRequest: updateGeofence,
        Create: postGeofence,
    } = useSecureBackendEndpoints('geofences').statusNotificationRequests;
    const [geofenceToUpdate, setGeofenceToUpdate] = useState<Geofence>(null);
    const [loading, setLoading] = useState(false);
    const { t } = useCustomTranslation();
    const onDelete = useCallback(async (geofence: Geofence) => {
        setLoading(true);
        await deleteGeofence(geofence.id);
        setLoading(false);
        setGeofences(prevState => prevState.filter(it => it.id !== geofence.id));
    }, [setGeofences]);

    const onGeofenceAdded = useCallback(async (path: LatLng[], name: string) => {
        setLoading(true);
        const newGeofence: Geofence = {
            name,
            siteId,
            coordinates: path.map(({ lng: longitude, lat: latitude }) => ({ longitude, latitude })),
        };

        if (geofenceToUpdate?.id) {
            await updateGeofence('PATCH', `/${geofenceToUpdate?.id}`, newGeofence);
            const updatedGeofence:Geofence = {
                id: geofenceToUpdate?.id,
                ...newGeofence,
            };

            setGeofences(prev => [...prev.filter(it => it.id !== geofenceToUpdate?.id), updatedGeofence]);
        } else {
            const result = await postGeofence(newGeofence);
            const savedGeofence = result?.data;

            if (savedGeofence) {
                setGeofences(prev => [...prev, savedGeofence]);
            }
        }
        setLoading(false);
    }, [siteId, geofenceToUpdate, setGeofences]);

    return (
        <div>
            <GeofenceModal
                open={open}
                setOpen={setOpen}
                onGeofenceAdded={onGeofenceAdded}
                geofenceData={geofenceToUpdate}
            />
            <TableContainer>
                <Table className={[classes.table, loading ? classes.loading : ''].join(' ')}>
                    <TableHead>
                        <TableRow className={classes.tableRow}>
                            <TableCell />
                            <TableCell style={{ width: '75px' }} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            geofences
                                .map((geofence) => {
                                    return (
                                        <TableRow
                                            className={classes.tableRow}
                                            key={`geofence_${geofence.id}`}
                                        >
                                            <TableCell>{geofence.name || '-'}</TableCell>
                                            <TableCell className={classes.tableButtonCell}>
                                                {
                                                    edit && (
                                                        <Delete
                                                            className={classes.icon}
                                                            onClick={onDelete.bind(null, geofence)}
                                                        />
                                                    )
                                                }
                                                {
                                                    edit && (
                                                        <Edit
                                                            className={classes.icon}
                                                            onClick={() => {
                                                                setGeofenceToUpdate(geofence);
                                                                setOpen(true);
                                                            }}
                                                        />
                                                    )
                                                }

                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                        }
                        {
                            geofences.length === 0 && (
                                <TableRow
                                    className={classes.tableRow}
                                    key="geofence_no_data"
                                >
                                    <TableCell>{t('PRODUCT_RELEASE.NO_DATA')}</TableCell>
                                    <TableCell className={classes.tableButtonCell} />
                                </TableRow>
                            )

                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {
                edit && (
                    <div className={classes.plusWrapper}>
                        <div
                            className={[
                                classes.plus,
                                classes.icon,
                            ].join(' ')}
                            onClick={() => {
                                setGeofenceToUpdate(null);
                                setOpen(true);
                            }}
                        >
                            <AddCircle />
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default GeofencesTable;
