export const getRejectedStatuses = (
    { sealStatus,
        containerStatus,
        palletStatus,
        palletStatus1,
        palletStatus2 },
) => {
    const statusSet = new Set();

    [
        { status: sealStatus, text: 'SEAL_ISSUE' },
        { status: containerStatus, text: 'PACKAGING_DAMAGE' },
        { status: palletStatus, text: 'PALLET_DAMAGE' },
        { status: palletStatus1, text: 'PALLET_DAMAGE' },
        { status: palletStatus2, text: 'PALLET_DAMAGE' },
    ].forEach(it => {
        if (it.status === 'REJECTED') {
            statusSet.add(it.text);
        }
    }, []);
    return Array.from(statusSet);
};
