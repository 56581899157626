import React, {
    useEffect,
    useState,
} from 'react';
import { TimeRange } from 'dataTypes/common';
import { SensorDataRequestBody } from 'dataTypes/SecureBackend/processedData';
import CurrentTemperature from 'shared-components/CurrentTemperature';
import useCustomTranslation from 'hooks/useCustomTranslation';
import {
    EntitySelectorItem,
} from './dataTypes';
import EntityController from './components/EntityController';
import Options from './components/Options';
import useStyles from './SensorData.style';
import useBreadcrumbsContext from '../hooks/useBreadcrumbsContext';

type Props = {
    entitySelectorItem: EntitySelectorItem,
    requestBody: SensorDataRequestBody,
    setRequestBody: (requestBody: SensorDataRequestBody) => void,
    setEntitySelectorItem: (entitySelectorItem: EntitySelectorItem) => void,
    setTimeRange: (timeRange: TimeRange) => void,
    timeRange: TimeRange,
    loggerList: EntitySelectorItem[],
    isCurrentTime: boolean,
    setIsCurrentTime: (isCurrentTime?: boolean) => void,
    loggersFetched: boolean,
    core?: string,
}

const LoggerSensorData = ({
    entitySelectorItem,
    requestBody,
    setRequestBody,
    setEntitySelectorItem,
    setTimeRange,
    timeRange,
    loggerList = [],
    isCurrentTime,
    setIsCurrentTime,
    loggersFetched,
    core = 'track-and-trace',
} : Props) => {
    const classes = useStyles();
    const { t } = useCustomTranslation();
    const [localTimezone, setLocalTimezone] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const { setBreadcrumbData } = useBreadcrumbsContext();

    const { entityType, additionalData } = entitySelectorItem ?? {};
    const {
        lastMeasuredTemp,
        lastMeasuredTempInternal = null,
        lastMeasuredTempInternalTimestamp = null,
    } = additionalData ?? {};

    useEffect(() => {
        setBreadcrumbData('entityType', t(({
            logger: t('TRACK_AND_TRACE.LOGGER'),
        })[entityType]));
    },
    [entitySelectorItem]);

    return (
        <>
            <div className={classes.contentContainer}>
                <div>
                    <EntityController
                        entitySelectorItem={entitySelectorItem}
                        localTimezone={localTimezone}
                        requestBody={requestBody}
                        showMap={showMap}
                        timeRange={timeRange}
                        isLoading={!loggersFetched}
                        core={core}
                    />
                </div>
                <div className={classes.contentRightColumn}>
                    <Options
                        entitySelectorItem={entitySelectorItem}
                        setEntitySelectorItem={setEntitySelectorItem}
                        setTimeRange={setTimeRange}
                        loggerList={loggerList}
                        disabled={entityType === null}
                        localTimezone={localTimezone}
                        requestBody={requestBody}
                        setRequestBody={setRequestBody}
                        setLocalTimezone={setLocalTimezone}
                        setShowMap={setShowMap}
                        timeRange={timeRange}
                        isCurrentTime={isCurrentTime}
                        setIsCurrentTime={setIsCurrentTime}
                    />
                    {
                        entityType === 'loggers' && (
                            <CurrentTemperature
                                lastMeasuredTempInternal={
                                    lastMeasuredTemp || lastMeasuredTempInternal
                                }
                                lastMeasuredOnUtcInternal={
                                    lastMeasuredTempInternalTimestamp
                                }
                            />
                        )
                    }
                </div>
            </div>
        </>
    );
};

export default LoggerSensorData;
