import React, { ReactNode } from 'react';
import Alert, { AlertColor } from '@mui/material/Alert';

import useStyles from '../StatusMessages.style';

type Props = {
    alertColor?: AlertColor,
    alertTime: number,
    icon: ReactNode,
    message?: string,
    setAlertToDelete: (alertTime: number) => void,
}

const StatusMessage = ({
    alertColor = 'success',
    alertTime,
    icon = null,
    message = '',
    setAlertToDelete,
} : Props) => {
    const classes = useStyles();
    const handleClose = () => setAlertToDelete(alertTime);

    return (
        <div className={classes.statusMessageItemContainer}>
            <div className={classes.message}>
                <Alert
                    className={alertColor === 'info' ? classes.customInfoSeverity : ''}
                    icon={icon}
                    onClose={handleClose}
                    severity={alertColor}
                    variant="filled"
                >
                    {message}
                </Alert>
            </div>
        </div>
    );
};

export default StatusMessage;
