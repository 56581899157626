import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    heading: {
        borderBottom: `1px solid ${theme.palette.primary[400]}`,
        fontSize: '36px',
        fontWeight: 200,
        marginBottom: '16px',
        color: theme.palette.secondary[900],
    },
}));
