import React, { useState } from 'react';

import TitleHeader from 'TitleHeader';
import Card from 'shared-components/Card';
import icons from 'shared-components/icons';
import { QuestionnaireItem } from 'shared-components/dataTypes';
import { makeStyles } from '@mui/styles';
import useCustomTranslation from 'hooks/useCustomTranslation';
import RowWithIcon from '../../RowWithIcon';
import TextArray from '../../TextArray';
import CapabilityDetails from './CapabilityDetails';

import { getIconByStatus } from '../../lib';

const useStyles = makeStyles(theme => ({
    categoryInfoTitle: {
        marginTop: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    categoryInfoBody: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, auto)',
        gridTemplateRows: 'auto',
        gridGap: 12,
    },
    groupTitle: {
        marginTop: '8px',
        marginBottom: '3px',
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: theme.palette.secondary[500],
        opacity: 1,
    },
    capabilityIcon: {
        height: '21px',
        width: '21px',
    },
    innerClass3Columns: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridGap: 6,
        paddingLeft: '0px',
    },
    innerClass2Columns: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridGap: 6,
        paddingLeft: '0',
    },
    responsiveOptions6: {
        display: 'grid',
        gridTemplateColumns: 'repeat(6, 1fr)',
        gridTemplateRows: 'auto',
        gridGap: 12,
        [theme.breakpoints.down(1200)]: {
            gridTemplateColumns: 'repeat(3,1fr)',
            justifyContent: 'stretch',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    responsiveOptions5: {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        gridTemplateRows: 'auto',
        gridGap: 12,
        [theme.breakpoints.down(1200)]: {
            gridTemplateColumns: 'repeat(3,1fr)',
            justifyContent: 'stretch',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    responsiveOptions4: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridTemplateRows: 'auto',
        gridGap: 12,
        [theme.breakpoints.down(1200)]: {
            gridTemplateColumns: 'repeat(3,1fr)',
            justifyContent: 'stretch',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    responsiveOptions3: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridTemplateRows: 'auto',
        gridGap: 12,
        [theme.breakpoints.down(1200)]: {
            gridTemplateColumns: 'repeat(3,1fr)',
            justifyContent: 'stretch',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    infoAndDetailsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '5px 0',
        alignItems: 'center',
        '&:hover': {
            boxShadow: '0px 1px 3px #00000033',
        },
        '&:hover $capabilityDetailsContainer': {
            display: 'block',
        },
    },
    capabilityDetailsContainer: {
        display: 'none',
    },
}));

type Props = {
    capabilities?: {
        [key: string]: {
            capability: string,
            status: string,
            questionnaireProfile: 'CORE' | 'EXTENDED',
        }[],
    },
    certificates?: {
        mainCertificate: string,
        subCategory: string,
        subCertificates: string[],
    }[],
    className?: string,
    facilities?: {
        extendedLabel: string,
        icon: string,
        label: string,
        subCategory: string,
    }[],
    title: string,
    showTooltip?: boolean,
    questionnaire: QuestionnaireItem[],
}

const CategoryInfo = ({
    capabilities,
    certificates = [],
    className = '',
    facilities = [],
    title,
    showTooltip = false,
    questionnaire = [],
} : Props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);

    const { t } = useCustomTranslation();
    const handleClick = () => setOpen(!open);

    const grouped = Object.keys(capabilities).reduce((data, subCategory) => {
        const certificatesFiltered = (certificates
            .filter((item) => item.subCategory === subCategory) || [])
            .reduce((certData, item) => {
                if (item.subCertificates?.length) {
                    return [...certData, ...item.subCertificates];
                }

                return [...certData, item.mainCertificate];
            }, []);
        const facilitiesFiltered = facilities
            .filter((item) => item.subCategory === subCategory) || [];

        return {
            ...data,
            [subCategory]: {
                certificatesFiltered,
                facilitiesFiltered,
                capabilities: capabilities[subCategory].filter((item) => item.status),
                questionnaire: questionnaire[subCategory],
                totalLength: capabilities[subCategory].length
                    + certificatesFiltered.length
                    + facilitiesFiltered.length,
            },
        };
    }, {});

    const sortedSubCategories = Object.keys(grouped)
        .sort((a, b) => grouped[b].totalLength - grouped[a].totalLength);

    const count = Math.max(sortedSubCategories.length, 3);
    const columCount = Math.min(count, 6);

    const categoryInfoBody = classes[`responsiveOptions${columCount}`];

    return (
        <div className={className}>
            <TitleHeader onClick={handleClick}>
                <div className={classes.categoryInfoTitle}>
                    <div>{title}</div>
                    {
                        open
                            ? <img alt="ArrowDown" src={icons.arrow_down_lg} />
                            : <img alt="ArrowForward" src={icons.arrow_right_lg} />
                    }
                </div>
            </TitleHeader>
            {
                open && (
                    <div className={categoryInfoBody}>
                        {
                            sortedSubCategories.map((subCategory, index) => {
                                const {
                                    certificatesFiltered,
                                    facilitiesFiltered,
                                    capabilities,
                                    questionnaire,
                                    totalLength,
                                } = grouped[subCategory];

                                let innerClass = null;

                                let gridColumn = '';

                                let gridRow = 'auto / span 2';

                                if (totalLength > 21) {
                                    innerClass = classes.innerClass3Columns;
                                    gridColumn = `${(index % 6) * 3 + 1} / ${(index % 6) * 3 + 4}`;
                                } else if (totalLength > 11 || sortedSubCategories.length < 3) {
                                    innerClass = classes.innerClass2Columns;
                                    gridColumn = `${(index % 6) * 2 + 1} / ${(index % 6) * 2 + 3}`;
                                } else if (totalLength < 5) {
                                    gridRow = 'auto / span 1';
                                } else if (count > 6 && (index + 1 > count - (count % 6))) {
                                    innerClass = classes.innerClass2Columns;
                                    gridColumn = `${index * 2 + 1} / ${index * 2 + 3}`;
                                }

                                const groupedCapabilities = [];
                                const additionalComponents = [];

                                if (facilitiesFiltered.length) {
                                    additionalComponents.push(
                                        <div key={`additionalComponents-${index}`}>
                                            <div className={classes.groupTitle}>
                                                {t('COMMON.FACILITIES')}
                                            </div>
                                            {
                                                facilitiesFiltered.map((item) => {
                                                    return (
                                                        <RowWithIcon
                                                            icon={item.icon}
                                                            key={item.extendedLabel}
                                                            label={item.extendedLabel}
                                                        />
                                                    );
                                                })
                                            }
                                        </div>,
                                    );
                                }

                                if (certificatesFiltered.length) {
                                    additionalComponents.push(
                                        <div>
                                            <div className={classes.groupTitle}>
                                                {t('COMMON.CERTIFICATES')}
                                            </div>
                                            <TextArray textItems={certificatesFiltered} />
                                        </div>,
                                    );
                                }

                                const step = 10;
                                const additionalElemCount = certificatesFiltered.length + facilitiesFiltered.length;

                                for (let i = 0; i * step < capabilities.length; i++) {
                                    const start = i * step - (i > 0 && additionalElemCount);
                                    const end = (i * step + step) - (i === 0 && additionalElemCount);

                                    const capabilitiesComponents = capabilities.slice(start, end).map((item, index) => {
                                        if (showTooltip) {
                                            const question = questionnaire.find((questionItem) => (
                                                questionItem.capability
                                                && item.capability
                                                && questionItem.capability === item.capability
                                            )) || null;

                                            if (question) {
                                                return (
                                                    <div
                                                        className={classes.infoAndDetailsContainer}
                                                        key={`InfoAndDetailsContainer-${index}`}
                                                    >
                                                        <RowWithIcon
                                                            customIconClass={classes.capabilityIcon}
                                                            icon={getIconByStatus(item.status)}
                                                            label={item.capability}
                                                            marginBottomDisabled
                                                        />
                                                        <div className={classes.capabilityDetailsContainer}>
                                                            <CapabilityDetails questionnaire={question} />
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }

                                        return (
                                            <RowWithIcon
                                                customIconClass={classes.capabilityIcon}
                                                icon={getIconByStatus(item.status)}
                                                label={item.capability}
                                            />
                                        );
                                    });

                                    if (i === 0 && additionalComponents.length) {
                                        groupedCapabilities.push([
                                            ...additionalComponents,
                                            (
                                                <div className={classes.groupTitle}>
                                                    {t('COMMON.CAPABILITIES')}
                                                </div>
                                            ),
                                            ...capabilitiesComponents,
                                        ]);
                                    } else {
                                        groupedCapabilities.push([
                                            (
                                                <div className={classes.groupTitle}>
                                                    {i === 0 ? t('COMMON.CAPABILITIES')
                                                        : t('SERVICE_PROVIDERS.CAPABILITIES_CONTINUED')}
                                                </div>
                                            ),
                                            ...capabilitiesComponents,
                                        ]);
                                    }
                                }

                                return (
                                    <Card
                                        key={`Card-${index}`}
                                        title={subCategory}
                                        style={{
                                            gridColumn,
                                            gridRow,
                                        }}
                                    >
                                        <div className={innerClass}>
                                            {
                                                Boolean(capabilities.length) && (
                                                    groupedCapabilities.map((group, index) => {
                                                        return (
                                                            <div key={`groupedCapabilities-${index}`}>
                                                                {
                                                                    group.map((item, index) => {
                                                                        return (
                                                                            <div key={`item-${index}`}>
                                                                                {item}
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        );
                                                    })
                                                )
                                            }
                                        </div>
                                    </Card>
                                );
                            })
                        }
                    </div>
                )
            }
        </div>
    );
};

export default CategoryInfo;
