import React from 'react';
import { OverlayView } from '@react-google-maps/api';
import PolygonMarker from 'shared-components/PolygonMarker';

const getPixelPositionOffset = () => ({
    x: -11,
    y: -11,
});

type Props = {
    itemType: string,
}

const TooltipPolygonMarker = ({ itemType }: Props) => {
    return (
        <OverlayView
            position={{}}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={getPixelPositionOffset}
        >
            <PolygonMarker
                itemType={itemType}
                theme="light"
                iconDiameter={35}
            />
        </OverlayView>
    );
};

export default TooltipPolygonMarker;
