import React from 'react';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import icons from 'shared-components/icons';

type Props = {
    energyLevel?: number,
}
const EnergyLevel = ({
    energyLevel,
}: Props) => {
    const { company } = useCurrentUserContext();

    return (
        <div style={{
            display: 'flex',
            color: energyLevel < company.energyLevelThreshold ? '#D44848' : 'black',
        }}
        >
            {energyLevel < company.energyLevelThreshold
                ? (
                    <img
                        style={{
                            marginRight: 4,
                            height: 14,
                        }}
                        key="icon_warning"
                        src={Number(energyLevel) === 0
                            ? icons.red_triangle : Number(energyLevel) < company.energyLevelThreshold
                                ? icons.warning_triangle : ''}
                        alt="warning"
                    />
                )
                : ''}
            <div>
                {Number(energyLevel === null ? 'n/a' : energyLevel)}
                %
            </div>
            <br />
            <br />
        </div>
    );
};

export default EnergyLevel;
