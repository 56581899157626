import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
    Period,
    TimeRange,
    PERIODS,
    DAY_TO_PERIOD,
} from 'dataTypes/common';
import { TIME_IN_MS } from 'shared-components/constants';
import useStyles from './PeriodSelector.style';

const initializePeriod = (timeRange: TimeRange): Period => {
    const { from = null, to = null } = timeRange;

    if (from && to) {
        const fromMilliseconds = new Date(from).getTime();
        const toMilliseconds = new Date(to).getTime();

        const range = Math.round((toMilliseconds - fromMilliseconds) / TIME_IN_MS.day);

        return DAY_TO_PERIOD[range] || 'Manual';
    }

    return '24h';
};

type Props = {
    disableManualOption?: boolean,
    getPeriod?: (period: Period) => void,
    isFullAccess?: boolean,
    setTimeRange: (timeRange: TimeRange) => void,
    timeRange?: TimeRange,
    additionalSelectEventReaction?: () => void,
}

const PeriodSelector = ({
    disableManualOption = false,
    getPeriod = () => {},
    isFullAccess = true,
    setTimeRange,
    timeRange = { from: null, to: null },
    additionalSelectEventReaction = () => {},
}: Props) => {
    const classes = useStyles();
    const [period, setPeriod] = useState(() => initializePeriod(timeRange));

    const onRestrinctPeriodChange = () => {
        setPeriod('24h');
        getPeriod('24h');
        const from = moment().subtract({ day: 1 });
        const to = moment();

        setTimeRange({
            from: from.utc().format('YYYY-MM-DDTHH:mm'),
            to: to.utc().format('YYYY-MM-DDTHH:mm'),
        });
        additionalSelectEventReaction();
    };

    const onPeriodChange = (period: Period) => {
        if (period !== 'Manual') {
            const from = moment().subtract({ day: PERIODS[period] });
            const to = moment();

            setTimeRange({
                from: from.utc().format('YYYY-MM-DDTHH:mm'),
                to: to.utc().format('YYYY-MM-DDTHH:mm'),
            });
        } else {
            // If 'Manual' is selected, pass the existing timeRange to maintain the selected dates.
            setTimeRange(timeRange);
        }

        setPeriod(period); // Move this line here to update the period after setting the time range
        getPeriod(period);
        additionalSelectEventReaction();
    };

    useEffect(() => {
        if (period !== 'Manual' && timeRange.to !== new Date().toDateString()) {
            const from = new Date(timeRange.from).getTime();
            const to = new Date(timeRange.to).getTime();

            const range = Math.round((to - from) / TIME_IN_MS.day);

            // If the range isn't found in DAY_TO_PERIOD, set it to 'Manual'
            const newPeriod = DAY_TO_PERIOD[range] || '24h';

            if (period !== newPeriod) {
                setPeriod(newPeriod);
            }
        }
    }, [period, timeRange]);

    return (
        isFullAccess
            ? (
                <div className={classes.periodsWrapper}>
                    <div className={classes.periods}>
                        {
                            Object.keys(PERIODS)
                                .filter(periodOption => periodOption !== 'Manual' || !disableManualOption)
                                .map((periodOption: Period) => {
                                    const handleChange = () => onPeriodChange(periodOption);

                                    return (
                                        <div
                                            key={periodOption}
                                            data-testid={periodOption}
                                            onClick={handleChange}
                                            className={`${classes.customPeriod} ${periodOption === period
                                                ? classes.active : ''}`}
                                        >
                                            {periodOption}
                                        </div>
                                    );
                                })
                        }
                    </div>
                </div>
            )
            : (
                <div className={classes.periodsWrapper}>
                    <span>Quick-Select</span>
                    <div
                        onClick={onRestrinctPeriodChange}
                        className={`${classes.customPeriod} ${classes.active}`}
                    >
                        24h
                    </div>
                </div>
            )
    );
};

export default PeriodSelector;
