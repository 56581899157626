import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    contentData: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '12px',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    contentDataMargin: {
        marginTop: '15px',
    },
    textFieldWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '15px 0px',
    },
    textField: {

    },
    fieldTitle: {
        margin: '12px 0 2px 0',
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: '#939393',
        opacity: 1,
    },
    coordinatesFieldTitle: {
        marginTop: '-4px',
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: '#939393',
        opacity: 1,
    },
    textFieldText: {
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    mandatory: {
        background: '#E0F7FF 0% 0% no-repeat padding-box',
        borderRadius: '3px',
        borderLeft: '6px solid #8BD1E8',
        opacity: 1,
    },
    selectStyle: {
        bakcground: 'purple!important',
    },
    flexContent: {
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
    },
    mapButtonContainer: {
        marginRight: '20px',
    },
    infoIcon: {
        width: '26px',
        height: '26px',
        marginRight: '7px',
        opacity: 1,
    },
    infoText: {
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: '#747474',
        opacity: 1,
    },
    latLngTitle: {
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: '#939393',
        opacity: 1,
    },
    addGeolocationDescription: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '16px',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: '#747474',
        opacity: 1,
    },
    addGeolocationDescriptionIcon: {
        marginRight: '8px',
    },
    buttonContainer: {
        display: 'flex',
        marginTop: '26px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    approveButton: {
        width: '90px',
        height: '36px',
        margin: '0 7px',
        background: `${theme.palette.primary[200]} 0% 0% no-repeat padding-box`,
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        opacity: 1,
        textAlign: 'left',
        font: 'normal normal 500 14px/17px Roboto',
        letterSpacing: '1.25px',
        color: theme.palette.common.white,
        textTransform: 'uppercase',
        '&:hover': {
            backgroundColor: theme.palette.primary[600],
        },
    },
    rejectButton: {
        width: '90px',
        height: '36px',
        margin: '0 7px',
        background: `${theme.palette.common.white} 0% 0% no-repeat padding-box`,
        border: `1px solid ${theme.palette.primary[200]}`,
        borderRadius: '4px',
        opacity: 1,
        textAlign: 'center',
        font: 'normal normal 500 14px/17px Roboto',
        letterSpacing: '1.25px',
        color: theme.palette.primary[200],
        textTransform: 'uppercase',
    },
    modalWidth: {
        width: '75%',
    },
    mapButton: {
        position: 'absolute',
        left: 'calc(50% - 50px)',
        bottom: '1%',
        width: '100px',
    },
    loading: {
        transition: '200ms ease',
        filter: 'blur(2px)',
    },
    listBox: {
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        '& .MuiAutocomplete-option[aria-selected="true"]': { // works
            backgroundColor: '#FFF!important',
        },
        '& .MuiAutocomplete-option.Mui-focused': { // works
            backgroundColor: 'rgba(25, 118, 210, 0.12)!important',
        },
    },
    table: {
        '& th, & td': {
            font: 'Roboto',
            fontSize: '16px',
            padding: '7px 10px',
        },
    },
    tableRow: {
        '& th': {
            color: theme.palette.secondary[600],
            fontWeight: 'bold',
        },
        '& td': {
            color: theme.palette.secondary[600],
            minHeight: '40px',
        },

    },
    plusWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    selectShade: {
        background: theme.palette.primary[30],
    },
    icon: {
        color: theme.palette.secondary[600],
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.secondary[800],
        },
    },
    disabledIcon: {
        opacity: 0.6,
        cursor: 'unset',
    },
    tableButtonCell: {
        textAlign: 'end',
        padding: '0 10px!important',
    },
    plus: {
        padding: '10px',
    },

}));

export default useStyles;
