import React, {
    useCallback,
    useMemo,
    CSSProperties,
} from 'react';
import { useTheme } from '@mui/material/styles';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { TEMPERATURE_STATUS } from 'shared-components/constants';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import Box from './Box';

const STATUS_PRIORITY = [
    TEMPERATURE_STATUS.IN_RANGE,
    TEMPERATURE_STATUS.WAITING_FOR_DATA,
    TEMPERATURE_STATUS.NOT_APPLICABLE,
    TEMPERATURE_STATUS.UNKNOWN,
    TEMPERATURE_STATUS.EXCURSION,
    TEMPERATURE_STATUS.PREDICTED_EXCURSION,
];

const rejectedStatuses = [TEMPERATURE_STATUS.EXCURSION];

const containerStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    marginRight: '5px',
};

type Props = {
    statuses: string[],
}

const PackagingsBoxVisualization = ({ statuses = [] }: Props) => {
    const { t } = useCustomTranslation();
    const theme = useTheme<SkycellThemeInterface>();

    const sortedStatuses = useMemo(() => {
        const cp = [...statuses];

        cp.sort((a, b) => {
            return cp[0] === 'PREDICTED_EXCURSION'
                ? 0 : STATUS_PRIORITY.indexOf(a) - STATUS_PRIORITY.indexOf(b);
        });
        return cp;
    }, [statuses]);

    const otherColors = useMemo(() => {
        if (sortedStatuses.length < 6) {
            return [];
        }

        const slicedStatuses = sortedStatuses.slice(2, sortedStatuses.length - 2);

        if (slicedStatuses.every(status => status === TEMPERATURE_STATUS.NO_DATA)) {
            return [
                theme.palette.secondary[400],
                theme.palette.secondary[400],
                theme.palette.secondary[400],
            ];
        }

        const rejectedStatusCount = slicedStatuses.filter(status => rejectedStatuses.includes(status)).length;
        const redDotsCount = Math.ceil((3 * rejectedStatusCount) / slicedStatuses.length);
        const dots = new Array(3)
            .fill(theme.palette.primary['deepBlue'])
            .fill(theme.palette.common['red'], 0, redDotsCount);

        return dots;
    }, [sortedStatuses, theme]);

    const statusToColor = useCallback(status => {
        switch (status) {
        case TEMPERATURE_STATUS.EXCURSION:
            return theme.palette.common.red;
        case TEMPERATURE_STATUS.NO_DATA:
            return theme.palette.secondary[400];
        case TEMPERATURE_STATUS.PREDICTED_EXCURSION:
            return theme.palette.common['yellow'];
        default:
            return theme.palette.primary['deepBlue'];
        }
    }, [theme]);

    if (statuses.length <= 5) {
        return (
            <div style={containerStyle}>
                {
                    sortedStatuses.map((status, i) => (
                        <Box
                            color={statusToColor(status)}
                            title={t(`TEMPERATURE_STATUS.${status}`)}
                            key={`${i}_${status}`}
                        />
                    ))
                }
            </div>
        );
    }

    return (
        <div style={containerStyle}>
            <Box
                color={statusToColor(sortedStatuses[0])}
                title={t(`TEMPERATURE_STATUS.${sortedStatuses[0]}`)}
            />

            <Box
                color={statusToColor(sortedStatuses[1])}
                title={t(`TEMPERATURE_STATUS.${sortedStatuses[1]}`)}
            />
            {
                otherColors.map((color, i) => (
                    <Box
                        key={`others_${color}_ ${i}`}
                        color={color}
                        small
                    />
                ))
            }
            <Box
                color={statusToColor(sortedStatuses[sortedStatuses.length - 2])}
                title={t(`TEMPERATURE_STATUS.${sortedStatuses[sortedStatuses.length - 2]}`)}
            />
            <Box
                color={statusToColor(sortedStatuses[sortedStatuses.length - 1])}
                title={t(`TEMPERATURE_STATUS.${sortedStatuses[sortedStatuses.length - 1]}`)}
            />
        </div>
    );
};

export default PackagingsBoxVisualization;
