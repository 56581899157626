import React, { useState } from 'react';
import { useStyles } from 'LaneManagement/AddLane/AddLane.style';
import LocationSelector from 'shared-components/LocationSelector';
import { AddressDTO } from 'shared-components/dataTypes';
import LocationInfo from 'LaneManagement/AddLane/Cards/AddLocation/LocationInfo';
import { FormControl } from '@mui/material';
import { InformationCardField } from '../fieldMapper';

type Props = {
    value: AddressDTO,
    field: InformationCardField,
    onChange?: any,
    showOnly?: string[],
    mandatory?: boolean,
    filter?: {
        [key: string]: any
    },
    font?: 'small' | 'medium',
}

const LocationSelectorField = ({
    value,
    field = null,
    onChange,
    showOnly = null,
    mandatory = false,
    filter,
    font = null,
}: Props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    return (
        <div style={{ overflow: 'hidden' }}>
            <FormControl
                fullWidth
            >
                <span className={classes.label}>{field.label}</span>
                <div style={{ height: 5 }} />
                <LocationInfo
                    location={value}
                    emptyText="Not Assigned"
                    short
                    onClick={onChange && setOpen.bind(null, true)}
                    mandatory={mandatory}
                    font={font}
                />
            </FormControl>

            {
                onChange && (
                    <LocationSelector
                        addressData={value}
                        onDataChange={onChange}
                        open={open}
                        onCloseAction={setOpen.bind(null, false)}
                        showOnly={showOnly}
                        filter={filter}
                    />
                )
            }
        </div>
    );
};

export default LocationSelectorField;
