import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    pane: {
        position: 'absolute',
        left: '10px',
        top: '10px',
    },
    button: {
        background: `${theme.palette.primary[200]} 0% 0% no-repeat padding-box`,
        borderRadius: '4px',
        boxShadow: '0px 1px 3px #00000033',
        textAlign: 'center',
        font: 'normal normal 500 14px/17px Roboto',
        letterSpacing: '1.25px',
        color: theme.palette.common.white,
        textTransform: 'uppercase',
        opacity: 1,
        height: '36px',
        '&:hover': {
            backgroundColor: theme.palette.primary[600],
        },
    },
}));
