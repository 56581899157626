import { RouteItem } from 'dataTypes/common';

export const GuideRouteMapping: RouteItem[] = [
    {
        exact: false,
        path: '/sensor-data',
        docPath: 'track_and_trace_user_manual.pdf',
    },
    {
        exact: false,
        path: '/track-and-trace/generic-shipments',
        docPath: 'track_and_trace_user_manual.pdf',
    },
    {
        exact: false,
        path: '/assets',
        docPath: 'track_and_trace_user_manual.pdf',
    },
    {
        exact: false,
        path: '/my-company',
        docPath: 'track_and_trace_user_manual.pdf',
    },
    {
        exact: false,
        path: '/notifications',
        docPath: 'track_and_trace_user_manual.pdf',
    },
];
