import React from 'react';

type Props = {
    srcImage: string,
    companyName: string | JSX.Element,
}

const ParentCompany = ({
    srcImage,
    companyName,
} : Props) => {
    return (
        srcImage && companyName
            ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                        style={{
                            display: 'flex',
                            width: '100px',
                            height: '40px',
                            marginRight: '7px',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            alt="Img"
                            style={{
                                maxWidth: '100%',
                                objectFit: 'contain',
                                maxHeight: '40px',
                            }}
                            src={srcImage}
                        />
                    </div>
                    <div>{companyName}</div>
                </div>
            )
            : (
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        height: '30px',
                    }}
                />
            )
    );
};

export default ParentCompany;
