import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    productsTable: {
        '& th, & td': {
            font: 'Roboto',
            fontSize: '16px',
            padding: '7px 10px',
        },
    },
    productsTableRow: {
        '& th': {
            color: theme.palette.secondary[600],
            fontWeight: 'bold',
        },
        '& td': {
            color: theme.palette.secondary[600],
        },

    },
    plusWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    selectShade: {
        background: theme.palette.primary[30],
    },
    icon: {
        color: theme.palette.secondary[600],
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.secondary[800],
        },
    },
    disabledIcon: {
        opacity: 0.6,
        cursor: 'unset',
    },
    productsTableButtonCell: {
        textAlign: 'end',
        padding: '0 10px!important',
    },
    plus: {
        padding: '10px',
    },
}));
