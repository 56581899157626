import React, { ReactElement } from 'react';
import Tooltip from '@mui/material/Tooltip';

import CustomTitle from './CustomTitle';
import useStyles from './IconWithTooltip.style';

type Props = {
    className?: string,
    iconSrc?: string,
    description: string,
    descriptionTitle: string,
    placement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top',
    children?: ReactElement,
}

const IconWithTooltip = ({
    className = '',
    iconSrc,
    description,
    descriptionTitle,
    placement = 'bottom-start',
    children = null,
} : Props) => {
    const classes = useStyles();

    return (
        <Tooltip
            disableFocusListener
            disableTouchListener
            placement={placement}
            componentsProps={{
                tooltip: {
                    sx: {
                        borderTop: '10px solid transparent',
                        borderBottom: '10px solid transparent',
                        borderColor: '#C3E9F7',
                        width: '288px',
                        background: '#F7F7F7 0% 0% no-repeat padding-box',
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: '10px',
                        opacity: 1,
                    },
                },
            }}
            title={(
                <CustomTitle
                    iconSrc={iconSrc}
                    description={description}
                    descriptionTitle={descriptionTitle}
                />
            )}
        >
            <div>
                {
                    !children && (
                        <img
                            alt={descriptionTitle}
                            src={iconSrc}
                            className={className || classes.icon}
                        />
                    )
                }
                {children}
            </div>
        </Tooltip>
    );
};

export default IconWithTooltip;
