import React, { useEffect, useContext, useState, useCallback, useMemo, useRef } from 'react';
import { LayoutContext } from 'Contexts/LayoutContext';
import icons from 'shared-components/icons';
import { Button, Modal, useTheme, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ModalDialog } from 'react-bootstrap';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import {
    Close,
    RotateLeft,
    InsertDriveFileOutlined,
    ErrorOutline,
    CheckOutlined,
    UploadFile,
} from '@mui/icons-material';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { DownloadComponentIcon } from 'shared-components/ApexTemperatureChart/icons';

const BORDER_SIZE = '2px';
const useStyles = makeStyles((theme: SkycellThemeInterface) => ({
    modalWrapper: {
        width: '100wv',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.black,
        position: 'relative',
    },
    modalWindow: {
        width: '410px',
        minHeight: '200px',
        background: theme.palette.common.white,
        position: 'relative',
        borderRadius: '4px',
        padding: '23px',
        zIndex: 101,
    },
    modalTitle: {
        fontSize: '32px',
        marginBottom: '17px',
    },
    closeIcon: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        fontSize: '20px',
        color: theme.palette.secondary[600],
    },
    backdrop: {
        zIndex: 100,
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        top: 0,
        left: 0,
    },
    buttonHolder: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    link: {
        color: theme.palette.primary[500],
        textDecoration: 'underline',
        marginLeft: '5px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
    },
    shake: {
        animation: '$shake 0.3s ease infinite',
    },
    importButton: {
        borderRadius: '4px',
        background: theme.palette.primary.deepBlue,
        color: theme.palette.common.white,
        fontSize: '14px',
        padding: '10px 20px',
        '&:hover': {
            background: theme.palette.primary[400],
        },
    },
    dropZone: ({ borderColor, backgroundColor }: {
        borderColor: string;
        backgroundColor: string;
    }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '10px',
        padding: '22px',
        minHeight: '150px',
        borderRadius: '4px',
        marginBottom: '15px',
        background: `
            linear-gradient(to right, ${
            borderColor} 40%, rgba(255,255,255,0) 0%) top left / 8px ${BORDER_SIZE} repeat-x, 
            linear-gradient(to right, ${
            borderColor} 40%, rgba(255,255,255,0) 0%) bottom left / 8px ${BORDER_SIZE} repeat-x, 
            linear-gradient(to bottom, ${
            borderColor} 40%, rgba(255,255,255,0) 0%) top right / ${BORDER_SIZE} 8px repeat-y, 
            linear-gradient(to bottom, ${
            borderColor} 40%, rgba(255,255,255,0) 0%) top left / ${BORDER_SIZE} 8px repeat-y, ${backgroundColor}`,
    }),
    dropZoneIcon: {
        color: theme.palette.primary.deepBlue,
        pointerEvents: 'none',
        '& svg': {
            fontSize: '55px',
        },
    },
    dropZoneTitle: {
        fontSize: '16px',
        color: theme.palette.secondary[700],
    },
    failTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '14px',
        textAlign: 'center',
        fontSize: '14px',
        color: theme.palette.common.red,
        letterSpacing: '0.43px',
    },
    browseLink: {
        color: theme.palette.primary.deepBlue,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    failDetails: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        color: theme.palette.common.red,
        cursor: 'pointer',
    },
    dropZonePlaceholder: {
        fontSize: '16px',
        color: theme.palette.secondary[700],
    },
    loadingIcon: {
        pointerEvents: 'none',
        animation: '$spin 1s linear infinite',
    },
    successIcon: {
        pointerEvents: 'none',
        color: theme.palette.common.green,
    },
    infoTitle: {
        fontSize: '16px',
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
        '& svg': {
            fontSize: '23px',
        },
        letterSpacing: '0.43px',
    },
    successTitle: {
        color: theme.palette.common.green,
        '& svg': {
            filter: `drop-shadow(1px 1px 0px ${
                theme.palette.common.green}) drop-shadow(-1px 0px 0px ${theme.palette.common.green})`,
        },
        letterSpacing: '0.43px',
    },
    errorTitle: {
        color: theme.palette.common.red,
        fontWeight: 500,
        letterSpacing: '0.43px',
        pointerEvents: 'none',
    },
    '@keyframes spin': {
        '0%': {
            rotate: '0deg',
        },
        '100%': {
            rotate: '-360deg',
        },
    },
    '@keyframes shake': {
        '0%': {
            rotate: '0',

        },
        '25%': {
            rotate: '-4deg',

        },
        '50%': {
            rotate: '4deg',

        },
        '100%': {
            rotate: '0',

        },
    },

}));

type ErrorType = 'size' | 'type' | 'misc' | null;
const AddAssets = () => {
    const {
        setMenuTabs,
    } = useContext(LayoutContext);
    const { t } = useCustomTranslation();
    const [open, setOpen] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const theme = useTheme<SkycellThemeInterface>();
    const [errorType, setErrorType] = useState<ErrorType>(null);
    const [errorReportId, setErrorReportId] = useState<string>(null);
    const [reportLoading, setReportLoading] = useState<boolean>(false);
    const [hasSuccess, setHasSuccess] = useState<boolean>(false);
    const [borderColor, setBorderColor] = useState(theme.palette.primary.deepBlue);
    const [fileDropHover, setFileDropHover] = useState(false);
    const classes = useStyles({
        borderColor,
        backgroundColor: '#F3F3F3',
    });

    useEffect(() => {
        setFile(null);
        setErrorType(null);
        setHasSuccess(false);
        setIsLoading(false);
        setErrorReportId(null);
        setBorderColor(theme.palette.primary.deepBlue);
    }, [open]);
    const {
        FlexibleRequest: importRequest,
    } = useSecureBackendEndpoints('assets/import').requests;

    const closeModal = useCallback(() => {
        setOpen(false);
    }, []);

    const importCallback = useCallback(() => {
        if (file) {
            const formData = new FormData();

            formData.append('file', file);

            setIsLoading(true);
            importRequest('POST', '', formData, {
                'Content-Type': 'multipart/form-data',
            }).then((res) => {
                if (res.status === 200) {
                    setHasSuccess(true);
                    setBorderColor(theme.palette.common.green);
                    setIsLoading(false);
                } else {
                    setBorderColor(theme.palette.common.red);
                    setErrorType('misc');
                }
            }).catch((e) => {
                const reportId = e?.response?.data?.errorReportId;

                if (reportId) {
                    setErrorReportId(reportId);
                }
                setErrorType('misc');
                setBorderColor(theme.palette.common.red);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }, [file]);

    const handleFileChange = useCallback((e) => {
        const f:File = e.target.files?.[0];

        if (f) {
            const sizeMb = f.size / 1024 / 1024;
            const fileExtension = f.name.split('.').pop().toLowerCase();

            if (fileExtension !== 'xlsx' && fileExtension !== 'xls') {
                setErrorType('type');
                setBorderColor(theme.palette.common.red);
                setFile(null);
            } else if (sizeMb > 5) {
                setErrorType('size');
                setBorderColor(theme.palette.common.red);
                setFile(null);
            } else {
                setFile(f);
                setErrorType(null);
            }
        }
    }, []);

    useEffect(() => {
        setMenuTabs([
            {
                title: t('ASSET_IMPORT.ADD_ASSETS'),
                onClick: () => setOpen(true),
                activePage: false,
                iconSrc: icons.addCircle,
                iconStyle: {
                    width: '15px',
                    height: '15px',
                    marginRight: '4px',
                },
            },
        ]);

        return () => setMenuTabs([]);
    }, []);

    const dragEvents = useMemo(() => ({
        onDragEnter: (e) => {
            e.stopPropagation();
            e.preventDefault();
            setBorderColor(theme.palette.primary[400]);
            setFileDropHover(true);
        },
        onDragLeave: (e) => {
            e.stopPropagation();
            e.preventDefault();
            setBorderColor(theme.palette.primary.deepBlue);
            setFileDropHover(false);
        },
        onDragOver: (e) => {
            e.stopPropagation();
            e.preventDefault();
        },
        onDrop: (e) => {
            e.stopPropagation();
            e.preventDefault();
            setFileDropHover(false);
            setErrorType(null);
            setErrorReportId(null);
            setHasSuccess(false);
            handleFileChange({
                target: {
                    files: e.dataTransfer.files,
                },
            });
            setBorderColor(theme.palette.primary.deepBlue);
        },
    }), []);
    const downloadReport = useCallback(() => {
        setReportLoading(true);
        importRequest('GET', `error-reports/${errorReportId}`, {}, {}, {
            responseType: 'blob',
        }).then((response) => {
            const href = URL.createObjectURL(response.data);

            const link = document.createElement('a');

            link.href = href;
            link.setAttribute('download', 'ErrorReport.xlsx');
            link.click();
            link.remove();

            URL.revokeObjectURL(href);
        }).finally(() => {
            setReportLoading(false);
        });
    }, [errorReportId]);

    return (
        <Modal open={open}>
            <ModalDialog className={classes.modalWrapper}>
                <div className={classes.backdrop} onClick={closeModal} />
                <div className={classes.modalWindow}>
                    <Close className={classes.closeIcon} onClick={closeModal} />
                    <div className={classes.modalTitle}>{t('ASSET_IMPORT.IMPORT_ASSETS')}</div>
                    <input
                        onChange={handleFileChange}
                        ref={fileInputRef}
                        multiple={false}
                        accept=".xls,.xlsx"
                        style={{ display: 'none' }}
                        id="xlsUpload"
                        type="file"
                    />
                    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                    <label
                        {...dragEvents}
                        htmlFor="xlsUpload"
                        onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
                        className={classes.dropZone}
                    >
                        {
                            !errorType && (
                                <div className={classes.dropZoneIcon}>
                                    {
                                        !isLoading && !file && (
                                            <UploadFile className={fileDropHover ? classes.shake : ''} />
                                        )
                                    }
                                    {
                                        isLoading && (
                                            <RotateLeft
                                                className={classes.loadingIcon}
                                            />
                                        )
                                    }
                                    {
                                        hasSuccess && (
                                            <InsertDriveFileOutlined
                                                className={classes.successIcon}
                                            />
                                        )
                                    }
                                </div>
                            )
                        }

                        {
                            file && !isLoading && !errorType && !hasSuccess && (
                                <div className={classes.dropZoneTitle}>
                                    {
                                `${file.name} (${(file.size / 1024 / 1024).toFixed(2)}MB)`
                                    }
                                </div>
                            )
                        }
                        {
                            !file && !isLoading
                            && (
                                <div className={classes.dropZonePlaceholder}>
                                    {
                                        !fileDropHover && (
                                            <>
                                                <span style={{ pointerEvents: 'none' }}>
                                                    {t('ASSET_IMPORT.DRAG_N_DROP_OR')}
                                                </span>
                                                {' '}
                                                <span
                                                    className={classes.browseLink}
                                                    onClick={() => { fileInputRef.current?.click(); }}
                                                >
                                                    {t('ASSET_IMPORT.BROWSE')}
                                                </span>
                                            </>
                                        )
                                    }
                                    {
                                        fileDropHover && (
                                            <>{t('ASSET_IMPORT.DROP_TO_PROCEED')}</>
                                        )
                                    }
                                    <div style={{
                                        color: theme.palette.secondary[400],
                                        fontSize: '12px',
                                        textAlign: 'center',
                                        marginTop: '8px',
                                    }}
                                    >
                                        {t('ASSET_IMPORT.SUPPORT_XLSX')}
                                    </div>

                                </div>
                            )
                        }
                        {
                            hasSuccess && (
                                <div className={[classes.infoTitle, classes.successTitle].join(' ')}>
                                    <CheckOutlined />
                                    {t('ASSET_IMPORT.FILES_UPLOADED_SUCCESSFULLY')}
                                </div>
                            )
                        }
                        {
                            errorType === 'type' && (
                                <div className={[classes.infoTitle, classes.errorTitle].join(' ')}>
                                    <ErrorOutline />
                                    {t('ASSET_IMPORT.INCORRECT_FORMAT')}
                                </div>
                            )
                        }
                        {
                            errorType === 'size' && (
                                <div className={[classes.infoTitle, classes.errorTitle].join(' ')}>
                                    <ErrorOutline />
                                    {t('ASSET_IMPORT.FILE_EXCEEDS_SIZE')}
                                </div>
                            )
                        }
                        {
                            errorType === 'misc' && errorReportId && (
                                <div className={classes.failTitle}>
                                    <div>
                                        {t('ASSET_IMPORT.UPLOAD_FAILED_DOWNLOAD_REPORT')}
                                    </div>
                                    <div
                                        className={classes.failDetails}
                                        onClick={reportLoading ? null : downloadReport}
                                    >
                                        <DownloadComponentIcon size={20} color={theme.palette.primary[500]} />
                                        <div
                                            className={classes.link}
                                        >
                                            ErrorReport.xlsx
                                            {
                                                reportLoading && (
                                                    <div style={{ width: 0, overflow: 'visible' }}>
                                                        <CircularProgress size={14} style={{ marginLeft: '4px' }} />
                                                    </div>
                                                )
                                            }

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {
                            errorType === 'misc' && !errorReportId && (
                                <div className={classes.failTitle}>
                                    <div>
                                        {t('ASSET_IMPORT.UPLOAD_FAILED_UNKNOWN')}
                                    </div>
                                </div>

                            )
                        }

                    </label>
                    <div className={classes.buttonHolder}>
                        {
                            !errorReportId && !errorType && !hasSuccess && (
                                <Button
                                    size="medium"
                                    className={classes.importButton}
                                    disabled={isLoading || !file}
                                    onClick={importCallback}
                                >
                                    {t('ASSET_IMPORT.IMPORT')}
                                </Button>
                            )
                        }
                    </div>
                </div>
            </ModalDialog>
        </Modal>
    );
};

export default AddAssets;
