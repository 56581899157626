import React from 'react';

export type KeyType = 'SHIPMENT_NUMBER'
    | 'LANE_NAME'
    | 'PRODUCT_RELEASE_TITLE'
    | 'SITE_NAME'
    | 'customBreadcrumb'
    | 'excludedPathsList'
    | 'entityType'

export type StoredDataType = string | string[]

export interface BreadcrumbsNamespace {
    [key: string]: StoredDataType
}
interface BreadcrumbsContextInterface {
    getBreadcrumbData: (key: KeyType) => StoredDataType,
    setBreadcrumbData: (key: KeyType, value: StoredDataType) => void,
}

const initialState:BreadcrumbsContextInterface = {
    getBreadcrumbData: () => '',
    setBreadcrumbData: () => {},
};

const BreadcrumbsContext = React.createContext<BreadcrumbsContextInterface>(initialState);

export default BreadcrumbsContext;
