import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    box: {
        display: 'flex',
        alignItems: 'flex-end',
        width: '234px',
        textAlign: 'left',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    title: {
        font: 'normal normal normal 16px/20px Roboto',
        letterSpacing: '0.26px',
        color: theme.palette.secondary[900],
        opacity: 1,
        marginBottom: '12px',
    },
    input: {
        width: '206px',
        textAlign: 'left',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        color: theme.palette.secondary[600],
        opacity: 1,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    expandButtonText: {},
    searchPreview: {
    },
    searchPreviewShipmentContainer: {
        maxWidth: '210px',
    },
    shipmentsContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '4px',
    },
    searchPreviewShipmentTitle: {
        font: 'normal normal normal 12px/19px Roboto',
        letterSpacing: '0.25px',
        color: '#009CCF !important',
        margin: '12px 0',
        whiteSpace: 'normal',
    },
    searchPreviewShipment: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#F4F4F4 0% 0% no-repeat padding-box',
        textAlign: 'left',
        font: 'normal normal normal 12px/19px Roboto',
        letterSpacing: '0.25px',
        color: '#000000DE',
        borderRadius: '16px',
        opacity: 1,
        padding: '3px 8px',
    },
    tooltipPlacementTop: {
        marginTop: '4px!important',
        font: 'normal normal normal 12px/14px Roboto',
    },
    filterBox: {
        padding: '16px',
        '&>*': {
            width: '100%',
        },
    },
    divider: {
        width: '100%',
        height: '1px',
        background: theme.palette.secondary[200],
    },
}));

export default useStyles;
