import React from 'react';
import clsx from 'clsx';
import { Typography } from '@mui/material';
// import { logout } from '@skycell-ag/auth';
// import logout from 'auth/logout';
// import { AuthContext } from 'Contexts/AuthContext';
// import useCustomTranslation from 'hooks/useCustomTranslation';
import MainUserInfo from 'Sidebar/components/MainUserInfo';
import useScreenSize from 'hooks/useScreenSize';
import { ScreenType } from 'hooks/useScreenSize/useScreenSize';
import useStyles from './MenuBar.style';

type Props = {
    userName: string,
    isFixed: boolean,
    barOpen: boolean
}

const MenuBar = ({
    userName = '',
    isFixed = false,
    barOpen,
} : Props) => {
    const classes = useStyles();
    // const { logout } = useContext(AuthContext);
    // const { t } = useCustomTranslation();
    const {
        checkScreenType,
    } = useScreenSize();

    return (
        <div
            className={clsx(
                classes.menuBar,
                isFixed && classes.fixedPosition,
            )}
            style={{
                height: barOpen ? 40 : 0,
                overflow: checkScreenType.down(ScreenType.XS) ? (barOpen ? 'visible' : 'hidden') : 'visible',
            }}
        >
            <div className={classes.rightContainer}>
                <div
                    className={classes.userWrapper}
                    style={{ opacity: barOpen ? 1 : 0 }}
                >
                    <Typography variant="h5">
                        {userName}
                    </Typography>
                </div>
                {/* <div
                    className={classes.logoutBtn}
                    onMouseDown={logout}
                    style={{ opacity: barOpen ? 1 : 0 }}
                >
                    {t('COMMON.LOG_OUT')}
                </div> */}
                <MainUserInfo />
            </div>
        </div>
    );
};

export default MenuBar;
