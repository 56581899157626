import React, {
    useEffect, useMemo,
    useState,
} from 'react';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import useGetTranslationGroup from 'hooks/useGetTranslationGroup';
import { RequestStatus } from 'dataTypes/common';
import { PageWithFilter } from 'Layout';
import Table, { useTableSideFilter } from 'shared-components/Table';

import useCustomTranslation from 'hooks/useCustomTranslation';
import useHasAccess, { userRoles } from 'hooks/useHasAccess';
import tableColumns from './tableColumns';

const Users = () => {
    const userRoleLabels = useGetTranslationGroup('USER_POLICY');
    const { t } = useCustomTranslation();
    const hasRoles = useHasAccess();
    const { GetAll: getUsers } = useSecureBackendEndpoints(
        hasRoles(userRoles.SKYCELL_ADMIN) ? 'admin/users'
            : 'users',
    ).requests;
    const { GetAll: getAllCompanies } = useSecureBackendEndpoints('companies').requests;

    const [users, setUsers] = useState([]);
    const [status, setStatus] = useState<RequestStatus>('INIT');
    const [filteredUsers, setFilteredUsers] = useState([]);

    useEffect(() => {
        if (Object.keys(userRoleLabels).length && status !== 'PENDING') {
            (async () => {
                try {
                    setStatus('PENDING');
                    const { resultList: users = [] } = (await getUsers()).data;
                    const { resultList: companies = [] } = (await getAllCompanies()).data;

                    const processedUsersInfo = users.map(user => {
                        const company = companies.find(obj => obj.id === Number(user.companyId));
                        const {
                            email = '',
                            fullName = null,
                            id = null,
                            userRoles = [],
                        } = user || {};
                        const { firstName = '', lastName = '' } = fullName || {};
                        const { name: companyName = null } = company || {};

                        return {
                            id,
                            userName: `${firstName || ''} ${lastName || ''}`,
                            email,
                            companyName,
                            roles: userRoles.map(roles => userRoleLabels[roles] || roles),
                        };
                    });

                    setUsers(processedUsersInfo);
                    setStatus('SUCCESS');
                } catch (error) {
                    setStatus('FAILURE');
                    setUsers([]);
                }
            })();
        }
    }, [userRoleLabels]);

    const columns = useMemo(() => tableColumns(t), []);
    const {
        component: FilterComponent,
        selectedFilterOptions,
    } = useTableSideFilter({
        columns,
        data: users,
        setFilteredData: setFilteredUsers,
    });

    return (
        <PageWithFilter>
            {FilterComponent}
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: '10px' }}>
                <Table
                    columns={columns}
                    data={filteredUsers}
                    maskForHighlight={`${(selectedFilterOptions?.userName?.[0]?.toString()?.length > 2
                        && selectedFilterOptions?.userName?.[0])
                    || (selectedFilterOptions?.email?.[0]?.toString()?.length > 2
                    && selectedFilterOptions?.email?.[0])}`}
                    tableMaxHeight="100%"
                    title={t('MENU_ITEMS.USERS')}
                    rowLinkTemplate="/administration/users/:id"
                />
            </div>
        </PageWithFilter>
    );
};

export default Users;
