import React, {
    useState,
} from 'react';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import TextField from '@mui/material/TextField';
// import timezones from 'utils/timezones';
import { makeStyles } from '@mui/styles';

import { TIME_IN_MS } from 'shared-components/constants';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        '& .css-1aquho2-MuiTabs-indicator': {
            backgroundColor: '#61C6E9!important',
        },
    },
    definePeriodDialogTitle: {
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: '#939393',
        opacity: 1,
    },
    textFieldText: {
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    timeZone: {
        marginLeft: '10px',
    },
    textFieldTextAutocomplete: {
        padding: '0',
        paddingRight: '10px!important',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
        '& input': {
            padding: '3px 15px 3px 0!important',
        },
    },
    dateText: {
        '& input': {
            cursor: 'pointer',
        },
        '& label': {
            color: 'rgb(183, 183, 183)',
        },
    },
    datePicker: {
        '& .Mui-selected': {
            background: '#61C6E9!important',
            color: '#FFF!important',
        },
        '& .MuiButton-textPrimary': {
            color: '#61C6E9!important',
        },
        '& .MuiClock-pin': {
            backgroundColor: '#61C6E9!important',
        },
        '& .MuiClockPointer-root': {
            backgroundColor: '#61C6E9!important',
        },
        '& .MuiClockPointer-thumb': {
            backgroundColor: '#61C6E9!important',
            border: '16px solid #61C6E9',
        },
        '& .MuiTabs-indicator': {
            backgroundColor: '#61C6E9!important',
        },
    },
}));

type Props = {
    setUtcDateTime: (date: string) => void,
    timeZone: string,
    defaultValue?: Moment,
    title: string,
}

const DateTimePickerShipmentPeriod = ({
    setUtcDateTime = () => {},
    timeZone,
    defaultValue = moment().add(7, 'days'),
    title = '',
}: Props) => {
    const classes = useStyles();
    const [dateTime, setDateTime] = useState<Moment>(defaultValue);

    const handleDateTime = (value: Moment) => {
        if (value !== null) {
            setDateTime(value);
            const rate = timeZone.replace(/\d|:/g, '') || '+';
            const [hours, minutes] = timeZone.replace(/\+|-/, '').split(':')
                .map(str => Number(str));
            const timeZoneOffset = (rate === '+' ? -1 : 1)
                * (hours * TIME_IN_MS.hour + minutes * TIME_IN_MS.minute);

            setUtcDateTime(moment(value.valueOf() + timeZoneOffset).format('yyyy-MM-DDTHH:mm'));
        }
    };

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={classes.definePeriodDialogTitle}>{title}</div>
                <DateTimePicker
                    DialogProps={{ className: classes.datePicker }}
                    value={dateTime}
                    inputFormat={`DD.MM.YYYY HH:mm ${timeZone}`}
                    minDate={moment('2020-01-01T00:00')}
                    maxDate={moment('2090-01-01T00:00')}
                    onChange={handleDateTime}
                    ampm={false}
                    renderInput={(params) => {
                        return (
                            <TextField
                                className={classes.dateText}
                                {...params}
                            />
                        );
                    }}
                />
            </LocalizationProvider>
        </div>
    );
};

export default DateTimePickerShipmentPeriod;
