import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    contentContainer: {
        display: 'grid',
        gridTemplateColumns: '8fr minmax(266px, 1fr)',
        gridGap: theme.spacing(1.5),
        padding: '0 12px 12px 12px',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column-reverse',
        },
    },
    contentRightColumn: {
        display: 'grid',
        gridGap: theme.spacing(1.5),
        // gridAutoRows: 'auto',
        marginTop: 38,
        alignContent: 'flex-start',
    },
}));

export default useStyles;
