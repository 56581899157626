import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    buttonStyle: {
        background: `${theme.palette.primary[200]} 0% 0% no-repeat padding-box`,
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        opacity: '1',
        margin: '5px',
        font: 'normal normal 500 14px/17px Roboto',
        letterSpacing: '1.25px',
        color: theme.palette.common.white,
        textTransform: 'uppercase',
        width: '80%',
        height: '36px',
        '&:hover': {
            backgroundColor: theme.palette.primary[600],
        },
        '&:disabled': {
            backgroundColor: theme.palette.secondary[300],
            color: theme.palette.common.white,
        },
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '12px',
    },

    loading: {
        filter: 'blur(1px)',
    },
}));
