import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import LastConnect from 'shared-components/LastConnect';
import { ExtractedLoggerData } from 'TrackAndTrace/Loggers/lib';

const tableColumns: (t) => ColumnsType<ExtractedLoggerData>[] = (t) => [
    {
        Header: t('COMMON.LOGGER_NUMBER'),
        accessor: 'loggerNumber',
        dataType: 'highlightedText',
    },
    {
        Header: t('COMMON.TYPE'),
        accessor: 'loggerFamilyLabel',
        dataType: 'highlightedText',
    },
    {
        Header: t('COMMON.TEMPERATURE'),
        accessor: 'lastMeasuredTempStr',
        dataType: 'text',
    },
    {
        Header: t('TRACK_AND_TRACE.LAST_UPDATED'),
        accessor: (row) => <LastConnect timestamp={row.lastMeasuredTimestamp} />,
        dataType: 'custom',
    },
];

export default tableColumns;
