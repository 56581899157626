import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    preview: (props: { searchBoxLeft:number }) => ({
        position: 'absolute',
        opacity: 0,
        pointerEvents: 'none',
        zIndex: 40000,
        transition: '300ms ease',
        width: 'auto',
        maxWidth: `calc(100vw - ${props.searchBoxLeft + 40}px)`,
        marginTop: '5px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 'calc( 100vw - 80px )',
        },
    }),
    open: () => ({
        opacity: '1!important',
        pointerEvents: 'unset',
    }),
    input: {
        textAlign: 'left',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        color: theme.palette.secondary[600],
        opacity: 1,
        height: '31px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        '& $input': {
            paddingLeft: '8px',
        },
    },
    rightArrow: {
        width: '18px',
        height: '18px',
        marginLeft: '5px',
    },
    moreSpan: {
        marginTop: '10px',
        display: 'flex',
        color: theme.palette.secondary[600],
        alignItems: 'center',
        cursor: 'pointer',
        userSelect: 'none',
        width: 'min-content',
    },
    paddedTable: {
        padding: '0 10px 10px 10px',
    },

}));

export default useStyles;
