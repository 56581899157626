import React, {
    useEffect,
    useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TimeRange } from 'dataTypes/common';
import { SensorDataRequestBody } from 'dataTypes/SecureBackend/processedData';
import useCacheContext from 'hooks/useCacheContext';
import useLoadSensorData from 'SensorDataCommon/hooks/useLoadSensorData';
import parseQueryParams from 'utils/parsePathQueries';
import {
    PathParams,
    EntitySelectorItem,
    initialEntitySelectorItem,
} from './dataTypes';
import {
    initializeTimeRange,
    initializeRequestBody,
} from './lib';
import LoggerSensorData from './LoggerSensorData';

const LoggerSensorDataContainer = ({ core = 'track-and-trace' }) => {
    const history = useHistory();
    const { getCacheValue } = useCacheContext();
    const {
        entityNumber: entityNumberInPath = '',
    } = useParams<PathParams>();
    const queryParams = parseQueryParams(history.location.search);

    const [isCurrentTime, setIsCurrentTime] = useState(true);
    const [firstEntry, setFirstEntry] = useState(true);
    const [requestBody, setRequestBody] = useState<SensorDataRequestBody>(initializeRequestBody(queryParams));
    const [timeRange, setTimeRange] = useState<TimeRange>(initializeTimeRange(true, queryParams));
    const [entitySelectorItem, setEntitySelectorItem] = useState<EntitySelectorItem>(
        !entityNumberInPath ? (getCacheValue('entitySelectorItem') || initialEntitySelectorItem)
            : initialEntitySelectorItem,
    );

    const {
        isFetchComplete,
        loggerRequestStatus,
        loggerList,
    } = useLoadSensorData(entitySelectorItem, 'loggers');

    useEffect(() => {
        if (
            entityNumberInPath
            && entitySelectorItem.entityNumber !== entityNumberInPath
        ) {
            if (loggerList.length > 0) {
                const logger = loggerList
                    .find(item => item.entityNumber.toLowerCase() === entityNumberInPath.toLowerCase())
                    || null;

                if (logger) {
                    setEntitySelectorItem(logger);
                }
            }
        }
    }, [
        loggerList,
    ]);

    useEffect(() => {
        if (!firstEntry && isFetchComplete) {
            history.push({
                pathname: `/${core}/loggers/${entitySelectorItem?.entityNumber || ''}`,
            });
        }
    }, [
        entitySelectorItem,
        requestBody,
        timeRange,
        isFetchComplete,
    ]);

    useEffect(() => {
        if (entitySelectorItem?.entityNumber) {
            if (firstEntry) {
                setFirstEntry(false);
            }
            setTimeRange(initializeTimeRange(true, queryParams, true));
        } else {
            setTimeRange({ from: null, to: null });
        }
    }, [entitySelectorItem, isCurrentTime]);

    return (
        <LoggerSensorData
            entitySelectorItem={entitySelectorItem}
            setEntitySelectorItem={setEntitySelectorItem}
            requestBody={requestBody}
            setRequestBody={setRequestBody}
            setTimeRange={setTimeRange}
            timeRange={timeRange}
            loggerList={loggerList}
            isCurrentTime={isCurrentTime}
            setIsCurrentTime={setIsCurrentTime}
            loggersFetched={loggerRequestStatus === 'SUCCESS'}
            core={core}
        />
    );
};

export default LoggerSensorDataContainer;
