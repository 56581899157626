const toDegreesMinutesAndSeconds = (coordinate) => {
    const absolute = Math.abs(coordinate);
    const degrees = Math.floor(absolute);
    const minutesNotTruncated = (absolute - degrees) * 60;
    const minutes = Math.floor(minutesNotTruncated);
    const seconds = Math.floor((minutesNotTruncated - minutes) * 60);

    return `${degrees}°${minutes}'${seconds}"`;
};

const convertLatLngDMS = (lat = 0, lng = 0) => {
    const latitude = toDegreesMinutesAndSeconds(lat);

    const latitudeCardinal = lat >= 0 ? 'N' : 'S';

    const longitude = toDegreesMinutesAndSeconds(lng);

    const longitudeCardinal = lng >= 0 ? 'E' : 'W';

    return `${latitude}${latitudeCardinal} / ${longitude}${longitudeCardinal}`;
};

export default convertLatLngDMS;
