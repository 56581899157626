import { makeStyles } from '@mui/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

export const useStyles = makeStyles((theme: SkycellThemeInterface) => ({
    link: {
        color: theme.palette.primary.deepBlue,
        textDecoration: 'none',
        '&:visited': {
            color: theme.palette.primary.deepBlue,
        },
    },
}));
