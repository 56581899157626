import moment from 'moment';
import {
    Packaging,
    Logger,
    Asset,
} from 'dataTypes/SecureBackend/apiResponse';
import { Shipment } from 'dataTypes/SecureBackend/apiResponse/Shipment';
import { SensorDataRequestBody } from 'dataTypes/SecureBackend/processedData';
import { ShipmentWithSharedField } from 'TrackAndTrace/Shipments/lib';
import {
    TIME_RANGE_PERIOD,
    TIME_IN_MS,
    assetsPath,
} from 'shared-components/constants';
import icons from 'shared-components/icons';
import dayPassedToRange from 'utils/dayPassedToRange';
import {
    // Period,
    TimeRange,
    // DAY_TO_PERIOD,
} from 'dataTypes/common';

import {
    CachedEntitySelectorItems,
    EntitySelectorItem,
    EntityType,
    dataTypeOptions,
    positionOptions,
} from './dataTypes';

export const requiredStatuses = ['SUCCESS', 'FAILURE'];

export const initializeTimeRange = (
    isFullAccess = true, queryParams: { [key: string]: string }, getOneDayRange = false,
): TimeRange => {
    const { from = null, to = null, period = null } = queryParams;

    const time = moment().format('HH:mm');

    if (isFullAccess && from && to) {
        return {
            from: moment(`${from}T${time}`).utc().format('YYYY-MM-DDTHH:mm'),
            to: moment(`${to}T${time}`).utc().format('YYYY-MM-DDTHH:mm'),
        };
    } else if (isFullAccess && period && TIME_RANGE_PERIOD[period]) {
        return dayPassedToRange(TIME_RANGE_PERIOD[period]);
    }
    if (getOneDayRange) {
        return dayPassedToRange(1);
    }

    return { from: null, to: null };
};

export const initializeRequestBody = (queryParams: { [key: string]: string }): SensorDataRequestBody => {
    const {
        dataTypes: dataTypesInQuery = '',
        positions: positionsInQuery = '',
    } = queryParams;
    const dataTypes = dataTypesInQuery.length === 0
        ? []
        : dataTypesInQuery.split(',').filter(type => dataTypeOptions.includes(type));

    const positions = positionsInQuery.length === 0
        ? []
        : positionsInQuery.split(',').filter(type => positionOptions.includes(type));

    return {
        dataTypes,
        loggers: [],
        positions,
    };
};

export const initializeEntityList = (entitySelectorItem: EntitySelectorItem, currentType: EntityType) => {
    const { entityType = null } = entitySelectorItem || {};

    return entityType === currentType
        ? [entitySelectorItem]
        : [];
};

export const getTitle = (entitySelectorItem: EntitySelectorItem, t = word => word): string => {
    const { entityType, entityNumber, additionalData } = entitySelectorItem ?? {};
    const { packagingTypeLabel = null, loggerTypeLabel = null } = additionalData ?? {};

    if (entityType === 'shipment') {
        return `${t('COMMON.SHIPMENT')} ${entityNumber} / MAWB ${additionalData.mawbNumber}`;
    } else if (entityType === 'packagings') {
        return `${t('SENSOR_DATA.DATA_READOUT_PACKAGING')} ${entityNumber}${
            packagingTypeLabel ? ` | ${packagingTypeLabel}` : ''
        }`;
    } else if (entityType === 'loggers') {
        return `${t('SENSOR_DATA.DATA_READOUT_LOGGER')} ${entityNumber}${
            loggerTypeLabel ? ` | ${loggerTypeLabel}` : ''
        }`;
    } else if (entityType === 'assets') {
        return `${t('SENSOR_DATA.DATA_READOUT_ASSET')} ${entityNumber}${
            loggerTypeLabel ? ` | ${loggerTypeLabel}` : ''
        }`;
    }

    return 'Sensor Data';
};

export const requiredDataUpdate = (timestamp: number): boolean => {
    return Date.now() - timestamp > TIME_IN_MS.minute * 5;
};

export const fetchEntitiesFromShipments = (
    rawData: ShipmentWithSharedField[],
    packagingCodeLabels = {},
) :EntitySelectorItem[] => {
    return rawData.map(shipment => {
        const {
            cargo,
            shipmentNumber,
            isShared = false,
            skyMindInfo,
            status = '',
        } = shipment;

        const {
            temperatureRange = '',
            from = '',
            to = '',
            mawbNumber = '',
            hawbNumber = '',
            shipmentStart = null,
            shipmentEnd = null,
            leaseStart = null,
            leaseEnd = null,
            leaseEndExpected = null,
        } = skyMindInfo || {};

        const packagingsInThisShipment = cargo.map(({ packaging, skyCoreProductRelease }) => {
            const {
                serialNumber,
                packagingType,
                lastMeasurementInfo = null,
            } = packaging || {};

            const {
                tempInternal: lastMeasuredTempInternal = null,
                tempInternalTimestamp: lastMeasuredTempInternalTimestamp,
                geolocation: lastMeasuredGeolocation = null,
            } = lastMeasurementInfo || {};

            const {
                type = '',
                packagingPictureUrl = null,
                temperatureRange,
            } = packagingType || {};
            const { id } = skyCoreProductRelease || {};

            return {
                packagingType: type,
                packagingTypeLabel: packagingCodeLabels[type] || type,
                packagingPictureUrl: packagingPictureUrl
                    ? `${assetsPath}/assets${packagingPictureUrl}`
                    : icons.default_packaging_icon,
                productReleaseId: id,
                serialNumber,
                lastMeasuredGeolocation,
                lastMeasuredTempInternal,
                lastMeasuredTempInternalTimestamp: moment(lastMeasuredTempInternalTimestamp).utc(true).valueOf(),
                temperatureRange,
            };
        });

        return {
            entityNumber: shipmentNumber.toUpperCase(),
            entityType: 'shipment',
            additionalData: {
                iataCodeFrom: from,
                iataCodeTo: to,
                mawbNumber,
                hawbNumber,
                isShared,
                packagingCount: cargo.length,
                temperatureRange,
                packagingsInThisShipment,
                shipmentStatus: status,
                shipmentStart,
                shipmentEnd,
                leaseStart,
                leaseEnd,
                leaseEndExpected,
            },
        };
    });
};

export const fetchEntitiesFromSharedShipments = (rawData: Shipment[],
    packagingCodeLabels = {}) :EntitySelectorItem[] => {
    return rawData.map(shipment => {
        const {
            cargo,
            shipmentNumber,
            isShared = true,
            skyMindInfo,
            status = '',
        } = shipment;

        const {
            temperatureRange = '',
            from = '',
            to = '',
            mawbNumber = '',
            hawbNumber = '',
            shipmentStart = null,
            shipmentEnd = null,
            leaseStart = null,
            leaseEnd = null,
            leaseEndExpected = null,
        } = skyMindInfo || {};

        const packagingsInThisShipment = cargo.map(({ packaging, skyCoreProductRelease }) => {
            const {
                serialNumber,
                packagingType,
                lastMeasurementInfo = null,
            } = packaging || {};

            const {
                tempInternal: lastMeasuredTempInternal = null,
                tempInternalTimestamp: lastMeasuredTempInternalTimestamp,
                geolocation: lastMeasuredGeolocation = null,
            } = lastMeasurementInfo || {};

            const {
                type = '',
                packagingPictureUrl = null,
                temperatureRange,
            } = packagingType || {};
            const { id } = skyCoreProductRelease || {};

            return {
                packagingType: type,
                packagingTypeLabel: packagingCodeLabels[type] || type,
                packagingPictureUrl: packagingPictureUrl
                    ? `${assetsPath}/assets${packagingPictureUrl}`
                    : icons.default_packaging_icon,
                productReleaseId: id,
                serialNumber,
                lastMeasuredGeolocation,
                lastMeasuredTempInternal,
                lastMeasuredTempInternalTimestamp: moment(lastMeasuredTempInternalTimestamp).valueOf(),
                temperatureRange,
            };
        });

        return {
            entityNumber: shipmentNumber.toUpperCase(),
            entityType: 'shipment',
            additionalData: {
                iataCodeFrom: from,
                iataCodeTo: to,
                mawbNumber,
                hawbNumber,
                isShared,
                packagingCount: cargo.length,
                temperatureRange,
                packagingsInThisShipment,
                shipmentStatus: status,
                shipmentStart: shipmentStart ? moment(shipmentStart).utc().format('YYYY-MM-DDTHH:mm') : null,
                shipmentEnd: shipmentEnd ? moment(shipmentEnd).utc().format('YYYY-MM-DDTHH:mm') : null,
                leaseStart: leaseStart ? moment(leaseStart).utc().format('YYYY-MM-DDTHH:mm') : null,
                leaseEnd: leaseEnd ? moment(leaseEnd).utc().format('YYYY-MM-DDTHH:mm') : null,
                leaseEndExpected: leaseEndExpected ? moment(leaseEndExpected).utc().format('YYYY-MM-DDTHH:mm') : null,
            },
        };
    });
};

export const fetchEntitiesFromPackagings = (rawData: Packaging[], packagingCodeLabels = {}) :EntitySelectorItem[] => {
    return rawData
        .filter(packaging => packaging.currentShipment === null)
        .map((packaging) => {
            const {
                serialNumber = '',
                packagingType,
                currentShipment = '',
                lastMeasurementInfo = null,
            } = packaging;

            const {
                tempInternal: lastMeasuredTempInternal = null,
                tempInternalTimestamp: lastMeasuredTempInternalTimestamp,
                geolocation: lastMeasuredGeolocation = null,
            } = lastMeasurementInfo || {};

            const {
                type = '',
                temperatureRange = '',
                packagingPictureUrl = null,
            } = packagingType || {};

            return {
                entityNumber: serialNumber.toUpperCase(),
                entityType: 'packagings',
                additionalData: {
                    currentShipmentNumber: currentShipment,
                    lastMeasuredGeolocation,
                    packagingPictureUrl: packagingPictureUrl
                        ? `${assetsPath}/assets${packagingPictureUrl}`
                        : icons.default_packaging_icon,
                    packagingType: type,
                    packagingTypeLabel: packagingCodeLabels[type] || type,
                    temperatureRange,
                    lastMeasuredTempInternal,
                    // lastMeasuredTempInternalTimestamp: new Date(lastMeasuredTempInternalTimestamp).getTime(),
                    lastMeasuredTempInternalTimestamp: moment(lastMeasuredTempInternalTimestamp).utc(true).valueOf(),
                },
            };
        });
};

export const fetchEntitiesFromLoggers = (rawData: Logger[], loggerTypeLabels = {}) :EntitySelectorItem[] => {
    return rawData
        .map((logger) => {
            const {
                loggerNumber,
                loggerTypeCode,
                lastMeasuredInfo,
            } = logger;
            const {
                temperature: lastMeasuredTempInternal = null,
                temperatureGeolocationTimestamp = '',
                geolocation: lastMeasuredGeolocation = null,
            } = lastMeasuredInfo || {};

            const loggerFamily = loggerTypeCode || '';

            return {
                entityNumber: loggerNumber,
                entityType: 'loggers',
                additionalData: {
                    loggerTypeCode: loggerFamily,
                    loggerTypeLabel: loggerTypeLabels[loggerTypeCode] || loggerTypeCode,
                    // lastMeasuredTemp,
                    lastMeasuredTempAmbient: null,
                    lastMeasuredTempInternal,
                    lastMeasuredTempInternalTimestamp: moment(temperatureGeolocationTimestamp).utc(true).valueOf(),
                    lastMeasuredGeolocation,
                },
            };
        });
};

export const fetchAssets = (rawData: Asset[], loggerTypeLabels = {}) :EntitySelectorItem[] => {
    return rawData
        .filter(({ assetNumber }) => assetNumber !== null)
        .map((asset) => {
            const {
                assetNumber = '',
                loggerNumber,
                assetTypeCode: code,
                id: entityId,
            } = asset;

            return {
                entityNumber: assetNumber,
                entityType: 'assets',
                entityId,
                additionalData: {
                    assetNumber,
                    assetType: code,
                    loggerNumber,
                    loggerTypeLabel: loggerTypeLabels[code] || code,
                },
            };
        });
};

export const prepareDataToCache = (data: EntitySelectorItem[]): CachedEntitySelectorItems => {
    return {
        items: data,
        timestamp: Date.now(),
    };
};

export const getRequestParametersString = (obj: object): string => {
    const parameters: string[] = Object.keys(obj).reduce((data, key) => {
        const val = obj[key];

        if (Array.isArray(val)) {
            return val.length === 0
                ? data
                : [...data, val.map(it => `${key}=${it}`).join('&')];
        } else if (val instanceof Object) {
            return [...data, getRequestParametersString(val)];
        }

        return [...data, `${key}=${val}`];
    }, []);

    return parameters.join('&');
};
