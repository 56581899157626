import { Button, Tooltip } from '@mui/material';
import React from 'react';
import { useStyles } from './ProposedValueTooltip.style';

type Props = {
    proposedValue: any,
    proposedValueText: any,
    onUse: any,
    title?: string,
    bottomTitle?: string,
    children?: any,
    open?: boolean,
}

const ProposedValueTooltip = ({
    // eslint-disable-next-line no-unused-vars
    proposedValue,
    proposedValueText,
    onUse,
    title = 'Proposed Duration: ',
    bottomTitle = 'Source: Duration by Car x 1.5 From Google Maps',
    children,
    open = false,
}: Props) => {
    const classes = useStyles();

    return (
        <Tooltip
            title={(
                <div
                    className={classes.tooltipContents}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className={classes.left}>
                        <span className={classes.topText}>
                            <span>{title}</span>
                            <span>
                                {proposedValueText}
                            </span>
                        </span>
                        <span className={classes.bottomText}>
                            {bottomTitle}
                        </span>

                    </div>
                    <div className={classes.right}>
                        {
                            onUse && (
                                <Button
                                    className={classes.button}
                                    onClick={onUse}
                                >
                                    USE
                                </Button>
                            )
                        }
                    </div>
                </div>
            )}
            open={open}
            arrow
            classes={{
                tooltip: classes.proposedToolTip,
                arrow: classes.proposedToolTipArrow,
                popper: classes.tooltipPopper,
            }}
        >
            {children}
        </Tooltip>

    );
};

export default ProposedValueTooltip;
