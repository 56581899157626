import React from 'react';
import {
    PackagingType,
    SimulationDataHolder,
} from 'LaneManagement/Lanes/tabs/TemperatureSimulation/TemperatureSimulation';
import { LaneObject } from 'shared-components/dataTypes';

interface LaneTemperatureContextInterface {
    packagingTypes?: PackagingType[],
    updatePackagingTypes?: Function,
    deletePackagingType?: Function,
    addPackagingType?: any,
    lane?: LaneObject,
    loading?: boolean,
    hasError?: boolean,
    profileData?: {
        [key: string]: SimulationDataHolder
    },
    setProfileData: Function,
}

const initialState = {
    packagingTypes: null,
    updatePackagingTypes: () => {},
    deletePackagingType: () => {},
    addPackagingType: () => {},
    lane: null,
    loading: false,
    hasError: false,
    profileData: { },
    setProfileData: () => {},
};
const LaneTemperatureContext = React.createContext<LaneTemperatureContextInterface>(initialState);

export default LaneTemperatureContext;
