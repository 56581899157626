import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import useHasAccess from 'hooks/useHasAccess';

import { StandardItem } from 'shared-components/menuItems';
import MenuItemLink from '../MenuItemLink';
import useStyles from './MenuItemCollapse.style';
import MenuItemFloatingChildren from '../MenuItemFloatingChildren';

type Props = {
    children: StandardItem[],
    currentLink: string,
    icon: string,
    isMobileDevice: boolean,
    link: string,
    setSidebarState: (sidebarState: boolean) => void,
    sidebarOpen: boolean,
    title: string,
}

const MenuItemCollapse = ({
    children = [],
    currentLink,
    icon = null,
    isMobileDevice,
    link,
    setSidebarState,
    sidebarOpen,
    title,
} : Props) => {
    const classes = useStyles();
    const hasAccess = useHasAccess();

    const hasActiveChild = useMemo(() => {
        return children.some((child) => child.link === currentLink);
    }, [currentLink]);
    const [linkFocused, setLinkFocused] = useState(false);
    const [childLinkFocused, setChildLinkFocused] = useState(false);

    const mainLink = useMemo(() => {
        // temporary
        // if (link === 'track-and-trace') {
        //     return `/${children[1].link}`;
        // }

        const childWithAccess = children.find(child => (child.sufficientRoles
            ? child.sufficientRoles.some(it => hasAccess(it))
            : (child.necessaryRoles || []).every(it => hasAccess(it)))) || null;

        return `/${childWithAccess ? childWithAccess.link : ''}`;
        // return `/${childWithAccess[0].link}`;
    }, [link]);

    const visibleChildren = useMemo(() => children
        .filter((item) => (item.sufficientRoles ? (item.sufficientRoles || [])
            .some(it => hasAccess(it)) : (item.necessaryRoles || []).every(it => hasAccess(it))))
        .filter(it => !it.hide), [children]);

    return (
        <div onClick={(event => event.stopPropagation())}>
            <Link
                className={classes.a}
                key={`link_to_${link}`}
                to={mainLink}
                onMouseEnter={() => setLinkFocused(true)}
                onMouseLeave={() => setLinkFocused(childLinkFocused)}
            >
                <ListItem
                    key={`${link}_button`}
                    button
                    className={[
                        (hasActiveChild && !sidebarOpen) ? classes.activeItem : '',
                        hasActiveChild ? classes.activeListItemStyle : classes.listItemStyle,
                    ].join(' ')}
                >
                    <div className={classes.icon}>
                        {
                            icon && (
                                <img
                                    key={`icon_${link}`}
                                    className={classes.icon}
                                    src={icon}
                                    alt={title}
                                />
                            )
                        }
                    </div>
                    <div
                        className={classes.listItemTextStyle}
                        hidden={!sidebarOpen}
                    >
                        {title}
                    </div>
                    {
                        !sidebarOpen && children && hasActiveChild && linkFocused && (
                            <MenuItemFloatingChildren
                                childLinks={visibleChildren}
                                currentLink={currentLink}
                                title={title}
                                setChildLinkFocused={setChildLinkFocused}
                            />
                        )
                    }
                </ListItem>
            </Link>
            <Collapse
                in={sidebarOpen && hasActiveChild}
                timeout={0}
                unmountOnExit
            >
                <div className={classes.block}>
                    {
                        sidebarOpen && visibleChildren
                            .map((child) => {
                                const isActive = child.link === currentLink;

                                return (
                                    <MenuItemLink
                                        type={isActive ? 'activeChild' : 'child'}
                                        key={`MenuItemLink_${child.id}`}
                                        isMobileDevice={isMobileDevice}
                                        isTextHidden={!sidebarOpen}
                                        link={child.link}
                                        setSidebarState={setSidebarState}
                                        title={child.title}
                                    />
                                );
                            })
                    }
                </div>
            </Collapse>
        </div>
    );
};

export default MenuItemCollapse;
