import React, { useMemo, useEffect, useContext, useState } from 'react';
import BackToLink from 'shared-components/BackToLink';
import useCacheContext from 'hooks/useCacheContext';
import { useHistory, useParams } from 'react-router-dom';
import useCustomTranslation from 'hooks/useCustomTranslation';
import Table from 'shared-components/Table/Table';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import AddCircle from '@mui/icons-material/AddCircle';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import useGetCommonData from 'hooks/useGetCommonData';
import { Asset } from 'dataTypes/SecureBackend/apiResponse';
import { LayoutContext } from 'Contexts/LayoutContext';
import ShortPackagingInfo from 'shared-components/ShortPackagingInfo';
import { PICTURES } from 'shared-components/constants';
import Autocomplete from '@mui/material/Autocomplete';
import QuickHelpTooltip from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import TextField from '@mui/material/TextField';
import RenderOptionItem from 'SensorDataCommon/components/EntitySelector/RenderOptionItem';
import Card from 'shared-components/Card';
import NoOpenEntity from 'SensorDataCommon/components/NoOpenEntity';
import UncontrolledTooltip from 'shared-components/ControlledTooltip/UncontrolledTooltip';
import useEditablePairingsColumns from './hooks/useEditablePairingsColumns/useEditablePairingsColumns';
import useStyles from './components/AssetUnit/AssetUnit.style';

const AssetPairings = () => {
    const { getCacheValue } = useCacheContext();
    const {
        entityNumber = '',
    } = useParams<{entityNumber: string}>();
    const { t } = useCustomTranslation();
    const classes = useStyles();
    const theme = useTheme<SkycellThemeInterface>();
    const {
        userInfo,
    } = useCurrentUserContext();
    const {
        setMenuTabs,
        setCustomPageTitle,
    } = useContext(LayoutContext);
    const [sampleForSearch, setSampleForSearch] = useState('');
    const history = useHistory();

    useEffect(() => {
        setMenuTabs([
            {
                title: t('COMMON.READOUT'),
                link: `/asset-management/assets/readout/${entityNumber}`,
                iconSrc: '',
                activePage: false,
            },
            {
                title: t('SENSOR_DATA.LOGGER_PAIRINGS'),
                link: `/asset-management/assets/pairings/${entityNumber}`,
                iconSrc: '',
                activePage: true,
            },
        ]);
        setCustomPageTitle(t('MENU_ITEMS.ASSET_DETAILS'));
        return () => {
            setMenuTabs([]);
            setCustomPageTitle('');
        };
    }, [entityNumber]);
    const {
        data: rawAssets,
    } = useGetCommonData<Asset[]>('assets', {
        postProcess: it => it.resultList,
        enabled: true,
    });
    const selectedAsset = useMemo<Asset>(() => rawAssets
        ?.find(it => it.assetNumber.toLowerCase() === entityNumber.toLowerCase()), [rawAssets, entityNumber]);

    const {
        columns,
        setHoveredItem,
        setEditedItem,
        editedItem,
        assetPairings = [],
        newField,
        users,
    } = useEditablePairingsColumns({
        assetId: selectedAsset?.id,
    });

    const preparedAssetPairings = useMemo(() => (newField
        ? assetPairings.concat(newField) : assetPairings), [assetPairings, newField]);

    const assetTypeLabel = !selectedAsset ? '' : t(`ASSET_TYPE_LABEL.${selectedAsset?.assetTypeCode}`);

    return (
        <div style={{
            padding: '0 12px 12px 12px',
        }}
        >
            <BackToLink
                to={`/asset-management/assets?view=${getCacheValue('VIEW_TYPE') || 'table'}`}
                title={t('COMMON.ASSETS_LIST')}
                marginRight={12}
                marginLeft={0}
                marginBottom={10}
                marginTop={10}
            />
            <div className={classes.pageContent}>
                <div style={{ display: 'grid' }}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        {
                            entityNumber ? (
                                <>
                                    <Table
                                        title={
                                        `${assetTypeLabel} ${entityNumber} | ${t('SENSOR_DATA.LOGGER_PAIRINGS')}`
                                        }
                                        classNames={{
                                            tableContainerClassName: classes.tableContainer,
                                            tableClassName: classes.table,
                                        }}
                                        embed
                                        clientSideFilter={{}}
                                        columns={columns}
                                        data={preparedAssetPairings}
                                        setClientSideFilter={() => {}}
                                        onRowHover={({ original, state }) => setHoveredItem(state ? original : null)}
                                    />
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        padding: '10px',
                                    }}
                                    >

                                        <UncontrolledTooltip
                                            description={t('LOGGER_PAIRINGS.ADD_NEW_PAIRING')}
                                            placement="bottom"
                                            shrinked
                                        >
                                            <AddCircle
                                                style={{
                                                    color: theme.palette.secondary[600],
                                                    cursor: editedItem ? 'not-allowed' : 'pointer',
                                                    transition: 'all 0.3s ease',
                                                    opacity: editedItem ? 0.5 : 1,
                                                }}
                                                onClick={!editedItem ? () => setEditedItem({
                                                    id: '+',
                                                    updatedOn: moment().utcOffset(0, false).utc(),
                                                    updatedBy: Number(
                                                        users.find(it => Object
                                                            .values(it.fullName).join(' ') === userInfo?.name).id,
                                                    ),
                                                }) : null}
                                            />
                                        </UncontrolledTooltip>

                                    </div>

                                </>
                            ) : <NoOpenEntity />
                        }
                    </LocalizationProvider>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '12px',
                    }}
                >
                    <Card title={t('SENSOR_DATA.OPTIONS')}>
                        <Autocomplete
                            autoSelect
                            classes={{
                                inputRoot: classes.textFieldTextAutocomplete,
                                listbox: classes.listBox,
                            }}
                            className={classes.autocompleteClass}
                            clearOnEscape
                            id="EntitySelector"
                            options={rawAssets}
                            size="small"
                            // @ts-ignore
                            value={rawAssets
                                ?.find(it => it?.assetNumber.toLowerCase() === entityNumber.toLowerCase())
                                || null}
                            // @ts-ignore
                            getOptionLabel={asset => asset.assetNumber}
                            isOptionEqualToValue={
                                (option, value) => option.assetNumber.toLowerCase() === value.assetNumber.toLowerCase()
                            }
                            onChange={(ev, opt: Asset) => {
                                history.push(`/asset-management/assets/pairings/${opt?.assetNumber || ''}`);
                            }}
                            renderInput={(params) => (
                                <QuickHelpTooltip
                                    tooltipInfo={{
                                        order: 1,
                                        text: t('QUICK_HELP.SENSOR_DATA.SEARCH_FOR_SHIPMENT'),
                                        uid: 'searchQuickHelp',
                                    }}
                                >
                                    <TextField
                                        {...params}
                                        label={t('COMMON.SEARCH')}
                                        placeholder={t('SENSOR_DATA.START_TYPING')}
                                        onChange={(event) => setSampleForSearch(event.target.value)}
                                        size="small"
                                        value={sampleForSearch}
                                        variant="standard"
                                    />
                                </QuickHelpTooltip>

                            )}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} style={{ borderBottom: '1px solid #0000001C' }}>
                                        <RenderOptionItem
                                            option={{
                                                entityNumber: option.assetNumber,
                                                entityId: option.id,
                                                entityType: 'assets',
                                                additionalData: {
                                                    assetNumber: option.assetNumber,
                                                    loggerNumber: option.loggerNumber,
                                                },
                                            }}
                                            sampleForSearch={sampleForSearch}
                                            searchMode
                                        />
                                    </li>
                                );
                            }}
                            noOptionsText={t('SENSOR_DATA.NO_RESULT')}
                            popupIcon={null}
                        />

                    </Card>
                    <ShortPackagingInfo
                        model={
                            selectedAsset?.assetTypeCode ? (selectedAsset?.assetTypeCode === 'CONTAINER'
                                ? t('ASSET_TYPE_LABEL.CONTAINER')
                                : t('ASSET_TYPE_LABEL.AIRPORT_PALLET')) : null
                        }
                        picture={
                            selectedAsset?.assetTypeCode ? (selectedAsset?.assetTypeCode === 'CONTAINER'
                                ? PICTURES.uld
                                : PICTURES.airport_pallet) : null
                        }
                    />
                </div>

            </div>

        </div>

    );
};

export default AssetPairings;
