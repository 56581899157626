import { AirportExtended } from 'dataTypes/SecureBackend/apiResponse';

export const sortAirportsByCategory = (airportList: AirportExtended[], returnedItemCount = 20) => {
    return airportList.slice()
        .sort((a, b) => a.category - b.category)
        .slice(0, returnedItemCount);
};

export const getAirportsInPassedBoundsZone = (airportList: AirportExtended[], bounds: number[] = [], count = 20) => {
    const [
        southWestLng = -136.49218749999997,
        southWestLat = -59.97296244693485,
        northEastLng = 152.4921875,
        northEastLat = 79.78259760486252,
    ] = bounds;

    const standardBehaviour = southWestLng < northEastLng;

    return airportList.slice()
        .filter(airport => {
            const { geolocation = null } = airport || {};
            const { latitude: lat = null, longitude: lng = null } = geolocation || {};

            return lat !== null && lng !== null
                && lat > southWestLat && lat < northEastLat
                && (standardBehaviour
                    ? (lng > southWestLng && lng < northEastLng)
                    : ((lng > southWestLng && lng < 180) || (lng < northEastLng && lng > -180))
                );
        })
        .sort((a, b) => a.category - b.category)
        .slice(0, count);
};
