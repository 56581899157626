import React, {
    useMemo,
    useState,
} from 'react';
import Button from '@mui/material/Button';
import Card from 'shared-components/Card';
import {
    QuestionnaireStatus,
    QuestionnaireItemAnswer,
} from 'shared-components/dataTypes';
import icons from 'shared-components/icons';
import RadioButtonType from 'shared-components/CompanyInfoComponents/AnswerUnit/RadioButtonType';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useStyles from './QuestionnaireCard.style';

type Props= {
    answers: QuestionnaireItemAnswer[],
    companyId: number,
    statusHistory: {
        icon: string,
        message: string,
    }[],
    questionnaireStatus: QuestionnaireStatus,
    questionnaireBasedOnAudit: string[],
}

const getMainText = (questionnaireStatus: QuestionnaireStatus, t = w => w) => {
    if ([
        'ANSWER_QUESTIONS',
        'REVIEW_ANSWERS',
        'PUBLISHED',
    ].includes(questionnaireStatus)) {
        return t(`MY_COMPANY.${questionnaireStatus}`);
    }

    return '';
};

const questionnaireStatusResponseMap = (questionnaireStatus: QuestionnaireStatus, approved = false):
QuestionnaireStatus => {
    switch (questionnaireStatus) {
    case 'ANSWER_QUESTIONS':
        return 'REVIEW_ANSWERS';
    case 'REVIEW_ANSWERS':
        return approved ? 'PUBLISH_QUESTIONNAIRE' : 'ANSWER_QUESTIONS';
    case 'PUBLISHED':
        return 'ANSWER_QUESTIONS';
    default:
        return null;
    }
};

const QuestionnaireCard = ({
    answers = [],
    // companyId,
    questionnaireBasedOnAudit = [],
    statusHistory = [],
    questionnaireStatus,
} : Props) => {
    const classes = useStyles();

    // ---
    // TODO: remove later or refactor to useSecureBackend
    // const { addObjectRecord: submitReview } = useAbstractService({
    //     objectName: `/serviceprovider/${companyId}/questionnaire`,
    // });
    const submitReview = (obj: any) => obj;
    // ---

    const { t } = useCustomTranslation();
    const [questionnaireProfile, setQuestionnaireProfile] = useState([]);
    const [showAllHistory, setShowAllHistory] = useState<boolean>(statusHistory.length < 4);

    const disableButton = useMemo(() => {
        return questionnaireBasedOnAudit.length === 0 || questionnaireProfile.length === 0;
    }, [
        questionnaireBasedOnAudit.length,
        questionnaireProfile.length,
    ]);

    const requestBody = useMemo(() => {
        return (
            {
                questionnaireProfile,
                questionnaireBasedOnAudit,
                questionnaire: answers,
            }
        );
    }, [
        answers,
        questionnaireProfile,
        questionnaireBasedOnAudit,
    ]);

    const handleClickShowAllHistory = () => setShowAllHistory(true);

    const handleClickDecision = () => {
        submitReview({
            ...requestBody,
            questionnaireStatus: questionnaireStatusResponseMap(questionnaireStatus),
        });
    };

    const handleClickConfirmReview = () => {
        submitReview({
            ...requestBody,
            questionnaireStatus: questionnaireStatusResponseMap(questionnaireStatus, true),
        });
    };

    const filteredHistory = useMemo(() => {
        return statusHistory.filter((item, index) => showAllHistory || statusHistory.length - index < 4);
    }, [statusHistory, showAllHistory]);

    return (
        <Card title={t('COMMON.QUESTIONNAIRE')}>
            <div className={classes.twoColumns}>
                <div>
                    <div>{getMainText(questionnaireStatus, t)}</div>
                    <div className={classes.answerContainer}>
                        <div>{t('MY_COMPANY.HOW_MANY_QUESTIONS_DO_YOU_WANT_TO_ANSWER')}</div>
                        <RadioButtonType
                            setAnswerUpdate={setQuestionnaireProfile}
                            availableAnswerOptions={['Core', 'Extended']}
                            row
                        />
                    </div>
                </div>
                <div>
                    {
                        !showAllHistory && (
                            <div className={classes.historyItemContainer}>
                                <div>
                                    <img
                                        alt="Previous Changes Icon"
                                        className={classes.historyItemIcon}
                                        src={icons.arrow_up_round}
                                    />
                                    <div className={classes.divider} />
                                </div>
                                <div>
                                    ...
                                    {t('MY_COMPANY.PREVIOUS_CHANGES')}
                                    ...
                                </div>
                                <Button
                                    className={classes.previousChangesButton}
                                    onClick={handleClickShowAllHistory}
                                    autoFocus
                                >
                                    {t('MY_COMPANY.SHOW_ALL')}
                                </Button>
                            </div>
                        )
                    }
                    {
                        filteredHistory.map((item, index) => {
                            return (
                                <div
                                    className={classes.historyItemContainer}
                                    key={`filteredHistory-${index}`}
                                >
                                    <div>
                                        <img
                                            alt={`history step ${index + 1}`}
                                            className={classes.historyItemIcon}
                                            src={item.icon}
                                        />
                                        {
                                            index < filteredHistory.length - 1 && (
                                                <div className={classes.divider} />
                                            )
                                        }
                                    </div>
                                    <div>{item.message}</div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            <div className={classes.buttonContainer}>
                {
                    questionnaireStatus === 'ANSWER_QUESTIONS' && (
                        <Button
                            autoFocus
                            className={classes.submitButton}
                            disabled={disableButton}
                            onClick={handleClickDecision}
                        >
                            {t('MY_COMPANY.SUBMIT_FOR_REVIEW')}
                        </Button>
                    )
                }
                {
                    questionnaireStatus === 'REVIEW_ANSWERS' && (
                        <Button
                            autoFocus
                            className={classes.submitButton}
                            disabled={disableButton}
                            onClick={handleClickDecision}
                        >
                            {t('MY_COMPANY.REJECT_ANSWERS')}
                        </Button>
                    )
                }
                {
                    questionnaireStatus === 'REVIEW_ANSWERS' && (
                        <Button
                            disabled={disableButton}
                            className={classes.submitButton}
                            onClick={handleClickConfirmReview}
                        >
                            {t('MY_COMPANY.CONFIRM_REVIEW')}
                        </Button>
                    )
                }
                {
                    questionnaireStatus === 'PUBLISHED' && (
                        <Button
                            disabled={disableButton}
                            className={classes.submitButton}
                            onClick={handleClickDecision}
                            autoFocus
                        >
                            {t('MY_COMPANY.ADJUST_QUESTIONNAIRE')}
                        </Button>
                    )
                }
            </div>
        </Card>
    );
};

export default QuestionnaireCard;
