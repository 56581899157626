import {
    getCapabilities,
    getCertificates,
    getFacilities,
    getQuestionnaire,
    getFullName,
} from 'hooks/useGetServiceProvider/lib';

export const fetchProcessedData = (rawData: any, labels = {}) => {
    return {
        id: rawData.id,
        audited: rawData.audited,
        address: {
            addressLine1: rawData?.addressLine1 || '',
            addressLine2: rawData?.addressLine2 || '',
            addressLine3: rawData?.addressLine3 || '',
            zip: rawData?.zip || '',
            city: rawData?.city || '',
            countryName: rawData?.country?.countryName || '',
        },
        capabilities: getCapabilities(rawData?.capabilities),
        questionnaireAnsweredBy: { fullName: getFullName(rawData?.questionnaireAnsweredBy) },
        questionnaireReviewedBy: { fullName: getFullName(rawData?.questionnaireReviewedBy) },
        certificates: getCertificates(rawData?.certificates, labels),
        commodities: rawData.commodities || [],
        contactEmail: rawData.contactEmail || '',
        contactName: rawData.contactName || '',
        companyName: rawData.companyName || '',
        companyLogo: rawData.companyLogo || '',
        contactPhone: rawData.contactPhone || '',
        facilities: getFacilities(rawData.facilities, labels) || {},
        location: {
            lat: rawData.latitude,
            lng: rawData.longitude,
        },
        services: rawData.services || [],
        questionnaire: getQuestionnaire(rawData.questionnaire),
        questionnaireProfile: rawData.questionnaireProfile || '',
        questionnairePublished: rawData.questionnairePublished || false,
        questionnaireStatus: rawData.questionnaireStatus || '',
        website: rawData?.website || '',
    };
};
