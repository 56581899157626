import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useState,
} from 'react';
import {
    AccordionOptionsGroup,
    BlockWithOverflow,
    CheckboxOptionItem,
    FilterBlock,
} from 'shared-components/SideFilter';
import useCustomTranslation from 'hooks/useCustomTranslation';
import {
    ClientSideFilterType,
    EnumFilterOption,
} from '../../dataTypes';
import { fetchCheckboxOptions } from '../../lib';

type ReducedColumnsType = {
    Header: any,
    poeLabelMask?: string,
    sideFilterKey?: string,
    sideFilterTitle?: string,
    sideFilterBlockMaxHeight?: '12vh' | '18vh' | '24vh' | '30vh',
}
type BaseProps = {
    column: ReducedColumnsType,
    options: EnumFilterOption[],
    skipOptionsUpdate?: boolean,
    showCount?: boolean,
}
type MandatorySelectedFilterOptions = BaseProps & {
    setSelectedFilterOptions: Dispatch<SetStateAction<ClientSideFilterType>>;
    updateFilters?: never;
};

type MandatoryUpdateFilters = BaseProps & {
    setSelectedFilterOptions?: never;
    updateFilters: Function;
};

type Props = MandatorySelectedFilterOptions | MandatoryUpdateFilters

const EnumFilter = ({
    column,
    options,
    setSelectedFilterOptions = null,
    updateFilters = null,
    skipOptionsUpdate = false,
    showCount = true,
} : Props) => {
    const { t } = useCustomTranslation();
    const [checkboxGroupOptions, setCheckboxGroupOptions] = useState<string[]>(fetchCheckboxOptions(options));
    const {
        sideFilterKey,
        sideFilterTitle,
        Header,
        sideFilterBlockMaxHeight,
        poeLabelMask,
    } = column;

    useEffect(() => {
        if (options.length === 0) return;
        if (skipOptionsUpdate) return;
        setCheckboxGroupOptions(fetchCheckboxOptions(options));
    }, [options]);

    useEffect(() => {
        if (setSelectedFilterOptions) {
            setSelectedFilterOptions(prev => ({
                ...prev,
                [sideFilterKey]: checkboxGroupOptions,
            }));
        } else if (updateFilters) {
            updateFilters(sideFilterKey, checkboxGroupOptions);
        }
    }, [checkboxGroupOptions]);

    const handleSelectDeselectAllOptions = () => {
        if (checkboxGroupOptions.length === 0) {
            setCheckboxGroupOptions(fetchCheckboxOptions(options));
        } else {
            setCheckboxGroupOptions([]);
        }
    };

    return (
        <FilterBlock>
            <AccordionOptionsGroup
                title={sideFilterTitle || Header}
                onSelectDeselect={handleSelectDeselectAllOptions}
            >
                <BlockWithOverflow
                    style={{
                        maxHeight: sideFilterBlockMaxHeight || '12vh',
                        width: '100%',
                    }}
                >
                    {
                        options.map((option) => (
                            <div key={option.value} style={{ marginBottom: '12px' }}>
                                <CheckboxOptionItem
                                    count={showCount ? (option.count || 0) : null}
                                    checked={checkboxGroupOptions.includes(option.value)}
                                    key={option.value}
                                    label={
                                        poeLabelMask
                                            ? t(`${poeLabelMask}.${option.value}`)
                                            : option.value
                                    }
                                    optionKey={option.value}
                                    setCheckboxGroupOptions={setCheckboxGroupOptions}
                                    // tooltipDescription={option.description}
                                />
                            </div>
                        ))
                    }
                </BlockWithOverflow>
            </AccordionOptionsGroup>
        </FilterBlock>
    );
};

export default EnumFilter;
