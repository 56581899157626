import {
    commoditiesArr,
    servicesArr,
    ServiceProviderSite,
} from 'dataTypes/SecureBackend/apiResponse';
import icons from 'shared-components/icons';

interface TableData {
    id: number,
    serviceProviderName: string,
    logoImagePath: string,
    name: string,
    certificates: string[],
    commodities: string[],
    services: string[],
    facilities: string[],
    commoditiesLabels: string[],
    servicesLabels: string[],
}
/*
const initialTableData: TableData = {
    id: null,
    serviceProviderName: '',
    logoImagePath: '',
    name: '',
    certificates: [],
    commodities: [],
    services: [],
    facilities: [],
};
*/

// const getCertificates = (certificates: string[], labels = {}) => {
//     certificates.map((item) => labels[item] || item);
// };

const getFilteredOffers = (offers: string[], offersGroup, labels = {}) => {
    return offers.filter(offer => offersGroup.includes(offer)).map(offer => labels[offer] || offer);
};

export const fetchTableData = (rawData: ServiceProviderSite[] = [], labels = {}): TableData[] => {
    return rawData.map(site => {
        const {
            id,
            logoImagePath = icons.placeholder_company_logo,
            // certificates = [],
            name,
            offers,
            serviceProviderName,
        } = site;

        return {
            id,
            serviceProviderName,
            name,
            // certificates: getCertificates(certificates, labels),
            certificates: ['TAPA', 'IFS'],
            logoImagePath,
            // commodities: offers.filter(offer => commoditiesArr.includes(offer)),
            // services: offers.filter(offer => servicesArr.includes(offer)),
            commodities: getFilteredOffers(offers, commoditiesArr),
            services: getFilteredOffers(offers, servicesArr),
            commoditiesLabels: getFilteredOffers(offers, commoditiesArr, labels),
            servicesLabels: getFilteredOffers(offers, servicesArr, labels),
            facilities: [],
        };
    });
};

const getFieldTypes = () => {
    const offerArrays = ['commodities', 'services'];

    return {
        arrayFields: ['certificates', ...offerArrays],
        offerArrays,
        stringFields: ['name', 'serviceProviderName'],
    };
};

const checkContainsStrSample = (str: string, sample: string): boolean => {
    return str.toLowerCase().includes(sample.toLowerCase());
};

const checkArrIncludesanotherArrItem = (arr1 = [], arr2 = []): boolean => {
    return arr1.some(arr1Item => arr2.includes(arr1Item));
};

export const getFilteredTableData = (availableData: TableData[], searchField = '', offers = []): TableData[] => {
    const { arrayFields, offerArrays, stringFields } = getFieldTypes();

    return availableData.filter(item => {
        const searchFieldFiltering = searchField
            ? (
                stringFields.some(field => checkContainsStrSample(item[field], searchField))
                || arrayFields.some(field => (
                    item[field].some(stringField => checkContainsStrSample(stringField, searchField))))
            )
            : true;

        const offersFiltering = offers.length === 0
            ? true
            : offerArrays.some(offerField => checkArrIncludesanotherArrItem(item[offerField], offers));

        return searchFieldFiltering && offersFiltering;
    });
};
