import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    input: {
        textAlign: 'left',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        color: theme.palette.secondary[600],
        opacity: 1,
        height: '31px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        '& $input': {
            paddingLeft: '8px',
        },
    },
    rightArrow: {
        width: '18px',
        height: '18px',
        marginLeft: '5px',
    },
    moreSpan: {
        marginTop: '10px',
        display: 'flex',
        color: theme.palette.secondary[600],
        alignItems: 'center',
        cursor: 'pointer',
        userSelect: 'none',
        width: 'min-content',
    },
    paddedTable: {
        padding: '0 10px 10px 10px',
    },
}));

export default useStyles;
