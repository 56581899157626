export type CompanyType = 'SERVICE_PROVIDER' | 'PHARMA'

export type energyLevelThreshold = number

export interface Geolocation {
    longitude: number,
    latitude: number,
}

export interface LabeledEnumDto {
    code: string,
    label: string,
}

export interface Country {
    code: string,
    name: string,
}

export interface Address {
    country: Country,
    city: string,
    geolocation: Geolocation,
    zipCode: string,
    addressLine1: string,
    addressLine2: string,
    addressLine3: string,
}

export interface ContactInfo {
    contactPerson: string,
    phoneNumber: string,
    email: string,
}

export interface Airport {
    airportName: string,
    iataCode?: string,
    geolocation: Geolocation,
}

export interface AirportExtended {
    category: number,
    city: string,
    code: string,
    country:Country,
    geolocation: Geolocation,
    name: string,
}

export interface Seaport {
    code: string,
    name: string,
    geolocation: Geolocation,
    country: Country,
}
export interface SeaportExtended {
    category: number,
    city: string,
    code: string,
    country:Country,
    geolocation: Geolocation,
    name: string,
}

export interface RentalInformation {
    rentalPeriodFrom: string,
    rentalPeriodTo: string,
    rentedBy: {
        id: number,
        name: string,
        companyType: CompanyType,
        logoImagePath: string,
        website: string,
        contactInfo: ContactInfo,
        address: Address,
    }
}

export type DayOfWeek = 'MONDAY'
    | 'TUESDAY'
    | 'WEDNESDAY'
    | 'THURSDAY'
    | 'FRIDAY'
    | 'SATURDAY'
    | 'SUNDAY'

export const weekDaysWithLabel = {
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday',
    SUNDAY: 'Sunday',
};
export const weekDaysWithLabelT = (t = w => w) => ({
    MONDAY: t('SERVICE_PROVIDERS.MONDAY'),
    TUESDAY: t('SERVICE_PROVIDERS.TUESDAY'),
    WEDNESDAY: t('SERVICE_PROVIDERS.WEDNESDAY'),
    THURSDAY: t('SERVICE_PROVIDERS.THURSDAY'),
    FRIDAY: t('SERVICE_PROVIDERS.FRIDAY'),
    SATURDAY: t('SERVICE_PROVIDERS.SATURDAY'),
    SUNDAY: t('SERVICE_PROVIDERS.SUNDAY'),
});
