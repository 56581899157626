import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    card: {
        zIndex: 10,
    },
    cardContentClass: {
        cursor: 'default',
        padding: '8px 12px!important',
        // fontSize: 14,
    },
    cardTitle: {
        font: 'normal normal normal 20px/26px Roboto',
        letterSpacing: '0.26px',
        color: '#191919',
        opacity: 1,
    },
    dailyForecastContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    dailyForecastItem: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '3px',
    },
    currentDailyForecastItem: {
        background: '#F8F9FA 0% 0% no-repeat padding-box',
        borderRadius: '3px',
    },
    forecastIcon: {
        width: '50px',
    },
    forecastDay: {
        font: 'normal normal 500 12px/14px Roboto',
        letterSpacing: '0.26px',
        marginBottom: '-5px',
        opacity: 1,
    },
    tempMin: {
        font: 'normal normal normal 9px/11px Roboto',
        color: '#939393',
        marginLeft: '7px',
    },
    tempMax: {
        font: 'normal normal normal 9px/11px Roboto',
    },
});

export default useStyles;
