import { makeStyles } from '@mui/styles';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => ({
    loading: {
        transition: '200ms ease',
        filter: 'blur(2px)',
    },
    operatingHoursContainer: {
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: '#747474',
        opacity: 1,
    },
    title: {
        margin: '10px 0 5px',
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: '#939393',
        opacity: 1,
    },
    weekContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '6px',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    rowGrid: {
        display: 'grid',
        gridTemplateColumns: '4fr 1fr 1fr',
        gridGap: '6px',
    },
    equalRowGrid: {
        display: 'grid',
        gridTemplateColumns: '2fr 2fr 1fr 1fr',
        gridGap: '12px',
    },
    editHoursTtitle: {
        marginTop: '10px',
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: '#939393',
        opacity: 1,
        // textTransform: 'capitalize',
    },
    textField: {
        marginTop: '-5px',
    },
    textFieldText: {
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        // color: theme.palette.secondary[600],
        opacity: 1,
        // padding: '0',
        '&::-webkit-datetime-edit-ampm-field': {
            padding: '0',
        },
        // '&::-webkit-datetime-edit-minute-field': {
        //     paddingRight: '0',
        // },
    },
    timePicker: {
        '& .Mui-selected': {
            background: '#61C6E9!important',
            color: '#FFF!important',
        },
        '& .MuiButton-textPrimary': {
            // background: '#61C6E9!important',
            color: '#61C6E9!important',
        },
        // '& .css-1umqo6f': {
        //     backgroundColor: '#61C6E9!important',
        // },
        '& .css-12ha4i7': {
            backgroundColor: '#61C6E9!important',
        },
        '& .css-7lip4c': {
            backgroundColor: '#61C6E9!important',
        },
        '& .css-118whkv': {
            backgroundColor: '#61C6E9!important',
            border: '16px solid #61C6E9',
        },
    },
}));

export default useStyles;
