import React from 'react';
import icons from 'shared-components/icons';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import { assetsPath } from 'shared-components/constants';
import { TFunction } from 'react-i18next';
import { PackagingType, SimulationData, SimulationDataHolder } from '../../TemperatureSimulation';

export const hasExcursion = (data: SimulationData) => {
    const { simulationData = [] } = data || {};

    return simulationData.some(({
        minTemperature,
        maxTemperature,
        simulatedTemperature,
    }) => (simulatedTemperature < minTemperature || simulatedTemperature > maxTemperature));
};

export const profileCell = (excursion: boolean) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        }}
        >
            <img
                src={excursion ? icons.warning_round : icons.checked_blue}
                alt="icon"
                style={{
                    width: '18px',
                    height: '18px',
                    marginRight: '5px',
                }}
            />
            <span>{excursion ? 'Excursion' : 'No Excursion'}</span>
        </div>
    );
};
const reserveCell = (data: SimulationData) => {
    if (data.calculationSummary.timeLeftHours === 0) return '-';
    if (data.calculationSummary.timeLeftHours >= 200) return 'More than 200h';
    return `${data.calculationSummary.timeLeftHours}h`;
};
const tableColumns:
    (profileData?: SimulationDataHolder, t?: TFunction) => ColumnsType[] = (profileData = { }, t) => {
        const hasProfiles = !!profileData?.winter || !!profileData?.summer;
        const summerProfile = profileData?.summer;
        const winterProfile = profileData?.winter;

        return [
            {
                Header: t('COMMON.PACKAGING'),
                accessor: (row: PackagingType) => (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            style={{ width: '35px' }}
                            alt=""
                            src={`${assetsPath}/assets${row.packagingPictureUrl}`}
                        />
                        <div>{ row.temperatureSpecificName }</div>
                    </div>
                ),
                dataType: 'custom',
            },
            {
                Header: t('LANE_MANAGEMENT.PACKAGING_TABLE.PROVIDER'),
                accessor: ({ provider }) => t(provider),
                dataType: 'custom',
            },
            {
                Header: t('LANE_MANAGEMENT.PACKAGING_TABLE.TECHNOLOGY'),
                accessor: ({ technology }) => t(technology),
                dataType: 'custom',
            },
            {
                Header: t('LANE_MANAGEMENT.PACKAGING_TABLE.SUMMER_PROFILE'),
                accessor: () => {
                    if (!hasProfiles) {
                        return 'Please add steps with a duration and temperature to see this profile.';
                    } else {
                        return profileCell(hasExcursion(summerProfile));
                    }
                },
                colSpan: hasProfiles ? 1 : 4,
                dataType: 'text',
            },
            {
                Header: t('LANE_MANAGEMENT.PACKAGING_TABLE.RESERVE'),
                accessor: () => {
                    return reserveCell(summerProfile);
                },
                dataType: 'text',
                skip: !hasProfiles,
            },
            {
                Header: t('LANE_MANAGEMENT.PACKAGING_TABLE.WINTER_PROFILE'),
                accessor: () => {
                    return profileCell(hasExcursion(winterProfile));
                },
                dataType: 'text',
                skip: !hasProfiles,
            },
            {
                Header: t('LANE_MANAGEMENT.PACKAGING_TABLE.RESERVE'),
                accessor: () => {
                    return reserveCell(winterProfile);
                },
                dataType: 'text',
                skip: !hasProfiles,
            },
        ];
    };

export default tableColumns;
