import React, { useMemo, useRef, CSSProperties, MouseEventHandler } from 'react';
import TableCell from '@mui/material/TableCell';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import {
    ClientSideFilterType,
    ColumnFilterType,
    ColumnFilterOptions,
} from '../../dataTypes';

import SortingIcon from './SortingIcon';
import ClientSideFilter from '../ClientSideFilter';
import useStyles from './HeaderCell.style';

type DragItem = {
    index: number,
    id: string,
    type: string,
}

type Props = {
    clientSideFilter: ClientSideFilterType,
    columnWidth?: string | number,
    filterKey: string | null,
    columnFilterOptions?: ColumnFilterOptions,
    filterType?: ColumnFilterType,
    headerData: string | number,
    headerProps: any,
    index: number,
    isSorted?: boolean,
    isSortedDesc?: boolean,
    moveColumn?: (dragIndex: number, hoverIndex: number) => void,
    setClientSideFilter: (clientSideFilter: ClientSideFilterType) => void,
    style?: CSSProperties,
    customHeaderStyle?: CSSProperties,
    className?: string,
    onClick?: MouseEventHandler<HTMLDivElement>,
}

const HeaderCell = ({
    clientSideFilter,
    columnWidth = null,
    filterKey,
    columnFilterOptions,
    filterType = null,
    headerData,
    headerProps,
    index,
    isSorted = false,
    isSortedDesc = false,
    setClientSideFilter,
    moveColumn,
    className = '',
    customHeaderStyle = {},
    onClick,
    ...rest
}: Props) => {
    const classes = useStyles();

    const ref = useRef<HTMLDivElement>(null);

    // eslint-disable-next-line no-unused-vars
    const [{ handlerId }, drop] = useDrop({
        accept: 'column',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientX = clientOffset.x - hoverBoundingRect.left;

            // Dragging to left
            if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                return;
            }

            // Dragging to right
            if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
                return;
            }

            // Time to actually perform the action
            moveColumn(dragIndex, hoverIndex);

            item.index = hoverIndex;
        },
    });

    // eslint-disable-next-line no-unused-vars
    const [{ isDragging }, drag] = useDrag({
        item: {
            type: 'column',
            index,
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    const style = useMemo(() => {
        const styleObject = {};

        if (columnWidth) {
            styleObject['width'] = columnWidth;
        }
        return styleObject;
    }, [columnWidth]);

    return (
        <TableCell
            className={[classes.headerCell, className].join(' ')}
            style={style}
            ref={ref}
            {...rest}
        >
            <div
                className={classes.cellContainer} style={customHeaderStyle} onClick={onClick || null}
            >
                <div
                    className={classes.headerData}
                    {...headerProps}
                >
                    {headerData}
                </div>
                {
                    filterType && filterKey && (
                        <ClientSideFilter
                            clientSideFilter={clientSideFilter}
                            filterKey={filterKey}
                            columnFilterOptions={columnFilterOptions}
                            filterType={filterType}
                            setClientSideFilter={setClientSideFilter}
                        />
                    )
                }
                <SortingIcon
                    isSorted={isSorted}
                    isSortedDesc={isSortedDesc}
                />
            </div>
        </TableCell>
    );
};

export default HeaderCell;
