import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    card: {
        // marginTop: '15px',
        minWidth: '35%',
        // marginBottom: '12px',
    },
    scrollable: {
        overflowX: 'auto',
    },
    flexContainer: {
        display: 'flex',
    },
    approvalRow: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '11px',
    },
    rowField: {
        minWidth: '80px',
        display: 'flex',
        marginRight: '50px',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0px',
        color: theme.palette.secondary[900],
        opacity: 1,
    },
    columnTitle: {
        textAlign: 'left',
        font: 'normal normal 500 16px/19px Roboto',
        letterSpacing: '0px',
        color: theme.palette.secondary[900],
        textTransform: 'uppercase',
        opacity: 1,
    },
    statusContainer: {
        display: 'flex',
        marginRight: '20px',
        alignItems: 'center',
    },
    statusText: {
        marginRight: '20px',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0px',
        color: theme.palette.secondary[900],
        opacity: 1,
    },
    icon: {
        marginRight: '10px',
        width: '21px',
        height: '21px',
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '11px',
        '@media (max-width: 1600px)': {
            alignSelf: 'flex-end',
            maxWidth: 'unset',
        },
    },
    buttonFlexBlock: {
        width: '165px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    button: {
        marginBottom: '9px',
        textTransform: 'uppercase',
        height: '36px',
    },
    disabledButton: {
        background: '#D7D7D7 0% 0% no-repeat padding-box',
        color: '#FFFFFF!important',
    },
    bigButton: {
        width: '165px',
    },
    smallButton: {
        width: '75px',
    },
    definePeriodTitle: {
        width: '250px',
        textAlign: 'center',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0px',
        color: '#747474',
        opacity: 1,
        whiteSpace: 'normal',
    },
    definePeriodDialog: {
        padding: '0 15px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '18px',
    },
    definePeriodDialogTitle: {
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: '#939393',
        opacity: 1,
    },
    definePeriodButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '25px',
    },
    responsiveFlex: {
        marginTop: '20px',
        display: 'grid',
        gridTemplateColumns: '3fr 1fr',
        gridGap: '6px',
        '@media (max-width: 1600px)': {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    approveButton: {
        width: '100px',
        height: '36px',
        textTransform: 'uppercase',
        margin: '0 12px',
    },
    rejectButton: {
        width: '100px',
        height: '36px',
        textTransform: 'uppercase',
        margin: '0 12px',
    },
}));

export default useStyles;
