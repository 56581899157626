import React, { useMemo } from 'react';
import useHasAccess, { userRoles } from 'hooks/useHasAccess';
import PictureCard from './components/PictureCard';
import AddNewPicture from './components/AddNewPicture';
import useStyles from './Gallery.style';

type Props = {
    accessToChange?: boolean,
    gallery: {
        id: number,
        attachmentUrl: string,
        description: string,
        filename: string,
    }[]
}

const Gallery = ({
    accessToChange = false,
    gallery = [],
} : Props) => {
    const classes = useStyles();
    const hasAccess = useHasAccess();
    const isAdmin = useMemo(() => hasAccess(userRoles.SKYCELL_ADMIN), [hasAccess]);

    return (
        <div className={classes.galleryContainer}>
            {
                gallery.map((item, index) => {
                    const deleteUrl = `${isAdmin ? '/admin' : ''}/serviceprovider/${item.id}/gallery`;

                    return (
                        <PictureCard
                            accessToChange={accessToChange}
                            attachmentUrl={item.attachmentUrl}
                            description={item.description}
                            deleteUrl={deleteUrl}
                            title={item.filename}
                            key={`PictureCard-${index}`}
                        />
                    );
                })
            }
            {
                accessToChange && (
                    <AddNewPicture />
                )
            }
        </div>
    );
};

export default Gallery;
