import React, { useMemo } from 'react';
import { Collapse } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Classes } from 'jss';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useHasAccess from 'hooks/useHasAccess';
import { TEMPERATURE_STATUS } from 'shared-components/constants';
import icons from 'shared-components/icons';
import { getTemperatureStatusIcon } from 'utils/stringTool';
import UncontrolledTooltip from 'shared-components/ControlledTooltip/UncontrolledTooltip';
import TemperatureInTempRange from 'shared-components/TemperatureInTempRange';
import { GenericCargo } from 'dataTypes/SecureBackend/apiResponse/Shipment';
import moment from 'moment';
import {
    getRejectedStatuses,
} from './lib';
import {
    useStyles,
} from '../ClusterBodyItem.style';
import TemperatureInfoBlock from './TemperatureInfoBlock';
import LastMeasuredBlock from './LastMeasuredBlock';

type Props = {
    cargo?: GenericCargo,
    togglePackagingInfo: Function,
    packagingInfoOpen: string[],
    energyLevel?: number,
    energyThreshold?: number,
}

interface StatusElementParams {
    classes: Classes;
    icon: string;
    text: string;
}

const StatusElement = ({ classes, icon, text }: StatusElementParams) => {
    return (
        <div className={classes.temperatureStatus}>
            <img
                alt="temperature status"
                src={icon}
            />
            <div>
                {text}
            </div>
        </div>
    );
};
const PackagingInfo = ({
    cargo,
    togglePackagingInfo,
    packagingInfoOpen,
    energyLevel,
    energyThreshold,
}: Props) => {
    const classes = useStyles();
    const hasAccess = useHasAccess();
    const { t } = useCustomTranslation();
    const {
        packaging,
        skyCoreProductRelease,
        temperatureCheckResult,
        lastMeasuredData,
    } = cargo || {};
    const {
        serialNumber,
        packagingTypeCode,
    } = packaging || {};
    const {
        sealStatus,
        containerStatus,
        palletStatus,
        palletStatus1,
        palletStatus2,
    } = skyCoreProductRelease || {};
    const {
        temperatureMin,
        temperatureMax,
        temperatureRangeMin,
        temperatureRangeMax,
        temperatureStatus,
    } = temperatureCheckResult || {};
    const {
        temperatureInternal,
        temperatureInternalTimestamp,
        temperatureAmbientTimestamp,
        temperatureAmbient,
    } = lastMeasuredData || {};
    const temperatureRange = [
        temperatureRangeMin,
        temperatureRangeMax,
    ];

    const infoOpen = useMemo(() => {
        return packagingInfoOpen.includes(serialNumber);
    }, [packagingInfoOpen, serialNumber]);
    const rejectedStatuses = useMemo(() => getRejectedStatuses({
        sealStatus,
        containerStatus,
        palletStatus,
        palletStatus1,
        palletStatus2,
    }), [
        sealStatus,
        containerStatus,
        palletStatus,
        palletStatus1,
        palletStatus2,
    ]);

    const hasCollapse = useMemo(() => temperatureInternal && temperatureAmbient,
        [temperatureInternal, temperatureAmbient]);

    return (
        <div className={classes.division}>
            <div className={classes.packagingTop}>
                <div className={classes.packagingTopLeft}>
                    <img src={icons.container_box_black} alt="container_black" />
                </div>
                <div className={classes.packagingTopMiddle}>
                    <div className={classes.containerName}>
                        <UncontrolledTooltip
                            description={t('LABEL_TOOLTIPS.PACKAGING_NUMBER_TYPE')}
                            shrinked
                            placement="left"
                        >
                            <div className={classes.containerNumber}>
                                {serialNumber}
                            </div>
                        </UncontrolledTooltip>
                        <div className={classes.containerLabel}>
                            {t(`PACKAGING_CODE_LABEL.${packagingTypeCode}`)}
                        </div>
                    </div>
                    <UncontrolledTooltip
                        description={t('EXPLANATION_TOOLTIPS.PACKAGING_TEMPERATURE_MAX_MIN')}
                        placement="top"
                    >
                        <div className={classes.containerStatus}>
                            <div className={classes.containerStatusTitle}>
                                {
                                    rejectedStatuses.length === 1 && (
                                        <StatusElement
                                            icon={getTemperatureStatusIcon(TEMPERATURE_STATUS.UNKNOWN)}
                                            text={t(`DAMAGE_STATUS.${rejectedStatuses[0]}`)}
                                            classes={classes}
                                        />
                                    )
                                }
                                {
                                    rejectedStatuses.length > 1 && (
                                        <StatusElement
                                            icon={getTemperatureStatusIcon(TEMPERATURE_STATUS.UNKNOWN)}
                                            text={t('DAMAGE_STATUS.SEVERAL_DAMAGES')}
                                            classes={classes}
                                        />
                                    )
                                }
                                <StatusElement
                                    icon={getTemperatureStatusIcon(packaging?.serialNumber === 'JNN-52236'
                                        ? 'PREDICTED_EXCURSION' : temperatureStatus)}
                                    text={packaging?.serialNumber === 'JNN-52236'
                                        ? t('TEMPERATURE_STATUS.PREDICTED_EXCURSION')
                                        : t(`TEMPERATURE_STATUS.${temperatureStatus}`)}
                                    classes={classes}
                                />
                            </div>
                            <div className={classes.containerStatusDescription}>
                                <TemperatureInfoBlock
                                    temperatureMin={temperatureMin}
                                    temperatureMax={temperatureMax}
                                    temperatureRangeMin={temperatureRangeMin}
                                    temperatureRangeMax={temperatureRangeMax}
                                />
                            </div>
                            {packaging?.serialNumber === 'JNN-52236'
                                && (
                                    <div className={classes.containerStatusDescription}>
                                        Predicted:&nbsp;
                                        <TemperatureInTempRange
                                            tempRange={temperatureRange}
                                            temperature={9.0}
                                        />
                                        (expected in 3d 10h)
                                    </div>
                                )}
                            {
                                hasAccess('INTELLIGENT_MONITORING')
                                && Number(((energyLevel / 1) * 100).toFixed(0)) < energyThreshold && (
                                    <div className={classes.status}>
                                        <img
                                            key="icon_support"
                                            className={classes.icon}
                                            src={Number(((energyLevel / 1) * 100)
                                                .toFixed(0)) === 0 ? icons.red_triangle : icons.warning_triangle}
                                            alt="support"
                                        />
                                        <div>
                                            {`${t('COMMON.LOW_ENERGY_LEVEL')}: 
                                        ${((energyLevel / 1) * 100).toFixed(0)}%`}
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </UncontrolledTooltip>
                </div>
                <div className={classes.packagingTopRight}>
                    <div
                        className={classes.containerExpand}
                        onClick={(e) => togglePackagingInfo(e, serialNumber)}
                    >
                        {
                            hasCollapse && (
                                <KeyboardArrowDown
                                    className={packagingInfoOpen.includes(serialNumber) ? classes.rotate : ''}
                                />
                            )
                        }
                    </div>
                </div>
            </div>

            {
                hasCollapse && (
                    <Collapse in={infoOpen}>
                        <div className={classes.packagingBottom}>
                            <LastMeasuredBlock
                                lastMeasuredTempInternal={temperatureInternal}
                                lastMeasuredTempAmbientTimestamp={moment.utc(temperatureAmbientTimestamp).valueOf()}
                                lastMeasuredTempInternalTimestamp={moment.utc(temperatureInternalTimestamp).valueOf()}
                                lastMeasuredTempAmbient={temperatureAmbient}
                                temperatureRange={temperatureRange}
                            />
                        </div>
                    </Collapse>
                )
            }

        </div>
    );
};

export default PackagingInfo;
