import useStatusStateProcessOptions from 'hooks/useStatusStateProcessOptions';
import React, { useCallback, useContext } from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import useHasAccess from 'hooks/useHasAccess';
import icons from 'shared-components/icons';
import menuItems from 'shared-components/menuItems';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { ListItem } from '@mui/material';
import { QuickHelpContext } from 'Contexts/QuickHelpContext/QuickHelpContext';
import { openSupport } from 'utils/jiraHelpDesk';
import Footer from './components/Footer';
import MenuItemCollapse from './components/MenuItemCollapse';
import MenuItemLink from './components/MenuItemLink';
import useStyles from './Sidebar.style';

type Props = {
    anchor?: 'bottom' | 'left' | 'right' | 'top',
    currentLink: string,
    setSidebarState: (sidebarState: boolean) => void,
    sidebarIsOpen: boolean,
}

const Sidebar = ({
    anchor = 'left',
    currentLink,
    setSidebarState,
    sidebarIsOpen,
} : Props) => {
    const classes = useStyles();
    const hasAccess = useHasAccess();
    const isMobileDevice = anchor === 'top';
    const handleFooterOnClick = () => {
        setSidebarState(!sidebarIsOpen);
    };
    const { t } = useCustomTranslation();
    const {
        setEnableTooltips,
        setEnableGuide,
        quickHelpTooltipsBag,
    } = useContext(QuickHelpContext);

    const {
        setErrorStatus,
    } = useStatusStateProcessOptions();

    const jiraHelpDeskCallback = useCallback(() => openSupport(() => {
        setErrorStatus('Jira extension failed to load.');
    }), []);

    return (
        <Drawer
            anchor={anchor}
            variant="permanent"
            classes={{ paper: sidebarIsOpen ? classes.boxStyleOpened : classes.boxStyle }}
        >
            {
                (sidebarIsOpen || !isMobileDevice) && (
                    <div>
                        <MenuItemLink
                            key="MenuItemLink_dashboard"
                            icon={icons.skycell}
                            type="home"
                            isMobileDevice={isMobileDevice}
                            isTextHidden={!sidebarIsOpen}
                            link="dashboard"
                            setSidebarState={setSidebarState}
                            title="SkyMind"
                        />
                    </div>
                )
            }
            <List className={classes.list}>
                {
                    (sidebarIsOpen || !isMobileDevice) && menuItems(t)
                        .filter((item) => {
                            return item.id !== 'start' && (
                                item.sufficientRoles ? (item.sufficientRoles || []).some(role => hasAccess(role))
                                    : (item.necessaryRoles || []).every(role => hasAccess(role)));
                        })
                        .map((item) => {
                            if (item.children) {
                                return (
                                    <MenuItemCollapse
                                        currentLink={currentLink}
                                        key={`MenuItemCollapse_${item.id}`}
                                        icon={item.iconSrc}
                                        isMobileDevice={isMobileDevice}
                                        link={item.link}
                                        setSidebarState={setSidebarState}
                                        title={item.title}
                                        sidebarOpen={sidebarIsOpen}
                                    >
                                        {item.children}
                                    </MenuItemCollapse>
                                );
                            }
                            const isActive = item.link === currentLink;

                            return (
                                <MenuItemLink
                                    type={isActive ? 'activeDefault' : 'default'}
                                    key={`MenuItemLink_${item.id}`}
                                    icon={item.iconSrc}
                                    isMobileDevice={isMobileDevice}
                                    isTextHidden={!sidebarIsOpen}
                                    link={item.link}
                                    setSidebarState={setSidebarState}
                                    title={item.title}
                                />
                            );
                        })
                }
            </List>
            <div style={{ flex: 1 }} />
            <div style={{ width: '100%' }}>
                <List>
                    <div
                        className={classes.a}
                        key="link_to_support"
                        // @ts-ignore
                        onClick={jiraHelpDeskCallback}
                    >
                        <ListItem
                            key="support_button"
                            className={classes.buttons}
                            style={{ paddingLeft: '8px' }}
                        >
                            <img
                                key="icon_support"
                                className={classes.icon}
                                src={icons.support_icon}
                                alt="support"
                            />
                            <div
                                className={classes.ItemText}
                                hidden={!sidebarIsOpen}
                            >
                                Support
                            </div>
                        </ListItem>
                    </div>
                    {
                        quickHelpTooltipsBag.length !== 0 && (
                            <div
                                className={classes.a}
                                key="link_to_quickHelp"
                                onClick={() => setEnableTooltips(prev => !prev)}
                            >
                                <ListItem
                                    key="quickHelp_button"
                                    className={classes.buttons}
                                    style={{ paddingLeft: '8px' }}
                                >
                                    <img
                                        key="icon_quickHelp"
                                        className={classes.icon}
                                        src={icons.quick_help_icon}
                                        alt="quickHelp"
                                    />
                                    <div
                                        className={classes.ItemText}
                                        hidden={!sidebarIsOpen}
                                    >
                                        Quick Help
                                    </div>
                                </ListItem>
                            </div>
                        )
                    }
                    <div
                        className={classes.a}
                        key="userGuide_link"
                        onClick={() => setEnableGuide(prev => !prev)}
                    >
                        <ListItem
                            key="userGuide_button"
                            className={classes.buttons}
                            style={{ paddingLeft: '8px' }}
                        >
                            <img
                                key="icon_userGuide"
                                className={classes.icon}
                                src={icons.user_guide_icon}
                                alt="userGuide"
                            />
                            <div
                                className={classes.ItemText}
                                hidden={!sidebarIsOpen}
                            >
                                User Guide
                            </div>
                        </ListItem>
                    </div>
                </List>
            </div>
            {
                !isMobileDevice && (
                    <Footer
                        handleFooterOnClick={handleFooterOnClick}
                        sidebarIsOpen={sidebarIsOpen}
                    />
                )
            }
        </Drawer>
    );
};

export default Sidebar;
