import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    base: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    image: {
        height: '40px',
        width: '40px',
        marginRight: '8px',
    },
}));
