import React, {
    useState,
    useEffect, useMemo,
} from 'react';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import { AddServiceProviderSiteRequestBody, Address } from 'dataTypes/SecureBackend/processedData';
import { ContactInfo as ContactInfoType } from 'dataTypes/SecureBackend/apiResponse';

import AddressCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/AddressCard';
// eslint-disable-next-line max-len
import ServiceProviderSiteCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/ServiceProviderSiteCard';
// eslint-disable-next-line max-len
import ContactInformationCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/ContactInformationCard';
import { isReadyServiceProviderSiteBody } from 'Administration/lib';
import useHasAccess, { userRoles } from 'hooks/useHasAccess';
import useStyles from '../../ServiceProviderSites.style';

const AddServiceProviderSite = () => {
    const classes = useStyles();
    const history = useHistory();
    const [companyInfo, setCompanyInfo] = useState(null);
    const [address, setAddress] = useState<Address>(null);
    const [contactInfo, setContactInfo] = useState<ContactInfoType>(null);
    const [serviceProviderCompanyId, setServiceProviderCompanyId] = useState<number>(null);
    const [
        addServiceProviderSiteRequestBody,
        setAddServiceProviderSiteRequestBody,
    ] = useState<AddServiceProviderSiteRequestBody>(null);
    const [readyToSubmission, setReadyToSubmission] = useState(false);
    const hasAccess = useHasAccess();
    const isAdmin = useMemo(() => hasAccess(userRoles.SKYCELL_ADMIN), [hasAccess]);

    const {
        FlexibleRequest: createServiceProviderSite,
    } = useSecureBackendEndpoints(
        isAdmin ? 'admin/service-provider-companies' : 'service-provider-companies',
    ).statusNotificationRequests;

    const handleClickCreate = async () => {
        try {
            const url = `${serviceProviderCompanyId}/service-provider-sites`;
            const {
                status = null,
                data = null,
            } = await createServiceProviderSite('POST', url, addServiceProviderSiteRequestBody);

            if (status === 201) {
                history.push(`/administration/service-provider-sites/${data.id}`);
            }
        } catch (error) {
            global.console.log(error);
        }
    };

    const handleClickCancel = () => {
        history.push('/administration/service-provider-sites');
    };

    useEffect(() => {
        if (address && contactInfo && companyInfo) {
            const { name = '', serviceProviderCompanyId = null } = companyInfo;

            setServiceProviderCompanyId(serviceProviderCompanyId);
            setAddServiceProviderSiteRequestBody({
                name,
                address,
                contactInfo,
            });
        } else {
            setAddServiceProviderSiteRequestBody(null);
        }
    }, [
        address,
        contactInfo,
        companyInfo,
    ]);

    useEffect(() => {
        setReadyToSubmission(isReadyServiceProviderSiteBody(addServiceProviderSiteRequestBody));
    }, [addServiceProviderSiteRequestBody]);

    return (
        <>
            <div className={classes.addCompanyPageContent}>
                <div className={classes.addEditCompanyCardContentData}>
                    <ServiceProviderSiteCard
                        type="new"
                        setFilter={setCompanyInfo}
                    />
                    <AddressCard
                        type="new"
                        setFilter={setAddress}
                    />
                    <ContactInformationCard
                        type="new"
                        setFilter={setContactInfo}
                    />
                </div>
            </div>
            <div className={classes.addCompanyPageContent}>
                <div className={classes.addEditCompanyButtonContainer}>
                    <Button
                        color="primary"
                        variant="contained"
                        autoFocus
                        className={classes.addEditCompanyApproveButton}
                        disabled={!readyToSubmission}
                        onClick={handleClickCreate}
                    >
                        CREATE
                    </Button>
                    <Button
                        color="primary"
                        variant="text"
                        className={classes.addEditCompanyRejectButton}
                        onClick={handleClickCancel}
                    >
                        CANCEL
                    </Button>
                </div>
            </div>
        </>
    );
};

export default AddServiceProviderSite;
