import React from 'react';
import ReactDOMServer from 'react-dom/server';
import useStyles from './CustomMapHintTooltip.style';

const CustomTooltip = () => {
    const classes = useStyles();

    return (
        <div className={classes.staticTextStyle} />
    );
};

const getTooltipAsHTML = () => {
    return ReactDOMServer.renderToString(<CustomTooltip />);
};

export default getTooltipAsHTML;
