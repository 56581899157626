import React, {
    useEffect,
    useState,
    ReactNode,
} from 'react';
import { AlertColor } from '@mui/material/Alert';
import useStatusState from 'hooks/useStatusState';
import { TIME_IN_MS } from 'shared-components/constants';

import AlertIcon from './components/AlertIcon';
import StatusMessage from './components/StatusMessage';
import useStyles from './StatusMessages.style';

const standardDelay = 5 * TIME_IN_MS.second;
const errorDelay = 10 * TIME_IN_MS.second;

type Alert = {
    message: string,
    alertColor: AlertColor,
    alertTime: number,
    icon: ReactNode,
}

const StatusMessages = () => {
    const { statusState } = useStatusState();
    const classes = useStyles();
    const [alerts, setAlerts] = useState<Alert[]>([]);
    const [alertToDelete, setAlertToDelete] = useState<number>(null);

    useEffect(() => {
        if (statusState.message) {
            setAlerts((prev) => ([
                {
                    message: statusState.message,
                    alertColor: statusState.color,
                    alertTime: statusState.time,
                    icon: <AlertIcon color={statusState.color} />,
                },
                ...prev,
            ]));

            statusState.relatedStatuses.forEach((item) => {
                if (item) {
                    setAlertToDelete(item);
                }
            });

            setTimeout(() => {
                setAlertToDelete(statusState.time);
            }, statusState.color === 'error' ? errorDelay : standardDelay);
        }
    }, [statusState]);

    useEffect(() => {
        if (alertToDelete) {
            setAlerts((prev) => prev.filter((alert) => alert.alertTime !== alertToDelete));
        }
    }, [alertToDelete]);

    return (
        <div className={classes.statusMessagesContainer}>
            <div className={classes.statusMessagesInnerContainer}>
                {
                    alerts.map((item, index) => {
                        return (
                            <StatusMessage
                                key={`StatusMessage-${index}`}
                                message={item.message}
                                alertColor={item.alertColor}
                                alertTime={item.alertTime}
                                icon={item.icon}
                                setAlertToDelete={setAlertToDelete}
                            />
                        );
                    })
                }
            </div>
        </div>
    );
};

export default StatusMessages;
