// eslint-disable-next-line no-unused-vars
import React from 'react';
import {
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
    Theme,
    FormControl,
} from '@mui/material';
import SelectField from 'shared-components/SelectField';
import { Classes } from 'jss';
import ReactComponent from 'react-styleguidist/lib/client/rsg-components/ReactComponent';
import LocationSelectorField from './LocationSelectorField';
import CompanySelectorField from './CompanySelectorField';
import PackagingTypeFieldRender from './PackagingTypeFieldRender';

export type SelectItem = {
    value: string,
    render: string | ReactComponent,
}

export type InformationCardField = {
    id?: string,
    label?: string | ReactComponent,
    type?: 'text' | 'select' | 'radio'| 'location'| 'company' | string,
    items?: SelectItem[],
    default?: string,
    showOnly?: string[],
    request?: string,
    bodyAdditions?: object,
    readOnly?: boolean,
    disabled?: boolean,
    hide?: boolean,
    mandatory?: boolean,
    filter?: {
        [key:string] : string
    }
}

type FieldMapperProps = {
    field?: InformationCardField,
    onChange?: Function,
    editable?: boolean,
    value?: any,
    classes?: Classes,
    theme: Theme
}

export const fieldMapper = ({
    field,
    onChange,
    editable,
    value,
    classes,
    theme,
}: FieldMapperProps) => {
    if (field.hide) return <></>;
    if (editable && !field.readOnly) {
        switch (field.type) {
        case 'text':
            return (
                <TextField
                    key={`field_edit_${field.id}`}
                    className={classes.textField}
                    value={value}
                    label={field.label}
                    onChange={(ev) => onChange(
                        field.id, ev.target.value,
                    )}
                    variant="standard"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                        className: classes.textFieldText,
                    }}
                />
            );
        case 'location':
            return (
                <LocationSelectorField
                    key={`field_edit_${field.id}`}
                    field={field}
                    value={value}
                    onChange={address => {
                        onChange(field.id, address);
                    }}
                    mandatory={field.mandatory}
                    showOnly={field.showOnly}
                    filter={field.filter}
                    font="medium"
                />
            );
        case 'readOnlyLocation':
            return (
                <LocationSelectorField
                    key={`field_edit_${field.id}`}
                    field={field}
                    value={value}
                    font="medium"
                />
            );
        case 'radio':
            return (
                <FormControl
                    key={`field_edit_${field.id}`}
                >
                    <FormLabel className={classes.label}>
                        {field.label}
                    </FormLabel>
                    <RadioGroup
                        row
                        value={value?.code || value}
                        onChange={ev => {
                            ev.preventDefault();
                            onChange(field.id, ev.target.value);
                        }}
                        classes={{
                            row: classes.radioRow,
                        }}
                    >
                        {
                            field.items.map(radioItem => {
                                return (
                                    <FormControlLabel
                                        key={`field_edit_radio_${field.id}_${radioItem.value}`}
                                        control={(
                                            <Radio
                                                key={`field_edit_radio_${field.id}_${radioItem.value}_radio`}
                                                classes={{
                                                    root: classes.radio,
                                                    checked: classes.checked,
                                                }}
                                                style={{
                                                    color: value === radioItem.value
                                                        ? theme.palette.primary[400] : theme.palette.secondary[400],
                                                }}
                                            />
                                        )}
                                        label={radioItem.render}
                                        value={radioItem.value}
                                    />
                                );
                            })
                        }
                    </RadioGroup>
                </FormControl>
            );
        case 'select':
            return (
                <SelectField
                    key={`field_edit_${field.id}`}
                    className={classes.select}
                    label={field.label}
                    id={field.id}
                    value={value?.code || value}
                    onChange={(newValue) => onChange(field.id, newValue)}
                    emptyValue
                    items={field.items}
                />
            );
        case 'company':
            return (
                <CompanySelectorField
                    key={`field_edit_${field.id}`}
                    classes={classes}
                    request={field.request}
                    value={value}
                    readOnly={field.disabled}
                    clientSide
                    onChange={(value) => {
                        onChange(field.id, value);
                    }}
                    filter={field?.filter}
                    field={field}
                    font="medium"
                />
            );
        default:
            return null;
        }
    } else {
        let render;

        switch (field.type) {
        case 'select':
            // eslint-disable-next-line prefer-destructuring
            render = field?.items?.find(it => it.value === (value?.code || value))?.render || '';
            break;
        case 'packagingType':
            render = <PackagingTypeFieldRender key={`field_read_inner_${field.id}`} value={value} />;
            break;
        case 'location':
        case 'readOnlyLocation':
            // eslint-disable-next-line prefer-destructuring
            return (
                <LocationSelectorField
                    key={`field_read_inner_${field.id}`}
                    field={field}
                    value={value}
                    font="medium"
                />
            );
        case 'company':
            return (
                <CompanySelectorField
                    key={`field_read_inner_${field.id}`}
                    classes={classes}
                    request={field.request}
                    value={value}
                    readOnly
                    onChange={(value) => {
                        onChange(field.id, value);
                    }}
                    field={field}
                    font="medium"
                />
            );
        case 'radio':
            render = field.items.map(radioItem => {
                return (
                    <FormControlLabel
                        key={`field_read_inner_${field.id}_${radioItem.value}`}
                        control={(
                            <Radio
                                classes={{
                                    root: classes.radio,
                                }}
                            />
                        )}
                        checked={radioItem.value === (value?.code || value)}
                        label={radioItem.render}
                        value={radioItem.value}
                    />
                );
            });
            break;
        default:
            render = value || ' ';
        }

        return (
            <FormControl
                key={`field_read_wrapper_${field.id}`}
            >
                <span className={classes.label}>{field.label}</span>
                <div className={classes.valueText}>{render}</div>
            </FormControl>
        );
    }
};

export const defaultLaneInformationObject = {
    description: '',
    incoterms: 'DAP',
    comment: '',
    status: 'ACTIVE',
    temperatureRange: 'CRT',
};
