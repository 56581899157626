import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';

const useStyles = makeStyles(theme => ({
    modalBody: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '600px',
        backgroundColor: theme.palette.common['contrastWhite50'],
        boxShadow: '24',
    },
    historyData: {
        padding: '20px',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    historyHeader: {
        background: `${theme.palette.primary[200]} 0% 0% no-repeat padding-box`,
        height: '35px',
        alignItems: 'center',
        opacity: 1,
    },
    historyHeaderText: {
        padding: '8px',
        textAlign: 'left',
        font: 'normal normal 500 14px/17px Roboto',
        letterSpacing: '1.25px',
        color: 'white',
        textTransform: 'uppercase',
        opacity: 1,
    },
    infoTitle: {
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: theme.palette.secondary[500],
        opacity: 1,
    },
    fieldsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '15px',
    },
    approveButton: {
        width: '100px',
        height: '36px',
        margin: '9px',
    },
    rejectButton: {
        width: '100px',
        height: '36px',
        margin: '9px',
        textTransform: 'uppercase',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '16px',
    },
    dateText: {
        '& input': {
            cursor: 'pointer',
        },
        '& label': {
            color: 'rgb(183, 183, 183)',
        },
    },

}));

type Props = {
    handleClose: () => void,
    open: boolean,
}

const AddAudit = ({
    handleClose,
    open = false,
}: Props) => {
    const classes = useStyles();
    const [date, setDate] = useState(null);

    return (
        <LocalizationProvider dateAdapter={DateAdapter}>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div>
                    <div className={classes.modalBody}>
                        <div className={classes.historyHeader}>
                            <div className={classes.historyHeaderText}>ADD AUDIT</div>
                        </div>
                        <div className={classes.historyData}>
                            <div>Please add the information about the audit below.</div>
                            <div className={classes.fieldsContainer}>
                                <div>
                                    <div className={classes.infoTitle}>Audited On</div>
                                    <DatePicker
                                        value={date}
                                        inputFormat="DD.MM.YYYY"
                                        disableCloseOnSelect
                                        onAccept={setDate}
                                        onChange={() => {
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                className={classes.dateText}
                                                variant="standard"
                                                {...params}
                                            />
                                        )}
                                    />

                                </div>
                                <div>
                                    <div className={classes.infoTitle}>Audited By</div>
                                    <TextField />
                                </div>
                                <div>
                                    <div className={classes.infoTitle}>Audit Type</div>
                                    <TextField />
                                </div>
                            </div>
                            <div className={classes.buttonContainer}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.approveButton}
                                    autoFocus
                                >
                                    ADD
                                </Button>
                                <Button
                                    color="primary"
                                    variant="text"
                                    className={classes.rejectButton}
                                >
                                    CANCEL
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </LocalizationProvider>

    );
};

export default AddAudit;
