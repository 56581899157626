import React, { useCallback } from 'react';
import { MyLocation } from '@mui/icons-material';
import useCustomTranslation from 'hooks/useCustomTranslation';
import Button from '@mui/material/Button';
import { LatLng } from 'dataTypes/common';
import { useGoogleMap } from '@react-google-maps/api';
import { useStyles } from './CenterPositionButton.style';

type Props = {
    position: LatLng,
}

const CenterPositionButton = ({
    position,
}:Props) => {
    const classes = useStyles();
    const { t } = useCustomTranslation();
    const map = useGoogleMap();

    const onCenter = useCallback(() => {
        if (map && position) {
            map.panTo(position);
        }
    }, [position, map]);

    return (
        <div className={classes.pane}>
            <Button
                className={classes.button}
                onClick={onCenter}
                color="primary"
            >
                <MyLocation style={{ marginRight: 5 }} />
                <span>
                    {t('COMMON.CENTER_ON_POSITION')}
                </span>
            </Button>
        </div>
    );
};

export default CenterPositionButton;
