import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    tempDownIcon: {
        marginRight: '7px',
        marginLeft: '10px',
    },
    tempUpIcon: {
        marginRight: '7px',
        marginLeft: '20px',
    },
}));
