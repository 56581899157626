import React from 'react';
import { assetsPath } from 'shared-components/constants';
import useGetTranslationGroup from 'hooks/useGetTranslationGroup';
import { useStyles } from './PackagingTypeFieldRender.style';

type Props = {
    value: any[]
}

const PackagingTypeFieldRender = ({
    value = [],
}: Props) => {
    const classes = useStyles();
    const packagingCodeLabels = useGetTranslationGroup('PACKAGING_CODE_LABEL');

    return (
        <div>
            {
                value.map(code => {
                    return (
                        <div
                            className={classes.base}
                            key={code.type}
                        >
                            <img
                                className={classes.image}
                                src={`${assetsPath}/assets${code.packagingPictureUrl}`}
                                alt={code.type}
                            />
                            <div>{packagingCodeLabels[code.type] || code.type}</div>
                        </div>
                    );
                })
            }
        </div>
    );
};

export default PackagingTypeFieldRender;
