import React, {
    useEffect,
    useState, useMemo,
} from 'react';
import { Company, ContactInfo as ContactInfoType } from 'dataTypes/SecureBackend/apiResponse';
import { AddCompanyRequestBody, Address } from 'dataTypes/SecureBackend/processedData';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';

import AddressCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/AddressCard';
import CompanyCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/CompanyCard';
// eslint-disable-next-line max-len
import ContactInformationCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/ContactInformationCard';

import useHasAccess, { userRoles } from 'hooks/useHasAccess';
import NotificationChannelSettingsCard from 'MyProfile/components/NotificationChannelSettingsCard';
import BatteryExchangeLocationsCard from 'MyCompany/CompanyInfo/tabs/Overview/components/BatteryExchangeLocationsCard';
import PharmaCompanySites from './components/PharmaCompanySites';
import ServiceProviderSites from './components/ServiceProviderSites';
import useStyles from './Overview.style';

const Overview = () => {
    const classes = useStyles();
    const { Get: getCompany, FlexibleRequest: uploadFile } = useSecureBackendEndpoints('companies').requests;
    const { FlexibleRequest: editCompany } = useSecureBackendEndpoints('companies').statusNotificationRequests;
    const hasAccess = useHasAccess();

    const [companyInfo, setCompanyInfo] = useState(null);
    const [currentEditedCard, setCurrentEditedCard] = useState<string>(null);
    const [address, setAddress] = useState<Address>(null);
    const [contactInfo, setContactInfo] = useState<ContactInfoType>(null);
    const [addCompanyRequestBody, setAddCompanyRequestBody] = useState<AddCompanyRequestBody>(null);
    const [logoFormData, setLogoFormData] = useState<FormData>(null);
    const [requiredUpdateData, setRequiredUpdateData] = useState(true);

    const {
        company,
        setCompany,
        userInfo,
    } = useCurrentUserContext();
    const [loading, setLoading] = useState(false);

    const { companyId } = userInfo;

    const handleClickEdit = async () => {
        try {
            if (logoFormData) {
                await uploadFile('POST', `/${companyId}/logo`, logoFormData);
                setLogoFormData(null);
            }
            const { status = null } = await editCompany('PATCH', `/${companyId}/overview-info`, addCompanyRequestBody);

            if (status === 200) {
                setRequiredUpdateData(true);
            }
        } catch (error) {
            global.console.log(error);
        }
    };

    useEffect(() => {
        if (address && contactInfo && companyInfo) {
            setAddCompanyRequestBody({
                ...companyInfo,
                address,
                contactInfo,
            });
        }
    }, [
        address,
        contactInfo,
        companyInfo,
    ]);

    useEffect(() => {
        if (companyId && (company.id === null || requiredUpdateData)) {
            setLoading(true);
            (async () => {
                try {
                    const rawResponse = await getCompany(companyId);
                    const response: Company = rawResponse?.data || null;

                    if (response) {
                        setCompany(response);
                    }
                } catch (error) {
                    global.console.log(error);
                } finally {
                    setLoading(false);
                    setRequiredUpdateData(false);
                }
            })();
        }
    }, [companyId, requiredUpdateData]);

    useEffect(() => {
        (async () => {
            try {
                if (company.id) {
                    setContactInfo(company.contactInfo);
                    setCompanyInfo({
                        companyName: company.name,
                        companyType: company.companyType,
                        website: company.website,
                    });
                    setAddress({
                        addressLine1: company.address.addressLine1,
                        addressLine2: company.address.addressLine2,
                        addressLine3: company.address.addressLine3,
                        city: company.address.city,
                        countryCode: company.address.country.code,
                        geolocation: company.address.geolocation,
                        zipCode: company.address.zipCode,
                    });
                }
            } catch (error) {
                global.console.log(error);
            }
        })();
    }, [company]);

    const isCustomerAdmin = useMemo(() => hasAccess(userRoles.CUSTOMER_ADMIN), [hasAccess]);

    return (
        <>
            {
                company.id
                    ? (
                        <div className={classes.container}>
                            <CompanyCard
                                className={classes.company}
                                currentCompanyLogo={company.logoImagePath}
                                currentCompanyName={company.name}
                                currentCompanyType={company.companyType}
                                currentEditedCard={currentEditedCard}
                                currentWebsite={company.website}
                                type="editable"
                                saveInfo={handleClickEdit}
                                setCurrentEditedCard={setCurrentEditedCard}
                                setFilter={setCompanyInfo}
                                setLogoFormData={setLogoFormData}
                            />
                            <AddressCard
                                className={classes.address}
                                currentAddressLine1={company.address.addressLine1}
                                currentAddressLine2={company.address.addressLine2}
                                currentAddressLine3={company.address.addressLine3}
                                currentCity={company.address.city}
                                currentZipCode={company.address.zipCode}
                                currentCountry={company.address.country.name}
                                currentCountryCode={company.address.country.code}
                                currentGeolocation={company.address.geolocation}
                                currentEditedCard={currentEditedCard}
                                type="editable"
                                setFilter={setAddress}
                                saveInfo={handleClickEdit}
                                setCurrentEditedCard={setCurrentEditedCard}
                                isCompanyPage
                            />
                            <ContactInformationCard
                                className={classes.contact}
                                currentContactPerson={company.contactInfo.contactPerson}
                                currentPhoneNumber={company.contactInfo.phoneNumber}
                                currentEmail={company.contactInfo.email}
                                currentEditedCard={currentEditedCard}
                                type="editable"
                                setFilter={setContactInfo}
                                saveInfo={handleClickEdit}
                                setCurrentEditedCard={setCurrentEditedCard}
                                isCompanyPage
                            />
                            {
                                company.companyType === 'PHARMA' && (hasAccess(userRoles.SITE_MANAGEMENT)) && (
                                    <PharmaCompanySites
                                        className={classes.pcsTable}
                                        pharmaCompanyId={companyId}
                                    />
                                )
                            }
                            {
                                company.companyType === 'SERVICE_PROVIDER'
                                && (hasAccess(userRoles.SITE_MANAGEMENT)) && (
                                    <ServiceProviderSites
                                        className={classes.spsTable}
                                        serviceProviderCompanyId={companyId}
                                    />
                                )
                            }
                        </div>
                    )
                    : null
            }
            {
                isCustomerAdmin && (
                    <NotificationChannelSettingsCard
                        setCurrentEditedCard={setCurrentEditedCard}
                        currentEditedCard={currentEditedCard}
                        notificationSettings={company?.notificationSettings || []}
                        admin
                        setLoading={setLoading}
                        companyId={companyId}
                        loading={!company?.notificationSettings || loading}
                        setRequiredUpdateData={setRequiredUpdateData}
                    />
                )
            }
            {
                hasAccess(userRoles.ASSET_MANAGEMENT) && (
                    <BatteryExchangeLocationsCard
                        currentEditedCard={currentEditedCard}
                        setCurrentEditedCard={setCurrentEditedCard}
                        loading={loading}
                        className={classes.belTable}
                    />
                )
            }
        </>
    );
};

export default Overview;
