import React from 'react';

import MenuBar from './MenuBar';

function MenuBarContainer(props) {
    return (
        <MenuBar
            {...props}
        />
    );
}

export default MenuBarContainer;
