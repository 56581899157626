import { makeStyles } from '@mui/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import { CIRCLE_GROW, CIRCLE_SIZE } from './TooltipCluster';

const useStyles = makeStyles((theme:SkycellThemeInterface) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
    },
    tooltip: {
        background: 'white',
        width: `${CIRCLE_SIZE}px`,
        height: `${CIRCLE_SIZE}px`,
        borderRadius: '50%',
        border: `${CIRCLE_SIZE / 10}px solid ${theme.palette.primary.deepBlue}`,
        color: theme.palette.primary.deepBlue,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '14px',
        transition: '200ms ease',
        cursor: 'unset',
    },
    tooltipWarning: {
        borderColor: theme.palette.common.red,
        color: theme.palette.common.red,
    },
    tooltipPredicted: {
        borderColor: 'rgb(237, 174, 73)',
        color: 'rgb(237, 174, 73)',
    },
    active: {
        background: theme.palette.primary.deepBlue,
        color: 'white',
        width: `${CIRCLE_SIZE + CIRCLE_GROW}px`,
        height: `${CIRCLE_SIZE + CIRCLE_GROW}px`,
        transform: `translate(${-CIRCLE_GROW / 2}px,${-CIRCLE_GROW / 2}px)`,
        '&$tooltipPredicted': {
            background: 'rgb(237, 174, 73)',
        },
        '&$tooltipWarning': {
            background: theme.palette.common.red,
        },
    },
    clusterBody: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    tooltipHeader: {
        display: 'flex',
        margin: '1px auto',
        alignItems: 'center',
    },
    headerInfoGroup: {
        display: 'flex',
        margin: '0',
        alignItems: 'center',
    },
    headerInfo: {
        margin: '0 3px',
        alignItems: 'center',
    },
    headerInfoMessageText: {
        margin: '0 3px',
        alignItems: 'center',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    headerInfoWithTextStyle: {
        margin: '2px 6px',
        alignItems: 'center',
        textAlign: 'left',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    containerIcon: {
        marginTop: '2px',
        margin: 'auto 2px',
        width: '12px',
        height: '13px',
    },
    warningIcon: {
        marginTop: '2px',
        margin: 'auto 2px',
        width: '22px',
        height: '19px',
    },
}));

export default useStyles;
