import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Button,
    InputAdornment,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { AddressDTO } from 'shared-components/dataTypes';
import icons from 'shared-components/icons';
import Address from 'shared-components/NewLocationForm/components/Address';
import SkyTooltip from 'shared-components/SkyTooltip';
import { useStyles as rootStyles } from 'LaneManagement/AddLane/AddLane.style';
import CompanySelectorField from 'LaneManagement/AddLane/Cards/SummaryCard/CompanySelectorField';
import useGetAirports from 'hooks/useGetAirports';
import useGetSeaports from 'hooks/useGetSeaports';
import { TFunction } from 'react-i18next';
import { useStyles } from './LocationSelect.style';
import { nonNullKey } from '../common';

type Props = {
    /**
     * Event which is fired whenever new data is applied
     * @default undefined
     */
    onDataChange?: (...data: any) => any,
    /**
     * External open/closed state of the tooltip
     * @default false
     */
    open: boolean,
    /**
     * External action to close the picker
     * @default undefined
     */
    onCloseAction: any,
    /**
     * Use this to show only specific location types in the picker.
     * Specify as an array of types
     * AVAILABLE TYPES:
     * serviceProviderSite
     * pharmaSite
     * airport
     * seaport
     * address
     * @default undefined
     * @example ['pharmaSite', 'airport']
     */
    showOnly?: string[],
    filter?: any,
    addressData?: AddressDTO
}

export const LOCATION_OPTIONS = (t: TFunction) => ({
    serviceProviderSite: {
        name: t('LANE_MANAGEMENT.ADDRESS.SERVICE_PROVIDER_LOCATION'),
        icon: icons.address,
    },
    pharmaSite: {
        name: t('COMMON.PHARMA_SITE'),
        icon: icons.address,
    },
    airport: {
        name: t('LANE_MANAGEMENT.ADDRESS.AIRPORT'),
        icon: icons.airport,
    },
    seaport: {
        name: t('LANE_MANAGEMENT.ADDRESS.SEAPORT'),
        icon: icons.seaport,
    },
    address: {
        name: t('COMMON.ADDRESS'),
        icon: icons.location_type.ADDRESS,
    },
});
export const sortData = (data, type: 'airport' | 'seaport') => {
    return data.map(it => ({ [type]: it }))
        .sort((a, b) => a[type]?.code.localeCompare(b[type]?.code));
};

/**
 * This component is used for picking a location using a picker
 */
const LocationSelector = ({
    onDataChange,
    open,
    addressData = null,
    onCloseAction,
    showOnly = null,
    filter,
}: Props) => {
    const classes = useStyles();
    const { t } = useCustomTranslation();
    const rootClasses = rootStyles();
    const [selected, setSelected] = useState(showOnly ? showOnly[0] : 'serviceProviderSite');
    const [address, setAddress] : any = useState<AddressDTO>(addressData);
    const rawAirports = useGetAirports();
    const rawSeaports = useGetSeaports();

    const airports = useMemo(() => sortData(rawAirports, 'airport'), [rawAirports]);
    const seaports = useMemo(() => sortData(rawSeaports, 'seaport'), [rawSeaports]);

    const locationData = useMemo(() => {
        return selected === 'airport' ? airports : seaports;
    }, [airports, seaports, selected]);

    useEffect(() => {
        setAddress(null);
    },
    [selected]);

    useEffect(() => {
        if (addressData) {
            setSelected(nonNullKey(addressData));
            if (nonNullKey(addressData) !== 'address') {
                setAddress(addressData);
            }
        }
    }, [addressData, open]);

    const inputValidated = useMemo(() => {
        return !!address;
    }, [address]);

    const handleRadioGroupChange = (ev) => setSelected(ev.target.value);
    const handleAutocompleteChange : any = (event, value: AddressDTO) => setAddress(value);
    const handleAddressChange = (filter) => setAddress({ address: filter() });
    const handlePharmaSiteChange = (id, pharma) => setAddress(id ? { pharmaSite: pharma } : null);
    const handleServiceProviderSiteChange = (id, pharma) => setAddress(id ? { serviceProviderSite: pharma } : null);
    const handleAddClick = () => {
        onDataChange(address);
        onCloseAction();
    };

    const getOptionLabel = (option: AddressDTO) => {
        const type = nonNullKey(option);

        return (option[type]?.code
            ? `${option[type]?.code} | ${option[type]?.name}` : '');
    };

    const isOptionEqualToValue = (option, value) => {
        const type = nonNullKey(option);

        return option[type]?.code === value[type]?.code;
    };

    return (
        <SkyTooltip
            open={open}
            onCloseAction={onCloseAction}
            modalMode
            align="center"
            label={t('LANE_MANAGEMENT.ADD_SERVICE_PROVIDER_LOCATION')}
        >
            <div className={classes.body}>
                <div className={classes.bodyLeft}>
                    <span className={classes.topTitle}>{t('LANE_MANAGEMENT.SELECT_FROM')}</span>
                    <RadioGroup
                        value={selected}
                        onChange={handleRadioGroupChange}
                    >
                        {
                            Object.keys(LOCATION_OPTIONS(t))
                                .filter(key => (showOnly ? showOnly.includes(key) : true))
                                .map(key => {
                                    return (
                                        <FormControlLabel
                                            key={`LOCATION_OPTION_${key}`}
                                            control={(
                                                <Radio
                                                    classes={{
                                                        root: classes.radio,
                                                        checked: classes.checked,
                                                    }}
                                                    className={classes.radioItem}
                                                    color="default"
                                                    disableRipple
                                                />
                                            )}
                                            label={(
                                                <div className={classes.buttonLabel}>
                                                    {LOCATION_OPTIONS(t)[key].name}
                                                </div>
                                            )}
                                            value={key}
                                        />
                                    );
                                })
                        }
                    </RadioGroup>
                </div>
                <div className={classes.addressBody}>
                    <span className={classes.topTitle}>{LOCATION_OPTIONS(t)?.[selected]?.name}</span>
                    {
                        (selected === 'airport' || selected === 'seaport') && (
                            <Autocomplete
                                className={classes.autocomplete}
                                onChange={handleAutocompleteChange}
                                value={address}
                                size="small"
                                options={locationData}
                                getOptionLabel={getOptionLabel}
                                isOptionEqualToValue={isOptionEqualToValue}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        className={classes.textField}
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <img src={LOCATION_OPTIONS(t)[selected].icon} alt="" />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />

                                )}
                            />
                        )
                    }
                    {
                        selected === 'address' && (
                            <Address
                                cardClassName={classes.addressCard}
                                cardRootClassName={classes.addressCardRoot}
                                setFilter={handleAddressChange}
                                initialData={addressData?.address}
                                title=""
                            />
                        )
                    }
                    {
                        selected === 'pharmaSite' && (
                            <CompanySelectorField
                                classes={classes}
                                request="pharma-sites"
                                value={address?.pharmaSite?.id}
                                onChange={handlePharmaSiteChange}
                                field={{}}
                                clientSide
                            />
                        )
                    }
                    {
                        selected === 'serviceProviderSite' && (
                            <CompanySelectorField
                                classes={classes}
                                request="service-provider-sites"
                                value={address?.serviceProviderSite?.id}
                                onChange={handleServiceProviderSiteChange}
                                field={{}}
                                clientSide
                                filter={filter}
                            />
                        )
                    }
                </div>
            </div>
            <div className={rootClasses.buttonContainer}>
                <Button
                    color="primary"
                    variant="contained"
                    autoFocus
                    disabled={!inputValidated}
                    className={[rootClasses.button, rootClasses.spaced].join(' ')}
                    onClick={handleAddClick}
                >
                    {t('MY_COMPANY.ADD')}
                </Button>
                <Button
                    color="primary"
                    variant="text"
                    className={[rootClasses.button, rootClasses.spaced].join(' ')}
                    onClick={onCloseAction}
                >
                    {t('COMMON.CANCEL')}

                </Button>
            </div>
        </SkyTooltip>
    );
};

export default LocationSelector;
