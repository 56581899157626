import React, { MouseEventHandler } from 'react';
import {
    GoogleMap,
    useLoadScript,
    Polyline,
    Marker,
} from '@react-google-maps/api';

import { CompositePolyline, LatLng } from 'dataTypes/common';
import icons from 'shared-components/icons';
import {
    MAP_CONFIG_DEFAULT,
    LIBRARIES, ZOOM_DEFAULTS,
} from 'shared-components/constants';
import {
    MapConfig,
    Geolocation,
} from 'shared-components/dataTypes';
import useScreenSize from 'hooks/useScreenSize';
import moment from 'moment';
import { ZoomOutMap, ZoomInMap } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import LocationInfoTooltip from './components/LocationInfoTooltip';
import CenterPositionButton from './components/CenterPositionButton';

type Props = {
    compositePolyline?: CompositePolyline[],
    mapConfig?: MapConfig,
    inLocalTimeZone?: boolean,
    separateMarker?: LatLng,
    separatePolyline?: CompositePolyline,
    separateMarkerIcon?: string | object,
    separateTooltip?: {
        location?: LatLng,
        time?: string,
        timeStamp?: number,
    },
    handleLoad?: (map: any) => void,
    draggableMarker?: {
        startPostion: LatLng,
        setGeolocationFromMap: (geolocation: Geolocation) => void,
    },
    showCenterToPosition?: boolean,
    googleMapOptionsOverride?: {
        backgroundColor?: string;
        clickableIcons?: boolean;
        disableDefaultUI?: boolean;
        disableDoubleClickZoom?: boolean;
        draggable?: boolean;
        draggableCursor?: string;
        draggingCursor?: string;
        fullscreenControl?: boolean;
        heading?: number;
        keyboardShortcuts?: boolean;
        mapTypeControl?: boolean;
        maxZoom?: number;
        minZoom?: number;
        noClear?: boolean;
        panControl?: boolean;
        rotateControl?: boolean;
        scaleControl?: boolean;
        scrollwheel?: boolean;
        streetViewControl?: boolean;
        mapTypeId?: string,
        tilt?: number;
        zoom?: number;
        zoomControl?: boolean;
    },
    customFullScreenControl?: MouseEventHandler<HTMLDivElement>,
    noMinZoomCap?: boolean,
    mapFullscreenIn?: boolean,
}

const {
    REACT_APP_GOOGLE_MAP_KEY: googleMapKey,
} = process.env;

const Map = ({
    compositePolyline = [],
    mapConfig = MAP_CONFIG_DEFAULT,
    inLocalTimeZone = false,
    separateMarker = null,
    separatePolyline = null,
    separateTooltip = null,
    separateMarkerIcon = null,
    draggableMarker = null,
    handleLoad = null,
    showCenterToPosition = false,
    googleMapOptionsOverride = {},
    customFullScreenControl = null,
    noMinZoomCap = false,
    mapFullscreenIn,
}: Props) => {
    const separateMarkerIconObj = separateMarkerIcon && {
        url: separateMarkerIcon,
        scaledSize: {
            width: 20,
            height: 20,
        },
        origin: {
            x: 0,
            y: 0,
        },
        anchor: {
            x: 10,
            y: 10,
        },
    };
    const theme = useTheme<SkycellThemeInterface>();

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: googleMapKey,
        mapIds: ['c5af2a2e4049bce5'],
        libraries: LIBRARIES,
    });

    const { currentSize } = useScreenSize();

    const handleDraggableMarkerPosition = (e) => {
        draggableMarker.setGeolocationFromMap({
            latitude: e.latLng.lat(),
            longitude: e.latLng.lng(),
        });
    };

    return (
        isLoaded ? (
            <GoogleMap
                onLoad={handleLoad}
                key="GoogleMap"
                mapContainerStyle={mapConfig.containerStyle}
                zoom={mapConfig.zoom}
                center={mapConfig?.center || null}
                options={{
                    mapId: 'c5af2a2e4049bce5',
                    disableDefaultUI: true,
                    fullscreenControl: true,
                    backgroundColor: mapConfig.backgroundColor,
                    minZoom: noMinZoomCap ? 0 : ZOOM_DEFAULTS[currentSize], // This will probably change,
                    ...googleMapOptionsOverride,
                }}
            >
                {
                    separateMarker
                        && separateMarker.lat
                        && separateMarker.lng
                        && (
                            <Marker
                                position={separateMarker}
                                icon={separateMarkerIconObj || icons.map_location_destination}
                            />
                        )
                }
                {
                    separateTooltip
                            && separateTooltip.location
                            && separateTooltip.location.lat
                            && separateTooltip.location.lng
                            && (
                                <>
                                    <LocationInfoTooltip
                                        location={separateTooltip.location}
                                        time={
                                            !inLocalTimeZone
                                                ? moment(separateTooltip?.timeStamp).local()
                                                    .format('YYYY-MM-DD HH:mm')
                                                : moment(separateTooltip?.timeStamp).format('YYYY-MM-DD HH:mm')
                                        }
                                    />
                                </>
                            )
                }

                {
                    draggableMarker && (
                        <Marker
                            position={draggableMarker.startPostion}
                            draggable
                            onDragEnd={handleDraggableMarkerPosition}
                            icon={icons.map_location_destination}
                        />
                    )
                }
                {
                    compositePolyline.map((polyline, polylineIndex) => {
                        return (
                            <Polyline
                                key={`polylineId${polylineIndex}`}
                                path={polyline.path}
                                options={polyline.options}
                            />
                        );
                    })
                }
                {
                    separatePolyline !== null && (
                        <Polyline
                            path={separatePolyline.path}
                            options={separatePolyline.options}
                        />
                    )
                }
                {
                    showCenterToPosition
                    && <CenterPositionButton position={separateTooltip?.location} />
                }
                {customFullScreenControl && (
                    <div
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            width: '28px',
                            height: '26px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '6px',
                            boxShadow: '0px 1px 3px #00000033',
                            cursor: 'pointer',
                            color: 'rgba(0, 0, 0, 0.54)',
                            backgroundColor: theme.palette.common.white,
                        }}
                        onClick={customFullScreenControl}
                    >
                        {
                            mapFullscreenIn ? <ZoomInMap style={{ fontSize: '20px' }} />
                                : <ZoomOutMap style={{ fontSize: '20px' }} />
                        }
                    </div>
                )}
            </GoogleMap>
        )
            : null
    );
};

export default Map;
