import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    headerInfoGroup: {
        display: 'flex',
        margin: '0 5px',
        alignItems: 'center',
    },
    headerInfoGroupSmall: {
        margin: '0',
    },
    headerInfo: {
        margin: '0 3px',
        alignItems: 'center',
    },
    headerInfoWithCodeStyle: {
        margin: '0 3px',
        alignItems: 'center',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.common.black,
        opacity: 1,
    },
    rightArrow: {
        margin: '3px',
        alignItems: 'center',
        display: 'flex',
    },
    containerFlex: {
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto 5%',
        display: 'flex',
        font: 'normal normal normal 14px/17px Roboto',
        color: theme.palette.secondary[600],
    },
    airportInfo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '12px auto',
        // borderBottom: `1px solid ${theme.palette.secondary[300]}`,
    },
    shipmentNumber: {
        margin: '8px 0',
    },
    infoField: {
        maxWidth: 'max-content(360px)',
        color: theme.palette.secondary[600],
        textAlign: 'left',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        margin: '10px',
    },
    informationTableIsPending: {
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto 5%',
        maxWidth: '400px',
    },
    informationTableIsAllApproved: {
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto 3% auto 5%',
        display: 'flex',
        font: 'normal normal normal 14px/17px Roboto',
        color: theme.palette.secondary[600],
    },
    informationTableIsAllApprovedIcon: {
        margin: '0 3px',
        alignItems: 'center',
        width: '36px',
        height: '38px',
    },
    buttonsContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
    buttonApprove: {
        textTransform: 'uppercase',
        width: '103px',
        height: '36px',
    },
    buttonCheck: {
        textTransform: 'uppercase',
        margin: '5px',
        width: '82px',
        height: '36px',
    },
    link: {
        textDecoration: 'none',
    },
    line: {
        color: `${theme.palette.secondary[300]}`,
        opacity: 0.5,
        margin: '0',
    },
    packagingsBlock: {
        borderTop: `1px solid ${theme.palette.secondary[300]}`,
        marginTop: '-1px',
    },
    packagingInfoContainer: {
        borderBottom: `1px solid ${theme.palette.secondary[300]}`,
        padding: '1px 6px 1px 6px',
        '&:hover': {
            padding: '0 0 1px 0',
            borderTop: '1px solid #EDAE49',
            borderRight: '6px solid #EDAE49',
            borderBottom: '1px solid #EDAE49',
            borderLeft: '6px solid #EDAE49',
        },
    },
    selectedPackaging: {
        padding: '0 0 1px 0',
        borderTop: '1px solid #EDAE49',
        borderRight: '6px solid #EDAE49',
        borderBottom: '1px solid #EDAE49',
        borderLeft: '6px solid #EDAE49',
    },
}));

export default useStyles;
