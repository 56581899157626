import React, {
    ReactNode,
    useMemo,
    useRef,
    useState,
} from 'react';
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useScreenSize from 'hooks/useScreenSize';
import { ScreenType } from 'hooks/useScreenSize/useScreenSize';
import useStyles from './SideFilter.style';

const closedBarSize = 20;

type Props = {
    rightSideMargin?: boolean,
    children: ReactNode,
}
const SideFilter = ({ rightSideMargin = false, children }: Props) => {
    const classes = useStyles();
    const { t } = useCustomTranslation();
    const { width, checkScreenType } = useScreenSize();

    const filtersRef = useRef(null);
    const hideButtonWrapperRef = useRef(null);
    const [filtersOpen, setFiltersOpen] = useState(checkScreenType.up(ScreenType.MD));

    const handleClick = () => setFiltersOpen(prev => !prev);

    const filterSizes = useMemo<Object>(() => {
        return {
            width: checkScreenType.down(ScreenType.SM)
                ? 'auto'
                : (filtersOpen
                    ? (filtersRef?.current?.scrollWidth || 'auto')
                    : closedBarSize),
            height: checkScreenType.up(ScreenType.SM)
                ? 'auto'
                : (filtersOpen
                    ? (filtersRef?.current?.firstElementChild?.scrollHeight
                        + (hideButtonWrapperRef?.current?.scrollHeight) || 'auto')
                    : closedBarSize + 24),
        };
    }, [filtersOpen, width]);

    return (
        <div
            className={[
                classes.container,
                filtersOpen
                    ? rightSideMargin ? classes.marginRight : ''
                    : classes.hidden,
            ].join(' ')}
            ref={filtersRef}
            style={filterSizes}
        >
            <div className={[classes.filtersPane, filtersOpen ? '' : classes.filtersPaneOff].join(' ')}>
                {children}
            </div>
            <div
                className={[classes.hideButtonWrapper, filtersOpen ? '' : classes.hideButtonWrapperClosed].join(' ')}
                ref={hideButtonWrapperRef}
            >
                <div
                    className={[classes.hideButton].join(' ')}
                    onClick={handleClick}
                    title={t('TRACK_AND_TRACE.FILTERS')}
                >
                    <div
                        className={[classes.hideButtonArrow, filtersOpen ? '' : classes.rotate].join(' ')}
                    >
                        <ArrowBackIosNew className={classes.arrowSvg} />
                    </div>
                    {filtersOpen || checkScreenType.down(ScreenType.SM)
                        ? (
                            <span className={classes.hideButtonText}>
                                {' '}
                                {filtersOpen ? t('TRACK_AND_TRACE.HIDE_FILTERS')
                                    : t('TRACK_AND_TRACE.SHOW_FILTERS')}
                            </span>
                        )
                        : ''}
                </div>
            </div>
        </div>
    );
};

export default SideFilter;
