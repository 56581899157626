import icons from 'shared-components/icons';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import { TFunction } from 'react-i18next';
import { ScreenType } from '../hooks/useScreenSize/useScreenSize';

export const {
    REACT_APP_SKYCELL_ASSETS_PATH: assetsPath = 'https://secure-assets.dev.skycell.ch',
} = process.env;

export const ORDER_STEP_TYPE = {
    CLOSED: 'CLOSED',
    IN_PROGRESS: 'IN_PROGRESS',
    NOT_STARTED: 'NOT_STARTED',
    COMPLETED: 'COMPLETED',
};

export const APPROVAL_STATUS = {
    APPROVED: 'APPROVED',
    CHECK_NOT_PASSED: 'CHECK_NOT_PASSED',
    CHECK_PASSED: 'CHECK_PASSED',
    NOT_APPLICABLE: 'NOT_APPLICABLE',
    NOT_CHECKED: 'NOT_CHECKED',
    REJECTED: 'REJECTED',
    RELEASED: 'RELEASED',
    WAITING_FOR_APPROVAL: 'WAITING_FOR_APPROVAL',
    PREDICTED_EXCURSION: 'PREDICTED_EXCURSION',
};

export const TEMPERATURE_STATUS = {
    EXCURSION: 'EXCURSION',
    PREDICTED_EXCURSION: 'PREDICTED_EXCURSION',
    IN_RANGE: 'IN_RANGE',
    NOT_APPLICABLE: 'NOT_APPLICABLE',
    UNKNOWN: 'UNKNOWN',
    WAITING_FOR_DATA: 'WAITING_FOR_DATA',
    NO_DATA: 'NO_DATA',
};

export enum STEP_TYPES {
    ROAD = 'ROAD',
    AIR = 'AIR',
    SEA = 'SEA',
    CONFIRMATION = 'CONFIRMATION',
    CUSTOMS = 'CUSTOMS',
    EVENT = 'EVENT'
}

export const TEMPERATURE_RANGES = {
    CRT: 'CRT (15 °C to 25 °C)',
    C: 'COL (2°C to 8°C)',
    F: 'F (-25°C to -15°C)',
    DF: 'DF (-80°C to -60°C)',
};
export const TEMPERATURE_RANGES_T = (t:TFunction = () => 'to') => ({
    CRT: `CRT (15 °C ${t('SENSOR_DATA.TO')} 25 °C)`,
    C: `COL (2°C ${t('SENSOR_DATA.TO')} 8°C)`,
    F: `F (-25°C ${t('SENSOR_DATA.TO')} -15°C)`,
    DF: `DF (-80°C ${t('SENSOR_DATA.TO')} -60°C)`,
});
export const INCOTERMS = {
    EXW: 'Ex Works',
    FCA: 'Free Carrier',
    FAS: 'Free Alongside Ship',
    FOB: 'Free On Board',
    CFR: 'Cost And Freight',
    CIF: 'Cost, Insurance And Freight',
    CPT: 'Carriage Paid To',
    CIP: 'Carriage Insurance Paid To',
    DATA: 'Delivered At Terminal',
    DAP: 'Delivered At Place',
    DDP: 'Delivered Duty Paid',
};

export const TEMPERATURE_RANGES_OPTIONS = {
    CRT: 'CRT',
    C: 'COL',
    F: 'F',
    DF: 'DF',
};

export const TEMPERATURE_RANGES_VALUES = {
    CRT: {
        tempRangeMin: 15,
        tempRangeMax: 25,
    },
    C: {
        tempRangeMin: 2,
        tempRangeMax: 8,
    },
    F: {
        tempRangeMin: -25,
        tempRangeMax: -15,
    },
    DF: {
        tempRangeMin: -80,
        tempRangeMax: -60,
    },
};

export const PROCESS_EVIDENCE_STEP_NAME = {
    SEAL: {
        SEAL: 'SEAL',
        SEAL_NUMBER: 'SEAL_NUMBER',
    },
    CONTAINER: {
        CONTAINER_BACK: 'CONTAINER_BACK',
        CONTAINER_FRONT: 'CONTAINER_FRONT',
        CONTAINER_INSIDE: 'CONTAINER_INSIDE',
        CONTAINER_LEFT: 'CONTAINER_LEFT',
        CONTAINER_RIGHT: 'CONTAINER_RIGHT',
    },
    PALLET: {
        PALLET_BACK: 'PALLET_BACK',
        PALLET_FRONT: 'PALLET_FRONT',
        PALLET_LEFT: 'PALLET_LEFT',
        PALLET_RIGHT: 'PALLET_RIGHT',
    },
    PALLET_1: {
        PALLET_1_BACK: 'PALLET_1_BACK',
        PALLET_1_FRONT: 'PALLET_1_FRONT',
        PALLET_1_LEFT: 'PALLET_1_LEFT',
        PALLET_1_RIGHT: 'PALLET_1_RIGHT',
    },
    PALLET_2: {
        PALLET_2_BACK: 'PALLET_2_BACK',
        PALLET_2_FRONT: 'PALLET_2_FRONT',
        PALLET_2_LEFT: 'PALLET_2_LEFT',
        PALLET_2_RIGHT: 'PALLET_2_RIGHT',
    },
};

export const PROCESS_EVIDENCE_STEP_TYPE = {
    PICTURE: 'PICTURE',
    PICTURE_COMPARISON: 'PICTURE_COMPARISON',
};
export const iconsPath = `${assetsPath}/assets/images/icons/`;
export const docsPath = `${assetsPath}/assets/documents/`;

export const containersPath = `${assetsPath}/assets/containerTypes/`;

export const orderStatus = {
    NOT_STARTED: 'Not Started',
    BOOKED: 'Booked',
    TO_BE_PROVISIONED: 'To Be Provisioned',
    READY_TO_SHIP: 'Ready To Ship',
    SELECTED: 'Selected',
    RESERVED: 'Reserved',
};

const picturesPath = `${assetsPath}/assets/images/pictures/`;

export const PICTURES = {
    reading_robot: `${picturesPath}reading_robot.svg`,
    searching_robot: `${picturesPath}searching_robot.svg`,
    sleeping: `${picturesPath}sleeping.svg`,
    noAccessRights: `${picturesPath}noAccessRights.svg`,
    noAccessRightsWithout404Bubble: `${picturesPath}noAccessRightsWithout404Bubble.svg`,
    noDataFound: `${picturesPath}noDataFound.svg`,
    howToZoom: `${picturesPath}how_to_zoom.svg`,
    uld: `${containersPath}uld.png`,
    airport_pallet: `${containersPath}airport_pallet.png`,
};

export const LOGGER_TYPE = {
    SECURE_LIVE_SR: 'SkyMind Live SR',
    SECURE_LIVE_MR: 'SkyMind Live MR',
    SECURE_LIVE_LR: 'SkyMind Live LR',
    SECURE_LIVE_CL: 'SkyMind Live CL',
    LIBERO_GL: 'Libero GL',
    LIBERO_GF: 'Libero GF',
    LIBERO_GT: 'Libero GT',
};

export enum LoggerUsage {
    STANDALONE='STANDALONE',
    LINKED_TO_PACKAGING='LINKED_TO_PACKAGING',
    USED_IN_SHIPMENT='USED_IN_SHIPMENT'
}

export const MAP_CONFIG_DEFAULT = {
    center: {
        lat: 48.15000867144938,
        lng: 17.102891139571113,
    },
    containerStyle: {
        height: '20vh',
        width: '100%',
    },
    zoom: 3,
    backgroundColor: 'unset',
};

export const MAP_CONFIG = {
    center: {
        lat: 30, lng: 8,
    },
    containerStyle: {
        height: '75vh',
        width: '100%',
    },
    zoom: 3,
    backgroundColor: 'unset',
};

export const TIME_RANGE_PERIOD = {
    '24h': 1,
    '7d': 7,
    '14d': 14,
    Manual: 0,
};

export const LIBRARIES: Libraries = ['places', 'drawing'];

export const ZOOM_DEFAULTS = {
    [ScreenType.XL]: 3,
    [ScreenType.LG]: 2,
    [ScreenType.MD]: 1.7,
    [ScreenType.SM]: 1.5,
    [ScreenType.XS]: 1.3,
    [ScreenType.XXS]: 1,
};

export const ORDERS_IN_PAGE = 10;

export const DOTS = '...';

export const ORDER_FIELDS_COUNT = (t) => ({
    orderStatus: {
        field: 'orderStatus',
        title: t('TRACK_AND_TRACE.TITLE_SHIPMENT_STATUS'),
    },
    selectedAssetsProductReleaseApprovalStatus: {
        field: 'selectedAssetsProductReleaseApprovalStatus',
        title: t('COMMON.APPROVALS'),
    },
    selectedAssetsProductReleaseTemperatureStatus: {
        field: 'selectedAssetsProductReleaseTemperatureStatus',
        title: t('COMMON.TEMPERATURE_EXCURSIONS'),
    },
    originAirportTitle: {
        field: 'originAirportTitle',
        title: t('COMMON.ORIGIN_AIRPORT'),
    },
    destinationAirportTitle: {
        field: 'destinationAirportTitle',
        title: t('COMMON.DESTINATION_AIRPORT'),
    },

});

export const SERVICE_PROVIDER_FILTER_DATA = {
    services: {
        GROUND_HANDLER: {
            icon: icons.ground_handler,
            label: 'Ground Handler',
        },
        WAREHOUSE: {
            icon: icons.warehouse,
            label: 'Warehouse',
        },
        FORWARDER: {
            icon: icons.forwarder,
            label: 'Forwarder',
        },
        AIRLINE: {
            icon: icons.airline,
            label: 'Airline',
        },
        PACKAGING_PROVIDER: {
            icon: icons.packaging_provider,
            label: 'Packaging Provider',
        },
    },
    commodities: {
        HEALTHCARE_PHARMA: {
            icon: icons.healthcare_pharmaceuticals,
            label: 'Healthcare / Pharmaceuticals',
        },
        DANGEROUS_GOODS_CHEMICALS: {
            icon: icons.dangerous_goods,
            label: 'Dangerous Goods & Chemicals',
        },
        HIGH_VALUE_CARGO: {
            icon: icons.high_value_cargo,
            label: 'High Value Cargo',
        },
        LIVE_ANIMALS: {
            icon: icons.live_animals,
            label: 'Live Animals',
        },
        PERISHABLES: {
            icon: icons.perishables,
            label: 'Perishables (Food, Flowers)',
        },
    },
    facilities: {
        DANGEROUS_GOODS_STORAGE: {
            icon: icons.dangerous_goods,
            label: 'Dangerous Goods Storage',
        },
        CRT_AREA: {
            icon: icons.temperature.CRT,
            label: 'CRT Area',
        },
        C_AREA: {
            icon: icons.temperature.C,
            label: 'C Area',
        },
        FREEZER: {
            icon: icons.temperature.F,
            label: 'Freezer',
        },
        ACT_PLUG: {
            icon: icons.act_plug,
            label: 'ACT Plug',
        },
        VAULT: {
            icon: icons.vault,
            label: 'Vault',
        },
        CAGE: {
            icon: icons.cage,
            label: 'Cage',
        },
        ANIMAL_STORAGE_AREA: {
            icon: icons.animal_storage_area,
            label: 'Animal Storage Area',
        },
    },
};
export const SERVICE_PROVIDER_FILTER_DATA_T = (t = w => w) => ({
    facilities: {
        DANGEROUS_GOODS_STORAGE: {
            icon: icons.dangerous_goods,
            label: t('SERVICE_PROVIDERS.DANGEROUS_GOODS_STORAGE'),
        },
        CRT_AREA: {
            icon: icons.temperature.CRT,
            label: t('SERVICE_PROVIDERS.CRT_AREA'),
        },
        C_AREA: {
            icon: icons.temperature.C,
            label: t('SERVICE_PROVIDERS.C_AREA'),
        },
        FREEZER: {
            icon: icons.temperature.F,
            label: t('SERVICE_PROVIDERS.FREEZER'),
        },
        ACT_PLUG: {
            icon: icons.act_plug,
            label: t('SERVICE_PROVIDERS.ACT_PLUG'),
        },
        VAULT: {
            icon: icons.vault,
            label: t('SERVICE_PROVIDERS.VAULT'),
        },
        CAGE: {
            icon: icons.cage,
            label: t('SERVICE_PROVIDERS.CAGE'),
        },
        ANIMAL_STORAGE_AREA: {
            icon: icons.animal_storage_area,
            label: t('SERVICE_PROVIDERS.ANIMAL_STORAGE_AREA'),
        },
    },
    services: {
        GROUND_HANDLER: {
            icon: icons.ground_handler,
            label: t('SERVICE_PROVIDERS.GROUND_HANDLER'),
        },
        WAREHOUSE: {
            icon: icons.warehouse,
            label: t('SERVICE_PROVIDERS.WAREHOUSE'),
        },
        FORWARDER: {
            icon: icons.forwarder,
            label: t('SERVICE_PROVIDERS.FORWARDER'),
        },
        AIRLINE: {
            icon: icons.airline,
            label: t('SERVICE_PROVIDERS.AIRLINE'),
        },
        PACKAGING_PROVIDER: {
            icon: icons.packaging_provider,
            label: t('SERVICE_PROVIDERS.PACKAGING_PROVIDER'),
        },
    },
    commodities: {
        HEALTHCARE_PHARMA: {
            icon: icons.healthcare_pharmaceuticals,
            label: t('SERVICE_PROVIDERS.HEALTHCARE_PHARMACEUTICALS'),
        },
        DANGEROUS_GOODS_CHEMICALS: {
            icon: icons.dangerous_goods,
            label: t('SERVICE_PROVIDERS.DANGEROUS_GOODS_CHEMICALS'),
        },
        HIGH_VALUE_CARGO: {
            icon: icons.high_value_cargo,
            label: t('SERVICE_PROVIDERS.HIGH_VALUE_CARGO'),
        },
        LIVE_ANIMALS: {
            icon: icons.live_animals,
            label: t('SERVICE_PROVIDERS.LIVE_ANIMALS'),
        },
        PERISHABLES: {
            icon: icons.perishables,
            label: t('SERVICE_PROVIDERS.PERISHABLES_FOOD_FLOWERS'),
        },
    },

});

export const BROWSER_LANGUAGE = navigator?.languages[0]
                            || navigator?.language
                            || navigator['userLanguage']
                            || navigator['browserLanguage'];

export const GOOGLE_LOCATION_INFO = {
    country: 'country',
    locality: 'locality',
    postal_code: 'postal_code',
};

// eslint-disable-next-line
export const EMAIL_PATTERN = /\w+(?:[.-]\w+)*@\w+(?:[.-]\w+)*(?:\.\w{2,3})+/
// eslint-disable-next-line
export const WEBSITE_PATTERN = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-zA-Z0-9()]{2,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

export const TIME_IN_MS = {
    second: 1000,
    minute: 60 * 1000,
    hour: 60 * 60 * 1000,
    day: 24 * 60 * 60 * 1000,
    week: 7 * 24 * 60 * 60 * 1000,
};

export const CHART_GRAPH_COLORS = [
    '#8BD1E8', '#2C5969',
    '#4790A8', '#4C9BB5',
    '#57B1CF', '#61C6E9',
    '#6FCAE8', '#2980B9',
    '#5499C7', '#7FB3D5',
    '#A9CCE3',
];

export const POSITION_TO_COLOR = {
    INTERNAL: '#8BD1E8',
    AMBIENT: '#2C5969',
    UNSPECIFIED: '#2C5969',
};

export const CHART_ANNOTATION_COLORS = [
    '#CF3B8A', '#D44848',
    '#17921F', '#EEE172',
    '#EDAE49', '#AB47BC',
    '#43A047', '#5D6D7E',
    '#1ABC9C',
];

export const LOCATION_COLORS = ['#E0F7FF', '#F0DEBF', '#8BD1E8', '#C5D86D', '#E2C044', '#90EE7E',
    '#6FCAE8', '#2980B9', '#5499C7', '#7FB3D5', '#A9CCE3'];

export const COMPANY_TYPE_LABELS = (t = w => w) => ({
    PHARMA: t('COMMON.PHARMA_COMPANY'),
    SERVICE_PROVIDER: t('ADMINISTRATION.SERVICE_PROVIDER'),
});

export const SENSOR_DATA_REQUEST_PARAMS = 'dataTypes=TEMPERATURE,DOOR&positions=AMBIENT,INTERNAL';

export const utcDateTimeRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/g;
