import React, { ReactElement, useMemo } from 'react';
import { attributeParser } from 'Notifications/components/ParsedMessage/lib';
import { useStyles } from 'Notifications/components/ParsedMessage/ParsedMessage.style';
import { Link } from 'react-router-dom';

export const AVAILABLE_TAGS = ['link', 'b', 'i'];

export const SPLIT_XML_REGEX = new RegExp(`<br/>|${AVAILABLE_TAGS
    .map(it => `<${it}.*?>(.*?)</${it}>`).join('|')}|[\\w\\d() .,:@+\\/-]+`, 'g');
export const STARTS_WITH = {
    BREAK: '<br/>',
    LINK: '<link',
    BOLD: '<b>',
    ITALIC: '<i>',
};

const LinkParser = ({
    message,
    children,
}) => {
    const attributes = useMemo<{ [key: string]: any }>(() => attributeParser(message), [message]);
    const classes = useStyles();
    const {
        to,
    } = attributes;
    const isMail = to?.startsWith('mailto');

    return (
        <Link
            to={isMail ? '#' : to}
            onClick={(e) => {
                if (isMail) {
                    window.location.href = to;
                    e.preventDefault();
                }
            }}
            target="_blank"
            className={classes.link}
        >
            {children}
        </Link>
    );
};

type Props = {
    message: string,
}

const bodyFromMessage: (message: string) => ReactElement[] = (message) => {
    const splittedMessage: RegExpMatchArray[] = Array.from(message.matchAll(SPLIT_XML_REGEX));

    const messageArray: ReactElement[] = splittedMessage
        .map(occurrence => {
            const filteredOccurrences = occurrence.filter(it => it);
            const [topLevel, lowLevel] = filteredOccurrences;

            let content: ReactElement[] | string = lowLevel || topLevel;

            const subOccurrences = Array.from(lowLevel?.matchAll(SPLIT_XML_REGEX) || [])
                .filter(it => it);

            if (subOccurrences.length > 1) content = bodyFromMessage(lowLevel);
            if (topLevel.startsWith(STARTS_WITH.BREAK)) {
                return <br />;
            } else if (topLevel.startsWith(STARTS_WITH.LINK)) {
                return <LinkParser message={topLevel}>{content}</LinkParser>;
            } else if (topLevel.startsWith(STARTS_WITH.BOLD)) {
                return <b>{content}</b>;
            } else if (topLevel.startsWith(STARTS_WITH.ITALIC)) {
                return <i>{content}</i>;
            } else return <span key={`${occurrence?.input}-${occurrence?.index}`}>{content}</span>;
        });

    return messageArray;
};
const ParsedMessage = ({
    message,
}: Props) => {
    const content = useMemo<ReactElement[]>(() => bodyFromMessage(message), [message]);

    return <>{content}</>;
};

export default ParsedMessage;
