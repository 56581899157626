import { makeStyles } from '@mui/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

export const useStyles = makeStyles((theme:SkycellThemeInterface) => ({
    page: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        padding: '15px',
    },
    textArea: {
        width: '100%',
        minHeight: '200px',
    },
    textAreaWrapper: {
        position: 'relative',
    },
    textAreaHighlight: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        background: 'transparent',
        pointerEvents: 'none',
        padding: '19px 14px',
        whiteSpace: 'pre',
    },
    shortcuts: {
        gap: '10px',
        display: 'flex',
        padding: '8px',
        fontSize: '15px',
    },
    badge: {
        padding: '6px 10px',
        color: theme.palette.primary[30],
        background: theme.palette.primary[500],
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '10px',
        transition: '200ms ease',
        cursor: 'pointer',
        userSelect: 'none',
        '&:hover': {
            background: theme.palette.primary[400],
        },
        '&:active': {
            background: theme.palette.primary[300],
        },
    },
    parsedMessage: {
        width: '100%',
        minHeight: '200px',
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '6px',
        padding: '15px',
    },
    contentEditPane: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
    },
    contentControl: {
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
    },
    key: {
        fontWeight: 'bold',
    },
    colon: {

    },
    value: {

    },
    buttonBox: {

    },
    button: {
        cursor: 'pointer',
    },
    actions: {
        display: 'flex',
        gap: '15px',
    },
    action: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
    examples: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    example: {
        background: theme.palette.primary[200],
        padding: '10px',
        borderRadius: '10px',
        boxShadow: '0px 1px 3px #00000033',
        cursor: 'pointer',
        color: theme.palette.common.white,
    },

}));
