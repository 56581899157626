import React from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowForward } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import { useStyles } from './StandardLink.style';

type Props = {
    /** Components label */
    label?: string,
    /** Link to route on component click */
    linkTo?: string,
    /** Disable test wrap? */
    noWrap?: boolean,
    /** Custom className which can be passed to component */
    className?: string,
    disabled?: boolean,
    /** Disable standard padding? (1px 4px) */
    disablePadding?: boolean,
    /** Add horizontal margin? (5px for both sides) */
    horizontalMargin?: boolean,
    /** Add vertical margin? (5px for top and bottom) */
    verticalMargin?: boolean,
    /** The labels font size:
     *      - medium: 14px/17px;
     *      - large: 16px/19px;
     *      - larger: 18px/21px;
     *      - header: 25px/28px;
     * */
    blueIcon?: boolean,
    size?: 'medium' | 'large' | 'larger' | 'header',
}

/** Provides a styled standard link */
const StandardLink = ({
    label,
    linkTo,
    blueIcon = false,
    size = 'large',
    noWrap = false,
    className,
    disabled = false,
    disablePadding = false,
    horizontalMargin = false,
    verticalMargin = false,
}: Props) => {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme<SkycellThemeInterface>();

    const getArrowIconContainer = (size: 'medium' | 'large' | 'larger' | 'header') => {
        switch (size) {
        case 'larger':
            return classes.arrowIconContainerLarger;
        case 'large':
            return classes.arrowIconContainerLarge;
        case 'medium':
            return classes.arrowIconContainerMedium;
        default:
            return classes.arrowIconContainerHeader;
        }
    };

    const handleClick = () => {
        if (!disabled) {
            history.push(linkTo);
        }
    };

    return (
        <div
            className={[
                classes[size],
                classes.link,
                noWrap ? classes.noWrap : '',
                disablePadding ? '' : classes.standardPadding,
                horizontalMargin ? classes.horizontalMargin : '',
                verticalMargin ? classes.verticalMargin : '',
                className || '',
            ].join(' ')}
            onClick={handleClick}
        >
            <div className={classes.linkContent}>
                <div className={getArrowIconContainer(size)}>
                    <ArrowForward
                        sx={{ background: blueIcon ? theme.palette.primary.deepBlue : '', borderRadius: '100%' }}
                        className={classes.arrowIcon}
                    />
                </div>
                <div style={{ color: theme.palette.common.black }}>{label}</div>
            </div>
        </div>
    );
};

export default StandardLink;
