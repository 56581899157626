import React from 'react';
import useGetServiceProvider from 'hooks/useGetServiceProvider';
import Capabilities from 'shared-components/CompanyInfoComponents/Capabilities';

function CapabilitiesContainer() {
    const provider = useGetServiceProvider();

    if (provider) {
        return (
            <Capabilities companyInfo={provider} />
        );
    }

    return null;
}

export default CapabilitiesContainer;
