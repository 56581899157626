import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import { RequestStatus } from 'dataTypes/common';
import Card from 'shared-components/Card';
import { PageWithFilter } from 'Layout';
import { useStyles } from 'LaneManagement/Lanes/Lanes.style';
import Table, { useTableSideFilter } from 'shared-components/Table';
import StatusInfo from 'shared-components/StatusInfo';
import TitleWithSwitch from 'shared-components/TitleWithSwitch';
import { fetchTableData, getIntermediateFilteredData, RequiredTableData } from './lib';
import getTableColumnsOpen from './tableColumnsOpen';
import getTableColumnsClosed from './tableColumnsClosed';

const ProductRelease = () => {
    const { t } = useCustomTranslation();
    const classes = useStyles();
    const { FlexibleRequest: getProductReleases } = useSecureBackendEndpoints('product-release/status').requests;

    const [showClosedProductReleases, setShowClosedProductReleases] = useState(false);
    const [requiredUpdateProductReleases, setRequiredUpdateProductReleases] = useState(true);
    const [statusOpenPR, setStatusOpenPR] = useState<RequestStatus>('INIT');
    const [statusClosedPR, setStatusClosedPR] = useState<RequestStatus>('INIT');
    const [openProductReleases, setOpenProductReleases] = useState<RequiredTableData[]>([]);
    const [filteredOpenProductReleases, setFilteredOpenProductReleases] = useState<RequiredTableData[]>([]);
    const [closedProductReleases, setClosedProductReleases] = useState<RequiredTableData[]>([]);
    const [filteredClosedProductReleases, setFilteredClosedProductReleases] = useState<RequiredTableData[]>([]);

    const [intermediateFilteredOpen, setIntermediateFilteredOpen] = useState<RequiredTableData[]>([]);
    const [intermediateFilteredClosed, setIntermediateFilteredClosed] = useState<RequiredTableData[]>([]);
    const [searchString, setSearchString] = useState('');

    const handleTitleWithSwitchChange = useCallback((event) => {
        setShowClosedProductReleases(event.target.checked);
    }, [setShowClosedProductReleases]);

    useEffect(() => {
        if (requiredUpdateProductReleases && statusOpenPR !== 'PENDING') {
            (async () => {
                try {
                    setStatusOpenPR('PENDING');
                    const response = (await getProductReleases('GET', 'open')).data;

                    setOpenProductReleases(fetchTableData(response));
                    setRequiredUpdateProductReleases(false);
                    setStatusOpenPR('SUCCESS');
                } catch (error) {
                    setOpenProductReleases([]);
                    setStatusOpenPR('FAILURE');
                }
            })();
        }
    }, [requiredUpdateProductReleases]);

    useEffect(() => {
        if (requiredUpdateProductReleases && statusClosedPR !== 'PENDING') {
            (async () => {
                try {
                    setStatusClosedPR('PENDING');
                    const response = (await getProductReleases('GET', 'closed')).data;

                    setClosedProductReleases(fetchTableData(response));
                    setRequiredUpdateProductReleases(false);
                    setStatusClosedPR('SUCCESS');
                } catch (error) {
                    setClosedProductReleases([]);
                    setStatusClosedPR('FAILURE');
                }
            })();
        }
    }, [requiredUpdateProductReleases]);

    useEffect(() => {
        setIntermediateFilteredOpen(getIntermediateFilteredData(openProductReleases, searchString));
        setIntermediateFilteredClosed(getIntermediateFilteredData(closedProductReleases, searchString));
    }, [
        closedProductReleases,
        openProductReleases,
        searchString,
    ]);

    const tableColumnsOpen = useMemo(() => (
        getTableColumnsOpen(setRequiredUpdateProductReleases, t)
    ), [setRequiredUpdateProductReleases]);

    const tableColumnsClosed = useMemo(() => (
        getTableColumnsClosed(setRequiredUpdateProductReleases, t)
    ), [setRequiredUpdateProductReleases]);

    const {
        component: FilterComponent,
    } = useTableSideFilter({
        columns: showClosedProductReleases ? tableColumnsClosed : tableColumnsOpen,
        data: showClosedProductReleases ? intermediateFilteredClosed : intermediateFilteredOpen,
        setFilteredData: showClosedProductReleases ? setFilteredClosedProductReleases : setFilteredOpenProductReleases,
        externalTextFilterString: searchString,
        setExternalTextFilterString: setSearchString,
        externalTextFilterPlaceholder: t('COMMON.SEARCH'),
    });

    return (
        <PageWithFilter>
            {
                FilterComponent
            }
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: '10px' }}>
                <TitleWithSwitch
                    isRightSwitchPosition={showClosedProductReleases}
                    leftSwitchPositionTitle={t('PRODUCT_RELEASE.OPEN_PRODUCT_RELEASES')}
                    onChange={handleTitleWithSwitchChange}
                    rightSwitchPositionTitle={t('PRODUCT_RELEASE.CLOSED_PRODUCT_RELEASES')}
                />
                {
                    showClosedProductReleases
                        ? (
                            statusClosedPR === 'SUCCESS'
                                ? (
                                    <Table
                                        noDataText={t('LANE_MANAGEMENT.LANES.NO_INACTIVE_LANES')}
                                        data={filteredClosedProductReleases}
                                        columns={tableColumnsClosed}
                                        tableMaxHeight="100%"
                                        maskForHighlight={searchString}
                                        classNames={{
                                            noDataContainer: classes.noDataContainer,
                                        }}
                                        title={t('PRODUCT_RELEASE.CLOSED_PRODUCT_RELEASES')}
                                        // rowLinkTemplate={rowLinkTemplate}
                                    />
                                )
                                : (
                                    <Card
                                        zeroVerticalPadding
                                        zeroSidePadding
                                        className={classes.cardContent}
                                        contentClass={classes.cardRoot}
                                    >
                                        <StatusInfo status={statusClosedPR} />
                                    </Card>
                                )
                        )
                        : (
                            statusOpenPR === 'SUCCESS'
                                ? (
                                    <Table
                                        data={filteredOpenProductReleases}
                                        columns={tableColumnsOpen}
                                        tableMaxHeight="100%"
                                        maskForHighlight={searchString}
                                        classNames={{
                                            noDataContainer: classes.noDataContainer,
                                        }}
                                        title={t('PRODUCT_RELEASE.OPEN_PRODUCT_RELEASES')}
                                        noDataText={t('LANE_MANAGEMENT.LANES.NO_INACTIVE_LANES')}
                                        // rowLinkTemplate={rowLinkTemplate}
                                    />
                                )
                                : (
                                    <Card
                                        zeroVerticalPadding
                                        zeroSidePadding
                                        className={classes.cardContent}
                                        contentClass={classes.cardRoot}
                                    >
                                        <StatusInfo status={statusOpenPR} />
                                    </Card>
                                )
                        )
                }
            </div>
        </PageWithFilter>
    );
};

export default ProductRelease;
