import React, { Dispatch, SetStateAction } from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import {
    FilterBlock,
    Title,
    SearchTextField,
} from 'shared-components/SideFilter';

type Props = {
    placeholder?: string,
    searchString: string,
    setSearchString: Dispatch<SetStateAction<string>>,
}

const ExternalTextFilter = ({
    placeholder = '',
    searchString,
    setSearchString,
} : Props) => {
    const { t } = useCustomTranslation();

    return (
        <FilterBlock>
            <Title title={t('TRACK_AND_TRACE.FILTERS')} />
            <SearchTextField
                placeholder={placeholder}
                searchString={searchString}
                setSearchString={setSearchString}
            />
        </FilterBlock>
    );
};

export default ExternalTextFilter;
