import React from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import icons from 'shared-components/icons';
import LastConnect from 'shared-components/LastConnect';
import TemperatureInTempRange from 'shared-components/TemperatureInTempRange';
import { useTheme } from '@mui/material/styles';
import UncontrolledTooltip from 'shared-components/ControlledTooltip/UncontrolledTooltip';
import { useStyles } from './LastMeasuredBlock.style';

type Props = {
    temperatureRange: number[],
    lastMeasuredTempInternal?: number,
    lastMeasuredTempInternalTimestamp?: number,
    lastMeasuredTempAmbient?: number,
    lastMeasuredTempAmbientTimestamp?: number,
}
const LastMeasuredBlock = ({
    temperatureRange,
    lastMeasuredTempInternal,
    lastMeasuredTempInternalTimestamp,
    lastMeasuredTempAmbient,
    lastMeasuredTempAmbientTimestamp,
}: Props) => {
    const { t } = useCustomTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const inRange = lastMeasuredTempInternal > temperatureRange[0] && lastMeasuredTempInternal < temperatureRange[1];

    return (
        <>
            <span style={{
                fontSize: '14px',
                letterSpacing: '0.01em',
            }}
            >
                {t('COMMON.LATEST_DATA')}
            </span>
            <UncontrolledTooltip
                description={t('EXPLANATION_TOOLTIPS.CURRENT_INTERNAL_TEMPERATURE')}
                placement="bottom"
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        className={classes.tempDownIcon}
                        src={icons.tempDown}
                        width="14px"
                        height="14px"
                        style={inRange ? {
                            filter:
                            'invert(27%) sepia(46%) saturate(6283%) hue-rotate(125deg) brightness(100%) contrast(82%)',
                        }
                            : { filter:
                            'invert(48%) sepia(16%) saturate(3216%) hue-rotate(320deg) brightness(84%) contrast(95%)',
                            }}
                        alt="icon"
                    />
                    {
                        lastMeasuredTempInternal && (
                            <TemperatureInTempRange
                                tempRange={temperatureRange}
                                temperature={lastMeasuredTempInternal}
                            />
                        )
                    }
                    <div style={{ marginRight: '4px' }}>{', '}</div>
                    <LastConnect
                        timestamp={lastMeasuredTempInternalTimestamp}
                        fontSize="small"
                    />
                </div>
            </UncontrolledTooltip>
            <UncontrolledTooltip
                description={t('EXPLANATION_TOOLTIPS.CURRENT_AMBIENT_TEMPERATURE')}
                placement="bottom"
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        className={classes.tempUpIcon}
                        src={icons.tempUp}
                        width="14px"
                        height="14px"
                        style={{ filter: theme.palette.common.black }}
                        alt="icon"
                    />
                    {
                        lastMeasuredTempAmbient && (
                            <TemperatureInTempRange
                                tempRange={temperatureRange}
                                temperature={lastMeasuredTempAmbient}
                                colorInRange={theme.palette.common.black}
                                colorOutOfRange={theme.palette.common.black}
                            />
                        )
                    }
                    <div style={{ marginRight: '4px' }}>{', '}</div>
                    <LastConnect
                        timestamp={lastMeasuredTempAmbientTimestamp}
                        fontSize="small"
                    />
                </div>
            </UncontrolledTooltip>

        </>
    );
};

export default LastMeasuredBlock;
