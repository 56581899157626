import { useMemo, CSSProperties } from 'react';
import { useTheme } from '@mui/material/styles';
import useScreenSize from 'hooks/useScreenSize';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import { TOOLTIP_PADDING } from 'Contexts/QuickHelpContext/QuickHelpElement/QuickHelpElement';

export const useCalculatedRectStyle = ({
    childRect,
    childOffsetPercent,
    offsetPx,
    customRectSize,
    padding = TOOLTIP_PADDING,
    shouldUpdate = new Date().getTime(),
}) => {
    const theme = useTheme<SkycellThemeInterface>();
    const {
        height,
        width,
    } = useScreenSize();

    return useMemo<CSSProperties>(() => {
        const {
            x: childX,
            y: childY,
            width: childWidth,
            height: childHeight,
        } = childRect;
        const [childXPercent = 0, childYPercent = 0] = childOffsetPercent || [];
        const [xPxOffset = 0, yPxOffset = 0] = offsetPx || [];

        let calculatedLeft: number = childX - padding;

        let calculatedTop: number = childY - padding;
        const width = (customRectSize ? customRectSize[0] : childWidth) + padding * 2;
        const height = (customRectSize ? customRectSize[1] : childHeight) + padding * 2;

        if (childXPercent) {
            calculatedLeft += childWidth * (childXPercent / 100) - customRectSize[0];
        }
        if (childYPercent) {
            calculatedTop += childHeight * (childYPercent / 100) - customRectSize[1];
        }
        if (xPxOffset) {
            calculatedLeft += xPxOffset;
        }
        if (yPxOffset) {
            calculatedTop += yPxOffset;
        }

        return {
            position: 'fixed',
            zIndex: 10001,
            left: calculatedLeft,
            top: calculatedTop,
            width,
            height,
            background: 'transparent',
            backgroundBlendMode: 'screen',
            borderRadius: '8px',
            border: '1px dashed',
            borderColor: theme.palette.common.black,
            cursor: 'pointer',
        };
    }, [childRect, height, width, shouldUpdate]);
};
