import { linearProgressClasses } from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const useStyles = makeStyles((theme: SkycellThemeInterface) => ({
    questionUnitContainer: {
        marginTop: '8px',
        display: 'grid',
        gridTemplateColumns: '4fr 2fr',
        gridGap: '6px',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
        '&:hover': {
            boxShadow: '0px 1px 3px #00000033',
        },
        '&:hover $questionDetailsContainer': {
            display: 'block',
        },
    },
    progressBarGridContainer: {
        display: 'grid',
        gridTemplateColumns: '4fr 2fr',
        gridGap: '6px',
        marginBottom: '25px',
    },
    progressBarContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    answerItemAndDetails: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    title: {
        paddingLeft: '16px',
        textAlign: 'left',
        font: 'normal normal normal 20px/24px Roboto',
        letterSpacing: '0.26px',
        // @ts-ignore
        color: theme.palette.common.titleBlackText,
        opacity: 1,
    },
    questionDetailsContainer: {
        display: 'none',
    },
    barroot: {
        backgroundColor: theme.palette.primary[400],
    },
    root: {
        backgroundColor: theme.palette.primary[400],
    },
    progressBar: {
        [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: theme.palette.primary[400],
        },
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.primary.darkBlue,
        },
    },
    questionText: {
        paddingLeft: '16px',
        alignSelf: 'center',
    },
    cardContent: {
        padding: '16px 0 24px',
    },
    percent: {
    // @ts-ignore
        color: `${theme.palette.common.titleBlackText}!important`,
    },
    completedProgressIcon: {
        marginRight: '12px',
    },
}));

export default useStyles;
