import React, { MouseEventHandler } from 'react';
import { OverlayView } from '@react-google-maps/api';
import PolygonMarker from 'shared-components/PolygonMarker';
import { ExtractedAssetData } from 'TrackAndTrace/Assets/lib';

type Props = {
    assetGroup: ExtractedAssetData[],
    showLoggerTypes?: boolean,
    showLastConnection?: boolean,
    onHover: MouseEventHandler<HTMLDivElement>,
    isSelected: boolean,
}
const AssetMarker = ({
    assetGroup = [],
    showLastConnection = false,
    onHover,
    isSelected = false,
}: Props) => {
    const [firstEntry] = assetGroup;
    const {
        lastMeasuredLatitude: lat,
        lastMeasuredLongitude: lng,
        lastMeasuredTimestamp,
    } = firstEntry || {};

    return (
        <OverlayView
            position={{ lat, lng }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
            <div onMouseEnter={onHover}>
                <PolygonMarker
                    count={assetGroup.length > 1 ? assetGroup.length : null}
                    polygonIconType="hexa"
                    lastConnected={
                        showLastConnection
                            ? lastMeasuredTimestamp
                            : null
                    }
                    theme="light"
                    iconDiameter={35}
                    polygonIconColor="blue"
                    filled={isSelected}
                    lastConnectedIsVarious={assetGroup.length > 1}
                />

            </div>
        </OverlayView>
    );
};

export default AssetMarker;
