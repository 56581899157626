import {
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';

interface ScreenSize {
    width: number,
    height: number
}

export enum ScreenType {
    XL = 'xl',
    LG = 'lg',
    MD = 'md',
    SM = 'sm',
    XS = 'xs',
    XXS = 'xxs'
}
type ScreenTypeProp = string | ScreenType
type CurrentSizeValue = ScreenTypeProp;

type ScreenSizeCheckFunctions = {
    up: (screenType: ScreenType) => boolean,
    down: (screenType: ScreenType) => boolean,
    range: (min: ScreenType, max: ScreenType) => boolean,
}

type SizeCheckObject = {
    checkScreenType: ScreenSizeCheckFunctions,
    currentSize: CurrentSizeValue
}
type ScreenSizeWithCheck = ScreenSize & SizeCheckObject

export const BREAKPOINTS = {
    [ScreenType.XL]: 2560,
    [ScreenType.LG]: 1920,
    [ScreenType.MD]: 1280,
    [ScreenType.SM]: 960,
    [ScreenType.XS]: 600,
    [ScreenType.XXS]: 400,
};
const useScreenSize = (): ScreenSizeWithCheck => {
    const [screenSizeState, setScreenSizeState] = useState<ScreenSize>({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const currentSize: CurrentSizeValue = useMemo(() => {
        const breakpoints = Object.keys(BREAKPOINTS);

        return breakpoints.find((bp, i) => {
            return BREAKPOINTS[breakpoints[i + 1]] < screenSizeState.width;
        }) || ScreenType.XXS;
    }, [screenSizeState]);

    const checkScreenType = useMemo((): ScreenSizeCheckFunctions => {
        const screenSizes = Object.keys(BREAKPOINTS).filter(bp => {
            return BREAKPOINTS[bp] > screenSizeState.width;
        });

        return {
            up: (screenType: ScreenType) => !screenSizes.includes(screenType),
            down: (screenType: ScreenType) => screenSizes.includes(screenType),
            range: (min: ScreenType, max: ScreenType) => !screenSizes.includes(min) && screenSizes.includes(max),
        };
    }, [screenSizeState]);

    useLayoutEffect(() => {
        const update = () => {
            setScreenSizeState({ width: window.innerWidth, height: window.innerHeight });
        };

        window.addEventListener('resize', update);
        update();
        return () => window.removeEventListener('resize', update);
    }, []);

    return {
        ...screenSizeState,
        checkScreenType,
        currentSize,
    };
};

export default useScreenSize;
