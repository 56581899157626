import React, { ReactNode } from 'react';
import useGetCurrentPageInfo from 'hooks/useGetCurrentPageInfo';

import CommonPage from './components/CommonPage';
import DashboardPage from './components/DashboardPage';

type Props = {
    children: ReactNode,
}

const Layout = ({ children = null } : Props) => {
    const currentPage = useGetCurrentPageInfo();

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                margin: '0',
            }}
        >
            {
                currentPage
                    ? <CommonPage currentPage={currentPage}>{children}</CommonPage>
                    : <DashboardPage>{children}</DashboardPage>
            }
        </div>
    );
};

export default Layout;
