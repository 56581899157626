import { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { parseParamAndQueries } from 'shared-components/common';

const allowedViews = ['map', 'table'];

const useGetViewTypeFromQuery = () => {
    const location = useLocation();
    const history = useHistory();
    const [view, setView] = useState('map');

    useEffect(() => {
        const { query = null } = parseParamAndQueries(location) || {};
        const { view = null } = query || {};

        if (allowedViews.includes(view)) {
            setView(view);
        } else {
            history.push({ search: '?view=map' });
        }
    }, [location.search]);

    return view;
};

export default useGetViewTypeFromQuery;
