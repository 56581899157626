import React, {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useCallback,
    useMemo, useState, useEffect,
} from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import {
    FilterPanelCheckboxOptionsGroup,
} from 'shared-components/dataTypes';
import SideFilter, {
    FilterBlock,
    AccordionOptionsGroup,
    CheckboxOption,
    SwitchOptionItem,
} from 'shared-components/SideFilter';
import useStyles from 'TrackAndTrace/commonComponents/SideFilterComponents.style';
import {
    ExtractedAssetData,
    getFieldCheckboxData,
} from 'TrackAndTrace/Assets/lib';
import { FacetDTO } from 'dataTypes/SecureBackend/apiResponse';
import { Autocomplete, TextField, createFilterOptions } from '@mui/material';
import SearchPreviewTable from './SearchPreviewTable';

const filter = createFilterOptions<string>();

const options = ['More than 7 days', 'More than 14 days', 'More than 30 days'];

type Props = {
    filterOptions: { [optionsGroupKey: string]: string[] },
    setFilterOptions: Dispatch<SetStateAction<{ [optionsGroupKey: string]: string[] }>>,
    setLyingTime: Dispatch<SetStateAction<string>>,
    setShowLastConnection: Dispatch<SetStateAction<boolean>>,
    setShowAirportsInfo: Dispatch<SetStateAction<boolean>>,
    changeHighlightedText: (newHighlightedText: string) => void,
    isTableView: boolean,
    facets: FacetDTO[],
    onLyingTimeChange: (timestamp: number | null) => void;
    countsLoading: boolean,
    lyingTime: string,
    showLastConnection: boolean,
    showAirports: boolean,
    query?: string,
    setQuery: Dispatch<SetStateAction<string>>,
}

const AssetFilterPanel = ({
    changeHighlightedText,
    filterOptions,
    setFilterOptions,
    setShowLastConnection,
    setShowAirportsInfo,
    onLyingTimeChange,
    isTableView,
    facets,
    countsLoading,
    showLastConnection,
    showAirports,
    setQuery,
    query,
}: Props) => {
    const classes = useStyles();
    const { t } = useCustomTranslation();
    const [loadedFacets, setLoadedFacets] = useState<FacetDTO[]>([]);
    const [assetsFromPreviewTable, setAssetsFromPreviewTable] = useState<ExtractedAssetData[]>([]);
    const [lyingTime, setLyingTime] = useState<string>('');
    const [lyingSinceTimestamp, setLyingSinceTimestamp] = useState<number | null>(null);

    useEffect(() => {
        if (!lyingTime || lyingTime === 'All') {
            if (lyingSinceTimestamp !== null) {
                setLyingSinceTimestamp(null);
                onLyingTimeChange(null);
            }
            return;
        }

        const daysMatch = lyingTime && lyingTime.match(/\d+/); // Ensure lyingTime is not null

        if (daysMatch) {
            // eslint-disable-next-line radix
            const days = parseInt(daysMatch[0]);

            if (!Number.isNaN(days)) {
                onLyingTimeChange(days);
            } else {
                setLyingTime(''); // or any action that signifies a reset
                onLyingTimeChange(null); // Reset if the format is incorrect
            }
        } else {
            onLyingTimeChange(null); // Reset if lyingTime is empty or format is incorrect
        }
    }, [lyingTime, onLyingTimeChange]);

    const handleLyingTimeChange = (event, newValue) => {
        if (typeof newValue === 'string') {
            setLyingTime(newValue);
            // Extract days and convert to timestamp or just pass days as needed
        } else {
            setLyingTime('');
            onLyingTimeChange(null); // Signal to parent that filter is reset
        }
    };

    useEffect(() => {
        if (facets?.length > 0) {
            setLoadedFacets(facets);
        }
    }, [facets]);
    const handleChangeShowLastConnection = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setShowLastConnection(event.target.checked);
    }, []);

    const handleChangeShowAirports = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setShowAirportsInfo(event.target.checked);
    }, []);

    const filterData: FilterPanelCheckboxOptionsGroup[] = useMemo<FilterPanelCheckboxOptionsGroup[]>(() => {
        return [
            {
                title: t('TRACK_AND_TRACE.ASSET_TYPE'),
                options: getFieldCheckboxData(loadedFacets, 'assetTypeCodes', t, 'ASSET_TYPE_LABEL'),
                optionsGroupKey: 'assetTypeCodes',
            },
            {
                title: t('LANE_MANAGEMENT.ADDRESS.AIRPORT'),
                options: getFieldCheckboxData(loadedFacets, 'iataCodes'),
                optionsGroupKey: 'iataCodes',
            },
            {
                title: t('TRACK_AND_TRACE.AREA'),
                options: getFieldCheckboxData(loadedFacets, 'areas'),
                optionsGroupKey: 'areas',
            },
            {
                title: t('TRACK_AND_TRACE.AIRLINE'),
                options: getFieldCheckboxData(loadedFacets, 'airlineCodes'),
                optionsGroupKey: 'airlineCodes',
            },
        ];
    }, [loadedFacets]);

    return (
        <SideFilter>
            <FilterBlock>
                <div className={classes.title}>{t('TRACK_AND_TRACE.FILTERS')}</div>
                <SearchPreviewTable
                    changeHighlightedText={changeHighlightedText}
                    setAssetsFromPreviewTable={setAssetsFromPreviewTable}
                    assetsFromPreviewTable={assetsFromPreviewTable}
                    setQuery={setQuery}
                    isTableView={isTableView}
                    query={query}
                />
            </FilterBlock>
            {
                filterData.map((checkboxGroup) => {
                    const { optionsGroupKey } = checkboxGroup;
                    const handleSelectDeselect = () => {
                        setFilterOptions(prev => {
                            const { [optionsGroupKey]: selectedOptions } = prev;

                            return selectedOptions?.length === 0
                                ? { ...prev,
                                    [optionsGroupKey]: facets.find(it => it.filterName === optionsGroupKey)
                                        .counts.filter(it => it.filterValue !== null)
                                        .map(it => it.filterValue) }
                                : { ...prev, [optionsGroupKey]: [] };
                        });
                    };

                    return (
                        <FilterBlock key={optionsGroupKey}>
                            <AccordionOptionsGroup
                                key={checkboxGroup.title}
                                title={checkboxGroup.title}
                                tooltipInfo={checkboxGroup.tooltipInfo}
                                onSelectDeselect={handleSelectDeselect}
                            >
                                {
                                    checkboxGroup.options
                                        .sort((a, b) => b.count - a.count || a.label.localeCompare(b.label))
                                        .map((option) => (
                                            <div
                                                key={`${checkboxGroup.title}_${option.label}`}
                                                style={{ marginBottom: '12px' }}
                                            >
                                                <CheckboxOption
                                                    count={option.count}
                                                    checked={filterOptions[optionsGroupKey].includes(option.value)}
                                                    key={option.value}
                                                    label={option.label}
                                                    optionKey={option.value}
                                                    optionsGroupKey={optionsGroupKey}
                                                    setCheckboxGroupOptions={setFilterOptions}
                                                    tooltipDescription={option.description}
                                                    countsLoading={countsLoading}
                                                />
                                            </div>
                                        ))
                                }
                            </AccordionOptionsGroup>
                        </FilterBlock>
                    );
                })
            }
            <FilterBlock>
                <AccordionOptionsGroup
                    title={t('TRACK_AND_TRACE.LYING_TIME')}
                    tooltipInfo={{
                        text: t('QUICK_HELP.TRACK_AND_TRACE.LYING_TIME'),
                        order: 4,
                        position: 'right',
                        uid: 'accordionOptionsAssetsTooltip',
                    }}
                >
                    <Autocomplete
                        size="small"
                        value={lyingTime}
                        onChange={handleLyingTimeChange}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            const isExisting = options.some(option => inputValue === option);

                            if (inputValue !== '' && !isExisting) {
                                filtered.push(`More than ${inputValue} days`);
                            } else if (inputValue === '\\d') {
                                // previous state of the request with the lyingTimestamp
                                filtered.push(`More than ${inputValue} days`);

                                setLyingSinceTimestamp(null);
                                onLyingTimeChange(null);
                            }

                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        freeSolo
                        options={options} // your options array
                        getOptionLabel={(option) => {
                            if (typeof option === 'string') {
                                return option;
                            }
                            return option;
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Enter or choose"
                                placeholder="Enter number of days"
                                variant="standard"
                            />
                        )}
                    />
                </AccordionOptionsGroup>
            </FilterBlock>
            { !isTableView
            && (
                <FilterBlock>
                    <AccordionOptionsGroup
                        title={t('TRACK_AND_TRACE.DISPLAY_OPTIONS')}
                        tooltipInfo={{
                            text: t('QUICK_HELP.TRACK_AND_TRACE.DISPLAY_PACKAGING_TYPES'),
                            order: 4,
                            position: 'right',
                            uid: 'accordionOptionsAssetsTooltip',
                        }}
                    >
                        <SwitchOptionItem
                            label={t('TRACK_AND_TRACE.LAST_UPDATED')}
                            onChange={handleChangeShowLastConnection}
                            defaultChecked={showLastConnection}
                        />
                        <SwitchOptionItem
                            label={t('TRACK_AND_TRACE.AIRPORTS')}
                            onChange={handleChangeShowAirports}
                            defaultChecked={showAirports}
                        />
                    </AccordionOptionsGroup>
                </FilterBlock>
            )}
        </SideFilter>
    );
};

export default AssetFilterPanel;
