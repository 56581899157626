import React from 'react';
import { Redirect } from 'react-router-dom';
// import { RouteItem } from 'dataTypes/common';
import { RouteItem } from 'dataTypes/common';
import { userRoles } from 'hooks/useHasAccess';

import Assets from './Assets';
import Loggers from './Loggers';
// import Packagings from './Packagings';
import AddShipment from './Shipments/AddShipment';
import GenericShipments from './GenericShipments/GenericShipments';
import GenericShipmentDetails from './GenericShipmentDetails/GenericShipmentDetailsContainer';

const getTrackAndTraceRouter = (hasAccess: (...accessRoles: string[]) => boolean): RouteItem[] => {
    const redirectPath = (hasAccess(userRoles.TEMPERATURE_CHECK) || hasAccess(userRoles.SHIPMENT_MANAGEMENT))
        ? '/track-and-trace/generic-shipments'
        : hasAccess(userRoles.LOGGER_MANAGEMENT)
            ? '/track-and-trace/loggers'
            : '/track-and-trace/assets';

    return [
        {
            exact: true,
            path: '/track-and-trace',
            component: <div><Redirect push to={redirectPath} /></div>,
        },
        {
            sufficientRoles: [userRoles.TEMPERATURE_CHECK, userRoles.SHIPMENT_MANAGEMENT],
            exact: true,
            path: '/track-and-trace/generic-shipments',
            component: <GenericShipments />,
        },
        {
            sufficientRoles: [userRoles.TEMPERATURE_CHECK, userRoles.SHIPMENT_MANAGEMENT],
            exact: true,
            path: '/track-and-trace/shipments/new',
            component: <AddShipment />,
        },
        {
            sufficientRoles: [userRoles.TEMPERATURE_CHECK, userRoles.SHIPMENT_MANAGEMENT],
            exact: true,
            path: '/track-and-trace/generic-shipments/:id',
            component: <GenericShipmentDetails />,
        },
        /* {
            necessaryRoles: [userRoles.SHIPMENT_MANAGEMENT],
            exact: false,
            path: '/track-and-trace/packagings',
            component: <Packagings />,
        }, */
        {
            sufficientRoles: [
                userRoles.LOGGER_MANAGEMENT, userRoles.ASSET_MANAGEMENT,
            ],
            exact: false,
            path: '/track-and-trace/loggers',
            component: <Loggers />,
        },
        {
            sufficientRoles: [
                userRoles.LOGGER_MANAGEMENT, userRoles.ASSET_MANAGEMENT,
            ],
            exact: false,
            path: '/asset-management/loggers',
            component: <Loggers core="asset-management" />,
        },
        {
            necessaryRoles: [userRoles.ASSET_MANAGEMENT],
            exact: false,
            path: '/asset-management/assets',
            component: <Assets />,
        },
        {
            necessaryRoles: [userRoles.ASSET_MANAGEMENT],
            exact: false,
            path: '/asset-management',
            component: <div><Redirect push to="/asset-management/assets" /></div>,
        },
    ];
};

export default getTrackAndTraceRouter;
