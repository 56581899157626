import { makeStyles } from '@mui/styles';

// eslint-disable-next-line no-unused-vars
export const useStyles = makeStyles((theme) => ({
    tooltipContents: {
        background: theme.palette.secondary[50],
        padding: '8px',
        display: 'grid',
        gridTemplateColumns: '1fr 65px',
        gridGap: '20px',

    },
    topText: {
        font: 'normal normal normal 16px/19px Roboto',
        color: theme.palette.secondary[600],
        whiteSpace: 'nowrap',
    },
    bottomText: {
        font: 'normal normal normal 12px/14px Roboto',
        color: theme.palette.secondary[600],
        whiteSpace: 'nowrap',
    },
    left: {
        display: 'flex',
        flexDirection: 'column',
    },
    right: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        background: `${theme.palette.primary[200]} 0% 0% no-repeat padding-box`,
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        opacity: 1,
        textAlign: 'center',
        font: 'normal normal 500 14px/17px Roboto',
        letterSpacing: '1.25px',
        color: theme.palette.common.white,
        textTransform: 'uppercase',
        width: 'auto',
        height: '30px',
        '&:hover': {
            backgroundColor: theme.palette.primary[600],
        },
    },
    proposedToolTip: {
        background: theme.palette.primary[50],
        padding: '5px 0 5px 0',
        maxWidth: '600px',
    },
    proposedToolTipArrow: {
        color: theme.palette.primary[50],
    },
    tooltipPopper: {
        pointerEvents: 'auto',
    },
}));
