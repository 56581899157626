import React, {
    useEffect,
    useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import useCustomTranslation from 'hooks/useCustomTranslation';
import MultipleChoiceType from './MultipleChoiceType';
import NumberInput from './NumberInput';
import RadioButtonType from './RadioButtonType';

const useStyles = makeStyles({
    answerUnitContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
});

type Answers = {
    question: string,
    selectedAnswerOptions: string[],
    amount?: number,
}[]

type Props = {
    answers?: Answers,
    setAnswers?: (answers: Answers) => void,
    amount?: number,
    availableAnswerOptions?: string[],
    disabled?: boolean,
    selectedAnswerOptions: string[],
    question: string,
    questionType: string,
}

const AnswerUnit = ({
    answers,
    setAnswers,
    amount = 0,
    availableAnswerOptions,
    disabled = false,
    selectedAnswerOptions,
    question,
    questionType,
} : Props) => {
    const classes = useStyles();
    const [amountUpdate, setAmountUpdate] = useState(null);
    const [answerUpdate, setAnswerUpdate] = useState([]);
    const { t } = useCustomTranslation();

    useEffect(() => {
        if (answers) {
            if (answerUpdate.length) {
                let needUpdate = false;

                if (questionType === 'MULTIPLE_CHOICE') {
                    if (answerUpdate.length > selectedAnswerOptions.length) {
                        needUpdate = answerUpdate.filter((item) => !selectedAnswerOptions.includes(item)).length > 0;
                    } else {
                        needUpdate = selectedAnswerOptions.filter((item) => !answerUpdate.includes(item)).length > 0;
                    }
                } else {
                    needUpdate = answerUpdate[0] !== selectedAnswerOptions[0];
                }

                if (needUpdate) {
                    setAnswers([
                        ...answers.filter((answer) => answer.question !== question),
                        {
                            question,
                            selectedAnswerOptions: answerUpdate,
                            amount: amountUpdate,
                        },
                    ]);
                } else {
                    const consistInAnswers = answers.some((answer) => answer.question === question);

                    if (consistInAnswers) {
                        setAnswers(answers.filter((answer) => answer.question !== question));
                    }
                }
            }
        }
    }, [
        amountUpdate,
        answerUpdate[0],
        answerUpdate.length,
    ]);

    if (questionType === 'MULTIPLE_CHOICE') {
        return (
            <MultipleChoiceType
                availableAnswerOptions={availableAnswerOptions}
                defaultValues={selectedAnswerOptions}
                disabled={disabled}
                setAnswerUpdate={setAnswerUpdate}
            />
        );
    }

    if (questionType === 'CONDITIONAL_NUMERIC') {
        const availableAnswers = [t('COMMON.YES'), t('COMMON.NO')];

        return (
            <div className={classes.answerUnitContainer}>
                <RadioButtonType
                    availableAnswerOptions={availableAnswers}
                    defaultValue={selectedAnswerOptions[0]}
                    disabled={disabled}
                    name={`radio-buttons-group-${question}`}
                    row
                    setAnswerUpdate={setAnswerUpdate}
                />
                <NumberInput
                    amount={amount}
                    disabled={disabled}
                    setAmountUpdate={setAmountUpdate}
                />
            </div>
        );
    }

    return (
        <RadioButtonType
            availableAnswerOptions={availableAnswerOptions}
            defaultValue={selectedAnswerOptions[0]}
            disabled={disabled}
            name={`radio-buttons-group-${question}`}
            row={questionType !== 'SINGLE_CHOICE'}
            setAnswerUpdate={setAnswerUpdate}
        />
    );
};

export default AnswerUnit;
