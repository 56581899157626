import { AirportExtended } from 'dataTypes/SecureBackend/apiResponse';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import useCachedQueryRequest from '../useCachedQueryRequest';

const useGetAirports = (postProcess = it => it): AirportExtended[] => {
    const { GetAll: getAirports } = useSecureBackendEndpoints('airports').requests;

    const {
        data: airportList = [],
    } = useCachedQueryRequest({
        key: 'airports',
        request: getAirports,
        onCompleted: it => postProcess(it?.data || []),
    });

    return airportList;
};

export default useGetAirports;
