import moment from 'moment';
import { Logger } from 'dataTypes/SecureBackend/apiResponse';
import icons from 'shared-components/icons';
import { CheckboxOption } from '../../shared-components/dataTypes';

export interface ExtractedLoggerData {
    lastMeasuredLongitude: number,
    lastMeasuredLatitude: number,
    lastMeasuredTemp: number,
    lastMeasuredTempStr: string,
    loggerFamily: string,
    loggerFamilyLabel: string,
    loggerNumber: string,
    lastMeasuredInfo?: any,
    lastMeasuredTimestamp?: number,
}

export interface LoggerFilter { [optionsGroupKey: string]: string[] }

export const extractLoggerData = (rawData: Logger[], loggerFamilyLabels = {}): ExtractedLoggerData[] => {
    return rawData
        .map((item) => {
            const {
                lastMeasuredInfo = null,
                loggerNumber = '',
                loggerTypeCode = null,
            } = item;
            const loggerFamily = loggerTypeCode || '';

            const {
                temperatureGeolocationTimestamp,
                geolocation,
                temperature = null,
            } = lastMeasuredInfo || {};

            const {
                longitude = null,
                latitude = null,
            } = geolocation || {};

            const lastMeasuredTempStr = temperature
                ? `${temperature.toFixed(1)}°C` : 'n/a';

            return {
                lastMeasuredLongitude: longitude ? (Math.trunc(longitude * 10000) / 10000) : null,
                lastMeasuredLatitude: latitude ? (Math.trunc(latitude * 10000) / 10000) : null,
                lastMeasuredTemp: temperature,
                lastMeasuredTempStr,
                lastMeasuredTimestamp: temperatureGeolocationTimestamp
                    ? moment(temperatureGeolocationTimestamp).utc(true).valueOf()
                    : null,
                loggerFamily,
                loggerFamilyLabel: loggerFamilyLabels[loggerFamily] || loggerFamily,
                loggerNumber,
            };
        });
};

export interface ActiveLogger {
    loggerNumber: string,
    timestamp: number,
}

export const markerIconObj = {
    url: icons.hex_with_cross,
    scaledSize: {
        width: 20,
        height: 20,
    },
    origin: {
        x: 0,
        y: 0,
    },
    anchor: {
        x: 10,
        y: 10,
    },
};

export const getMapDataItem = (logger: ExtractedLoggerData) => {
    return {
        type: 'Feature',
        properties: {
            marker: true,
            data: logger,
        },
        geometry: {
            type: 'Point',
            coordinates: [
                logger.lastMeasuredLongitude,
                logger.lastMeasuredLatitude,
            ],
        },
    };
};

export const getSuperclusterOptions = (mapData, bounds, zoom) => {
    return {
        points: mapData,
        bounds,
        zoom: zoom || 10,
        options: {
            radius: 75,
            maxZoom: 5,
            generateId: true,
            map: (item) => {
                return {
                    loggerFamilyLabel: item.data.loggerFamilyLabel,
                    lastMeasuredTimestamp: item.data.lastMeasuredTimestamp,
                };
            },
            reduce: (data, props) => {
                const { loggerTypes = [], lastMeasuredTimes = [] } = data;

                if (!loggerTypes.includes(props.loggerFamilyLabel)) {
                    data.loggerTypes = [...loggerTypes, props.loggerFamilyLabel];
                }

                if (!lastMeasuredTimes.includes(props.lastMeasuredTimestamp)) {
                    data.lastMeasuredTimes = [...lastMeasuredTimes, props.lastMeasuredTimestamp];
                }
                return data;
            },
        },
    };
};

export const getExistedLoggerFamilies = (loggers: ExtractedLoggerData[]): string[] => {
    return loggers.reduce((data, { loggerFamilyLabel }) => {
        return data.includes(loggerFamilyLabel)
            ? data
            : [...data, loggerFamilyLabel];
    }, []);
};

export const getLoggersFamilyCheckboxOption = (loggers: ExtractedLoggerData[]): CheckboxOption[] => {
    const existedLoggerFamilies = getExistedLoggerFamilies(loggers);

    return existedLoggerFamilies.map((currentLoggerFamily) => {
        const count = loggers
            .filter(({ loggerFamilyLabel }) => loggerFamilyLabel === currentLoggerFamily).length;

        return {
            count,
            label: currentLoggerFamily,
            value: currentLoggerFamily,
        };
    });
};
