import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useState,
} from 'react';
import { RequestStatus } from 'dataTypes/common';
import StatusInfo from 'shared-components/StatusInfo';
import ShipmentsMap from 'TrackAndTrace/GenericShipments/components/ShipmentsMap/ShipmentsMap';
import { ORDERS_IN_PAGE } from 'shared-components/constants';
import useStyles from './MapView.style';
import {
    GenericShipmentData,
} from '../lib';

type Props = {
    currentPage: number,
    error: string,
    setCurrentPage: Dispatch<SetStateAction<number>>,
    shipments: GenericShipmentData[],
    showAirportsInfo: boolean,
    status: RequestStatus,
    updateMap: Dispatch<SetStateAction<number>>,
};

const MapView = ({
    currentPage,
    error,
    setCurrentPage,
    shipments = [],
    showAirportsInfo,
    status,
    updateMap,
}: Props) => {
    const classes = useStyles();
    const [paginatedShipments, setPaginatedShipments] = useState<GenericShipmentData[]>([]);
    const [shipmentsCount, setShipmentsCount] = useState(0);

    useEffect(() => {
        const sortedShipments = [...shipments].sort((a, b) => {
            return new Date(b.shipmentStart).getTime() - new Date(a.shipmentStart).getTime();
        });

        setShipmentsCount(sortedShipments.length);
        const start = (currentPage - 1) * ORDERS_IN_PAGE;
        const end = currentPage * ORDERS_IN_PAGE;

        setPaginatedShipments(sortedShipments.slice(start, end));
    }, [currentPage, shipments]);

    return (
        <div style={{ display: 'flex' }}>
            <div className={classes.mapViewContainer}>
                {
                    status === 'PENDING'
                        ? (
                            <StatusInfo
                                error={error}
                                status={status}
                            />
                        ) : (
                            <ShipmentsMap
                                currentPage={currentPage}
                                shipments={paginatedShipments}
                                shipmentsCount={shipmentsCount}
                                setCurrentPage={setCurrentPage}
                                showAirportsInfo={showAirportsInfo}
                                updateMap={updateMap}
                            />
                        )
                }
            </div>
        </div>
    );
};

export default MapView;
