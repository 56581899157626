import React, { SetStateAction, Dispatch } from 'react';
import IconButton from '@mui/material/IconButton';
import { Lock } from '@mui/icons-material';
import icons from 'shared-components/icons';
import ControlledTooltip from 'shared-components/ControlledTooltip';
import { Classes } from 'jss';
import useCustomTranslation from 'hooks/useCustomTranslation';

type Props = {
    classes: Classes,
    open?: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>,
}
const LockTooltip = ({
    classes,
    open,
    setOpen,
}: Props) => {
    const { t } = useCustomTranslation();

    return (
        <ControlledTooltip
            description={(
                <div className={classes.tooltipText}>
                    <span>
                        <img src={icons.skycell_blue} alt="" />
                        {t('LANE_MANAGEMENT.DATA_MAINTAINED_BY_SKY_CELL')}
                    </span>
                    <span>
                        {t('LANE_MANAGEMENT.DATA_MAINTAINED_BY_SKY_CELL_TEXT')}
                    </span>
                </div>
            )}
            open={open}
        >
            <IconButton
                size="small"
                onMouseEnter={setOpen.bind(null, true)}
                onMouseLeave={setOpen.bind(null, false)}
            >
                <Lock className={classes.icon} />
            </IconButton>
        </ControlledTooltip>
    );
};

export default LockTooltip;
