import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    dummyHolder: {
        maxHeight: '0px',
        overflow: 'visible',
    },
    itemsList: {
        position: 'absolute',
        zIndex: 100,
        listStyle: 'none',
        paddingInlineStart: '0px',
        transition: '200ms ease',
        maxHeight: '200px',
        overflowY: 'auto',
        margin: '0px',
        boxShadow: `0px 1px 3px ${theme.palette.secondary[200]}`,
        background: theme.palette.common.white,
        '&::-webkit-scrollbar-track': {
            '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,0.3)',
            borderRadius: '10px',
            backgroundColor: theme.palette.common.white,
        },
        '&::-webkit-scrollbar': {
            width: '6px',
            backgroundColor: theme.palette.secondary[100],
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '6px',
            '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,1)',
            backgroundColor: 'rgba(0,0,0,0.2)',
        },
        opacity: 0,
        visibility: 'hidden',

    },
    visible: {
        opacity: 1,
        visibility: 'visible',
    },
    listLoading: {
        filter: 'blur(2px)',
        transition: '200ms ease',
        pointerEvents: 'none',
    },
    searchContainer: {
        width: '100%',
    },
    listItem: {
        transition: 'background 200ms ease',
        userSelect: 'none',
        color: theme.palette.common.black,
        cursor: 'pointer',
        padding: '6px',
        '&:first-child': {
            marginTop: '10px',
        },
        '&:hover': {
            background: theme.palette.secondary[100],
        },
    },
    appearingLi: {
        opacity: 0,
        animation: '$opacityIn 200ms forwards ease-in-out',
    },
    '@keyframes opacityIn': {
        from: {
            opacity: 0,
        },
        to: {
            opacity: 1,
        },
    },

    iconButton: {
        width: '20px',
        height: '20px',
    },
    icon: {
        fontSize: '14px',
    },
    smallFont: {
        fontSize: '12px',
        '& input': {
            fontSize: '12px',
        },
    },
    mediumFont: {
        fontSize: '14px',
        '& input': {
            fontSize: '14px',
        },
    },
}));
