import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    tempValue: {
        textAlign: 'center',
        font: 'normal normal normal 66px/80px Roboto',
        letterSpacing: '0.85px',
        color: theme.palette.secondary[900],
        opacity: 1,
    },
    lastConnectContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

export default useStyles;
