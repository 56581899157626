import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import useStyles from 'TrackAndTrace/commonComponents/SideFilterComponents.style';

type Props = {
    packagingsSerialNumberList: string[],
    shipmentNumber: string,
    onClick: () => void,
}

const ShipmentFromPreviewTable = ({
    packagingsSerialNumberList = [],
    shipmentNumber,
    onClick,
} : Props) => {
    const classes = useStyles();

    return (
        <Tooltip
            classes={{
                tooltipPlacementBottom: classes.tooltipPlacementTop,
            }}
            title={(
                <div>
                    <div>Packagings:</div>
                    {
                        packagingsSerialNumberList
                            .map(serialNumber => <div key={serialNumber}>{serialNumber}</div>)
                    }
                </div>
            )}
        >
            <div className={classes.searchPreviewShipment}>
                <div>{shipmentNumber}</div>
                <IconButton
                    onClick={onClick}
                    size="small"
                    style={{
                        marginLeft: '2px',
                        padding: '0',
                    }}
                >
                    <CancelIcon
                        fontSize="small"
                        style={{ width: '14px', height: '14px' }}
                    />
                </IconButton>
            </div>
        </Tooltip>
    );
};

export default ShipmentFromPreviewTable;
