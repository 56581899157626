import React, { useState, useEffect, FocusEventHandler } from 'react';
import { TextField } from '@mui/material';
import { CancelSharp } from '@mui/icons-material';
import { Classes } from 'jss';
import { Moment } from 'moment';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';

type Props = {
    classes: Classes,
    value: Moment,
    onChange: (arg) => void,
    placeholder?: string,
    invalid?: boolean,
    flushable?: boolean,
    mandatory?: boolean,
    minWidth?: string | number,
    onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>,
}
const DateTimePickerWithFlush = ({
    classes = {},
    value = null,
    onChange,
    placeholder,
    invalid = false,
    flushable = false,
    mandatory = false,
    minWidth = 'unset',
    onFocus = () => {},

}: Props) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handleClose = () => setOpen(false);

        document.addEventListener('click', handleClose);

        return () => document.removeEventListener('click', handleClose);
    }, []);
    return (
        <DesktopDateTimePicker
            value={value}
            onChange={() => {}}
            inputFormat="DD.MM.YYYY HH:mm"
            open={open}
            PopperProps={{
                onClick: (ev) => ev.stopPropagation(),
            }}
            onAccept={(date) => {
                onChange(date);
                setOpen(false);
            }}
            ignoreInvalidInputs={false}
            renderInput={(props) => (
            // @ts-ignore
                <TextField
                    placeholder={placeholder || ''}
                    onClick={(event) => {
                        event.stopPropagation();
                        setOpen(prev => !prev);
                    }}
                    classes={{
                        root: [classes.input,
                            invalid ? classes.invalidField : '',
                            mandatory ? classes.mandatory : '',
                        ].join(' '),
                    }}
                    style={{
                        minWidth,
                    }}
                    {...props}
                    title={value ? value.format('DD.MM.YYYY HH:mm') : ''}
                    variant="outlined"
                    onFocus={onFocus}
                    onKeyDown={ev => ev.preventDefault()}
                    InputProps={{
                        endAdornment:
                        flushable
                            ? (
                                <CancelSharp
                                    className={[classes.flushIcon, classes.flushIconShift].join(' ')}
                                    style={{
                                        opacity: (open && value) ? 1 : '',
                                        pointerEvents: (open && value) ? 'all' : 'none',
                                    }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        onChange(null);
                                    }}
                                />
                            ) : null,
                        classes: {
                            adornedEnd: classes.adornedEnd,
                        },
                    }}
                />
            )}
        />
    );
};

export default DateTimePickerWithFlush;
