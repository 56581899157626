import { Classes } from 'jss';
import React from 'react';
import icons from 'shared-components/icons';

type Props = {
    name?: string,
    classes: Classes
}
const SingleAddressView = ({
    name = null,
    classes,
}: Props) => {
    return (

        <div
            className={classes.infoTextShort}
        >
            <img
                className={classes.iconShort}
                src={icons.location_type.ADDRESS}
                alt="address"
            />
            <span>
                <span className={classes.line}>
                    {name}
                </span>
            </span>

        </div>

    );
};

export default SingleAddressView;
