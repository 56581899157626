import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    main: {
        background: theme.palette.secondary[50],
        height: '100%',
        maxHeight: '100%',
        overflowY: 'auto',
    },
    topContainer: {
        marginBottom: '36px',
    },
    body: {
        flexGrow: 1,
        padding: `${theme.spacing(8)} ${theme.spacing(8)}`,
        [theme.breakpoints.down('lg')]: {
            padding: `${theme.spacing(8)} ${theme.spacing(8)}`,
        },
        [theme.breakpoints.down('sm')]: {
            padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
        },
    },
}));

export default useStyles;
