import React, {
    useState,
    useEffect,
} from 'react';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import TextField from '@mui/material/TextField';
import CompanySelector from 'shared-components/CompanySelector';
import ControlledTooltip from 'shared-components/ControlledTooltip';
import { Company } from 'dataTypes/SecureBackend/apiResponse';
import icons from 'shared-components/icons';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useStyles from './ServiceProviderSiteCard.style';

type Props = {
    companyLogo?: string,
    siteName?: string,
    setFilter: (filter) => void,
    type?: 'new' | 'editable' | 'newMyCompany',
    setCheckInProgress?: any,
}

const EditMode = ({
    companyLogo = '',
    setFilter,
    siteName = '',
    type = 'editable',
    setCheckInProgress = () => {},
}: Props) => {
    const classes = useStyles();
    const { FlexibleRequest: isNameUnique } = useSecureBackendEndpoints('service-provider-sites').requests;
    const [isCorrectSiteName, setIsCorrectSiteName] = useState(true);
    const [serviceProviderCompany, ServiceProviderCompany] = useState<Company>(null);
    const [serviceProviderCompanyId, setServiceProviderCompanyId] = useState<number>(null);
    const [name, setName] = useState(siteName);
    const [timerId, setTimerId] = useState(null);

    const { t } = useCustomTranslation();
    const handleSiteNameChange = (event) => {
        setName(event.target.value);
    };

    useEffect(() => {
        if (serviceProviderCompany) {
            setServiceProviderCompanyId(serviceProviderCompany.id);
        }
    }, [serviceProviderCompany]);

    useEffect(() => {
        if (name) {
            (async () => {
                try {
                    clearTimeout(timerId);
                    setCheckInProgress(true);
                    const timerid = setTimeout(async () => {
                        const url = `/name-is-free?serviceProviderSiteName=${name}`;
                        const isCorrectName = (await isNameUnique('GET', url) || {})?.data?.value || false;

                        setIsCorrectSiteName(isCorrectName || name === siteName);
                        setFilter(previous => (
                            {
                                ...previous,
                                name: (isCorrectName || name === siteName)
                                    ? name
                                    : '',
                            }
                        ));
                        setCheckInProgress(false);
                    }, 1500);

                    setTimerId(timerid);
                } catch (error) {
                    global.console.log(error);
                }
            })();
        } else {
            setFilter(previous => (
                {
                    ...previous,
                    name,
                }
            ));
        }
    }, [name]);

    useEffect(() => {
        setFilter(previous => (
            {
                ...previous,
                serviceProviderCompanyId,
            }
        ));
    }, [serviceProviderCompanyId]);

    return (
        <>
            {
                type === 'new'
                    ? (
                        <div>
                            <div className={classes.fieldTitle}>{t('COMMON.COMPANY')}</div>
                            <CompanySelector
                                className={classes.mandatory}
                                company={serviceProviderCompany}
                                companyType="SERVICE_PROVIDER"
                                setCompany={ServiceProviderCompany}
                            />
                        </div>
                    )
                    : (
                        <img
                            alt="Company Logo"
                            src={companyLogo || icons.placeholder_company_logo}
                            style={{ height: '60px', marginTop: '20px' }}
                        />
                    )
            }
            <div className={classes.fieldTitle}>{t('COMMON.SITE_NAME')}</div>
            <ControlledTooltip
                borderColor="red"
                description={t('SERVICE_PROVIDERS.THIS_NAME_IS_ALREADY_TAKEN_PLEASE_SELECT')}
                open={!isCorrectSiteName}
                placement="top-end"
            >
                <TextField
                    className={
                        isCorrectSiteName
                            ? classes.mandatory
                            : classes.error
                    }
                    fullWidth
                    value={name}
                    onChange={handleSiteNameChange}
                    variant="standard"
                    size="small"
                    // label={type === 'new' && 'Site Name'}
                    inputProps={{ className: classes.textFieldText }}
                />
            </ControlledTooltip>
        </>
    );
};

export default EditMode;
