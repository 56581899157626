import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    footerContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 'auto',
        marginRight: '10px',
    },
    iconButton: {
        textAlign: 'left',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '-0.14px',
        // @ts-ignore
        color: `${theme.palette.common.menuTextWhite}!important`,
        textTransform: 'capitalize',
        opacity: 1,
        bottom: '20%',
    },
    iconWrapper: {
        display: 'flex',
        width: '20px',
        height: '20px',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '4px',

    },
    arrowIcon: {
        fontSize: '14px!important',

    },
    rotateIcon: {
        transform: 'rotate(180deg)',
    },
}));

export default useStyles;
