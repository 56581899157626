import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    wrapper: {},
    skyTooltip: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '12px',
        userSelect: 'none',
        cursor: 'pointer',
        width: 'auto',
    },
    hideLabel: {
        visibility: 'hidden',
    },
    tooltipBackground: {
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        top: '0px',
        left: '0px',
        background: 'black',
        zIndex: 40,
        opacity: 0,
        pointerEvents: 'none',
        cursor: 'pointer',
    },
    tooltipBackgroundOn: {
        opacity: 0.5,
        pointerEvents: 'all',
    },
    tooltipIcon: {
        backgroundColor: theme.palette.primary[200],
        color: 'white',
        borderRadius: '50%',
        width: '18px ',
        height: '18px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '7px',
    },
    centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        zIndex: 41,
        left: '0px',
        pointerEvents: 'none',
        top: '0px',
        '& > &tooltipBody': {
            left: 'unset!important',
        },
    },
    body: {
        opacity: 0,
        pointerEvents: 'none',
        background: 'white',
        width: 'auto',
        height: '0px',
        overflow: 'hidden',
        zIndex: 41,
        boxShadow: '0px 2px 4px #00000033',
        transition: 'opacity 300ms ease-in-out',
        maxWidth: '80vw',
    },
    tooltipBody: {
        cursor: 'pointer',
        position: 'fixed',
        '@media (max-width: 700px)': {
            left: '0px',
        },
    },
    modalBody: {
    // @ts-ignore
        backgroundColor: theme.palette.common.contrastWhite50,
    },
    tooltipTop: {
        background: theme.palette.primary[200],
        color: 'white!important',
        padding: '9px 10px',
        fontSize: '14px',
        textTransform: 'uppercase',
        fontFamily: 'Roboto',
        letterSpacing: '1.25px',
    },
    contents: {
        padding: '8px 24px',
        maxHeight: 'calc(90vh - 38px)',

        overflow: 'auto',
        '@media (max-width: 700px)': {
            padding: '4px',
        },
        '&::-webkit-scrollbar-track': {
            '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,0.3)',
            borderRadius: '10px',
            backgroundColor: theme.palette.primary[600],
        },
        '&::-webkit-scrollbar': {
            width: '6px',
            backgroundColor: theme.palette.primary[600],
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,1)',
            backgroundColor: theme.palette.primary[200],
        },

    },
    toggled: {
        pointerEvents: 'all',
        opacity: 1,
        height: 'auto',
    },
}));

export default useStyles;
