import React from 'react';
import { Link } from 'react-router-dom';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useCacheContext from 'hooks/useCacheContext';

import useStyles from './BackToLink.style';

type Props = {
    to?: string,
    title?: string,
    marginBottom?: string | number,
    marginTop?: string | number,
    marginRight?: string | number,
    marginLeft?: string | number,
    isGeneric?: boolean,
}

const BackToLink = ({
    to = null, // '/shipments',
    title,
    marginTop = 10,
    marginBottom = 10,
    marginLeft = 12,
    isGeneric = false,
    marginRight = 0,
} : Props) => {
    const classes = useStyles();
    const { t } = useCustomTranslation();
    const { getCacheValue } = useCacheContext();

    const pathTo = to
        || `/track-and-trace/${isGeneric ? 'generic-' : ''}shipments?view=${getCacheValue('VIEW_TYPE') || 'map'}`;

    return (
        <Link
            to={pathTo}
            className={classes.link}
            style={{
                marginBottom,
                marginTop,
                marginLeft,
                marginRight,
            }}
        >
            ←&nbsp;
            {title || t('COMMON.SHIPMENTS_LIST')}
        </Link>
    );
};

export default BackToLink;
