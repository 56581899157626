import React from 'react';
import TitleHeader from 'TitleHeader';
import useStyles from './TitleLine.style';

type Props = {
    title: string
}

const TitleLine = ({
    title = '',
} : Props) => {
    const classes = useStyles();

    return (
        <TitleHeader>
            <div className={classes.container}>
                <div>{title}</div>
            </div>
        </TitleHeader>
    );
};

export default TitleLine;
