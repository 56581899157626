import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    validationContainer: {
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: '#747474',
        opacity: 1,
        paddingRight: '0px',
        paddingLeft: '0px',
    },
    validationContainerText: {
        padding: '16px',
    },
    nonPaddedCard: {
        '& > div': {
            paddingLeft: '0px',
            paddingRight: '0px',
        },
    },
    customPad: {
        paddingLeft: '16px',
        paddingRight: '16px',
    },
    validationFlexContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    validationAnsys: {
        margin: '12px 0 10px',
    },
    validationCert: {
        marginBottom: '24px',
    },
    validationCertIcon: {
        marginRight: '10px',
    },
});

export default useStyles;
