import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    filteredTable: {
        margin: '0px 10px 0px 24px',
        [theme.breakpoints.down('sm')]: {
            margin: '0px 10px',
        },
    },
}));
