import useStyles from 'MyCompany/Sites/Sites.style';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AddCircle from '@mui/icons-material/AddCircle';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import { ServiceProviderSite } from 'dataTypes/SecureBackend/apiResponse';
import { fetchTableData } from 'ServiceProviders/lib';
import Card from 'shared-components/Card';
import Table from 'shared-components/Table';
import useCustomTranslation from 'hooks/useCustomTranslation';
import tableColumns from './tableColumns';

type Props = {
    className?: string,
    serviceProviderCompanyId: number | string,
}

const ServiceProviderSites = ({ serviceProviderCompanyId, className = '' }: Props) => {
    const { FlexibleRequest: getSites } = useSecureBackendEndpoints('service-provider-companies').requests;
    const [data, setData] = useState([]);
    const { t } = useCustomTranslation();

    const classes = useStyles();

    useEffect(() => {
        (async () => {
            try {
                const link = `/${serviceProviderCompanyId}/service-provider-sites`;
                const response: ServiceProviderSite[] = (await getSites('GET', link)).data;

                setData(fetchTableData(response));
            } catch (error) {
                setData([]);
                global.console.log(error);
            }
        })();
    }, []);

    return (
        <Card
            className={className}
            title={t('COMMON.SITES')}
            zeroSidePadding
        >
            <Table
                data={data}
                columns={tableColumns(t)}
                rowLinkTemplate="/my-company/sites/:id/overview"
                embed
                classNames={{
                    bodyCell: classes.cell,
                    headerCell: classes.hcell,
                }}
            />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    padding: '11px 13px 0',
                }}
            >
                <Link to="/my-company/sites/new">
                    <AddCircle fontSize="small" color="action" />
                </Link>
            </div>
        </Card>
    );
};

export default ServiceProviderSites;
