import { CSSProperties } from 'react';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

export const minRangeStyle: CSSProperties = {
    font: 'normal normal normal 12px/14px Roboto',
    letterSpacing: '0.37px',
    position: 'relative',
    zIndex: 2,
    width: '31px',
    textAlign: 'end',
    bottom: '0px',
    marginTop: 'auto',
    right: '-13px',
};
export type BoundValues = { width: number, left: number, right: number };
export const maxRangeStyle: CSSProperties = {
    font: 'normal normal normal 12px/14px Roboto',
    letterSpacing: '0.37px',
    position: 'relative',
    zIndex: 2,
    width: '31px',
    textAlign: 'end',
    bottom: '0px',
    marginTop: 'auto',
    left: '-19px',
};
const BORDER_SIZE = 6;
const LABEL_WIDTH = 40;

export const getLineStyle = (lineCount: number, lastElement = false): CSSProperties => {
    return {
        borderLeft: '1px dashed #B7B7B7',
        height: '100%',
        marginRight: lastElement
            ? '0px'
            : lineCount === 7 ? '24px' : '14px',
    };
};

export const getNumberWidth = (temperature: number) => {
    const negativeNumber = temperature < 0 ? 4 : 0;
    const absolute = Math.abs(temperature);

    if (absolute >= 0 && absolute < 10 && absolute % 1 === 0) {
        return 32 + negativeNumber;
    } else if (absolute >= 0 && absolute < 10) {
        return 43 + negativeNumber;
    } else if (absolute >= 10 && absolute % 1 === 0) {
        return 43 + negativeNumber;
    }
    return 50 + negativeNumber;
};

export const getTempMinStyle = (
    tempMin: number, tempMinRange: number, noData: boolean, boundValues: BoundValues,
): CSSProperties => {
    const backgroundColor = tempMin === null
        ? '#EFEFEF'
        : tempMin >= tempMinRange ? '#EAF9EF' : '#FFE5E5';
    const color = tempMin === null
        ? '#747474'
        : tempMin >= tempMinRange ? '#17921F' : '#D44848';
    const {
        left,
    } = boundValues;

    return {
        background: noData ? 'transparent' : `${backgroundColor} 0% 0% no-repeat padding-box`,
        borderRadius: '4px',
        color: noData ? '#747474' : color,
        padding: '0 4px',
        textAlign: 'center',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.37px',
        opacity: 1,
        position: 'absolute',
        boxSizing: 'content-box',
        top: '3px',
        width: LABEL_WIDTH,
        left: left - LABEL_WIDTH - BORDER_SIZE,
    };
};

export const getTempMaxStyle = (
    tempMax: number, tempMaxRange: number, noData: boolean, boundValues: BoundValues,
): CSSProperties => {
    const backgroundColor = tempMax === null
        ? '#EFEFEF'
        : tempMax <= tempMaxRange ? '#EAF9EF' : '#FFE5E5';
    const color = tempMax === null
        ? '#747474'
        : tempMax <= tempMaxRange ? '#17921F' : '#D44848';
    const {
        right,
    } = boundValues;

    return {
        background: noData ? 'transparent' : `${backgroundColor} 0% 0% no-repeat padding-box`,
        borderRadius: '4px',
        color: noData ? '#747474' : color,
        padding: '0 4px',
        textAlign: 'center',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.37px',
        opacity: 1,
        position: 'absolute',
        boxSizing: 'content-box',
        top: '3px',
        width: LABEL_WIDTH,
        left: right + BORDER_SIZE * 3.5,
    };
};

const getTempRulerStyleBackground = (minInRange: boolean, maxInRange: boolean) => {
    if (minInRange && maxInRange) {
        return '#E0F7FF 0% 0% no-repeat padding-box';
    } else if (!minInRange && !maxInRange) {
        return '#FFE5E5 0% 0% no-repeat padding-box';
    }

    return `transparent linear-gradient(${
        minInRange ? 270 : 90}deg, #FFE5E5 0%, #E0F7FF 100%) 0% 0% no-repeat padding-box`;
};

export const getTempRulerStyle = (
    boundValues: BoundValues,
    tempMin: number,
    tempMax: number,
    tempMinRange: number,
    tempMaxRange: number,
    noData: boolean,
    theme: SkycellThemeInterface,
): CSSProperties => {
    const minInRange = tempMin !== null && tempMin >= tempMinRange;
    const maxInRange = tempMax !== null && tempMax <= tempMaxRange;
    const borderLeftColor = tempMin === null
        ? theme.palette.secondary[500]
        : minInRange ? theme.palette.primary[400] : theme.palette.common.red;
    const borderRightColor = tempMax === null
        ? theme.palette.secondary[500]
        : maxInRange ? theme.palette.primary[400] : theme.palette.common.red;
    const background = tempMin === null && tempMax === null
        ? '#EFEFEF 0% 0% no-repeat padding-box'
        : getTempRulerStyleBackground(minInRange, maxInRange);
    const {
        width, left,
    } = boundValues;

    return {
        zIndex: 5,
        width,
        position: 'absolute',
        top: '0',
        left: left + BORDER_SIZE,
        boxSizing: 'content-box',
        height: '20px',
        borderLeft: `${BORDER_SIZE}px solid ${noData ? theme.palette.secondary[600] : borderLeftColor}`,
        borderRight: `${BORDER_SIZE}px solid ${noData ? theme.palette.secondary[600] : borderRightColor}`,
        borderRadius: '3px',
        background: noData ? theme.palette.secondary[200] : background,
    };
};
