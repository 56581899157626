import React, { ReactNode } from 'react';

type WrapperProps = {
    children: ReactNode,
    fullHeight?: boolean,
    className?: string,
}

export const Empty = ({ children }: WrapperProps) => {
    return <>{children}</>;
};

export const Div = ({ children, ...props }: WrapperProps) => {
    return <div {...props}>{children}</div>;
};
