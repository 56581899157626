import { makeStyles } from '@mui/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

export const useStyles = makeStyles((theme: SkycellThemeInterface) => ({
    cell: {
        padding: '5px',
        '& .MuiFormControl-root': {
            width: '100%',
        },
    },
    hcell: {
        padding: '0 0 0 5px!important',
        color: `${theme.palette.common.black}!important`,
        '&::after': {
            display: 'none',
        },
    },
    noLocations: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        color: theme.palette.secondary[600],
    },
    filledSelect: {
        background: theme.palette.primary[50],
    },
    icon: {
        color: theme.palette.secondary[600],
        '&:hover': {
            color: theme.palette.secondary[800],
        },
    },
    caret: {
        marginRight: '5px',
        color: theme.palette.primary[700],
        fontSize: '30px',
    },
    loadingSkeleton: {
        width: '100%',
        height: '35px',
        background: `linear-gradient(90deg, ${
            theme.palette.secondary[200]} 0%, ${
            theme.palette.secondary[400]} 25%, ${
            theme.palette.secondary[200]} 50%,${
            theme.palette.secondary[400]} 75%,${
            theme.palette.secondary[200]} 100% )`,
        borderRadius: '4px',
        animation: '$loading 10s ease infinite alternate',

    },
    '@keyframes loading': {
        '0%': {
            backgroundPositionX: '-1000px',
        },
        '100%': {
            backgroundPositionX: '-100px',
        },
    },

}));
