import React from 'react';

type Props = {
    className?: string,
}

const Line = ({ className = '' } : Props) => (
    <hr className={className} />
);

export default Line;
