import { makeStyles } from '@mui/styles';
import { sidebarWidth, sidebarClosedWidth } from 'theme/shared/variables';

const useStyles = makeStyles((theme) => {
    const horizontal = theme.spacing(1.5);

    return {
        standardBody: {
            width: '100%',
            height: '100%',
            margin: '0',
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
        },
        standardBodyContainer: {
            display: 'grid',
            gridTemplateRows: '36px 1fr',
            backgroundColor: theme.palette.secondary[50],
        },
        bodyContentBlock: {
            flexGrow: 1,
            // padding: '12px',
            marginLeft: `${sidebarClosedWidth}px`,
            overflowX: 'hidden',
            '&::-webkit-scrollbar-track': {
                '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,0.3)',
                borderRadius: '10px',
                backgroundColor: theme.palette.common.white,
            },
            '&::-webkit-scrollbar': {
                width: '10px',
                backgroundColor: theme.palette.secondary[100],
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: '10px',
                '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,1)',
                backgroundColor: 'rgba(0,0,0,0.2)',
            },
        },
        bodyContentBlockWithOpenedSideBar: {
            flexGrow: 1,
            color: theme.palette.common.black,
            // padding: '12px',
            marginLeft: `${sidebarWidth}px`,
            overflowX: 'hidden',
            // width: `calc(100vw - ${sidebarWidth}px)`,
            '&::-webkit-scrollbar-track': {
                '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,0.3)',
                borderRadius: '10px',
                backgroundColor: theme.palette.common.white,
            },
            '&::-webkit-scrollbar': {
                width: '10px',
                backgroundColor: theme.palette.secondary[100],
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: '10px',
                '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,1)',
                backgroundColor: 'rgba(0,0,0,0.2)',
            },
            position: 'relative',

        },
        appBarStyle: {
            top: '0',
            position: 'sticky',
            zIndex: 30,
            alignItems: 'center',
            display: 'flex',
            paddingRight: '0px 0px',
            marginLeft: `${sidebarClosedWidth}px`,
            background: '#00739A 0% 0% no-repeat padding-box',
            // boxShadow: '0px 2px 4px #00000033',
            opacity: 1,
            textTransform: 'capitalize',
        },
        appBarStyleOpenedSideBar: {
            top: '0',
            position: 'sticky',
            zIndex: 1000,
            marginLeft: `${sidebarWidth}px`,
            alignItems: 'center',
            display: 'flex',
            paddingRight: '0px',
            background: '#00739A 0% 0% no-repeat padding-box',
            // boxShadow: '0px 2px 4px #00000033',
            opacity: 1,
            textTransform: 'capitalize',
            whiteSpace: 'nowrap',
            overflowX: 'inherit',
            overflowY: 'inherit',
            '&::-webkit-scrollbar-track': {
                '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,0.3)',
                borderRadius: '10px',
                backgroundColor: theme.palette.common.white,
            },
            '&::-webkit-scrollbar': {
                width: '2px',
                height: '4px',
                backgroundColor: theme.palette.secondary[100],
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: '6px',
                '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,1)',
                backgroundColor: 'rgba(0,0,0,0.2)',
            },
        },
        appBarText: {
            height: '36px',
            paddingTop: '7px',
            paddingRight: '50px',
            paddingLeft: `${horizontal}`,
            textAlign: 'left',
            verticalAlign: 'middle',
            font: 'normal normal 400 16px/19px Roboto',
            background: '#00739A 0% 0% no-repeat padding-box',
            letterSpacing: '0.31px',
            // @ts-ignore
            color: theme.palette.common.menuTextWhite,
            opacity: 1,
        },
        headerLeftButtons: {
            paddingTop: '8px',
        },
        headerLeftButtonsOneTab: {
            paddingTop: '0px',
        },
        headerRightButtons: {
            display: 'flex',
            justifyContent: 'flex-end',
            flex: 1,
        },
        topRightButton: {
            '& img': {
                marginRight: '8px',
                height: '20px',
            },
            userSelect: 'none',
            color: theme.palette.common.white,
            display: 'flex',
            marginLeft: '10px',
            alignItems: 'center',
            transition: '200ms ease',
            '&:hover': {
                filter: 'brightness(0.96)',
                cursor: 'pointer',
            },
        },
        mobileAppBar: {
            padding: '4px 8px',
            height: '36px',
            alignItems: 'center',
            display: 'flex',
            background: `${theme.palette.primary[200]} 0% 0% no-repeat padding-box`,
            // boxShadow: '0px 2px 4px #00000033',
            opacity: 1,
            textTransform: 'capitalize',
        },
        mobileBurger: {
            right: '0',
            marginLeft: 'auto',
            padding: '0',
            zIndex: 30,
            color: theme.palette.common.white,
        },
        mobileSidebar: {
            marginTop: '50px',
        },
        mobileBody: {
            padding: `4px ${horizontal}px`,
        },
    };
});

export default useStyles;
