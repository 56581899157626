import icons from 'shared-components/icons';

export const getIconByStatus = (status: string): string => {
    switch (status) {
    case 'AVAILABLE':
        return icons.checked_blue;
    case 'PARTIALLY_AVAILABLE':
        return icons.checked_yellow;
    case 'NOT_AVAILABLE':
        return icons.warning_round;
    case 'NOT_ANSWERED':
        return icons.dots_round_grey;
    default:
        return '';
    }
};

export const getQuestionnaireStat = (questionnaire = []) => {
    const answered = questionnaire.filter((question) => {
        return question.amount > 0 || question.selectedAnswerOptions.length > 0;
    }).length;
    const total = questionnaire.length;

    return Math.round((answered / total) * 100);
};
