import React, {
    useMemo,
    useState,
} from 'react';
import IconButton from '@mui/material/IconButton';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { EnumFilterOption } from 'shared-components/Table/dataTypes';
import CheckboxRow from 'shared-components/CheckboxRow';
import useCustomTranslation from 'hooks/useCustomTranslation';
import StyledTextField from '../StyledTextField';
import useStyles from './EnumFilter.style';

type Props = {
    selectedOptions: string[],
    availableOptions: EnumFilterOption[],
    setSelectedOptions: (selectedOptions: string[]) => void,
}

const EnumFilter = ({
    selectedOptions = [],
    availableOptions,
    setSelectedOptions,
} : Props) => {
    const classes = useStyles();
    const [textFieldValue, setTextFieldValue] = useState('');
    const { t } = useCustomTranslation();
    const handleChange = (event) => setTextFieldValue(event.target.value);

    const checkboxData = useMemo((): EnumFilterOption[] => {
        if (!availableOptions) {
            return [];
        }
        if (!textFieldValue) {
            return availableOptions;
        }

        return availableOptions.filter(item => item.value.toLowerCase().includes(textFieldValue.toLowerCase()));
    }, [availableOptions, textFieldValue]);

    const handleOnClickClearFilter = () => {
        setTextFieldValue('');
        setSelectedOptions([]);
    };

    const noOptions = textFieldValue.length === 0 && checkboxData.length === 0;

    return (
        <div className={classes.container}>
            <div className={classes.list}>
                <StyledTextField
                    disabled={noOptions}
                    onChange={handleChange}
                    label={noOptions ? t('COMMON.NO_OPTIONS') : t('COMMON.SEARCH_OPTIONS')}
                    size="small"
                    value={textFieldValue}
                    autoFocus
                />
                <IconButton
                    disabled={noOptions}
                    style={{ marginLeft: '5px', padding: '0!important' }}
                    size="small"
                    onClick={handleOnClickClearFilter}
                >
                    <FilterAltOffIcon color="action" fontSize="small" />
                </IconButton>
            </div>
            {
                checkboxData.map((item, index: number) => {
                    return (
                        <CheckboxRow
                            key={`CheckboxRow-${index}`}
                            classes={classes}
                            count={item.count}
                            label={item.value}
                            setFilter={setSelectedOptions}
                            value={item.value}
                            initialValue={selectedOptions.includes(item.value)}
                        />
                    );
                })
            }
        </div>
    );
};

export default EnumFilter;
