import React, { useMemo } from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useGetTimeZoneInfoByGeolocation from 'hooks/useGetTimeZoneInfoByGeolocation';
import moment from 'moment';
import { TIME_IN_MS } from 'shared-components/constants';
import { useTheme, CircularProgress } from '@mui/material';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import icons from 'shared-components/icons';
import { GatewayInfoDTO } from 'dataTypes/SecureBackend/apiResponse';
import useStyles from './GatewayStep.style';

type Props = {
    dash?: boolean,
    gateway: GatewayInfoDTO,
    time: string,
    loading: boolean,
}

const GatewayStepLocation = ({
    dash = false,
    gateway,
    time,
    loading,
}: Props) => {
    const classes = useStyles();
    const { t } = useCustomTranslation();
    const theme = useTheme<SkycellThemeInterface>();

    const {
        gmtOffsetInMs: actualGmtOffsetInMs,
        loading: loadingTimezone,
    } = useGetTimeZoneInfoByGeolocation({
        latitude: gateway?.latitude,
        longitude: gateway?.longitude,
    }, time, true);

    const breakpointDate = useMemo(() => {
        return moment(time)
            .utc(true)
            .utcOffset(actualGmtOffsetInMs / TIME_IN_MS.minute)
            .format('DD.MM.YYYY HH:mm Z');
    }, [time, actualGmtOffsetInMs]);

    const dashColor = useMemo(() => theme.palette.primary.deepBlue, [theme]);

    return (
        <div className={[classes.orderStepLocation, loading ? classes.loadingWrapper : ''].join(' ')}>
            <div className={classes.timeline}>
                {
                    dash && (
                        <div className={classes.dashWrapper}>
                            <svg
                                width="10"
                                height="65"
                            >
                                <rect
                                    x="5"
                                    y="-4"
                                    width="10"
                                    height="100"
                                    stroke={dashColor}
                                    strokeWidth="1"
                                    strokeDasharray="5 3"
                                    fill="transparent"
                                />
                            </svg>
                        </div>
                    )
                }
                <div
                    className={classes.dataDot}
                    style={{
                        background: dashColor,
                    }}
                />

            </div>
            <div className={classes.locationData}>
                <div className={classes.locationDataContent}>
                    <div className={classes.dataPart}>
                        <span className={`${classes.dataText}`}>
                            {
                                `${gateway?.iataCode || ''} | ${gateway?.locationRemark || ''}`
                            }
                        </span>
                    </div>
                    <div className={classes.dataPart}>
                        <img
                            className={classes.dataIcon}
                            src={icons.clock}
                            height={25}
                            width={25}
                            style={{ filter: dashColor }}
                            alt="icon"
                        />
                        <div className={classes.dataTextClock}>
                            {
                                loadingTimezone && (
                                    <CircularProgress size={15} />
                                )
                            }
                            {
                                !loadingTimezone && (breakpointDate || t('ORDER_DETAILS.NO_DATE_TIME_YET'))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GatewayStepLocation;
