import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';

// import NotificationsCell from 'Administration/Users/components/NotificationsCell';
import RolesCell from 'Administration/Users/components/RolesCell';
import { TFunction } from 'react-i18next';

const tableColumns: (t:TFunction) => ColumnsType[] = (t = w => w) => [
    {
        Header: t('COMMON.USER'),
        accessor: 'userName',
        dataType: 'text',
    },
    {
        Header: t('COMMON.E_MAIL'),
        accessor: 'email',
        dataType: 'text',
    },
    {
        Header: t('COMMON.ROLES'),
        accessor: (row) => (<RolesCell role={row.role} />),
        dataType: 'text',
        sideFilterKey: 'role',
        sideFilterType: 'enumList',
        sideFilterBlockMaxHeight: '24vh',
    },
    // {
    //     Header: 'Open Tasks / Notifications',
    //     accessor: (row) => (
    //         <NotificationsCell
    //             notificationCount={row.notificationCount}
    //             openTaskCount={row.openTaskCount}
    //         />
    //     ),
    //     dataType: 'text',
    //     sideFilterKey: 'countryName',
    //     sideFilterType: 'enum',
    // },
];

export default tableColumns;
