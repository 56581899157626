import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    body: {
        display: 'grid',
        gridTemplateColumns: '260px 1fr',
        minWidth: '30vw',
    },
    bodyLeft: {
        display: 'flex',
        flexDirection: 'column',
    },
    addressCard: {
        boxShadow: 'none',
    },
    addressCardRoot: {
        padding: '0px',
        maxWidth: '600px',
    },
    radio: {
        '&$checked': {
            color: theme.palette.primary[200],
        },
        '& .MuiSvgIcon-root': {
            height: '18px',
            width: '18px',
        },
    },
    radioItem: {
        padding: '0 4px!important',
    },
    checked: {},
    autocomplete: {},
    textField: {
        '& > div': {
            paddingRight: '1px!important',
        },
    },
    buttonLabel: {
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    topTitle: {
        letterSpacing: 0.4,
        color: theme.palette.secondary[500],
        fontSize: '12px',
    },
    addressBody: {
        display: 'flex',
        flexDirection: 'column',
    },

}));
