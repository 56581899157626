import React, {
    createContext,
    useState,
    ReactChild,
} from 'react';
import { AlertColor } from '@mui/material/Alert';

const initialState = {
    code: null,
    color: null,
    status: null,
    message: '',
    time: null,
    relatedStatuses: [],
};

export type StatusState = {
    color: AlertColor,
    code?: number,
    message: string,
    time?: number,
    relatedStatuses?: number[],
}

export type SetStatusState = (statusState: StatusState) => void

export interface StatusInfoContextInterface {
    statusState: StatusState,
    setStatusState?: SetStatusState,
}

export const StatusInfoContext = createContext<StatusInfoContextInterface>({
    statusState: initialState,
    setStatusState: () => {},
});

type Props = {
    children: ReactChild,
}

export const StatusInfoProvider = ({ children }: Props) => {
    const [statusState, setStatusState] = useState<StatusState>(initialState);

    const setStatusStateWithDefaultParams = (statusStateObj: StatusState) => {
        const { time = Date.now() } = statusStateObj;

        setStatusState({ ...statusStateObj, time });
    };

    return (
        <StatusInfoContext.Provider value={{
            statusState,
            setStatusState: setStatusStateWithDefaultParams,
        }}
        >
            { children }
        </StatusInfoContext.Provider>
    );
};
