import React from 'react';
import { makeStyles } from '@mui/styles';
import TextArray from './TextArray';

const useStyles = makeStyles(theme => ({
    groupTitle: {
        marginTop: '8px',
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: theme.palette.secondary[500],
        opacity: 1,
    },
    textArrayContainer: {
        marginBottom: '15px',
    },
}));

type Props = {
    certificates?: {
        [key: string]: {
            mainCertificate: string,
            subCertificates?: string[],
        }[] | null,
    },
    certificatesFlat?: string[],
}

const Certificates = ({
    certificates = {},
    certificatesFlat = [],
} : Props) => {
    const classes = useStyles();

    return (
        <>
            {
                Object.keys(certificates).map((group) => {
                    const textItems = certificates[group].reduce((data, item) => {
                        if (item.subCertificates?.length) {
                            return [...data, ...item.subCertificates];
                        }

                        return [...data, item.mainCertificate];
                    }, []);

                    return (
                        <div key={group}>
                            <div className={classes.groupTitle}>{group}</div>
                            {
                                certificates[group] && (
                                    <div className={classes.textArrayContainer}>
                                        <TextArray
                                            key={group}
                                            textItems={textItems}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    );
                })
            }
            {
                certificatesFlat && (
                    <div className={classes.textArrayContainer}>
                        <TextArray
                            textItems={certificatesFlat}
                        />
                    </div>
                )
            }
        </>
    );
};

export default Certificates;
