import { Button, TextField } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import Card from 'shared-components/Card';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import useCustomTranslation from 'hooks/useCustomTranslation';
import moment from 'moment';
import useGetTimeZoneInfoByGeolocation from 'hooks/useGetTimeZoneInfoByGeolocation';
import { useStyles } from './ShipmentInformationCard.style';

type Props = {
    deliveryServiceType: string,
    onSubmit: (...args: any) => any,
    handoverPoint: any
}

const ShipmentInformationCard = ({
    deliveryServiceType,
    onSubmit,
    handoverPoint,
}: Props) => {
    const classes = useStyles();
    const [requestedPointDate, setRequestedPointDate] = useState(null);
    const [customerReference, setCustomerReference] = useState('');
    const [orderComment, setOrderComment] = useState('');
    const [currentDate, setCurrentDate] = useState(null);
    const { t } = useCustomTranslation();

    const timezoneCheckDate = useMemo<string>(() => {
        if (!currentDate) {
            return null;
        } else {
            const nowDate = moment().utcOffset(0, true);

            return moment(currentDate, 'DD.MM.YYYY HH:mm').set({
                hours: nowDate.hours(),
                minutes: nowDate.minutes(),
            }).format('YYYY-MM-DDTHH:mm');
        }
    }, [currentDate]);

    const {
        timeZoneStr = '+00:00',
        loading,
    } = useGetTimeZoneInfoByGeolocation(
        handoverPoint?.geolocation,
        timezoneCheckDate,
    );

    const handleRequestPointDateChange = useCallback((date) => {
        setRequestedPointDate(date);
    },
    []);

    const minTime = useMemo(() => {
        const initTime = moment().set({ hours: 0, minutes: 0 });

        if (!currentDate) return initTime;
        const now = moment().utc(false);
        const currentDay = now
            .clone()
            .startOf('day')
            .valueOf() === currentDate
            ?.clone()
            .utc(true)
            ?.startOf('day')
            .valueOf();

        return currentDay ? now : initTime;
    }, [currentDate]);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Card
                className={classes.card}
                title={t('TRACK_AND_TRACE.ADD_SHIPMENT.SHIPMENT_INFORMATION')}
            >
                <div className={classes.cardContents}>
                    <div className={classes.cardField}>
                        <div>
                            <div className={classes.fieldTitle}>
                                {
                                    ['DELIVERY_ROAD', 'DELIVERY_AIR'].includes(deliveryServiceType)
                                        ? t('TRACK_AND_TRACE.ADD_SHIPMENT.REQUESTED_DELIVERY')
                                        : t('TRACK_AND_TRACE.ADD_SHIPMENT.REQUESTED_PICK_UP')
                                }
                            </div>
                            <DateTimePicker
                                PopperProps={{
                                    className: [classes.datePicker,
                                        loading ? classes.loadingPicker : ''].join(' '),
                                }}
                                value={requestedPointDate}
                                minTime={minTime}
                                ampm={false}
                                disablePast
                                inputFormat="DD.MM.YYYY HH:mm"
                                onAccept={handleRequestPointDateChange}
                                onChange={((date) => {
                                    setCurrentDate(date);
                                })}
                                InputProps={{
                                    classes: { root: classes.mandatory },
                                }}
                                onClose={() => {
                                    setRequestedPointDate(currentDate);
                                }}
                                renderInput={(params: any) => {
                                    return (
                                        <TextField
                                            InputLabelProps={{
                                                error: false,
                                            }}
                                            classes={{
                                                root: [classes.inputField, classes.shift].join(' '),
                                            }}
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps,
                                                value: `${params.inputProps.value} \
    ${params.inputProps.value ? timeZoneStr : ''}`,
                                                disabled: true,
                                            }}
                                        />
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className={classes.cardField}>
                        <div>
                            <div className={classes.fieldTitle}>
                                {t('TRACK_AND_TRACE.ADD_SHIPMENT.REFERENCE_PO_NUMBER')}
                            </div>
                            <TextField
                                className={`${classes.inputField} ${classes.mandatory}`}
                                onChange={(event => {
                                    setCustomerReference(event.target.value);
                                })}
                                value={customerReference}
                            />
                        </div>
                    </div>
                    <div className={classes.cardField}>
                        <TextField
                            value={orderComment}
                            onChange={(event => {
                                setOrderComment(event.target.value);
                            })}
                            className={classes.inputField}
                            label={t('COMMON.SHIPMENT_COMMENT')}
                        />
                    </div>
                    <div className={`${classes.cardFieldFlexCenter} ${classes.cardField}`}>
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.button}
                            onClick={() => onSubmit(
                                {
                                    customerReference,
                                    orderComment,
                                    requestedPoint: requestedPointDate
                                        ?.clone()
                                        .utcOffset(timeZoneStr, true),
                                },
                            )}
                            style={{ textTransform: 'uppercase' }}
                        >
                            {t('MENU_ITEMS.ADD_SHIPMENT')}
                        </Button>
                    </div>
                </div>
            </Card>
        </LocalizationProvider>
    );
};

export default ShipmentInformationCard;
