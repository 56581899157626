import moment from 'moment';
import {
    Cargo, EnergyLevelCheckResult,
    Location,
    OrderStep,
    Shipment, TemperatureCheckResult,
} from 'dataTypes/SecureBackend/apiResponse/Shipment';
import {
    Airport,
    Geolocation,
} from 'dataTypes/SecureBackend/apiResponse';
import {
    LatLng,
    PolylineAndStepStatus,
    TimeRange,
} from 'dataTypes/common';
import { CheckboxOption } from 'shared-components/dataTypes';
import icons from 'shared-components/icons';
import {
    APPROVAL_STATUS,
    ORDER_STEP_TYPE, TEMPERATURE_STATUS,
} from 'shared-components/constants';
import { ProcessedPackagingData, fetchPackaging } from 'TrackAndTrace/Packagings/lib';

export const getApprovalStatuses = (cargo: Cargo[]): string[] => {
    return cargo.map(item => {
        const { skyCoreProductRelease } = item;
        const { approvalStatus = APPROVAL_STATUS.CHECK_NOT_PASSED } = skyCoreProductRelease || {};

        return approvalStatus;
    });
};
export const getEnergyLevels = (cargo: Cargo[]): EnergyLevelCheckResult[] => {
    return cargo.map(item => item.energyLevelCheckResult);
};
export const getTemperatureStatuses = (cargo: Cargo[]): string[] => {
    return cargo.map(item => {
        const { temperatureCheckResult } = item;
        const { temperatureStatus = TEMPERATURE_STATUS.EXCURSION } = temperatureCheckResult || {};

        return temperatureStatus;
    });
};

const getNumberOfStatus = (statuses: string[], requiredStatus: string): number => (
    statuses.filter((status) => status === requiredStatus).length
);

export const getPackagingIcons = (statuses: string[]): string[] => {
    if (statuses.length < 6) {
        return statuses.map((status) => {
            if (status === APPROVAL_STATUS.REJECTED
                || status === APPROVAL_STATUS.CHECK_NOT_PASSED) {
                return icons.rectangle_red;
            }
            if (status === APPROVAL_STATUS.NOT_CHECKED) {
                return icons.rectangle_grey;
            }
            return icons.rectangle_blue;
        });
    }
    const notApprovedCount = getNumberOfStatus(statuses, APPROVAL_STATUS.REJECTED);

    /** Show ceiling(4 x count(ProductRelease | REJECTED) / count(ProductRelease))
     dots with color #D44848 and the other dots with #61C6E9 */
    const redIconsCount = Math.ceil((4 * notApprovedCount) / statuses.length);

    return [
        redIconsCount > 0 ? icons.rectangle_red : icons.rectangle_blue,
        redIconsCount > 1 ? icons.rectangle_red : icons.rectangle_blue,
        icons.dots_blue,
        redIconsCount > 2 ? icons.rectangle_red : icons.rectangle_blue,
        redIconsCount > 3 ? icons.rectangle_red : icons.rectangle_blue,
    ];
};

export const checkIsWarning = (statuses: string[]): boolean => (
    statuses.includes(APPROVAL_STATUS.REJECTED)
    || statuses.includes(APPROVAL_STATUS.CHECK_NOT_PASSED)
);

export const checkIsPending = (statuses: string[]): boolean => {
    const approvedCount = getNumberOfStatus(statuses, APPROVAL_STATUS.APPROVED);

    const checkPassedCount = getNumberOfStatus(statuses, APPROVAL_STATUS.CHECK_PASSED);

    return (statuses.length !== 0
        && (checkPassedCount === statuses.length
            || ((checkPassedCount + approvedCount) === statuses.length && checkPassedCount > 0)
        )
    );
};

export const checkIsAllApproved = (statuses: string[]): boolean => {
    const approvedCount = getNumberOfStatus(statuses, APPROVAL_STATUS.APPROVED);

    return (statuses.length !== 0 && approvedCount === statuses.length);
};

export const getPackagingTypesCount = (cargo: Cargo[]): { [key: string]: number } => {
    return cargo.reduce((types, item) => {
        const { packaging = null } = item || {};
        const { packagingType = null } = packaging || {};
        const { type = null } = packagingType || {};

        return type
            ? { ...types, [type]: types[type] ? types[type] + 1 : 1 }
            : types;
    }, {});
};

export interface ShipmentWithSharedField extends Shipment {
    isShared: boolean,
}

export interface Packaging {
    serialNumber: string,
}

export interface ProcessedPackagingAndProductRelease {
    packagingInfo: ProcessedPackagingData,
    productRelease: {
        approvalStatus: string,
        temperatureStatus: string,
        temperatureMin: number,
        temperatureRangeMin: number,
        temperatureMax: number,
        temperatureRangeMax: number,
        containerStatus: string,
        palletStatus: string,
        palletStatus1: string,
        palletStatus2: string,
        sealStatus: string,
        rejectedStatusMessage: string,
    },
    temperatureCheckResult: TemperatureCheckResult,
}

export interface ShipmentData {
    collectionDropoffPoint: string,
    customerReference: string,
    destinationAirportCity: string,
    destinationAirportCode: string,
    handoverPoint: string,
    isAllApproved: boolean,
    isPending: boolean,
    isWarning: boolean,
    leaseEnd: string,
    leaseStart: string,
    marker: LatLng,
    mawbNumber: string,
    hawbNumber: string,
    originAirportCity: string,
    originAirportCode: string,
    packagingSquareIcons: string[],
    packagingTypesCount: { [key: string]: number },
    packagings: ProcessedPackagingAndProductRelease[],
    packagingsSerialNumberList: string[],
    energyLevel: EnergyLevelCheckResult[],
    energyLevelsList: string[],
    pharmaCompanyName: string,
    polylines: PolylineAndStepStatus[],
    shipmentNumber: string,
    status: string,
    statusLabel: string,
    temperatureRange: string,
    shipmentSensorDataTimeRange: TimeRange,
    currentPosition: LatLng,
}

interface Statuses {
    containerStatus: string,
    palletStatus: string,
    palletStatus1: string,
    palletStatus2: string,
    sealStatus: string,
}

const getRejectedStatusMessage = (statuses: Statuses): string => {
    const rejectedStatuses = Object.keys(statuses).filter(status => statuses[status] === 'REJECTED');

    if (rejectedStatuses.length === 0) {
        return null;
    } else if (rejectedStatuses.length > 0) {
        return 'Several Damages Reported';
    }
    const [rejected] = rejectedStatuses;

    switch (rejected) {
    case 'containerStatus':
        return 'Container Damage Reported';
    case 'sealStatus':
        return 'Seal Issue Reported';
    default:
        return 'Pallet Damage Reported';
    }
};

const getProcessedPackagingAndProductRelease = (
    cargo: Cargo[], packagingCodeLabels = {},
): ProcessedPackagingAndProductRelease[] => {
    return cargo.map(item => {
        const { packaging, skyCoreProductRelease, temperatureCheckResult } = item;
        const {
            approvalStatus,
            temperatureStatus = '',
            temperatureMin = null,
            temperatureRangeMin = null,
            temperatureMax = null,
            temperatureRangeMax = null,
            containerStatus = '',
            palletStatus = '',
            palletStatus1 = '',
            palletStatus2 = '',
            sealStatus = '',
        } = skyCoreProductRelease || {};

        return {
            packagingInfo: fetchPackaging(packaging, packagingCodeLabels),
            productRelease: {
                approvalStatus,
                temperatureStatus,
                temperatureMin,
                temperatureRangeMin,
                temperatureMax,
                temperatureRangeMax,
                containerStatus,
                palletStatus,
                palletStatus1,
                palletStatus2,
                sealStatus,
                rejectedStatusMessage: getRejectedStatusMessage({
                    containerStatus,
                    sealStatus,
                    palletStatus,
                    palletStatus1,
                    palletStatus2,
                }),
            },
            temperatureCheckResult,
        };
    });
};

const isNumber = (value: any): boolean => {
    return !Number.isNaN(Number.parseFloat(value));
};

const isPathCorrect = (startLocation: (Airport | Location), endLocation: (Airport | Location)): boolean => {
    const { geolocation: startGeolocation = null } = startLocation || {};
    const { geolocation: endGeolocation = null } = endLocation || {};

    return startGeolocation !== null && endGeolocation !== null
        && isNumber(startGeolocation.latitude) && isNumber(startGeolocation.longitude)
        && isNumber(endGeolocation.latitude) && isNumber(endGeolocation.longitude);
};

const getLocation = (location: Geolocation): LatLng => ({
    lat: (Math.trunc(location.latitude * 10000) / 10000),
    lng: (Math.trunc(location.longitude * 10000) / 10000),
});

const getPolyline = (startPoint: (Airport | Location), endPoint: (Airport | Location), stepStatus: string)
    : PolylineAndStepStatus => (
    {
        path: [getLocation(startPoint.geolocation), getLocation(endPoint.geolocation)],
        stepStatus,
    }
);

export const getMarker = (polylines: PolylineAndStepStatus[]): LatLng => {
    if (!polylines?.length) {
        return null;
    }

    if (polylines[0].stepStatus !== ORDER_STEP_TYPE.CLOSED) {
        return polylines[0].path[0];
    }

    for (let i = 1; i < polylines.length; i++) {
        if (polylines[i - 1].stepStatus === ORDER_STEP_TYPE.CLOSED
            && polylines[i].stepStatus !== ORDER_STEP_TYPE.CLOSED) {
            return polylines[i].path[0];
        }
    }

    return polylines[polylines.length - 1].path[1];
};

export const getPolylines = (orderSteps: OrderStep[] = []): PolylineAndStepStatus[] => {
    return orderSteps?.reduce((polylines, orderStep) => {
        const {
            pickupLocation = null,
            originAirport = null,
            destinationAirport = null,
            deliveryLocation = null,
            stepStatus = null,
        } = orderStep || {};

        if (isPathCorrect(pickupLocation, originAirport)
            && isPathCorrect(destinationAirport, deliveryLocation)) {
            return [
                ...polylines,
                getPolyline(pickupLocation, originAirport, stepStatus),
                getPolyline(originAirport, destinationAirport, stepStatus),
                getPolyline(destinationAirport, deliveryLocation, stepStatus),
            ];
        } else if (isPathCorrect(pickupLocation, deliveryLocation)) {
            return [
                ...polylines,
                getPolyline(pickupLocation, deliveryLocation, stepStatus),
            ];
        }
        return polylines;
    }, []);
};
export const getCurrentPosition = (orderSteps: OrderStep[] = []): LatLng => {
    let currentPosition = null;

    if (orderSteps.find(it => it.stepStatus === ORDER_STEP_TYPE.IN_PROGRESS)) {
        const intersectingStep = orderSteps.find(it => it.stepStatus === ORDER_STEP_TYPE.IN_PROGRESS);

        currentPosition = intersectingStep.pickupLocation || intersectingStep.location;
    } else if (orderSteps.find(it => (it.stepStatus === ORDER_STEP_TYPE.CLOSED
        || it.stepStatus === ORDER_STEP_TYPE.COMPLETED))) {
        const [intersectingStep] = orderSteps.filter(it => (it.stepStatus === ORDER_STEP_TYPE.CLOSED
            || it.stepStatus === ORDER_STEP_TYPE.COMPLETED)).slice(-1);

        currentPosition = intersectingStep.deliveryLocation || intersectingStep.location;
    } else {
        const intersectingStep = orderSteps.find(it => it.stepStatus === ORDER_STEP_TYPE.NOT_STARTED);

        currentPosition = intersectingStep.pickupLocation || intersectingStep.location;
    }

    if (!currentPosition) return null;
    const {
        longitude: lng,
        latitude: lat,
    } = currentPosition?.geolocation || {};

    return lng && lat ? {
        lat,
        lng,
    } : null;
};

export const getShipmentTimeRange = (
    shipmentStart: string,
    shipmentEnd: string,
    leaseStart: string,
    leaseEnd: string,
    leaseEndExpected: string,
): TimeRange => {
    const from = shipmentStart
        ? moment(shipmentStart).utc().format('YYYY-MM-DDTHH:mm')
        : leaseStart
            ? moment(leaseStart).utc().format('YYYY-MM-DDTHH:mm')
            : null;

    const to = shipmentEnd !== null
        ? shipmentEnd
        : leaseEnd !== null
            ? leaseEnd
            : leaseEndExpected !== null
                ? leaseEndExpected
                : moment().utc().format('YYYY-MM-DDTHH:mm');

    return { from, to };
};

export const getShipmentData = (
    rawData: ShipmentWithSharedField[] = [],
    packagingCodeLabels = {},
    shipmentStatusLabels = {},
): ShipmentData[] => {
    const processedData = rawData.map(shipment => {
        const {
            cargo = [],
            pharmaCompany = null,
            skyMindInfo,
            shipmentNumber,
            status = '',
            customerReference = '',
        } = shipment;

        const {
            name: pharmaCompanyName = '',
        } = pharmaCompany || {};

        const {
            collectionDropoffPoint = '',
            handoverPoint = '',
            temperatureRange = '',
            from: originAirportCode = '',
            to: destinationAirportCode = '',
            orderSteps = [],
            shipmentStart = null,
            shipmentEnd = null,
            leaseStart = null,
            leaseEnd = null,
            leaseEndExpected = null,
            mawbNumber = '',
            hawbNumber = '',
        } = skyMindInfo || {};

        const approvalStatuses = getApprovalStatuses(cargo);
        const temperatureStatuses = getTemperatureStatuses(cargo);
        const polylines = getPolylines(orderSteps);
        const currentPosition = getCurrentPosition(orderSteps);
        const packagings = getProcessedPackagingAndProductRelease(cargo, packagingCodeLabels);
        const packagingsSerialNumberList = packagings
            .map(packaging => packaging.packagingInfo.serialNumber);
        const energyLevel = getEnergyLevels(cargo);
        const energyLevelsList = energyLevel
            .map(item => ((item?.remainingEnergyLevel / 1) * 100).toFixed(0));

        return {
            collectionDropoffPoint,
            customerReference,
            destinationAirportCity: '',
            destinationAirportCode,
            handoverPoint,
            isAllApproved: checkIsAllApproved(approvalStatuses),
            isPending: checkIsPending(approvalStatuses),
            isWarning: temperatureStatuses.includes(TEMPERATURE_STATUS.EXCURSION),
            leaseEnd: leaseEnd ? moment(leaseEnd).utc().format('DD.MM.YYYY') : '',
            leaseStart: leaseStart ? moment(leaseStart).utc().format('DD.MM.YYYY') : '',
            marker: getMarker(polylines),
            mawbNumber,
            hawbNumber,
            originAirportCity: '',
            originAirportCode,
            packagings,
            packagingsSerialNumberList,
            energyLevelsList,
            packagingSquareIcons: getPackagingIcons(approvalStatuses),
            packagingTypesCount: getPackagingTypesCount(cargo),
            pharmaCompanyName,
            polylines,
            shipmentNumber,
            energyLevel,
            status,
            statusLabel: shipmentStatusLabels[status] || status,
            temperatureRange,
            shipmentSensorDataTimeRange: getShipmentTimeRange(
                shipmentStart,
                shipmentEnd,
                leaseStart,
                leaseEnd,
                leaseEndExpected,
            ),
            currentPosition,
        };
    });

    return processedData.filter(shipment => {
        const {
            status = null,
            packagings = [],
            originAirportCode,
            destinationAirportCode,
        } = shipment;

        const correctTemperatureStatuses = packagings.length === 0
        || packagings.every(packaging => {
            const { temperatureStatus } = packaging.temperatureCheckResult || {};

            return temperatureStatus?.length > 0;
        });

        return status && originAirportCode && destinationAirportCode && correctTemperatureStatuses;
    });
};

export interface AvailableFilterOptions {
    allShipmentCount: number,
    allPackagingCount: number,
    shipmentStatus: CheckboxOption[],
    temperatureStatus: CheckboxOption[],
    originAirports: string[],
    destinationAirports: string[],
}

export const initialAvailableFilterOptions: AvailableFilterOptions = {
    allShipmentCount: 0,
    allPackagingCount: 0,
    shipmentStatus: [],
    temperatureStatus: [],
    originAirports: [],
    destinationAirports: [],
};

const getFilterOptions = (shipments: ShipmentData[] = []): { [key: string]: string[] } => {
    return shipments.reduce((data, shipment) => {
        const {
            shipmentStatusArr = [],
            temperatureStatusArr = [],
            originAirportsArr = [],
            destinationAirportsArr = [],
        } = data;
        const {
            status = null,
            packagings = [],
            originAirportCode,
            destinationAirportCode,
        } = shipment;

        const temperatureStatuses = packagings.reduce((data, packaging) => {
            const { temperatureStatus = null } = packaging.temperatureCheckResult || {};

            return temperatureStatus
            && !data.includes(temperatureStatus)
            && !temperatureStatusArr.includes(temperatureStatus)
                ? [...data, temperatureStatus]
                : data;
        }, []);

        return {
            shipmentStatusArr: status && !shipmentStatusArr.includes(status)
                ? [...shipmentStatusArr, status]
                : shipmentStatusArr,
            temperatureStatusArr: temperatureStatusArr.concat(temperatureStatuses),
            originAirportsArr: originAirportCode && !originAirportsArr.includes(originAirportCode)
                ? [...originAirportsArr, originAirportCode]
                : originAirportsArr,
            destinationAirportsArr: destinationAirportCode && !destinationAirportsArr.includes(destinationAirportCode)
                ? [...destinationAirportsArr, destinationAirportCode]
                : destinationAirportsArr,
        };
    }, {
        shipmentStatusArr: [],
        temperatureStatusArr: [],
        originAirportsArr: [],
        destinationAirportsArr: [],
    });
};

const fetchShipmentStatusOptions = (
    shipments: ShipmentData[], availableStatuses: string[], labels = {}, descriptions = {},
): CheckboxOption[] => {
    return availableStatuses.map(option => ({
        count: shipments.filter(item => item.status === option).length,
        label: labels[option] || option,
        value: option,
        description: descriptions[option] || '',
    }));
};

const getPackagingCountByTemperatureStatus = (shipments: ShipmentData[], status: string): number => {
    return shipments.reduce((count, shipment) => {
        const { packagings = [] } = shipment;

        return packagings.length === 0
            ? count
            : count + packagings.filter(packaging => {
                const { temperatureStatus = null } = packaging.temperatureCheckResult || {};

                return temperatureStatus === status;
            }).length;
    }, 0);
};

const fetchTemperatureStatusOptions = (
    shipments: ShipmentData[], availableStatuses: string[], labels = {}, descriptions = {},
): CheckboxOption[] => {
    return availableStatuses.map(option => ({
        count: getPackagingCountByTemperatureStatus(shipments, option),
        label: labels[option] || option,
        value: option,
        description: descriptions[option] || '',
    }));
};

export const getAvailableFilterOptions = (
    shipments: ShipmentData[] = [],
    shipmentStatusLabels = {},
    temperatureStatusLabels = {},
    shipmentStatusDescriptions = {},
    temperatureStatusDescriptions = {},
): AvailableFilterOptions => {
    const options = getFilterOptions(shipments);

    const shipmentStatus = fetchShipmentStatusOptions(
        shipments, options.shipmentStatusArr, shipmentStatusLabels, shipmentStatusDescriptions,
    );
    const temperatureStatus = fetchTemperatureStatusOptions(
        shipments, options.temperatureStatusArr, temperatureStatusLabels, temperatureStatusDescriptions,
    );
    const allPackagingCount = temperatureStatus.reduce((sum, option) => {
        return sum + option.count;
    }, 0);

    const { originAirportsArr = [], destinationAirportsArr = [] } = options;

    return {
        allShipmentCount: shipments.length,
        allPackagingCount,
        shipmentStatus,
        temperatureStatus,
        originAirports: originAirportsArr.sort(),
        destinationAirports: destinationAirportsArr.sort(),
    };
};

export const getShipmentStatusCount = (shipments: ShipmentData[]): { [key: string]: number } => {
    const statuses = shipments.map(({ status }) => status);
    const availableStatuses = [...(new Set(statuses))];

    return availableStatuses.reduce((data, option) => {
        return {
            ...data,
            [option]: statuses.filter(status => status === option).length,
        };
    }, {});
};

export interface ClientSideFilter {
    shipmentStatus: string[],
    temperatureStatus: string[],
    originAirports: string[],
    destinationAirports: string[],
}

export const initialClientSideFilter: ClientSideFilter = {
    shipmentStatus: [],
    temperatureStatus: [],
    originAirports: [],
    destinationAirports: [],
};

export interface FilterOptionsCount {
    shipmentStatusCount: { [key: string]: number },
    temperatureStatusCount: { [key: string]: number },
}

export const getPackagingsCount = (shipments: ShipmentData[] = []) : { [key: string]: number } => {
    const options = getFilterOptions(shipments);

    const temperatureStatus = fetchTemperatureStatusOptions(shipments, options.temperatureStatusArr);

    return temperatureStatus.reduce((data, option) => {
        return {
            ...data,
            [option.value]: option.count,
        };
    }, {});
};

export const initialFilterOptionsCount: FilterOptionsCount = {
    shipmentStatusCount: {},
    temperatureStatusCount: {},
};

const areCoordinatesEqual = (point1: LatLng, point2: LatLng): boolean => {
    return point1.lat === point2.lat && point1.lng === point2.lng;
};

export interface ClusterInfo {
    id: number,
    position: LatLng,
    hasPending: boolean,
    hasWarning: boolean,
    shipmentNumbers: string[],
}

export const getClustersInfo = (shipments: ShipmentData[] = []): ClusterInfo[] => shipments
    .reduce((data: ClusterInfo[], shipment) => {
        if (!shipment.marker) {
            return data;
        }

        const clusterIndexWithSameLocation = data
            .findIndex(cluster => areCoordinatesEqual(cluster.position, shipment.marker));

        return clusterIndexWithSameLocation === -1
            ? [
                ...data,
                {
                    id: data.length,
                    position: shipment.marker,
                    hasPending: shipment.isPending,
                    hasWarning: shipment.isWarning,
                    shipmentNumbers: [shipment.shipmentNumber],
                },
            ] : [
                ...data.slice(0, clusterIndexWithSameLocation),
                {
                    id: data[clusterIndexWithSameLocation].id,
                    position: data[clusterIndexWithSameLocation].position,
                    hasPending: data[clusterIndexWithSameLocation].hasPending || shipment.isPending,
                    hasWarning: data[clusterIndexWithSameLocation].hasWarning || shipment.isWarning,
                    shipmentNumbers: [
                        ...data[clusterIndexWithSameLocation].shipmentNumbers,
                        shipment.shipmentNumber,
                    ],
                },
                ...data.slice(clusterIndexWithSameLocation + 1),
            ];
    }, []);

export const getFilteredShipmentsByAirports = (
    data: ShipmentData[] = [],
    filters: ClientSideFilter,
) => {
    return data.filter((shipment) => {
        return filters.originAirports.includes(shipment.originAirportCode)
            && filters.destinationAirports.includes(shipment.destinationAirportCode);
    });
};

export const getFilteredShipmentsByShipmentStatus = (
    data: ShipmentData[] = [],
    filters: ClientSideFilter,
) => {
    const { shipmentStatus } = filters;

    return data.filter((shipment) => shipmentStatus.includes(shipment.status));
};

export const getFilteredShipmentsByTemperatureStatus = (
    data: ShipmentData[] = [],
    filters: ClientSideFilter,
) => {
    const { temperatureStatus } = filters;

    return data.filter((shipment) => {
        return (shipment.packagings.length === 0
            ? true
            : shipment.packagings.some(item => {
                const { temperatureCheckResult } = item;

                return temperatureStatus.includes(temperatureCheckResult.temperatureStatus);
            }));
    });
};
