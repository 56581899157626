import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    expansion: {
        padding: '14px 0',
        cursor: 'auto',
    },
    expansionLoading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    expansionBlur: {
        filter: 'blur(2px)',
        pointerEvents: 'none',
    },
    expansionLayout: {
        display: 'grid',
        gridGap: '12px',
        gridTemplateColumns: '4fr 2fr',
        width: '100%',
        minHeight: '157px',
        paddingRight: '12px',
    },
    containers: {
        display: 'inline-grid',
        gridAutoFlow: 'column',
        gridGap: '12px',
    },
    expansionRoot: {
        background: theme.palette.secondary['50'],
    },
}));
