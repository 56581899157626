import React, {
    CSSProperties,
    useCallback,
    useState,
} from 'react';
import Button from '@mui/material/Button';
import ApprovalModal from 'shared-components/ApprovalModal';
import useCustomTranslation from 'hooks/useCustomTranslation';

const buttonStyle: CSSProperties = {
    margin: '2px 0',
    textTransform: 'uppercase',
    width: '165px',
    height: '35px',
};

type Props = {
    dataUpdateTrigger: (requiredDataUpdate: boolean) => void,
    evidenceId: number,
    orderNumber: string,
}

const QMApprovalCell = ({
    dataUpdateTrigger,
    evidenceId,
    orderNumber,
} : Props) => {
    const [openApprovalModal, setOpenApprovalModal] = useState(false);
    const { t } = useCustomTranslation();
    const handleClick = useCallback(() => setOpenApprovalModal(true), []);

    return (
        <>
            <Button
                color="primary"
                variant="contained"
                style={buttonStyle}
                onClick={handleClick}
            >
                {t('COMMON.APPROVE_REJECT')}
            </Button>
            <ApprovalModal
                open={openApprovalModal}
                setOpen={setOpenApprovalModal}
                shipmentNumber={orderNumber}
                productReleaseId={evidenceId}
                dataUpdateTrigger={dataUpdateTrigger}
            />
        </>
    );
};

export default QMApprovalCell;
