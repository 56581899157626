import React from 'react';
import Map from 'shared-components/Map';
import Tooltip from '@mui/material/Tooltip';

import icons from 'shared-components/icons';
import { useQuery } from 'react-query';
import { fetchPlaceInfo, PlaceInfo } from '../lib';

const getMapConfig = (location) => ({
    center: location,
    containerStyle: {
        height: '25vh',
        width: '100%',
    },
    zoom: 2,
    backgroundColor: 'unset',
});

type Props = {
    lat?: number,
    lng?: number,
}

const LatitudeLongitudeWithPlaceInfo = ({
    lat = null,
    lng = null,
} : Props) => {
    // const placeInfo = useGetPlaceInfoByCoordinates({ lat, lng });

    const { data: placeInfo = null } = useQuery<PlaceInfo>(
        ['placeInfo', lat, lng],
        () => fetchPlaceInfo(lat, lng),
        {
            enabled: !!lat && !!lng,
            cacheTime: Number.MAX_SAFE_INTEGER,
        },
    );

    if (!lat || !lng) {
        return null;
    }

    const locationInfo = placeInfo?.city && placeInfo?.country
        ? `${placeInfo?.city}, ${placeInfo?.country}`
        : placeInfo?.city || placeInfo?.country || '';

    return (
        <Tooltip
            placement="left-end"
            componentsProps={{
                tooltip: {
                    sx: {
                        borderTop: '10px solid transparent',
                        borderBottom: '10px solid transparent',
                        borderColor: '#C3E9F7',
                        minWidth: '650px',
                        background: '#F7F7F7 0% 0% no-repeat padding-box',
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: '10px',
                        opacity: 1,
                    },
                },
            }}
            title={(
                <Map
                    mapConfig={getMapConfig({ lat, lng })}
                    separateMarker={{ lat, lng }}
                    separateMarkerIcon={icons.hexa_blue}
                />
            )}
        >
            <div>{ `${lat} / ${lng} ${locationInfo && `(${locationInfo})`}` }</div>
        </Tooltip>
    );
};

export default LatitudeLongitudeWithPlaceInfo;
