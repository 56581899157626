import React from 'react';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Alert from '@mui/material/Alert';
import { RequestStatus } from 'dataTypes/common';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'white',
    },
    spinner: {
        color: theme.palette.primary[400],
    },
}));

type Props = {
    status: RequestStatus,
    isAuthLoading?: boolean,
    error?: string,
}

const StatusInfo = ({
    status,
    isAuthLoading = false,
    error = null,
} : Props) => {
    const classes = useStyles();

    if (isAuthLoading || status === 'PENDING') {
        return (
            <div className={classes.wrapper}>
                <CircularProgress classes={{
                    colorPrimary: classes.spinner,
                }}
                />
            </div>
        );
    }
    return (
        <>
            <Alert severity="error">
                {error || 'Some error happened'}
            </Alert>
        </>
    );
};

export default StatusInfo;
