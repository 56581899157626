import { Classes } from 'jss';
import React from 'react';
import { ManualAddress } from 'shared-components/dataTypes';
import { LOCATION_OPTIONS } from 'shared-components/LocationSelector/LocationSelector';
import useCustomTranslation from 'hooks/useCustomTranslation';

type Props = {
    data?: ManualAddress,
    short: boolean,
    error: boolean,
    noIcon?: boolean,
    onClick: Function,
    classes: Classes
}
const ManualAddressView = ({
    data = null,
    short = false,
    error = false,
    onClick,
    classes,
    noIcon = false,
}: Props) => {
    const { t } = useCustomTranslation();

    return (

        <div
            className={
                [short ? classes.infoTextShort : classes.infoText, error ? classes.error : ''].join(' ')
            }
        >
            {
                short && !noIcon && (
                    <img
                        className={classes.iconShort}
                        src={LOCATION_OPTIONS(t)['address']?.icon}
                        alt="address"
                    />
                )
            }
            <span className={onClick ? classes.editable : ''}>
                <span className={classes.line}>
                    {`${data?.addressLine1}${(short && data?.city) ? `, ${data?.city}` : ''}`}
                </span>
                {
                    !short && (
                        <>
                            <span className={classes.line}>
                                {data?.zipCode}
                                {' '}
                                {data?.city}
                            </span>
                            <span className={classes.line}>
                                {data?.country?.name || data?.country?.code}
                            </span>
                        </>
                    )
                }

            </span>

        </div>

    );
};

export default ManualAddressView;
