import React, { useCallback, useState } from 'react';
import { QmApprovalInfo } from 'dataTypes/SecureBackend/processedData';
import { APPROVAL_STATUS } from 'shared-components/constants';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';

import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import useGetTranslationGroup from 'hooks/useGetTranslationGroup';
import Line from 'shared-components/Line';
import CustomModal from 'shared-components/CustomModal';
import useCustomTranslation from 'hooks/useCustomTranslation';
import QmApprovalHistoryItem from './QmApprovalHistoryItem';
import useStyles from './ApprovalReviseModal.style';

type Props = {
    /**
     * Shipment(order) number, required for update request
     */
    shipmentNumber: string,
    /**
     * Evidence Id, required for update request
     */
    productReleaseId: number,
    /**
     * Describes the state of the modal window (open/close)
     */
    open: boolean,

    /**
     * Array of previous qmApproval decisions
     */
    qmApproval?: QmApprovalInfo,
    /**
    * Controls the state of the modal window (open/close)
    */
   setOpen: (isOpen: boolean) => void,
    /**
     * Required to get from BE actual data and update state of the above components.
     */
    dataUpdateTrigger: (requiredDataUpdate: boolean) => void,
}

/**
 * Modal UI component to revise
 * the current QMApproval decision with providing a history
 */
const ApprovalReviseModal = ({
    shipmentNumber,
    productReleaseId = null,
    open = false,
    setOpen,
    qmApproval = {
        qmApprovalStatus: '',
        qmApprovalIcon: '',
        qmApprovalComment: '',
        qmApprovedBy: '',
        qmApprovedOn: '',
        qmApprovalDetails: [],
    },
    dataUpdateTrigger,
    // updateQMApproval = null,
} : Props) => {
    const classes = useStyles();
    const [qmApprovalComment, setQmApprovalComment] = useState('');
    const { FlexibleRequest: updateQMApproval } = useSecureBackendEndpoints('skyship').statusNotificationRequests;
    const approvalStatusLabels = useGetTranslationGroup('APPROVAL_STATUS');
    const updateQuery = `shipment/${shipmentNumber}/product-release/${productReleaseId}/qm-approval`;

    const { t } = useCustomTranslation();
    const {
        qmApprovalStatus = '',
        qmApprovalDetails = [],
    } = qmApproval;

    const handleApproveClick = useCallback(async () => {
        await updateQMApproval('PATCH', updateQuery, {
            qmApprovalComment,
            qmApprovalStatus: APPROVAL_STATUS.APPROVED,
        });

        setQmApprovalComment('');
        setOpen(false);
        dataUpdateTrigger(true);
    }, [updateQMApproval, qmApprovalComment]);

    const handleRejectClick = useCallback(async () => {
        await updateQMApproval('PATCH', updateQuery, {
            qmApprovalComment,
            qmApprovalStatus: APPROVAL_STATUS.REJECTED,
        });

        setQmApprovalComment('');
        setOpen(false);
        dataUpdateTrigger(true);
    }, [updateQMApproval, qmApprovalComment]);

    const handleTextInputChange = (event) => {
        setQmApprovalComment(event.target.value);
    };

    const handleClose = () => setOpen(false);

    return (
        <CustomModal
            key={`Dialog${productReleaseId}`}
            open={open}
            onClose={handleClose}
            title={t('ORDER_DETAILS.APPROVAL_REJECTION')}
        >
            <DialogContent>
                <DialogContentText
                    className={classes.dialogContentText}
                    id={`dialogDescription${productReleaseId}`}
                >
                    {
                        qmApprovalStatus === APPROVAL_STATUS.APPROVED
                            ? t('ORDER_DETAILS.PACKAGING_BEEN_APPROVED')
                            : t('ORDER_DETAILS.PACKAGING_BEEN_REJECTED')
                    }
                </DialogContentText>
                <Line className={classes.line} />
                {
                    qmApprovalDetails?.map(({
                        qmApprovalStatus,
                        qmApprovalComment,
                        qmApprovedBy,
                        qmApprovedOn,
                    }, index) => (
                        <QmApprovalHistoryItem
                            key={`qmApprovalDetail${index}`}
                            qmApprovalStatus={qmApprovalStatus}
                            qmApprovalStatusLabel={approvalStatusLabels[qmApprovalStatus]}
                            qmApprovalComment={qmApprovalComment}
                            qmApprovedBy={qmApprovedBy}
                            qmApprovedOn={qmApprovedOn}
                        />
                    ))
                }
            </DialogContent>
            <TextField
                id={`comment${productReleaseId}`}
                className={classes.textField}
                label={t('COMMON.COMMENT')}
                value={qmApprovalComment}
                onChange={handleTextInputChange}
                multiline
                variant="standard"
                inputProps={{
                    className: classes.textFieldText,
                }}
            />
            <DialogActions className={classes.dialogActions}>
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.approveButton}
                    onClick={handleApproveClick}
                    autoFocus
                >
                    {t('ORDER_DETAILS.APPROVE')}
                </Button>
                <Button
                    color="primary"
                    variant="text"
                    className={classes.rejectButton}
                    onClick={handleRejectClick}
                >
                    {t('ORDER_DETAILS.REJECT')}
                </Button>
            </DialogActions>
        </CustomModal>
    );
};

export default ApprovalReviseModal;
