import React, { MouseEventHandler, useState, useMemo, useCallback } from 'react';
import { Collapse, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { OverlayView } from '@react-google-maps/api';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import { Link } from 'react-router-dom';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import useCachedQueryRequest from 'hooks/useCachedQueryRequest';
import moment from 'moment';
import { ExtractedAssetData } from 'TrackAndTrace/Assets/lib';
import { GatewayInfoDTO } from 'dataTypes/SecureBackend/apiResponse';
import GatewayStepLocation from './GatewayStep';

type Props = {
    assetGroup: ExtractedAssetData[],
    onMouseLeave: MouseEventHandler<HTMLDivElement>,
    gateways: GatewayInfoDTO[],
}
const getClusterPositionOffset = () => ({
    x: 0,
    y: 0,
});

const useStyles = makeStyles((theme: SkycellThemeInterface) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        paddingLeft: '40px',
    },
    loggerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
        position: 'relative',
        borderRadius: '4px',
        zIndex: 100,
        backgroundColor: theme.palette.common.white,
    },
    headerText: {
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        textDecoration: 'underline',
        fontSize: '32px',
        padding: '8px 30px',
        letterSpacing: '0.01em',
        textAlign: 'left',
        color: theme.palette.common.black,
    },
    body: {},
    division: {
        padding: '14px 14px 30px 14px',
        borderTop: '1px solid #dbdbdb',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontSize: '14px',
        width: '100%',
    },
}));
// 2023-09-12T13:58
const AssetTooltip = ({
    assetGroup = [],
    onMouseLeave,
    gateways = [],
}: Props) => {
    const classes = useStyles();
    const [hoveredAssetId, setHoveredAssetId] = useState<number | null>(null);
    const {
        Get: getLoggerSensorData,
    } = useSecureBackendEndpoints('assets').requests;
    const {
        data: sensorData,
        isSuccess: sensorDataLoaded,
    } = useCachedQueryRequest({
        key: `${hoveredAssetId}_sensor_data`,
        request: getLoggerSensorData.bind(null, `${
            hoveredAssetId
        }/sensor-data?dataTypes=LOCATION_LATITUDE&dataTypes=LOCATION_LONGITUDE&from=${
            moment().utc().subtract(7, 'day').format('YYYY-MM-DDTHH:mm')
        }&to=${moment().utc().format('YYYY-MM-DDTHH:mm')}`),
        options: {
            enabled: !!hoveredAssetId,
        },
    });
    const [firstEntry] = assetGroup;
    const {
        lastMeasuredLatitude: lat, lastMeasuredLongitude: lng,
    } = firstEntry || {};

    const { t: trans } = useCustomTranslation();

    const lastGateways = useMemo<{
        gateway: GatewayInfoDTO,
        t: string,
    }[]>(() => {
        if (hoveredAssetId && sensorData && gateways) {
            const gatewaysIndex = sensorData.dataTypes.indexOf('GATEWAY_NUMBER');
            const isHistoricalIndex = sensorData.dataTypes.indexOf('IS_HISTORICAL');

            if (gatewaysIndex !== -1 && isHistoricalIndex !== -1) {
                const sensorDataIndexMap = sensorData.data
                    .filter(({ d = [] }) => !d[isHistoricalIndex])
                    .map(({ d = [], t }) => ({
                        gatewayNumber: d[gatewaysIndex],
                        t,
                    }));

                return [...sensorDataIndexMap].reduce((
                    acc: {
                        gateway: GatewayInfoDTO | undefined,
                        t: string,
                    }[],
                    { gatewayNumber, t },
                ) => {
                    const lastGatewayNumber = acc[acc.length - 1]?.gateway?.gatewayImeiMac;

                    if (lastGatewayNumber?.toLowerCase() === gatewayNumber?.toLowerCase()) return acc;

                    return [
                        ...acc,
                        {
                            gateway: gateways
                                .find(({ gatewayImeiMac: gn }) => gn?.toLowerCase() === gatewayNumber?.toLowerCase()),
                            t,
                        },
                    ];
                }, []).slice(-3).reverse();
            } else return [];
        } else return [];
    }, [sensorData, hoveredAssetId, gateways]);
    const getLatestStoredGateway = useCallback<(asset:ExtractedAssetData) => {
        gateway: GatewayInfoDTO,
        t: string,
    }[]>((asset) => [{
            gateway: {
                latitude: asset.lastMeasuredLatitude,
                longitude: asset.lastMeasuredLongitude,
                iataCode: asset.iataCode,
                locationRemark: asset.locationRemark,
            },
            t: moment(asset.lastMeasuredTimestamp).format('YYYY.MM.DD HH:mm'),
        }], [assetGroup]);

    return (

        <OverlayView
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={{
                lat,
                lng,
            }}
            getPixelPositionOffset={getClusterPositionOffset}
        >

            <div
                className={classes.root}
                onMouseLeave={onMouseLeave}
            >
                {
                    assetGroup.map((asset) => {
                        const link = `/asset-management/assets/readout/${asset.assetNumber}`;
                        const {
                            assetNumber,
                            id,
                        } = asset || {};

                        return (
                            <div
                                className={classes.loggerWrapper}
                                key={assetNumber}
                            >
                                <Link to={link}>
                                    <div
                                        className={classes.headerText}
                                        onMouseEnter={() => setHoveredAssetId(id)}
                                    >
                                        {assetNumber}
                                    </div>
                                </Link>
                                <Collapse className={classes.body} in={hoveredAssetId === id}>
                                    <div className={classes.division}>
                                        <span style={{
                                            paddingBottom: '30px',
                                            fontSize: '14px',
                                        }}
                                        >
                                            {trans('TRACK_AND_TRACE.RECENT_LOCATIONS')}
                                        </span>
                                        {
                                            !sensorDataLoaded && (
                                                <CircularProgress size={16} />
                                            )
                                        }
                                        {
                                            sensorDataLoaded && lastGateways
                                            && lastGateways
                                                .map(({ gateway, t: time }, i) => (
                                                    <GatewayStepLocation
                                                        key={`${assetNumber}_${time}_${gateway?.latitude}`}
                                                        dash={i !== 0}
                                                        gateway={gateway}
                                                        time={time}
                                                        loading={!sensorDataLoaded}
                                                    />
                                                ))
                                        }
                                        {
                                            sensorDataLoaded && lastGateways.length === 0 && (
                                                getLatestStoredGateway(asset).map(({ gateway, t: time }) => (
                                                    <GatewayStepLocation
                                                        key={`${gateway.iataCode}${gateway.remark}`}
                                                        gateway={gateway}
                                                        time={time}
                                                        loading={!sensorDataLoaded}
                                                    />
                                                ))
                                            )
                                        }

                                    </div>
                                </Collapse>
                            </div>
                        );
                    })
                }
            </div>
        </OverlayView>

    );
};

export default AssetTooltip;
