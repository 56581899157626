import React, { ReactElement, ReactNode } from 'react';
import Tooltip from '@mui/material/Tooltip';
import CustomTitle from 'shared-components/ControlledTooltip/CustomTitle';
import icons from 'shared-components/icons';

type Props = {
    children?: ReactElement,
    description: string | ReactNode,
    descriptionTitle?: string,
}

const OptionItemTooltip = ({
    children = null,
    description,
    descriptionTitle = null,
} : Props) => {
    return (
        <Tooltip
            disableFocusListener
            disableTouchListener
            placement="right-start"
            componentsProps={{
                tooltip: {
                    sx: {
                        borderTop: '10px solid transparent',
                        borderBottom: '10px solid transparent',
                        borderColor: '#C3E9F7',
                        width: '288px',
                        background: '#F7F7F7 0% 0% no-repeat padding-box',
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: '10px',
                        opacity: 1,
                    },
                },
            }}
            title={(
                <CustomTitle
                    iconSrc={icons.info_blue}
                    description={description}
                    descriptionTitle={descriptionTitle}
                />
            )}
        >
            { children }
        </Tooltip>
    );
};

export default OptionItemTooltip;
