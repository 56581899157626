import React, { useContext, useMemo } from 'react';
import Card from 'shared-components/Card';
import Table from 'shared-components/Table';

import LaneTemperatureContext from 'Contexts/LaneTemperatureContext';
import useCustomTranslation from 'hooks/useCustomTranslation';
import tableColumns from './tableColumns';
import useStyles from '../../TemperatureSimulation.style';

// type Props = {
//     tableData: {
//         certifiedOn: string,
//         code: string,
//         documentUrl: string,
//         packagingPictureUrl: string,
//         provider: string,
//         summerProfile: boolean | {
//             data: string,
//             colSpan: number,
//         },
//         summerReserve?: string,
//         winterProfile?: string,
//         winterReserve?: string,
//         technology: string,
//         temperatureSpecificName: string,
//     }[],
// }
type Props = {
   type: 'winter' | 'summer',
}
const SimulationSummary = ({
    type,
}: Props) => {
    const classes = useStyles();
    const { t } = useCustomTranslation();
    const {
        packagingTypes,
        profileData,
        lane,
    } = useContext(LaneTemperatureContext);
    const selectedPackagingTypeCodes = useMemo<string[]>(() => {
        return lane?.packagingTypes?.map(it => it.code) || [];
    }, [lane]);

    const tableData = useMemo(() => {
        return selectedPackagingTypeCodes
            ?.map(code => packagingTypes.find(pt => pt.type === code))
            ?.filter(it => it);
    }, [
        packagingTypes,
        selectedPackagingTypeCodes,
    ]);
    const tableColumnsPrepared = useMemo(() => {
        return tableColumns(profileData, type, t);
    }, [profileData, type, t]);

    return (
        <Card
            title={t('COMMON.SUMMARY')}
            titleClass={classes.simulationSummaryTitle}
            zeroSidePadding
        >
            <Table
                data={tableData}
                columns={tableColumnsPrepared}
                embed
            />
        </Card>
    );
};

export default SimulationSummary;
