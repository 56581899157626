import React, { useMemo } from 'react';
import Card from 'shared-components/Card';
import { Empty } from 'utils/screenWrap';
import useStyles from './StatusContainer.style';

type Props = {
    title: string,
    comparisonMatchingInfo?: {
        icon: string,
        statusMessage: string,
    }[],
    horizontal?: boolean
}

const StatusContainer = ({
    title,
    comparisonMatchingInfo = [],
    horizontal = false,
} : Props) => {
    const classes = useStyles();
    const Wrapper = useMemo(() => (horizontal ? Empty : Card), [horizontal]);

    return (
        <Wrapper fullHeight>
            <div className={classes.title}>{title}</div>
            <div className={horizontal ? classes.horizontalFlex : ''}>
                {
                    comparisonMatchingInfo.map((comparison, index) => (
                        <div
                            key={`comparison${index * 2}`}
                            className={classes.comparisonContainer}
                        >
                            <div className={classes.flex}>
                                <img
                                    key={`locationIcon${title.replace(' ', '')}`}
                                    className={classes.icon}
                                    src={comparison.icon}
                                    alt={`Icon ${title}`}
                                />
                            </div>
                            <div className={classes.statusMessage}>{comparison.statusMessage}</div>
                        </div>
                    ))
                }
            </div>
        </Wrapper>
    );
};

export default StatusContainer;
