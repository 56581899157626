import React from 'react';

type Props = {
    isActive?: boolean,
}

const FilterIcon = ({ isActive = false } : Props) => {
    return (
        <svg
            style={{
                color: isActive ? 'black' : '#D7D7D7',
                height: '20px',
                width: '20px',
            }}
            viewBox="0 0 15 15"
        >
            <path d="M2 2.054464L15.230225 2l-5.132897 4.825055-.123136 8.311554-3.318686-2.544346.142712-5.715111z" />
        </svg>
    );
};

export default FilterIcon;
