import { useTheme } from '@mui/material/styles';
import useAvailableHeight from 'hooks/useAvailableHeight';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Polyline, Marker } from '@react-google-maps/api';
import { LatLng, LatLngAndTime, PolylineAndStepStatus } from 'dataTypes/common';
import { ORDER_STEP_TYPE } from 'shared-components/constants';
import useGetAirports, { getAirportsInPassedBoundsZone } from 'hooks/useGetAirports';
import AirportMarker from 'TrackAndTrace/commonComponents/AirportMarker';
import AirportInfoTooltip from 'TrackAndTrace/Tooltips/AirportInfoTooltip';
import { ActiveAirportInfo } from 'TrackAndTrace/Tooltips/dataTypes';
import polylineType from 'shared-components/Map/polylineType';
import { crossMarkerIconObj, getCombinedShipmentPolylines } from 'TrackAndTrace/Packagings/lib';
import LocationInfoTooltip from 'shared-components/Map/components/LocationInfoTooltip';
import BaseMap from 'TrackAndTrace/commonComponents/BaseMap';
import { GenericShipmentData, getClustersInfo, ClusterInfo } from '../../lib';
import Pagination from './components/Pagination';
import TooltipCluster from './components/TooltipCluster';

type Props = {
    currentPage: number,
    shipments: GenericShipmentData[],
    updateMap: Dispatch<SetStateAction<number>>,
    shipmentsCount: number,
    setCurrentPage: Dispatch<SetStateAction<number>>,
    showAirportsInfo: boolean,
}
export type ActiveShipment = {
    externalId: string,
    timestamp: number
}

const ShipmentsMap = ({
    currentPage = 1,
    shipments = [],
    shipmentsCount = null,
    setCurrentPage,
    showAirportsInfo,
}: Props) => {
    const [activeCluster, setActiveCluster] = useState({
        clusterId: null,
        timestamp: Date.now(),
        shipmentNumbers: [],
    });
    const theme = useTheme();
    const availableHeight = useAvailableHeight();

    const [activeShipment, setActiveShipment] = useState<ActiveShipment>({
        externalId: null,
        timestamp: Date.now(),
    });
    const [activeAirportInfo, setActiveAirportInfo] = useState<ActiveAirportInfo>({
        code: null,
        timestamp: Date.now(),
    });
    const [polylineMarkerInfo, setPolylineMarkerInfo] = useState<LatLngAndTime>(null);
    const [polylinePath, setPolylinePath] = useState<LatLng[]>([]);
    const [gMap, setGMap] = useState(null);
    const [bounds, setBounds] = useState<number[]>([]);

    const [clusters, setClusters] = useState<ClusterInfo[]>([]);
    const [shipmentsBluePolyline, setShipmentsBluePolyline] = useState<PolylineAndStepStatus[][]>([]);
    const [activeShipmentOrangePolyline, setActiveShipmentOrangePolyline] = useState<PolylineAndStepStatus[]>([]);

    const airports = getAirportsInPassedBoundsZone(useGetAirports(), bounds);

    useEffect(() => {
        setPolylinePath([]);
        setPolylineMarkerInfo(null);
    }, [activeShipment]);

    useEffect(() => {
        setActiveShipment({
            externalId: null,
            timestamp: Date.now(),
        });
    }, [shipments]);

    const closeActiveTooltip = useCallback(() => {
        const now = Date.now();

        if (activeShipment.externalId !== null) {
            setActiveShipment({
                externalId: null,
                timestamp: Date.now(),
            });
        }
        if (activeAirportInfo.code !== null) {
            setActiveAirportInfo({
                code: null,
                timestamp: now,
            });
        }
    }, [
        activeCluster,
        activeShipment,
        activeAirportInfo,
    ]);

    const cancelAirportInfoTooltipClosing = useCallback(() => {
        setActiveAirportInfo(prev => ({
            code: prev.code,
            timestamp: Date.now(),
        }));
    }, []);

    useEffect(() => {
        if (shipments.length > 0) {
            setClusters(getClustersInfo(shipments));
        } else {
            setClusters([]);
        }
    }, [shipments, activeShipment]);

    useEffect(() => {
        if (activeShipment.externalId !== null && shipments.length > 0 && activeCluster.clusterId !== null) {
            const shipment = shipments.find(item => item.externalId === activeShipment.externalId) || null;
            const { polylines: shipmentPolylines = [] } = shipment || {};

            if (shipmentPolylines.length > 0) {
                setActiveShipmentOrangePolyline(
                    getCombinedShipmentPolylines(shipmentPolylines, polylinePath, shipment.marker),
                );
            }
        } else {
            setActiveShipmentOrangePolyline([]);
        }
    }, [activeShipment, shipments, polylinePath, activeCluster]);

    useEffect(() => {
        if (activeShipment.externalId === null && shipments.length > 0 && clusters.length > 0) {
            const shipmentsInClusterArray: GenericShipmentData[][] = clusters.reduce((data, cluster) => {
                const shipment = shipments.filter(item => cluster.externalIds.includes(item.externalId)) || null;

                return shipment !== null
                    ? [...data, shipment]
                    : data;
            }, []);

            const polylineGroups: PolylineAndStepStatus[][] = shipmentsInClusterArray
                .map(shipmentsCluster => shipmentsCluster
                    .map(({ polylines }) => polylines))
                .flat();

            if (polylineGroups.length > 0) {
                setShipmentsBluePolyline(polylineGroups);
            } else {
                setShipmentsBluePolyline([]);
            }
        } else {
            setShipmentsBluePolyline([]);
        }
    }, [
        activeShipment,
        clusters,
        shipments,
    ]);

    const showedAirportInfo = useMemo(() => {
        if (airports.length === 0 || activeAirportInfo === null || activeAirportInfo.code === null) {
            return null;
        }

        const airport = airports.find(item => item.code === activeAirportInfo.code) || null;

        if (airport) {
            return airport;
        }
        return null;
    }, [activeAirportInfo, airports]);

    return (
        <BaseMap
            setBounds={setBounds}
            onClick={closeActiveTooltip}
            gMap={gMap}
            setGMap={setGMap}
            mapContainerStyle={{
                width: '100%',
                height: `calc(${availableHeight} - 60px)`,
                zIndex: 1,
            }}
        >
            {
                clusters.map((cluster, clusterIndex) => {
                    return (
                        <TooltipCluster
                            key={`cluster-${clusterIndex}-${cluster.externalIds.join('_')}`}
                            activeCluster={activeCluster}
                            setActiveCluster={setActiveCluster}
                            shipments={shipments}
                            cluster={cluster}
                            closeActiveTooltip={closeActiveTooltip}
                            activeShipment={activeShipment}
                            setActiveShipment={setActiveShipment}
                            position={cluster.position}
                        />
                    );
                })
            }
            {
                polylineMarkerInfo && (
                    <Marker
                        position={polylineMarkerInfo.location}
                        icon={crossMarkerIconObj}
                    />
                )
            }
            {
                polylineMarkerInfo && (
                    <LocationInfoTooltip
                        location={polylineMarkerInfo.location}
                        time={polylineMarkerInfo.time}
                    />
                )
            }
            {
                activeShipmentOrangePolyline.length === 0 && shipmentsBluePolyline.map(bluePolylineGroup => {
                    return (
                        bluePolylineGroup.map((polyline, polylineIndex) => {
                            return (
                                <Polyline
                                    key={`polyline${polylineIndex}${
                                        polyline.stepStatus}${polyline
                                            .path.map(p => `${p.lng}_${p.lng}`).join('_')}Blue`}
                                    path={polyline.path}
                                    options={{
                                        ...(polylineType(
                                            polyline.stepStatus !== ORDER_STEP_TYPE.COMPLETED,
                                        )),
                                        strokeColor: theme.palette.primary['deepBlue'],
                                    }}
                                />
                            );
                        }));
                })
            }
            {
                activeShipmentOrangePolyline.length !== 0
                && activeShipmentOrangePolyline.map((polyline, polylineIndex) => {
                    return (
                        <Polyline
                            key={`polyline${polylineIndex}${
                                polyline.stepStatus}${polyline
                                    .path.map(p => `${p.lng}_${p.lng}`).join('_')}Orange`}
                            path={polyline.path}
                            options={{
                                ...(polylineType(
                                    polyline.stepStatus !== ORDER_STEP_TYPE.COMPLETED,
                                )),
                                strokeColor: '#EDAE49',
                            }}
                        />
                    );
                })
            }
            {
                showAirportsInfo && airports.map(airport => {
                    const handleClickAirportsInfo = () => {
                        setActiveAirportInfo(prev => ({
                            code: prev.code === airport.code
                                ? null
                                : airport.code,
                            timestamp: Date.now(),
                        }));
                    };

                    return (
                        <AirportMarker
                            onClick={handleClickAirportsInfo}
                            isOpen={activeAirportInfo.code === airport.code}
                            airportInfo={airport}
                            key={airport.code}
                        />
                    );
                })
            }
            {
                showedAirportInfo !== null && (
                    <AirportInfoTooltip
                        airportInfo={showedAirportInfo}
                        cancelTooltipClosing={cancelAirportInfoTooltipClosing}
                    />
                )
            }
            {
                shipmentsCount > 0 && (
                    <Pagination
                        currentPage={currentPage}
                        shipmentsCount={shipmentsCount}
                        setCurrentPage={setCurrentPage}
                    />
                )
            }
        </BaseMap>
    );
};

export default ShipmentsMap;
