import React, {
    useState,
    useEffect,
} from 'react';
import TextField from '@mui/material/TextField';
import { EMAIL_PATTERN } from 'shared-components/constants';
import PhoneNumberTextField from 'shared-components/PhoneNumberTextField';
import ControlledTooltip from 'shared-components/ControlledTooltip';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useStyles from './ContactInformationCard.style';

type Props = {
    setFilter: (filter) => void,
    currentContactPerson?: string,
    currentPhoneNumber?: string,
    currentEmail?: string,
}

const EditMode = ({
    setFilter,
    currentContactPerson = '',
    currentPhoneNumber = '',
    currentEmail = '',
}: Props) => {
    const classes = useStyles();
    const [contactPerson, setContactPerson] = useState(currentContactPerson);
    const [phoneNumber, setPhoneNumber] = useState(currentPhoneNumber);
    const [email, setEmail] = useState(currentEmail);
    const { t } = useCustomTranslation();
    const handleContactName = (event) => {
        setContactPerson(event.target.value);
    };

    const handleContactPhoneNumber = (value) => {
        setPhoneNumber(value);
    };

    const handleContactEmail = (event) => {
        setEmail(event.target.value);
    };

    useEffect(() => {
        setFilter(previous => (
            {
                ...previous,
                contactPerson,
                phoneNumber: phoneNumber?.replace(/\(|\)| /g, ''),
                email,
            }
        ));
    }, [
        contactPerson,
        phoneNumber,
        email,
    ]);

    return (
        <>
            <TextField
                className={classes.textField}
                label={t('COMMON.CONTACT_NAME')}
                value={contactPerson}
                onChange={handleContactName}
                variant="standard"
                size="small"
                inputProps={{
                    className: classes.textFieldText,
                }}
            />
            <div>
                <PhoneNumberTextField
                    className={classes.textField}
                    phoneNumber={phoneNumber}
                    label={t('SERVICE_PROVIDERS.CONTACT_PHONE')}
                    handleContactPhoneNumber={handleContactPhoneNumber}
                    inputPropsClassName={classes.textFieldText}
                    placeholder={t('SERVICE_PROVIDERS.CONTACT_PHONE')}
                />
            </div>
            <ControlledTooltip
                borderColor="red"
                description={t('SERVICE_PROVIDERS.INVALID_EMAIL')}
                open={Boolean(email && !EMAIL_PATTERN.test(email))}
                placement="top-end"
            >
                <TextField
                    className={`${classes.textField} ${
                        (email && !EMAIL_PATTERN.test(email) && classes.error) || ''
                    }`}
                    label={t('SERVICE_PROVIDERS.CONTACT_EMAIL')}
                    value={email}
                    onChange={handleContactEmail}
                    variant="standard"
                    autoFocus
                    size="small"
                    inputProps={{
                        className: classes.textFieldText,
                    }}
                />
            </ControlledTooltip>
        </>
    );
};

export default EditMode;
