import React from 'react';
import icons from 'shared-components/icons';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useStyles from './ServiceProviderSiteCard.style';

type Props = {
    companyLogo?: string,
    siteName?: string,
}

const ReadMode = ({
    companyLogo = '',
    siteName = '',
}: Props) => {
    const classes = useStyles();
    const { t } = useCustomTranslation();

    return (
        <>
            <img
                alt="Company Logo"
                src={companyLogo || icons.placeholder_company_logo}
                style={{ height: '60px', marginTop: '20px' }}
            />
            <div className={classes.fieldTitle}>{t('COMMON.SITE_NAME')}</div>
            <div className={classes.filedInfo}>{siteName}</div>

        </>
    );
};

export default ReadMode;
