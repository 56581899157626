import React from 'react';
import { SERVICE_PROVIDER_FILTER_DATA_T } from 'shared-components/constants';
import useCustomTranslation from 'hooks/useCustomTranslation';
import RowWithIcon from './RowWithIcon';

type Props = {
    commodities?: string[],
    services?: string[],
}

const Commodities = ({
    commodities = [],
    services = [],
} : Props) => {
    const { t } = useCustomTranslation();

    return (
        <>
            {
                commodities
                    .filter((commodity) => SERVICE_PROVIDER_FILTER_DATA_T(t).commodities[commodity])
                    .map((commodity) => {
                        return (
                            <RowWithIcon
                                icon={SERVICE_PROVIDER_FILTER_DATA_T(t).commodities[commodity].icon}
                                label={SERVICE_PROVIDER_FILTER_DATA_T(t).commodities[commodity].label}
                                key={commodity}
                            />
                        );
                    })
            }
            {
                services
                    .filter((service) => SERVICE_PROVIDER_FILTER_DATA_T(t).services[service])
                    .map((service) => {
                        return (
                            <RowWithIcon
                                icon={SERVICE_PROVIDER_FILTER_DATA_T(t).services[service].icon}
                                label={SERVICE_PROVIDER_FILTER_DATA_T(t).services[service].label}
                                key={service}
                            />
                        );
                    })
            }
        </>
    );
};

export default Commodities;
