import React from 'react';
import Modal from '@mui/material/Modal';
import RowWithIcon from 'shared-components/CompanyInfoComponents/RowWithIcon';
import icons from 'shared-components/icons';
import { BROWSER_LANGUAGE } from 'shared-components/constants';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    modalBody: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '600px',
        backgroundColor: theme.palette.common['contrastWhite50'],
        boxShadow: '24',
    },
    historyData: {
        padding: '20px',
        display: 'grid',

    },
    historyHeader: {
        background: `${theme.palette.primary[200]} 0% 0% no-repeat padding-box`,
        height: '35px',
        alignItems: 'center',
        opacity: 1,
    },
    historyHeaderText: {
        padding: '8px',
        textAlign: 'left',
        font: 'normal normal 500 14px/17px Roboto',
        letterSpacing: '1.25px',
        color: 'white',
        textTransform: 'uppercase',
        opacity: 1,
    },
    row: {
        display: 'grid',
        gridTemplateColumns: '2fr 1fr 1fr',
        gridGap: '6px',
        alignItems: 'center',
        marginTop: '12px',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.common['contrastGray600'],
        opacity: 1,
    },
    iconContainer: {
        marginTop: '12px',
    },
    infoTitle: {
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: theme.palette.secondary[500],
        opacity: 1,
    },
}));

type Props = {
    auditStatusHistory: {
        auditType: string,
        auditedBy: {
            id: number,
            fullName: string,
            email: string
        },
        auditedOn: string
    }[],
    handleClose: () => void,
    open: boolean,
}

const AuditHistory = ({
    auditStatusHistory = [],
    handleClose,
    open = false,
} : Props) => {
    const classes = useStyles();

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className={classes.modalBody}>
                <div className={classes.historyHeader}>
                    <div className={classes.historyHeaderText}>AUDIT HISTORY</div>
                </div>
                <div className={classes.historyData}>
                    {
                        auditStatusHistory.map((item, index) => {
                            const auditDate = new Date(item.auditedOn).toLocaleDateString(BROWSER_LANGUAGE);

                            return (
                                <div
                                    className={classes.row}
                                    key={`row-${index}`}
                                >
                                    <div className={classes.iconContainer}>
                                        <RowWithIcon
                                            label={
                                                item.auditType === 'REMOTE'
                                                    ? 'Location audited remotely.'
                                                    : 'Location audited on-site.'
                                            }
                                            icon={icons.prize}
                                        />
                                    </div>
                                    <div>
                                        <div className={classes.infoTitle}>Audited On</div>
                                        <div>{auditDate}</div>
                                    </div>
                                    <div>
                                        <div className={classes.infoTitle}>Audited By</div>
                                        <div>{item.auditedBy.fullName}</div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </Modal>
    );
};

export default AuditHistory;
