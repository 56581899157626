import { useEffect, useState } from 'react';
// import useCustomTranslation from 'hooks/useCustomTranslation';
import useCacheContext from 'hooks/useCacheContext';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';

const cacheKey = 'enum';

const useGetEnums = (enumName: string) => {
    const { getCacheValue, setCacheValue } = useCacheContext();
    const { FlexibleRequest: getEnums } = useSecureBackendEndpoints('enum').requests;
    const [enumList, setEnumList] = useState<string[]>([]);

    useEffect(() => {
        const enumsFromCache = getCacheValue<object>(cacheKey) || {};
        const enumListFromCache = enumsFromCache[enumName] || null;

        if (enumListFromCache) {
            setEnumList(enumListFromCache);
        } else {
            (async () => {
                try {
                    const enumListFromRequest = (await getEnums('GET', enumName)).data;

                    setCacheValue(cacheKey, { ...enumsFromCache, [enumName]: enumListFromRequest });
                    setEnumList(enumListFromRequest);
                } catch (error) {
                    setEnumList([]);
                }
            })();
        }
    }, []);

    return enumList;
};

export default useGetEnums;
