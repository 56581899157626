import { FacetDTO } from 'dataTypes/SecureBackend/apiResponse';

export type CustomSort = { columnId: string, direction: 'asc' | 'desc' };
export const trimQuery = (
    filterOptions: { [optionsGroupKey: string]: string[] },
    query: string,
    allFacets: FacetDTO[],
    sort: CustomSort = null,
    currentFacets?: FacetDTO[],
) => {
    const queryObj: object = Object.keys(filterOptions).reduce((acc, key) => {
        const allSelected = allFacets
            ?.find(it => it.filterName === key)
            ?.counts
            ?.filter(it => it.filterValue !== null)?.length === filterOptions?.[key]?.length;

        if (filterOptions[key].length > 0 && !allSelected) {
            const facet = currentFacets?.find(it => it.filterName === key)?.counts;

            const queryOptions = filterOptions[key];

            if (!currentFacets) {
                acc[key] = queryOptions;
            } else if (facet) {
                acc[key] = queryOptions.filter(it => facet?.find(facetItem => facetItem.filterValue === it));
            }
        }
        return acc;
    }, { });

    if (sort && sort?.columnId && sort?.direction) {
        queryObj['sortBy'] = sort.columnId;
        queryObj['asc'] = sort.direction === 'asc';
    }
    if (query) queryObj['q'] = query;
    return queryObj;
};
