import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import TempRange from 'shared-components/TempRange';
import EnergyLevel from 'shared-components/EnergyLevel';
import TemperatureCheckRelative from 'shared-components/TemperatureCheckRelative';

const tableColumns: (t, minMaxTempArray: [number, number])
    => ColumnsType[] = (t = w => w, minMaxTempArray = [0, 0]) => [
        {
            Header: t('ORDER_DETAILS.REFERENCE_PO_NUMBER'),
            accessor: 'customerReference',
            dataType: 'highlightedText',
        },
        {
            Header: t('TRACK_AND_TRACE.MAWB'),
            accessor: 'mawbNumber',
            dataType: 'highlightedText',
            customWidth: 140,
        },
        {
            Header: t('TRACK_AND_TRACE.HAWB'),
            accessor: 'hawbNumber',
            dataType: 'highlightedText',
            customWidth: 140,
        },
        {
            Header: t('COMMON.PACKAGINGS'),
            accessor: (row) => row?.cargo.map(item => item.packaging.serialNumber)?.join('\n\n'),
            dataType: 'highlightedText',
            customCellStyle: {
                whiteSpace: 'pre-wrap',
                maxWidth: '230px',
            },
        },
        {
            Header: t('COMMON.ENERGY'),
            accessor: ({ energyLevelsList }) => energyLevelsList.map((energyLevelCheckResult) => {
                return (<EnergyLevel energyLevel={energyLevelCheckResult} />
                );
            }),
            dataType: 'text',
            // eslint-disable-next-line no-unused-vars
            sortType: (rowA, rowB, id, desc) => {
                const sortedEnergyLevelsA = rowA.original.energyLevelsList.map(Number).sort((a, b) => a - b);
                const sortedEnergyLevelsB = rowB.original.energyLevelsList.map(Number).sort((a, b) => a - b);

                const relevantEnergyA = desc ? sortedEnergyLevelsA[sortedEnergyLevelsA.length - 1]
                    : sortedEnergyLevelsA[0];
                const relevantEnergyB = desc ? sortedEnergyLevelsB[sortedEnergyLevelsB.length - 1]
                    : sortedEnergyLevelsB[0];

                return relevantEnergyA - relevantEnergyB;
            },

            customCellStyle: {
                whiteSpace: 'pre-wrap',
                maxWidth: '230px',
            },
        },
        {
            Header: t('COMMON.RANGE_C'),
            accessor: (row) => <TempRange tempRange={row.temperatureRange} />,
            dataType: 'custom',
            sortType: (rowA, rowB) => {
                const { original: originalA } = rowA || {};
                const { original: originalB } = rowB || {};
                const { temperatureRange: temperatureRangeA = '' } = originalA || {};
                const { temperatureRange: temperatureRangeB = '' } = originalB || {};

                return temperatureRangeA.localeCompare(temperatureRangeB);
            },
        },
        {
            Header: `${t('PRODUCT_RELEASE.TEMPERATURE_CHECK') }`,
            customWidth: '320px',
            accessor: ({ packagings }) => packagings.map((packaging) => {
                const {
                    temperatureStatus,
                    temperatureMax,
                    temperatureRangeMax,
                    temperatureRangeMin,
                    temperatureMin,
                } = packaging.temperatureCheckResult || {};

                return (
                    <div style={{
                        paddingRight: '10px',
                        paddingBottom: '10px',
                    }}
                    >
                        <TemperatureCheckRelative
                            temperatureMax={temperatureMax}
                            temperatureMin={temperatureMin}
                            temperatureRangeMax={temperatureRangeMax}
                            temperatureRangeMin={temperatureRangeMin}
                            temperatureCheckStatus={temperatureStatus}
                            grid={false}
                            bounds={minMaxTempArray}
                        />
                    </div>
                );
            }),
            dataType: 'custom',
        },
        {
            Header: t('COMMON.FROM'),
            accessor: 'originAirport',
            dataType: 'text',
        },
        {
            Header: t('COMMON.TO'),
            accessor: 'destinationAirport',
            dataType: 'text',
        },
        {
            Header: t('TRACK_AND_TRACE.ORDER'),
            accessor: 'externalId',
            dataType: 'highlightedText',
        },
        {
            Header: t('TRACK_AND_TRACE.STATUS'),
            accessor: t('status'),
            dataType: 'text',
        },
    ];

export default tableColumns;
