import React from 'react';
import { ProcessedNotification } from '../lib';

import useStyles from '../Notifications.style';

type Props = {
    notification: ProcessedNotification,
}

const NotificationSubject = ({ notification }: Props) => {
    const classes = useStyles();
    const { hasBeenRead, subject } = notification;

    return (
        <div className={[classes.formControlLabelStyle,
            hasBeenRead ? '' : classes.formControlLabelStyleSelected].join(' ')}
        >
            {subject}

        </div>
    );
};

export default NotificationSubject;
