import useAvailableHeight from 'hooks/useAvailableHeight';
import React, { ReactNode } from 'react';
import useStyles from '../Layout.style';

type Props = {
    children: ReactNode,
}

const PageWithFilter = ({ children = null } : Props) => {
    const availableHeight = useAvailableHeight();
    const classes = useStyles(({ availableHeight }));

    return (
        <div className={classes.pageWithFilter}>
            {children}
        </div>
    );
};

export default PageWithFilter;
