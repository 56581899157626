import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import LastConnect from 'shared-components/LastConnect';
import LatitudeLongitudeWithPlaceInfo from 'shared-components/Table/CellComponents/LatitudeLongitudeWithPlaceInfo';
import { ExtractedLoggerData } from 'TrackAndTrace/Loggers/lib';

const tableColumns: (t) => ColumnsType<ExtractedLoggerData>[] = (t = w => w) => [
    {
        Header: t('COMMON.LOGGER_NUMBER'),
        accessor: 'loggerNumber',
        dataType: 'highlightedText',
        sideFilterKey: 'loggerNumber',
        sideFilterType: 'text',
    },
    {
        id: 'loggerTypeCode',
        Header: t('COMMON.TYPE'),
        accessor: 'loggerFamilyLabel',
        dataType: 'text',
        sideFilterKey: 'loggerFamilyLabel',
        sideFilterType: 'enum',
    },
    // {
    //     Header: t('COMMON.PACKAGING'),
    //     accessor: 'containerSerialNumber',
    //     dataType: 'text',
    //     filterKey: 'containerSerialNumber',
    //     filterType: 'text',
    // },
    // {
    //     Header: t('COMMON.SHIPMENT'),
    //     accessor: 'currentOrderOrderNumber',
    //     dataType: 'custom',
    //     filterKey: 'currentOrderOrderNumber',
    //     filterType: 'enum',
    // },
    {
        id: 'lastMeasuredInfo.temperature',
        Header: t('COMMON.TEMPERATURE'),
        accessor: (row) => row.lastMeasuredTempStr,
        dataType: 'text',
    },
    {
        id: 'lastMeasuredInfo.temperatureGeolocationTimestamp',
        Header: t('TRACK_AND_TRACE.LAST_UPDATED'),
        accessor: (row) => <LastConnect timestamp={row.lastMeasuredTimestamp} />,
        dataType: 'custom',
    },
    {
        id: 'lastMeasuredInfo.geolocation',
        Header: t('TRACK_AND_TRACE.LATITUDE_LONGITUDE'),
        accessor: (row) => (
            <LatitudeLongitudeWithPlaceInfo
                lat={row.lastMeasuredLatitude}
                lng={row.lastMeasuredLongitude}
            />
        ),
        dataType: 'custom',
        sortType(rowA, rowB) {
            const aLat = rowA.original.lastMeasuredLatitude;
            const bLat = rowB.original.lastMeasuredLatitude;
            const aLong = rowA.original.lastMeasuredLongitude;
            const bLong = rowB.original.lastMeasuredLongitude;

            if (aLat === bLat) {
                return aLong - bLong;
            }

            return aLat - bLat;
        },
    },
];

export default tableColumns;
