import { useQuery } from 'react-query';

const useCachedQueryRequest = ({
    key,
    request,
    onCompleted = it => it?.data,
    options = {},
}) => {
    return useQuery({
        queryKey: [
            key, options,
        ],
        queryFn: () => request().then(onCompleted),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        cacheTime: 1000 * 60,
        ...options,
    });
};

export default useCachedQueryRequest;
