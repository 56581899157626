import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { Checkbox, NativeSelect, Input } from '@mui/material';
import {
    NotificationSettings, NotificationChannel, NotificationSettingOverrides,
} from 'MyProfile/components/NotificationChannelSettingsCard/NotificationChannelSettingsCard';
import { Classes } from 'jss';
import useCustomTranslation from 'hooks/useCustomTranslation';

type Props = {
    row: NotificationSettings,
    gridSettings: string,
    classes: Classes,
    categoryTranslation: { [key: string]: string },
    availableChannelStateOptions: {
        value: string,
        label: string,
    }[],
    availableChannels: NotificationChannel[],
    admin: boolean,
    setOverrides: Dispatch<SetStateAction<NotificationSettingOverrides>>,
    overrides: NotificationSettingOverrides,
    editMode: boolean,
}
const NotificationChannelRow = ({
    row,
    gridSettings,
    classes,
    categoryTranslation,
    admin,
    availableChannelStateOptions,
    availableChannels,
    editMode,
    setOverrides,
    overrides,
}: Props) => {
    const {
        channels,
        notificationTemplateId,
        notificationName,
        suggestedState,
        configurable,
        allowedChannels,
        state,
    } = row || {};
    const isMandatory = state === 'MANDATORY' || suggestedState === 'MANDATORY';
    const { t } = useCustomTranslation();

    const computedState = useMemo(() => {
        return overrides?.[notificationTemplateId]?.state
            || suggestedState
            || state;
    }, [overrides, notificationTemplateId, state, suggestedState]);

    return (
        <div
            style={{
                gridTemplateColumns: gridSettings,
            }}
            className={classes.tableRow}
        >
            {
                row && (
                    <>
                        <div className={[
                            classes.tableCell,
                            classes.tableTitleCell,
                            ((isMandatory && !admin) || !configurable) && classes.mandatoryRow,
                        ].join(' ')}
                        >
                            {categoryTranslation[
                                notificationName
                            ] || categoryTranslation[
                            `${
                                notificationTemplateId
                            }_${notificationName}`
                            ] || notificationName}
                        </div>
                        {
                            admin && (
                                <div className={[
                                    classes.tableCell,
                                    classes.tableTitleCell,
                                ].join(' ')}
                                >
                                    {
                                        editMode && (
                                            <NativeSelect
                                                value={computedState}
                                                classes={{
                                                    select: classes.select,
                                                }}
                                                className={classes.selectRoot}
                                                onChange={(ev) => {
                                                    // @ts-ignore
                                                    const state: "MANDATORY" | "OPTIONAL" | "OFF" = ev.target.value;

                                                    setOverrides(prev => ({
                                                        ...prev,
                                                        [notificationTemplateId]: {
                                                            ...prev[notificationTemplateId],
                                                            state,
                                                        },
                                                    }));
                                                }}
                                                disabled={!configurable}
                                                input={<Input disableUnderline />}
                                            >
                                                {
                                                    availableChannelStateOptions.map(
                                                        (
                                                            option,
                                                        ) => <option value={option.value}>{option.label}</option>,
                                                    )
                                                }
                                            </NativeSelect>
                                        )
                                    }
                                    {
                                        !editMode && (
                                            t(`NOTIFICATION_CHANNEL_SETTINGS.${suggestedState || state}`)
                                        )
                                    }
                                </div>
                            )
                        }
                        {
                            availableChannels.map((channel) => {
                                const overriddenChannels = overrides?.[notificationTemplateId]?.channels;
                                const checkedOverride = overriddenChannels?.includes(channel);
                                const checkboxDisabled = !editMode
                                    || computedState === 'OFF'
                                    || !configurable
                                    || (!admin && isMandatory)
                                    || !allowedChannels.includes(channel);

                                return (
                                    <div
                                        className={
                                            [classes.tableCell, classes.channelCell]
                                                .join(' ')
                                        }
                                    >
                                        <Checkbox
                                            size="small"
                                            color="primary"
                                            disabled={checkboxDisabled}
                                            className={[
                                                classes.checkboxStyle,
                                                editMode && classes.editCheckbox,
                                                (!editMode && isMandatory) && classes.blockCheckbox,
                                            ]
                                                .join(' ')}
                                            onChange={(event, checked) => {
                                                setOverrides(prev => {
                                                    const placeholder = row?.channels || [];
                                                    const oldChannels = prev[notificationTemplateId]?.channels
                                                        || placeholder;

                                                    return ({
                                                        ...prev,
                                                        [notificationTemplateId]: {
                                                            ...prev[notificationTemplateId],
                                                            channels: !checked
                                                                ? oldChannels
                                                                    ?.filter(it => it !== channel) : [
                                                                    ...oldChannels,
                                                                    channel,
                                                                ],
                                                        },
                                                    });
                                                });
                                            }}
                                            checked={(checkedOverride !== undefined)
                                                ? checkedOverride : channels.includes(channel)}
                                        />
                                    </div>
                                );
                            })
                        }
                    </>
                )

            }
        </div>
    );
};

export default NotificationChannelRow;
