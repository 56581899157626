import React, {
    useState,
    useEffect, useMemo, useContext,
} from 'react';
import { useParams } from 'react-router-dom';
import { Company, User } from 'dataTypes/SecureBackend/apiResponse';
import { AddUserInfo } from 'dataTypes/SecureBackend/processedData';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import useBreadcrumbsContext from 'hooks/useBreadcrumbsContext';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import CompanyRolesCard from 'Administration/Users/components/CompanyRolesCard';
import UserInfoCard from 'Administration/Users/components/UserInfoCard';
import UserAccountCard from 'Administration/Users/components/UserAccountCard';
import { userRoles } from 'hooks/useHasAccess';
import { LayoutContext } from 'Contexts/LayoutContext';
import {
    extractOnlyNotNullFields,
    isReadyAddUserRequestBody,
} from '../../../lib';
import useStyles from './EditUser.style';

const initialUser: User = {
    businessPhone: '',
    companyId: null,
    companyName: '',
    email: '',
    fullName: {
        firstName: '',
        lastName: '',
    },
    id: '',
    jobCategory: '',
    jobTitle: '',
    mobilePhone: '',
    userRoles: [],
};

type PathParams = {
    userId: string,
}
type Props = {
    admin?: boolean,
}
const EditUser = ({
    admin = false,
}:Props) => {
    const classes = useStyles();
    const { Get: getCompany } = useSecureBackendEndpoints('companies').requests;
    const { Get: getUsers } = useSecureBackendEndpoints(`${admin ? 'admin/' : ''}users`).requests;
    const { FlexibleRequest: updateInfo } = useSecureBackendEndpoints(`${admin ? 'admin/' : ''}users`)
        .statusNotificationRequests;
    const {
        FlexibleRequest: getCompanyUsers,
    } = useSecureBackendEndpoints(`${admin ? 'admin/' : ''}companies`).requests;
    const { userId } = useParams<PathParams>();
    const { setBreadcrumbData } = useBreadcrumbsContext();
    const { setCustomPageTitle } = useContext(LayoutContext);
    const { userInfo: currentUserInfo = null } = useCurrentUserContext();

    const [currentEditedCard, setCurrentEditedCard] = useState<string>(null);
    const [companyResponse, setCompanyResponse] = useState<Company>(null);
    const [userResponse, setUserResponse] = useState<User>(initialUser);
    const [userInfo, setUserInfo] = useState<AddUserInfo>(null);
    const [companyUsers, setCompanyUsers] = useState([]);
    const [roles, setRoles] = useState<string[]>([]);
    const [requiredUpdateData, setRequiredUpdateData] = useState(true);

    const isCurrentUserAdmin = useMemo(
        () => [userRoles.CUSTOMER_ADMIN, userRoles.SKYCELL_ADMIN]
            .some(role => currentUserInfo?.assignedRoles?.includes(role)),
        [currentUserInfo],
    );

    const requestToSaveUserInfo = async () => {
        const {
            status = null,
        } = await updateInfo('PATCH', `/${userId}/overview-info`, extractOnlyNotNullFields(userInfo));

        if (status === 200) {
            setRequiredUpdateData(true);
            setCustomPageTitle(`User Details: ${userInfo.firstName} ${userInfo.lastName}`);
        }
    };

    const requestToSaveRole = async () => {
        const { status = null } = await updateInfo('PATCH', `/${userId}/roles`, roles);

        if (status === 200) {
            setRequiredUpdateData(true);
        }
    };

    useEffect(() => {
        if (userResponse.id === initialUser.id || requiredUpdateData) {
            (async () => {
                try {
                    const userResponse: User = await (await getUsers(userId)).data || null;

                    if (userResponse) {
                        setUserResponse(userResponse);
                        setRoles(userResponse.userRoles);
                        setBreadcrumbData('customBreadcrumb',
                            `${userResponse.fullName.firstName} ${userResponse.fullName.lastName}`);
                        setCustomPageTitle(
                            `User Details: ${userResponse.fullName.firstName} ${userResponse.fullName.lastName}`,
                        );
                    }
                } catch (error) {
                    setUserResponse(initialUser);
                } finally {
                    setRequiredUpdateData(false);
                }
            })();
        }
    }, [requiredUpdateData, setCustomPageTitle]);

    useEffect(() => {
        return () => setCustomPageTitle('');
    }, []);
    useEffect(() => {
        if (userResponse?.companyId) {
            (async () => {
                try {
                    const users = (await getCompanyUsers('GET', `${userResponse.companyId}/users`))?.data;

                    if (users) {
                        setCompanyUsers(users);
                    }
                } catch (error) {
                    setCompanyUsers([]);
                }
            })();
        }
    }, [userResponse?.companyId]);

    useEffect(() => {
        if (userResponse.companyId && !companyResponse) {
            (async () => {
                try {
                    const companyResponse: Company = await (await getCompany(userResponse.companyId)).data || null;

                    if (companyResponse) {
                        setCompanyResponse(companyResponse);
                    }
                } catch (error) {
                    setCompanyResponse(null);
                }
            })();
        }
    }, [userResponse]);

    return (
        <div className={classes.pageContent}>
            <UserInfoCard
                currentBusinessPhone={userResponse.businessPhone}
                currentCompany={companyResponse}
                currentEmail={userResponse.email}
                currentFirstName={userResponse.fullName.firstName}
                currentLastName={userResponse.fullName.lastName}
                currentJobCategory={userResponse.jobCategory}
                currentJobTitle={userResponse.jobTitle}
                currentMobilePhone={userResponse.mobilePhone}
                currentEditedCard={currentEditedCard}
                disabledSaveButton={!isReadyAddUserRequestBody(userInfo)}
                setCurrentEditedCard={setCurrentEditedCard}
                setUserInfo={setUserInfo}
                saveInfo={requestToSaveUserInfo}
                type="editable"
                admin={admin}
            />
            <CompanyRolesCard
                currentRoles={userResponse.userRoles}
                currentEditedCard={currentEditedCard}
                setCurrentEditedCard={setCurrentEditedCard}
                setRoles={setRoles}
                roles={roles}
                saveInfo={requestToSaveRole}
                availableRoles={companyResponse?.companyRoles || []}
                type="editable"
            />
            {
                isCurrentUserAdmin && (
                    <UserAccountCard
                        admin={admin}
                        disableUserButtonDisabled={false}
                        resetPasswordButtonDisabled={false}
                        selectedUserInfo={userResponse}
                        setRequiredUpdateData={setRequiredUpdateData}
                        companyUsers={companyUsers}
                    />
                )
            }
        </div>
    );
};

export default EditUser;
