import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        width: '100%',
    },
    icon: {
        height: '18px',
        marginLeft: '10px',
    },
    iconShort: {
        width: '22px',
        height: '18px',
        marginRight: '10px',
    },
    infoText: {
        color: theme.palette.common.black,
        fontSize: '16px',
    },
    infoTextShort: {
        display: 'flex',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        color: theme.palette.common.black,
        fontSize: '16px',
        alignItems: 'center',
    },
    line: {
        display: 'flex',
        alignItems: 'center',
    },
    wrap: {
        whiteSpace: 'pre-wrap',
    },
    clickable: {
    },
    minWidth: {
        width: 'auto',
    },
    mandatory: {
        background: `${theme.palette.primary[30]} 0% 0% no-repeat padding-box`,
        borderRadius: '3px',
        borderLeft: `6px solid ${theme.palette.primary[200]}`,
        opacity: 1,
    },
    error: {
    // @ts-ignore
        background: `${theme.palette.common.red}2e`,
    },
    shrinkedContainer: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '& $line': {
            width: '100%',
            whiteSpace: 'nowrap',
        },
        '& $editable': {
            width: '90%',
            whiteSpace: 'nowrap',
        },
    },
    autoOverflow: {
        overflow: 'visible',
        '& $line': {
            height: '10px',
            margin: '16px 0',
        },
    },
    smallFont: {
        fontSize: '12px',
        '& $line': {
            fontSize: '12px',
        },
    },
    mediumFont: {
        fontSize: '14px',
        '& $line': {
            fontSize: '14px',
        },
    },
    editable: {
        cursor: 'pointer',
        width: 'max-content',
        transition: '200ms ease',
        '&:hover': {
            color: theme.palette.secondary[500],

            '&:after': {
                background: theme.palette.secondary[500],
            },
        },
        '&:after': {
            content: '""',
            display: 'block',
            background: theme.palette.secondary[600],
            height: '1px',
            width: '100%',
            transform: 'translateY(-2px)',
        },
    },
}));
