import { makeStyles } from '@mui/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const useStyles = makeStyles((theme: SkycellThemeInterface) => ({
    container: {
        borderRadius: 4,
        border: `1px solid ${theme.palette.secondary[50]}`,
        overflow: 'hidden',
        boxShadow: `0px 1px 3px ${theme.palette.secondary[200]}`,
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            display: 'inline-grid',
            gridTemplateColumns: '85px 1fr',
            gridAutoRows: '86px',
        },
    },
    topWrapper: {
        height: '161px',
        background: theme.palette.primary.darkBlue,
        display: 'flex',
        padding: `0 ${theme.spacing(4)}`,
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
            padding: '10px',
        },
    },
    iconWrapper: {
        [theme.breakpoints.down('xs')]: {
            height: '100%',
            width: '100%',
        },

    },
    topWrapperIcon: {
        height: '80px',
        [theme.breakpoints.down('xs')]: {
            height: '100%',
            width: '100%',
        },
    },
    bottomWrapper: {
        height: '110px',
        padding: theme.spacing(2),
        boxSizing: 'border-box',
        // @ts-ignore
        background: theme.palette.common.contrastWhite,
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    title: {
        fontSize: '20px',
        paddingTop: theme.spacing(1),
        color: theme.palette.secondary[900],
        [theme.breakpoints.down('xs')]: {
            paddingTop: '0px',
        },
    },
    info: {
        color: theme.palette.secondary[600],
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
}));

export default useStyles;
