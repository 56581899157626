import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    fieldTitle: {
        margin: '12px 0 2px 0',
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: '#939393',
        opacity: 1,
    },
    filedInfo: {
        textAlign: 'left',
        font: 'normal normal normal 16px/21px Roboto',
        letterSpacing: '0.49px',
        color: '#747474',
        opacity: 1,
    },
    textField: {
        marginTop: '12px',
        width: '100%',
    },
    textFieldText: {
        paddingRight: '0!important',
        height: '21px',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    mandatory: {
        background: '#E0F7FF 0% 0% no-repeat padding-box',
        borderRadius: '3px',
        borderLeft: '6px solid #8BD1E8',
        opacity: 1,
    },
    error: {
        background: '#FFE5E5 0% 0% no-repeat padding-box',
        borderRadius: '3px',
        borderLeft: '6px solid #D44848',
        opacity: 1,
    },
    loading: {
        transition: '200ms ease',
        filter: 'blur(2px)',
    },
    textFieldWithMargin: {
        margin: '12px 0 2px 0',
    },
}));

export default useStyles;
