import React, {
    useEffect,
    useMemo,
    useState, useCallback,
} from 'react';
import { FacetDTO, LoggersDTO } from 'dataTypes/SecureBackend/apiResponse';
import useGetCommonData from 'hooks/useGetCommonData';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { PageWithFilter } from 'Layout';
import TitleWithMapTableSwitch from 'shared-components/TitleWithMapTableSwitch';
import Table from 'shared-components/Table';
import useGetViewTypeFromQuery from 'TrackAndTrace/localHooks/useGetViewTypeFromQuery';

import { useStyles } from 'TrackAndTrace/Loggers/Loggers.style';
import DataPaginationContext from 'Contexts/DataPaginationContext';
import { CustomSort, trimQuery } from 'TrackAndTrace/lib';
import { getOptionsFromFacets } from 'TrackAndTrace/Assets/lib';
import useGetTranslationGroup from 'hooks/useGetTranslationGroup';
import StandalonePagination from 'TrackAndTrace/Assets/components/StandalonePagination';
import {
    extractLoggerData,
    ExtractedLoggerData,
} from './lib';

import LoggerFilterPanel from './components/LoggerFilterPanel';
import MapView from './MapView';
import tableColumns from './tableColumns';

const PAGE_SIZE = 10;

const Loggers = ({ core = 'track-and-trace' }) => {
    const view = useGetViewTypeFromQuery();
    const { t } = useCustomTranslation();
    const [page, setPage] = useState(1);
    const [totalElements, setTotalElements] = useState(0);

    const loggerTypeLabels = useGetTranslationGroup('LOGGER_TYPE_LABEL');
    const [highlightedText, setHighlightedText] = useState('');
    const classes = useStyles();
    const [query, setQuery] = useState('');
    const [
        filterOptions,
        setFilterOptions,
    ] = useState<{ [optionsGroupKey: string]: string[] }>({ loggerFamilyLabel: [] });

    const {
        data: rawInitialFacets,
    } = useGetCommonData<FacetDTO[]>('loggers/search/facets',
        {
            cache: true,
            query: {
                initial: true,
            },
        });

    const [sort, setSort] = useState<CustomSort>(null);

    const updateSort = useCallback((columnId: string, direction: 'asc' | 'desc') => {
        setSort({ columnId, direction });
    }, []);

    const {
        data: rawFacets, status: facetsStatus,
    } = useGetCommonData<FacetDTO[]>('loggers/search/facets',
        {
            cache: true,
            query: {
                ...(trimQuery(filterOptions, query, rawInitialFacets)),
            },
            queryWrap: false,
        });

    const {
        data: rawLoggers, status: loggersStatus,
    } = useGetCommonData<LoggersDTO>('loggers/search',
        {
            cache: true,
            query: {
                page: page - 1,
                pageSize: PAGE_SIZE,
                ...(trimQuery(filterOptions, query, rawInitialFacets, sort, rawFacets)),
            },
            enabled: facetsStatus === 'SUCCESS',
            queryWrap: false,
        });

    const changeHighlightedText = (newHighlightedText) => {
        setHighlightedText(newHighlightedText);
    };

    const [loggers, setLoggers] = useState<ExtractedLoggerData[]>([]);
    const [showLoggerTypes, setShowLoggerTypes] = useState(false);
    const [showLastConnection, setShowLastConnection] = useState(false);
    const [showAirportsInfo, setShowAirportsInfo] = useState(false);
    const [loggersFromPreviewTable, setLoggersFromPreviewTable] = useState<ExtractedLoggerData[]>(null);

    useEffect(() => {
        if (loggersStatus === 'SUCCESS' && rawLoggers?.resultList) {
            const allLoggers = extractLoggerData(rawLoggers?.resultList, loggerTypeLabels);

            setTotalElements(rawLoggers.totalElements);
            setLoggers(allLoggers);
        }
    }, [loggersStatus, rawLoggers, loggerTypeLabels]);

    useEffect(() => {
        if (rawInitialFacets) {
            setFilterOptions({
                loggerTypeCodes: getOptionsFromFacets(rawInitialFacets, 'loggerTypeCodes'),
            });
        }
    }, [rawInitialFacets]);

    const columns = useMemo(() => tableColumns(t), [t]);

    useEffect(() => {
        if ((page - 1) * PAGE_SIZE > totalElements) setPage(Math.ceil(totalElements / PAGE_SIZE));
    }, [page, totalElements]);

    const withoutGeolocationCount = useMemo(() => {
        return loggers?.filter(logger => !logger.lastMeasuredLatitude || !logger.lastMeasuredLongitude)?.length || 0;
    }, [loggers]);

    return (
        <DataPaginationContext.Provider value={{
            page,
            setPage,
            perPage: PAGE_SIZE,
            totalElements,
            paginationLoading: loggersStatus === 'PENDING',
        }}
        >
            <PageWithFilter>
                <LoggerFilterPanel
                    filterOptions={filterOptions}
                    changeHighlightedText={changeHighlightedText}
                    setShowLoggerTypes={setShowLoggerTypes}
                    setShowLastConnection={setShowLastConnection}
                    setShowAirportsInfo={setShowAirportsInfo}
                    setFilterOptions={setFilterOptions}
                    loggersFromPreviewTable={loggersFromPreviewTable}
                    setLoggersFromPreviewTable={setLoggersFromPreviewTable}
                    isTableView={view === 'table'}
                    showLastConnection={showLastConnection}
                    showLoggerTypes={showLoggerTypes}
                    showAirports={showAirportsInfo}
                    setQuery={setQuery}
                    countsLoading={facetsStatus === 'PENDING'}
                    facets={rawFacets}
                    core={core}
                />
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <TitleWithMapTableSwitch
                        isTableView={view === 'table'}
                        pathname={`/${core}/loggers`}
                    />
                    {view === 'table'
                        ? (
                            <Table
                                columns={columns}
                                data={loggers}
                                title={t('MENU_ITEMS.TRACK_AND_TRACE_LOGGERS')}
                                rowLinkTemplate={`/${core}/loggers/:loggerNumber`}
                                maskForHighlight={highlightedText}
                                classNames={{
                                    tableContainerClassName: classes.filteredTable,
                                }}
                                currentSort={sort}
                                onSort={updateSort}
                            />
                        )
                        : (
                            <MapView
                                loggers={loggers.filter(it => it.lastMeasuredLongitude && it.lastMeasuredLatitude)}
                                showAirportsInfo={showAirportsInfo}
                                showLoggerTypes={showLoggerTypes}
                                showLastConnection={showLastConnection}
                                core={core}
                                countOfLoggersWithoutGeolocation={withoutGeolocationCount}
                            />
                        )}
                    {
                        view === 'table' && <StandalonePagination detached entity="Loggers" />
                    }
                </div>
            </PageWithFilter>
        </DataPaginationContext.Provider>
    );
};

export default Loggers;
