import { makeStyles } from '@mui/styles';
import { piCardWidth } from '../../PartsDeliveryInfo';

const useStyles = makeStyles((theme) => ({
    cardTitle: {
        textAlign: 'left',
        font: 'normal normal normal 20px/24px Roboto',
        letterSpacing: '0.26px',
        color: `${theme.palette.common.black}!important`,
        opacity: 1,
        textTransform: 'capitalize',
        marginBottom: '10px',
    },
    row: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            width: piCardWidth,
            minWidth: piCardWidth,
            boxShadow: 'inset 0px 0px 20px 0px #dbdbdb',
            borderRadius: '13px',
            marginRight: '5px',
        },
    },
    fieldTitle: {
        marginTop: '15px',
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: theme.palette.secondary[500],
        opacity: 1,
    },
    fieldInfo: {
        whiteSpace: 'pre-line',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    picture: {
        width: '182px',
        height: '182px',
        marginTop: '12px',
        marginBottom: '12px',
        marginRight: '12px',
        [theme.breakpoints.down('md')]: {
            borderRadius: '10px',
            margin: '10px',
        },

    },
    textData: {
        width: '182px',
    },
    horizontalFlex: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',

    },
}));

export default useStyles;
