import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    fileUploadContainer: {
        width: '100%',
        height: '100%',
    },
    root: {
        background: '#F3F3F3 0% 0% no-repeat padding-box',
        border: '1px dashed #393939',
        opacity: 1,
        cursor: 'pointer',
        textAlign: 'center',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: '#00000099',
        padding: '25px 30px',
        display: 'flex',
        justifyContent: 'center',
        '&:hover p,&:hover svg,& img': {
            opacity: 1,
        },
        '& p, svg': {
            opacity: 0.4,
        },
        '&:hover img': {
            opacity: 0.3,
        },
    },
    noMouseEvent: {
        pointerEvents: 'none',
    },
    iconText: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    },
    hidden: {
        display: 'none',
    },
    onDragOver: {
        '& img': {
            opacity: 0.3,
        },
        '& p, svg': {
            opacity: 1,
        },
    },
    selectedFiles: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '3px',
        textAlign: 'left',
        textDecoration: 'underline',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: '#00000099',
        opacity: 1,
    },
});

export default useStyles;
