import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    questionDetailsData: {
        width: '55vw',
        padding: '20px',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    grid2Columns: {
        marginTop: '8px',
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        gridGap: '6px',
        boxShadow: '0px 1px 1px #00000033',
        paddingTop: '4px',
        paddingBottom: '8px',
    },
    grid3Columns: {
        marginTop: '8px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridGap: '6px',
        boxShadow: '0px 1px 1px #00000033',
    },
    grid4Columns: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr 1fr 2fr 1fr',
        gridGap: '6px',
    },

    questionDetailsHeaderText: {
        padding: '8px',
        textAlign: 'left',
        font: 'normal normal 500 14px/17px Roboto',
        letterSpacing: '1.25px',
        color: theme.palette.common.white,
        textTransform: 'uppercase',
        opacity: 1,
    },
    title: {
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: theme.palette.secondary[500],
        opacity: 1,
    },
    profileIcon: {
        width: '21px',
        height: '21px',
    },
    tooltipIcon: {
        backgroundColor: theme.palette.primary[200],
        color: 'white',
        borderRadius: '50%',
        width: '18px ',
        height: '18px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '7px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    modalBody: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // @ts-ignore
        backgroundColor: theme.palette.common.contrastWhite50,
        boxShadow: '24',
    },
    modalData: {
        padding: '20px',
    },
    modalHeader: {
        background: `${theme.palette.primary[200]} 0% 0% no-repeat padding-box`,
        height: '35px',
        alignItems: 'center',
        opacity: 1,
    },
    modalHeaderText: {
        padding: '8px',
        textAlign: 'left',
        font: 'normal normal 500 14px/17px Roboto',
        letterSpacing: '1.25px',
        color: theme.palette.common.white,
        textTransform: 'uppercase',
        opacity: 1,
    },
    answerType: {
        textTransform: 'capitalize',
    },
    node: {
        height: '10px',
        width: '10px',
        borderRadius: '50%',
        display: 'inline-block',
        transition: 'all 1000ms ease',
        backgroundColor: theme.palette.primary[200],
    },

    divider: {
        height: '25px',
        width: '1px',
        marginLeft: '4.5px',
        marginBottom: '-5px',
        marginTop: '-5px',
        transition: 'all 800ms ease',
        backgroundColor: theme.palette.primary[200],
    },

    li: {
        listStyle: 'none',
        lineHeight: '1px',
    },
    progress: {
        listStyle: 'none',
        marginBottom: '0',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        marginRight: '5px',
    },
}));

export default useStyles;
