import React, {
    Dispatch, SetStateAction, useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { InputAdornment, TextField, useTheme } from '@mui/material';
import Search from '@mui/icons-material/Search';
import useCustomTranslation from 'hooks/useCustomTranslation';
import QuickHelpTooltip from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import PreviewTable from 'TrackAndTrace/commonComponents/PreviewTable';
import useGetCommonData from 'hooks/useGetCommonData';
import { AssetsDTO } from 'dataTypes/SecureBackend/apiResponse';
import { ClearOutlined } from '@mui/icons-material';
import { ExtractedAssetData, extractAssetData } from '../../../lib';
import useStyles from './SearchPreviewTable.style';
import tableColumns from './tableColumns';

type Props = {
    setAssetsFromPreviewTable: Dispatch<SetStateAction<ExtractedAssetData[]>>,
    assetsFromPreviewTable: ExtractedAssetData[],
    changeHighlightedText: (newHighlightedText: any) => void,
    isTableView: boolean,
    setQuery?: Dispatch<SetStateAction<string>>,
    query?: string,
}

const SearchPreview = ({
    changeHighlightedText,
    setAssetsFromPreviewTable,
    assetsFromPreviewTable,
    isTableView,
    setQuery,
    query,
} : Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useCustomTranslation();
    const textRef = useRef(null);
    const [searchString, setSearchString] = useState('');
    const [open, setOpen] = useState(false);
    const [data, setData] = useState<ExtractedAssetData[]>([]);

    const {
        data: rawAssets,
    } = useGetCommonData<AssetsDTO>('assets/search',
        {
            query: {
                page: 0,
                pageSize: 9999,
                q: searchString,
            },
            enabled: searchString?.length > 0 && !isTableView,
        });

    useEffect(() => {
        const toggle = () => setOpen(false);

        document.addEventListener('click', toggle);
        return () => {
            document.removeEventListener('click', toggle);
        };
    }, []);

    const handleFocus = () => {
        setOpen(prev => prev || searchString.length > 0 || assetsFromPreviewTable.length > 0);
    };
    const handleClick = useCallback((e) => e.stopPropagation(), []);

    const searchStringChanged = (event) => {
        if (isTableView) {
            setQuery(event.target.value);
        }
        setSearchString(event.target.value);
        if (event.target.value) {
            changeHighlightedText(event.target.value.replace(/[\\/#!$%^&*;:{}=\-_`~()]/g, ''));
            setOpen(event.target.value.length !== 0);
        } else if (!event.target.value && isTableView) {
            setAssetsFromPreviewTable([]);
        }
    };

    const handleClickShowResults = useCallback(() => {
        setQuery(searchString);
        setOpen(false);
    }, [searchString]);
    const handleClickClearSearch = useCallback(() => {
        setAssetsFromPreviewTable([]);
        setOpen(false);
        setSearchString('');
        setQuery('');
    }, []);

    useEffect(() => {
        if (rawAssets?.resultList) {
            const extractedAssetData = extractAssetData(rawAssets.resultList);

            setData(extractedAssetData);
            if (isTableView) {
                setAssetsFromPreviewTable(extractedAssetData);
            }
        }
    }, [rawAssets]);

    const columns = useMemo(() => tableColumns(t), [t]);

    useEffect(() => {
    }, [isTableView]);

    return (
        <div>
            <QuickHelpTooltip
                tooltipInfo={{
                    text: t('QUICK_HELP.TRACK_AND_TRACE.SEARCH_PACKAGING_BY_SERIAL'),
                    order: 2,
                    position: 'right',
                    uid: 'searchBySerial',
                }}
            >
                <div style={{
                    display: 'inline-flex',
                }}
                >
                    <TextField
                        className={classes.input}
                        id="TextFieldInput"
                        onInput={searchStringChanged}
                        value={isTableView ? query : searchString}
                        placeholder={t('COMMON.SEARCH')}
                        ref={textRef}
                        variant="outlined"
                        onFocus={handleFocus}
                        onClick={handleClick}
                        style={{ flex: 1 }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search style={{ color: theme.palette.common.black }} />
                                </InputAdornment>
                            ),
                            className: classes.input,
                        }}
                    />
                    {
                        isTableView && query && (
                            <div style={{
                                display: 'flex',
                                alignSelf: 'center',
                                justifyContent: 'flex-end',
                                height: '100%',
                                width: '25px',
                            }}
                            >
                                <ClearOutlined
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={handleClickClearSearch}
                                />
                            </div>
                        )
                    }
                </div>
            </QuickHelpTooltip>
            {
                !isTableView && (
                    <PreviewTable
                        data={data}
                        columns={columns}
                        maskForHighlight={searchString}
                        onClickButtonClearSearch={handleClickClearSearch}
                        rowLinkTemplate="/asset-management/assets/readout/:assetNumber"
                        onCardClick={handleClick}
                        onClickButtonShowResults={handleClickShowResults}
                        isCardOpen={open}
                        textRef={textRef}
                    />
                )
            }
        </div>
    );
};

export default SearchPreview;
