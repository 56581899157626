import { SensorDataRequestBody } from 'dataTypes/SecureBackend/processedData';
import useCachedQueryRequest from 'hooks/useCachedQueryRequest';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';

type Props = {
    requestOptions?: SensorDataRequestBody,
    requestType: 'packagings' | 'loggers' | 'assets',
    dateTimeFrom?:string,
    dateTimeTo?:string,
    shipmentNumber?: string,
    cargoId?: number,
    shipmentId?: string,
    serialNumber?: string,
    currentDate?: any,
    isPredictedExcursion?: boolean,
}
const useLoadedInitialData = ({
    shipmentNumber,
    serialNumber,
    cargoId,
    shipmentId,
    isPredictedExcursion = false,
}: Props) => {
    const { FlexibleRequest: getShipmentSensorData } = useSecureBackendEndpoints('').requests;

    const {
        status,
        isError,
        data: rawSensorData = null,
    } = useCachedQueryRequest({
        key: {
            shipmentNumber,
            shipmentId,
            cargoId,
            serialNumber,
            isPredictedExcursion,
        },
        request: () => {
            return isPredictedExcursion
                ? getShipmentSensorData('GET',
                `v2/shipments/${shipmentId}/cargo/${cargoId}/sensor-data-with-prediction`)
                : null;
        },
        onCompleted: response => response?.data || null,
        options: {
            retry: false,
            cacheTime: 9999999,
        },
    });

    return {
        status,
        isError,
        rawSensorData,
    };
};

export default useLoadedInitialData;
