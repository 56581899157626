import React from 'react';
import UncontrolledTooltip from 'shared-components/ControlledTooltip/UncontrolledTooltip';

type Props = {
    color: string,
    title?: string,
    small?: boolean,
}

const Box = ({
    color,
    title = '',
    small = false,
}: Props) => {
    return (
        <UncontrolledTooltip
            description={title}
            placement="bottom"
            shrinked
            position="absolute"
            whiteSpace="nowrap"
        >
            <div
                style={{
                    height: small ? '6px' : '24px',
                    width: small ? '6px' : '24px',
                    display: 'flex',
                    borderRadius: '5px',
                    backgroundColor: color,
                }}
            />
        </UncontrolledTooltip>
    );
};

export default Box;
