import icons from 'shared-components/icons';
import { LatLng, PolylineAndStepStatus } from 'dataTypes/common';
import { GenericPackaging, Packaging } from 'dataTypes/SecureBackend/apiResponse';
import { assetsPath } from 'shared-components/constants';
import moment from 'moment';

export interface ActivePackaging {
    serialNumber: string,
    timestamp: number,
}

export interface ProcessedPackagingData {
    serialNumber: string,
    temperatureRange: string,
    packagingPicture: string,
    type: string,
    customerReference: string,
    currentShipment: string,
    lastMeasuredGeolocationTimestamp: number,
    lastMeasuredLatitude: number,
    lastMeasuredLongitude: number,
    lastMeasuredTempInternal: number,
    lastMeasuredTempAmbient: number,
    lastMeasuredTempInternalTimestamp: number,
    lastMeasuredTempAmbientTimestamp: number,
}

export interface GenericProcessedPackagingData {
    serialNumber: string,
    jypId: string,
    packagingTypeCode: string,
}

export const fetchPackaging = (
    rawData: Packaging, packagingCodeLabels = {}, notInShipment = null,
): ProcessedPackagingData => {
    const {
        serialNumber = '',
        packagingType,
        lastMeasurementInfo,
        currentShipment = null,
    } = rawData;

    const {
        geolocation: lastMeasuredGeolocation = null,
        geolocationTimestamp: lastMeasuredGeolocationTimestamp = null,
        tempInternal: lastMeasuredTempInternal,
        tempAmbient: lastMeasuredTempAmbient,
        tempInternalTimestamp: lastMeasuredTempInternalTimestamp,
        tempAmbientTimestamp: lastMeasuredTempAmbientTimestamp,
    } = lastMeasurementInfo || {};

    const lastMeasuredLatitude = lastMeasuredGeolocation && lastMeasuredGeolocation.latitude
        ? (Math.trunc(lastMeasuredGeolocation.latitude * 10000) / 10000)
        : null;

    const lastMeasuredLongitude = lastMeasuredGeolocation && lastMeasuredGeolocation.longitude
        ? (Math.trunc(lastMeasuredGeolocation.longitude * 10000) / 10000)
        : null;

    const {
        temperatureRange = '',
        type = '',
        packagingPictureUrl: pictureUrl = null,
    } = packagingType;

    return {
        currentShipment: currentShipment || notInShipment,
        customerReference: '',
        type: packagingCodeLabels[type] || type,
        lastMeasuredTempInternal,
        lastMeasuredTempAmbient,
        lastMeasuredTempInternalTimestamp: lastMeasuredTempInternalTimestamp
            ? moment(lastMeasuredTempInternalTimestamp).utc(true).valueOf()
            : null,
        lastMeasuredTempAmbientTimestamp: lastMeasuredTempAmbientTimestamp
            ? moment(lastMeasuredTempAmbientTimestamp).utc(true).valueOf()
            : null,
        lastMeasuredGeolocationTimestamp: new Date(lastMeasuredGeolocationTimestamp).getTime(),
        lastMeasuredLatitude,
        lastMeasuredLongitude,
        packagingPicture: pictureUrl ? `${assetsPath}/assets${pictureUrl}` : icons.default_packaging_icon,
        serialNumber,
        temperatureRange,
    };
};

export const fetchGenericPackaging = (
    rawData: GenericPackaging,
): GenericProcessedPackagingData => {
    const {
        serialNumber = '',
        packagingTypeCode = '',
        jypId = '',
    } = rawData;

    return {
        packagingTypeCode,
        serialNumber,
        jypId,
    };
};

export const fetchPackagingData = (rawData: Packaging[], packagingCodeLabels = {}, notInShipment = null)
: ProcessedPackagingData[] => {
    return rawData.map(item => fetchPackaging(item, packagingCodeLabels, notInShipment));
};

export const ringMarkerIconObj = {
    url: icons.polylinePathOrangeRingIcon,
    scaledSize: {
        width: 10,
        height: 10,
    },
    origin: {
        x: 0,
        y: 0,
    },
    anchor: {
        x: 5,
        y: 5,
    },
};

export const crossMarkerIconObj = {
    url: icons.hex_with_cross,
    scaledSize: {
        width: 20,
        height: 20,
    },
    origin: {
        x: 0,
        y: 0,
    },
    anchor: {
        x: 10,
        y: 10,
    },
};

export const filterByPackagingShipmentStatus = (options: string[], currentShipment): boolean => {
    return (options.includes('IN_SHIPMENT') && currentShipment !== null)
        || (options.includes('NOT_IN_SHIPMENT') && currentShipment === null);
};

export const getMapDataItem = (packaging: ProcessedPackagingData) => {
    return {
        type: 'Feature',
        properties: {
            marker: true,
            data: packaging,
        },
        geometry: {
            type: 'Point',
            coordinates: [
                packaging.lastMeasuredLongitude,
                packaging.lastMeasuredLatitude,
            ],
        },
    };
};

export const getSuperclusterOptions = (mapData, bounds, zoom = 10) => {
    return {
        points: mapData,
        bounds,
        zoom,
        options: {
            radius: 75,
            maxZoom: 5,
            generateId: true,
            map: (item) => {
                return {
                    type: item.data.type,
                    lastMeasuredTempInternalTimestamp: item.data.lastMeasuredTempInternalTimestamp,
                };
            },
            reduce: (data, props) => {
                const { packagingTypes = [], lastMeasuredTimes = [] } = data;

                if (!packagingTypes.includes(props.type)) {
                    data.packagingTypes = [...packagingTypes, props.type];
                }

                if (!lastMeasuredTimes.includes(props.lastMeasuredTempInternalTimestamp)) {
                    data.lastMeasuredTimes = [...lastMeasuredTimes, props.lastMeasuredTempInternalTimestamp];
                }
                return data;
            },
        },
    };
};

export const emptyFunction = () => {};

export const getCombinedShipmentPolylines = (
    shipmentPolylines: PolylineAndStepStatus[] = [],
    polylinesFromSensorData: LatLng[] = [],
    currentMarkerPosition: LatLng,
): PolylineAndStepStatus[] => {
    if (polylinesFromSensorData.length === 0) {
        return shipmentPolylines;
    }
    const packagingTraveledPath: PolylineAndStepStatus[] = polylinesFromSensorData.length === 0
        ? []
        : [{
            stepStatus: 'CLOSED',
            path: [
                ...polylinesFromSensorData,
                currentMarkerPosition,
            ],
        }];

    const plannedShipmentPath = shipmentPolylines.filter(item => item.stepStatus !== 'CLOSED');

    if (plannedShipmentPath.length > 0) {
        const [firstShipmentPolylineLocation] = plannedShipmentPath[0].path;

        const shipmentPolylinePath: PolylineAndStepStatus[] = [
            ...packagingTraveledPath,
            {
                stepStatus: 'NOT_STARTED',
                path: [
                    currentMarkerPosition,
                    firstShipmentPolylineLocation,
                ],
            },
            ...plannedShipmentPath,
        ];

        return shipmentPolylinePath;
    }

    return packagingTraveledPath;
};

const isSamePositions = (first: LatLng, second: LatLng): boolean => {
    return first.lat === second.lat && first.lng === second.lng;
};

export const getPolylineMarkers = (polyline: PolylineAndStepStatus[], currentMarkerPosition: LatLng) => {
    return polyline.reduce((data, item, index) => {
        const { path } = item;

        if (path.length === 0) {
            return data;
        }
        const [firstPosition] = path;
        const lastPosition = path[path.length - 1];

        if (index === 0) {
            return [
                ...(isSamePositions(firstPosition, currentMarkerPosition) ? [] : [firstPosition]),
                ...(isSamePositions(lastPosition, currentMarkerPosition) ? [] : [lastPosition]),
            ];
        } else if (isSamePositions(lastPosition, currentMarkerPosition)) {
            return [
                ...data,
                lastPosition,
            ];
        }

        return data;
    }, []);
};
