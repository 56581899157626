import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    flexContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr',
        },
    },
    minHeight: {
        minHeight: '91px',
    },
    cardTitle: {
        textAlign: 'left',
        font: 'normal normal normal 20px/24px Roboto',
        letterSpacing: '0.26px',
        color: `${theme.palette.common.black}!important`,
        opacity: 1,
        textTransform: 'capitalize',
    },
    fullHeightImplicitly: {
        height: '100%',
    },
    cardContents: {
        display: 'flex',
        flexDirection: 'column',
        height: 'inherit',
    },
    fieldTitle: {
        marginTop: '15px',
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: theme.palette.secondary[500],
        opacity: 1,
    },
    fieldInfoAndIconContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 25px',
    },
    alignLeft: {
        marginLeft: '0',
        marginRight: 'auto',
        width: '100%',
    },
    alignRight: {
        marginRight: '0',
        marginLeft: 'auto',
    },
    fieldInfo: {
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    fieldInfoLineBreak: {
        whiteSpace: 'pre-line',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    icon: {
        height: '13px',
        textAlign: 'right',
        width: '100%',
    },
    iconHolder: {
        display: 'flex',
        justifyContent: 'center',
    },
    contactItem: {
        marginBottom: '5px',
    },
    blockHalfFlex: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        marginRight: '10px',
    },
    blockHalfFlexWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    space: {
        flex: 1,
    },
}));

export default useStyles;
