import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import Table, { useTableSideFilter } from 'shared-components/Table';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { PageWithFilter } from 'Layout';
import tableColumns from './tableColumns';

const CompanyType = (t) => ({
    SERVICE_PROVIDER: t('ADMINISTRATION.SERVICE_PROVIDER'),
    PHARMA: t('ADMINISTRATION.PHARMA'),
});

const Companies = () => {
    const { t } = useCustomTranslation();
    const [companies, setCompanies] = useState([]);
    const [filteredCompanies, setFilteredCompanies] = useState([]);
    const { GetAll: getAllCompanies } = useSecureBackendEndpoints('companies').requests;
    const [maskForHighlight, setMaskForHighlight] = useState('');

    useEffect(() => {
        (async () => {
            try {
                const companyResponse = await getAllCompanies();

                setCompanies(companyResponse.data.resultList || []);
            } catch (error) {
                setCompanies([]);
            }
        })();
    }, []);

    const updatedCompanyInfo = useMemo(() => {
        if (companies.length === 0) {
            return [];
        }

        return companies.map(company => {
            const { address, companyType, ...restInfo } = company;

            return {
                ...restInfo,
                city: address.city,
                companyType: CompanyType(t)[companyType] || companyType,
                countryName: address?.country?.name || '',
            };
        });
    }, [companies]);

    const columns = useMemo(() => tableColumns(t, maskForHighlight)
        .filter(it => !it.hide), [t, maskForHighlight]);
    const {
        component: FilterComponent,
        selectedFilterOptions,
    } = useTableSideFilter({
        columns,
        data: updatedCompanyInfo,
        setFilteredData: setFilteredCompanies,
        externalTextFilterString: maskForHighlight,
    });

    useEffect(() => {
        const newMaskForHighlight = `${(selectedFilterOptions?.name?.[0]?.toString()?.length > 2
            && selectedFilterOptions?.name?.[0])
        || (selectedFilterOptions?.city?.[0]?.toString()?.length > 2
            && selectedFilterOptions?.city?.[0])}`;

        setMaskForHighlight(newMaskForHighlight);
    }, [selectedFilterOptions]);

    return (
        <PageWithFilter>
            {FilterComponent}
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: '10px' }}>
                <Table
                    data={filteredCompanies}
                    columns={columns}
                    maskForHighlight={`${(selectedFilterOptions?.name?.[0]?.toString()?.length > 2
                        && selectedFilterOptions?.name?.[0])
                    || (selectedFilterOptions?.city?.[0]?.toString()?.length > 2
                        && selectedFilterOptions?.city?.[0])}`}
                    tableMaxHeight="100%"
                    title={t('MENU_ITEMS.COMPANIES')}
                    equalColumnWidths
                    rowLinkTemplate="/administration/companies/:id"
                />
            </div>
        </PageWithFilter>
    );
};

export default Companies;
