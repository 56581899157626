import { LaneObject, Milestone } from 'shared-components/dataTypes';
import { ENTITY_TYPE } from 'hooks/useGetEntity/useGetEntity';
import { Classes } from 'jss';
import { InformationCardField } from 'LaneManagement/AddLane/Cards/SummaryCard/fieldMapper';
import { TEMPERATURE_RANGES, INCOTERMS } from 'shared-components/constants';
import React from 'react';
import TempRange from 'shared-components/TempRange';
import { TFunction } from 'react-i18next';

export const bakeLane = (original: LaneObject, replaceWithAddressDTO, getEntity, entityFromAddressId) => {
    const newLaneObject: LaneObject = { ...original };

    newLaneObject.fourthPartyLogistics = {
        serviceProviderSite:
            getEntity(original.fourthPartyLogisticsId, ENTITY_TYPE.SERVICE_PROVIDER_SITE),
    };
    newLaneObject.milestones = original.milestones.map((milestone:Milestone) => ({
        ...milestone,
        pickupLocation: entityFromAddressId(milestone.pickupLocation),
        deliveryLocation: entityFromAddressId(milestone.deliveryLocation),
    }));

    return newLaneObject;
};

export const fieldsLeft: (classes: Classes, t: TFunction, laneId?: any, company?: any, laneData?: LaneObject)
    => InformationCardField[] = (classes, t, laneId = null, company, laneData) => [
        {
            id: 'temperatureRange',
            label: t('COMMON.TEMPERATURE_RANGE'),
            type: 'select',
            readOnly: laneId !== null,
            items: Object.keys(TEMPERATURE_RANGES)
                .map(key => ({
                    value: key,
                    render: <TempRange
                        key={`option_${key}`}
                        tempRange={key}
                    />,
                })),
        },
        ...(laneId ? [{
            id: 'laneNumber',
            label: t('LANE_MANAGEMENT.SUMMARY_CARD.LANE_NAME'),
            type: 'text',
            readOnly: true,
        }] : []),
        {
            id: 'pharmaCompanyId',
            label: t('COMMON.PHARMA_COMPANY'),
            type: 'company',
            mandatory: true,
            request: 'companies',
            readOnly: Boolean(laneId) || Boolean(company.companyType === 'PHARMA'),
            disabled: Boolean(laneId),
            filter: company.companyType !== 'PHARMA' ? {
                companyType: 'PHARMA',
            } : {},
        },
        {
            id: 'fourthPartyLogistics',
            label: t('COMMON.4_PL'),
            type: 'location',
            mandatory: true,
            showOnly: ['serviceProviderSite'],
            filter: company?.companyType === 'PHARMA' ? (laneId ? {
                serviceProviderId: laneData?.fourthPartyLogisticsCompanyId,
            } : {}) : {
                serviceProviderId: company?.id,
            },
            disabled: Boolean(laneId),
        },
        {
            id: 'primaryCarrier',
            label: t('LANE_MANAGEMENT.SUMMARY_CARD.PRIMARY_CARRIER'),
            type: 'readOnlyLocation',
            readOnly: true,
            hide: !laneData.primaryCarrierId,
        },
    ];

export const fieldsRight: (classes: Classes, t: TFunction) => InformationCardField[] = (classes, t) => [
    {
        id: 'comment',
        label: t('COMMON.COMMENT'),
        type: 'text',
    },
    {
        id: 'description',
        label: t('LANE_MANAGEMENT.SUMMARY_CARD.DESCRIPTION'),
        type: 'text',
    },
    {
        id: 'incoterms',
        label: t('LANE_MANAGEMENT.SUMMARY_CARD.INCOTERMS'),
        type: 'select',
        items: Object.keys(INCOTERMS).map(key => ({
            value: key,
            render: INCOTERMS[key],
        })),
    },
    {
        id: 'status',
        label: t('LANE_MANAGEMENT.SUMMARY_CARD.LANE_STATUS'),
        type: 'radio',
        items: [
            {
                value: 'ACTIVE',
                render: 'Active',
            },
            {
                value: 'INACTIVE',
                render: 'Inactive',
            },
        ],
    },
];
