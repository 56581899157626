import checkIfScriptAlreadyLoaded from './checkIfScriptAlreadyLoaded';

const loadGoogleCharts = () => {
    const url = 'https://www.gstatic.com/charts/loader.js';

    if (checkIfScriptAlreadyLoaded(url)) {
        document.getElementById('googleLoader').remove();
    }
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = url;
    script.id = 'googleLoader';
    document.getElementsByTagName('head')[0].appendChild(script);
};

export default loadGoogleCharts;
