import React, { useContext, useCallback, ChangeEvent } from 'react';
import DataPaginationContext from 'Contexts/DataPaginationContext';
import MuiPagination from '@mui/material/Pagination';
import { makeStyles } from '@mui/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import useCustomTranslation from 'hooks/useCustomTranslation';

const useStyles = makeStyles((theme: SkycellThemeInterface) => ({
    wrapper: {
        display: 'flex',
        flex: '1',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        padding: '9px',
    },
    root: {
        '& .MuiPaginationItem-root': {
            color: theme.palette.primary.deepBlue,
            border: `1px solid ${theme.palette.primary.deepBlue}`,
            borderRadius: '0',
            backgroundColor: 'transparent',
            height: '100%',
            width: '20px',
            font: 'normal normal normal 14px/17px Roboto',
            margin: '3px 1.5px',
            padding: '0',
        },
        '& .Mui-selected': {
            backgroundColor: `${theme.palette.primary.deepBlue}!important`,
            color: theme.palette.common.white,
        },
    },
    label: {
        marginRight: '19.5px',
        textAlign: 'left',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        color: 'black',
        opacity: 1,
        display: 'inline-flex',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            flex: '100%',
        },
    },
    labelBlue: {
        color: theme.palette.primary.deepBlue,
        display: 'inline-flex',
        paddingLeft: '4px',
        paddingRight: '4px',
    },
    pagination: {
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        boxSizing: 'border-box',
        boxShadow: '0px 1px 3px #00000033',
        display: 'flex',
        height: '70px',
        padding: '0 9px',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            justifyContent: 'center',
            flexWrap: 'wrap',
            padding: '5px',
            flex: '1',
        },
    },
    wrap: {
        flexWrap: 'wrap',
        justifyContent: 'center',
        '& ul': {
            flexWrap: 'nowrap',
        },
        '& $label': {
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down('lg')]: {
            width: 'min-content',
        },
    },
    muiPagination: {
        margin: '3px 0',
    },
    noPages: {
        opacity: '0.6',
        fontSize: '14px',
    },
    shrink: {
        width: 'min-content',
    },
}));

type Props = {
    detached?: boolean,
    wrap?: boolean,
    entity?: string,
    shrink?: boolean,
}

const StandalonePagination = ({
    detached = false,
    wrap = false,
    shrink = false,
    entity = 'Assets',
}: Props) => {
    const { t } = useCustomTranslation();
    const {
        page,
        setPage,
        totalElements,
        perPage,
        paginationLoading,
    } = useContext(DataPaginationContext);
    const classes = useStyles();
    const handleChange = useCallback((event: ChangeEvent<unknown>, value: number) => {
        setPage(value);
    }, []);

    const pagesCount = Math.ceil(totalElements / perPage);

    const infoLabelFromTo = ` ${(page - 1) * perPage + 1}
        to ${page * perPage < totalElements
        ? page * perPage : totalElements} `;
    const infoLabelTotalNumber = `of ${totalElements}`;

    return (
        <div className={`${detached ? classes.wrapper : ''} ${shrink && classes.shrink}`}>
            <div
                className={[
                    classes.pagination,
                    wrap && classes.wrap,
                    pagesCount === 0 && classes.noPages,
                ].join(' ')} aria-disabled
            >
                {pagesCount > 0 && (
                    <>
                        <div className={classes.label}>
                            Showing
                            {' '}
                            {entity}
                            <div className={classes.labelBlue}>{infoLabelFromTo}</div>
                            {infoLabelTotalNumber}
                        </div>
                        <div className={classes.muiPagination}>
                            <MuiPagination
                                classes={{ root: classes.root }}
                                count={pagesCount}
                                onChange={handleChange}
                                inputMode="numeric"
                                size="small"
                                variant="outlined"
                                shape="rounded"
                                page={page || 1}
                                disabled={paginationLoading}
                            />
                        </div>
                    </>
                )}
                {
                    pagesCount === 0 && t('COMMON.NO_OPTIONS')
                }
            </div>
        </div>

    );
};

export default StandalonePagination;
