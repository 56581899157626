import useAvailableHeight from 'hooks/useAvailableHeight';
import React, {
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useGetAirports, { getAirportsInPassedBoundsZone } from 'hooks/useGetAirports';
import { MAP_CONFIG } from 'shared-components/constants';
import { QuickHelpContext } from 'Contexts/QuickHelpContext/QuickHelpContext';
import QuickHelpTooltip from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import AirportMarker from 'TrackAndTrace/commonComponents/AirportMarker';
import TooltipPolygonMarker from 'TrackAndTrace/commonComponents/TooltipPolygonMarker';
import AirportInfoTooltip from 'TrackAndTrace/Tooltips/AirportInfoTooltip';
import { ActiveAirportInfo } from 'TrackAndTrace/Tooltips/dataTypes';

import { Geofence } from 'shared-components/CompanyInfoComponents/AdministrationCompany/GeofencesCard/GeofencesCard';
import LegendPanel from 'TrackAndTrace/Assets/components/LegendPanel';
import { joinLoggers } from 'shared-components/common';
import AssetMarker from 'TrackAndTrace/Assets/components/AssetMarker';
import AssetTooltip from 'TrackAndTrace/Assets/components/AssetTooltip';
import useGetCommonData from 'hooks/useGetCommonData';
import { GatewayInfoDTO } from 'dataTypes/SecureBackend/apiResponse';
import CustomPolygon from '../components/CustomPolygon';

import {
    ActiveAsset,
    ExtractedAssetData,
} from '../lib';

import BaseMap from '../../commonComponents/BaseMap';

type Props = {
    showAirportsInfo: boolean,
    showLastConnection: boolean,
    assets: ExtractedAssetData[],
    geofences: Geofence[]
}
type AssetGroups = {
    [key: string]: ExtractedAssetData[],
};

const MapView = ({
    showLastConnection,
    showAirportsInfo,
    assets,
    geofences = [],
}: Props) => {
    const { t } = useCustomTranslation();
    const { enableTooltips } = useContext(QuickHelpContext);
    const [gMap, setGMap] = useState(null);
    const [bounds, setBounds] = useState<number[]>([]);
    const [zoom, setZoom] = useState(MAP_CONFIG.zoom);
    const [activeAsset, setActiveAsset] = useState<ActiveAsset>({
        assetNumber: null,
        timestamp: Date.now(),
    });
    const { data: gateways = [] } = useGetCommonData<GatewayInfoDTO[]>('gateways', {
        postProcess: data => data.resultList,
    });
    const availableSpace = useAvailableHeight();
    const [mapContainerRef, setMapContainerRef] = useState(null);
    const [activeAirportInfo, setActiveAirportInfo] = useState<ActiveAirportInfo>({
        code: null,
        timestamp: Date.now(),
    });
    const [hoveredGroupGeoKey, setHoveredGroupGeoKey] = useState<string>(null);

    const airports = getAirportsInPassedBoundsZone(useGetAirports(), bounds);

    const closeActiveTooltip = useCallback(() => {
        const delay = 700;
        const now = Date.now();

        if (activeAsset.assetNumber !== null && activeAsset.timestamp + delay < now) {
            setActiveAsset({
                assetNumber: null,
                timestamp: now,
            });
        }
        if (activeAirportInfo.code !== null && activeAirportInfo.timestamp + delay < now) {
            setActiveAirportInfo({
                code: null,
                timestamp: now,
            });
        }
    }, [activeAsset, activeAirportInfo]);

    const cancelAirportInfoTooltipClosing = useCallback(() => {
        setActiveAirportInfo(prev => ({
            code: prev.code,
            timestamp: Date.now(),
        }));
    }, []);

    const showedAirportInfo = useMemo(() => {
        if (airports.length === 0 || activeAirportInfo === null || activeAirportInfo.code === null) {
            return null;
        }

        const airport = airports.find(item => item.code === activeAirportInfo.code) || null;

        if (airport) {
            return airport;
        }
        return null;
    }, [activeAirportInfo, airports]);

    const groupedAssets = useMemo<AssetGroups>(() => joinLoggers<ExtractedAssetData>(
        assets.filter(({ lastMeasuredLatitude }) => lastMeasuredLatitude),
    ), [assets]);

    const withoutGeolocationCount = useMemo(() => assets
        .filter(({ lastMeasuredLatitude }) => !lastMeasuredLatitude).length, [assets]);

    return (
        <div>
            <QuickHelpTooltip
                tooltipInfo={{
                    order: 5,
                    text: t('QUICK_HELP.TRACK_AND_TRACE.PACKAGING_MAP'),
                    backgroundOpacity: 0.2,
                    childOffsetPercent: [50, 50],
                    customRectSize: [60, 40],
                    offsetPx: [60, 40],
                    explicitChildRef: mapContainerRef,
                    uid: 'assetsMapTooltip',
                }}
            >
                <BaseMap
                    onContainerRefLoad={(ref) => {
                        setMapContainerRef(ref);
                    }}
                    mapContainerStyle={{
                        width: '100%',
                        height: `calc(${availableSpace} - 60px)`,
                    }}
                    gMap={gMap}
                    setGMap={setGMap}
                    setBounds={setBounds}
                    setZoom={setZoom}
                    onClick={closeActiveTooltip}
                >
                    {
                        enableTooltips && (
                            <TooltipPolygonMarker itemType="Asset" />
                        )
                    }
                    {
                        Object.keys(groupedAssets).map((geoKey) => (
                            <AssetMarker
                                key={geoKey}
                                assetGroup={groupedAssets[geoKey]}
                                showLastConnection={showLastConnection}
                                onHover={() => setHoveredGroupGeoKey(geoKey)}
                                isSelected={hoveredGroupGeoKey === geoKey}
                            />
                        ))
                    }

                    {
                        geofences.map(({ name, coordinates }, index) => {
                            const latLngCoordinates = coordinates
                                .map(({ latitude: lat, longitude: lng }) => ({ lat, lng }));

                            return (
                                <CustomPolygon
                                    isNameVisible={zoom > 15}
                                    key={index}
                                    name={name}
                                    paths={latLngCoordinates}
                                    position={latLngCoordinates[0]}
                                />
                            );
                        })
                    }
                    {
                        showAirportsInfo && airports.map(airport => {
                            const handleClickAirportsInfo = () => {
                                setActiveAirportInfo(prev => ({
                                    code: prev.code === airport.code
                                        ? null
                                        : airport.code,
                                    timestamp: Date.now(),
                                }));
                            };

                            return (
                                <AirportMarker
                                    onClick={handleClickAirportsInfo}
                                    isOpen={activeAirportInfo.code === airport.code}
                                    airportInfo={airport}
                                    key={airport.code}
                                />
                            );
                        })
                    }
                    {
                        showedAirportInfo !== null && (
                            <AirportInfoTooltip
                                airportInfo={showedAirportInfo}
                                cancelTooltipClosing={cancelAirportInfoTooltipClosing}
                            />
                        )
                    }
                    {
                        hoveredGroupGeoKey && (
                            <AssetTooltip
                                assetGroup={groupedAssets[hoveredGroupGeoKey]}
                                onMouseLeave={() => setHoveredGroupGeoKey(null)}
                                gateways={gateways}
                            />
                        )
                    }

                    <LegendPanel withoutGeolocationCount={withoutGeolocationCount} />
                </BaseMap>
            </QuickHelpTooltip>
        </div>
    );
};

export default MapView;
