import React from 'react';
import icons from 'shared-components/icons';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useStyles from './OfferingsCard.style';

type Props = {
    currentCommodities: string[],
    currentServices: string[],
}

const ReadMode = ({
    currentCommodities = [],
    currentServices = [],
}: Props) => {
    const classes = useStyles();
    const { t } = useCustomTranslation();

    return (
        <div className={classes.offeringsCardContainer}>
            <div className={classes.title}>{t('COMMON.SERVICES')}</div>
            {
                currentServices.length === 0
                    ? (
                        <div>{t('SERVICE_PROVIDERS.NO_SERVICES_AVAILABLE')}</div>
                    )
                    : (
                        currentServices.map(service => {
                            return (
                                <div className={classes.offeringItemContainer} key={service}>
                                    <div className={classes.offeringIconContainer}>
                                        <img
                                            alt={service}
                                            src={icons[service.toLowerCase()]}
                                        />
                                    </div>
                                    <div>{t(`SERVICE_PROVIDERS.${service}`)}</div>
                                </div>
                            );
                        })
                    )
            }
            <div className={classes.title}>{t('COMMON.COMMODITIES')}</div>
            {
                currentCommodities.length === 0
                    ? (
                        <div>{t('SERVICE_PROVIDERS.NO_COMMODITIES_SUPPORTED')}</div>
                    )
                    : (
                        currentCommodities.map(commodity => {
                            return (
                                <div className={classes.offeringItemContainer} key={commodity}>
                                    <div className={classes.offeringIconContainer}>
                                        <img
                                            alt={commodity}
                                            src={icons[commodity.toLowerCase()]}
                                        />
                                    </div>
                                    <div>
                                        {t(`SERVICE_PROVIDERS.${commodity}`)}
                                    </div>
                                </div>
                            );
                        })
                    )
            }
        </div>
    );
};

export default ReadMode;
