import { LayoutContext, Tab } from 'Contexts/LayoutContext';
import React, {
    ReactNode, useEffect, useRef,
    // useContext,
    useState,
} from 'react';
import Sidebar from 'Sidebar';
import StatusMessages from 'StatusMessages';
// import Breadcrumbs from 'shared-components/Breadcrumbs';
// import icons from 'shared-components/icons';
// import { QuickHelpContext } from 'Contexts/QuickHelpContext/QuickHelpContext';
// import useCustomTranslation from 'hooks/useCustomTranslation';
import UserInfo from 'Sidebar/components/UserInfo';
import { getSidebarIsOpenLocalStorage, setLocalStorageValue } from 'utils/localStorageUtils';
import { SIDEBAR_IS_OPEN_KEY_LS } from 'dataTypes/common';
import AppBarButton from './AppBarButton';
// import SupportModal from './SupportModal';
import useStyles from './CommonPage.style';

export type PageDetails = {
    link: string,
    title: string,
    tabs?: Tab[],
} | null

type Props = {
    children?: ReactNode,
    currentPage: PageDetails,
}

const CommonPage = ({
    children = null,
    currentPage,
} : Props) => {
    const classes = useStyles();
    const isMobileDevice = window.screen.width < 820;
    const sidebarIsOpenLS = getSidebarIsOpenLocalStorage();
    const [sidebarIsOpen, setSidebarIsOpen] = useState(!isMobileDevice && sidebarIsOpenLS);
    const appBarRef = useRef(null);
    const [customPageTitle, setCustomPageTitle] = useState<string>('');
    const [availableSpace, setAvailableSpace] = useState<string>(`${window.innerHeight}px`);
    // const [supportIsOpen, setSupportState] = useState(false);
    // const { t } = useCustomTranslation();
    // const {
    //     setEnableTooltips,
    //     setEnableGuide,
    //     hasUserGuide,
    //     quickHelpTooltipsBag,
    // } = useContext(QuickHelpContext);
    const [customMenuTabs, setCustomMenuTabs] = useState<PageDetails['tabs']>([]);

    useEffect(() => {
        if (appBarRef.current) {
            const onResize = () => {
                setAvailableSpace(`${window.innerHeight - appBarRef?.current?.scrollHeight}px`);
            };

            onResize();
            window.addEventListener('resize', onResize);

            return () => window.removeEventListener('resize', onResize);
        }
    }, [appBarRef.current]);
    const iframe = document.querySelector('#jsd-widget');

    // @ts-ignore
    const iframeContent = iframe?.contentDocument;

    // eslint-disable-next-line no-unused-vars
    const button = iframeContent?.querySelector('#help-button');

    // @ts-ignore
    // const handleSupportClick = () => {
    //     // button?.click();
    //     // setSupportState(prev => !prev);
    // };

    const setSidebarState = (sidebarState: boolean) => {
        setSidebarIsOpen(sidebarState);
        setLocalStorageValue(SIDEBAR_IS_OPEN_KEY_LS, sidebarState);
    };

    return (
        <div className={classes.standardBody}>
            <Sidebar
                currentLink={currentPage.link}
                setSidebarState={setSidebarState}
                sidebarIsOpen={sidebarIsOpen}
            />
            <div className={classes.standardBodyContainer}>
                <div
                    ref={appBarRef}
                    className={
                        sidebarIsOpen
                            ? classes.appBarStyleOpenedSideBar
                            : classes.appBarStyle
                    }
                >
                    <div className={classes.appBarText}>{customPageTitle || currentPage.title}</div>
                    <div
                        className={[
                            classes.headerLeftButtons,
                            (currentPage.tabs || []).concat(customMenuTabs)?.length === 1
                                ? classes.headerLeftButtonsOneTab
                                : '',
                        ].join(' ')}
                    >
                        {
                            (currentPage.tabs || []).concat(customMenuTabs)
                                .filter(tab => !tab.hide)
                                .map((submenu) => {
                                    return (
                                        <AppBarButton
                                            active={submenu.activePage}
                                            iconSrc={submenu.iconSrc}
                                            key={`${submenu.link || '_'}_${submenu.title}`}
                                            onClick={submenu.onClick || null}
                                            link={submenu.link}
                                            title={submenu.title}
                                            iconStyle={submenu.iconStyle || {}}
                                            isOneButton={(currentPage.tabs || []).concat(customMenuTabs)?.length === 1}
                                        />
                                    );
                                })
                        }
                    </div>
                    <div className={classes.headerRightButtons}>
                        <UserInfo />

                        {/* <div
                            className={classes.topRightButton}
                            onClick={handleSupportClick}
                        >
                            <img src={icons.support_icon} alt="support" />
                            <span>{t('COMMON.SUPPORT')}</span>
                        </div>
                        <SupportModal
                            open={supportIsOpen}
                            setOpen={setSupportState}
                            content={iframe?.children}
                        />
                        {
                            quickHelpTooltipsBag.length !== 0 && (
                                <div
                                    className={classes.topRightButton}
                                    onClick={() => setEnableTooltips(prev => !prev)}
                                >
                                    <img src={icons.quick_help_icon} alt="quickHelp" />
                                    <span>{t('COMMON.QUICK_HELP')}</span>
                                </div>
                            )
                        }
                        {
                            hasUserGuide && (
                                <div
                                    className={classes.topRightButton}
                                    onClick={() => setEnableGuide(prev => !prev)}
                                >
                                    <img src={icons.user_guide_icon} alt="userGuide" />
                                    <span>{t('COMMON.USER_GUIDE')}</span>
                                </div>
                            )
                        } */}
                    </div>
                </div>
                <div
                    id="content"
                    className={
                        sidebarIsOpen
                            ? classes.bodyContentBlockWithOpenedSideBar
                            : classes.bodyContentBlock
                    }
                >
                    {/* <Breadcrumbs /> */}
                    <LayoutContext.Provider value={{
                        availableHeight: availableSpace,
                        setMenuTabs: setCustomMenuTabs,
                        menuTabs: customMenuTabs,
                        setCustomPageTitle,
                    }}
                    >
                        { children }
                    </LayoutContext.Provider>
                    <StatusMessages />
                </div>
            </div>
        </div>
    );

    /*
    return (
        isMobileDevice
            ? (
                <div>
                    <div className={classes.mobileAppBar}>
                        <div className={classes.appBarText}>{currentPage.title}</div>
                        <IconButton
                            className={classes.mobileBurger}
                            onClick={handleClick}
                        >
                            { sidebarIsOpen ? <CloseIcon /> : <MenuIcon /> }
                        </IconButton>
                    </div>
                    <div className={classes.mobileSidebar}>
                        <Sidebar
                            anchor="top"
                            currentLink={currentPage.link}
                            setSidebarState={setSidebarState}
                            sidebarIsOpen={sidebarIsOpen}
                        />
                    </div>
                    <div className={classes.mobileBody}>
                        { children }
                        <StatusMessages />
                    </div>
                </div>
            )
            : (
                <div className={classes.standardBody}>
                    <Sidebar
                        currentLink={currentPage.link}
                        setSidebarState={setSidebarState}
                        sidebarIsOpen={sidebarIsOpen}
                    />
                    <div className={classes.standardBodyContainer}>
                        <div
                            className={
                                sidebarIsOpen
                                    ? classes.appBarStyleOpenedSideBar
                                    : classes.appBarStyle
                            }
                        >
                            <div className={classes.appBarText}>{currentPage.title}</div>
                            {
                                currentPage.tabs.map((submenu) => {
                                    return (
                                        <AppBarButton
                                            active={submenu.activePage}
                                            iconSrc={submenu.iconSrc}
                                            key={submenu.link}
                                            link={submenu.link}
                                            title={submenu.title}
                                        />
                                    );
                                })
                            }
                        </div>
                        <div
                            id="content"
                            className={
                                sidebarIsOpen
                                    ? classes.bodyContentBlockWithOpenedSideBar
                                    : classes.bodyContentBlock
                            }
                        >
                            <Breadcrumbs />
                            { children }
                            <StatusMessages />
                        </div>
                    </div>
                </div>
            )
    );
    */
};

export default CommonPage;
