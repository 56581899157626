import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { TEMPERATURE_RANGES_VALUES } from 'shared-components/constants';

import { makeStyles } from '@mui/styles';
import useCustomTranslation from 'hooks/useCustomTranslation';
import QuickHelpTooltip, { QuickHelpTooltipInfo } from 'Contexts/QuickHelpContext/QuickHelpTooltip/QuickHelpTooltip';

const useStyles = makeStyles({
    tempRangeSelectorContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'left',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        color: '#6E8192',
        opacity: 1,
    },
    tempRangeTitle: {
        marginRight: '10px',
    },
    select: {
        paddingTop: '0!important',
        paddingBottom: '0!important',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: '#747474',
        opacity: 1,
    },
});

const ranges = {
    None: {
        tempRangeMin: null,
        tempRangeMax: null,
    },
    ...TEMPERATURE_RANGES_VALUES,
};

type Props = {
    setValue?: (range) => void,
    tooltipInfo: QuickHelpTooltipInfo,
}

const TemperatureRangeSelector = ({
    setValue,
    tooltipInfo = { text: null, uid: null },
} : Props) => {
    const classes = useStyles();
    const { t } = useCustomTranslation();

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const removeFocus = () => {
        setTimeout(() => {
            // @ts-ignore
            document.activeElement.blur();
        }, 0);
    };

    return (
        <QuickHelpTooltip tooltipInfo={tooltipInfo}>
            <div className={classes.tempRangeSelectorContainer}>
                <div className={classes.tempRangeTitle}>{t('COMMON.TEMPERATURE_RANGE')}</div>
                <Select
                    labelId="selectTempRange"
                    id="selectTempRange"
                    onChange={handleChange}
                    variant="standard"
                    classes={{ standard: classes.select }}
                    style={{
                        padding: '3px 6px 0px 6px',
                        textAlign: 'left',
                        font: 'normal normal normal 14px Roboto',
                        letterSpacing: '0.49px',
                        color: '#747474',
                        opacity: 1,
                    }}
                    onClose={removeFocus}
                    defaultValue={ranges.None}
                >
                    {
                        Object.keys(ranges).map((range) => {
                            return (
                                <MenuItem
                                    key={range}
                                    style={{
                                        padding: '3px 9px 3px 9px',
                                        textAlign: 'left',
                                        font: 'normal normal normal 14px Roboto',
                                        letterSpacing: '0.49px',
                                        color: '#747474',
                                        opacity: 1,
                                    }}
                                    value={ranges[range]}
                                >
                                    {range}
                                </MenuItem>
                            );
                        })
                    }
                </Select>
            </div>
        </QuickHelpTooltip>
    );
};

export default TemperatureRangeSelector;
