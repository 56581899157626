import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import icons from 'shared-components/icons';
import ParentCompany from 'shared-components/CompanyInfoComponents/ParentCompany';
import { highlight } from 'utils/stringTool';

const tableColumns: (
    arg: ((t:string) => string),
    maskForHighlight?: string) => ColumnsType[] = (t = w => w, maskForHighlight) => [
        {
            Header: t('COMMON.COMPANY'),
            accessor: (row) => {
                return (
                    <ParentCompany
                        companyName={highlight(row.name, maskForHighlight)}
                        srcImage={row.logoImagePath || icons.placeholder_company_logo}
                    />
                );
            },
            dataType: 'custom',
            sideFilterKey: 'name',
            sideFilterType: 'text',
            sortType(rowA, rowB) {
                const a = rowA.original.serviceProviderName;
                const b = rowB.original.serviceProviderName;

                return a.localeCompare(b);
            },
        },
        {
            Header: t('COMMON.COMPANY_TYPE'),
            accessor: 'companyType',
            dataType: 'text',
            sideFilterKey: 'companyType',
            sideFilterType: 'enum',
        },
        {
            Header: t('COMMON.CITY'),
            accessor: 'city',
            dataType: 'highlightedText',
            sideFilterKey: 'city',
            sideFilterType: 'text',
        },
        {
            Header: t('COMMON.COUNTRY'),
            accessor: 'countryName',
            dataType: 'text',
            sideFilterKey: 'countryName',
            sideFilterType: 'enum',
        },
    ];

export default tableColumns;
