import React, {
    useEffect,
    useState,
} from 'react';
import Button from '@mui/material/Button';
import MapIcon from '@mui/icons-material/Map';
import icons from 'shared-components/icons';
import { Geolocation } from 'shared-components/dataTypes';

import useCustomTranslation from 'hooks/useCustomTranslation';
import AddGeolocation from './AddGeolocation';
import Coordinates from './Coordinates';
import useStyles from '../GoogleAddress.style';

type Props = {
    geolocation: Geolocation,
    message: string,
    setGeolocation: (geolocation: Geolocation) => void,
}

const GeolocationInfo = ({
    geolocation = { latitude: null, longitude: null },
    message,
    setGeolocation,
}: Props) => {
    const classes = useStyles();
    const [openAddGeolocation, setopenAddGeolocation] = useState(false);
    const [latitude, setLatitude] = useState(geolocation?.latitude || null);
    const [longitude, setLongitude] = useState(geolocation?.longitude || null);
    const { t } = useCustomTranslation();
    const handleOpenAddGeolocation = () => setopenAddGeolocation(true);

    useEffect(() => {
        if (latitude && longitude) {
            setGeolocation({
                latitude,
                longitude,
            });
        }
    }, [latitude, longitude]);

    useEffect(() => {
        setLatitude(geolocation.latitude);
        setLongitude(geolocation.longitude);
    }, [
        geolocation?.latitude,
        geolocation?.longitude,
    ]);

    return (
        <div className={`${classes.contentData} ${classes.contentDataMargin}`}>
            <div className={classes.flexContent}>
                <img
                    alt="info icon"
                    className={classes.infoIcon}
                    src={icons.info_blue}
                />
                <div className={classes.infoText}>{ message }</div>
            </div>
            <div className={`${classes.flexContent} ${classes.mapButtonContainer}`}>
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.mapButton}
                    onClick={handleOpenAddGeolocation}
                >
                    <MapIcon />
                    {t('MY_COMPANY.MAP')}
                </Button>
                <Coordinates
                    type={t('COMMON.LATITUDE')}
                    setValue={setLatitude}
                    value={latitude}
                />
                <Coordinates
                    type={t('COMMON.LONGITUDE')}
                    setValue={setLongitude}
                    value={longitude}
                />
            </div>
            <AddGeolocation
                open={openAddGeolocation}
                setOpen={setopenAddGeolocation}
                geolocation={geolocation}
                setGeolocation={setGeolocation}
            />
        </div>
    );
};

export default GeolocationInfo;
