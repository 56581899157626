import React, { useContext, useEffect, useState } from 'react';
import CardWrapper from 'LaneManagement/AddLane/StepsWrapper/CardWrapper';
import CommonStepTable from 'LaneManagement/AddLane/StepsWrapper/CommonStepTable';
import LaneCardsContext from 'Contexts/LaneCardsContext';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { Milestone } from 'shared-components/dataTypes';
import { locationsTableDefaults, tableMetaData } from './tableMetaData';

type Props = {
    stepData: Milestone,
    onChange: Function,
    index?: number
}

const Flight = ({
    stepData,
    onChange,
    ...rest
}: Props) => {
    const [hasPrecedingFlight, setHasPrecedingFlight] = useState(null);
    const { steps } = useContext(LaneCardsContext);

    const { t } = useCustomTranslation();

    useEffect(() => {
        const { index: stepIndex } = rest;

        setHasPrecedingFlight(!(stepIndex === 0) && steps[stepIndex - 1]?.type === 'AIR');
    }, []);

    return hasPrecedingFlight !== null && (
        <CardWrapper stepData={stepData} onChange={onChange} {...rest}>
            <CommonStepTable
                tableData={tableMetaData(t)}
                tableDefaults={locationsTableDefaults}
            />
            <div />
        </CardWrapper>
    );
};

export default Flight;
