import React, {
    useEffect,
    useState, useContext,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TimeRange } from 'dataTypes/common';
import { SensorDataRequestBody } from 'dataTypes/SecureBackend/processedData';
import useCacheContext from 'hooks/useCacheContext';
import useLoadSensorData from 'SensorDataCommon/hooks/useLoadSensorData';
import parseQueryParams from 'utils/parsePathQueries';
import { LayoutContext } from 'Contexts/LayoutContext';
import useCustomTranslation from 'hooks/useCustomTranslation';
import {
    PathParams,
    EntitySelectorItem,
    initialEntitySelectorItem,
} from './dataTypes';
import {
    initializeTimeRange,
    initializeRequestBody,
} from './lib';
import AssetSensorData from './AssetSensorData';

const AssetSensorDataContainer = () => {
    const history = useHistory();
    const { getCacheValue } = useCacheContext();
    const {
        entityNumber: entityNumberInPath = '',
    } = useParams<PathParams>();
    const queryParams = parseQueryParams(history.location.search);

    const [isCurrentTime, setIsCurrentTime] = useState(true);
    const [firstEntry, setFirstEntry] = useState(true);
    const [requestBody, setRequestBody] = useState<SensorDataRequestBody>(initializeRequestBody(queryParams));
    const [timeRange, setTimeRange] = useState<TimeRange>(initializeTimeRange(true, queryParams));
    const [entitySelectorItem, setEntitySelectorItem] = useState<EntitySelectorItem>(
        !entityNumberInPath ? (getCacheValue('entitySelectorItem') || initialEntitySelectorItem)
            : initialEntitySelectorItem,
    );
    const { t } = useCustomTranslation();

    const {
        setMenuTabs,
        setCustomPageTitle,
    } = useContext(LayoutContext);

    useEffect(() => {
        setMenuTabs([
            {
                title: t('COMMON.READOUT'),
                link: `/asset-management/assets/readout/${entitySelectorItem.entityNumber}`,
                iconSrc: '',
                activePage: true,
            },
            {
                title: t('SENSOR_DATA.LOGGER_PAIRINGS'),
                link: `/asset-management/assets/pairings/${entitySelectorItem.entityNumber}`,
                iconSrc: '',
                activePage: false,
            },
        ]);
        setCustomPageTitle(t('MENU_ITEMS.ASSET_DETAILS'));
        return () => {
            setCustomPageTitle('');
            setMenuTabs([]);
        };
    }, [entitySelectorItem]);

    const {
        isFetchComplete,
        assetList,
        assetRequestStatus,
    } = useLoadSensorData(entitySelectorItem, 'assets');

    useEffect(() => {
        if (
            entityNumberInPath
            && entitySelectorItem.entityNumber !== entityNumberInPath
        ) {
            if (assetList.length > 0) {
                const asset = assetList.find(item => item.entityNumber === entityNumberInPath) || null;

                if (asset) {
                    setEntitySelectorItem(asset);
                }
            }
        }
    }, [
        assetList,
    ]);

    useEffect(() => {
        if (!firstEntry && isFetchComplete) {
            history.push({
                pathname: `/assets/readout/${entitySelectorItem?.entityNumber || ''}`,
            });
        }
    }, [
        entitySelectorItem,
        requestBody,
        timeRange,
        isFetchComplete,
    ]);

    useEffect(() => {
        if (entitySelectorItem?.entityNumber) {
            if (firstEntry) {
                setFirstEntry(false);
            }
            setTimeRange(initializeTimeRange(true, queryParams, true));
        } else {
            setTimeRange({ from: null, to: null });
        }
    }, [entitySelectorItem, isCurrentTime]);

    return (
        <AssetSensorData
            entitySelectorItem={entitySelectorItem}
            setEntitySelectorItem={setEntitySelectorItem}
            requestBody={requestBody}
            setRequestBody={setRequestBody}
            setTimeRange={setTimeRange}
            timeRange={timeRange}
            assetList={assetList}
            isCurrentTime={isCurrentTime}
            setIsCurrentTime={setIsCurrentTime}
            assetsFetched={assetRequestStatus === 'SUCCESS'}
        />
    );
};

export default AssetSensorDataContainer;
