import { PackagingType } from 'dataTypes/SecureBackend/apiResponse';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import useCachedQueryRequest from '../useCachedQueryRequest';

const useGetPackagingTypes = (postProcess = it => it): PackagingType[] => {
    const { GetAll: getPackagingTypes } = useSecureBackendEndpoints('packaging-types').requests;

    const {
        data: packagingTypeList = [],
    } = useCachedQueryRequest({
        key: 'packaging-types',
        request: getPackagingTypes,
        onCompleted: it => postProcess(it?.data || []),
    });

    return packagingTypeList;
};

export default useGetPackagingTypes;
