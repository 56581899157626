import moment from 'moment';
import { Asset, FacetDTO } from 'dataTypes/SecureBackend/apiResponse';
import icons from 'shared-components/icons';
import { CheckboxOption } from '../../shared-components/dataTypes';

export interface ExtractedAssetData {
    assetNumber: string,
    assetTypeCode: string,
    airlineCode: string,
    iataCode: string,
    id: number,
    lastMeasuredLongitude: number,
    lastMeasuredLatitude: number,
    locationName: string,
    locationRemark: string,
    lastMeasuredTimestamp?: number,
    lyingSinceTimestamp?: number,
    loggerFamily?: string,
    loggerFamilyLabel?: string,
    loggerNumber?: string,
    temperature: number,
}

export const extractAssetData = (rawData: Asset[]): ExtractedAssetData[] => {
    return rawData
        .map((item) => {
            const {
                id,
                lastMeasuredInfo = null,
                assetNumber = '',
                assetTypeCode,
                airlineCode,
                lyingSinceTimestamp,
                loggerNumber,
            } = item;

            const {
                temperatureGeolocationTimestamp,
                geolocation = null,
                locationName = null,
                iataCode,
                locationRemark,
                temperature,
            } = lastMeasuredInfo || {};

            const {
                longitude = null,
                latitude = null,
            } = geolocation || {};

            return {
                id,
                iataCode,
                lastMeasuredLongitude: longitude ? (Math.trunc(longitude * 10000) / 10000) : null,
                lastMeasuredLatitude: latitude ? (Math.trunc(latitude * 10000) / 10000) : null,
                assetNumber,
                airlineCode,
                locationRemark,
                locationName,
                assetTypeCode,
                lastMeasuredTimestamp: temperatureGeolocationTimestamp
                    ? moment(temperatureGeolocationTimestamp).utc(true).valueOf()
                    : null,
                lyingSinceTimestamp,
                loggerNumber,
                temperature,
            };
        });
};

export interface ActiveAsset {
    assetNumber: string,
    timestamp: number,
}

export const markerIconObj = {
    url: icons.hex_with_cross,
    scaledSize: {
        width: 20,
        height: 20,
    },
    origin: {
        x: 0,
        y: 0,
    },
    anchor: {
        x: 10,
        y: 10,
    },
};

export const getMapDataItem = (asset: ExtractedAssetData) => {
    return {
        type: 'Feature',
        properties: {
            marker: true,
            data: asset,
        },
        geometry: {
            type: 'Point',
            coordinates: [
                asset.lastMeasuredLongitude,
                asset.lastMeasuredLatitude,
            ],
        },
    };
};

export const getSuperclusterOptions = (mapData, bounds, zoom) => {
    return {
        points: mapData,
        bounds,
        zoom,
        options: {
            radius: 75,
            maxZoom: 15,
            generateId: true,
            map: (item) => {
                return {
                    lastMeasuredTimestamp: item.data.lastMeasuredTimestamp,
                };
            },
            reduce: (data, props) => {
                const { lastMeasuredTimes = [] } = data;

                if (!lastMeasuredTimes.includes(props.lastMeasuredTimestamp)) {
                    data.lastMeasuredTimes = [...lastMeasuredTimes, props.lastMeasuredTimestamp];
                }
                return data;
            },
        },
    };
};

export type FilterFields = 'assetTypeCode' | 'iataCode' | 'locationRemark' | 'airlineCode';

export const getExistedOptions = (
    assets: ExtractedAssetData[], field: FilterFields,
): string[] => {
    return assets.reduce((data, { [field]: currentKey }) => {
        return !currentKey || data.includes(currentKey)
            ? data
            : [...data, currentKey];
    }, []);
};
export const getOptionsFromFacets = (
    facets: FacetDTO[], field: string,
): string[] => {
    return facets.find(({ filterName }) => filterName === field)
        ?.counts
        ?.map(({ filterValue, count }) => (count > 0 ? filterValue : null))
        ?.filter(Boolean) || [];
};

export const initialAssetFilterOptions: { [optionsGroupKey: string]: string[] } = {
    assetTypeCodes: [],
    iataCodes: [],
    areas: [],
    airlineCodes: [],
    lyingSinceTimestamps: [],
};

export const getFieldCheckboxData = (
    facets: FacetDTO[] = [],
    pluralName: string,
    t?: Function,
    tgroup?: string,
) : CheckboxOption[] => {
    const existedOption = facets.find(({ filterName }) => filterName === pluralName);

    if (!facets || !existedOption) { return []; }
    return existedOption.counts.map(({ count, filterValue }) => {
        if (filterValue === null) {
            return null;
        }
        return {
            count,
            label: tgroup ? t(`${tgroup}.${filterValue}`) : filterValue,
            value: filterValue,
        };
    }).filter(Boolean);
};
