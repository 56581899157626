import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { PageWithFilter } from 'Layout';
import { useStyles } from 'TrackAndTrace/Assets/Assets.style';
import useGetViewTypeFromQuery from 'TrackAndTrace/localHooks/useGetViewTypeFromQuery';
import TitleWithMapTableSwitch from 'shared-components/TitleWithMapTableSwitch';
import useGetCommonData from 'hooks/useGetCommonData';
import { AssetsDTO, FacetDTO } from 'dataTypes/SecureBackend/apiResponse';
import useCustomTranslation from 'hooks/useCustomTranslation';
import Table from 'shared-components/Table';
import { Geofence } from 'shared-components/CompanyInfoComponents/AdministrationCompany/GeofencesCard/GeofencesCard';
import AddAssets from 'TrackAndTrace/Assets/components/AddAssets';
import DataPaginationContext from 'Contexts/DataPaginationContext';
import StandalonePagination from 'TrackAndTrace/Assets/components/StandalonePagination';
import { CustomSort, trimQuery } from 'TrackAndTrace/lib';
import { extractAssetData, ExtractedAssetData, initialAssetFilterOptions, getOptionsFromFacets } from './lib';

import AssetFilterPanel from './components/AssetFilters';

import MapView from './MapView';
import tableColumns from './tableColumns';

const PAGE_SIZE = 10;
const Assets = () => {
    const { t } = useCustomTranslation();
    const classes = useStyles();
    const view = useGetViewTypeFromQuery();
    const [page, setPage] = useState(1);
    const [totalElements, setTotalElements] = useState(0);
    const [query, setQuery] = useState('');
    const [lyingSinceTimestamp, setLyingSinceTimestamp] = useState<number | null>(null);
    const {
        data: rawInitialFacets,
    } = useGetCommonData<FacetDTO[]>('assets/search/facets',
        {
            cache: true,
            query: {
                initial: true,
            },
        });

    const [sort, setSort] = useState<CustomSort>(null);

    const updateSort = useCallback((columnId: string, direction: 'asc' | 'desc') => {
        setSort({ columnId, direction });
    }, []);
    const [
        filterOptions,
        setFilterOptions,
    ] = useState<{ [optionsGroupKey: string]: string[] }>(initialAssetFilterOptions);

    const {
        data: rawFacets, status: facetsStatus,
    } = useGetCommonData<FacetDTO[]>('assets/search/facets',
        {
            cache: true,
            query: {
                ...(trimQuery(filterOptions, query, rawInitialFacets)),
            },
            queryWrap: false,
        });

    const handleLyingTimeChange = (days) => {
        if (days === null) {
            setLyingSinceTimestamp(null);
        } else {
            setLyingSinceTimestamp(days);
        }
    };

    const {
        data: rawAssets, status: assetsStatus,
    } = useGetCommonData<AssetsDTO>('assets/search', {
        cache: true,
        query: {
            page: page - 1,
            pageSize: PAGE_SIZE,
            ...(trimQuery(filterOptions, query, rawInitialFacets, sort, rawFacets)),
            ...(lyingSinceTimestamp !== null ? { lyingSinceTimestamp } : {}),
        },
        enabled: facetsStatus === 'SUCCESS',
        queryWrap: false,
    });

    const {
        data: geofences,
    } = useGetCommonData<Geofence[]>('geofences');

    const [assets, setAssets] = useState<ExtractedAssetData[]>([]);

    const [showLastConnection, setShowLastConnection] = useState(false);
    const [lyingTime, setLyingTime] = useState('All');
    const [showAirportsInfo, setShowAirportsInfo] = useState(false);
    const [highlightedText, setHighlightedText] = useState('');
    const changeHighlightedText = (newHighlightedText) => {
        setHighlightedText(newHighlightedText);
    };

    useEffect(() => {
        if (assetsStatus === 'SUCCESS' && rawAssets?.resultList) {
            const allAssets = extractAssetData(rawAssets?.resultList);

            setTotalElements(rawAssets.totalElements);
            setAssets(allAssets);
        }
    }, [assetsStatus, rawAssets]);

    useEffect(() => {
        if (rawInitialFacets) {
            setFilterOptions({
                assetTypeCodes: getOptionsFromFacets(rawInitialFacets, 'assetTypeCodes'),
                iataCodes: getOptionsFromFacets(rawInitialFacets, 'iataCodes'),
                areas: getOptionsFromFacets(rawInitialFacets, 'areas'),
                airlineCodes: getOptionsFromFacets(rawInitialFacets, 'airlineCodes'),
            });
        }
    }, [rawInitialFacets]);

    const columns = useMemo(() => tableColumns(t), [t]);

    useEffect(() => {
        if ((page - 1) * PAGE_SIZE > totalElements) setPage(Math.ceil(totalElements / PAGE_SIZE));
    }, [page, totalElements]);
    return (
        <DataPaginationContext.Provider value={{
            page,
            setPage,
            perPage: PAGE_SIZE,
            totalElements,
            paginationLoading: assetsStatus === 'PENDING',
        }}
        >
            <PageWithFilter>
                <AssetFilterPanel
                    changeHighlightedText={changeHighlightedText}
                    filterOptions={filterOptions}
                    setFilterOptions={setFilterOptions}
                    onLyingTimeChange={handleLyingTimeChange}
                    lyingTime={lyingTime}
                    setShowLastConnection={setShowLastConnection}
                    setLyingTime={setLyingTime}
                    setShowAirportsInfo={setShowAirportsInfo}
                    isTableView={view === 'table'}
                    facets={rawFacets}
                    countsLoading={facetsStatus === 'PENDING'}
                    showLastConnection={showLastConnection}
                    showAirports={showAirportsInfo}
                    setQuery={setQuery}
                />
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <AddAssets />
                    <TitleWithMapTableSwitch
                        isTableView={view === 'table'}
                        pathname="/asset-management/assets"
                    />
                    {
                        view === 'table'
                            ? (
                                <Table
                                    columns={columns}
                                    data={assets}
                                    tableMaxHeight="100%"
                                    maskForHighlight={highlightedText}
                                    rowLinkTemplate="/asset-management/assets/readout/:assetNumber"
                                    title={t('MENU_ITEMS.TRACK_AND_TRACE_ASSETS')}
                                    classNames={{
                                        tableContainerClassName: classes.filteredTable,
                                    }}
                                    currentSort={sort}
                                    onSort={updateSort}
                                />
                            )
                            : (
                                <MapView
                                    assets={assets}
                                    showAirportsInfo={showAirportsInfo}
                                    showLastConnection={showLastConnection}
                                    geofences={geofences}
                                />
                            )
                    }
                    {
                        view === 'table' && <StandalonePagination detached entity="Assets" />
                    }
                </div>
            </PageWithFilter>
        </DataPaginationContext.Provider>
    );
};

export default Assets;
