import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    commodityContainer: {
        alignItems: 'center',
        display: 'flex',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.common['contrastGray600'],
        opacity: 1,
    },
    marginBottom10px: {
        marginBottom: '10px',
    },
    iconOnLeftSide: {
        marginRight: '11px',
        width: '20px',
    },
    iconOnRightSide: {
        right: '0',
        marginLeft: '11px',
        width: '20px',
    },
}));

type Props = {
    icon: string,
    customIconClass?: string,
    label: string,
    iconOnRightSide?: boolean,
    marginBottomDisabled?: boolean,
}

const RowWithIcon = ({
    icon,
    customIconClass = null,
    label,
    iconOnRightSide = false,
    marginBottomDisabled = false,
} : Props) => {
    const classes = useStyles();

    return (
        <div
            className={`${classes.commodityContainer} ${!marginBottomDisabled ? classes.marginBottom10px : ''}`}
            key={label}
        >
            {iconOnRightSide && <div>{label}</div>}
            <div className={iconOnRightSide ? classes.iconOnRightSide : classes.iconOnLeftSide}>
                <img
                    className={customIconClass}
                    alt={label}
                    src={icon}
                />
            </div>
            {!iconOnRightSide && <div>{label}</div>}
        </div>
    );
};

export default RowWithIcon;
