import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    productsTable: {
        '& th, & td': {
            font: 'Roboto',
            fontSize: '16px',
            padding: '2px 10px',
        },
    },
    packagingsTableContainer: {
        boxShadow: 'none',
    },
    productsTableRow: {
        '& th': {
            color: theme.palette.secondary[600],
            fontWeight: 'bold',
        },
        '& td': {
            color: theme.palette.secondary[600],
        },

    },
    plusWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    selectShade: {
        background: theme.palette.primary[30],
        marginTop: '7px',
        marginBottom: '7px',
    },
    icon: {
        color: theme.palette.secondary[600],
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.secondary[800],
        },
    },
    disabledIcon: {
        opacity: 0.6,
        cursor: 'unset',
    },
    productsTableButtonCell: {
        textAlign: 'end',
        paddingRight: '10px!important',
    },
    plus: {
        padding: '10px',
    },
    footerText: {
        display: 'flex',
        padding: '12px',
        marginTop: '80px',
        color: theme.palette.secondary[600],
        font: 'normal normal normal 16px/19px Roboto',
    },
}));
