import { makeStyles } from '@mui/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

export const useStyles = makeStyles((theme: SkycellThemeInterface) => ({
    '@keyframes appearTitle': {
        from: {
            width: '0%',
        },
        to: {
            width: '100%',
        },
    },
    '@keyframes appearLink': {
        from: {
            opacity: 0,
            height: '0px',
        },
        to: {
            opacity: 1,
            height: '32px',
        },
    },
    floatingMenu: {
        position: 'absolute',
        left: '100%',
        top: '0px',
        minWidth: '200px',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '14px',
        borderRadius: '0 10px 0 0!important',
        background: theme.palette.primary.darkBlue,
        height: '42px',
        fontSize: '14px',
        textTransform: 'uppercase',
        width: '0%',
        color: theme.palette.common.white,
        animation: '$appearTitle 100ms ease forwards',
    },
    floatingItem: {
        display: 'flex',
        height: '0px',
        paddingLeft: '14px',
        alignItems: 'center',
        fontSize: '14px',
        background: theme.palette.primary.darkBlue,
        color: theme.palette.common.white,
        textDecoration: 'none',
        justifyContent: 'space-between',
        marginLeft: '10px',
        opacity: 0,
        animation: '$appearLink 50ms ease forwards',
        '&:last-child': {
            borderRadius: '0 0 10px 10px!important',
        },
        '& span': {
            display: 'flex',
            paddingRight: '6px',
        },
        '&:hover': {
            background: theme.palette.primary.deepBlue,
        },
        '& svg': {
            rotate: '180deg',
            fontSize: '14px',
        },
    },
    active: {
        background: theme.palette.primary.darkBlue,
    },
}));
