import { styled } from '@mui/material';
import TextField from '@mui/material/TextField';

const StyledTextField = styled(TextField)({
    width: '100%',
    '& label.Mui-focused': {
        color: '#61c6e9',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#61c6e9',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#61c6e9',
        },
    },
});

export default StyledTextField;
