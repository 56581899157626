import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    a: {
        textDecoration: 'none',
    },
    linkedTableRow: {
        width: '100%',
        // minHeight: '30px',
        minHeight: '100%',
        height: '41px',
        borderBottom: `1px solid ${theme.palette.secondary[100]}`,
    },
    linkedTableRowWithPointer: {
        width: '100%',
        verticalAlign: 'top!important',
        minHeight: '30px',
        borderBottom: `1px solid ${theme.palette.secondary[100]}`,
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

export default useStyles;
