import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    textField: {
        margin: '0 25px',
        width: 'calc(100% - 50px)',
    },
    textFieldText: {
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    dialogActions: {
        marginTop: '27px',
        marginBottom: '19px',
        justifyContent: 'center',
    },
    approveButton: {
        width: '100px',
        height: '36px',
        textTransform: 'uppercase',
    },
    rejectButton: {
        width: '100px',
        height: '36px',
        textTransform: 'uppercase',
    },
    dialogTitle: {
        textAlign: 'left',
        font: 'normal normal normal 20px/24px Roboto',
        letterSpacing: '0.26px',
        color: theme.palette.secondary[900],
        opacity: 1,
    },
    dialogContentText: {
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
}));

export default useStyles;
