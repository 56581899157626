import React, { useCallback, useEffect } from 'react';
import { TextField, Select, MenuItem, useTheme, Autocomplete } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InputMask from 'react-input-mask';
import DateTimePickerWithFlush from 'shared-components/DateTimePickerWithFlush';
import { Edit, Undo, Save, CancelSharp } from '@mui/icons-material';
import { Pairing } from 'SensorDataCommon/hooks/useEditablePairingsColumns/useEditablePairingsColumns';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import UncontrolledTooltip from 'shared-components/ControlledTooltip/UncontrolledTooltip';

export type ValidationCheck = {
    message: string,
    field: string,
}
export type FieldDefinition = {
    Header: string,
    type: string,
    id: string,
    placeholder?: string,
    accessor?: (original: any) => any,
    options?: {
        value: string,
        label: string,
    }[],
    mandatory?: boolean | ((editedItem: any) => boolean),
    validation?: (editedItem: any) => string | null,
    editable?: boolean | ((editedItem: any) => boolean),
    mask?: string,
    flushable?: boolean,
    noBar?: boolean,
    minWidth?: string | number,
    maskChars?: { [key: number | string]: string },
    /**
     * restrictInput - Restricts the input based on input integrity.
     * @param newValue - The new value the user has inputted.
     * @returns The new value if it has passed the integrity test, otherwise returns the old value.
     */
    restrictInput?: (newValue) => boolean,
    onKeyDown?: (ev, onChange, restrictInput) => void,
}

const useStyles = makeStyles((theme: SkycellThemeInterface) => ({
    icon: {
        color: theme.palette.secondary[600],
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.secondary[800],
        },
    },
    invalid: {
        opacity: 0.5,
        cursor: 'not-allowed!important',
    },
    invalidField: {
        '& fieldset': {
            borderColor: `${theme.palette.common.red}!important`,
        },
    },
    input: {
        width: '90%',
        '& input': {
            padding: '5px',
            fontSize: '14px',
            textOverflow: 'ellipsis',
        },
        '&:focus-within $flushIcon': {
            opacity: 1,
        },
    },
    autoCompleteInput: {
        padding: '5px!important',
        '& .MuiInputBase-input': {
            height: '29px',
        },
        '& input': {
            padding: '5px',
        },
    },
    autoCompleteInputRoot: {
        width: '100%',
        padding: '0!important',
        textOverflow: 'ellipsis',
    },
    autoCompleteRoot: {
        width: '90%',
        '&:focus-within $flushIcon': {
            opacity: 1,
        },
        '& $flushIcon': {
            marginRight: '14px',
        },

    },
    select: {
        '& .MuiInputBase-input': {
            padding: '5px 20px 5px 5px',
        },
        fontSize: '14px',
        width: '90%',
    },
    placeholder: {},
    flushIcon: {
        color: theme.palette.secondary[400],
        opacity: 0,
        fontSize: '20px',
        transition: '200ms ease',
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.secondary[500],
        },
    },
    flushIconShift: {
        transform: 'translateX(-14px)',
        [theme.breakpoints.down('md')]: {
            transform: 'translateX(-2px)',
        },
        [theme.breakpoints.down('lg')]: {
            transform: 'translateX(-3px)',
        },

    },
    mandatory: {
        position: 'relative',
        '&::after': {
            content: '"*"',
            color: theme.palette.common.red,
            position: 'absolute',
            top: '0',
            right: '-10px',
        },
    },
    adornedEnd: {
        paddingRight: '0!important',
    },
}));

type Props = {
    predefinedColumns: FieldDefinition[],
    validationCheck?: ValidationCheck[],
    onSave?: (arg) => void,
    editedItem: any,
    setEditedItem: (arg: any) => void,
    elementId: string | number,
    hoveredItem: any,
}

const useEditableTableColumns: (props: Props) => ColumnsType[] = ({
    predefinedColumns = [],
    validationCheck = [],
    onSave = () => {},
    editedItem,
    setEditedItem,
    elementId,
    hoveredItem,
}) => {
    const classes = useStyles();
    const theme = useTheme<SkycellThemeInterface>();
    const [fieldFocused, setFieldFocused] = React.useState({});

    const onFocus = useCallback((field) => {
        setFieldFocused(prev => ({ ...prev, [field]: true }));
    }, []);

    useEffect(() => {
        setFieldFocused({});
    }, []);

    const onSaveCallback = useCallback(() => {
        onSave(editedItem);
        setFieldFocused({});
    }, [editedItem]);
    const onUndoCallback = useCallback(() => {
        setEditedItem(null);
        setFieldFocused({});
    }, []);

    return [
        ...predefinedColumns.map((field) => {
            const validationMessage = validationCheck.find(vCheck => vCheck.field === field.id)?.message;
            const invalid = !!validationMessage;

            return {
                Header: field.Header,
                type: 'custom',
                noBar: field.noBar,
                accessor: (original: Pairing) => {
                    const isBeingEdited = original.id === editedItem?.id;
                    const {
                        flushable,
                        placeholder,
                        Header,
                        onKeyDown,
                        options,
                        editable: editableCheck = true,
                        mandatory: mandatoryCheck = false,
                    } = field;
                    const onChange = (value: any) => {
                        setEditedItem(prev => ({
                            ...prev,
                            [field.id]: value,
                        }));
                    };
                    const key = `${elementId}_${original.id}_${Header}`;
                    const mandatory = typeof mandatoryCheck === 'function'
                        ? mandatoryCheck(editedItem) : mandatoryCheck;
                    const editable = typeof editableCheck === 'function'
                        ? editableCheck(editedItem) : editableCheck;
                    const tooltipIsOpen = fieldFocused[field.id] || false;

                    if (editable !== false && isBeingEdited) {
                        switch (field.type) {
                        case 'text':
                            return (
                                <UncontrolledTooltip
                                    key={key}
                                    description={validationMessage}
                                    borderColor="red"
                                    enabled={invalid}
                                    placement="top"
                                    open={tooltipIsOpen}
                                >
                                    <TextField
                                        id={key}
                                        placeholder={placeholder || ''}
                                        className={[
                                            classes.input,
                                            invalid ? classes.invalidField : '',
                                            mandatory ? classes.mandatory : '',
                                        ].join(' ')}
                                        value={editedItem[field.id] || ''}
                                        style={{
                                            minWidth: field.minWidth || 'unset',
                                        }}
                                        onFocus={() => onFocus(field.id)}
                                        onChange={(e) => onChange(e.target.value)}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </UncontrolledTooltip>
                            );
                        case 'mask':
                            return (
                                <UncontrolledTooltip
                                    key={key}
                                    description={validationMessage}
                                    borderColor="red"
                                    enabled={invalid}
                                    placement="top"
                                    open={tooltipIsOpen}
                                >
                                    <InputMask
                                        key={key}
                                        mask={field.mask}
                                        value={editedItem[field.id] || ''}
                                        onChange={(e) => onChange(field.restrictInput
                                            ? field.restrictInput(e.target.value) : e.target.value)}
                                        // @ts-ignore
                                        formatChars={field.maskChars}
                                    >
                                        {
                                            () => (
                                                <TextField
                                                    id={key}
                                                    placeholder={placeholder || ''}
                                                    className={[
                                                        classes.input,
                                                        invalid ? classes.invalidField : '',
                                                        mandatory ? classes.mandatory : '',
                                                    ].join(' ')}
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onFocus={() => onFocus(field.id)}
                                                    InputProps={{
                                                        endAdornment:
                                                                flushable
                                                                    ? (
                                                                        <CancelSharp
                                                                            className={classes.flushIcon}
                                                                            onClick={() => onChange('')}
                                                                        />
                                                                    ) : null,
                                                    }}
                                                    onKeyDown={(ev) => {
                                                        if (!onKeyDown) return;
                                                        onKeyDown(
                                                            ev,
                                                            (val) => onChange(val),
                                                            field.restrictInput || (it => it),
                                                        );
                                                    }}
                                                />
                                            )
                                        }
                                    </InputMask>
                                </UncontrolledTooltip>

                            );
                        case 'select':
                            return (

                                <UncontrolledTooltip
                                    key={key}
                                    description={validationMessage}
                                    borderColor="red"
                                    enabled={invalid}
                                    placement="top"
                                    open={tooltipIsOpen}
                                >
                                    <Select
                                        key={key}
                                        className={[
                                            classes.select,
                                            invalid ? classes.invalidField : '',
                                            mandatory ? classes.mandatory : '',
                                        ].join(' ')}
                                        value={editedItem[field.id] || ''}
                                        onChange={(ev) => onChange(ev.target.value)}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        variant="outlined"
                                        onFocus={() => onFocus(field.id)}
                                        renderValue={(value) => {
                                            const optionText = options
                                                .find(it => it.value === value)?.label || placeholder;

                                            return (
                                                <span
                                                    title={optionText}
                                                    style={{
                                                        color: !editedItem?.[field.id]
                                                            ? theme.palette.secondary[400] : 'unset',
                                                    }}
                                                >
                                                    {optionText}
                                                </span>
                                            );
                                        }}
                                    >
                                        <MenuItem value="">{placeholder || ''}</MenuItem>
                                        {options.map((it) => (
                                            <MenuItem
                                                key={`${field.id}_${it.value}`}
                                                value={it.value}
                                            >
                                                {it.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </UncontrolledTooltip>

                            );
                        case 'datetime':
                            return (
                                <UncontrolledTooltip
                                    key={key}
                                    description={validationMessage}
                                    borderColor="red"
                                    enabled={invalid}
                                    placement="top"
                                    open={tooltipIsOpen}
                                >
                                    <div
                                        key={key}
                                    >
                                        <DateTimePickerWithFlush
                                            key={`${key}_picker`}
                                            classes={classes}
                                            onChange={onChange}
                                            value={editedItem?.[field.id] || null}
                                            placeholder={placeholder || ''}
                                            flushable={flushable}
                                            invalid={invalid}
                                            mandatory={mandatory}
                                            minWidth={field.minWidth}
                                            onFocus={() => onFocus(field.id)}
                                        />

                                    </div>
                                </UncontrolledTooltip>

                            );
                        case 'autocomplete':
                            // eslint-disable-next-line no-case-declarations
                            const autocompleteOptionSelected = editedItem[field.id] ? options
                                .filter(it => it.label.toLowerCase()
                                        === editedItem[field.id]?.toLowerCase()) : options;

                            return (
                                <UncontrolledTooltip
                                    key={key}
                                    description={validationMessage}
                                    borderColor="red"
                                    enabled={invalid}
                                    placement="top"
                                    open={tooltipIsOpen}
                                >
                                    <Autocomplete
                                        key={key}
                                        classes={{
                                            inputRoot: classes.autoCompleteInputRoot,
                                            root: classes.autoCompleteRoot,
                                            input: classes.autoCompleteInput,
                                        }}
                                        options={autocompleteOptionSelected.length > 0
                                            ? autocompleteOptionSelected : options}
                                        disableClearable
                                        disablePortal
                                        size="small"
                                        value={editedItem[field.id] || ''}
                                        renderOption={(props, option) => (
                                            <li {...props} title={option.label}>
                                                {option.label}
                                            </li>
                                        )}
                                        onFocus={() => onFocus(field.id)}
                                        style={{
                                            minWidth: field.minWidth || 'unset',
                                        }}
                                        // @ts-ignore
                                        onSelect={(e) => onChange(e.target.value)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                className={[
                                                    classes.input,
                                                    invalid ? classes.invalidField : '',
                                                    mandatory ? classes.mandatory : '',
                                                ].join(' ')}
                                                variant="outlined"
                                                title={editedItem[field.id] || placeholder || ''}
                                                placeholder={placeholder || ''}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: flushable
                                                        ? (
                                                            <CancelSharp
                                                                className={classes.flushIcon}
                                                                onClick={() => onChange('')}
                                                            />
                                                        ) : null,
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                    />
                                </UncontrolledTooltip>

                            );
                        }
                    } else {
                        return (
                            <>
                                {field.accessor(original)}
                            </>
                        );
                    }
                },
            };
        }),
        {
            Header: ' ',
            type: 'custom',
            accessor: (row) => {
                const isBeingEdited = row.id === editedItem?.id;
                const isHovered = hoveredItem?.id === row.id;
                const isValid = validationCheck.length === 0;

                return (
                    <div style={{
                        height: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        width: '80px',
                    }}
                    >
                        {
                            isHovered && !isBeingEdited && (
                                <Edit
                                    className={[
                                        classes.icon,
                                        editedItem ? classes.invalid : '',
                                    ].join(' ')}
                                    onClick={editedItem ? null : () => setEditedItem(row)}
                                />
                            )
                        }
                        {
                            isBeingEdited && (
                                <>
                                    <Undo
                                        className={classes.icon}
                                        onClick={onUndoCallback}
                                    />
                                    <Save
                                        className={[
                                            classes.icon,
                                            !isValid ? classes.invalid : '',
                                        ].join(' ')}
                                        onClick={isValid ? onSaveCallback : null}
                                    />
                                </>
                            )
                        }
                    </div>
                );
            },
        },
    ];
};

export default useEditableTableColumns;
