import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    mapViewContainer: {
        display: 'flex',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
}));

export default useStyles;
