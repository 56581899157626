import { useLocation } from 'react-router-dom';
import useCacheContext from 'hooks/useCacheContext';

interface CustomTitle {
    title: string,
    path: string,
}

const emptyValue: CustomTitle = {
    title: '',
    path: '',
};

const useCustomTitle = () => {
    const { pathname } = useLocation();
    const { getCacheValue, setCacheValue } = useCacheContext();

    const getTitle = () => {
        const customTitle: CustomTitle = getCacheValue('customTitle') || emptyValue;

        return customTitle.path === pathname
            ? customTitle.title
            : '';
    };

    const setTitle = (title: string) => {
        const currentValueInCache = getTitle();

        if (!currentValueInCache) {
            const customTitle: CustomTitle = { path: pathname, title };

            setCacheValue('customTitle', customTitle);
        }
    };

    return { getTitle, setTitle };
};

export default useCustomTitle;
