import React from 'react';
import LocationInfo from 'LaneManagement/AddLane/Cards/AddLocation/LocationInfo';
import useScreenSize from 'hooks/useScreenSize';
import { ScreenType } from 'hooks/useScreenSize/useScreenSize';
import { AddressDTO } from '../dataTypes';
import { useStyles } from './Milestones.style';

type Props = {
    data: AddressDTO[]
}

const Milestones = ({
    data,
}: Props) => {
    const classes = useStyles();
    const {
        checkScreenType,
    } = useScreenSize();

    return (
        <>
            {
                data.map((item, index) => {
                    return (
                        <>
                            <div className={classes.location}>
                                <LocationInfo
                                    location={item}
                                    short
                                    wrap
                                    autoOverflow
                                    font={checkScreenType.down(ScreenType.MD) ? 'small' : 'medium'}
                                />
                            </div>
                            {
                                index !== data.length - 1 && (
                                    <div className={classes.locationDivider} />
                                )
                            }

                        </>
                    );
                })
            }
        </>
    );
};

export default Milestones;
