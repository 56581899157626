import React from 'react';
import TitleHeader from 'TitleHeader';
import { makeStyles } from '@mui/styles';
import Locations from '../Locations';
import OverviewInfoCard from '../OverviewInfoCard';

const useStyles = makeStyles({
    infoContainer: {
        display: 'grid',
        gridGap: '12px',
        gridTemplateColumns: '1fr 2fr',
    },
});

type Props = {
    companyInfo: any,
}

const CompanyInfoWithLocations = ({ companyInfo } : Props) => {
    const classes = useStyles();

    return (
        <>
            <TitleHeader>{companyInfo.companyName}</TitleHeader>
            <div className={classes.infoContainer}>
                <OverviewInfoCard
                    address={companyInfo.address}
                    contactEmail={companyInfo.contactEmail}
                    contactName={companyInfo.contactName}
                    contactPhone={companyInfo.contactPhone}
                    companyName={companyInfo.companyName}
                    companyLogo={companyInfo.companyLogo}
                    website={companyInfo.website}
                    location={companyInfo.location}
                />
                <Locations parentCompanyId={companyInfo.id} />
            </div>
        </>
    );
};

export default CompanyInfoWithLocations;
