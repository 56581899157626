import React from 'react';

interface CustomThemeContextInterface {
    setCustomTheme: (theme: object) => void,
    theme?: 'default' | 'dark' | any
}

const initialState = {
    setCustomTheme: () => {},
    theme: 'default',
};

const CustomThemeContext = React.createContext<CustomThemeContextInterface>(initialState);

export default CustomThemeContext;
