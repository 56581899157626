import React from 'react';
import CardWrapper from 'LaneManagement/AddLane/StepsWrapper/CardWrapper';
import CommonStepTable from 'LaneManagement/AddLane/StepsWrapper/CommonStepTable';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { Milestone } from 'shared-components/dataTypes';
import { roadTableDefaults, tableMetaData } from './tableMetaData';

type Props = {
    stepData: Milestone,
    onChange: Function,
}

const Customs = ({
    stepData,
    onChange,
    ...rest
}: Props) => {
    const { t } = useCustomTranslation();

    return (
        <CardWrapper stepData={stepData} onChange={onChange} {...rest}>
            <CommonStepTable
                tableData={tableMetaData(t)}
                tableDefaults={roadTableDefaults}
            />
            <div />
        </CardWrapper>
    );
};

export default Customs;
