import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
    ReactNode,
} from 'react';
import { AxiosResponse } from 'axios';
import { IncludeFilters } from 'shared-components/dataTypes';
import {
    ColumnsType,
    TableMaxHeight,
} from 'shared-components/Table/dataTypes';
import Table from 'shared-components/Table';

// const getFilterFieldCount = (filter = {}) => (
//     Object.keys(filter).reduce((count, field) => {
//         return count + filter[field].length;
//     }, 0)
// );

type Props = {
    columns: ColumnsType[],
    dataRequest: (params?: object, queryObj?: object) => Promise<AxiosResponse<any> | any>,
    filterCountRequest?: (fieldName: string, params?: object) => Promise<AxiosResponse<any>>,
    defaultIncludeFilters?: IncludeFilters,
    itemsOnPage?: number,
    tableMaxHeight?: TableMaxHeight,
    onRowClick?: (...args: any) => any,
    rowLinkTemplate?: string,
    rowExtension?: ReactNode,
}

const DynamicTable = ({
    columns,
    dataRequest,
    defaultIncludeFilters = {},
    itemsOnPage = 20,
    tableMaxHeight = '80vh',
    rowLinkTemplate = null,
    onRowClick,
    rowExtension = null,
}: Props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);

    const getData = async (
        includeFilters: IncludeFilters,
        pageNumber: number,
        itemsOnPage: number,
    ) => {
        try {
            const response = await dataRequest({
                start: (pageNumber - 1) * itemsOnPage,
                rows: pageNumber === 1 ? itemsOnPage * 2 : itemsOnPage,
                ...defaultIncludeFilters,
                includeFilters: {
                    ...includeFilters,
                },
            });

            if (response?.data?.items) {
                setData(response.data.items);
                setHasMore(response.data.items.length > 0);
            }
        } catch (error) {
            global.console.log(error);
            setData([]);
            setHasMore(false);
        }
    };

    const observer = useRef<ReactNode | null>(null);

    // Infinity loop
    const lastRowRef = useCallback((node) => {
        if (loading) return;
        if (observer.current) {
            // @ts-ignore
            observer.current.disconnect();
        }
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMore && data.length > itemsOnPage) {
                setPageNumber((prevPageNumber) => prevPageNumber + 1);
            }
        });
        if (node) {
            // @ts-ignore
            observer.current.observe(node);
        }
    }, [loading, hasMore]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getData(null, pageNumber, itemsOnPage);
            setLoading(false);
        })();
    }, [pageNumber]);

    return (
        <Table
            columns={columns}
            data={data}
            lastRowRef={lastRowRef}
            rowLinkTemplate={rowLinkTemplate}
            tableMaxHeight={tableMaxHeight}
            onRowClick={onRowClick}
            rowExtension={rowExtension}
        />
    );
};

export default DynamicTable;
