import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    addCompanyPageContent: {
        display: 'grid',
        gridTemplateColumns: '4fr 2fr',
        gridGap: '12px',
        padding: '12px',
        margin: 'auto',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    editCompanyPageContent: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: '12px',
        padding: '12px',
    },
    addEditCompanyCardContentData: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr 1fr 1fr 1fr',
        gridGap: '12px',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    addForm: {
        gridTemplateColumns: '1fr 2fr 1fr 1fr',
    },
    addEditCompanyFieldTitle: {
        marginTop: '10px',
        marginBottom: '5px',
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: '#939393',
        opacity: 1,
    },
    addEditCompanyTextField: {
        marginTop: '12px',
        width: '100%',
    },
    addEditCompanyTextFieldText: {
        paddingRight: '0!important',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    addEditCompanyMandatoryField: {
        background: '#E0F7FF 0% 0% no-repeat padding-box',
        borderRadius: '3px',
        borderLeft: '6px solid #8BD1E8',
        opacity: 1,
    },
    addEditCompanyButtonContainer: {
        display: 'flex',
        marginTop: '28px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    approveButton: {
        width: '90px',
        height: '36px',
        margin: '0 7px',
        background: `${theme.palette.primary[200]} 0% 0% no-repeat padding-box`,
        boxShadow: '0px 1px 3px #00000033',
        borderRadius: '4px',
        opacity: 1,
        textAlign: 'left',
        font: 'normal normal 500 14px/17px Roboto',
        letterSpacing: '1.25px',
        color: theme.palette.common.white,
        textTransform: 'uppercase',
        '&:hover': {
            backgroundColor: theme.palette.primary[600],
        },
    },
    rejectButton: {
        width: '90px',
        height: '36px',
        margin: '0 7px',
        background: `${theme.palette.common.white} 0% 0% no-repeat padding-box`,
        border: `1px solid ${theme.palette.primary[200]}`,
        borderRadius: '4px',
        opacity: 1,
        textAlign: 'center',
        font: 'normal normal 500 14px/17px Roboto',
        letterSpacing: '1.25px',
        color: theme.palette.primary[200],
        textTransform: 'uppercase',
    },
    secondApproveButton: {
        color: '#fff',
        '&:hover': {
            backgroundColor: '#4C9BB5!important',
        },
    },
}));

export default useStyles;
