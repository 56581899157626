import React, { useContext, useMemo, LegacyRef } from 'react';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { CHART_GRAPH_COLORS } from 'shared-components/constants';
import LaneTemperatureContext from 'Contexts/LaneTemperatureContext';
// import moment from 'moment';
import { downloadIcon } from 'shared-components/ApexTemperatureChart/icons';
import { useStyles } from './AmbientTemperatureChart.style';

type Props = {
    type: 'summer' | 'winter',
    chartRef: LegacyRef<any>,
}

const AmbientTemperatureChart = ({
    type = 'summer',
    chartRef,
}: Props) => {
    const {
        lane,
    } = useContext(LaneTemperatureContext);
    const classes = useStyles();
    const data = useMemo(() => {
        if (!lane) return [];

        const activities = lane?.milestones?.map(m => m.activities)?.flat();
        const seriesData = activities
            ?.map(activity => activity?.[type === 'summer' ? 'temperatureSummer' : 'temperatureWinter']);

        return [{
            data: [seriesData?.[0] || null, ...seriesData, seriesData?.[seriesData?.length - 1] || null],
        }];
    }, [lane]);

    const categories = useMemo(() => {
        if (!lane) return [];
        const activities = lane?.milestones?.map(m => m.activities)?.flat();
        const categoriesArray = [];

        let time = 0;

        categoriesArray.push(time);
        activities?.forEach((activity) => {
            time += activity?.duration;
            categoriesArray.push(time);
        });

        return categoriesArray;
    }, [lane]);

    const colors = useMemo(() => {
        if (!data) return [];
        return data?.length === 1
            ? ['#2C5969']
            : CHART_GRAPH_COLORS.slice(0, data?.length);
    }, [data?.length]);

    const options = useMemo((): ApexOptions => {
        return {
            chart: {
                type: 'line',
                height: 350,
                toolbar: {
                    /* export: {
                          csv: {
                             filename: undefined,
                             columnDelimiter: ',',
                             dateFormatter(timestamp) {
                                 return moment(timestamp).utc(false).format('YYYY-MM-DD HH:mm');
                             },
                         },
                     }, */
                    tools: {
                        download: downloadIcon,
                    },
                },
            },
            stroke: {
                width: 2,
                curve: 'stepline',
            },
            dataLabels: {
                enabled: false,
            },
            colors,
            legend: {
                show: false,
            },
            xaxis: {
                categories: ['0', ...categories],
                labels: {
                    formatter(value) {
                        if (Number(value) < 0) return '';

                        let hours: any = Math.floor(Number(value) / 60 / 60);

                        let minutes: any = (Number(value) / 60) % 60;

                        if (hours === 0 && minutes === 0) return '0h';
                        if (minutes === 0) minutes = ''; else minutes = `${Math.round(minutes)}m`;
                        if (hours === 0) hours = ''; else hours = `${hours}h`;
                        return `${hours} ${minutes}`;
                    },
                },
                type: 'numeric',
            },
            tooltip: {
                y: {
                    title: {
                        formatter(): string {
                            return type === 'summer' ? 'Summer Temperature' : 'Winter Temperature';
                        },
                    },
                },
                x: {
                    show: false,
                },
            },
            yaxis: {
                labels: {
                    formatter(value) {
                        if (value === null) return 'Not Assigned';
                        return `${value.toFixed(1)}°C`.padStart(5, '\u00A0');
                    },
                },
            },
        };
    }, []);

    if (!lane) return <></>;

    return (
        <div className={classes.chart}>
            <ReactApexChart
                options={options}
                height={250}
                series={data}
                type="line"
                ref={chartRef}
            />
        </div>
    );
};

export default AmbientTemperatureChart;
