import React, { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Table from 'shared-components/Table/TableContainer';
import AddCircle from '@mui/icons-material/AddCircle';
import CardWithEditableContent from 'shared-components/CardWithEditableContent';
import NoLocationsDefined from 'MyCompany/CompanyInfo/tabs/Overview/components/NoLocationsDefined';
import useGetCommonData from 'hooks/useGetCommonData';
import { GatewayInfoDTO } from 'dataTypes/SecureBackend/apiResponse';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import { uniqueId } from 'utils/stringTool';
import { useTheme } from '@mui/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import { CircularProgress } from '@mui/material';
import {
    tableColumns, resolveSequential,
} from 'MyCompany/CompanyInfo/tabs/Overview/components/BatteryExchangeLocationsCard/lib';
import useStatusStateProcessOptions from 'hooks/useStatusStateProcessOptions';
import { useStyles } from './BatteryExchangeLocationsCard.styles';

type Props = {
    className: string,
    setCurrentEditedCard?: (currentEditedCard: string) => void,
    currentEditedCard: string,
    loading: boolean,

}
const loadingStyle = {
    transition: '200ms ease',
    filter: 'blur(2px)',
    pointerEvents: 'none',
};
const cardName = 'BatteryExchangeLocationsCard';

const BatteryExchangeLocationsCard = ({
    className = '',
    setCurrentEditedCard,
    currentEditedCard,
    loading,
}: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [editMode, setEditMode] = useState(false);
    const theme = useTheme<SkycellThemeInterface>();
    const [shouldUpdate, setShouldUpdate] = useState(Date.now());
    const [workInProgress, setWorkInProgress] = useState(false);
    const {
        data: gateways = [],
    } = useGetCommonData<GatewayInfoDTO[]>('gateways', {
        postProcess: (data) => data.resultList.filter(it => it.gatewayImeiMac && it.iataCode),
    });

    const [
        editState, setEditState,
    ] = useState<{
        gatewayId: number,
        uniqueId: string,
    }[]>([]);
    const {
        data: batteryLevelSettings,
        isLoading: batterySettingsLoading,
    } = useGetCommonData<{
        gatewayIds: number[],
        companyId: number,
        batteryLevelThreshold: number,
    }>('assets/battery-level-settings', {
        query: {
            shouldUpdate,
        },
    });
    const {
        setErrorStatus,
        setProgressStatus,
        setSuccessStatus,
    } = useStatusStateProcessOptions();

    const update = useCallback(() => {
        setProgressStatus('Updating Battery Level Settings');
        setShouldUpdate(Date.now());
    }, []);
    const {
        Create: addGateway,
    } = useSecureBackendEndpoints('assets/battery-level-settings/action/add-gateway').requests;
    const {
        Create: removeGateway,
    } = useSecureBackendEndpoints('assets/battery-level-settings/action/remove-gateway').requests;

    const readState = useMemo<{
        gatewayId: number,
        uniqueId: string,
        label: string,
    }[]>(() => {
        if (!batteryLevelSettings?.gatewayIds || gateways.length === 0) return [];
        return batteryLevelSettings.gatewayIds.map(it => {
            const gatewayFound = gateways.find(({ id }) => it === id);

            const {
                iataCode,
                locationName,
                locationRemark,
            } = gatewayFound || {};

            return {
                gatewayId: gatewayFound?.id,
                uniqueId: uniqueId(),
                label: [iataCode,
                    locationName,
                    locationRemark].filter(it => it).join(' | '),
            };
        });
    }, [batteryLevelSettings, gateways]);

    const handleClickUndoButton = useCallback(() => {
        setCurrentEditedCard(null);
        setEditMode(false);
    }, []);
    const handleClickEditButton = useCallback(() => {
        setEditState(readState);
        setCurrentEditedCard(cardName);
        setEditMode(true);
    }, [readState]);

    const handleChangeEditStateValue = useCallback((val, uniqueId) => {
        setEditState(prev => {
            const newData = [...prev.filter(it => it.uniqueId !== uniqueId)];

            return [...newData, {
                uniqueId,
                gatewayId: val,
            }];
        });
    }, []);
    const handleRemoveItem = useCallback((uId) => {
        setEditState(prev => prev.filter(({ uniqueId }) => uId !== uniqueId));
    }, []);
    const handleClickSaveButton = useCallback(async () => {
        try {
            const removed = readState
                .filter(read => !editState.find(edit => read.gatewayId === edit.gatewayId));
            const added = editState
                .filter(edit => !readState.find(read => read.gatewayId === edit.gatewayId));
            const addTasks = added.map(it => async () => addGateway({
                gatewayId: it.gatewayId,
            }));
            const removeTasks = removed.map(it => async () => removeGateway({
                gatewayId: it.gatewayId,
            }));

            setWorkInProgress(true);
            try {
                if (added.length > 0) {
                    await resolveSequential(addTasks);
                }
                if (removeTasks.length > 0) {
                    await resolveSequential(removeTasks);
                }
            } catch (e) {
                setErrorStatus('Something went wrong');
                return;
            } finally {
                update();
                setWorkInProgress(false);
                setEditState([]);
                setProgressStatus(null);
                setEditMode(false);
                setSuccessStatus('Updated Successfully');
            }
        } catch (e) {
            global.console.log(e);
        }
    }, [editState, readState]);

    return (
        <CardWithEditableContent
            className={className}
            title={t('MY_COMPANY.BATTERY_EXCHANGE_LOCATIONS')}
            style={loading ? loadingStyle : { }}
            onClickEditButton={handleClickEditButton}
            onClickSaveButton={handleClickSaveButton}
            onClickUndoButton={handleClickUndoButton}
            showEditButton={!loading && !editMode}
            showSaveButton={!loading && editMode}
            showUndoButton={!loading && editMode}
            loadingIcon={loading}
            disabledEditButton={currentEditedCard !== null && currentEditedCard !== cardName}
            zeroSidePadding
        >
            {
                !batterySettingsLoading && (
                    <>
                        {
                            (editMode || readState.length > 0) && (
                                <Table
                                    data={editMode ? editState.sort(a => (a.gatewayId ? -1 : 1)) : readState}
                                    columns={tableColumns(
                                        t,
                                        theme,
                                        classes,
                                        editMode,
                                        editState,
                                        gateways || [],
                                        handleChangeEditStateValue,
                                        handleRemoveItem,
                                        workInProgress,
                                    )}
                                    embed
                                    classNames={{
                                        bodyCell: classes.cell,
                                        headerCell: classes.hcell,
                                    }}
                                />
                            )
                        }
                        {
                            (!editMode && readState.length === 0) && <NoLocationsDefined />
                        }
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                padding: '11px 13px 0',
                            }}
                        >
                            {
                                editMode && (
                                    <AddCircle
                                        fontSize="small"
                                        color="action"
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => setEditState(prev => [...prev, {
                                            gatewayId: null,
                                            uniqueId: uniqueId(),
                                        }])}
                                        className={classes.icon}
                                    />
                                )
                            }
                        </div>
                    </>
                )
            }
            {
                batterySettingsLoading && (
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100px',
                    }}
                    >
                        <CircularProgress
                            size={20}
                            style={{ color: theme.palette.secondary[600] }}
                        />

                    </div>
                )
            }

        </CardWithEditableContent>
    );
};

export default BatteryExchangeLocationsCard;
