import { makeStyles } from '@mui/styles';

const TABLE_PADDING = 20;
const useStyles = makeStyles((theme) => ({
    tableTitle: {
        fontSize: '32px',
        margin: `12px ${TABLE_PADDING}px`,
    },
    tableComponent: (props?: { title?: string }) => ({
        color: theme.palette.secondary[600],
        border: 'none',
        font: 'normal normal 500 1rem Roboto',
        textDecoration: 'none',
        backgroundColor: 'transparent !important',
        wordBreak: 'break-word',
        [theme.breakpoints.down('sm')]: {
            wordBreak: 'unset',
        },
        width: props.title ? `calc(100% - ${TABLE_PADDING * 2}px)` : '100%',
        margin: 'auto',
    }),
    noBar: {
        '&::after': {
            background: 'transparent!important',
        },
    },
    tableContainer: {
        overflow: 'auto',
        scrollSnapType: 'y mandatory',
        '&::-webkit-scrollbar-track': {
            '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,0.3)',
            borderRadius: '10px',
            backgroundColor: theme.palette.common.white,
        },
        '&::-webkit-scrollbar': {
            width: '6px',
            height: '6px',
            backgroundColor: theme.palette.secondary[100],
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,1)',
            backgroundColor: 'rgba(0,0,0,0.2)',
        },
        background: theme.palette.common.white,
        borderRadius: 6,
        paddingBottom: '20px',
    },
    boxShadow: {
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    },
    tableHeader: {
        background: theme.palette.common.white,
        top: '0',
        position: 'sticky',
        zIndex: 100,
        boxShadow: '0 0.6px rgba(0, 0, 0, 0.1)',
        paddingLeft: '12px',
    },
    reactTableTableRow: {
        width: '100%',
        minHeight: '30px',
        borderBottom: `1px solid ${theme.palette.secondary[100]}`,
    },
    rowWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    dateFilterContainer: {
        display: 'flex',
        margin: '5px',
        alignItems: 'center',
        padding: '10px',
        border: '2px solid #61C6E9',
        borderRadius: 4,
    },
    dateFilterContainerText: {
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: '#747474',
        opacity: 1,
    },
    noData: {
        display: 'flex',
        width: '100%',
        height: '50px',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.secondary[600],
    },
    datePicker: {
        '& .Mui-selected': {
            background: '#61C6E9!important',
            color: '#FFF!important',
        },
        '& .MuiButton-textPrimary': {
            color: '#61C6E9!important',
        },
        '& .css-12ha4i7': {
            backgroundColor: '#61C6E9!important',
        },
        '& .css-7lip4c': {
            backgroundColor: '#61C6E9!important',
        },
        '& .css-118whkv': {
            backgroundColor: '#61C6E9!important',
            border: '16px solid #61C6E9',
        },
    },
    datePickerTextField: {
        width: '120px',
    },
}));

export default useStyles;
