import React from 'react';
import ServiceProviders from 'ServiceProviders';

const ServiceProviderSites = () => {
    return (
        <ServiceProviders rowLinkTemplate="/administration/service-provider-sites/:id" />
    );
};

export default ServiceProviderSites;
