import React, {
    ChangeEvent,
    DragEvent,
    useMemo,
    useState,
} from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import IconButton from '@mui/material/IconButton';
import FileIcon from '@mui/icons-material/InsertDriveFile';
import ClearIcon from '@mui/icons-material/Clear';
import clsx from 'clsx';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useStyles from './FileUpload.style';

type Props = {
    accept?: string,
    dropLabel?: string,
    fileNames: string[],
    hoverLabel?: string,
    multiple?: boolean,
    onChange: (event: ChangeEvent<HTMLInputElement>) => void,
    onDrop: (event: DragEvent<HTMLElement>) => void,
    setIndexToDelete: (indexToDelete: number) => void,
}

const FileUpload = ({
    accept,
    dropLabel,
    fileNames = [],
    hoverLabel,
    multiple = false,
    onChange,
    onDrop,
    setIndexToDelete,
} : Props) => {
    const classes = useStyles();
    const { t } = useCustomTranslation();
    const [labelText,
        setLabelText] = useState<string>(hoverLabel || t('MY_COMPANY.DRAG_AND_DROP_A_FILE_HERE_OR_CLICK_TO_SEARCH'));
    const [iconSize, setIconSize] = useState<'small' | 'large'>('small');
    const [isDragOver, setIsDragOver] = useState<boolean>(false);
    const stopDefaults = (e: DragEvent) => {
        e.stopPropagation();
        e.preventDefault();
    };
    const dragEvents = {
        onDragEnter: (e: DragEvent) => {
            stopDefaults(e);
            setIsDragOver(true);
            setLabelText(dropLabel || t('MY_COMPANY.DROP_FILE_HERE'));
            setIconSize('large');
        },
        onDragLeave: (e: DragEvent) => {
            stopDefaults(e);
            setIsDragOver(false);
            setLabelText(hoverLabel || t('MY_COMPANY.DRAG_AND_DROP_A_FILE_HERE_OR_CLICK_TO_SEARCH'));
            setIconSize('small');
        },
        onDragOver: stopDefaults,
        onDrop: (e: DragEvent<HTMLElement>) => {
            stopDefaults(e);
            setLabelText(hoverLabel || t('MY_COMPANY.DRAG_AND_DROP_A_FILE_HERE_OR_CLICK_TO_SEARCH'));
            setIsDragOver(false);
            setIconSize('large');
            onDrop(e);
        },
    };

    const selectedFiles = useMemo(() => {
        return fileNames.map((fileName, index) => (
            {
                fileName,
                key: `file${index}`,
                handleClick: () => setIndexToDelete(index),
            }
        ));
    }, [fileNames]);

    return (
        <>
            <input
                onChange={onChange}
                accept={accept}
                multiple={multiple}
                className={classes.hidden}
                id="file-upload"
                type="file"
            />

            <label
                htmlFor="file-upload"
                {...dragEvents}
                className={clsx(classes.root, isDragOver && classes.onDragOver)}
            >
                <div>
                    <div className={classes.iconText}>
                        <CloudUploadIcon fontSize={iconSize} />
                        <div>{labelText}</div>
                    </div>
                </div>
            </label>
            {
                selectedFiles.map(({ fileName, key, handleClick }) => (
                    <div key={key} className={classes.selectedFiles}>
                        <FileIcon fontSize="small" color="action" />
                        { fileName }
                        <IconButton size="small" onClick={handleClick}>
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    </div>
                ))
            }
        </>
    );
};

export default FileUpload;
