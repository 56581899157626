import { SIDEBAR_IS_OPEN_KEY_LS } from 'dataTypes/common';

export const setLocalStorageValue = (key: string, value) => {
    localStorage.setItem(key, String(value));
};

export const getSidebarIsOpenLocalStorage = () => {
    const value = localStorage.getItem(SIDEBAR_IS_OPEN_KEY_LS);

    if (!value) {
        // If the value is not set, we assume the sidebar is open by default
        return true;
    }

    return value === 'true';
};
