import React from 'react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import TempRange from 'shared-components/TempRange';
import icons from 'shared-components/icons';
import { highlight } from 'utils/stringTool';

import {
    EntitySelectorItem,
    initialEntitySelectorItem,
    ASSET_TYPES,
} from '../../dataTypes';

const mainIconStyle = { width: '24px', height: '20px', marginRight: '7px' };

type Props = {
    option: EntitySelectorItem,
    sampleForSearch?: string,
    searchMode?: boolean,
}

const RenderOptionItem = ({
    option = initialEntitySelectorItem,
    sampleForSearch = '',
    searchMode = false,
} : Props) => {
    const { entityNumber, entityType, additionalData } = option;

    if (entityType === 'shipment') {
        const {
            iataCodeFrom = '',
            iataCodeTo = '',
            packagingCount = 0,
            mawbNumber = '',
            hawbNumber = '',
            temperatureRange = '',
            packagingsInThisShipment = [],
        } = additionalData;

        return (
            <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        alt="shipment"
                        src={icons.truck_and_clock}
                        style={mainIconStyle}
                    />
                    <div>
                        {
                            searchMode
                                ? highlight(entityNumber, sampleForSearch)
                                : entityNumber
                        }
                    </div>
                    <div style={{ margin: '0 5px' }}>|</div>
                    <TempRange tempRange={temperatureRange} mode="reduced" />
                    <div style={{ margin: '0 5px 0 0' }}>|</div>
                    <div>{`${packagingCount} x`}</div>
                    <img
                        alt="packagingCount"
                        src={icons.container_grey}
                        style={{ width: '20px', height: '18px', marginLeft: '7px' }}
                    />
                </div>

                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '32px' }}>
                    {'MAWB: '}
                    {
                        searchMode && mawbNumber
                            ? highlight(mawbNumber, sampleForSearch)
                            : mawbNumber
                    }
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '32px' }}>
                    {'HAWB: '}
                    {
                        searchMode && hawbNumber
                            ? highlight(hawbNumber, sampleForSearch)
                            : hawbNumber
                    }
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '32px' }}>
                    <div>{iataCodeFrom}</div>
                    <ArrowRightIcon fontSize="medium" />
                    <div>{iataCodeTo}</div>
                </div>
                {
                    searchMode && (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                marginLeft: '32px',
                                font: 'normal normal normal 16px/21px Roboto',
                                letterSpacing: '0px',
                            }}
                        >
                            {
                                packagingsInThisShipment.map(({ serialNumber }) => (
                                    <div
                                        style={{
                                            border: '1px solid #DFDFDF',
                                            borderRadius: '10px',
                                            padding: '0 4px',
                                            margin: '0 6px 3px 0',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {highlight(serialNumber, sampleForSearch)}
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
            </div>
        );
    }

    if (entityType === 'packagings') {
        const { packagingTypeLabel = '', temperatureRange } = additionalData;

        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <img
                    alt="packaging"
                    src={icons.container_grey}
                    style={mainIconStyle}
                />
                <div>
                    {
                        searchMode
                            ? highlight(entityNumber, sampleForSearch)
                            : entityNumber
                    }
                </div>
                <div style={{ margin: '0 5px' }}>|</div>
                <TempRange tempRange={temperatureRange} mode="reduced" />
                <div style={{ margin: '0 5px 0 0' }}>|</div>
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{packagingTypeLabel}</div>
            </div>
        );
    }

    if (entityType === 'loggers') {
        const { loggerTypeLabel = '' } = additionalData;

        return (
            <div
                style={{
                    display: 'flex',
                }}
            >
                <img
                    alt="loggers"
                    src={icons.frozenTempIcon}
                    style={mainIconStyle}
                />
                <div
                    style={{
                        display: 'block',
                        alignItems: 'center',
                    }}
                >
                    <div style={{ width: '100%' }}>
                        {
                            searchMode
                                ? highlight(entityNumber, sampleForSearch)
                                : entityNumber
                        }
                    </div>
                    <div style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{loggerTypeLabel}</div>
                </div>
            </div>
        );
    }

    if (entityType === 'assets') {
        const { assetNumber = '', loggerNumber = '', assetType } = additionalData || {};

        return (
            <div
                style={{
                    display: 'flex',
                }}
            >
                {assetType === ASSET_TYPES.CONTAINER
                && (
                    <img
                        alt="asset"
                        src={icons.container_black}
                        style={mainIconStyle}
                    />
                )}
                {assetType === ASSET_TYPES.AIRPORT_PALLET
                && (
                    <img
                        alt="asset"
                        src={icons.asset_pallet_black}
                        style={mainIconStyle}
                    />
                )}
                <div
                    style={{
                        display: 'block',
                        alignItems: 'center',
                    }}
                >
                    <div style={{ width: '100%' }}>
                        {
                            searchMode
                                ? highlight(assetNumber, sampleForSearch)
                                : assetNumber
                        }
                    </div>
                    <div
                        style={{
                            width: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {
                            searchMode
                                ? highlight(loggerNumber, sampleForSearch)
                                : loggerNumber
                        }
                    </div>
                </div>
            </div>
        );
    }

    return null;
};

export default RenderOptionItem;
