import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    wrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    title: {
        textAlign: 'center',
        font: 'normal normal 300 56px/67px Roboto',
        letterSpacing: '-0.49px',
        color: '#000000DE',
        opacity: 1,
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    picture: {
        paddingTop: '30px',
        width: '45%',
        background: 'transparent 0% 0% no-repeat padding-box',
        opacity: 1,
    },
    text: {
        textAlign: 'right',
        width: '50%',
        float: 'right',
        font: 'normal normal 300 36px/43px Roboto',
        letterSpacing: '-0.32px',
        color: '#000000DE',
        opacity: 1,
    },
    button: {
        height: '36px',
        margin: '15px 7px',
        background: '#fff 0% 0% no-repeat padding-box',
        border: '1px solid #8BD1E8',
        borderRadius: '4px',
        textAlign: 'center',
        font: 'normal normal 500 14px/17px Roboto',
        letterSpacing: '1.25px',
        color: '#8BD1E8',
        textTransform: 'uppercase',
    },
});

export default useStyles;
