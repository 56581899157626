import { useTheme } from '@mui/material/styles';
import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import useCustomTranslation from 'hooks/useCustomTranslation';
import QuickHelpTooltip from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import { GenericShipmentData } from 'TrackAndTrace/GenericShipments/lib';
import PreviewTable from 'TrackAndTrace/commonComponents/PreviewTable';
import tableColumns from './tableColumns';
import useStyles from './SearchPreviewTable.style';

type Props = {
    shipments: GenericShipmentData[],
    changeHighlightedText: (newHighlightedText: any) => void,
    setShipmentsFromPreviewTable: Dispatch<SetStateAction<GenericShipmentData[]>>,
    shipmentsFromPreviewTable: GenericShipmentData[],
    isTableView: boolean,
}

const SearchPreviewPackages = ({
    setShipmentsFromPreviewTable,
    changeHighlightedText = null,
    shipments = [],
    shipmentsFromPreviewTable = [],
    isTableView,
} : Props) => {
    const textRef = useRef(null);
    const classes = useStyles();
    const theme = useTheme();
    const [searchString, setSearchString] = useState('');
    const [data, setData] = useState<GenericShipmentData[]>(shipmentsFromPreviewTable);
    const [open, setOpen] = useState(shipmentsFromPreviewTable.length > 0);

    const { t } = useCustomTranslation();

    useEffect(() => {
        if (!isTableView) {
            const toggle = () => setOpen(false);

            document.addEventListener('click', toggle);
            return () => {
                document.removeEventListener('click', toggle);
            };
        }
    }, []);

    const handleFocus = () => {
        setOpen(prev => prev || searchString.length > 0 || shipmentsFromPreviewTable.length > 0);
    };
    const handleClick = useCallback((e) => e.stopPropagation(), []);
    const handleClickShowResults = () => {
        setShipmentsFromPreviewTable(data);
        setOpen(false);
    };

    const searchStringChanged = useCallback((event) => {
        setSearchString(event.target.value);
        changeHighlightedText(event.target.value.replace(/[\\/#!$%^&*;:{}=_`~()]/g, ''));
        if (!isTableView) {
            setOpen(event.target.value.length !== 0);
        }
    }, []);

    const handleClickClearSearch = useCallback((event) => {
        setShipmentsFromPreviewTable([]);
        searchStringChanged(event);
    }, []);

    useEffect(() => {
        const searchStringLowerCase = searchString?.toLowerCase();

        const filteredData = shipments.length === 0 || searchString.length === 0
            ? []
            : shipments.filter(item => {
                return item.externalId?.toLowerCase().includes(searchStringLowerCase)
                    || item.mawbNumber?.toLowerCase().includes(searchStringLowerCase)
                    || item.hawbNumber?.toLowerCase().includes(searchStringLowerCase)
                    || item.customerReference?.toLowerCase().includes(searchStringLowerCase)
                    || item.cargo?.map(item => item?.packaging?.serialNumber).join('').includes(searchStringLowerCase)
                    || item.originAirport?.toLowerCase().includes(searchStringLowerCase)
                    || item.destinationAirport?.toLowerCase().includes(searchStringLowerCase);
            });

        setData(filteredData);
        if (isTableView && filteredData.length <= 20) {
            setShipmentsFromPreviewTable(filteredData);
        }
    }, [shipments, searchString]);

    const columns = useMemo(() => {
        return tableColumns(t);
    }, [t]);

    return (
        <div>
            <QuickHelpTooltip
                tooltipInfo={{
                    text: t('QUICK_HELP.TRACK_AND_TRACE.SEARCH_PACKAGING_BY_SERIAL'),
                    order: 2,
                    position: 'right',
                    uid: 'getBySerialPreviewTooltip',
                }}
            >
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
                >
                    <TextField
                        className={classes.input}
                        id="TextFieldInput"
                        onInput={searchStringChanged}
                        value={searchString}
                        variant="outlined"
                        placeholder={t('COMMON.SEARCH')}
                        ref={textRef}
                        onFocus={handleFocus}
                        onClick={handleClick}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search style={{ color: theme.palette.common.black }} />
                                </InputAdornment>
                            ),
                            className: classes.input,
                        }}
                    />

                    <Button
                        className={classes.clearSearchBtn}
                        onClick={() => handleClickClearSearch({ target: { value: '' } })}
                        disabled={!searchString && shipmentsFromPreviewTable.length === 0}
                    >
                        <CloseIcon />
                    </Button>
                </div>
            </QuickHelpTooltip>
            {
                !isTableView && (
                    <PreviewTable
                        data={data}
                        columns={columns}
                        maskForHighlight={searchString}
                        onClickButtonClearSearch={handleClickClearSearch}
                        rowLinkTemplate="/track-and-trace/generic-shipments/:id"
                        onCardClick={handleClick}
                        onClickButtonShowResults={handleClickShowResults}
                        isCardOpen={open}
                        textRef={textRef}
                    />
                )
            }
        </div>
    );
};

export default SearchPreviewPackages;
