import React from 'react';
import { useParams } from 'react-router-dom';

import { LaneTemperatureProvider } from 'Contexts/LaneTemperatureContext/LaneTemperatureProvider';
import TemperatureSimulation from './TemperatureSimulation';

type PathParams = {
    laneId: string,
}

const TemperatureSimulationContainer = () => {
    const { laneId } = useParams<PathParams>();

    return (
        <LaneTemperatureProvider
            laneId={laneId}
        >
            <TemperatureSimulation />
        </LaneTemperatureProvider>
    );
};

export default TemperatureSimulationContainer;
