import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    location: {
        marginLeft: '14px',
        marginTop: '4px',
        marginBottom: '4px',

    },
    locationDivider: {
        flex: 1,
        '&:after': {
            content: '""',
            display: 'block',
            height: '100%',
            width: '1px',
            background: `linear-gradient(${theme.palette.secondary[600]} 50%, ${theme.palette.common.white} 50%)`,
            backgroundRepeatY: 'repeat',
            backgroundSize: '10px 9px',
            marginLeft: '24px',
        },
    },
}));
