import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    input: {
        textAlign: 'left',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        color: theme.palette.secondary[600],
        opacity: 1,
        height: '31px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        '& $input': {
            paddingLeft: '8px',
        },
    },
}));

export default useStyles;
