import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    filteredTable: {
        margin: '0px 10px 0px 24px',
        [theme.breakpoints.down('sm')]: {
            margin: '0px 10px',
        },
    },
    legendContainer: {
        alignItems: 'center',
        boxSizing: 'border-box',
        border: '1px solid transparent',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'space-between',
        outline: 'none',
        position: 'absolute',
        left: '0',
        right: '30px',
        bottom: '10px',
        flexWrap: 'nowrap',
        padding: '16px 24px',
        gap: '18px',
    },
    legend: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        borderRadius: '4px',
        padding: '20px 24px',
        color: 'black',
        fontSize: '14px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
        userSelect: 'none',
        whiteSpace: 'nowrap',
        maxWidth: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        height: '66px',
        '&::-webkit-scrollbar-track': {
            '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,0.3)',
            borderRadius: '10px',
            backgroundColor: theme.palette.common.white,
        },
        '&::-webkit-scrollbar': {
            height: '3px',
            backgroundColor: theme.palette.secondary[100],
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,1)',
            backgroundColor: 'rgba(0,0,0,0.2)',
        },
    },
    noGeolocation: {
        fontSize: '14px',
        padding: '10px 14px',
        width: '300px',
        whiteSpace: 'wrap',
    },
    legendText: {
        fontWeight: 400,
        fontSize: 16,
    },
    legendPoints: {
        display: 'flex',
        gap: '5px',
        [theme.breakpoints.down(1920)]: {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            padding: '4px 10px',
            alignItems: 'flex-start',
            '& $legendPoint': {
                marginLeft: 0,
            },
        },
    },
    legendPointsWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
    legendPoint: {
        alignItems: 'center',
        display: 'inline-flex',
        marginLeft: '20px',
        fontSize: '14px',
    },
    polygonIcon: {
        objectFit: 'unset',
    },
    polygonMarker: {
        position: 'relative',
        cursor: 'pointer',
    },
    polygonClusteredIcon: {},
    clusterCount: {
        position: 'absolute',
        top: '0',
        left: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // @ts-ignore
        color: theme.palette.common.constants.gray900,
    },
    legendIcon: {
        marginRight: '5px',
        maxHeight: '35px',
    },
    legendWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        position: 'absolute',
        bottom: '25px',
        left: '25px',
        right: '60px',
        zIndex: 2,
        gap: '10px',
    },

}));
