import React from 'react';
import { getIconByStatus } from 'utils/shipmentsDataProcessing';

const palletTitle = ({
    palletCode1, palletCode2, t,
}) => {
    if (!palletCode1) {
        return '';
    }

    return ` | ${t('ASSET_TYPE_LABEL.PALLET')}${palletCode2 ? 's' : ''} ${palletCode1}${palletCode2
        ? `, ${palletCode1}`
        : ''}`;
};

export const getTitleText = ({
    t, serialNumber, showInUTC,
}) => {
    const pTitle = palletTitle(t);

    return `${t('COMMON.PACKAGING')} ${serialNumber} ${pTitle}\
${showInUTC ? t('SENSOR_DATA.IN_C_UTC_TIME') : t('SENSOR_DATA.IN_C')}`;
};

export const middleStatus = (...statuses: string[]) => {
    if (statuses.includes('REJECTED')) return 'REJECTED';
    else if (statuses.includes('NOT_CHECKED')) return 'NOT_CHECKED';
    else return 'APPROVED';
};
export const getTempRangeCheck = ({
    theme,
    temperatureRangeMin,
    temperatureMin,
    temperatureRangeMax,
    temperatureMax,
}) => (
    <>
        <div>
            Min:
            <span style={{
                color: theme.palette.common[temperatureMin < temperatureRangeMin ? 'red' : 'black'],
            }}
            >
                {` ${temperatureMin}°C`}
            </span>
        </div>
        <div>
            Max:
            <span style={{
                color: theme.palette.common[temperatureMax > temperatureRangeMax ? 'red' : 'black'],
            }}
            >
                {` ${temperatureMax}°C`}
            </span>
        </div>
    </>
);
export const Badge = ({
    title,
    status,
}) => (
    <div style={{ display: 'flex', alignItems: 'center', textDecoration: 'underline' }}>
        <img src={getIconByStatus(status)} alt="" style={{ marginRight: '6px' }} />
        {title}
    </div>
);

export const Steps = [
    {
        uniqueId: '534626f6-f143-41bf-96a7-8bd3f4ed7435',
        type: 'LOADING',
        pickupLocation: {
            serviceProviderSite: {
                id: 38,
                serviceProviderName: 'Ndex Logistic',
                name: 'Ndex Logistic',
                address: {
                    country: {
                        code: 'IN',
                        name: 'India',
                    },
                    city: 'Agra',
                    geolocation: {
                        latitude: 27.17675687,
                        longitude: 78.00807265,
                    },
                },
            },
        },
        deliveryLocation: {
            serviceProviderSite: {
                id: 38,
                serviceProviderName: 'Ndex Logistic',
                name: 'Ndex Logistic',
                address: {
                    country: {
                        code: 'IN',
                        name: 'India',
                    },
                    city: 'Agra',
                    geolocation: {
                        latitude: 27.17675687,
                        longitude: 78.00807265,
                    },
                },
            },
        },
        serviceProviderSiteId: null,
        activities: [
            {
                name: 'Loading',
                ambientControl: 'CONTROLLED',
                temperatureSummer: 40,
                temperatureWinter: 6,
                duration: 14000,
                proposedTemperatureSummer: null,
                proposedTemperatureWinter: null,
                proposedDuration: null,
                index: 1,
            },
        ],
        index: 1,
    },
    {
        uniqueId: '7533b95f-347f-466b-9272-4c23c8d8e4a1',
        type: 'ROAD',
        pickupLocation: {
            serviceProviderSite: {
                id: 38,
                serviceProviderName: 'Ndex Logistic',
                name: 'Ndex Logistic',
                address: {
                    country: {
                        code: 'IN',
                        name: 'India',
                    },
                    city: 'Agra',
                    geolocation: {
                        latitude: 27.17675687,
                        longitude: 78.00807265,
                    },
                },
            },
        },
        deliveryLocation: {
            airport: {
                code: 'DEL',
                name: 'Indira Gandhi International Airport',
                city: 'New Delhi',
                geolocation: {
                    longitude: 77.103104,
                    latitude: 28.5665,
                },
            },
        },
        serviceProviderSiteId: null,
        activities: [
            {
                name: 'Transport',
                ambientControl: 'NOT_CONTROLLED',
                temperatureSummer: 36,
                temperatureWinter: 5,
                duration: 23800,
                proposedTemperatureSummer: null,
                proposedTemperatureWinter: null,
                proposedDuration: null,
                index: 1,
            },
        ],
        index: 2,
    },
    {
        uniqueId: '012689f0-37af-41f0-b83a-15d18023ccea',
        type: 'GROUND_HANDLING',
        pickupLocation: {
            airport: {
                code: 'DEL',
                name: 'Indira Gandhi International Airport',
                city: 'New Delhi',
                geolocation: {
                    longitude: 77.103104,
                    latitude: 28.5665,
                },
            },
        },
        deliveryLocation: {
            airport: {
                code: 'DEL',
                name: 'Indira Gandhi International Airport',
                city: 'New Delhi',
                geolocation: {
                    longitude: 77.103104,
                    latitude: 28.5665,
                },
            },
        },
        serviceProviderSiteId: null,
        activities: [
            {
                name: 'Cold Room Storage',
                ambientControl: 'CONTROLLED',
                temperatureSummer: 6,
                temperatureWinter: 1,
                duration: 16200,
                proposedTemperatureSummer: null,
                proposedTemperatureWinter: null,
                proposedDuration: null,
                index: 2,
            },
            {
                name: 'Unloading',
                ambientControl: 'NOT_CONTROLLED',
                temperatureSummer: 35,
                temperatureWinter: 6,
                duration: 14800,
                proposedTemperatureSummer: null,
                proposedTemperatureWinter: null,
                proposedDuration: null,
                index: 1,
            },
            {
                name: 'ULD Build-up',
                ambientControl: 'CONTROLLED',
                temperatureSummer: 6,
                temperatureWinter: 5,
                duration: 14700,
                proposedTemperatureSummer: null,
                proposedTemperatureWinter: null,
                proposedDuration: null,
                index: 3,
            },
            {
                name: 'Transport, Tarmac, Loading',
                ambientControl: 'NOT_CONTROLLED',
                temperatureSummer: 30,
                temperatureWinter: 6,
                duration: 13000,
                proposedTemperatureSummer: null,
                proposedTemperatureWinter: null,
                proposedDuration: null,
                index: 5,
            },
            {
                name: 'ULD Waiting Area',
                ambientControl: 'CONTROLLED',
                temperatureSummer: 6,
                temperatureWinter: 4,
                duration: 80000,
                proposedTemperatureSummer: null,
                proposedTemperatureWinter: null,
                proposedDuration: null,
                index: 4,
            },
        ],
        index: 3,
    },
    {
        uniqueId: '0b013adf-8f90-4246-aae9-1c1ba0f9e461',
        type: 'AIR',
        pickupLocation: {
            airport: {
                code: 'DEL',
                name: 'Indira Gandhi International Airport',
                city: 'New Delhi',
                geolocation: {
                    longitude: 77.103104,
                    latitude: 28.5665,
                },
            },
        },
        deliveryLocation: {
            airport: {
                code: 'BOM',
                name: 'Chhatrapati Shivaji International Airport',
                city: 'Mumbai',
                geolocation: {
                    longitude: 72.86789703,
                    latitude: 19.08869934,
                },
            },
        },
        serviceProviderSiteId: null,
        activities: [
            {
                name: 'Flight',
                ambientControl: 'CONTROLLED',
                temperatureSummer: 11,
                temperatureWinter: 10,
                duration: 11700,
                proposedTemperatureSummer: null,
                proposedTemperatureWinter: null,
                proposedDuration: null,
                index: 1,
            },
        ],
        index: 4,
    },
    {
        uniqueId: 'f02bbe86-eefd-4717-bd18-326e2c852c2b',
        type: 'AIR',
        pickupLocation: {
            airport: {
                code: 'BOM',
                name: 'Chhatrapati Shivaji International Airport',
                city: 'Mumbai',
                geolocation: {
                    longitude: 72.86789703,
                    latitude: 19.08869934,
                },
            },
        },
        deliveryLocation: {
            airport: {
                code: 'XMN',
                name: 'Xiamen Gaoqi International Airport',
                city: 'Xiamen',
                geolocation: {
                    longitude: 118.1279984,
                    latitude: 24.54400063,
                },
            },
        },
        serviceProviderSiteId: null,
        activities: [
            {
                name: 'Reloading',
                ambientControl: 'NOT_CONTROLLED',
                temperatureSummer: 35,
                temperatureWinter: 20,
                duration: 12600,
                proposedTemperatureSummer: null,
                proposedTemperatureWinter: null,
                proposedDuration: null,
                index: 1,
            },
            {
                name: 'Flight',
                ambientControl: 'CONTROLLED',
                temperatureSummer: 11,
                temperatureWinter: 10,
                duration: 9400,
                proposedTemperatureSummer: null,
                proposedTemperatureWinter: null,
                proposedDuration: null,
                index: 2,
            },
        ],
        index: 5,
    },
    {
        uniqueId: 'f02bbe86-eefd-4717-bd18-326e7c852c2b',
        type: 'CUSTOMS',
        pickupLocation: {
            airport: {
                code: 'BOM',
                name: 'Chhatrapati Shivaji International Airport',
                city: 'Mumbai',
                geolocation: {
                    longitude: 72.86789703,
                    latitude: 19.08869934,
                },
            },
        },
        deliveryLocation: {
            airport: {
                code: 'XMN',
                name: 'Xiamen Gaoqi International Airport',
                city: 'Xiamen',
                geolocation: {
                    longitude: 118.1279984,
                    latitude: 24.54400063,
                },
            },
        },
        serviceProviderSiteId: null,
        activities: [
            {
                name: 'Reloading',
                ambientControl: 'NOT_CONTROLLED',
                temperatureSummer: 35,
                temperatureWinter: 20,
                duration: 120600,
                proposedTemperatureSummer: null,
                proposedTemperatureWinter: null,
                proposedDuration: null,
                index: 1,
            },
            {
                name: 'Flight',
                ambientControl: 'CONTROLLED',
                temperatureSummer: 11,
                temperatureWinter: 10,
                duration: 9400,
                proposedTemperatureSummer: null,
                proposedTemperatureWinter: null,
                proposedDuration: null,
                index: 2,
            },
        ],
        index: 6,
    },
    {
        uniqueId: '0068ec1a-b84d-4f42-b6d9-2aa052c3beb5',
        type: 'GROUND_HANDLING',
        pickupLocation: {
            airport: {
                code: 'XMN',
                name: 'Xiamen Gaoqi International Airport',
                city: 'Xiamen',
                geolocation: {
                    longitude: 118.1279984,
                    latitude: 24.54400063,
                },
            },
        },
        deliveryLocation: {
            airport: {
                code: 'XMN',
                name: 'Xiamen Gaoqi International Airport',
                city: 'Xiamen',
                geolocation: {
                    longitude: 118.1279984,
                    latitude: 24.54400063,
                },
            },
        },
        serviceProviderSiteId: null,
        activities: [
            {
                name: 'Storage',
                ambientControl: 'CONTROLLED',
                temperatureSummer: 8,
                temperatureWinter: 8,
                duration: 1600,
                proposedTemperatureSummer: null,
                proposedTemperatureWinter: null,
                proposedDuration: null,
                index: 4,
            },
            {
                name: 'Transport',
                ambientControl: 'CONTROLLED',
                temperatureSummer: 6,
                temperatureWinter: 6,
                duration: 10200,
                proposedTemperatureSummer: null,
                proposedTemperatureWinter: null,
                proposedDuration: null,
                index: 2,
            },
            {
                name: 'ULD Breakdown',
                ambientControl: 'CONTROLLED',
                temperatureSummer: 8,
                temperatureWinter: 6,
                duration: 6000,
                proposedTemperatureSummer: null,
                proposedTemperatureWinter: null,
                proposedDuration: null,
                index: 3,
            },
            {
                name: 'Loading',
                ambientControl: 'NOT_CONTROLLED',
                temperatureSummer: 30,
                temperatureWinter: 2,
                duration: 3000,
                proposedTemperatureSummer: null,
                proposedTemperatureWinter: null,
                proposedDuration: null,
                index: 5,
            },
            {
                name: 'Aircraft Unloading',
                ambientControl: 'NOT_CONTROLLED',
                temperatureSummer: 28,
                temperatureWinter: 3,
                duration: 3600,
                proposedTemperatureSummer: null,
                proposedTemperatureWinter: null,
                proposedDuration: null,
                index: 1,
            },
        ],
        index: 7,
    },
    {
        uniqueId: 'ebc35ca2-e120-4acd-8b3a-b9ef6094a0c1',
        type: 'ROAD',
        pickupLocation: {
            airport: {
                code: 'XMN',
                name: 'Xiamen Gaoqi International Airport',
                city: 'Xiamen',
                geolocation: {
                    longitude: 118.1279984,
                    latitude: 24.54400063,
                },
            },
        },
        deliveryLocation: {
            serviceProviderSite: {
                id: 47,
                serviceProviderName: 'Fujian Baoda Logistics Limited Company',
                name: 'Fujian Baoda Logistics Limited Company',
                address: {
                    country: {
                        code: 'CN',
                        name: 'China',
                    },
                    city: 'Fuzhou',
                    geolocation: {
                        latitude: 26.0212373503638,
                        longitude: 119.418991159397,
                    },
                },
            },
        },
        serviceProviderSiteId: null,
        activities: [
            {
                name: 'Transport',
                ambientControl: 'NOT_CONTROLLED',
                temperatureSummer: 32,
                temperatureWinter: 5,
                duration: 22000,
                proposedTemperatureSummer: null,
                proposedTemperatureWinter: null,
                proposedDuration: null,
                index: 1,
            },
        ],
        index: 8,
    },
    {
        uniqueId: '6d973ac8-7c46-4b4a-b6fa-1ee253c444b7',
        type: 'UNLOADING',
        pickupLocation: {
            serviceProviderSite: {
                id: 47,
                serviceProviderName: 'Fujian Baoda Logistics Limited Company',
                name: 'Fujian Baoda Logistics Limited Company',
                address: {
                    country: {
                        code: 'CN',
                        name: 'China',
                    },
                    city: 'Fuzhou',
                    geolocation: {
                        latitude: 26.0212373503638,
                        longitude: 119.418991159397,
                    },
                },
            },
        },
        deliveryLocation: {
            serviceProviderSite: {
                id: 47,
                serviceProviderName: 'Fujian Baoda Logistics Limited Company',
                name: 'Fujian Baoda Logistics Limited Company',
                address: {
                    country: {
                        code: 'CN',
                        name: 'China',
                    },
                    city: 'Fuzhou',
                    geolocation: {
                        latitude: 26.0212373503638,
                        longitude: 119.418991159397,
                    },
                },
            },
        },
        serviceProviderSiteId: null,
        activities: [
            {
                name: 'Unloading',
                ambientControl: 'CONTROLLED',
                temperatureSummer: 5,
                temperatureWinter: 5,
                duration: 10000,
                proposedTemperatureSummer: null,
                proposedTemperatureWinter: null,
                proposedDuration: null,
                index: 1,
            },
        ],
        index: 9,
    },
];
