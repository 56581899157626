import { LatLngTimeTimestamp, TimeRange } from 'dataTypes/common';
import { SensorDataRequestBody } from 'dataTypes/SecureBackend/processedData';
import useHasAccess from 'hooks/useHasAccess';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import {
    Badge,
    // getTempRangeCheck,
    middleStatus,
} from 'TrackAndTrace/ShipmentDetails/components/PackagingTemperatureInfo/lib';
import { GenericPackaging } from 'TrackAndTrace/GenericShipmentDetails/lib';
import IconButton from '@mui/material/IconButton';
import ZoomInIcon from '@mui/icons-material/ZoomInMapOutlined';
import ZoomOutIcon from '@mui/icons-material/ZoomOutMapOutlined';
import moment from 'moment';
import { SensorDataItem, SensorPredictedDataResponse } from 'dataTypes/SecureBackend/apiResponse';
import TemperatureCheck from 'shared-components/TemperatureCheck';
import { GenericCargo, Milestones } from 'dataTypes/SecureBackend/apiResponse/Shipment';
import EnergyLevelBar from 'shared-components/EnergyLevelBar';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import { ZoomedDataLimits } from 'shared-components/ApexTemperatureChart';
import { HiddenSeries } from 'TrackAndTrace/GenericShipmentDetails/GenericShipmentDetails';
import { useStyles } from './PackagingTemperatureInfo.style';
import PackagingTemperature from './PackagingTemperature';
import useLoadedInitialData from './useLoadedInitialData';
import { getTitleText } from './lib';

type Props = {
    packaging: GenericPackaging,
    timeRange: TimeRange,
    showMarkers: boolean,
    showMeasuredOnly: boolean,
    showInUTC: boolean,
    showExpTemp: boolean,
    showTempRange: boolean,
    shipmentStart: string,
    shipmentEnd: string,
    shipmentNumber: string,
    shipmentId?: string,
    onMaximize: (serialNumber: string) => void,
    maximized: boolean,
    cargo: GenericCargo,
    transport: boolean,
    energyLevel?: number,
    hiddenSeries?: HiddenSeries,
    setHiddenSeries?: (hiddenSeries: HiddenSeries) => void,
    exportTempData?: (serialNumber: string, data: SensorDataItem[], labels: {
        dataTypes: string[],
        loggerTypes: string[],
        positions: string[],
    }) => void,
    exportPng?: (base64: string, aspectRatio: number) => void,
    milestones?: Milestones[],
}
type MouseStateProps = {
    index: number,
    timestamp: number,
}
const PackagingTemperatureInfo = ({
    cargo = null,
    packaging = null,
    showExpTemp = false,
    showMeasuredOnly = false,
    showMarkers = false,
    showInUTC = false,
    showTempRange = false,
    shipmentStart,
    shipmentEnd,
    shipmentNumber,
    shipmentId,
    onMaximize,
    maximized = false,
    transport = false,
    energyLevel = null,
    hiddenSeries,
    setHiddenSeries,
    exportTempData,
    exportPng,
}: Props) => {
    const classes = useStyles();
    const { company } = useCurrentUserContext();
    const { t } = useCustomTranslation();
    // const theme = useTheme();
    const hasAccess = useHasAccess();
    const [coordinates, setCoordinates] = useState<LatLngTimeTimestamp[]>([]);
    const [zoomedDataLimits, setZoomedDataLimits] = useState<ZoomedDataLimits>({ min: null, max: null });
    const [mouseMoveDataIndex, setMouseMoveDataIndex] = useState<MouseStateProps>({ index: null, timestamp: null });
    const [predictedData, setPredictedData] = useState<SensorPredictedDataResponse>(null);
    const {
        serialNumber,
    } = packaging || {};
    const handleMouseMoveChart = useCallback((index, timestamp) => {
        setMouseMoveDataIndex(({
            index,
            timestamp,
        }));
    }, []);
    const {
        temperatureMin,
        temperatureMax,
        temperatureStatus,
        temperatureRangeMax,
        temperatureRangeMin,
    } = cargo.temperatureCheckResult || {};
    const {
        sealStatus,
        palletStatus,
        palletStatus1,
        palletStatus2,
        containerStatus,
    } = cargo.skyCoreProductRelease || {};
    const {
        temperatureInternal,
    } = cargo.lastMeasuredData || {};

    const temperatureRange = [
        temperatureRangeMin,
        temperatureRangeMax,
    ];

    const [requestBody, setRequestBody] = useState<SensorDataRequestBody>({
        dataTypes: [],
        positions: [],
    });

    useEffect(() => {
        setRequestBody({
            dataTypes: [
                'TEMPERATURE',
                'DOOR',
                ...(['LOCATION_LATITUDE', 'LOCATION_LONGITUDE']),
            ],
            positions: [
                'AMBIENT',
                'INTERNAL',
            ],
        });
    }, []);
    const handleMaximize = useCallback(() => {
        onMaximize(packaging.serialNumber);
    }, [packaging.serialNumber]);
    const title = useMemo(() => getTitleText({ t, serialNumber, showInUTC }),
        [serialNumber, showInUTC]);
    const handleChange = useCallback(value => {
        setPredictedData(value);
    }, []);

    const {
        rawSensorData,
    } = useLoadedInitialData({
        shipmentId,
        cargoId: cargo.id,
        serialNumber,
        shipmentNumber,
        requestType: 'packagings',
        isPredictedExcursion: showExpTemp,
    });

    useEffect(() => {
        if (rawSensorData !== null && predictedData === null) {
            if (rawSensorData && rawSensorData.data.length > 0) {
                setPredictedData(rawSensorData);
            } else {
                setPredictedData(null);
            }
        }
    }, [rawSensorData]);
    let reserve;

    let predictedRuntime;

    let timeLeftInMinutes;

    const timeLeftHours = predictedData?.calculationSummary?.timeLeftHours;
    const timeLeftMinutes = predictedData?.calculationSummary?.timeLeftMinutes;

    if (timeLeftHours !== 0 || timeLeftMinutes !== 0) {
        timeLeftInMinutes = timeLeftHours * 60 + timeLeftMinutes;
        reserve = timeLeftInMinutes * 60000;
        predictedRuntime = moment(predictedData?.data[predictedData?.data.length - 1].t)
            .subtract(reserve, 'milliseconds');
    } else {
        const lastDataTimestamp = moment(predictedData?.data[predictedData?.data?.length - 1]?.t).valueOf();

        for (let i = 0; i < predictedData?.data?.length - 1; i++) {
            const currentTemperature = i < 254 ? predictedData?.data[i]?.d[1] : predictedData?.data[i].d[5];

            if (currentTemperature > temperatureRangeMax || currentTemperature < temperatureRangeMin) {
                const currentTimeStamp = moment(predictedData?.data[i]?.t).valueOf();

                reserve = lastDataTimestamp - currentTimeStamp;

                if (i >= 254) {
                    predictedRuntime = currentTimeStamp;
                    break;
                }
            }
        }
    }
    const timeRange = useMemo(() => (transport ? {
        from: null,
        to: moment.utc().format('YYYY-MM-DDTHH:mm'),
    }
        : { from: shipmentStart,
            to: shipmentEnd }), []);

    return (
        <div className={[classes.root, maximized && classes.maximized].join(' ')}>
            <div className={classes.map}>
                <div className={classes.titleWithMaximize}>
                    <div>{title}</div>
                    <IconButton
                        onClick={handleMaximize}
                        size="small"
                    >
                        {
                            maximized
                                ? <ZoomInIcon color="action" fontSize="small" />
                                : <ZoomOutIcon color="action" fontSize="small" />
                        }
                    </IconButton>
                </div>
                <PackagingTemperature
                    key={`PackagingTemperature_${serialNumber}`}
                    options={requestBody}
                    showExpTemp={showExpTemp}
                    cargoId={cargo.id}
                    showMarkers={showMarkers}
                    shipmentId={shipmentId}
                    showTempRange={showTempRange}
                    showInUTC={showInUTC}
                    showMeasuredOnly={showMeasuredOnly}
                    serialNumber={serialNumber}
                    temperatureRange={temperatureRange}
                    timeRange={timeRange}
                    showMap={maximized}
                    shipmentNumber={shipmentNumber}
                    maximized={maximized}
                    onFullscreenClick={() => onMaximize(serialNumber)}
                    handleChange={handleChange}
                    coordinates={coordinates}
                    setCoordinates={setCoordinates}
                    zoomedDataLimits={zoomedDataLimits}
                    setZoomedDataLimits={setZoomedDataLimits}
                    mouseMoveData={mouseMoveDataIndex}
                    setMouseMoveDataIndex={handleMouseMoveChart}
                    hiddenSeries={hiddenSeries}
                    setHiddenSeries={setHiddenSeries}
                    extractTempData={exportTempData}
                    exportPng={exportPng}
                    mapDefaultUI
                    mapFullscreenIn
                />
            </div>
            <div className={classes.divider} />
            <div className={classes.options}>
                <div className={classes.currentTemperature}>
                    {
                        transport && (
                            <>
                                <div className={classes.fieldTitle}>{t('SENSOR_DATA.CURRENT_TEMPERATURE')}</div>
                                <div className={classes.temperature}>
                                    {temperatureInternal?.toFixed(1) || 'N/A'}
                                    °C
                                </div>

                            </>
                        )
                    }
                    {
                        temperatureMin && temperatureMax && (
                            <>
                                <div className={classes.fieldTitle}>
                                    {t('TRACK_AND_TRACE.TEMPERATURE_DURING_SHIPMENT')}
                                </div>
                                <div className={classes.tempRangeCheck}>
                                    <div style={{
                                        marginRight: '2.3ch',
                                        fontSize: '14px',
                                        color: 'black',
                                        marginTop: 5,
                                        marginBottom: 7,
                                        justifyContent: 'space-between',
                                    }}
                                    >
                                        Actual
                                    </div>
                                    <TemperatureCheck
                                        temperatureMin={temperatureMin}
                                        temperatureMax={temperatureMax}
                                        temperatureRangeMin={temperatureRangeMin}
                                        temperatureRangeMax={temperatureRangeMax}
                                        grid={false}
                                        temperatureCheckStatus={temperatureStatus}
                                    />
                                </div>
                                {
                                    shipmentNumber === 'CT-023321'
                                    && showExpTemp && (
                                        <div className={classes.tempRangeCheck}>
                                            <div style={{
                                                marginRight: '2.3ch',
                                                fontSize: '14px',
                                                color: 'black',
                                                marginTop: 5,
                                                justifyContent: 'space-between',
                                                marginBottom: 7,
                                            }}
                                            >
                                                Expected
                                            </div>
                                            <TemperatureCheck
                                                temperatureMin={predictedData
                                                    ?.data[predictedData?.data.length - 2].d[4]}
                                                temperatureMax={predictedData
                                                    ?.data[predictedData?.data.length - 2].d[5]}
                                                temperatureRangeMin={temperatureRangeMin}
                                                temperatureRangeMax={temperatureRangeMax}
                                                grid={false}
                                                temperatureCheckStatus={temperatureStatus}
                                            />
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                    {
                        shipmentNumber === 'CT-023321'
                        && showExpTemp && (
                            <div className={classes.fieldTitle}>
                                {t('TRACK_AND_TRACE.TIMELINES')}
                                <div style={{
                                    display: 'flex',
                                    marginTop: 5,
                                    marginBottom: 5,
                                    justifyContent: 'space-between',
                                    fontSize: '14px',
                                    letterSpacing: '0.01em',
                                    color: 'black' }}
                                >
                                    <div>
                                        Expected Arrival:
                                    </div>
                                    <div style={{ marginRight: 15 }}>
                                        { moment(predictedData?.data[predictedData?.data.length - 1].t)
                                            .format('DD.MM. HH:mm') }
                                    </div>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    marginTop: 5,
                                    marginBottom: 5,
                                    justifyContent: 'space-between',
                                    fontSize: '14px',
                                    letterSpacing: '0.01em',
                                    color: 'black' }}
                                >
                                    <div>Predicted Runtime:</div>
                                    <div style={{ marginRight: 15 }}>
                                        {moment(predictedRuntime)
                                            .format('DD.MM. HH:mm')}
                                    </div>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    marginTop: 5,
                                    marginBottom: 5,
                                    justifyContent: 'space-between',
                                    fontSize: '14px',
                                    letterSpacing: '0.01em',
                                    color: 'black' }}
                                >
                                    <div>
                                        { predictedData?.calculationSummary?.timeLeftHours === 0
                                        && predictedData?.calculationSummary?.timeLeftMinutes === 0
                                            ? 'Overrun:' : 'Reserve:' }
                                    </div>
                                    <div style={
                                        predictedData?.calculationSummary?.timeLeftHours === 0
                                        && predictedData?.calculationSummary?.timeLeftMinutes === 0
                                            ? { marginRight: 15, color: 'red' }
                                            : { marginRight: 15, color: 'green' }
                                    }
                                    >
                                        {
                                            (predictedData?.calculationSummary?.timeLeftHours === 0
                                                && predictedData?.calculationSummary?.timeLeftMinutes === 0)
                                                ? `${moment(reserve).hours() - 1}h ${moment(reserve).minutes()}min`
                                                : (`${predictedData?.calculationSummary?.timeLeftHours}h ${predictedData
                                                    ?.calculationSummary?.timeLeftMinutes}min`)
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    <div style={{ flex: 1 }} />
                    {
                        hasAccess('PRODUCT_RELEASE') && (
                            <>
                                <div className={classes.fieldTitle}>{t('TRACK_AND_TRACE.PACKAGING_INTEGRITY')}</div>
                                <div className={classes.integrity}>
                                    <Badge
                                        title={t('ASSET_TYPE_LABEL.PALLET')} status={
                                            middleStatus(palletStatus, palletStatus1, palletStatus2)
                                        }
                                    />
                                    <Badge title={t('COMMON.PACKAGING')} status={containerStatus} />
                                    <Badge title={t('PACKAGING_PICTURE_SIDES.SEAL')} status={sealStatus} />
                                </div>
                            </>
                        )
                    }
                    <div>
                        {hasAccess('INTELLIGENT_MONITORING') ? (
                            <>
                                {' '}
                                <div className={classes.fieldTitle}>{t('TRACK_AND_TRACE.ENERGY_LEVEL')}</div>
                                <EnergyLevelBar
                                    energyLevel={energyLevel}
                                    threshold={company?.energyLevelThreshold}
                                />
                            </>
                        ) : ''}
                    </div>
                </div>
                <div className={classes.divider} />
                <div className={classes.displayOptions}>
                    {
                        !maximized && (
                            <PackagingTemperature
                                key={`PackagingTemperatureMiniMap_${serialNumber}`}
                                showMap
                                maximized={false}
                                handleChange={handleChange}
                                localTimezone={showInUTC}
                                zoomedDataLimits={zoomedDataLimits}
                                coordinates={coordinates}
                                onFullscreenClick={() => onMaximize(serialNumber)}
                                mouseMoveData={mouseMoveDataIndex}
                                noTooltip
                            />
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default PackagingTemperatureInfo;
