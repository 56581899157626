import React, {
    useEffect,
    useState, ReactElement,
} from 'react';
import {
    Checkbox,
    FormControlLabel,
} from '@mui/material';

type Props = {
    classes: any,
    count?: number,
    disabled?: boolean,
    filter?: string[],
    label: string | ReactElement,
    setFilter: (filter) => void,
    value: string,
    initialValue?: boolean,
}

const CheckboxRow = ({
    classes,
    count = null,
    disabled = false,
    label = null,
    setFilter,
    value,
    initialValue = false,
} : Props) => {
    const [checked, setChecked] = useState(initialValue);

    const handleChange = (event) => setChecked(event.target.checked);

    useEffect(() => {
        if (checked) {
            setFilter(prev => (prev.includes(value) ? prev : [...prev, value]));
        } else {
            setFilter(prev => prev.filter((valueItem) => valueItem !== value));
        }
    }, [checked]);

    useEffect(() => {
        setChecked(initialValue);
    }, [initialValue]);

    return (
        <div className={classes.checkboxRow}>
            <FormControlLabel
                disabled={disabled}
                label={
                    <div className={classes.formControlLabelStyle}>{label}</div>
                }
                className={classes.formControlLabelStyle}
                control={(
                    <Checkbox
                        size="small"
                        checked={checked}
                        onChange={handleChange}
                        className={classes.checkboxStyle}
                        style={{ color: checked && !disabled ? '#61C6E9' : '#747474' }}
                    />
                )}
            />
            {
                count !== null && <div className={classes.count}>{count}</div>
            }
        </div>
    );
};

export default CheckboxRow;
