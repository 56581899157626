import { SeaportExtended } from 'dataTypes/SecureBackend/apiResponse';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import useCachedQueryRequest from '../useCachedQueryRequest';

const useGetSeaports = (postProcess = it => it): SeaportExtended[] => {
    const { GetAll: getSeaports } = useSecureBackendEndpoints('seaports').requests;

    const {
        data: seaportList = [],
    } = useCachedQueryRequest({
        key: 'seaports',
        request: getSeaports,
        onCompleted: it => postProcess(it?.data || []),
    });

    return seaportList;
};

export default useGetSeaports;
