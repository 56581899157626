import React, { ChangeEvent } from 'react';
import { Switch } from '@mui/material';
import OptionItemTooltip from './OptionItemTooltip';
import useStyles from '../SideFilter.style';

type Props = {
    label: string,
    onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void,
    showTooltip?: boolean,
    tooltipDescription?: string,
    defaultChecked?: boolean,
}

const SwitchOptionItem = ({
    label,
    onChange,
    defaultChecked,
    showTooltip = false,
    tooltipDescription = '',
} : Props) => {
    const classes = useStyles();

    if (showTooltip) {
        return (
            <OptionItemTooltip
                description={tooltipDescription}
                descriptionTitle={label}
            >
                <div className={classes.switchRow}>
                    <span>{label}</span>
                    <Switch
                        size="medium"
                        color="primary"
                        onChange={onChange}
                        className={classes.switchStyle}
                    />
                </div>
            </OptionItemTooltip>
        );
    }

    return (
        <div className={classes.switchRow}>
            <span>{label}</span>
            <Switch
                className={classes.switchStyle}
                color="primary"
                size="medium"
                onChange={onChange}
                defaultChecked={defaultChecked}
            />
        </div>
    );
};

export default SwitchOptionItem;
