import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';

// import NotificationsCell from './components/NotificationsCell';
import { TFunction } from 'react-i18next';
import RolesCell from './components/RolesCell';

const tableColumns: (t:TFunction) => ColumnsType[] = (t = w => w) => [
    {
        Header: t('COMMON.USER'),
        accessor: 'userName',
        dataType: 'highlightedText',
        sideFilterKey: 'userName',
        sideFilterType: 'text',
    },
    {
        Header: t('COMMON.E_MAIL'),
        accessor: 'email',
        dataType: 'highlightedText',
        sideFilterKey: 'email',
        sideFilterType: 'text',
    },
    {
        Header: t('COMMON.COMPANY'),
        accessor: 'companyName',
        dataType: 'text',
        sideFilterKey: 'companyName',
        sideFilterType: 'enum',
    },
    {
        Header: t('COMMON.ROLES'),
        accessor: (row) => (<RolesCell role={row.roles} />),
        dataType: 'text',
        sideFilterKey: 'roles',
        sideFilterType: 'enumList',
    },
    // {
    //     Header: 'Open Tasks / Notifications',
    //     accessor: (row) => (
    //         <NotificationsCell
    //             notificationCount={row.notificationCount}
    //             openTaskCount={row.openTaskCount}
    //         />
    //     ),
    //     dataType: 'text',
    //     sideFilterKey: 'countryName',
    //     sideFilterType: 'enum',
    // },
];

export default tableColumns;
