import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: theme.typography.h1.fontSize,
        fontWeight: 200,
        // @ts-ignore
        color: theme.palette.common.titleBlackText,
        borderBottom: `1px solid ${theme.palette.primary[400]}`,
        marginBottom: theme.spacing(2),
    },
    cursorPointer: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

export default useStyles;
