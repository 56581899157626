import React, {
    useEffect,
    useState,
} from 'react';
import { DayOfWeek, OperatingHours, weekDaysWithLabelT } from 'dataTypes/SecureBackend/apiResponse';
import useCustomTranslation from 'hooks/useCustomTranslation';
import OperatingHoursRow from './OperatingHoursRow';

// const [currentDate] = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
//     .toISOString().split('T');

type Props ={
    currentOperatingHours: OperatingHours[],
    setData?: (operatingHours: OperatingHours[]) => void,
}

const EditMode = ({
    currentOperatingHours = [],
    setData = () => {},
}: Props) => {
    const [operatingHours, setOperatingHours] = useState<OperatingHours[]>(currentOperatingHours);

    const { t } = useCustomTranslation();

    useEffect(() => {
        setData(operatingHours);
    }, [operatingHours]);

    return (
        <div>
            {
                Object.keys(weekDaysWithLabelT(t)).map((day: DayOfWeek) => {
                    const currentDay = operatingHours.find(item => item.dayOfWeek === day) || null;
                    // const currentFrom = currentDay ? new Date(`${currentDate}T${currentDay.from}`) : null;
                    // const currentTo = currentDay ? new Date(`${currentDate}T${currentDay.to}`) : null;

                    return (
                        <OperatingHoursRow
                            key={day}
                            currentFrom={currentDay ? currentDay.from : null}
                            currentTo={currentDay ? currentDay.to : null}
                            dayOfWeek={day}
                            operatingHours={operatingHours}
                            setOperatingHours={setOperatingHours}
                        />
                    );
                })
            }
        </div>
    );
};

export default EditMode;
