import React from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useTranslation } from 'react-i18next';
import {
    useStyles,
} from '../BatteryExchangeLocationsCard/BatteryExchangeLocationsCard.styles';

const NoLocationsDefined = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.noLocations}>
            <LocationOnIcon style={{
                fontSize: '64px',
            }}
            />
            <div style={{
                fontSize: '18px',
            }}
            >
                {t('MY_COMPANY.NO_LOCATIONS_DEFINED')}
            </div>
            <div style={{
                maxWidth: '300px',
                textAlign: 'center',
                fontSize: '14px',
            }}
            >
                {t('MY_COMPANY.NO_LOCATIONS_DEFINED_DESC')}
            </div>
        </div>
    );
};

export default NoLocationsDefined;
