import React, { ChangeEvent, useCallback } from 'react';
import MuiPagination from '@mui/material/Pagination';
import { ORDERS_IN_PAGE } from 'shared-components/constants';

import useStyles from './Pagination.style';
import Legend from '../Legend';

type Props = {
    currentPage?: number,
    shipmentsCount: number,
    setCurrentPage: (newPage: number) => void,
}

const Pagination = ({
    currentPage = 1,
    shipmentsCount = 0,
    setCurrentPage,
} : Props) => {
    const classes = useStyles();

    const pagesCount = Math.ceil(shipmentsCount / ORDERS_IN_PAGE);

    const infoLabelFromTo = ` ${(currentPage - 1) * ORDERS_IN_PAGE + 1}
        to ${currentPage * ORDERS_IN_PAGE < shipmentsCount
        ? currentPage * ORDERS_IN_PAGE : shipmentsCount} `;
    const infoLabelTotalNumber = `of ${shipmentsCount}`;

    const handleChange = useCallback((event: ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    }, []);

    return (
        <div className={classes.legendWrapper}>
            <Legend />

            <div className={classes.pagination} aria-disabled>
                <div className={classes.label}>
                    Showing Shipments
                    <div className={classes.labelBlue}>{infoLabelFromTo}</div>
                    {infoLabelTotalNumber}
                </div>
                <div className={classes.muiPagination}>
                    <MuiPagination
                        classes={{ root: classes.root }}
                        count={pagesCount}
                        onChange={handleChange}
                        inputMode="numeric"
                        size="small"
                        variant="outlined"
                        shape="rounded"
                        page={currentPage || 1}
                    />
                </div>
            </div>
        </div>
    );
};

export default Pagination;
