import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
    },
    switchContainer: {
        display: 'flex',
        alignItems: 'center',
        right: '0',
        marginLeft: 'auto',
    },
    active: {
        textAlign: 'left',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        color: theme.palette.secondary[400],
        opacity: 1,
    },
    inactive: {
        textAlign: 'left',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.43px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    switch_track: {
        backgroundColor: theme.palette.primary[400],
        opacity: 0.4,
    },
    switch_base: {
        color: theme.palette.primary[400],
    },
}));

export default useStyles;
