import { ReactElement } from 'react';

export enum TABLE_INPUT_TYPE {
    PK,
    TEMPERATURE,
    TEXT,
    NUMBER,
    WITH_POSTFIX,
    TIME_24,
    SELECT
}

export type CommonTableItem = {
    id: string,
    title?: ReactElement | string,
    inputType?: TABLE_INPUT_TYPE,
    selectData?: {
        value: string | number,
        render: number | string | ReactElement
    }[],
    width?: string,
    required?: boolean,
}
