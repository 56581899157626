import {
    useEffect,
    useState,
} from 'react';
import useGetServiceProvider from 'hooks/useGetServiceProvider';
import useBreadcrumbsContext from 'hooks/useBreadcrumbsContext';
import useCustomTitle from 'hooks/useCustomTitle';
import useCacheContext from 'hooks/useCacheContext';
import { fetchProcessedData } from './lib';

const useGetParentCompany = () => {
    const serviceProvider = useGetServiceProvider(true);
    const { getCacheValue, setCacheValue } = useCacheContext();
    const [company, setCompany] = useState(null);

    const { setBreadcrumbData } = useBreadcrumbsContext();
    const { setTitle } = useCustomTitle();

    // ---
    // TODO: remove later or refactor to useSecureBackend
    //     const mockUrl = useMemo(() => {
    //         return `https://14d6ca64-1e7d-486d-b8c3-8df59dbf4069.mock.pstmn.io/\
    // business-objects/v1/parentcompany/${serviceProvider?.parentCompany?.id}`;
    //     }, [serviceProvider?.parentCompany]);

    // const { makeMockRequest } = useAbstractService({
    //     method: 'GET',
    //     url: mockUrl,
    // });
    const makeMockRequest = async () => ({ data: null });
    // ---

    useEffect(() => {
        (async () => {
            if (serviceProvider && !company) {
                const { id } = serviceProvider.parentCompany;

                const companyFromSession = getCacheValue<{
                        id: number,
                    }>('PARENT_COMPANY');

                if (companyFromSession?.id === id) {
                    setCompany(companyFromSession);
                } else {
                    try {
                        const response = await makeMockRequest();

                        if (response?.data) {
                            const processedData = fetchProcessedData(response.data);

                            setCompany(processedData);
                            setCacheValue('PARENT_COMPANY', processedData);
                        }
                    } catch (error) {
                        setCompany(null);
                        global.console.log(error);
                    }
                }
            }
        })();
    }, [serviceProvider, makeMockRequest]);

    useEffect(() => {
        if (company) {
            setBreadcrumbData('customBreadcrumb', serviceProvider?.serviceProviderName);
            setTitle(serviceProvider?.serviceProviderName);
        }
    }, [company]);

    return company;
};

export default useGetParentCompany;
