import moment from 'moment';
import {
    GenericCargo,
    Milestones,
    Location,
    GenericShipment,
    TemperatureCheckResult,
    EnergyLevelCheckResult,
} from 'dataTypes/SecureBackend/apiResponse/Shipment';
import { Geolocation, Airport } from 'dataTypes/SecureBackend/apiResponse';
import {
    LatLng,
    PolylineAndStepStatus,
    TimeRange,
} from 'dataTypes/common';
import { CheckboxOption } from 'shared-components/dataTypes';
import icons from 'shared-components/icons';
import {
    APPROVAL_STATUS,
    ORDER_STEP_TYPE,
    TEMPERATURE_STATUS,
} from 'shared-components/constants';

export const getApprovalStatuses = (cargo: GenericCargo[]): string[] => {
    return cargo.map(item => {
        const { skyCoreProductRelease } = item;
        const { approvalStatus = APPROVAL_STATUS.CHECK_NOT_PASSED } = skyCoreProductRelease || {};

        return approvalStatus;
    });
};
export const getEnergyLevels = (cargo: GenericCargo[]): EnergyLevelCheckResult[] => {
    return cargo.map(item => item.energyLevelCheckResult);
};
export const getTemperatureStatuses = (cargo: GenericCargo[]): string[] => {
    return cargo.map(item => {
        const { temperatureCheckResult } = item;
        const { temperatureStatus = TEMPERATURE_STATUS.EXCURSION } = temperatureCheckResult || {};

        return temperatureStatus;
    });
};

const getNumberOfStatus = (statuses: string[], requiredStatus: string): number => (
    statuses.filter((status) => status === requiredStatus).length
);

export const getPackagingIcons = (statuses: string[]): string[] => {
    if (statuses.length < 6) {
        return statuses.map((status) => {
            if (status === APPROVAL_STATUS.REJECTED
                || status === APPROVAL_STATUS.CHECK_NOT_PASSED) {
                return icons.rectangle_red;
            }
            if (status === APPROVAL_STATUS.NOT_CHECKED) {
                return icons.rectangle_grey;
            }
            return icons.rectangle_blue;
        });
    }
    const notApprovedCount = getNumberOfStatus(statuses, APPROVAL_STATUS.REJECTED);

    /** Show ceiling(4 x count(ProductRelease | REJECTED) / count(ProductRelease))
     dots with color #D44848 and the other dots with #61C6E9 */
    const redIconsCount = Math.ceil((4 * notApprovedCount) / statuses.length);

    return [
        redIconsCount > 0 ? icons.rectangle_red : icons.rectangle_blue,
        redIconsCount > 1 ? icons.rectangle_red : icons.rectangle_blue,
        icons.dots_blue,
        redIconsCount > 2 ? icons.rectangle_red : icons.rectangle_blue,
        redIconsCount > 3 ? icons.rectangle_red : icons.rectangle_blue,
    ];
};

export const checkIsWarning = (statuses: string[]): boolean => (
    statuses.includes(APPROVAL_STATUS.REJECTED)
    || statuses.includes(APPROVAL_STATUS.CHECK_NOT_PASSED)
);

export const checkIsPending = (statuses: string[]): boolean => {
    const approvedCount = getNumberOfStatus(statuses, APPROVAL_STATUS.APPROVED);

    const checkPassedCount = getNumberOfStatus(statuses, APPROVAL_STATUS.CHECK_PASSED);

    return (statuses.length !== 0
        && (checkPassedCount === statuses.length
            || ((checkPassedCount + approvedCount) === statuses.length && checkPassedCount > 0)
        )
    );
};

export const checkIsAllApproved = (statuses: string[]): boolean => {
    const approvedCount = getNumberOfStatus(statuses, APPROVAL_STATUS.APPROVED);

    return (statuses.length !== 0 && approvedCount === statuses.length);
};

export const getPackagingTypesCount = (cargo: GenericCargo[]): { [key: string]: number } => {
    return cargo.reduce((types, item) => {
        const { packaging = null } = item || {};
        const { packagingTypeCode = null } = packaging || {};

        return packagingTypeCode
            ? { ...types, [packagingTypeCode]: types[packagingTypeCode] ? types[packagingTypeCode] + 1 : 1 }
            : types;
    }, {});
};

export interface ShipmentWithSharedField extends GenericShipment {
    isShared: boolean,
}

export interface Packaging {
    serialNumber: string,
}

export interface ProcessedPackagingAndProductRelease {
    temperatureCheckResult: TemperatureCheckResult,
}

export interface GenericShipmentData {
    id?: number | string,
    cargo?: GenericCargo[];
    customerReference: string,
    destinationAirport: string,
    isAllApproved: boolean,
    isPending: boolean,
    isWarning: boolean,
    leaseEnd?: string,
    packagingsSerialNumberList?: string[],
    leaseStart?: string,
    shipmentStart?: string,
    shipmentEnd?: string,
    marker: LatLng,
    mawbNumber: string,
    hawbNumber: string,
    originAirport: string,
    packagingSquareIcons: string[],
    packagingTypesCount: { [key: string]: number },
    packagings: ProcessedPackagingAndProductRelease[],
    pharmaCompanyName: string,
    polylines: PolylineAndStepStatus[],
    externalId: string,
    status: string,
    statusLabel?: string,
    temperatureRange: string,
    shipmentSensorDataTimeRange: TimeRange,
    currentPosition: LatLng,
}

const isNumber = (value: any): boolean => {
    return !Number.isNaN(Number.parseFloat(value));
};

const isPathCorrect = (startLocation: (Location), endLocation: (Location)): boolean => {
    const { geolocation: startGeolocation = null } = startLocation || {};
    const { geolocation: endGeolocation = null } = endLocation || {};

    return startGeolocation !== null && endGeolocation !== null
        && isNumber(startGeolocation.latitude) && isNumber(startGeolocation.longitude)
        && isNumber(endGeolocation.latitude) && isNumber(endGeolocation.longitude);
};

const getLocation = (location: Geolocation): LatLng => ({
    lat: (Math.trunc(location.latitude * 10000) / 10000),
    lng: (Math.trunc(location.longitude * 10000) / 10000),
});

const getPolyline = (startPoint: (Airport | Location), endPoint: (Airport | Location), stepStatus?: string)
    : PolylineAndStepStatus => (
    {
        path: [getLocation(startPoint.geolocation), getLocation(endPoint.geolocation)],
        stepStatus,
    }
);

export const getMarker = (polylines: PolylineAndStepStatus[]): LatLng => {
    if (!polylines?.length) {
        return null;
    }

    if (polylines[0].stepStatus !== ORDER_STEP_TYPE.CLOSED) {
        return polylines[0].path[0];
    }

    for (let i = 1; i < polylines.length; i++) {
        if (polylines[i - 1].stepStatus === ORDER_STEP_TYPE.CLOSED
            && polylines[i].stepStatus !== ORDER_STEP_TYPE.CLOSED) {
            return polylines[i].path[0];
        }
    }

    return polylines[polylines.length - 1].path[1];
};

export const getPolylines = (milestones: Milestones[] = []): PolylineAndStepStatus[] => {
    return milestones?.reduce((polylines, milestones: Milestones) => {
        const {
            pickupLocation = null,
            deliveryLocation = null,
            progress = null,
        } = milestones;

        if (isPathCorrect(pickupLocation, deliveryLocation)) {
            return [
                ...polylines,
                getPolyline(pickupLocation, deliveryLocation, progress.status),
                getPolyline(pickupLocation, deliveryLocation, progress.status),
            ];
        } else if (isPathCorrect(pickupLocation, deliveryLocation)) {
            return [
                ...polylines,
                getPolyline(pickupLocation, deliveryLocation, progress.status),
            ];
        }
        return polylines;
    }, []);
};
export const getCurrentPosition = (milestones: Milestones[] = []): LatLng => {
    let currentPosition = null;

    if (milestones.find(it => it.progress.status === ORDER_STEP_TYPE.IN_PROGRESS)) {
        const intersectingStep = milestones.find(it => it.progress.status === ORDER_STEP_TYPE.IN_PROGRESS);

        currentPosition = intersectingStep?.pickupLocation || null;
    } else if (milestones.find(it => (it.progress.status === ORDER_STEP_TYPE.CLOSED
        || it.progress.status === ORDER_STEP_TYPE.COMPLETED))) {
        const [intersectingStep] = milestones.filter(it => (it.progress.status === ORDER_STEP_TYPE.CLOSED
            || it.progress.status === ORDER_STEP_TYPE.COMPLETED)).slice(-1);

        currentPosition = intersectingStep?.deliveryLocation || null;
    } else {
        const [intersectingStep] = milestones;

        currentPosition = intersectingStep?.pickupLocation || null;
    }

    if (!currentPosition) return null;
    const {
        longitude: lng,
        latitude: lat,
    } = currentPosition?.geolocation || {};

    return lng && lat ? {
        lat,
        lng,
    } : null;
};

export const getGenericApprovalStatuses = (shipment: GenericShipment): string => {
    return shipment.status;
};
export const getShipmentTimeRange = (
    shipmentStart: string,
    shipmentEnd: string,
): TimeRange => {
    const from = shipmentStart
        ? moment(shipmentStart).utc().format('YYYY-MM-DDTHH:mm')
        : null;

    const to = shipmentEnd !== null
        ? shipmentEnd
        : moment().utc().format('YYYY-MM-DDTHH:mm');

    return { from, to };
};

export const getShipmentData = (
    rawData: ShipmentWithSharedField[] = [],
): GenericShipmentData[] => {
    const processedData = rawData.map(shipment => {
        const {
            id,
            cargo = [],
            pharmaCompany = null,
            externalId,
            status = '',
            forwarderId = '',
            hawbNumber = '',
            mawbNumber = '',
            milestones: rawMilestones = null,
            customerReference = '',
            customerSpecificInformation = null,
            temperatureRange = '',
            originAirport = '',
            destinationAirport = '',
            shipmentStart = null,
            shipmentEnd = null,
        } = shipment;

        const {
            name: pharmaCompanyName = '',
        } = pharmaCompany || {};

        const milestones = rawMilestones.sort((a, b) => Number(a.index) - Number(b.index));

        const approvalStatuses = getApprovalStatuses(cargo);
        const temperatureStatuses = getTemperatureStatuses(cargo);
        const polylines = getPolylines(milestones);
        const currentPosition = getCurrentPosition(milestones);
        const packagings = (cargo);
        const statuses = {
            TRANSPORT: 'Transport',
            CLOSED: 'Closed',
            NOT_STARTED: 'Not Started',
        };
        const energyLevel = getEnergyLevels(cargo);
        const energyLevelsList = energyLevel
            .map(item => ((item?.remainingEnergyLevel / 1) * 100).toFixed(0));

        return {
            id,
            externalId,
            customerReference,
            status: statuses[status],
            cargo,
            customerSpecificInformation,
            destinationAirport,
            forwarderId,
            isAllApproved: checkIsAllApproved(approvalStatuses),
            isPending: checkIsPending(approvalStatuses),
            isWarning: temperatureStatuses.includes(TEMPERATURE_STATUS.EXCURSION),
            marker: getMarker(polylines),
            mawbNumber,
            hawbNumber,
            originAirportCity: '',
            originAirport,
            packagings,
            packagingSquareIcons: getPackagingIcons(approvalStatuses),
            packagingTypesCount: getPackagingTypesCount(cargo),
            energyLevelsList,
            pharmaCompanyName,
            polylines,
            temperatureRange,
            shipmentStart,
            shipmentEnd,
            shipmentSensorDataTimeRange: getShipmentTimeRange(
                shipmentStart,
                shipmentEnd,
            ),
            currentPosition,
        };
    });

    return processedData;
};

export interface AvailableFilterOptions {
    allShipmentCount: number,
    allPackagingCount: number,
    shipmentStatus: CheckboxOption[],
    temperatureStatus: CheckboxOption[],
    originAirports: string[],
    destinationAirports: string[],
}

export const initialAvailableFilterOptions: AvailableFilterOptions = {
    allShipmentCount: 0,
    allPackagingCount: 0,
    shipmentStatus: [],
    temperatureStatus: [],
    originAirports: [],
    destinationAirports: [],
};

const getFilterOptions = (shipments: GenericShipmentData[] = []): { [key: string]: string[] } => {
    return shipments.reduce((data, shipment) => {
        const {
            shipmentStatusArr = [],
            temperatureStatusArr = [],
            originAirportsArr = [],
            destinationAirportsArr = [],
        } = data;
        const {
            status = null,
            packagings = [],
            originAirport,
            destinationAirport,
        } = shipment;

        const temperatureStatuses = packagings.reduce((data, packaging) => {
            const { temperatureStatus = null } = packaging.temperatureCheckResult || {};

            return temperatureStatus
            && !data.includes(temperatureStatus)
            && !temperatureStatusArr.includes(temperatureStatus)
                ? [...data, temperatureStatus]
                : data;
        }, []);

        return {
            shipmentStatusArr: status && !shipmentStatusArr.includes(status)
                ? [...shipmentStatusArr, status]
                : shipmentStatusArr,
            temperatureStatusArr: temperatureStatusArr.concat(temperatureStatuses),
            originAirportsArr: originAirport && !originAirportsArr.includes(originAirport)
                ? [...originAirportsArr, originAirport]
                : originAirportsArr,
            destinationAirportsArr: destinationAirport && !destinationAirportsArr.includes(destinationAirport)
                ? [...destinationAirportsArr, destinationAirport]
                : destinationAirportsArr,
        };
    }, {
        shipmentStatusArr: [],
        temperatureStatusArr: [],
        originAirportsArr: [],
        destinationAirportsArr: [],
    });
};

const fetchShipmentStatusOptions = (
    shipments: GenericShipmentData[], availableStatuses: string[], labels = {}, descriptions = {},
): CheckboxOption[] => {
    return availableStatuses.map(option => ({
        count: shipments.filter(item => item.status === option).length,
        label: labels[option] || option,
        value: option,
        description: descriptions[option] || '',
    }));
};

const getPackagingCountByTemperatureStatus = (shipments: GenericShipmentData[], status: string): number => {
    return shipments.reduce((count, shipment) => {
        const { packagings = [] } = shipment;

        return packagings.length === 0
            ? count
            : count + packagings.filter(packaging => {
                const { temperatureStatus = null } = packaging.temperatureCheckResult || {};

                return temperatureStatus === status;
            }).length;
    }, 0);
};

const fetchTemperatureStatusOptions = (
    shipments: GenericShipmentData[], availableStatuses: string[], labels = {}, descriptions = {},
): CheckboxOption[] => {
    return availableStatuses.map(option => ({
        count: getPackagingCountByTemperatureStatus(shipments, option),
        label: labels[option] || option,
        value: option,
        description: descriptions[option] || '',
    }));
};

export const getAvailableFilterOptions = (
    shipments: GenericShipmentData[] = [],
    shipmentStatusLabels = {},
    temperatureStatusLabels = {},
    shipmentStatusDescriptions = {},
    temperatureStatusDescriptions = {},
): AvailableFilterOptions => {
    const options = getFilterOptions(shipments);

    const shipmentStatus = fetchShipmentStatusOptions(
        shipments, options.shipmentStatusArr, shipmentStatusLabels, shipmentStatusDescriptions,
    );
    const temperatureStatus = fetchTemperatureStatusOptions(
        shipments, options.temperatureStatusArr, temperatureStatusLabels, temperatureStatusDescriptions,
    );
    const allPackagingCount = temperatureStatus.reduce((sum, option) => {
        return sum + option.count;
    }, 0);

    const { originAirportsArr = [], destinationAirportsArr = [] } = options;

    return {
        allShipmentCount: shipments.length,
        allPackagingCount,
        shipmentStatus,
        temperatureStatus,
        originAirports: originAirportsArr.sort(),
        destinationAirports: destinationAirportsArr.sort(),
    };
};

export const getShipmentStatusCount = (shipments: GenericShipmentData[]): { [key: string]: number } => {
    const statuses = shipments.map(({ status }) => status);
    const availableStatuses = [...(new Set(statuses))];

    return availableStatuses.reduce((data, option) => {
        return {
            ...data,
            [option]: statuses.filter(status => status === option).length,
        };
    }, {});
};

export interface ClientSideFilter {
    shipmentStatus: string[],
    temperatureStatus: string[],
    originAirports: string[],
    destinationAirports: string[],
}

export const initialClientSideFilter: ClientSideFilter = {
    shipmentStatus: [],
    temperatureStatus: [],
    originAirports: [],
    destinationAirports: [],
};

export interface FilterOptionsCount {
    shipmentStatusCount: { [key: string]: number },
    temperatureStatusCount: { [key: string]: number },
}

export const getPackagingsCount = (shipments: GenericShipmentData[] = []) : { [key: string]: number } => {
    const options = getFilterOptions(shipments);

    const temperatureStatus = fetchTemperatureStatusOptions(shipments, options.temperatureStatusArr);

    return temperatureStatus.reduce((data, option) => {
        return {
            ...data,
            [option.value]: option.count,
        };
    }, {});
};

export const initialFilterOptionsCount: FilterOptionsCount = {
    shipmentStatusCount: {},
    temperatureStatusCount: {},
};

const areCoordinatesEqual = (point1: LatLng, point2: LatLng): boolean => {
    return point1.lat === point2.lat && point1.lng === point2.lng;
};

export interface ClusterInfo {
    id: number,
    position: LatLng,
    hasPending: boolean,
    hasWarning: boolean,
    externalIds: string[],
}

export const getClustersInfo = (shipments: GenericShipmentData[] = []): ClusterInfo[] => shipments
    .reduce((data: ClusterInfo[], shipment) => {
        if (!shipment.currentPosition) {
            return data;
        }

        const clusterIndexWithSameLocation = data
            .findIndex(cluster => areCoordinatesEqual(cluster.position, shipment.currentPosition));

        return clusterIndexWithSameLocation === -1
            ? [
                ...data,
                {
                    id: data.length,
                    position: shipment.currentPosition,
                    hasPending: shipment.isPending,
                    hasWarning: shipment.isWarning,
                    externalIds: [shipment.externalId],
                },
            ] : [
                ...data.slice(0, clusterIndexWithSameLocation),
                {
                    id: data[clusterIndexWithSameLocation].id,
                    position: data[clusterIndexWithSameLocation].position,
                    hasPending: data[clusterIndexWithSameLocation].hasPending || shipment.isPending,
                    hasWarning: data[clusterIndexWithSameLocation].hasWarning || shipment.isWarning,
                    externalIds: [
                        ...data[clusterIndexWithSameLocation].externalIds,
                        shipment.externalId,
                    ],
                },
                ...data.slice(clusterIndexWithSameLocation + 1),
            ];
    }, []);

export const getFilteredShipmentsByAirports = (
    data: GenericShipmentData[] = [],
    filters: ClientSideFilter,
) => {
    return data.filter((shipment) => {
        return filters.originAirports.includes(shipment.originAirport)
            && filters.destinationAirports.includes(shipment.destinationAirport);
    });
};

export const getFilteredShipmentsByShipmentStatus = (
    data: GenericShipmentData[] = [],
    filters: ClientSideFilter,
) => {
    const { shipmentStatus } = filters;

    return data.filter((shipment) => shipmentStatus.includes(shipment.status));
};

export const getFilteredShipmentsByTemperatureStatus = (
    data: GenericShipmentData[] = [],
    filters: ClientSideFilter,
) => {
    const { temperatureStatus } = filters;

    return data.filter((shipment) => {
        return (shipment?.packagings?.length === 0
            ? true
            : shipment?.packagings?.some(item => {
                const { temperatureCheckResult } = item;

                return temperatureStatus.includes(temperatureCheckResult.temperatureStatus);
            }));
    });
};
