import React, { useContext, useMemo } from 'react';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { CHART_GRAPH_COLORS, TEMPERATURE_RANGES_VALUES } from 'shared-components/constants';
import moment from 'moment';
import LaneTemperatureContext from 'Contexts/LaneTemperatureContext';
import { downloadIcon } from 'shared-components/ApexTemperatureChart/icons';
import { useStyles } from './TemperatureChart.style';

type Props = {
    type: 'winter' | 'summer',
    timeInterval?: 5 | 10
}

const TemperatureChart = ({
    type = 'summer',
    timeInterval = 10,
}: Props) => {
    const {
        lane,
        profileData,
        packagingTypes,
    } = useContext(LaneTemperatureContext);
    const classes = useStyles();
    const selectedPackagingTypeCodes = useMemo(() => {
        return lane?.packagingTypes?.map(it => it.code) || [];
    }, [lane]);

    const tempLimits = useMemo(() => {
        return TEMPERATURE_RANGES_VALUES[lane?.temperatureRange || 'CRT'];
    }, [lane]);

    const {
        tempRangeMin,
        tempRangeMax,
    } = tempLimits || {};

    const colors = useMemo(() => {
        const clrs = [];
        const chartClrs = [...CHART_GRAPH_COLORS];

        selectedPackagingTypeCodes
            ?.filter(code => packagingTypes.find(pt => pt.type === code))
            ?.forEach((code, i) => {
                if (profileData?.[code]?.winter || profileData?.[code]?.summer) clrs.push(chartClrs[i]);
            });

        return clrs;
    }, [profileData, selectedPackagingTypeCodes]);

    const series = useMemo(() => {
        // eslint-disable-next-line no-unused-vars
        const data = (selectedPackagingTypeCodes || [])
            .filter(code => profileData?.[code]?.winter || profileData?.[code]?.summer)
            .map(key => {
                const simulation = profileData?.[key]?.[type]?.simulationData || [];
                const packagingType = packagingTypes.find(pt => pt.type === key);

                const name = `${packagingType.temperatureSpecificName}`;

                return {
                    name,
                    data: simulation?.map(it => it.simulatedTemperature),
                };
            });

        return (data || []).filter((it => it?.data?.length !== 0));
    }, [type, profileData, selectedPackagingTypeCodes]);

    const categories = useMemo(() => {
        // eslint-disable-next-line no-unused-vars
        const data = selectedPackagingTypeCodes
            ?.filter(code => profileData?.[code]?.winter || profileData?.[code]?.summer)
            ?.map(key => {
                const simulation = profileData?.[key]?.[type]?.simulationData || [];

                // return simulation.map(it => moment(it.timestamp, 'DD.MM.YYYY HH:mm ZZ').valueOf());
                return simulation.map(it => it.time * timeInterval * 60);
            });

        return data?.[0] || [];
    }, [type, profileData, selectedPackagingTypeCodes]);

    const options = useMemo((): ApexOptions => {
        return {
            chart: {
                type: 'line',
                height: 350,
                toolbar: {
                    export: {
                        csv: {
                            filename: undefined,
                            columnDelimiter: ',',
                            dateFormatter(timestamp) {
                                return moment(timestamp).utc(false).format('YYYY-MM-DD HH:mm');
                            },
                        },
                    },
                    tools: {
                        download: downloadIcon,
                    },
                },
            },
            stroke: {
                width: 2,
                curve: 'straight',
            },
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: true,
            },
            annotations: {
                yaxis: [
                    tempRangeMin && {
                        y: tempRangeMin,
                        borderColor: '#EDAE49',
                        strokeDashArray: 3,
                        borderWidth: 3,
                        label: {
                            style: {
                                fontSize: '15px',
                                color: '#EDAE49',
                                background: 'none',
                            },
                            offsetX: -10,
                            offsetY: 18,
                            textAnchor: 'end',
                            borderWidth: 0,
                            text: 'Min',
                        },
                    },
                    tempRangeMax && {
                        y: tempRangeMax,
                        borderColor: '#EDAE49',
                        strokeDashArray: 3,
                        borderWidth: 3,
                        label: {
                            style: {
                                fontSize: '15px',
                                color: '#EDAE49',
                                background: 'none',
                            },
                            offsetX: -10,
                            textAnchor: 'end',
                            borderWidth: 0,
                            text: 'Max',
                        },
                    },
                ],
            },
            xaxis: {
                categories: ['0', ...categories],
                labels: {
                    formatter(value) {
                        if (Number(value) < 0) return '';
                        let hours: any = Math.floor(Number(value) / 60 / 60);

                        let minutes: any = (Number(value) / 60) % 60;

                        if (hours === 0 && minutes === 0) return '0h';
                        if (minutes === 0) minutes = ''; else minutes = `${Math.round(minutes)}m`;
                        if (hours === 0) hours = ''; else hours = `${hours}h`;
                        return `${hours} ${minutes}`;
                    },
                },
                type: 'numeric',
            },
            colors,
            tooltip: {
                shared: true,
                x: {
                    show: true,
                },
                y: {
                    // eslint-disable-next-line no-unused-vars
                    formatter(value: any, { series, seriesIndex, dataPointIndex, w }): string {
                        return Number.isNaN(value) ? undefined : `${value}°C`;
                    },
                },
            },

            yaxis: {
                min: Math.min(tempRangeMin, Math.min(...series.map(it => it.data).flat())) - 2,
                max: Math.max(tempRangeMax, Math.max(...series.map(it => it.data).flat())) + 2,
                labels: {
                    formatter(val: number): string | string[] {
                        return `${(val).toFixed(1)}°C`;
                    },
                },
            },
        };
    }, [
        tempRangeMax,
        tempLimits,
        categories,
        series,
        profileData,
        colors,
    ]);

    return (
        <div className={classes.chart}>
            <ReactApexChart
                options={options}
                height={250}
                series={series}
                type="line"
            />
        </div>
    );
};

export default TemperatureChart;
