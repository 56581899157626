import React from 'react';
import Card from 'shared-components/Card';
import PercentageScale from 'shared-components/PercentageScale';
import icons from 'shared-components/icons';

import { makeStyles } from '@mui/styles';
import useCustomTranslation from 'hooks/useCustomTranslation';

const useStyles = makeStyles(theme => ({
    firstPercentageScaleContainer: {
        marginTop: '18px',
        marginBottom: '12px',
    },
    percentageScaleContainer: {
        marginBottom: '12px',
    },
    profileContainer: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    extendedProfileIcon: {
        marginRight: '7px',
        width: '21px',
        height: '21px',
    },
    personIcon: {
        marginRight: '7px',
    },
    title: {
        marginTop: '20px',
        marginBottom: '1px',
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: theme.palette.secondary[500],
        opacity: 1,
    },
    contactNameContainer: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
}));

type Props = {
    className?: string,
    definedBy?: string,
    extendedProfile?: boolean,
    id: string,
    reviewedBy?: string,
    totalStatistics: {
        all: number,
        core: number,
    },
}

const SummaryCard = ({
    className = '',
    definedBy = '',
    extendedProfile = false,
    id,
    reviewedBy = '',
    totalStatistics,
} : Props) => {
    const classes = useStyles();
    const cardSize = document.getElementById(id)?.offsetWidth;
    const { t } = useCustomTranslation();

    return (
        <Card title={t('COMMON.SUMMARY')} className={className}>
            <div id={id} className={classes.firstPercentageScaleContainer}>
                <PercentageScale
                    size={cardSize < 320 ? 20 : 30}
                    value={totalStatistics.core}
                    label={`${totalStatistics.core}%`}
                    title={t('COMMON.CORE_CAPABILITIES_ANSWERED')}
                />
            </div>
            <div className={classes.percentageScaleContainer}>
                <PercentageScale
                    size={cardSize < 320 ? 20 : 30}
                    value={totalStatistics.all}
                    label={`${totalStatistics.all}%`}
                    title={t('COMMON.ALL_CAPABILITIES_ANSWERED')}
                />
            </div>
            {
                extendedProfile && (
                    <div className={classes.profileContainer}>
                        <img
                            alt="Extended Profile"
                            className={classes.extendedProfileIcon}
                            src={icons.star}
                        />
                        <div>{t('SERVICE_PROVIDERS.EXTENDED_PROFILE')}</div>
                    </div>
                )
            }
            <div className={classes.title}>{t('SERVICE_PROVIDERS.DEFINED_BY')}</div>
            <div className={classes.profileContainer}>
                <img
                    alt="Defined By"
                    className={classes.personIcon}
                    src={icons.a_gender_person}
                />
                <div>{definedBy}</div>
            </div>
            <div className={classes.title}>{t('SERVICE_PROVIDERS.REVIEWED_BY')}</div>
            <div className={classes.profileContainer}>
                <img
                    alt="Reviewed By"
                    className={classes.personIcon}
                    src={icons.a_gender_person}
                />
                <div>{reviewedBy}</div>
            </div>
        </Card>
    );
};

export default SummaryCard;
