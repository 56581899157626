import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    galleryContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridGap: '12px',
    },
});

export default useStyles;
