import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    quickHelp: {
        color: theme.palette.secondary[600],
        zIndex: 105,
        opacity: 0,
        pointerEvents: 'none',
        transition: 'opacity 200ms ease',
        display: 'flex',
        minWidth: '400px',
        maxWidth: '400px',
    },
    tooltipVisible: {
        opacity: 1,
        pointerEvents: 'all',
    },
    quickHelpRight: {
        flexDirection: 'row-reverse',
        '& $tooltipBodyWrapper': {
            flexDirection: 'row-reverse',
        },
        '& $tooltipTriangle': {
            flexDirection: 'row-reverse',
            transform: 'skew(-8deg, -39deg) rotate(-50deg) translateY(2px) translateX(8px)',
        },
        '& $badge': {
            transform: 'translate(-7px, -15px)',
        },
    },
    badge: {
        borderRadius: '10px',
        backgroundColor: theme.palette.primary[300],
        fontSize: '28px',
        fontWeight: 'bold',
        color: theme.palette.common.white,
        height: '33px',
        minWidth: '26px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transform: 'translate(7px, -15px)',
        zIndex: 101,
        boxShadow: '0px 0px 4px #8a8a8a',
    },
    tooltipBodyWrapper: {
        display: 'flex',
        alignItems: 'center',
        filter: 'drop-shadow(2px 2px 3px #747474)',
        width: '100%',
    },
    tooltipBody: {
        padding: '10px',
        backgroundColor: theme.palette.common.white,
        borderRadius: '10px',
        minHeight: '50px',
        display: 'flex',
        flexDirection: 'row',
        lineHeight: 'normal',
        width: '100%',

    },
    tooltipTriangle: {
        width: '0px',
        height: '0px',
        borderTop: '20px solid transparent',
        borderBottom: '20px solid transparent',
        borderLeft: `20px solid ${theme.palette.common.white}`,
        transform: 'skew(-8deg, 4deg) rotate(-10deg) translateY(-1px) translateX(-10px)',
    },
    line: {
        width: '2px',
        backgroundColor: theme.palette.primary[200],
        marginRight: '8px',
    },
    content: {
        '& span': {
            fontSize: '16px',
            fontWeight: 'normal',
            lineHeight: 1.2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        lineHeight: '20px',
    },
    linkHolder: {
        marginTop: '20px',
        whiteSpace: 'nowrap',
    },
}));
