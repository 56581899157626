import React, { useMemo } from 'react';
import PolygonIcon from 'shared-components/PolygonMarker/PolygonIcon';
import PolygonMarker from 'shared-components/PolygonMarker';
import useCustomTranslation from 'hooks/useCustomTranslation';
import StandalonePagination from 'TrackAndTrace/Assets/components/StandalonePagination';
import useScreenSize from 'hooks/useScreenSize';
import { ScreenType } from 'hooks/useScreenSize/useScreenSize';
import { useStyles } from '../../Assets.style';

type Props = {
    withoutGeolocationCount?: number,
};
const LegendPanel = ({
    withoutGeolocationCount,
}: Props) => {
    const classes = useStyles();
    const { t } = useCustomTranslation();

    const withoutGeolocationText = useMemo(() => {
        // I18n templates to be used with interpolation
        const text = withoutGeolocationCount === 1
            ? t('TRACK_AND_TRACE.ASSET_WITHOUT_GEOLOCATION_NOTICE')
            : t('TRACK_AND_TRACE.ASSETS_WITHOUT_GEOLOCATION_NOTICE');

        return text.replace('#count', `${withoutGeolocationCount}`);
    }, [withoutGeolocationCount]);

    const {
        checkScreenType,
    } = useScreenSize();
    const iconSizes = useMemo(() => (checkScreenType.down(ScreenType.LG) ? 25 : 35), [checkScreenType]);

    return (
        <div>
            <div className={classes.legendContainer}>
                <div className={`${classes.legend} ${classes.legendPoints}`}>
                    <span className={classes.legendText}>{t('COMMON.LEGEND')}</span>
                    <div className={classes.legendPointsWrapper}>

                        <div className={classes.legendPoint}>
                            <PolygonIcon
                                className={classes.legendIcon}
                                type="hexa"
                                theme="light"
                                iconDiameter={iconSizes}
                            />
                            {t('TRACK_AND_TRACE.ASSET')}
                        </div>
                        <div className={classes.legendPoint}>
                            <PolygonMarker
                                className={classes.legendIcon}
                                polygonIconType="hexa"
                                theme="light"
                                count={99}
                                iconDiameter={iconSizes}
                            />
                            {t('TRACK_AND_TRACE.ASSET_GROUP')}
                        </div>
                        <div className={classes.legendPoint}>
                            <PolygonMarker
                                className={classes.legendIcon}
                                polygonIconType="hexa"
                                theme="light"
                                filled
                                iconDiameter={iconSizes}
                            />
                            {t('TRACK_AND_TRACE.SELECTED_ASSET')}
                        </div>
                    </div>
                </div>
                {
                    withoutGeolocationCount > 0 && (
                        <div>
                            <div className={`${classes.legend} ${classes.noGeolocation}`}>
                                {withoutGeolocationText}
                            </div>
                        </div>
                    )
                }
                <StandalonePagination wrap shrink />

            </div>

        </div>
    );
};

export default LegendPanel;
