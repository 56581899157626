import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    tooltipContainer: {
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    titleContainer: {
        display: 'flex',
    },
    title: {
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0px',
        color: theme.palette.secondary[900],
        opacity: 1,
    },
    tooltipBody: {
        margin: '2px 0',
    },
    icon: {
        width: '21px',
        height: '21px',
    },
    iconWithRightMargin: {
        marginRight: '10px',
        width: '21px',
        height: '21px',
    },
}));

export default useStyles;
