import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    customPeriod: {
        width: 'auto',
        cursor: 'pointer',
        padding: '1px 6px',
        textAlign: 'center',
        '&:hover': {
            borderRadius: '40px',
            color: theme.palette.primary[50],
            background: theme.palette.primary[500],
        },
    },
    active: {
        background: theme.palette.primary[700],
        color: theme.palette.primary[30],
        borderRadius: '40px',
    },
    periods: {
        display: 'flex',
        alignItems: 'center',
    },
    periodsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: theme.palette.common.black,
        '& > span': {
            marginRight: '19px',
        } },
}));

export default useStyles;
