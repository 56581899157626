import { makeStyles } from '@mui/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const COMMON_PADDING = 16;

export const useStyles = makeStyles((theme: SkycellThemeInterface) => ({
    root: {
        minHeight: '150px',
        width: '100%',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        borderRadius: '6px',
    },
    maximized: {
        height: '100%',
    },
    map: {
        flex: 3,
        padding: `${COMMON_PADDING}px`,
        display: 'flex',
        flexDirection: 'column',
        gap: `${COMMON_PADDING}px`,
        maxWidth: '100%',
    },
    options: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minWidth: '300px',
    },
    fieldTitle: {
        margin: '12px 0 4px 0',
        textAlign: 'left',
        font: 'normal normal normal 14px Roboto',
        letterSpacing: '0.4px',
        color: '#000',
        opacity: 1,
    },
    divider: {
        backgroundColor: theme.palette.secondary[200],
        flexBasis: '1px',
    },
    currentTemperature: {
        padding: `${COMMON_PADDING}px`,
        display: 'flex',
        flexDirection: 'column',
    },
    displayOptions: {
        padding: `${COMMON_PADDING - 9}px ${COMMON_PADDING}px ${COMMON_PADDING}px ${COMMON_PADDING}px`,
    },
    accordionDetails: {
        gap: '3px',
    },
    title: {
        fontSize: '18px',
        letterSpacing: '0.01em',
        color: theme.palette.secondary[800],
    },
    titleWithMaximize: {
        fontSize: '18px',
        letterSpacing: '0.01em',
        color: theme.palette.secondary[800],
        display: 'flex',
        justifyContent: 'space-between',
    },
    switchRow: {
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        fontSize: '12px',
        letterSpacing: '0.01em',
        textAlign: 'left',
        color: theme.palette.common.black,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& > label': {
            maxWidth: '75%',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    switchTrack: {
        backgroundColor: theme.palette.secondary[500],
    },
    switchChecked: {
        color: theme.palette.primary.deepBlue,
        '&+.MuiSwitch-track': {
            backgroundColor: 'white',
        },
    },
    switchDisabled: {
        color: theme.palette.secondary[900],
        '&+.MuiSwitch-track': {
            backgroundColor: theme.palette.secondary[600],
        },
    },
    integrity: {
        display: 'flex',
        gap: '10px',
        marginTop: '8px',
        fontSize: '14px',
    },
    temperature: {
        fontSize: '32px',
        letterSpacing: '0.01em',
        color: theme.palette.common.black,
        margin: '2px 0 14px 0',
    },
    tempRangeCheck: {
        fontSize: '16px',
        letterSpacing: '0.01em',
        color: theme.palette.common.black,
        display: 'flex',
        gap: '12px',
        flex: 1,
        width: '100%',
        alignItems: 'baseline',
        justifyContent: 'space-between',
    },
    chart: {
        minHeight: '250px',
        width: '100%',
    },
    gmap: {

    },
}));
