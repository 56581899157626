import React, { useState, useMemo, useCallback } from 'react';
import CardWithEditableContent from 'shared-components/CardWithEditableContent';
import { useStyles } from 'MyProfile/components/NotificationChannelSettingsCard/NotificationChannelSettingsCard.style';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useGetTranslationGroup from 'hooks/useGetTranslationGroup';
import NotificationChannelRow from 'MyProfile/components/NotificationChannelSettingsCard/NotificationChannelRow';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import useStatusStateProcessOptions from 'hooks/useStatusStateProcessOptions';

const loadingStyle = {
    transition: '200ms ease',
    filter: 'blur(2px)',
    pointerEvents: 'none',
};

export type NotificationChannel = 'IN_APP'| 'EMAIL'| 'SMS';
const cardName = 'NotificationChannelSettingsCard';
// SMS is not available yet, just add it to this array when available
const AVAILABLE_CHANNELS: NotificationChannel[] = ['IN_APP', 'EMAIL'];
const FILLER_CHANNELS_FOR_PADDING = 1;

export interface NotificationSettings {
    notificationTemplateId: string,
    notificationName: string,
    notificationGroup: string,
    configurable: boolean,
    state: "MANDATORY" | "OPTIONAL" | "OFF",
    allowedChannels: NotificationChannel[],
    suggestedState: "MANDATORY" | "OPTIONAL" | "OFF",
    channels: NotificationChannel[],
}
type TemplatesGroup = {
    [key: string]: NotificationSettings[],
}
export type NotificationSettingOverrides = {
    [key: string]: NotificationSettings,
}
type Props = {
    currentEditedCard?: string,
    setCurrentEditedCard?: (currentEditedCard: string) => void,
    notificationSettings?: NotificationSettings[],
    loading?: boolean,
    admin?: boolean,
    companyId?: string | number,
    setRequiredUpdateData: (requiredUpdateData: boolean) => void,
    setLoading: (loading: boolean) => void,
}
const AVAILABLE_OPTIONS = (t = w => w) => ['MANDATORY', 'OPTIONAL', 'OFF'].map((it) => ({
    value: it,
    label: t(`NOTIFICATION_CHANNEL_SETTINGS.${it}`),
}));
const NotificationChannelSettingsCard = ({
    currentEditedCard = null,
    setCurrentEditedCard,
    notificationSettings = [],
    loading,
    admin = false,
    companyId,
    setRequiredUpdateData,
    setLoading = () => { },
}: Props) => {
    const [editMode, setEditMode] = useState(false);
    const { t } = useCustomTranslation();
    const classes = useStyles();
    const categoryTranslation = useGetTranslationGroup('NOTIFICATION_CATEGORY');
    const [overrides, setOverrides] = useState<NotificationSettingOverrides>({ });
    const {
        FlexibleRequest: patchNotificationSettings,
    } = useSecureBackendEndpoints('users/notification-settings').requests;
    const {
        setErrorStatus,
    } = useStatusStateProcessOptions();
    const {
        FlexibleRequest: patchNotificationSettingsAdmin,
    } = useSecureBackendEndpoints(`companies/${companyId}/notification-settings`).requests;

    const groupedTemplates: TemplatesGroup = notificationSettings.reduce((acc, template) => {
        if (!acc[template.notificationGroup]) {
            acc[template.notificationGroup] = [];
        }
        acc[template.notificationGroup].push(template);
        return acc;
    }, {});

    const availableChannelStateOptions = useMemo(() => AVAILABLE_OPTIONS(t), []);
    const handleClickSaveButton = useCallback(async () => {
        let result;

        setLoading(true);
        try {
            if (!admin) {
                result = await Promise
                    .all(Object.keys(overrides)
                        .map((id) => patchNotificationSettings('PATCH', id, overrides[id])));
            } else {
                result = await Promise
                    .all(Object.keys(overrides)
                        .map((id) => {
                            const {
                                state,
                                channels,
                            } = overrides[id];
                            const original = notificationSettings.find(({
                                notificationTemplateId,
                            }) => notificationTemplateId === id);
                            const reqObj = {
                                state: state || original.state || original.suggestedState,
                                channels: channels
                                    || original.channels
                                    || [],
                            };

                            return patchNotificationSettingsAdmin('PATCH', id, reqObj).catch((error) => {
                                setErrorStatus(error?.response?.data?.errorMessage
                                    || t('TRACK_AND_TRACE.ADD_SHIPMENT.ERROR_HAPPENED'));
                            });
                        }));
            }
        } catch (e) {
            global.console.error(e);
        } finally {
            setRequiredUpdateData(true);
            setOverrides({});
            setEditMode(false);
            setCurrentEditedCard(null);
            setLoading(false);
        }
        global.console.log(result);
    }, [overrides, notificationSettings, admin]);

    const handleClickUndoButton = useCallback(() => {
        setCurrentEditedCard(null);
        setEditMode(false);
        setOverrides({});
    }, []);
    const handleClickEditButton = useCallback(() => {
        setCurrentEditedCard(cardName);
        setEditMode(true);
    }, []);

    return (
        <CardWithEditableContent
            className={classes.root}
            style={loading ? loadingStyle : { }}
            onClickEditButton={handleClickEditButton}
            onClickSaveButton={handleClickSaveButton}
            onClickUndoButton={handleClickUndoButton}
            showEditButton={!loading && !editMode}
            showSaveButton={!loading && editMode}
            showUndoButton={!loading && editMode}
            loadingIcon={loading}
            disabledEditButton={currentEditedCard !== null && currentEditedCard !== cardName}
            title={t('NOTIFICATION_CHANNEL_SETTINGS.NOTIFICATION_CHANNEL_SETTINGS')}
        >
            <div className={classes.notificationSettingsTable}>
                {
                    Object.entries(groupedTemplates || []).sort((
                        a, b,
                    ) => (a[0] < b[0] ? -1 : 1))
                        .map(([header, groupedArray]) => {
                            const gridChannels = AVAILABLE_CHANNELS.length + FILLER_CHANNELS_FOR_PADDING;
                            const gridSettings = `1fr ${admin ? '120px' : ''} ${new Array(gridChannels)
                                .fill(null).map(() => '80px').join(' ')}`;
                            const tg = 'NOTIFICATION_CHANNEL_SETTINGS'; // translation group
                            const maxRows = Math.max(
                                ...Object.values(groupedTemplates)
                                    .map(it => it.filter(row => (row?.state !== 'OFF') || admin))
                                    .map((it) => it.length),
                            ) + 1;

                            const groupedArrayFiltered = groupedArray.filter(row => (row?.state !== 'OFF') || admin);

                            const filledTemplates: NotificationSettings[] = [
                                ...groupedArrayFiltered,
                                ...(new Array(maxRows - groupedArrayFiltered.length).fill(null)),
                            ];

                            return (
                                <div className={classes.tablePart}>
                                    <div
                                        style={{
                                            gridTemplateColumns: gridSettings,
                                        }}
                                        className={[classes.tableRow, classes.tableHeaderRow].join(' ')}
                                    >
                                        <div className={classes.tableCell}>{t(`${tg}.${header}`)}</div>
                                        {
                                            admin && (
                                                <div className={[
                                                    classes.tableCell,
                                                ].join(' ')}
                                                >
                                                    {t('NOTIFICATION_CHANNEL_SETTINGS.AVAILABILITY')}
                                                </div>
                                            )
                                        }
                                        {
                                            AVAILABLE_CHANNELS.map((channel) => (
                                                <div className={[
                                                    classes.tableCell, classes.channelCell,
                                                ].join(' ')}
                                                >
                                                    {t(`${tg}.${channel}`)}
                                                </div>
                                            ))
                                        }
                                    </div>
                                    {
                                        filledTemplates
                                            .sort((a, b) => (
                                                a?.notificationTemplateId < b?.notificationTemplateId ? -1 : 1))
                                            .map((row) => (
                                                <NotificationChannelRow
                                                    admin={admin}
                                                    row={row}
                                                    overrides={overrides}
                                                    setOverrides={setOverrides}
                                                    availableChannelStateOptions={availableChannelStateOptions}
                                                    classes={classes}
                                                    categoryTranslation={categoryTranslation}
                                                    availableChannels={AVAILABLE_CHANNELS}
                                                    editMode={editMode}
                                                    gridSettings={gridSettings}
                                                />
                                            ))
                                    }

                                </div>
                            );
                        })
                }
            </div>
            <div className={classes.help}>
                <span className={classes.tooltipIcon}>?</span>
                {t('NOTIFICATION_CHANNEL_SETTINGS.HELP_TO_CUSTOMIZE')}
            </div>
        </CardWithEditableContent>
    );
};

export default NotificationChannelSettingsCard;
