import React, { useMemo } from 'react';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import { useTheme } from '@mui/material/styles';
import UncontrolledTooltip from 'shared-components/ControlledTooltip/UncontrolledTooltip';
import useCustomTranslation from 'hooks/useCustomTranslation';

type Props = {
    temperatureCheckStatus: string,
    size?: string,
}
const TemperatureCheckIcon = ({
    temperatureCheckStatus = null,
    size = '20px',
}: Props) => {
    const theme = useTheme<SkycellThemeInterface>();
    const {
        t,
    } = useCustomTranslation();

    const colorMap = useMemo(() => ({
        IN_RANGE: theme.palette.primary.deepBlue,
        IN_RANGE_PARTIAL_DATA: theme.palette.primary.deepBlue,
        POTENTIAL_EXCURSION: '#ba2c31',
        IN_RANGE_ONGOING: theme.palette.primary.deepBlue,
        NOT_APPLICABLE: theme.palette.primary.deepBlue,
        UNKNOWN: theme.palette.primary.deepBlue,
        IN_RANGE_NOT_COMPLETED: theme.palette.primary.deepBlue,
        EXCURSION: '#ba2c31',
        NO_DATA: theme.palette.secondary[600],
        WAITING_FOR_DATA: theme.palette.secondary[600],
    }), [temperatureCheckStatus, theme]);

    const icon = useMemo(() => (temperatureCheckStatus ? (
        <div style={{ fontSize: size }}>
            <svg
                width="1em" height="1em" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg"
            >
                <defs>
                    <mask id="cutoutMask">
                        <rect width="100%" height="100%" fill="white" />
                        <rect
                            x="30" y="0" width="20" height="200"
                            fill="black"
                        />
                        <rect
                            x="90" y="0" width="20" height="200"
                            fill="black"
                        />
                        <rect
                            x="150" y="0" width="20" height="200"
                            fill="black"
                        />
                    </mask>
                </defs>
                <circle
                    cx="100" cy="100" r="95" fill={colorMap[temperatureCheckStatus] || 'transparent'}
                    {...((temperatureCheckStatus === 'IN_RANGE_PARTIAL_DATA'
                        || temperatureCheckStatus === 'POTENTIAL_EXCURSION') && { mask: 'url(#cutoutMask)' })}
                />
            </svg>
        </div>
    ) : null), [temperatureCheckStatus]);

    if (temperatureCheckStatus === null) return <></>;

    return (
        <UncontrolledTooltip
            description={(
                <div style={{
                    color: theme.palette.common.black,
                }}
                >
                    <span style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '6px',
                    }}
                    >
                        <span style={{
                            display: 'inline-block',
                            width: '20px',
                            height: '20px',
                            margin: '6px 3px',
                        }}
                        >
                            {icon}
                        </span>

                        {t(`TEMPERATURE_STATUS.${temperatureCheckStatus}`)}
                    </span>
                    {t(`TEMPERATURE_STATUS_TOOLTIP_DESCRIPTION.${temperatureCheckStatus}`)}
                </div>
            )}
        >
            {icon}
        </UncontrolledTooltip>

    );
};

export default TemperatureCheckIcon;
