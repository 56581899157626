interface Response { [key: string]: string }

const parseQueryParams = (historyLocationSearch: string = ''): Response => {
    try {
        if (historyLocationSearch) {
            return historyLocationSearch.replace('?', '').split('&').reduce((data, query) => {
                const [key, value] = query.split('=');

                return { ...data, [key]: value };
            }, {});
        }
        return {};
    } catch (error) {
        return {};
    }
};

export default parseQueryParams;
