import React, { useState, useCallback, useMemo, CSSProperties, useEffect } from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useHasAccess, { userRoles } from 'hooks/useHasAccess';
import useCustomTitle from 'hooks/useCustomTitle';
import BackToLink from 'shared-components/BackToLink';
import PackagingTemperatureInfo from 'TrackAndTrace/GenericShipmentDetails/components/PackagingTemperatureInfo';
import useAvailableHeight from 'hooks/useAvailableHeight';
import PackagingViewOptions
from 'TrackAndTrace/GenericShipmentDetails/components/PackagingViewOptions/PackagingViewOptions';
import { GenericCargo, Milestones } from 'dataTypes/SecureBackend/apiResponse/Shipment';
import { LaneObject } from 'shared-components/dataTypes';
import { SensorDataItem } from 'dataTypes/SecureBackend/apiResponse';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import Card from 'shared-components/Card';
import { Button, useTheme } from '@mui/material';
import { DownloadComponentIcon } from 'shared-components/ApexTemperatureChart/icons';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import icons from 'shared-components/icons';
import Approval from './components/Approval';
import Summary from './components/Summary';
import useStyles from './GenericShipmentDetails.style';
import { ShipmentDetailsInterface } from './lib';
import PackagingHealth from './components/PackagingHealth/PackagingHealth';
import ShipmentSteps from './components/ShipmentSteps';
import { generatePdf } from './pdfGenerator';
import UncontrolledTooltip from '../../shared-components/ControlledTooltip/UncontrolledTooltip';

type Props = {
    shipment?: ShipmentDetailsInterface,
    shipmentId?: string,
    milestones?: Milestones[],
    lane?: LaneObject,
    cargo?: GenericCargo[],
    setRequiredOrderDataUpdate?: (requiredOrderDataUpdate: boolean) => void,
}

export type HiddenSeries = {
    [position: string]: boolean
}
export type ChartDataToExport = {
    [packagingSerialNumber: string]: {
        base64: string,
        chartAspectRatio: number,
        latestTemp: SensorDataItem[],
        labels: {
            dataTypes: string[],
            loggerTypes: string[],
            positions: string[],
        },
    }
}
const GenericShipmentDetails = ({
    shipmentId,
    milestones,
    lane,
    cargo,
    shipment,
    setRequiredOrderDataUpdate,
}: Props) => {
    const classes = useStyles();
    const { setTitle } = useCustomTitle();
    const { t } = useCustomTranslation();
    const hasAccess = useHasAccess();
    const displayProductReleaseFunctionality = hasAccess(userRoles.PRODUCT_RELEASE);
    const [showTempRange, setShowTempRange] = useState(true);
    const [showMeasuredOnly, setShowMeasuredOnly] = useState(
        shipment.status === 'CLOSED',
    );
    const [showMarkers, setShowMarkers] = useState(false);
    const [showInUTC, setShowInUTC] = useState(false);
    const [showExpTemp, setShowExpTemp] = useState(shipment.externalId === 'CT-023321');
    const [activePackagingSerialNumber, setActivePackagingSerialNumber] = useState<string>(null);
    const availableHeight = useAvailableHeight();
    const [chartDataToExport, setChartDataToExport] = useState<ChartDataToExport>({});
    const [hiddenSeries, setHiddenSeries] = useState<HiddenSeries>(
        {
            AMBIENT: false,
            DOOR: false,
        },
    );
    const theme = useTheme<SkycellThemeInterface>();
    const {
        userInfo,
    } = useCurrentUserContext();
    const { Get: getCompany } = useSecureBackendEndpoints('companies').requests;
    const [shipperName, setShipperName] = useState<string>(null);
    const [forwarderName, setForwarderName] = useState<string>(null);

    useEffect(() => {
        if (shipment.shipperId) {
            (async () => {
                try {
                    const rawResponse = await getCompany(shipment.shipperId);
                    const response: string = rawResponse?.data.name || null;

                    if (response) {
                        setShipperName(response);
                    }
                } catch (error) {
                    global.console.log(error);
                }
            })();
        }
        if (shipment.forwarderId) {
            (async () => {
                try {
                    const rawResponse = await getCompany(shipment.forwarderId);
                    const response: string = rawResponse?.data.name || null;

                    if (response) {
                        setForwarderName(response);
                    }
                } catch (error) {
                    global.console.log(error);
                }
            })();
        }
    }, [shipment]);

    useEffect(() => {
        setTitle(t('SENSOR_DATA.SHIPMENT_DETAILS'));
    }, [setTitle, t]);

    const onMaximize = useCallback((packagingSerial) => {
        setActivePackagingSerialNumber(prevSerial => (packagingSerial === prevSerial ? null : packagingSerial));
    }, []);

    const maximized = useMemo(() => {
        return Boolean(activePackagingSerialNumber);
    }, [activePackagingSerialNumber]);
    const pageStyle = useMemo<CSSProperties>(() => {
        return {
            height: availableHeight,
            display: 'flex',
            flexDirection: 'column',
        };
    }, [availableHeight]);

    const downloadPdfHandler = useCallback(() => {
        generatePdf({
            cargo,
            chartDataToExport,
            userInfo,
            shipment,
            lane,
            t,
            shipperName,
            forwarderName,
        });
    }, [cargo, chartDataToExport, userInfo, shipment, lane, t, shipperName, forwarderName]);

    return (
        <div style={pageStyle}>
            <BackToLink isGeneric />
            <div
                className={
                    [displayProductReleaseFunctionality
                        ? classes.panelsWrapper
                        : classes.panelsWrapperWithoutApprovalCard,
                    maximized ? classes.maximizedPane : '',
                    ].join(' ')
                }
            >
                {shipment.status === 'NOT_STARTED'
                    ? (
                        <Card className={`${classes.cardNotStarted} ${classes.panelElement}`}>
                            <img
                                className={classes.icon}
                                alt="icon"
                                src={icons.info_blue}
                            />
                            <p className={classes.text}>
                                This shipment has not yet started.
                                The packaging information and sensor data will show up once the shipment has started.
                            </p>
                            <img
                                className={classes.iconEnd}
                                alt="icon"
                                src={icons.info_blue}
                            />
                        </Card>
                    )
                    : ('')}
                <Summary
                    className={`${classes.summary} ${classes.panelElement}`}
                    title={`${t('COMMON.SHIPMENT')} ${shipment?.externalId}`}
                    shipment={shipment}
                    shipmentEnd={shipment.shipmentEnd}
                    shipmentStart={shipment.shipmentStart}
                    lane={lane}
                    originValue={shipment.originAirport}
                    originLabel="Origin Airport"
                    destinationLabel="Destination Airport"
                    destinationValue={shipment.destinationAirport}
                    shipperName={shipperName}
                    forwarderName={forwarderName}
                />
                {
                    displayProductReleaseFunctionality && false && (
                        <Approval
                            shipmentNumber={shipment?.externalId}
                            packagings={shipment?.packagings}
                            dataUpdateTrigger={setRequiredOrderDataUpdate}
                            className={`${classes.approval} ${classes.panelElement}`}
                            onCsvButton={() => {
                            }}
                            shipmentEnd={shipment.shipmentEnd}
                            shipmentStart={shipment.shipmentStart}
                            skyMindId={shipment?.skyMindId}
                        />
                    )
                }
                {
                    !maximized && (
                        <div className={classes.orderSteps}>
                            {hasAccess(userRoles.INTELLIGENT_MONITORING) && (
                                <Card
                                    className={[classes.card, classes.downloadReportCard].join(' ')}
                                    title={t('TRACK_AND_TRACE.DATA_REPORTS')}
                                    zeroSidePadding
                                    zeroVerticalPadding
                                    titleClass={classes.title}
                                >
                                    <div className={classes.downloadButtons}>
                                        <UncontrolledTooltip
                                            description={t('TRACK_AND_TRACE.PDF_BUTTON_DISABLED_LABEL')}
                                            shrinked
                                            enabled={
                                                !hasAccess(userRoles.INTELLIGENT_MONITORING)
                                                || shipment?.status !== 'CLOSED'
                                                || !forwarderName
                                                || !shipperName
                                                || showMeasuredOnly === false
                                            }
                                        >
                                            <span>
                                                <Button
                                                    variant="contained"
                                                    onClick={downloadPdfHandler}
                                                    style={{
                                                        background: shipment?.status === 'CLOSED'
                                                            ? theme.palette.primary.deepBlue
                                                            : theme.palette.secondary[300],
                                                    }}
                                                    disabled={!hasAccess(userRoles.INTELLIGENT_MONITORING)
                                                        || shipment?.status !== 'CLOSED'
                                                        || !forwarderName
                                                        || !shipperName
                                                        || showMeasuredOnly === false}
                                                >
                                                    <DownloadComponentIcon color={theme.palette.common.white} />
                                                    PDF
                                                </Button>
                                            </span>
                                        </UncontrolledTooltip>
                                        {
                                            false && (
                                                <Button
                                                    variant="contained"
                                                    disabled
                                                >
                                                    <DownloadComponentIcon color={theme.palette.common.white} />
                                                    Excel
                                                </Button>
                                            )
                                        }

                                    </div>
                                </Card>
                            )}

                            {
                                shipment?.shipmentSteps?.length !== 0 && (
                                    <ShipmentSteps
                                        key={shipment?.externalId}
                                        milestones={milestones}
                                    />
                                )
                            }
                        </div>
                    )
                }
                <div className={classes.packagingDetailsWrapper}>
                    {
                        !maximized && shipment?.status !== 'NOT_STARTED' && (
                            <PackagingHealth
                                packagings={cargo?.map(item => item.packaging)}
                                cargo={cargo}
                            />
                        )
                    }
                    {
                        shipment?.status !== 'NOT_STARTED' && (
                            <PackagingViewOptions
                                packagings={cargo?.map(item => item.packaging)}
                                showTempRange={showTempRange}
                                laneId={shipment.laneId}
                                milestones={lane?.milestones}
                                showMeasuredOnly={showMeasuredOnly}
                                showMarkers={showMarkers}
                                showInUTC={showInUTC}
                                showExpTemp={showExpTemp}
                                showAmbientTemp={!hiddenSeries.AMBIENT}
                                showDoors={!hiddenSeries.DOOR}
                                setShowMarkers={setShowMarkers}
                                setShowInUTC={setShowInUTC}
                                setShowMeasuredOnly={setShowMeasuredOnly}
                                setShowTempRange={setShowTempRange}
                                setShowExpTemp={setShowExpTemp}
                                setShowAmbientTemp={
                                    () => setHiddenSeries(prev => ({ ...prev, AMBIENT: !prev.AMBIENT }))
                                }
                                setShowDoors={
                                    () => setHiddenSeries(prev => ({ ...prev, DOOR: !prev.DOOR }))
                                }
                            />
                        )
                    }

                    {shipment?.status !== 'NOT_STARTED' ? cargo
                        .map((item) => item.packaging)
                        ?.filter((packaging) => !activePackagingSerialNumber
                            || (packaging?.serialNumber === activePackagingSerialNumber))
                        ?.map((packaging, index) => {
                            return (
                                <PackagingTemperatureInfo
                                    key={`packagingTemperatureInfo_${packaging?.serialNumber}`}
                                    cargo={cargo.find(item => item.packaging === packaging)}
                                    packaging={packaging}
                                    timeRange={shipment?.shipmentSensorDataTimeRange}
                                    showExpTemp={showExpTemp}
                                    showMarkers={showMarkers}
                                    showInUTC={showInUTC}
                                    showMeasuredOnly={showMeasuredOnly}
                                    showTempRange={showTempRange}
                                    shipmentNumber={shipment?.externalId}
                                    shipmentStart={shipment?.shipmentStart}
                                    shipmentEnd={shipment?.shipmentEnd}
                                    shipmentId={shipmentId}
                                    onMaximize={onMaximize}
                                    maximized={maximized}
                                    milestones={milestones}
                                    transport={shipment?.status === 'TRANSPORT'}
                                    energyLevel={cargo[index]?.energyLevelCheckResult?.remainingEnergyLevel}
                                    hiddenSeries={hiddenSeries}
                                    setHiddenSeries={setHiddenSeries}
                                    exportTempData={(serialNumber, sd, labels) => {
                                        setChartDataToExport(prev => ({
                                            ...prev,
                                            [serialNumber]: {
                                                latestTemp: sd,
                                                labels,
                                                base64: prev[serialNumber]?.base64,
                                                chartAspectRatio: prev[serialNumber]?.chartAspectRatio,
                                            },
                                        }));
                                    }}
                                    exportPng={(base64, aspectRatio) => {
                                        setChartDataToExport(prev => ({
                                            ...prev,
                                            [packaging.serialNumber]: {
                                                ...prev[packaging.serialNumber],
                                                base64,
                                                chartAspectRatio: aspectRatio,
                                            },
                                        }));
                                    }}
                                />
                            );
                        }) : ''}
                </div>
            </div>
        </div>
    );
};

export default GenericShipmentDetails;
