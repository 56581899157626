import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    statusMessagesInnerContainer: {
        bottom: '40px',
        position: 'fixed',
        zIndex: 1500,
    },
    statusMessagesContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    statusMessageItemContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px',
        width: '100%',
        zIndex: 1600,
    },
    message: {
        margin: '0 auto',
    },
    iconWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    progressIconWrapper: {
        position: 'relative',
        width: '100%',
        height: '100%',
        top: '0px',
        left: '0px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    progressIcon: {
        position: 'relative',
        color: theme.palette.common.white,
    },
    customInfoSeverity: {
        backgroundColor: '#61C6E9!important',
    },
}));

export default useStyles;
