import React, { useEffect, useState, useCallback } from 'react';
import { Period, TimeRange, DAY_TO_PERIOD } from 'dataTypes/common';
import DateRangePickerMini from 'shared-components/DateRangePickerMini';
import PeriodSelector from 'shared-components/PeriodSelector';
import { TIME_IN_MS } from 'shared-components/constants';

const initializePeriod = (timeRange: TimeRange): Period => {
    const { from = null, to = null } = timeRange;

    if (from && to) {
        const fromMilliseconds = new Date(from).getTime();
        const toMilliseconds = new Date(to).getTime();

        const range = Math.round((toMilliseconds - fromMilliseconds) / TIME_IN_MS.day);

        // Check if the range matches one of the predefined ranges (24h/7d/14d)
        if (range === 1 || range === 7 || range === 14) {
            return 'Manual';
        }

        return DAY_TO_PERIOD[range] || 'Manual';
    }

    return '24h';
};

type Props = {
    setTimeRange: (timeRange: TimeRange) => void;
    isFullAccess?: boolean;
    timeRange: TimeRange;
    maxDateRange: number;
};

const PeriodSelectorWithDatePicker = ({
    isFullAccess = true,
    setTimeRange,
    timeRange,
    maxDateRange = null,
}: Props) => {
    const [period, setPeriod] = useState<Period>(initializePeriod(timeRange));
    const [isManual, setIsManual] = useState<boolean>(false);

    useEffect(() => {
        const newPeriod = initializePeriod(timeRange);

        if (period !== newPeriod) {
            setPeriod(newPeriod);
        }
    }, [timeRange]);

    const onPeriodChange = useCallback((targetPeriod) => {
        setPeriod(targetPeriod);
        setIsManual(() => targetPeriod === 'Manual');
    }, []);

    return (
        <div>
            <div style={{ marginBottom: '10px' }}>
                <PeriodSelector
                    isFullAccess={isFullAccess}
                    getPeriod={onPeriodChange}
                    setTimeRange={setTimeRange}
                    timeRange={timeRange}
                />
            </div>
            {isManual && (
                <DateRangePickerMini
                    setTimeRange={setTimeRange}
                    timeRange={timeRange}
                    maxDateRange={maxDateRange}
                />
            )}
        </div>
    );
};

export default PeriodSelectorWithDatePicker;
