import React from 'react';

interface CustomThemeContextInterface {
    setPage: (page: number) => void,
    perPage?: number,
    setPerPage?: (perPage: number) => void,
    page?: number,
    totalElements?: number,
    paginationLoading?: boolean,
}

const initialState = {
    setPage: () => {},
    setPerPage: () => {},
    perPage: 10,
    page: 0,
    totalElements: 0,
    paginationLoading: false,
};

const DataPaginationContext = React.createContext<CustomThemeContextInterface>(initialState);

export default DataPaginationContext;
