import React, { useMemo, CSSProperties, useContext, useState, useRef, useCallback } from 'react';
import { QuickHelpTooltipInfo } from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import { QuickHelpContext } from 'Contexts/QuickHelpContext/QuickHelpContext';
import { useStyles } from 'Contexts/QuickHelpContext/QuickHelpElement/QuickHelpElement.style';
import useScreenSize from 'hooks/useScreenSize';
import { useCalculatedRectStyle } from 'Contexts/QuickHelpContext/useCalculatedRectStyle';

type Props = {
    tooltipInfo: QuickHelpTooltipInfo,
}
export const TOOLTIP_PADDING = 4;

const QuickHelpElement = ({
    tooltipInfo,
}: Props) => {
    const {
        childRef,
        text,
        customRectSize,
        childOffsetPercent,
        offsetPx,
    } = tooltipInfo;
    const classes = useStyles();
    const { enableTooltips } = useContext(QuickHelpContext);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [shouldUpdate, setShouldUpdate] = useState(0);

    const update = useCallback(() => {
        setShouldUpdate(new Date().getTime());
    }, []);
    const { height, width } = useScreenSize();
    const tooltipRef = useRef(null);
    const childRect = useMemo<DOMRect>(() => {
        const element:HTMLElement = childRef?.current;

        if (!element) return null;

        return element?.getBoundingClientRect();
    }, [tooltipInfo]);
    const tooltipRect = useMemo<DOMRect>(() => {
        const element:HTMLElement = tooltipRef?.current;

        if (!element) return null;

        return element?.getBoundingClientRect();
    }, [tooltipRef, shouldUpdate]);

    const rectStyle = useCalculatedRectStyle({
        childRect,
        customRectSize,
        childOffsetPercent,
        offsetPx,
        shouldUpdate,
    });

    const calculatedPosition = useMemo(() => {
        const rectLeft: number = parseFloat(rectStyle.left.toString());
        const rectWidth: number = parseFloat(rectStyle.width.toString());

        return width / 2 < rectLeft + rectWidth ? 'left' : 'right';
    }, [width, rectStyle]);

    const tooltipStyle = useMemo<CSSProperties>(() => {
        const rectTop: number = parseFloat(rectStyle.top.toString());
        const rectLeft: number = parseFloat(rectStyle.left.toString());
        const rectHeight: number = parseFloat(rectStyle.height.toString());
        const rectWidth: number = parseFloat(rectStyle.width.toString());

        let calculatedLeft = rectLeft;

        const calculatedTop = rectTop + rectHeight / 2 + TOOLTIP_PADDING - tooltipRect?.height / 2;

        switch (calculatedPosition) {
        case 'left':
            calculatedLeft -= tooltipRect?.width;
            break;
        case 'right':
            calculatedLeft += rectWidth + 8;
            break;
        }

        return {
            left: calculatedLeft,
            top: calculatedTop,
            position: 'fixed',
            zIndex: 10001,
            display: !enableTooltips || !tooltipInfo ? 'none' : 'flex',
        };
    }, [
        childOffsetPercent,
        childOffsetPercent,
        offsetPx,
        offsetPx,
        height,
        width,
        rectStyle,
        tooltipRect,
        calculatedPosition,
    ]);

    return (
        <>
            <div
                onMouseEnter={(event) => {
                    event.stopPropagation();
                    update();
                    setTooltipVisible(true);
                }}
                onMouseLeave={(event) => {
                    event.stopPropagation();
                    update();
                    setTooltipVisible(false);
                }}
                style={rectStyle}
            />
            <div
                className={[
                    classes.quickHelp,
                    calculatedPosition === 'right' ? classes.quickHelpRight : '',
                    tooltipVisible && text ? classes.tooltipVisible : '',
                ].join(' ')}
                style={tooltipStyle}
                ref={tooltipRef}
            >
                <span className={classes.badge}>i</span>
                <div className={classes.tooltipBodyWrapper}>
                    <div className={classes.tooltipBody}>
                        <div className={classes.line} />
                        <div className={classes.content}>
                            <span>{text}</span>
                        </div>
                    </div>
                    <div className={classes.tooltipTriangle} />
                </div>
            </div>
        </>
    );
};

export default QuickHelpElement;
