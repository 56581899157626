import React from 'react';
import IconWithTooltip from 'shared-components/IconWithTooltip';
import { IconTooltipInfo } from 'shared-components/dataTypes';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useStyles from './ApprovalSummary.style';

type Props = {
    temperatureIcon?: string,
    sealIcon?: string,
    containerIcon?: string,
    palletCode?: string,
    palletCode1?: string,
    palletCode2?: string,
    palletIcon?: string,
    pallet1Icon?: string,
    pallet2Icon?: string,
    sealApprovalsIconTooltipInfo?: IconTooltipInfo,
    containerApprovalsIconTooltipInfo?: IconTooltipInfo,
    temperatureReadoutTooltipInfo: IconTooltipInfo,
    palletApprovalsIconTooltipInfo?: IconTooltipInfo,
    pallet1ApprovalsIconTooltipInfo?: IconTooltipInfo,
    pallet2ApprovalsIconTooltipInfo?: IconTooltipInfo,
}

const StatusesContainer = ({
    temperatureIcon = '',
    sealIcon = '',
    containerIcon = '',
    palletCode = '',
    palletCode1 = '',
    palletCode2 = '',
    palletIcon = '',
    pallet1Icon = '',
    pallet2Icon = '',
    containerApprovalsIconTooltipInfo,
    sealApprovalsIconTooltipInfo,
    temperatureReadoutTooltipInfo,
    palletApprovalsIconTooltipInfo,
    pallet1ApprovalsIconTooltipInfo,
    pallet2ApprovalsIconTooltipInfo,
} : Props) => {
    const classes = useStyles();

    const { t } = useCustomTranslation();

    return (
        <div className={classes.statusesContainer}>
            <div className={classes.fieldTitle}>{t('COMMON.APPROVAL_EVIDENCE')}</div>
            <div className={classes.statusContainer}>
                <IconWithTooltip
                    iconSrc={temperatureIcon}
                    className={classes.icon}
                    description={temperatureReadoutTooltipInfo.description}
                    descriptionTitle={temperatureReadoutTooltipInfo.descriptionTitle}
                />
                <div className={classes.approvalItem}>{t('COMMON.TEMPERATURE')}</div>
            </div>
            <div className={classes.statusContainer}>
                <IconWithTooltip
                    iconSrc={sealIcon}
                    className={classes.icon}
                    description={sealApprovalsIconTooltipInfo.description}
                    descriptionTitle={sealApprovalsIconTooltipInfo.descriptionTitle}
                />
                <div className={classes.approvalItem}>{t('PACKAGING_PICTURE_SIDES.SEAL')}</div>
            </div>
            <div className={classes.statusContainer}>
                <IconWithTooltip
                    iconSrc={containerIcon}
                    className={classes.icon}
                    description={containerApprovalsIconTooltipInfo.description}
                    descriptionTitle={containerApprovalsIconTooltipInfo.descriptionTitle}
                />
                <div className={classes.approvalItem}>{t('COMMON.PACKAGING')}</div>
            </div>
            {
                palletCode && palletIcon && (
                    <div className={classes.statusContainer}>
                        <IconWithTooltip
                            iconSrc={palletIcon}
                            className={classes.icon}
                            description={palletApprovalsIconTooltipInfo.description}
                            descriptionTitle={palletApprovalsIconTooltipInfo.descriptionTitle}
                        />
                        <div className={classes.approvalItem}>{`${t('ASSET_TYPE_LABEL.PALLET')} ${palletCode}`}</div>
                    </div>
                )
            }
            {
                palletCode1 && pallet1Icon && (
                    <div className={classes.statusContainer}>
                        <IconWithTooltip
                            iconSrc={pallet1Icon}
                            className={classes.icon}
                            description={pallet1ApprovalsIconTooltipInfo.description}
                            descriptionTitle={pallet1ApprovalsIconTooltipInfo.descriptionTitle}
                        />
                        <div className={classes.approvalItem}>{`${t('ASSET_TYPE_LABEL.PALLET')} ${palletCode1}`}</div>
                    </div>
                )
            }
            {
                palletCode2 && pallet2Icon && (
                    <div className={classes.statusContainer}>
                        <IconWithTooltip
                            iconSrc={pallet2Icon}
                            className={classes.icon}
                            description={pallet2ApprovalsIconTooltipInfo.description}
                            descriptionTitle={pallet2ApprovalsIconTooltipInfo.descriptionTitle}
                        />
                        <div className={classes.approvalItem}>{`${t('ASSET_TYPE_LABEL.PALLET')} ${palletCode2}`}</div>
                    </div>
                )
            }
        </div>
    );
};

export default StatusesContainer;
