import { ColumnsType } from 'shared-components/Table/dataTypes';
import { GatewayInfoDTO } from 'dataTypes/SecureBackend/apiResponse';
import RegularSelect from 'shared-components/RegularSelect';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';

export const tableColumns:
    (...args) => ColumnsType[] = (
        t,
        theme,
        classes,
        edit,
        editState,
        gateways: GatewayInfoDTO[],
        onChange,
        onRemove,
        workInProgress,
    ) => {
        if (workInProgress) {
            return [
                {
                    id: 'bat-table-loading',
                    Header: '',
                    accessor: () => <div className={classes.loadingSkeleton} />,
                },
            ];
        }
        return (edit ? [{
            Header: t('TRACK_AND_TRACE.LOCATION'),
            accessor: ({ gatewayId, uniqueId }) => (
                <RegularSelect
                    value={gatewayId}
                    className={gatewayId === null && classes.filledSelect}
                    borderless
                    onChange={it => onChange(it, uniqueId)}
                    options={gateways.filter(g => !editState
                        .find(es => es.gatewayId === g.id && es.gatewayId !== gatewayId))
                        .map(({ iataCode, locationName, locationRemark, id }) => ({
                            value: id,
                            label: [iataCode,
                                locationName,
                                locationRemark].filter(it => it).join(' | '),
                        }))}
                    placeholder="Select a gateway"
                    customClasses={{
                        caretIcon: gatewayId === null && classes.caret,
                    }}
                />
            ),
            dataType: 'custom',
        }, {
            Header: '',
            id: 'removeCol',
            customWidth: 40,
            accessor: ({ uniqueId }) => (
                <DeleteIcon
                    className={classes.icon}
                    onClick={() => onRemove(uniqueId)}
                />
            ),
            dataType: 'custom',
        },
        ] : [
            {
                Header: t('TRACK_AND_TRACE.LOCATION'),
                accessor: 'label',
                dataType: 'text',
            },
        ]);
    };
export const resolveSequential = async (tasks) => {
    return tasks.reduce(async (
        accumulator, currentPromise,
    ) => {
        const results = await accumulator;

        return [...results, await currentPromise()];
    }, Promise.resolve([]));
};
