import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useState,
} from 'react';
import {
    AvailableFilterOptions,
    ClientSideFilter,
    FilterOptionsCount,
    GenericShipmentData,
} from '../../lib';
import ShipmentClientSideFilter from './ShipmentClientSideFilter';

type Props = {
    availableFilterOptions: AvailableFilterOptions,
    changeHighlightedText: (newHighlightedText: any) => void,
    filterOptionsCount: FilterOptionsCount,
    isTableView: boolean,
    setCurrentPage: (currentPage: number) => void,
    setFilters: (filters: ClientSideFilter) => void,
    setShipmentsFromPreviewTable: Dispatch<SetStateAction<GenericShipmentData[]>>,
    setShowAirportsInfo: (showAirportsInfo: boolean) => void,
    shipments: GenericShipmentData[],
    shipmentsFromPreviewTable: GenericShipmentData[],
}

const ShipmentClientSideFilterContainer = ({
    availableFilterOptions,
    changeHighlightedText,
    filterOptionsCount,
    isTableView,
    setCurrentPage,
    setFilters,
    setShipmentsFromPreviewTable,
    setShowAirportsInfo,
    shipments = [],
    shipmentsFromPreviewTable = [],
} : Props) => {
    const [shipmentStatus, setShipmentStatus] = useState<string[]>([]);
    const [temperatureStatus, setTemperatureStatus] = useState<string[]>([]);
    const [originAirports, setOriginAirports] = useState<string[]>([]);
    const [destinationAirports, setDestinationAirports] = useState<string[]>([]);

    useEffect(() => {
        setShipmentStatus(availableFilterOptions.shipmentStatus
            .filter(item => item.value !== 'Closed')
            .map(item => item.value));
        setTemperatureStatus(availableFilterOptions.temperatureStatus.map(item => item.value));
        setOriginAirports(availableFilterOptions.originAirports);
        setDestinationAirports(availableFilterOptions.destinationAirports);
    }, [availableFilterOptions]);

    useEffect(() => {
        setFilters({
            shipmentStatus,
            temperatureStatus,
            originAirports,
            destinationAirports,
        });
        setCurrentPage(1);
    }, [
        shipmentStatus,
        temperatureStatus,
        originAirports,
        destinationAirports,
    ]);

    return (
        <ShipmentClientSideFilter
            availableFilterOptions={availableFilterOptions}
            changeHighlightedText={changeHighlightedText}
            filterOptionsCount={filterOptionsCount}
            setShipmentStatus={setShipmentStatus}
            shipmentStatus={shipmentStatus}
            temperatureStatus={temperatureStatus}
            setTemperatureStatus={setTemperatureStatus}
            setOriginAirports={setOriginAirports}
            setDestinationAirports={setDestinationAirports}
            originAirports={originAirports}
            destinationAirports={destinationAirports}
            setShowAirportsInfo={setShowAirportsInfo}
            setShipmentsFromPreviewTable={setShipmentsFromPreviewTable}
            shipments={shipments}
            shipmentsFromPreviewTable={shipmentsFromPreviewTable}
            isTableView={isTableView}
        />
    );
};

export default ShipmentClientSideFilterContainer;
