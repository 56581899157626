import React, { Dispatch, SetStateAction, useState } from 'react';
import CardWithEditableContent from 'shared-components/CardWithEditableContent';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useStyles from 'shared-components/CompanyInfoComponents/AdministrationCompany/GeofencesCard/GeofencesCard.style';
import GeofencesTable from 'shared-components/CompanyInfoComponents/AdministrationCompany/GeofencesCard/GeofencesTable';

export type Geofence = {
    id?: number,
    siteId?: string | number,
    name: string,
    coordinates: {
        longitude: number,
        latitude: number
    }[]
}

type Props = {
    currentEditedCard?: string,
    setCurrentEditedCard: (arg: string) => void,
    currentGeofences: Geofence[],
    setGeofences: Dispatch<SetStateAction<Geofence[]>>,
    siteId: string,
}
const cardName = 'GeofencesCard';

const GeofencesCard = ({
    currentEditedCard,
    setCurrentEditedCard,
    currentGeofences,
    setGeofences,
    siteId,
}: Props) => {
    const { t } = useCustomTranslation();
    const classes = useStyles();

    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClickEditButton = () => {
        setCurrentEditedCard(cardName);
        setEditMode(true);
    };

    const handleClickSaveButton = async () => {
        setLoading(true);
        setEditMode(false);
        setLoading(false);
        setCurrentEditedCard(null);
    };

    return (
        <CardWithEditableContent
            className={loading ? classes.loading : ''}
            onClickEditButton={handleClickEditButton}
            onClickSaveButton={handleClickSaveButton}
            loadingIcon={false}
            showEditButton={!editMode}
            showSaveButton={editMode}
            disabledEditButton={currentEditedCard !== null && currentEditedCard !== cardName}
            title={t('COMMON.GEOFENCES')}
        >
            <GeofencesTable
                geofences={currentGeofences}
                edit={editMode}
                setGeofences={setGeofences}
                siteId={siteId}
            />
        </CardWithEditableContent>
    );
};

export default GeofencesCard;
