/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import {
    Route, Switch,
} from 'react-router-dom';
import { GuideRouteMapping } from 'UserGuides/GuideRouteMapping';
import CloseIcon from '@mui/icons-material/Close';
import { docsPath } from 'shared-components/constants';
import NoUserGuide from 'UserGuides/NoUserGuide';
import useCustomTranslation from 'hooks/useCustomTranslation';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { useStyles } from './UserGuide.style';
import { QuickHelpContext } from '../QuickHelpContext';
import HasUserGuide from '../../../UserGuides/HasUserGuide';

const UserGuide = () => {
    const classes = useStyles();
    const { enableGuide, setEnableGuide } = useContext(QuickHelpContext);

    // eslint-disable-next-line no-unused-vars
    const { i18n } = useCustomTranslation();

    return (
        <>
            <div className={[
                classes.drawer,
                enableGuide ? classes.visible : '',
            ].join(' ')}
            >
                <div className={classes.drawerCard}>
                    <div className={classes.closer}>
                        <span
                            onClick={setEnableGuide.bind(null, false)}
                            className={classes.x}
                        >
                            <CloseIcon />
                        </span>
                    </div>
                    <div className={classes.drawerContents}>
                        <Switch>
                            {
                                GuideRouteMapping.map((item, index) => (
                                    <Route
                                        exact={item.exact}
                                        key={`GuideRouteMapping-${index}`}
                                        path={item.path}
                                    >
                                        {
                                            item.component
                                        }
                                        {
                                            enableGuide && item.docPath && (
                                                <DocViewer
                                                    key={`${docsPath}${i18n.language}/${item.docPath}`}
                                                    className={classes.document}
                                                    pluginRenderers={DocViewerRenderers}
                                                    prefetchMethod="GET"
                                                    config={{
                                                        header: {
                                                            disableHeader: true,
                                                        },
                                                        pdfZoom: {
                                                            defaultZoom: 1.5,
                                                            zoomJump: 0.1,
                                                        },
                                                    }}
                                                    documents={[
                                                        { uri: `${docsPath}${i18n.language}/${item.docPath}` },
                                                    ]}
                                                />
                                            )
                                        }
                                        <HasUserGuide />
                                    </Route>
                                ))
                            }
                            <NoUserGuide />
                        </Switch>

                    </div>

                </div>
            </div>
        </>
    );
};

export default UserGuide;
