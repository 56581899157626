import { makeStyles } from '@mui/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

export const useStyles = makeStyles((theme: SkycellThemeInterface) => ({
    active: {

    },
    root: {
        display: 'grid',
        gridGap: '8px',
        gridAutoRows: '20px 20px 31px',
        flex: 1,
        transition: '200ms ease',
        flexDirection: 'column',
        alignItems: 'center',
        '&:nth-child(4n+1)': {
            '& > $line$active': {
                background: theme.palette.primary[30],
            },
            '& > $line': {
                background: theme.palette.common.secondShadeOfGray,
            },

        },
        '&:nth-child(4n+2)': {
            '& > $line$active': {
                background: theme.palette.primary[400],
            },
            '& > $line': {
                background: theme.palette.common.thirdShadeOfGray,
            },

        },
        '&:nth-child(4n+3)': {
            '& > $line$active': {
                background: theme.palette.primary[700],
            },
            '& > $line': {
                background: theme.palette.common.fourthShadeOfGray,
            },

        },
        '&:nth-child(4n+4)': {
            '& > $line$active': {
                background: theme.palette.primary[400],
            },
            '& > $line': {
                background: theme.palette.common.thirdShadeOfGray,
            },

        },
    },
    shrinked: {
        gridAutoRows: '20px 31px',
        cursor: 'pointer',
        '&:hover': {
            filter: 'grayscale(1)',
        },
    },
    line: {
        height: '22px',
        transition: '200ms ease',
        animation: '$fadeInOpacity 300ms forwards ease-in-out',
    },
    lineTbd: {
        background: `${theme.palette.secondary[400]}!important`,
    },
    time: {
        textAlign: 'center',
        color: theme.palette.secondary[600],
    },
    iconWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon: {
        height: '25px',
        width: '100%',
        maxWidth: '25px',
        minWidth: '5%',
        animation: '$fadeIn 200ms forwards ease-in-out',
    },
    '@keyframes fadeIn': {
        '0%': {
            transform: 'scale(0.5)',
            opacity: 0,
        },
        '100%': {
            transform: 'scale(1)',
            opacity: 1,
        },
    },
    '@keyframes fadeInOpacity': {
        '0%': {
            opacity: 0.4,
        },
        '100%': {
            opacity: 1,
        },
    },
    tooltipDescription: {
        display: 'flex',
        whiteSpace: 'nowrap',
        alignItems: 'center',
    },
    tooltipBody: {
        maxWidth: 'unset!important',
        width: 'auto!important',
    },
    divider: {
        width: '75px',
        height: '1px',
        marginRight: '40px',
        marginLeft: '40px',
        background: `linear-gradient(90deg, ${theme.palette.secondary[600]} 4px, ${theme.palette.common.white} 4px)`,
        backgroundSize: '8px',
    },
}));
