import { LatLng } from 'dataTypes/common';

const getDirection = (p1: LatLng, p2: LatLng, p3: LatLng): number => {
    return (
        (p2.lng - p1.lng) * (p3.lat - p1.lat)
        - (p3.lng - p1.lng) * (p2.lat - p1.lat)
    );
};

const arePointsEqual = (p1: LatLng, p2: LatLng): boolean => {
    return p1.lat === p2.lat && p1.lng === p2.lng;
};

const areSegmentsIntersecting = (p1: LatLng, p2: LatLng, p3: LatLng, p4: LatLng): boolean => {
    const d1 = getDirection(p3, p4, p1);
    const d2 = getDirection(p3, p4, p2);
    const d3 = getDirection(p1, p2, p3);
    const d4 = getDirection(p1, p2, p4);

    return (
        ((d1 > 0 && d2 < 0) || (d1 < 0 && d2 > 0))
        && ((d3 > 0 && d4 < 0) || (d3 < 0 && d4 > 0))
    );
};

const hasSelfIntersections = (points: LatLng[]): boolean => {
    const numPoints = points.length;

    for (let i = 0; i < numPoints; i++) {
        const p1 = points[i];
        const p2 = points[(i + 1) % numPoints];

        for (let j = i + 1; j < numPoints; j++) {
            const p3 = points[j];
            const p4 = points[(j + 1) % numPoints];

            if (!arePointsEqual(p1, p3)
                && !arePointsEqual(p1, p4) && !arePointsEqual(p2, p3) && !arePointsEqual(p2, p4)) {
                if (areSegmentsIntersecting(p1, p2, p3, p4)) {
                    return true;
                }
            }
        }
    }

    return false;
};

export const validatePolygon = (polygonPath: LatLng[], log) => {
    const lats = polygonPath.map(({ lat }) => lat);
    const lngs = polygonPath.map(({ lng }) => lng);

    const intersection = hasSelfIntersections(polygonPath);

    if (intersection) {
        log('The lines defined by the coordinates cross each other.'
            + ' Please re-order the coordinates such that they form a polygon.');
        return false;
    }
    if (polygonPath.length < 3) {
        log('A geofence must have at least three coordinates.');
        return false;
    }
    if (lats.some(lat => lat < -90 || lat > 90)) {
        log('The latitude of a coordinate is outside the interval [-90, 90].');
        return false;
    }
    if (lngs.some(lng => lng < -180 || lng > 180)) {
        log('The longitude of a coordinate is outside the interval [-180, 180).');
        return false;
    }

    return true;
};
