export const openSupport = (onError = () => {}) => {
    const jiraIframe:HTMLIFrameElement = document.querySelector('iframe[name="JSD widget"]');
    const button:HTMLElement = jiraIframe?.contentDocument?.querySelector('#help-button');

    if (!jiraIframe || !button) {
        onError();
        return;
    }
    let iframeStyle:HTMLStyleElement = jiraIframe.contentDocument.querySelector('#customStyle');

    if (!iframeStyle) {
        iframeStyle = jiraIframe
            .contentDocument.body.appendChild(document.createElement('style'));
        iframeStyle.id = 'customStyle';
        iframeStyle.textContent = '#button-container { display: none; }';
        jiraIframe.style.pointerEvents = 'all';
        jiraIframe.style.opacity = '1';
    }

    button.click();
};
