import React from 'react';
import icons from 'shared-components/icons';
import { LaneObject } from 'shared-components/dataTypes';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import TempRange from 'shared-components/TempRange';
import { TFunction } from 'react-i18next';

const tableColumns: (t:TFunction) => ColumnsType[] = (t) => [
    {
        id: 'cell-Lane',
        Header: t('LANE_MANAGEMENT.LANES.LANE'),
        accessor: ({ laneNumber }: LaneObject) => laneNumber,
        dataType: 'custom',
    },
    {
        id: 'cell-fourthPartyLogistics',
        Header: t('COMMON.4_PL'),
        accessor: ({ fourthPartyLogistics }: LaneObject) => fourthPartyLogistics,
        dataType: 'text',
        sideFilterKey: 'fourthPartyLogistics',
        sideFilterType: 'enum',
        sideFilterPosition: 1,
        customWidth: '10%',
    },
    {
        id: 'cell-TempRange',
        Header: t('COMMON.TEMPERATURE_RANGE'),
        accessor: ({ temperatureRange }: LaneObject) => <TempRange tempRange={temperatureRange} />,
        dataType: 'custom',
        sideFilterKey: 'temperatureRange',
        sideFilterType: 'enum',
        // customWidth: '10%',
    },
    {
        id: 'cell-From',
        Header: t('COMMON.FROM'),
        accessor: ({ originAirportCode } :LaneObject) => originAirportCode,
        dataType: 'custom',
        sideFilterKey: 'originAirportCode',
        sideFilterType: 'airportOrigin',
        // customWidth: '80px',
    },
    {
        id: 'cell-ArrowIcon',
        Header: '',
        accessor: ({ originAirportCode, destinationAirportCode } :LaneObject) => (
            originAirportCode && destinationAirportCode
                ? (
                    <img
                        alt="arrow"
                        src={icons.longArrowRight}
                        style={{
                            width: 38,
                            marginRight: 30,
                        }}
                    />
                ) : ''
        ),
        // customWidth: '120px',
        dataType: 'custom',

    },
    {
        id: 'cell-To',
        Header: t('COMMON.TO'),
        accessor: ({ destinationAirportCode } :LaneObject) => destinationAirportCode,
        dataType: 'custom',
        sideFilterKey: 'destinationAirportCode',
        sideFilterType: 'airportDestination',
        // customWidth: '100px',
    },
    {
        id: 'cell-Loading-Handover',
        Header: t('LANE_MANAGEMENT.LANES.LOADING_HANDOVER'),
        accessor: 'handoverPoint',
        dataType: 'custom',
        sideFilterKey: 'handoverPoint',
        sideFilterType: 'enum',
        sideFilterPosition: 2,
        customWidth: '20%',
    },
    {
        id: 'cell-Unloading-Dropoff',
        Header: t('LANE_MANAGEMENT.LANES.UNLOADING_COLLECTION_DROP_OFF'),
        accessor: 'collectionDropoffPoint',
        dataType: 'custom',
        sideFilterKey: 'collectionDropoffPoint',
        sideFilterType: 'enum',
        sideFilterPosition: 3,
        customWidth: '20%',
    },
    {
        id: 'cell-MilestonesDuration',
        Header: t('COMMON.DURATION'),
        accessor: 'milestones',
        dataType: 'custom',
        // customWidth: '100px',
    },
    {
        id: 'cell-ManagedBy',
        Header: t('LANE_MANAGEMENT.LANES.MANAGED_BY'),
        accessor: ({ isSkycellManaged } :LaneObject) => (isSkycellManaged === 'Yes'
            ? (<img src={icons.skycell_blue} alt="skycell_icon" style={{ height: 38, width: 38 }} />) : ''),
        dataType: 'custom',
    },

];

export default tableColumns;
