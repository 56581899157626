import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    itemText: {
        fontSize: '14px',
        color: theme.palette.common.black,
        '& label': {
            color: theme.palette.common.black,
        },
    },
}));
