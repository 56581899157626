import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    statusDot: {
        display: 'inline-flex',
        width: '7px',
        height: '7px',
        borderRadius: '50%',
        marginRight: '2px',
    },
    statusDotBackgroundGreen: {
        background: '#17921F 0% 0% no-repeat padding-box',
    },
    statusDotBackgroundYellow: {
        background: '#EDAE49 0% 0% no-repeat padding-box',
    },
    statusDotBackgroundRed: {
        background: '#D44848 0% 0% no-repeat padding-box',
    },
    statusDotBackgroundBlue: {
        background: '#2C5969 0% 0% no-repeat padding-box',
    },
    background: {
        display: 'inline-flex',
        padding: '0 4px',
        alignItems: 'center',
        borderRadius: '3px',
        color: '#747474',
        opacity: 1,
        whiteSpace: 'nowrap',
    },
    backgroundGreen: {
        background: '#EAF9EF 0% 0% no-repeat padding-box',
    },
    backgroundYellow: {
        background: '#F3EAD9 0% 0% no-repeat padding-box',
    },
    backgroundRed: {
        background: '#FFE5E5 0% 0% no-repeat padding-box',
    },
    backgroundBlue: {
        background: '#E0F7FF 0% 0% no-repeat padding-box',
    },
    small: {
        textAlign: 'left',
        font: 'normal normal normal 10px/13px Roboto',
        letterSpacing: '0.31px',
        opacity: 1,
    },
    medium: {
        textAlign: 'left',
        font: 'normal normal normal 14px/17px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
    },
    large: {
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        opacity: 1,
    },
});

export default useStyles;
