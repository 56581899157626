import React from 'react';
import icons from 'shared-components/icons';

type Position = 'topRight' | 'topLeft' | 'bottomRight' | 'bottomLeft'
export type PolygonIconType = 'hexa' | 'tetra';
export type PolygonIconColor = 'blue' | 'blue_dark' | 'grey' | 'orange' | 'red';

export const getPosition = (iconDiameter: number, position: Position) => {
    switch (position) {
    case 'topRight':
        return {
            top: -5,
            left: iconDiameter - 5,
        };
    case 'topLeft':
        return {
            top: -5,
            right: iconDiameter - 5,
        };
    case 'bottomRight':
        return {
            top: iconDiameter - 5,
            left: iconDiameter - 5,
        };
    case 'bottomLeft':
        return {
            top: iconDiameter - 5,
            right: iconDiameter - 5,
        };
    }
};

type Props = {
    className?: string,
    iconDiameter?: number,
    type?: PolygonIconType,
    theme?: 'default' | 'light',
    color?: PolygonIconColor,
    filled?: boolean,
}

const PolygonIcon = ({
    className = '',
    iconDiameter = 22,
    type = 'hexa',
    theme = 'default',
    color = 'blue',
    filled = false,
}: Props) => {
    const iconName = `${theme === 'default' ? '' : `${theme}_`}${type}_${color}${filled ? '_filled' : ''}`;

    return (
        <img
            className={className}
            style={{
                width: iconDiameter,
                height: iconDiameter,
                objectFit: 'unset',
            }}
            src={icons[iconName]}
            alt={iconName}
        />
    );
};

export default PolygonIcon;
