import React, { useContext, useEffect } from 'react';
import { QuickHelpContext } from 'Contexts/QuickHelpContext/QuickHelpContext';

const HasUserGuide = () => {
    const { setHasUserGuide } = useContext(QuickHelpContext);

    useEffect(() => {
        setHasUserGuide(true);
        return () => {
            setHasUserGuide(false);
        };
    }, [setHasUserGuide]);
    return <></>;
};

export default HasUserGuide;
