import React from 'react';
import menuItems from 'shared-components/menuItems';
import useHasAccess from 'hooks/useHasAccess';
import useCustomTranslation from 'hooks/useCustomTranslation';
// import PackagingSelectorWidget from './PackagingSelectorWidget';

import Dashboard from './Dashboard';

function DashboardContainer() {
    const hasAccess = useHasAccess();
    const { t } = useCustomTranslation();
    const filteredMenuItems = menuItems(t)
        .filter((item) => {
            return item.id !== 'start' && (
                item.sufficientRoles ? (item.sufficientRoles || []).some(role => hasAccess(role))
                    : (item.necessaryRoles || []).every(role => hasAccess(role))
            );
        });

    // const widgets = filteredMenuItems.map((item) => {
    //     if (item.id === 'sensorData') {
    //         return {
    //             ...item,
    //             component: PackagingSelectorWidget,
    //         };
    //     }
    //     return item;
    // });

    // return (<Dashboard widgets={widgets} />);
    return (<Dashboard widgets={filteredMenuItems} />);
}

export default DashboardContainer;
