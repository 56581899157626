import React, { useMemo } from 'react';
import icons from 'shared-components/icons';
import IconWithTooltip from 'shared-components/IconWithTooltip';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useStyles from './ShipmentSteps.style';

const getHeader = (stepType, t = w => w) => {
    if (stepType) {
        return {
            icon: icons.stepTypes[stepType.toLowerCase()],
            text: t(`STEP_TYPE.${stepType}`),
        };
    } else {
        return {
            icon: icons.no_icon,
            text: '',
        };
    }
};

const getStatusInfo = (stepStatus = '', t = w => w) => {
    switch (stepStatus) {
    case 'NOT_STARTED':
        return {
            icon: icons.dots_round_grey,
            descriptionTitle: t('SHIPMENT_STATUS.NOT_STARTED'),
            description: t('ORDER_DETAILS.NOT_STARTED_DESC'),
        };
    case 'IN_PROGRESS':
        return {
            icon: icons.in_progress_blue,
            descriptionTitle: t('ORDER_DETAILS.IN_PROGRESS'),
            description: t('ORDER_DETAILS.IN_PROGRESS_DESC'),
        };
    default:
        return {
            icon: icons.success,
            descriptionTitle: t('ORDER_DETAILS.COMPLETED'),
            description: t('ORDER_DETAILS.COMPLETED_DESC'),
        };
    }
};

type Props = {
    stepType: string,
    stepStatus?: string,
}

const ShipmentStepHeader = ({
    stepType,
    stepStatus = '',
}: Props) => {
    const closed = useMemo(() => stepStatus === 'COMPLETED', [stepStatus]);
    const classes = useStyles({ closed });
    const { t } = useCustomTranslation();
    const currentHeader = getHeader(stepType, t);

    const currentStatus = getStatusInfo(stepStatus, t);

    return (
        <div className={classes.cardTitle}>
            <div className={classes.stepHeaderWrapper}>
                <div className={classes.stepIconWrapper}>
                    <img
                        src={currentHeader.icon}
                        className={classes.stepIcon}
                        width={25}
                        height="auto"
                        alt="header"
                        style={!closed
                            ? { filter: 'brightness(0)' }
                            : {
                                filter: stepType !== 'LOADING' && stepType !== 'UNLOADING'
                                    ? 'invert(64%) sepia(54%) saturate(6823%) '
                                    + 'hue-rotate(167deg) brightness(93%) contrast(139%)'
                                    : 'invert(67%) sepia(54%) saturate(6823%) '
                                    + 'hue-rotate(164deg) brightness(92%) contrast(104%)' }}
                    />
                </div>
                <span
                    className={classes.headerText}
                >
                    {currentHeader.text}
                </span>
                {
                    stepStatus !== 'NOT_STARTED' && (
                        <IconWithTooltip
                            description={currentStatus.description}
                            descriptionTitle={currentStatus.descriptionTitle}
                        >
                            <img
                                src={currentStatus.icon}
                                className={classes.stepIcon}
                                width={22}
                                height={22}
                                alt="status"
                                style={{
                                    filter: 'invert(48%) sepia(54%) saturate(6823%)'
                                        + 'hue-rotate(164deg) brightness(92%) contrast(104%)' }}
                            />
                        </IconWithTooltip>
                    )
                }
            </div>
        </div>
    );
};

export default ShipmentStepHeader;
