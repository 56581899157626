import React from 'react';
import Card from 'shared-components/Card';
import PercentageScale from 'shared-components/PercentageScale';
import { makeStyles } from '@mui/styles';
import useCustomTranslation from 'hooks/useCustomTranslation';
import RowWithIcon from '../../RowWithIcon';
import TextArray from '../../TextArray';

const useStyles = makeStyles(theme => ({
    firstPercentageScaleContainer: {
        marginTop: '18px',
        marginBottom: '12px',
    },
    percentageScaleContainer: {
        marginBottom: '12px',
    },
    groupTitle: {
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: theme.palette.secondary[500],
        opacity: 1,
    },
    groupContainer: {
        marginBottom: '12px',
    },
}));

type Props = {
    categoryStatistics: {
        all: number,
        core: number,
    },
    certificates?: string[],
    className?: string,
    facilities?: {
        extendedLabel: string,
        icon: string,
        label: string,
    }[],
    title: string,
    id: string,
}

const CategorySummary = ({
    categoryStatistics,
    certificates = [],
    className = '',
    facilities = [],
    id,
    title,
} : Props) => {
    const classes = useStyles();
    const cardSize = document.getElementById(id)?.offsetWidth;
    const { t } = useCustomTranslation();

    return (
        <Card title={title}>
            <div id={id} className={className}>
                <div className={classes.firstPercentageScaleContainer}>
                    <PercentageScale
                        size={cardSize < 320 ? 20 : 30}
                        value={categoryStatistics.core}
                        label={`${categoryStatistics.core}%`}
                        title={t('COMMON.CORE_CAPABILITIES_ANSWERED')}
                    />
                </div>
                <div className={classes.percentageScaleContainer}>
                    <PercentageScale
                        size={cardSize < 320 ? 20 : 30}
                        value={categoryStatistics.all}
                        label={`${categoryStatistics.all}%`}
                        title={t('COMMON.ALL_CAPABILITIES_ANSWERED')}
                    />
                </div>
                {
                    Boolean(certificates.length) && (
                        <div className={classes.groupContainer}>
                            <div className={classes.groupTitle}>{t('COMMON.CERTIFICATES')}</div>
                            <TextArray textItems={certificates} />
                        </div>
                    )
                }
                {
                    Boolean(facilities.length) && (
                        <div className={classes.groupContainer}>
                            <div className={classes.groupTitle}>{t('COMMON.FACILITIES')}</div>
                            {
                                facilities.map((item) => {
                                    return (
                                        <RowWithIcon
                                            icon={item.icon}
                                            key={item.label}
                                            label={item.extendedLabel}
                                        />
                                    );
                                })
                            }
                        </div>
                    )
                }
            </div>
        </Card>
    );
};

export default CategorySummary;
