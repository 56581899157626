import { makeStyles } from '@mui/styles';
import { sidebarClosedWidth, sidebarWidth } from 'theme/shared/variables';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';

const useStyles = makeStyles((theme: SkycellThemeInterface) => ({
    a: {
        textDecoration: 'none',
    },
    buttons: {
        '&:hover': {
            width: '100%',
            background: `${theme.palette.primary.darkBlue} 0% 0% no-repeat padding-box!important`,
            opacity: 1,
            height: '42px',
            padding: '8px',
        },
    },
    boxStyle: {
        backgroundColor: '#009CCF!important',
        overflow: 'visible',
        width: `${sidebarClosedWidth}px`,
    },
    boxStyleOpened: {
        backgroundColor: '#009CCF!important',
        width: `${sidebarWidth}px`,
        '&::-webkit-scrollbar-track': {
            '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,0.3)',
            borderRadius: '10px',
            backgroundColor: theme.palette.primary[600],
        },
        '&::-webkit-scrollbar': {
            width: '6px',
            backgroundColor: theme.palette.primary[600],
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            '&::-webkit-scrollbar-shadow': 'inset 0 0 2px rgba(0,0,0,1)',
            backgroundColor: theme.palette.primary[200],
        },

    },
    // '@media only screen and (max-width: 1024px)': {
    //     boxStyleOpened: {
    //         width: '100%',
    //         height: '100%',
    //     },
    // },
    ItemText: {
        marginLeft: '15px',
        textAlign: 'left',
        font: 'normal normal 500 14px/17px Roboto',
        letterSpacing: '1.25px',
        // @ts-ignore
        color: theme.palette.common.menuTextWhite,
        textTransform: 'uppercase',
        opacity: 1,
        cursor: 'pointer',
    },
    list: {
        padding: '15px 0px 0px 0px!important',
    },
    icon: {
        width: '31px',
        height: '26px',
    },
}));

export default useStyles;
