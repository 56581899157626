import React, { useEffect } from 'react';
import { TimeRange } from 'dataTypes/common';
import { SensorDataRequestBody } from 'dataTypes/SecureBackend/processedData';
import CircularProgress from '@mui/material/CircularProgress';
import NoOpenEntity from '../NoOpenEntity';
import AssetUnit from '../AssetUnit';
import LoggerUnit from '../LoggerUnit';
// import PackagingUnit from '../PackagingUnit';
import ShipmentPackagingUnit from '../ShipmentPackagingUnit';
import {
    EntitySelectorItem,
    PackagingsInThisShipment,
} from '../../dataTypes';

type Props = {
    entitySelectorItem: EntitySelectorItem,
    requestBody: SensorDataRequestBody,
    setShipmentsActivePackagingSerialNumber?: (shipmentsActivePackagingSerialNumber?: string) => void,
    shipmentsActivePackagingSerialNumber?: string,
    timeRange: TimeRange,
    shipmentsActivePackaging?: PackagingsInThisShipment,
    localTimezone: boolean,
    showMap: boolean,
    isLoading?: boolean,
    core?: string,
}

const EntityController = ({
    entitySelectorItem,
    requestBody,
    shipmentsActivePackagingSerialNumber = null,
    setShipmentsActivePackagingSerialNumber,
    timeRange,
    localTimezone,
    showMap,
    isLoading = false,
    core = 'track-and-trace',
} : Props) => {
    const {
        entityNumber,
        entityType,
        entityId,
        additionalData = null,
    } = entitySelectorItem || {};

    const {
        packagingsInThisShipment = [],
    } = additionalData || {};

    useEffect(() => {
        if (packagingsInThisShipment.length === 1) {
            setShipmentsActivePackagingSerialNumber(packagingsInThisShipment[0].serialNumber);
        }
    }, [packagingsInThisShipment]);

    if (entityType) {
        if (entityType === 'shipment') {
            return (
                <>
                    {
                        packagingsInThisShipment.map(packaging => {
                            const { serialNumber } = packaging;

                            return (
                                <ShipmentPackagingUnit
                                    requestBody={requestBody}
                                    serialNumber={serialNumber}
                                    shipmentNumber={entitySelectorItem.entityNumber}
                                    setShipmentsActivePackagingSerialNumber={
                                        setShipmentsActivePackagingSerialNumber
                                    }
                                    temperatureRange={additionalData.temperatureRange}
                                    timeRange={timeRange}
                                    isActivePackaging={serialNumber === shipmentsActivePackagingSerialNumber}
                                    isOnlyPackagingInTheShipment={packagingsInThisShipment.length === 1}
                                    isSelectedAnyPackaging={shipmentsActivePackagingSerialNumber !== null}
                                    showMap={showMap}
                                    localTimezone={localTimezone}
                                    key={serialNumber}
                                />
                            );
                        })
                    }
                </>
            );
        } else
            if (entityType === 'loggers') {
                return (
                    <LoggerUnit
                        localTimezone={localTimezone}
                        loggerNumber={entityNumber}
                        options={requestBody}
                        showMap={showMap}
                        timeRange={timeRange}
                        core={core}
                    />
                );
            } else {
                return (
                    <AssetUnit
                        localTimezone={localTimezone}
                        loggerNumber={entityNumber}
                        options={requestBody}
                        showMap={showMap}
                        timeRange={timeRange}
                        entityId={entityId}
                        entityType={entitySelectorItem.additionalData.assetType}
                    />
                );
            }
    }

    if (!isLoading) {
        return (
            <NoOpenEntity />
        );
    } else {
        return <CircularProgress />;
    }
};

export default EntityController;
