import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    editCompanyCardContentData: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr 1fr 1fr 1fr',
        padding: '12px',
        gridGap: '12px',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
}));

export default useStyles;
