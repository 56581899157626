import React, { CSSProperties } from 'react';
import icons from 'shared-components/icons';

const containerStyle: CSSProperties = {
    display: 'flex',
    background: '#2C5969 0% 0% no-repeat padding-box',
    borderRadius: '5px',
    opacity: 1,
    font: 'normal normal normal 14px/20px Roboto',
    letterSpacing: '0px',
    color: '#FFFFFF',
    // marginRight: '8px',
    padding: '0 3px',
    textAlign: 'center',
};

type Props = {
    count: number,
    icon?: 'email' | 'todo_list',
}

const NotificationCount = ({
    count,
    icon = null,
} : Props) => {
    return (
        <div style={containerStyle}>
            {
                icon && <img alt="tasks" src={icons[icon]} />
            }
            <div style={{ marginLeft: icon ? '4px' : '-1px' }}>{count}</div>
        </div>
    );
};

export default NotificationCount;
