import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useState,
} from 'react';
import { AccordionOptionsGroup, AirportOptions, FilterBlock } from 'shared-components/SideFilter';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { ClientSideFilterType, ColumnsType } from '../../dataTypes';

type Props = {
    originAirportColumn: ColumnsType,
    destinationAirportColumn: ColumnsType,
    originAirportOptions: string[],
    destinationAirportOptions: string[],
    setSelectedFilterOptions: Dispatch<SetStateAction<ClientSideFilterType>>
}

const AirportFilter = ({
    originAirportColumn,
    destinationAirportColumn,
    originAirportOptions,
    destinationAirportOptions,
    setSelectedFilterOptions,
} : Props) => {
    const { t } = useCustomTranslation();

    const [selectedOriginOptions, setSelectedOriginOptions] = useState<string[]>(originAirportOptions);
    const [selectedDestinationOptions, setSelectedDestinationOptions] = useState<string[]>(destinationAirportOptions);

    const { sideFilterKey: originAirportFilterKey } = originAirportColumn;
    const { sideFilterKey: destinationAirportFilterKey } = destinationAirportColumn;

    useEffect(() => {
        setSelectedFilterOptions(prev => {
            if (selectedOriginOptions.length === 0) {
                // eslint-disable-next-line no-unused-vars
                const { [originAirportFilterKey]: unnecessary, ...filtersWithoutCurrentKey } = prev;

                return filtersWithoutCurrentKey;
            } else {
                return { ...prev, [originAirportFilterKey]: selectedOriginOptions };
            }
        });
    }, [selectedOriginOptions]);

    useEffect(() => {
        setSelectedFilterOptions(prev => {
            if (selectedDestinationOptions.length === 0) {
                // eslint-disable-next-line no-unused-vars
                const { [destinationAirportFilterKey]: unnecessary, ...filtersWithoutCurrentKey } = prev;

                return filtersWithoutCurrentKey;
            } else {
                return { ...prev, [destinationAirportFilterKey]: selectedDestinationOptions };
            }
        });
    }, [selectedDestinationOptions]);

    return (
        <FilterBlock>
            <AccordionOptionsGroup title={t('LANE_MANAGEMENT.ADDRESS.AIRPORT')}>
                <AirportOptions
                    availableDestinationAirports={destinationAirportOptions}
                    availableOriginAirports={originAirportOptions}
                    selectedDestinationAirports={selectedDestinationOptions}
                    selectedOriginAirports={selectedOriginOptions}
                    setDestinationAirports={setSelectedDestinationOptions}
                    setOriginAirports={setSelectedOriginOptions}
                />
            </AccordionOptionsGroup>
        </FilterBlock>
    );
};

export default AirportFilter;
