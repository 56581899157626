import React from 'react';
import { OverlayView, Polygon, PolygonProps } from '@react-google-maps/api';
import { LatLng } from 'dataTypes/common';

type Props = PolygonProps & {
    position: LatLng,
    name: string,
    isNameVisible?: boolean,
}

const CustomPolygon = ({
    isNameVisible = false,
    name,
    options = {
        strokeColor: '#FFC30B',
        strokeOpacity: 0.4,
        strokeWeight: 1,
        fillColor: '#FFC30B',
        fillOpacity: 0.15,
    },
    position,
    paths = null,
} : Props) => {
    return (
        <div>
            <Polygon
                options={options}
                paths={paths}
            />
            <OverlayView
                position={position}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
                <div style={{ visibility: isNameVisible ? 'visible' : 'hidden' }}>{name}</div>
            </OverlayView>
        </div>
    );
};

export default CustomPolygon;
