import React from 'react';
import { PICTURES } from 'shared-components/constants';
import useCustomTranslation from 'hooks/useCustomTranslation';
import Heading from '../Components/Heading';
import Division from '../Components/Division';

const NoUserGuide = () => {
    const { t } = useCustomTranslation();

    return (
        <>
            <Heading
                text={t('COMMON.NO_USER_GUIDE')}
            />
            <Division flex={[{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
            }]}
            >
                <p>
                    {t('COMMON.NO_USER_GUIDE_TEXT')}
                </p>
                <img src={PICTURES.noDataFound} alt="No Data Found" />
            </Division>
        </>
    );
};

export default NoUserGuide;
