import React, {
    useCallback, useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Marker,
    Polyline,
} from '@react-google-maps/api';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useGetAirports, { getAirportsInPassedBoundsZone } from 'hooks/useGetAirports';
import { LatLng, LatLngAndTime } from 'dataTypes/common';
import { joinLoggers } from 'shared-components/common';
import LocationInfoTooltip from 'shared-components/Map/components/LocationInfoTooltip';
import { QuickHelpContext } from 'Contexts/QuickHelpContext/QuickHelpContext';
import QuickHelpTooltip from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import AirportMarker from 'TrackAndTrace/commonComponents/AirportMarker';
import AirportInfoTooltip from 'TrackAndTrace/Tooltips/AirportInfoTooltip';
import { ActiveAirportInfo } from 'TrackAndTrace/Tooltips/dataTypes';

import TooltipPolygonMarker from 'TrackAndTrace/commonComponents/TooltipPolygonMarker';
import useAvailableHeight from 'hooks/useAvailableHeight';
import LoggerMarker from 'TrackAndTrace/Loggers/components/LoggerMarker';
import LoggerTooltip from 'TrackAndTrace/Loggers/components/LoggerTooltip';
import { useTheme } from '@mui/material/styles';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import {
    ActiveLogger,
    ExtractedLoggerData,
    markerIconObj,
} from './lib';

import LegendPanel from './components/LegendPanel';

import BaseMap from '../commonComponents/BaseMap';

type Props = {
    countOfLoggersWithoutGeolocation?: number,
    loggers: ExtractedLoggerData[],
    showAirportsInfo: boolean,
    showLoggerTypes: boolean,
    showLastConnection: boolean,
    core: string,
}
type LoggerGroups = {
    [key: string]: ExtractedLoggerData[],
};

const MapView = ({
    countOfLoggersWithoutGeolocation = 0,
    loggers = [],
    showLoggerTypes,
    showLastConnection,
    showAirportsInfo,
    core = 'track-and-trace',
}:Props) => {
    const { t } = useCustomTranslation();
    const { enableTooltips } = useContext(QuickHelpContext);
    const [gMap, setGMap] = useState(null);
    const [bounds, setBounds] = useState<number[]>([]);
    const [activeLogger, setActiveLogger] = useState<ActiveLogger>({
        loggerNumber: null,
        timestamp: Date.now(),
    });
    const theme = useTheme<SkycellThemeInterface>();
    const [hoveredGroupGeoKey, setHoveredGroupGeoKey] = useState<string>(null);
    const availableheight = useAvailableHeight();
    const [mapContainerRef, setMapContainerRef] = useState(null);
    const [polylineMarkerInfo, setPolylineMarkerInfo] = useState<LatLngAndTime>(null);
    const [polylinePath, setPolylinePath] = useState<LatLng[]>([]);
    const [activeAirportInfo, setActiveAirportInfo] = useState<ActiveAirportInfo>({
        code: null,
        timestamp: Date.now(),
    });

    const airports = getAirportsInPassedBoundsZone(useGetAirports(), bounds);

    const closeActiveTooltip = useCallback(() => {
        const delay = 300;
        const now = Date.now();

        if (activeLogger.loggerNumber !== null && activeLogger.timestamp + delay < now) {
            setActiveLogger({
                loggerNumber: null,
                timestamp: now,
            });
        }
        if (activeAirportInfo.code !== null && activeAirportInfo.timestamp + delay < now) {
            setActiveAirportInfo({
                code: null,
                timestamp: now,
            });
        }
    }, [activeLogger, activeAirportInfo]);

    const cancelAirportInfoTooltipClosing = useCallback(() => {
        setActiveAirportInfo(prev => ({
            code: prev.code,
            timestamp: Date.now(),
        }));
    }, []);

    useEffect(() => {
        if (activeLogger.loggerNumber === null) {
            setPolylineMarkerInfo(null);
            setPolylinePath([]);
        }
    }, [activeLogger]);

    const showedAirportInfo = useMemo(() => {
        if (airports.length === 0 || activeAirportInfo === null || activeAirportInfo.code === null) {
            return null;
        }

        const airport = airports.find(item => item.code === activeAirportInfo.code) || null;

        if (airport) {
            return airport;
        }
        return null;
    }, [activeAirportInfo, airports]);

    const groupedLoggers = useMemo<LoggerGroups>(() => joinLoggers<ExtractedLoggerData>(loggers), [loggers]);

    return (
        <div style={{ display: 'flex' }}>
            <QuickHelpTooltip
                tooltipInfo={{
                    order: 5,
                    text: t('QUICK_HELP.TRACK_AND_TRACE.PACKAGING_MAP'),
                    backgroundOpacity: 0.2,
                    childOffsetPercent: [50, 50],
                    customRectSize: [60, 40],
                    offsetPx: [60, 40],
                    explicitChildRef: mapContainerRef,
                    uid: 'packagingMapTooltip',
                }}
            >
                <BaseMap
                    onContainerRefLoad={(ref) => {
                        setMapContainerRef(ref);
                    }}
                    mapContainerStyle={{
                        width: '100%',
                        height: `calc(${availableheight} - 60px)`,
                    }}
                    gMap={gMap}
                    setGMap={setGMap}
                    setBounds={setBounds}
                    onClick={closeActiveTooltip}
                >
                    {
                        enableTooltips && (
                            <TooltipPolygonMarker itemType="Logger" />
                        )
                    }
                    {
                        Object.keys(groupedLoggers).map((geoKey) => (
                            <LoggerMarker
                                key={geoKey}
                                loggerGroup={groupedLoggers[geoKey]}
                                showLoggerTypes={showLoggerTypes}
                                showLastConnection={showLastConnection}
                                onHover={() => setHoveredGroupGeoKey(geoKey)}
                                isSelected={hoveredGroupGeoKey === geoKey}
                            />
                        ))
                    }

                    {
                        showAirportsInfo && airports.map(airport => {
                            const handleClickAirportsInfo = () => {
                                setActiveAirportInfo(prev => ({
                                    code: prev.code === airport.code
                                        ? null
                                        : airport.code,
                                    timestamp: Date.now(),
                                }));
                            };

                            return (
                                <AirportMarker
                                    onClick={handleClickAirportsInfo}
                                    isOpen={activeAirportInfo.code === airport.code}
                                    airportInfo={airport}
                                    key={airport.code}
                                />
                            );
                        })
                    }
                    {
                        showedAirportInfo !== null && (
                            <AirportInfoTooltip
                                airportInfo={showedAirportInfo}
                                cancelTooltipClosing={cancelAirportInfoTooltipClosing}
                            />
                        )
                    }
                    {
                        hoveredGroupGeoKey && (
                            <LoggerTooltip
                                loggerGroup={groupedLoggers[hoveredGroupGeoKey]}
                                onMouseLeave={() => {
                                    setPolylinePath([]);
                                    setHoveredGroupGeoKey(null);
                                }}
                                setPolylinePath={setPolylinePath}
                                core={core}
                            />
                        )
                    }
                    {
                        polylinePath.length > 0 && (
                            <Polyline
                                path={polylinePath}
                                options={{
                                    geodesic: true,
                                    strokeOpacity: 1.0,
                                    strokeWeight: 3,
                                    strokeColor: theme.palette.primary.deepBlue,
                                }}
                            />
                        )
                    }
                    {
                        polylineMarkerInfo && (
                            <Marker
                                position={polylineMarkerInfo.location}
                                icon={markerIconObj}
                            />
                        )
                    }
                    {
                        polylineMarkerInfo && (
                            <LocationInfoTooltip
                                location={polylineMarkerInfo.location}
                                time={polylineMarkerInfo.time}
                            />
                        )
                    }
                    <LegendPanel countOfLoggersWithoutGeolocation={countOfLoggersWithoutGeolocation} />
                </BaseMap>
            </QuickHelpTooltip>
        </div>
    );
};

export default MapView;
