import React from 'react';
import { PICTURES } from 'shared-components/constants';
import useStyles from './NoSensorInfo.style';

type Props = {
    picture?: 'reading_robot' | 'noDataFound',
    pictureSize?: 'small' | 'medium' | 'large',
    text: string,
}

const NoSensorInfo = ({
    picture = 'reading_robot',
    pictureSize = 'medium',
    text = '',
}: Props) => {
    const classes = useStyles();

    return (
        <div className={classes[`${pictureSize}Row`]}>
            <img
                key="reading_robotPicture"
                className={classes[pictureSize]}
                src={PICTURES[picture]}
                alt="reading_robot"
            />
            <div className={classes[`${pictureSize}InfoField`]}>{text}</div>
        </div>
    );
};

export default NoSensorInfo;
