import moment from 'moment';

const dayPassedToRange = (value = 1) => {
    return {
        from: moment().subtract({ day: value }).utc().format('YYYY-MM-DDTHH:mm'),
        to: moment().utc().format('YYYY-MM-DDTHH:mm'),
    };
};

export default dayPassedToRange;
