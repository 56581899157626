import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    switchItemContainerStyle: {
        display: 'flex',
        marginTop: '12px',
        alignItems: 'center',
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    blocked: {
        pointerEvents: 'none',
        '& .MuiSwitch-thumb': {
            background: theme.palette.secondary[500],
        },
    },
    tooltipIcon: {
        backgroundColor: theme.palette.primary[200],
        color: 'white',
        borderRadius: '50%',
        width: '18px ',
        height: '18px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '3px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    tooltipIconContainer: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: theme.palette.secondary.main,
        opacity: 1,
    },
    modalData: {
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: theme.palette.secondary[600],
        opacity: 1,
    },
    dataRow: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        gridGap: '12px',
        borderBottom: '1px solid #0000001A',
        marginTop: '18px',
        paddingBottom: '13px',
    },
}));

export default useStyles;
