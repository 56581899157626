import React, { useState } from 'react';
import CardWithEditableContent from 'shared-components/CardWithEditableContent';

import useCustomTranslation from 'hooks/useCustomTranslation';
import EditMode from './EditMode';
import ReadMode from './ReadMode';
import useStyles from './OfferingsCard.style';

type Props = {
    currentCommodities: string[],
    currentServices: string[],
    currentEditedCard?: string,
    type?: 'editable' | 'readOnly',
    saveInfo?: () => void,
    setCurrentEditedCard?: (currentEditedCard: string) => void,
    setOfferings?: (value: string[]) => void,
}

const cardName = 'OfferingsCard';

const OfferingsCard = ({
    currentCommodities = [],
    currentServices = [],
    currentEditedCard = null,
    type = 'readOnly',
    saveInfo = () => {},
    setCurrentEditedCard = () => {},
    setOfferings = () => {},
} : Props) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const { t } = useCustomTranslation();
    const handleClickEditButton = () => {
        setCurrentEditedCard(cardName);
        setEditMode(true);
    };

    const handleClickSaveButton = async () => {
        setLoading(true);
        await saveInfo();
        setEditMode(false);
        setLoading(false);
        setCurrentEditedCard(null);
    };

    const handleClickUndoButton = () => {
        setCurrentEditedCard(null);
        setEditMode(false);
    };

    return (
        <CardWithEditableContent
            className={loading ? classes.loading : ''}
            onClickEditButton={handleClickEditButton}
            onClickSaveButton={handleClickSaveButton}
            onClickUndoButton={handleClickUndoButton}
            showEditButton={type === 'editable' && !editMode}
            showSaveButton={type === 'editable' && editMode}
            showUndoButton={type === 'editable' && editMode}
            disabledEditButton={currentEditedCard !== null && currentEditedCard !== cardName}
            title={t('SERVICE_PROVIDERS.OFFERINGS')}
        >
            {
                (type === 'editable' && editMode)
                    ? (
                        <EditMode
                            currentCommodities={currentCommodities}
                            currentServices={currentServices}
                            setOfferings={setOfferings}
                        />
                    )
                    : (
                        <ReadMode
                            currentCommodities={currentCommodities}
                            currentServices={currentServices}
                        />
                    )

            }
        </CardWithEditableContent>
    );
};

export default OfferingsCard;
