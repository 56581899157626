import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    loading: {
        transition: '200ms ease',
        filter: 'blur(2px)',
    },
    offeringsCardContainer: {
        textAlign: 'left',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        color: '#747474',
        opacity: 1,
    },
    title: {
        margin: '10px 0 5px',
        textAlign: 'left',
        font: 'normal normal normal 12px/14px Roboto',
        letterSpacing: '0.4px',
        color: '#939393',
        opacity: 1,
    },
    offeringItemContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '7px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    offeringIconContainer: {
        width: '30px',
        marginRight: '5px',
    },
});

export default useStyles;
