import React, { SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useStyles from './UserInfo.style';

type Props = {
    logout: (ev) => void,
    open: boolean,
    notificationCount: number,
    setOpen: (prev : SetStateAction<boolean>) => void,
    width?: number | string,
}

const UserInfoMenuEntries = ({
    logout,
    open = false,
    width = '10%',
    setOpen,
} : Props) => {
    const classes = useStyles({
        collapseWidth: width,
    });
    const { t } = useCustomTranslation();

    const handleClickToCollapseItem = () => setOpen(false);

    return (
        <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            className={classes.collapse}
        >
            <div style={{ display: 'grid' }}>
                <Link to="/my-profile" className={classes.a}>
                    <Button
                        key="my-profile"
                        className={classes.userInfoMenuEntry}
                        onClick={handleClickToCollapseItem}
                    >
                        <PersonIcon fontSize="small" className={classes.userInfoMenuEntryIcon} />
                        <div className={classes.notificationLabel}>My Profile</div>
                    </Button>
                </Link>
                <Button
                    key="logout"
                    onClick={logout}
                    className={classes.userInfoMenuEntry}
                >
                    <LogoutIcon fontSize="small" className={classes.userInfoMenuEntryIconLogout} />
                    {t('COMMON.LOG_OUT')}
                </Button>
            </div>
        </Collapse>
    );
};

export default UserInfoMenuEntries;
