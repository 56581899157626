import { LatLng } from 'dataTypes/common';
import React, { useCallback, useState } from 'react';
import { OverlayView } from '@react-google-maps/api';
import ClusterBodyItem
from 'TrackAndTrace/GenericShipments/components/ShipmentsMap/components/TooltipCluster/ClusterBodyItem';
import { ActiveShipment } from 'TrackAndTrace/GenericShipments/components/ShipmentsMap/ShipmentsMap';
import { ClusterInfo, GenericShipmentData } from 'TrackAndTrace/GenericShipments/lib';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import useStyles from './TooltipCluster.style';

interface ActiveCluster {
    clusterId: number,
    timestamp: number,
    shipmentNumbers: string[],
}

type Props = {
    cluster: ClusterInfo,
    activeCluster: ActiveCluster,
    setActiveCluster: (activeCluster: ActiveCluster | any) => void,
    shipments: GenericShipmentData[],
    closeActiveTooltip: Function,
    activeShipment: ActiveShipment,
    setActiveShipment: Function,
    position?: LatLng,
}
export const CIRCLE_GROW = 10;
export const CIRCLE_SIZE = 35;

const getClusterPositionOffset = () => ({
    x: -(CIRCLE_GROW + CIRCLE_SIZE) / 2,
    y: -(CIRCLE_GROW + CIRCLE_SIZE) / 2,
});

const TooltipCluster = ({
    cluster,
    setActiveCluster,
    shipments = [],
    activeCluster,
    closeActiveTooltip,
    activeShipment,
    setActiveShipment,
    position = null,
} : Props) => {
    const classes = useStyles();
    const { company } = useCurrentUserContext();
    const [isFrontElement, setFrontElement] = useState(false);
    const showTooltip = useCallback(() => {
        setActiveCluster(() => {
            return ({
                clusterId: cluster.id,
                shipmentNumbers: cluster.externalIds,
                timestamp: Date.now(),
            });
        });
        setFrontElement(true);
    }, [cluster, activeCluster]);
    const hideTooltip = useCallback(() => {
        setFrontElement(false);
        closeActiveTooltip();
        setActiveCluster({
            clusterId: null,
            timestamp: Date.now(),
            shipmentNumbers: [],
        });
    }, [closeActiveTooltip, activeCluster]);

    const stopPropagation = useCallback((e) => e.stopPropagation(), []); // disable double click zoom on tooltip

    return (
        <>
            <OverlayView
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                position={position || cluster.position}
                getPixelPositionOffset={getClusterPositionOffset}
            >
                <div
                    className={[
                        classes.wrapper,
                    ].join(' ')}
                    onMouseEnter={showTooltip}
                    onMouseLeave={hideTooltip}
                    onDoubleClick={stopPropagation}
                >
                    <div
                        className={[
                            classes.tooltip,
                            activeCluster.clusterId === cluster.id ? classes.active : '',
                            cluster.externalIds.includes('CT-023321') ? classes.tooltipPredicted
                                : cluster.hasWarning ? classes.tooltipWarning : ''].join(' ')}
                        style={{ zIndex: isFrontElement ? 100 : 0 }}
                    >
                        {cluster.externalIds.length}
                    </div>
                    {
                        activeCluster.clusterId === cluster.id && (
                            <div
                                className={classes.clusterBody}
                            >
                                {
                                    cluster.externalIds.map(externalId => (
                                        <ClusterBodyItem
                                            key={externalId}
                                            shipment={shipments.find(it => it.externalId === externalId)}
                                            activeShipment={activeShipment}
                                            setActiveShipment={setActiveShipment}
                                            energyThreshold={company?.energyLevelThreshold}
                                        />
                                    ))
                                }
                            </div>

                        )
                    }
                </div>
            </OverlayView>
        </>

    );
};

export default TooltipCluster;
