import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    location: {
        background: theme.palette.common.white,
        marginLeft: '16px',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingRight: '10px',
    },
    transport: {
        background: theme.palette.common.white,
    },
    control: {
        background: theme.palette.common.white,
    },
    hoverIconsWrapper: {
        position: 'absolute',
        top: '0px',
        right: '0px',
        padding: '6px 10px',

    },
    hoverIconsRow: {
        transition: '200ms ease-in-out',
        pointerEvents: 'none',
        opacity: 0,
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 20px)',
        whiteSpace: 'nowrap',
    },
    icon: {
        color: theme.palette.secondary[600],
        cursor: 'pointer',
        fontSize: '23px',
        height: '23px',
        '&:hover': {
            color: theme.palette.secondary[800],
        },
    },
    visible: {
        opacity: 1,
        pointerEvents: 'auto',
    },
}));
