import moment from 'moment';
import icons from 'shared-components/icons';
import {
    Cargo,
    EnergyLevelCheckResult,
    Location,
    OrderStep,
    Shipment,
    ShipmentLaneResponseDto, SkyMindInfo, TemperatureCheckResult,
} from 'dataTypes/SecureBackend/apiResponse/Shipment';
import {
    Airport,
    Geolocation,
} from 'dataTypes/SecureBackend/apiResponse';
import { QmApprovalInfo } from 'dataTypes/SecureBackend/processedData';
import { LatLng, CompositePolyline, TimeRange } from 'dataTypes/common';
import { ORDER_STEP_TYPE } from 'shared-components/constants';
import { IconTooltipInfo, AddressDTO } from 'shared-components/dataTypes';
import { getIconTooltipInfo, getIconByStatus } from 'utils/shipmentsDataProcessing';
import polylineType from 'shared-components/Map/polylineType';
import {
    getApprovalStatuses,
    getPackagingTypesCount,
    getPackagingIcons,
    getPolylines,
    checkIsPending,
    checkIsWarning,
    getShipmentTimeRange, getCurrentPosition,
} from 'TrackAndTrace/Shipments/lib';

export interface Summary {
    customerReference: string,
    originAirportCode: string,
    originAirportName: string,
    destinationAirportCode: string,
    destinationAirportName: string,
    laneNumber: string,
    packagingSquareIcons: string[],
    packagingTypesCount: { [key: string]: number },
    status: string,
    mawbNumber: string,
    hawbNumber: string,
    temperatureRange: string,
    fourPlName: string,
    fourthPartyLogistics: string,
    pharmaCompanyName: string,
    isWarning: boolean,
}

export interface RouteInfo {
    originLabel: string,
    originValue: string | AddressDTO,
    destinationLabel: string,
    destinationValue: string | AddressDTO,
    polylines: CompositePolyline[],
    marker: LatLng,
}

export interface Packaging {
    serialNumber: string,
    lastMeasurementInfo: {
        geolocation: Geolocation,
    },
    productRelease: {
        id: number,
        palletCode1: string,
        palletCode2: string,
        containerApprovalsIcon: string,
        sealApprovalsIcon: string,
        temperatureIcon: string,
        pallet1ApprovalsIcon: string,
        pallet2ApprovalsIcon: string,
        containerApprovalsIconTooltipInfo: IconTooltipInfo,
        sealApprovalsIconTooltipInfo: IconTooltipInfo,
        temperatureReadoutTooltipInfo: IconTooltipInfo,
        pallet1ApprovalsIconTooltipInfo: IconTooltipInfo,
        pallet2ApprovalsIconTooltipInfo: IconTooltipInfo,
        temperatureRangeMin: number,
        temperatureRangeMax: number,
        temperatureMin: number,
        temperatureMax: number,
        lastMeasuredTempInternal: number,
        temperatureExcursionOn: string,
        temperatureRange: string,
        qmApproval: QmApprovalInfo,
        approvalStatus: string,
        temperatureStatus: string,
        containerStatus: string,
        palletStatus: string,
        palletStatus1: string,
        palletStatus2: string,
        sealStatus: string,
    },
    temperatureCheckResult: TemperatureCheckResult,
}

export interface ShipmentStepInfo {
    locationIcon: string,
    clockIcon: string,
    locationName: string,
    actualTimeStamp: string,
    plannedTimestamp: string,
    timeStamp?: string,
    stepGeolocation: Geolocation,
}

export interface ShipmentStep {
    stepType: string,
    stepStatus: string,
    shipmentStepInfo: ShipmentStepInfo[],
}
export interface ShipmentDetailsInterface {
    shipmentNumber: string,
    energyLevel?: EnergyLevelCheckResult[],
    summary: Summary,
    lane: ShipmentLaneResponseDto,
    routeInfo: RouteInfo,
    packagings: Packaging[],
    shipmentSteps: ShipmentStep[],
    shipmentSensorDataTimeRange: TimeRange,
    leaseEndExpected: string,
    leaseStart: string,
    leaseEnd: string,
    shipmentStart: string,
    shipmentEnd: string,
    skyMindId: number,
    startGeolocation: Geolocation,
    endGeolocation: Geolocation,
    skyMindInfo: {
        temperatureRange: string,
    }
}

const getPackagings = (cargo: Cargo[] = [], labels = {}): Packaging[] => {
    return cargo.filter(item => item.packaging).map(item => {
        const {
            packaging,
            skyCoreProductRelease,
            temperatureCheckResult,
        } = item;

        const { serialNumber = '', lastMeasurementInfo, packagingType } = packaging;
        const { temperatureRange } = packagingType || {};
        const {
            tempInternal = null,
        } = lastMeasurementInfo || {};

        const {
            id,
            palletCode1 = '',
            palletCode2 = '',
            containerStatus = '',
            sealStatus = '',
            palletStatus1 = '',
            palletStatus2 = '',
            palletStatus = '',
            approvalStatus = '',
            temperatureExcursionOn = null,
            qmApprovalComment,
            qmApprovalStatus,
            qmApprovalDetails = [],
            qmApprovedBy,
            qmApprovedOn,
        } = skyCoreProductRelease || {};

        const {
            temperatureStatus = '',
            temperatureMin = null,
            temperatureMax = null,
            temperatureRangeMin = null,
            temperatureRangeMax = null,
        } = temperatureCheckResult || {};

        return {
            serialNumber,
            lastMeasurementInfo,
            productRelease: {
                id,
                palletCode1,
                palletCode2,
                containerApprovalsIcon: getIconByStatus(containerStatus),
                sealApprovalsIcon: getIconByStatus(sealStatus),
                temperatureIcon: getIconByStatus(temperatureStatus),
                pallet1ApprovalsIcon: getIconByStatus(palletStatus1),
                pallet2ApprovalsIcon: getIconByStatus(palletStatus2),
                containerApprovalsIconTooltipInfo: getIconTooltipInfo(containerStatus, labels),
                sealApprovalsIconTooltipInfo: getIconTooltipInfo(sealStatus, labels),
                temperatureReadoutTooltipInfo: getIconTooltipInfo(temperatureStatus, labels),
                pallet1ApprovalsIconTooltipInfo: getIconTooltipInfo(palletStatus1, labels),
                pallet2ApprovalsIconTooltipInfo: getIconTooltipInfo(palletStatus2, labels),
                temperatureRangeMin,
                temperatureRangeMax,
                temperatureMin,
                temperatureRange,
                temperatureMax,
                containerStatus,
                sealStatus,
                temperatureStatus,
                palletStatus1,
                palletStatus2,
                lastMeasuredTempInternal: tempInternal,
                palletStatus,
                approvalStatus,
                temperatureExcursionOn: temperatureExcursionOn
                    ? moment(temperatureExcursionOn).format('DD.MM.YYYY HH:mm')
                    : null,
                qmApproval: {
                    qmApprovalComment,
                    qmApprovalStatus,
                    qmApprovalDetails,
                    qmApprovedBy,
                    qmApprovedOn,
                    qmApprovalIcon: getIconByStatus(qmApprovalStatus),
                },
            },
            temperatureCheckResult,
        };
    });
};

const getCompositePolyline = (orderSteps: OrderStep[] = [], isPending = false): CompositePolyline[] => {
    return getPolylines(orderSteps).map(polyline => {
        return {
            path: polyline.path,
            options: {
                strokeColor: isPending ? '#009CCF' : '#009CCF',
                ...(polylineType(
                    polyline.stepStatus !== ORDER_STEP_TYPE.CLOSED,
                )),
            },
        };
    });
};

const getGeolocatioFromOrderStep = (orderStep: OrderStep, position: 'start' | 'end'): Geolocation => {
    const {
        location = null,
        deliveryLocation = null,
        pickupLocation = null,
    } = orderStep || {};
    const { geolocation: geolocationFromLocation = null } = location || {};
    const { geolocation: geolocationFromDeliveryLocation = null } = deliveryLocation || {};
    const { geolocation: geolocationFromPickupLocation = null } = pickupLocation || {};

    return geolocationFromLocation !== null
        ? geolocationFromLocation
        : position === 'start'
            ? geolocationFromDeliveryLocation
            : geolocationFromPickupLocation;
};

/**
 *  Shipment Steps processing
 */

const fetchLocationInfo = (location: Location = null, stepStatus,
    actualTimeStamp = null, plannedTimestamp = null, departureOrArrivalimestamp = null): ShipmentStepInfo[] => {
    if (location === null) {
        return [];
    }
    const { addressName = '', geolocation = null } = location;
    const isBlue = stepStatus === 'CLOSED' || actualTimeStamp !== null || departureOrArrivalimestamp !== null;

    return [{
        clockIcon: isBlue ? icons.clock_blue : icons.clock,
        locationIcon: isBlue ? icons.address_blue : icons.address,
        locationName: addressName,
        actualTimeStamp,
        plannedTimestamp,
        stepGeolocation: geolocation,
    }];
};

const fetchAirportInfo = (airport: Airport = null, stepStatus,
    actualTimeStamp = null, plannedTimestamp = null, departureOrArrivalimestamp = null): ShipmentStepInfo[] => {
    if (airport === null) {
        return [];
    }
    const {
        iataCode = '',
        airportName = '',
        geolocation = null,
    } = airport;
    const isBlue = stepStatus === 'CLOSED' || actualTimeStamp !== null || departureOrArrivalimestamp !== null;

    return [{
        clockIcon: isBlue ? icons.clock_blue : icons.clock,
        locationIcon: isBlue ? icons.airport_blue : icons.airport,
        locationName: `${iataCode} | ${airportName}`,
        actualTimeStamp,
        plannedTimestamp,
        stepGeolocation: geolocation,
    }];
};

const getShipmentStepInfo = (orderStep: OrderStep): ShipmentStepInfo[] => {
    const {
        pickupLocation = null,
        originAirport = null,
        destinationAirport = null,
        deliveryLocation = null,
        location = null,
        actualArrival = null,
        actualDelivery = null,
        actualDeparture = null,
        actualCompletion = null,
        actualPickup = null,
        plannedPickup = null,
        plannedCompletion = null,
        plannedDeparture = null,
        plannedArrival = null,
        plannedDelivery = null,
        stepStatus = 'CLOSED',
    } = orderStep;

    if (pickupLocation === null) {
        return fetchLocationInfo(location, stepStatus, actualCompletion, plannedCompletion);
    }

    const airportsInfo = originAirport === null
        ? []
        : [
            ...fetchAirportInfo(originAirport, stepStatus, actualDeparture, plannedDeparture),
            ...fetchAirportInfo(destinationAirport, stepStatus, actualArrival, plannedArrival),
        ];

    return [
        ...fetchLocationInfo(pickupLocation, stepStatus, actualPickup, plannedPickup, actualDeparture),
        ...airportsInfo,
        ...fetchLocationInfo(deliveryLocation, stepStatus, actualDelivery, plannedDelivery, actualArrival),
    ];
};

/**
 *  Shipment Steps processing end
 */

export const getShipmentData = (rawShipment: Shipment): {
    summary: {
        temperatureRange: string;
        destinationAirportCode: string;
        originAirportCode: string;
        isWarning: boolean;
        packagingTypesCount: { [p: string]: number };
        pharmaCompanyName: string;
        destinationAirportName: string;
        originAirportName: string;
        laneNumber: string;
        packagingSquareIcons: string[];
        fourPlName: string;
        customerReference: string;
        mawbNumber: string;
        fourthPartyLogistics: any;
        hawbNumber: string;
        status: string
    };
    skyMindId: number;
    leaseEndExpected: string;
    skyMindInfo: SkyMindInfo;
    shipmentEnd: string;
    routeInfo: {
        polylines: CompositePolyline[];
        destinationLabel: string;
        destinationValue: any;
        marker: LatLng;
        originLabel: string;
        originValue: any
    };
    packagings: Packaging[];
    startGeolocation: Geolocation;
    leaseStart: string;
    energyLevel?: EnergyLevelCheckResult[];
    shipmentSteps: { stepType: string; shipmentStepInfo: ShipmentStepInfo[]; stepStatus: string }[];
    leaseEnd: string;
    shipmentNumber: string;
    lane: {
        temperatureRange: string;
        originAirport: any;
        loadingLocation: any;
        skyMindId: number;
        fourthPartyLogistics: any;
        id: number;
        laneNumber: string;
        unloadingLocation: any;
        destinationAirport: any
    };
    shipmentSensorDataTimeRange: TimeRange;
    endGeolocation: Geolocation;
    shipmentStart: string
} => {
    const {
        shipmentNumber = '',
        lane,
        cargo = [],
        skyMindInfo,
        customerReference = '',
        status,
        fourthPartyLogisticsCompany = null,
        pharmaCompany,
    } = rawShipment;

    const { name: fourPlName = '' } = fourthPartyLogisticsCompany || {};

    const {
        originAirport = null,
        destinationAirport = null,
        loadingLocation = null,
        unloadingLocation = null,
        id = null,
        laneNumber = null,
        fourthPartyLogistics = null,
    } = lane || {};

    const energyLevel = cargo.map(({ energyLevelCheckResult }) => {
        const {
            energyLevelStatus,
            breachTimestamp,
            remainingEnergyLevel,
        } = energyLevelCheckResult || {};

        return {
            energyLevelStatus,
            breachTimestamp,
            remainingEnergyLevel,
        };
    });

    const {
        temperatureRange = '',
        leaseStart = null,
        leaseEnd = null,
        leaseEndExpected = null,
        shipmentStart = null,
        shipmentEnd = null,
        orderSteps = [],
        handoverPoint = '',
        collectionDropoffPoint = '',
        skyMindId,
        mawbNumber,
        hawbNumber,
    } = skyMindInfo || {};

    const approvalStatuses = getApprovalStatuses(cargo);
    const isPending = checkIsPending(approvalStatuses);
    const polylines = getCompositePolyline(orderSteps, isPending);

    return {
        shipmentNumber,
        energyLevel,
        lane: {
            id,
            laneNumber,
            fourthPartyLogistics,
            skyMindId,
            temperatureRange,
            originAirport,
            destinationAirport,
            loadingLocation,
            unloadingLocation,
        },
        summary: {
            customerReference,
            originAirportCode: originAirport ? originAirport.code : '',
            originAirportName: originAirport ? originAirport.name : '',
            destinationAirportCode: destinationAirport ? destinationAirport.code : '',
            destinationAirportName: destinationAirport ? destinationAirport.name : '',
            packagingSquareIcons: getPackagingIcons(approvalStatuses),
            packagingTypesCount: getPackagingTypesCount(cargo),
            laneNumber,
            status,
            mawbNumber,
            hawbNumber,
            temperatureRange,
            fourPlName,
            fourthPartyLogistics,
            pharmaCompanyName: pharmaCompany.name,
            isWarning: checkIsWarning(approvalStatuses),
        },
        routeInfo: {
            polylines,
            marker: getCurrentPosition(orderSteps),
            originLabel: (loadingLocation || unloadingLocation) ? 'Loading Location' : 'Origin Location',
            originValue: loadingLocation || handoverPoint,
            destinationLabel: (loadingLocation || unloadingLocation) ? 'Unloading Location' : 'Destination Location',
            destinationValue: unloadingLocation || collectionDropoffPoint,
        },
        packagings: getPackagings(cargo),
        shipmentSteps: orderSteps.map(orderStep => {
            const { stepStatus, stepType } = orderStep;

            return {
                shipmentStepInfo: getShipmentStepInfo(orderStep),
                stepStatus,
                stepType,
            };
        }),
        startGeolocation: orderSteps.length > 0
            ? getGeolocatioFromOrderStep(orderSteps[0], 'start')
            : null,
        endGeolocation: orderSteps.length > 0
            ? getGeolocatioFromOrderStep(orderSteps[orderSteps.length - 1], 'end')
            : null,
        shipmentSensorDataTimeRange: getShipmentTimeRange(
            shipmentStart,
            shipmentEnd,
            leaseStart,
            leaseEnd,
            leaseEndExpected,
        ),
        leaseEndExpected,
        leaseStart,
        leaseEnd,
        shipmentStart,
        shipmentEnd,
        skyMindId,
        skyMindInfo,
    };
};
