import React, {
    CSSProperties,
    ReactNode,
    useEffect,
    useRef,
} from 'react';
import {
    GoogleMap,
    useLoadScript,
} from '@react-google-maps/api';
import {
    LIBRARIES,
    MAP_CONFIG,
    // ZOOM_DEFAULTS,
} from 'shared-components/constants';

const {
    REACT_APP_GOOGLE_MAP_KEY: googleMapKey,
} = process.env;

type Props = {
    children?: ReactNode,
    gMap: any,
    setGMap: (gMap: any) => void,
    setBounds: (bounds: any[]) => void,
    setZoom?: (zoom: number) => void,
    onClick?: () => void,
    onContainerRefLoad?: (ref: any) => void,
    disableDrag?: boolean,
    disableDefaultUI?: boolean,
    mapContainerStyle?: CSSProperties,
}

const BaseMap = ({
    children = null,
    gMap = null,
    setGMap,
    setBounds,
    setZoom = null,
    onClick = null,
    onContainerRefLoad = () => {},
    disableDrag = false,
    disableDefaultUI = false,
    mapContainerStyle = MAP_CONFIG.containerStyle,
} : Props) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: googleMapKey,
        libraries: LIBRARIES,
        mapIds: ['c5af2a2e4049bce5'],
    });
    const mapRef = useRef(null);

    useEffect(() => {
        onContainerRefLoad(mapRef);
    }, [mapRef]);

    const handleLoad = (map) => setGMap(map);

    const handleBoundsChanged = () => {
        if (gMap) {
            const newBounds = gMap.getBounds();

            setBounds([
                newBounds.getSouthWest().lng(),
                newBounds.getSouthWest().lat(),
                newBounds.getNorthEast().lng(),
                newBounds.getNorthEast().lat(),
            ]);
        }
    };

    const handleZoomChanged = () => {
        if (gMap && setZoom) {
            setZoom(gMap.getZoom());
        }
    };

    return (
        <>
            {
                isLoaded
                    ? (
                        <GoogleMap
                            key="GoogleMap"
                            onLoad={handleLoad}
                            mapContainerStyle={mapContainerStyle}
                            zoom={MAP_CONFIG.zoom}
                            center={MAP_CONFIG.center}
                            onBoundsChanged={handleBoundsChanged}
                            onZoomChanged={handleZoomChanged}
                            onClick={onClick}
                            options={{ mapId: 'c5af2a2e4049bce5', draggable: !disableDrag, disableDefaultUI }}
                        >
                            <div
                                ref={mapRef} style={{
                                    height: '100%',
                                    width: '100%',
                                }}
                            >
                                { children }
                            </div>
                        </GoogleMap>
                    )
                    : null
            }
        </>
    );
};

export default BaseMap;
