import React, { useCallback, useEffect, useState } from 'react';
import { Lane } from 'shared-components/dataTypes';
import { CircularProgress } from '@mui/material';
import useStatusState from 'hooks/useStatusState';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import useStatusStateProcessOptions from 'hooks/useStatusStateProcessOptions';
import { getErrorReponseMessage } from 'hooks/useSecureBackendEndpoints/lib';
import useGetPackagingTypes from 'hooks/useGetPackagingTypes';
import ShipmentInformationCard from './ShipmentInformationCard';
import RequestResultCard from './RequestResultCard';
import { useStyles } from './LaneRowExpansion.style';
import ContainerCard from './ContainerCard';

type Props = {
    laneData: Lane
}

const LaneRowExpansion = ({ laneData }: Props) => {
    const classes = useStyles();
    const { Get: getLaneById } = useSecureBackendEndpoints('lane-management/lane').requests;
    const packagingTypes = useGetPackagingTypes();
    const {
        Create: postChanges,
    } = useSecureBackendEndpoints('shipment/skycell-shipment').requests;
    const {
        setErrorStatus,
        setProgressStatus,
        setSuccessStatus,
    } = useStatusStateProcessOptions();

    const { t } = useCustomTranslation();
    const [laneResponse, setLaneResponse] = useState<Lane>(null);
    const [quantities, setQuantities] = useState({});
    const [completed, setCompleted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const { setStatusState } = useStatusState();

    const sendRequest = useCallback(({ customerReference, orderComment, requestedPoint }) => {
        const hasQuantities = laneResponse
            .laneGroup
            .allowedContainerTypes
            .map(allowedType => quantities[allowedType.type])
            .some(it => it);

        if (!hasQuantities || !requestedPoint || !customerReference) {
            setStatusState({
                message: t('TRACK_AND_TRACE.ADD_SHIPMENT.PLEASE_FILL_ALL_REQUIRED_DATA'),
                color: 'warning',
                relatedStatuses: [],
            });
        } else {
            setLoading(true);

            (async () => {
                const requestedDate = requestedPoint.clone()?.utcOffset(0)?.format('YYYY-MM-DDTHH:mm');

                const requestObject = {
                    laneId: laneResponse?.skyMindId,
                    customerReference,
                    orderComment,
                    [['DELIVERY_ROAD', 'DELIVERY_AIR'].includes(laneResponse?.skyMindInfo?.deliveryServiceType)
                        ? 'requestedDelivery' : 'requestedPickup']: requestedDate,
                    requiredPackagings: laneResponse.laneGroup.allowedContainerTypes.map(allowedType => ({
                        quantity: quantities[allowedType.type] || 0,
                        packagingType: allowedType.type,
                    })),
                };

                let time: number = null;

                try {
                    time = setProgressStatus('Request sending. This might take a bit.');

                    await postChanges(requestObject);
                    setSuccessStatus('Success request for skyship', [time]);
                    setCompleted(true);
                } catch (error) {
                    setErrorStatus(getErrorReponseMessage(error,
                        t('TRACK_AND_TRACE.ADD_SHIPMENT.ERROR_HAPPENED')), [time]);
                    setHasError(true);
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [quantities, laneResponse, loading, setLoading]);

    useEffect(() => {
        (async () => {
            const laneResponse = await getLaneById(laneData.id).then(r => r?.data);

            const allowedContainerTypes = packagingTypes
                ?.filter(
                    it => (laneResponse
                        ?.packagingTypes
                        ?.map(pt => pt?.code))
                        .includes(it.type),
                );

            setLaneResponse({
                ...laneResponse,
                laneGroup: {
                    ...laneResponse.laneGroup,
                    allowedContainerTypes,
                },
            });
        })();
    }, [packagingTypes]);

    if (!laneResponse) {
        return (
            <div className={[classes.expansion, classes.expansionLoading].join(' ')}>
                <CircularProgress />
            </div>
        );
    }

    if (completed) {
        return (
            <div className={classes.expansion}>
                <RequestResultCard />
            </div>
        );
    }
    if (hasError) {
        return (
            <div className={classes.expansion}>
                <RequestResultCard
                    error={hasError} onTryAgain={() => {
                        setHasError(false);
                        setQuantities({ });
                    }}
                />
            </div>
        );
    }

    return (
        <div className={classes.expansion}>
            <div className={[
                classes.expansionLayout,
                loading ? classes.expansionBlur : '',
            ].join(' ')}
            >
                <div className={classes.containers}>
                    {
                        laneResponse?.laneGroup?.allowedContainerTypes.map(packagingType => (
                            <ContainerCard
                                key={`laneId_${laneResponse.id}_${packagingType.type}`}
                                containerName={`${t(packagingType.provider)}\
                                 ${t(`PACKAGING_CODE_LABEL.${packagingType.type}`)}`}
                                containerPicture={packagingType?.packagingPictureUrl}
                                quantity={quantities[packagingType.type] || 0}
                                onChange={quantity => setQuantities(prev => (
                                    { ...prev, [packagingType.type]: quantity }
                                ))}
                            />
                        ))
                    }
                </div>
                <ShipmentInformationCard
                    deliveryServiceType={laneResponse?.skyMindInfo?.deliveryServiceType}
                    handoverPoint={laneResponse?.skyMindInfo?.handoverPoint}
                    onSubmit={sendRequest}
                />
            </div>
        </div>
    );
};

export default LaneRowExpansion;
