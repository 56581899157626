import React from 'react';
import ContentLoader from 'react-content-loader';

type Props = {
    speed?: number,
    width?: string | number,
    height?: string | number,
}

const RectangleContentLoader = ({
    speed = 1,
    width = '100%',
    height = '100%',
} : Props) => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            alignItems: 'center',
        }}
        >
            <ContentLoader
                speed={speed}
                width={width}
                height={height}
            >
                <rect
                    rx="0"
                    ry="0"
                    width="100%"
                    height="100%"
                />
            </ContentLoader>
        </div>
    );
};

export default RectangleContentLoader;
