import React, {
    useEffect, useMemo,
    useState,
} from 'react';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import useGetTranslationGroup from 'hooks/useGetTranslationGroup';
import { RequestStatus } from 'dataTypes/common';
import { PageWithFilter } from 'Layout';
import Table, { useTableSideFilter } from 'shared-components/Table';

import useCustomTranslation from 'hooks/useCustomTranslation';
import useHasAccess, { userRoles } from 'hooks/useHasAccess';
import tableColumns from './tableColumns';

const Users = () => {
    const { t } = useCustomTranslation();
    const { userInfo } = useCurrentUserContext();
    const userRoleLabels = useGetTranslationGroup('USER_POLICY');
    const { companyId } = userInfo;
    const hasRoles = useHasAccess();
    const { GetAll: getUsers } = useSecureBackendEndpoints(
        hasRoles(userRoles.SKYCELL_ADMIN) ? 'admin/users'
            : 'users',
    ).requests;

    const [users, setUsers] = useState([]);
    const [intermediateFilteredUsers, setIntermediateFilteredUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [status, setStatus] = useState<RequestStatus>('INIT');
    const [searchString, setSearchString] = useState('');

    useEffect(() => {
        if (Object.keys(userRoleLabels).length && companyId && status !== 'PENDING') {
            (async () => {
                try {
                    setStatus('PENDING');
                    const users = (await getUsers()).data?.resultList;

                    const processedUsersInfo = users.map(user => {
                        const {
                            email = '',
                            fullName = null,
                            id = null,
                            userRoles = [],
                        } = user || {};
                        const { firstName = '', lastName = '' } = fullName || {};

                        return {
                            id,
                            userName: `${firstName || ''} ${lastName || ''}`,
                            email,
                            role: userRoles.map(role => userRoleLabels[role] || role),
                        };
                    });

                    setUsers(processedUsersInfo);
                    setStatus('SUCCESS');
                } catch (error) {
                    setUsers([]);
                    setStatus('FAILURE');
                }
            })();
        }
    }, [companyId, userRoleLabels]);

    useEffect(() => {
        if (users.length === 0 || searchString.length === 0) {
            setIntermediateFilteredUsers(users);
        } else {
            const searchStringLowerCase = searchString.toLowerCase();
            const data = users.filter(user => {
                const { userName = '', email = '' } = user;

                return userName.toLowerCase().includes(searchStringLowerCase)
                    || email.toLowerCase().includes(searchStringLowerCase);
            });

            setIntermediateFilteredUsers(data);
        }
    }, [users, searchString]);

    const columns = useMemo(() => tableColumns(t), []);
    const {
        component: FilterComponent,
    } = useTableSideFilter({
        columns,
        data: intermediateFilteredUsers,
        setFilteredData: setFilteredUsers,
        externalTextFilterString: searchString,
        setExternalTextFilterString: setSearchString,
        externalTextFilterPlaceholder: t('COMMON.SEARCH'),
    });

    return (
        <PageWithFilter>
            {FilterComponent}
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: '10px' }}>
                <Table
                    columns={columns}
                    data={filteredUsers}
                    tableMaxHeight="100%"
                    rowLinkTemplate="/my-company/users/:id"
                    title={t('MENU_ITEMS.USERS')}
                />
            </div>
        </PageWithFilter>
    );
};

export default Users;
