/* eslint-disable camelcase */
import React, {
    KeyboardEvent,
    useEffect,
    useMemo,
    useState,
} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { highlight } from 'utils/stringTool';

import useCustomTranslation from 'hooks/useCustomTranslation';
import GeolocationInfo from './components/GeolocationInfo';
import useStyles from './GoogleAddress.style';

type Country = {
    code: string,
    name: string,
}

type AddressOption = {
    description: string,
    id: null,
    place_id: string,
    structured_formatting: any,
}

type Geolocation = {
    latitude?: number,
    longitude?: number,
}

const getLocationInfoMessage = (addressLine1: string = null, geolocation: Geolocation, t = w => w) => {
    if (!addressLine1) {
        return t('MY_COMPANY.YOU_CAN_SELECT_ON_THE_MAP');
    } else if (!geolocation.latitude || !geolocation.longitude) {
        return t('MY_COMPANY.WE_COULD_NOT_GEOLOCATE_ADDRESS');
    }
    return t('MY_COMPANY.ADDRESS_HAS_A_GEOLOCATION');
};

type Props = {
    addressOptions: AddressOption[],
    addressLine1FormValue: AddressOption | any,
    addressLine1: string,
    addressLine2: string,
    addressLine3: string,
    countries: Country[],
    country?: string,
    countryCode: string,
    city: string,
    geolocation?: Geolocation,
    isGoogleServiceReady: boolean,
    setAddressLine1FormValue: (addressLine1FormValue: AddressOption) => void,
    setAddressLine1: (value: string) => void,
    setAddressLine2: (value: string) => void,
    setAddressLine3: (value: string) => void,
    setAutocompleteGoogle: (value: string) => void,
    setCity: (value: string) => void,
    setCountry: (value: string) => void,
    setCountryCode: (value: string) => void,
    setGeolocation: (geolocation: Geolocation) => void,
    setPlaceId: (value: string) => void,
    setValue: (value: string) => void,
    setZipCode: (value: string) => void,
    zipCode: string,
}

const GoogleAddress = ({
    addressLine1FormValue,
    addressLine1,
    addressLine2,
    addressLine3,
    addressOptions = [],
    city,
    countries = [],
    // eslint-disable-next-line no-unused-vars
    country,
    countryCode,
    zipCode,
    geolocation = { latitude: null, longitude: null },
    isGoogleServiceReady,
    setAddressLine1FormValue,
    setAddressLine1,
    setAddressLine2,
    setAddressLine3,
    setAutocompleteGoogle,
    setCity,
    // eslint-disable-next-line no-unused-vars
    setCountry,
    setCountryCode,
    setGeolocation,
    setPlaceId,
    setValue,
    setZipCode,
} : Props) => {
    const classes = useStyles();
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [countryFieldSample, setCountryFieldSample] = useState('');
    const { t } = useCustomTranslation();
    const handleCountryInputChange = (event) => {
        setCountryFieldSample(event.target.value.toLowerCase() || '');
    };

    const handleAutocompleteGoogle = (event) => {
        setAddressLine1(event.target.value);
        setAutocompleteGoogle(event.target.value);
        if (isGoogleServiceReady) {
            setValue(event.target.value);
        }
    };

    const handlePlaceId = (event, selected) => {
        setPlaceId(selected?.place_id || '');
        setAddressLine1(selected?.structured_formatting?.main_text || selected?.description || '');
    };

    const handleAddressLine2 = (event) => {
        setAddressLine2(event.target.value);
    };

    const handleAddressLine3 = (event) => {
        setAddressLine3(event.target.value);
    };

    const handleCity = (event) => {
        setCity(event.target.value);
    };

    const handleZipCode = (event) => {
        setZipCode(event.target.value);
    };

    const handleCountryAutocomplete = (event, selected) => {
        setSelectedCountry(selected);
    };

    useEffect(() => {
        if (countryCode && !selectedCountry) {
            setSelectedCountry({ code: countryCode, name: country });
        }
    }, [countryCode]);

    useEffect(() => {
        setCountryCode(selectedCountry?.code || '');
        setCountry(selectedCountry?.name || '');
    }, [selectedCountry]);

    const countriesData = useMemo(() => {
        // const data = [...countries, { code: '', name: '' }];

        return countryFieldSample
            ? countries.filter(country => country.name.toLowerCase().includes(countryFieldSample))
            : countries;
    }, [countries, countryFieldSample]);

    const checkCountryValue = (option, value) => option.code === value?.code || countryCode === value?.code;
    const getOptionLabel = (option) => option.name;

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Tab') {
            if (addressOptions.length > 0) {
                const [firstItem] = addressOptions;

                const {
                    description = '',
                    id = null,
                    place_id = '',
                    structured_formatting = {},
                } = firstItem;

                const option = {
                    description,
                    id,
                    place_id,
                    structured_formatting,
                };

                setAddressLine1FormValue(option);
                setPlaceId(option?.place_id || '');
                setAddressLine1(option?.structured_formatting?.main_text || option?.description || '');
            }
        }
    };

    return (
        <div>
            <div className={classes.contentData}>
                <div>
                    <div className={classes.fieldTitle}>{t('COMMON.ADDRESS_LINE_1')}</div>
                    <Autocomplete
                        onChange={handlePlaceId}
                        onKeyDown={handleKeyDown}
                        classes={{
                            listbox: classes.listBox,
                            inputRoot: classes.textFieldText,
                        }}
                        freeSolo
                        size="small"
                        // className={classes.textField}
                        className={classes.mandatory}
                        // id="addressLine1"
                        options={addressOptions}
                        getOptionLabel={(option) => option.description}
                        isOptionEqualToValue={(option, value) => option.description === value.description}
                        filterSelectedOptions
                        value={addressLine1FormValue}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                onChange={handleAutocompleteGoogle}
                                variant="standard"
                                size="small"
                            />
                        )}
                    />
                    <TextField
                        className={classes.textField}
                        fullWidth
                        label={t('COMMON.ADDRESS_LINE_2')}
                        value={addressLine2 || ''}
                        onChange={handleAddressLine2}
                        variant="standard"
                        size="small"
                        inputProps={{
                            className: classes.textFieldText,
                        }}
                    />
                    <TextField
                        className={classes.textField}
                        fullWidth
                        label={t('COMMON.ADDRESS_LINE_3')}
                        value={addressLine3 || ''}
                        onChange={handleAddressLine3}
                        variant="standard"
                        size="small"
                        inputProps={{
                            className: classes.textFieldText,
                        }}
                    />
                </div>
                <div>
                    <TextField
                        className={classes.textField}
                        fullWidth
                        label={t('COMMON.CITY')}
                        value={city || ''}
                        onChange={handleCity}
                        variant="standard"
                        size="small"
                        inputProps={{
                            className: classes.textFieldText,
                        }}
                    />
                    <TextField
                        className={classes.textField}
                        fullWidth
                        label={t('COMMON.ZIP_CODE')}
                        value={zipCode || ''}
                        onChange={handleZipCode}
                        variant="standard"
                        size="small"
                        inputProps={{
                            className: classes.textFieldText,
                        }}
                    />
                    <div className={classes.fieldTitle}>{t('COMMON.COUNTRY')}</div>
                    <Autocomplete
                        onChange={handleCountryAutocomplete}
                        id="countries"
                        options={countriesData}
                        classes={{
                            listbox: classes.listBox,
                            inputRoot: classes.textFieldText,
                        }}
                        autoSelect
                        className={classes.mandatory}
                        size="small"
                        // filterOptions={filterOptions}
                        // defaultValue={countries.find((country) => country.code === countryCode)}
                        getOptionLabel={getOptionLabel}
                        isOptionEqualToValue={checkCountryValue}
                        value={selectedCountry}
                        // value={{
                        //     name: country,
                        //     code: countryCode,
                        // }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                onChange={handleCountryInputChange}
                                variant="standard"
                                size="small"
                                value={countryFieldSample}
                            />
                        )}
                        renderOption={(props, option) => (
                            <li {...props}>{highlight(option.name, countryFieldSample)}</li>
                        )}
                        clearOnEscape
                        noOptionsText={t('COMMON.WRITE_TO_GET_OPTIONS')}
                        popupIcon={null}
                    />
                </div>
            </div>
            <GeolocationInfo
                geolocation={geolocation}
                message={getLocationInfoMessage(addressLine1, geolocation, t)}
                setGeolocation={setGeolocation}
            />
        </div>
    );
};

export default GoogleAddress;
