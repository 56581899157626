import icons from 'shared-components/icons';
import { UserInfo, ExtendedProfile } from './dataTypes';

export const initialExtendedProfile: ExtendedProfile = {
    assignedRoles: [],
    attributes: {
        companyId: [],
    },
    username: '',
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    emailVerified: null,
};

export const initialUserInfo: UserInfo = {
    assignedRoles: [],
    avatar: '',
    companyId: null,
    name: '',
    email: '',
};

export const getAvatar = (gender: string) => {
    const maxNumberLimit = icons.avatars[gender]?.length || 0;
    const index = Math.floor(Math.random() * maxNumberLimit);

    return maxNumberLimit ? icons.avatars[gender][index] : '';
};

export const getUserInfo = (extendedProfile: ExtendedProfile, avatar = ''): UserInfo => {
    const {
        assignedRoles = [],
        attributes,
        email,
        name,
    } = extendedProfile;
    const { companyId: compIdArr = [] } = attributes;
    const [companyId = null] = compIdArr;

    return {
        avatar,
        assignedRoles,
        companyId,
        email,
        name,
    };
};
