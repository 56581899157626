import React from 'react';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import LastConnect from 'shared-components/LastConnect';

const tableColumns: (t) => ColumnsType[] = (t = w => w) => [
    {
        Header: t('TRACK_AND_TRACE.ASSET'),
        accessor: 'assetNumber',
        dataType: 'highlightedText',
    },
    {
        Header: t('TRACK_AND_TRACE.ASSET_TYPE'),
        accessor: ({ assetTypeCode }) => t(`ASSET_TYPE_LABEL.${assetTypeCode}`),
        dataType: 'text',
    },
    {
        Header: t('LANE_MANAGEMENT.ADDRESS.AIRPORT'),
        accessor: 'iataCode',
        dataType: 'text',
    },
    {
        Header: 'Airport Name', // required to add to PoE
        accessor: 'locationName',
        dataType: 'text',
    },
    {
        Header: t('TRACK_AND_TRACE.AREA'),
        accessor: 'locationRemark',
        dataType: 'text',
    },
    {
        Header: t('COMMON.TEMPERATURE'),
        accessor: (row) => (row.temperature ? `${Number(row?.temperature?.toFixed(1))}°C` : ''),
        dataType: 'text',
    },
    {
        Header: t('TRACK_AND_TRACE.LAST_UPDATED'),
        accessor: (row) => (!row.lastMeasuredTimestamp ? ' '
            : <LastConnect timestamp={row.lastMeasuredTimestamp} />),
        dataType: 'custom',
        sortType: (rowA, rowB) => rowB.original.lastMeasuredTimestamp - rowA.original.lastMeasuredTimestamp,
    },
    {
        Header: t('COMMON.LOGGER_NUMBER'),
        accessor: 'loggerNumber',
        dataType: 'highlightedText',
    },
];

export default tableColumns;
