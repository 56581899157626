/* eslint-disable max-len */
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    overrides: {
        MuiButton: {
            root: {
                borderRadius: '0!important',
                color: 'white!important',
            },
            disabled: {
                borderRadius: '0!important',
                color: 'white!important',
            },
        },
    },
    row: {
        display: 'flex',
    },
    panelsWrapper: {
        display: 'grid',
        gridTemplateAreas: `
            "card              card              card             card             card             card             card             card"
            "summary           summary           summary          summary          summary          summary          summary          summary"
            "orderSteps        orderSteps        packagingDetails packagingDetails packagingDetails packagingDetails packagingDetails packagingDetails"
        `,
        gridAutoRows: 'max-content',
        gridTemplateColumns: 'repeat(8,1fr)',
        padding: '12px',
        gridGap: '12px',
        [theme.breakpoints.down('md')]: {
            gridTemplateAreas: `
            "card             card             card"
            "summary          summary          summary"
            "summary          summary          summary" 
            "orderSteps       orderSteps       orderSteps"
            "packagingDetails packagingDetails packagingDetails"
        `,
            gridTemplateColumns: 'repeat(3,1fr)',
            padding: '10px',

        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    title: {
        padding: '16px',
    },
    panelsWrapperWithoutApprovalCard: {
        display: 'grid',
        gridTemplateAreas: `
            "card              card              card             card             card             card"
            "summary           summary           summary          summary          summary          summary"
            "orderSteps        orderSteps        packagingDetails packagingDetails packagingDetails packagingDetails"
        `,
        gridTemplateColumns: 'repeat(6,1fr)',
        gridGap: '12px',
        [theme.breakpoints.down('md')]: {
            gridTemplateAreas: `
            "card             card             card"
            "summary          summary          summary"
            "route            route            collectionPoint" 
            "orderSteps       orderSteps       orderSteps"
            "packagingDetails packagingDetails packagingDetails"
        `,
            gridTemplateColumns: 'repeat(3,1fr)',
            padding: '10px',

        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    maximizedPane: {
        gridTemplateAreas: `
            "card              card"
            "summary           summary"
            "packagingDetails  packagingDetails"
        `,
        gridTemplateColumns: '1fr 1fr',
        gridAutoRows: 'min-content min-content 1fr',
        flex: 1,
        '& $root': {
            height: '100%',
        },
    },
    panelElement: {
        minWidth: 'unset!important',
        maxWidth: 'unset!important',
        margin: '0',
    },
    icon: {
        display: 'flex',
        width: '22px',
        height: '22px',
        margin: '11px',
    },
    iconEnd: {
        display: 'flex',
        width: '22px',
        height: '22px',
        margin: '11px',
        flexGrow: 1,
    },
    text: {
        display: 'flex',
        font: 'normal normal normal 14px/17px Roboto',
        color: theme.palette.secondary[900],
        alignItems: 'center',
        letterSpacing: '0.49px',
        flexGrow: 140000,
    },
    card: {
        display: 'flex',
        gridArea: 'card',
        width: '100%',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
        borderRadius: '6px',
        color: theme.palette.secondary[600],
        textAlign: 'left',
        height: '44px',
        '& div': {
            // @ts-ignore
            color: theme.palette.common.contrastGray600,
        },
    },
    cardNotStarted: {
        display: 'flex',
        gridArea: 'card',
        width: '100%',
        font: 'normal normal normal 16px/19px Roboto',
        letterSpacing: '0.49px',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
        borderRadius: '6px',
        color: theme.palette.secondary[600],
        textAlign: 'left',
        height: '44px',
        '& div': {
            // @ts-ignore
            color: theme.palette.common.contrastGray600,
        },
        '& .MuiCardContent-root:last-child': {
            display: 'contents',
        },
    },
    summary: {
        gridArea: 'summary',
    },
    handoverPoint: {
        gridArea: 'handoverPoint',
        '& div': {
            // @ts-ignore
            color: theme.palette.common.contrastGray600,
        },
    },
    collectionPoint: {
        gridArea: 'collectionPoint',
        '& div': {
            // @ts-ignore
            color: theme.palette.common.contrastGray600,
        },
    },
    route: {
        gridArea: 'route',
        '& div': {
            // @ts-ignore
            color: theme.palette.common.contrastGray600,
        },
    },
    approval: {
        gridArea: 'approval',
        '& div': {
            // @ts-ignore
            color: theme.palette.common.contrastGray600,
        },

    },
    packagingDetailsWrapper: {
        // marginTop: '-12px',
        gridArea: 'packagingDetails',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        // '& div': {
        //     // @ts-ignore
        //     color: theme.palette.common.contrastGray600,
        // },
    },
    packagingDetails: {
        // marginBottom: '12px!important',
        // '& div': {
        //     // @ts-ignore
        //     color: theme.palette.common.contrastGray600,
        // },

    },
    orderSteps: {
        gridArea: 'orderSteps',
    },
    downloadReportCard: {
        marginBottom: '12px',
        height: 'auto',
        '& .MuiCardContent-root': {
            width: '100%',
        },
    },
    downloadButtons: {
        display: 'flex',
        gap: '12px',
        color: 'white!important',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '16px',
        borderRadius: '0px!important',
        '& button': {
            padding: '8px 36px',
            color: 'white!important',
            borderRadius: '0px!important',
        },
    },
}));

export default useStyles;
