import React from 'react';
import {
    TIME_IN_MS,
} from 'shared-components/constants';
import useCustomTranslation from 'hooks/useCustomTranslation';
import moment from 'moment';
import useStyles from './LastConnect.style';

type Props = {
    fontSize?: 'small' | 'medium' | 'large',
    timestamp?: number,
    various?: boolean,
    fullText?: boolean,
}

const LastConnect = ({
    fontSize = 'large',
    timestamp = null,
    various = false,
    fullText = false,
}: Props) => {
    const classes = useStyles();
    const { t } = useCustomTranslation();

    const now = moment().utcOffset(0, false).valueOf();

    if (!timestamp
        || Number.isNaN(timestamp)
        || typeof timestamp !== 'number'
        || timestamp < 0
    ) {
        return null;
    }

    if (various) {
        return (
            <div className={`${classes.background} ${classes.backgroundBlue}`}>
                <span className={`${classes.statusDot} ${classes.statusDotBackgroundBlue}`} />
                <div className={classes[fontSize]}>{t('COMMON.VARIOUS')}</div>
            </div>
        );
    }

    const timePassed = Math.abs(moment(now).valueOf() - timestamp);

    if (timePassed <= TIME_IN_MS.minute * 50) {
        const minutes = Math.round(timePassed / TIME_IN_MS.minute / 10) * 10;
        const mPlural = minutes !== 1;

        return (
            <div className={`${classes.background} ${classes.backgroundGreen}`}>
                <span className={`${classes.statusDot} ${classes.statusDotBackgroundGreen}`} />
                <div className={classes[fontSize]}>
                    {
                        fullText
                            ? `${t('SENSOR_DATA.MEASURED')} ${minutes} ${t(mPlural
                                ? 'SENSOR_DATA.MINUTES_AGO' : 'SENSOR_DATA.MINUTE_AGO')}`
                            : `${minutes}${t('TIME.M')}`
                    }
                </div>
            </div>
        );
    }

    if (timePassed < TIME_IN_MS.day) {
        const hours = Math.round(timePassed / TIME_IN_MS.hour);
        const hPlural = hours !== 1;

        return (
            <div className={`${classes.background} ${classes.backgroundYellow}`}>
                <span className={`${classes.statusDot} ${classes.statusDotBackgroundYellow}`} />
                <div className={classes[fontSize]}>
                    {
                        fullText
                            ? `${t('SENSOR_DATA.MEASURED')} ${hours} ${t(hPlural
                                ? 'SENSOR_DATA.HOURS_AGO' : 'SENSOR_DATA.HOUR_AGO')}`
                            : `${hours}${t('TIME.H')}`
                    }
                </div>
            </div>
        );
    }

    const days = Math.round(timePassed / TIME_IN_MS.day);
    const dPlural = days !== 1;

    return (
        <div className={`${classes.background} ${classes.backgroundRed}`}>
            <span className={`${classes.statusDot} ${classes.statusDotBackgroundRed}`} />
            <div className={classes[fontSize]}>
                {
                    fullText
                        ? `${t('SENSOR_DATA.MEASURED')} ${days} ${t(dPlural
                            ? 'SENSOR_DATA.DAYS_AGO' : 'SENSOR_DATA.DAY_AGO')}`
                        : `${days}d`
                }
            </div>
        </div>
    );
};

export default LastConnect;
