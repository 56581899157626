import React, {
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material';
import skyCellTheme from 'themes/skycellThemeInterface';
import { StyledEngineProvider } from '@mui/material/styles';
import CustomThemeContext from '../Contexts/CustomThemeContext';
import skyCellDark from '../themes/skyCellDark';

type Props = {
    children: ReactElement,
}

// @ts-ignore
const defaultTheme = createTheme(skyCellTheme);
// @ts-ignore
const darkTheme = createTheme(skyCellDark);

const ThemeProvider = ({ children }: Props) => {
    const [themeState, setTheme] = useState('default');

    const themes = useMemo(() => ({
        default: defaultTheme,
        dark: darkTheme,
    }), []);

    const themeChange = useCallback(() => setTheme(prev => (prev === 'default' ? 'dark' : 'default')), []);

    useEffect(() => {
        // @ts-ignore
        document.switchTheme = themeChange;
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <CustomThemeContext.Provider
                value={{
                    setCustomTheme: themeChange,
                    theme: themeState,
                }}
            >
                <MuiThemeProvider
                    theme={themes[themeState]}
                >
                    {children}
                </MuiThemeProvider>
            </CustomThemeContext.Provider>
        </StyledEngineProvider>
    );
};

export default ThemeProvider;
