import React, { useEffect } from 'react';
import {
    BrowserRouter,
} from 'react-router-dom';
// @ts-ignore
// import Auth from '@skycell-ag/auth';
// import AuthSkyMind from 'auth';
import Auth from 'Contexts/AuthContext';
import {
    StylesProvider,
    createGenerateClassName,
} from '@mui/styles';
import { useLoadScript } from '@react-google-maps/api';
import { LIBRARIES } from 'shared-components/constants';
import { StatusInfoProvider } from 'Contexts/StatusInfoContext';
import CacheContextProvider from 'Contexts/CacheContext';
import CurrentUserProvider from 'Contexts/CurrentUserContext';
import Layout from 'Layout';
import Root from 'Root';
import ThemeProvider from 'ThemeProvider';
import TagManager from 'react-gtm-module';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { QueryClient, QueryClientProvider } from 'react-query';
import BreadcrumbsProvider from './Contexts/BreadcrumbsContext/BreadcrumbsProvider';
import QuickHelpContextProvider from './Contexts/QuickHelpContext';

const tagManagerArgs = {
    gtmId: 'G-8X506GY7VY',
};

const isUnderMaintenance = false;

TagManager.initialize(tagManagerArgs);

const {
    REACT_APP_GOOGLE_MAP_KEY: googleMapKey,
} = process.env;

const generateClassName = createGenerateClassName({
    productionPrefix: 'atlantis',
    disableGlobal: true,
});
const queryClient = new QueryClient();

function App() {
    useLoadScript({
        googleMapsApiKey: googleMapKey,
        libraries: LIBRARIES,
    });

    const { i18n } = useCustomTranslation();

    useEffect(() => {
        // @ts-ignore
        document.changeLang = i18n.changeLanguage;
    }, []);

    return isUnderMaintenance
        ? (
            <>
                <head>
                    <title>Site Maintenance</title>
                </head>
                <body style={{
                    textAlign: 'center',
                    padding: '150px',
                    font: '20px Helvetica, sans-serif',
                    color: '#333' }}
                >
                    <article style={{
                        display: 'block',
                        textAlign: 'left',
                        width: '650px',
                        margin: '0 auto' }}
                    >
                        <h1 style={{ fontSize: '50px' }}>We&rsquo;ll be back soon!</h1>
                        <div>
                            <p>
                                Sorry for the inconvenience but we&rsquo;re performing some
                                maintenance at the moment. We&rsquo;ll be back online shortly!
                            </p>
                            <p>&mdash; The SkyMind Team</p>
                        </div>
                    </article>
                </body>
            </>
        )
        : (
            <StylesProvider
                generateClassName={generateClassName}
            >
                <ThemeProvider>
                    <Auth>
                        <QueryClientProvider client={queryClient}>
                            <BrowserRouter>
                                <QuickHelpContextProvider>
                                    <BreadcrumbsProvider>
                                        <StatusInfoProvider>
                                            <CurrentUserProvider>
                                                <CacheContextProvider>
                                                    <Layout>
                                                        <Root />
                                                    </Layout>
                                                </CacheContextProvider>
                                            </CurrentUserProvider>
                                        </StatusInfoProvider>
                                    </BreadcrumbsProvider>
                                </QuickHelpContextProvider>
                            </BrowserRouter>
                        </QueryClientProvider>
                    </Auth>
                </ThemeProvider>

            </StylesProvider>
        );
}

export default App;
