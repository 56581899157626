import { useState, useEffect } from 'react';

const useElementSize = (ref) => {
    const [size, setSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
        let animationFrameId = null;

        const updateSize = (width, height) => {
            cancelAnimationFrame(animationFrameId);
            animationFrameId = requestAnimationFrame(() => {
                setSize({ width, height });
            });
        };

        if (ref.current) {
            const resizeObserver = new ResizeObserver((entries) => {
                if (entries.length === 0 || !entries[0].target) return;
                const { width, height } = entries[0].contentRect;

                // Only update size if it has changed
                if (width !== size.width || height !== size.height) {
                    updateSize(width, height);
                }
            });

            resizeObserver.observe(ref.current);

            return () => {
                resizeObserver.disconnect();
                cancelAnimationFrame(animationFrameId);
            };
        }
    }, [ref, size.width, size.height]);

    return size;
};

export default useElementSize;
