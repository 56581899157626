import {
    jsPDF as JSPDF,
} from 'jspdf';
import moment from 'moment';
import { GenericCargo } from 'dataTypes/SecureBackend/apiResponse/Shipment';
import { UserInfo } from 'Contexts/AuthContext';
import { LaneObject } from 'shared-components/dataTypes';
import { ShipmentDetailsInterface } from 'TrackAndTrace/GenericShipmentDetails/lib';
import LocationIcon from './icons/addressIcon.png';
import { ChartDataToExport } from './GenericShipmentDetails';

const DATE_FORMAT = 'MMMM D, YYYY HH:mm';

type Props = {
    cargo: GenericCargo[],
    chartDataToExport: ChartDataToExport,
    userInfo: UserInfo,
    shipment: ShipmentDetailsInterface,
    lane: LaneObject,
    t: (key: string) => string,
    shipperName: string,
    forwarderName: string,
}

// eslint-disable-next-line no-unused-vars
const removeTrailingNulls = (data, tempIndex): any[] => {
    const mapped: (number | null)[] = data.map(it => it.d[tempIndex]);

    let hasNonNull = false;

    const nonNullLength = mapped
        .slice()
        .reverse()
        .filter(item => {
            if (item !== null) hasNonNull = true;
            return hasNonNull;
        })
        .length;

    return data.slice(0, nonNullLength - 1);
};

export const generatePdf = ({
    cargo,
    chartDataToExport,
    userInfo,
    shipment,
    lane,
    t,
    shipperName,
    forwarderName,
}: Props) => {
    const doc = new JSPDF('p', 'mm', 'a4');
    const docWidth = 210;
    const docHeight = 297;
    const padding = 10;
    const headerSize = 24;
    const defTextSize = 6;
    const defTexHeader = 5;
    const subHeaderSize = 13;
    const tableHeaderSize = 6;
    const tableCellSize = 6;
    const divisionCount = 6;
    const divisionLessCount = 4;
    const defaultColor = '#000000';
    const colBreaks = new Array(divisionCount)
        .fill(0).map((_, i) => ((docWidth - padding * 2) / divisionCount) * i + padding);
    const colBreaks4 = new Array(divisionLessCount)
        .fill(0).map((_, i) => ((docWidth - padding * 2) / divisionLessCount) * i + padding);

    let cursorPosition = padding;

    const addCursorOffset = (offset: number, expectedSize = 0) => {
        if (cursorPosition > (docHeight - padding) - expectedSize) {
            doc.addPage();
            cursorPosition = padding;
        } else {
            cursorPosition += offset;
        }
    };

    doc.setFont('helvetica', 'normal');

    const hLine = (start, y, end, color = '#B7B7B7', width = 0.05) => {
        doc.setDrawColor(color);
        doc.setLineWidth(width);
        doc.line(start, y, end, y);
        doc.setDrawColor(defaultColor);
    };
    // Header

    addCursorOffset(10);
    doc.setFontSize(headerSize);
    doc.text(`Shipment ${shipment?.externalId} Summary Report`, padding, cursorPosition);

    // Header Details
    addCursorOffset(10);
    doc.setFontSize(defTexHeader);
    doc.text('Report Generation Date and Time:', colBreaks[0], cursorPosition);
    doc.text('Generated By:', colBreaks[1], cursorPosition);
    doc.setFontSize(defTextSize);
    addCursorOffset(3);
    doc.text(moment.utc().format(DATE_FORMAT), colBreaks[0], cursorPosition);
    doc.text(userInfo?.name, colBreaks[1], cursorPosition);

    // Summary Title
    addCursorOffset(10);
    doc.setFontSize(subHeaderSize);
    doc.text('SUMMARY', padding, cursorPosition);
    hLine(padding, cursorPosition + 1, docWidth - padding);

    // Summary Details
    addCursorOffset(7);
    doc.setFontSize(defTexHeader);
    doc.text('Reference / PO Number', colBreaks[0], cursorPosition);
    doc.text('Shipment Status', colBreaks[1], cursorPosition);
    doc.text('Forwarder', colBreaks[2], cursorPosition);
    doc.text('Shipper', colBreaks[3], cursorPosition);
    doc.text('HAWB/MAWB', colBreaks[4], cursorPosition);
    doc.text('Lane Number', colBreaks[5], cursorPosition);
    addCursorOffset(3);
    doc.setFontSize(defTextSize);
    doc.text(shipment.customerReference, colBreaks[0], cursorPosition);
    doc.text(t(`SHIPMENT_STATUS.${shipment.status}`), colBreaks[1], cursorPosition);
    doc.text(forwarderName, colBreaks[2], cursorPosition);
    doc.text(shipperName, colBreaks[3], cursorPosition);
    doc.text(`${shipment.hawbNumber || 'N/A'}/${shipment.mawbNumber || 'N/A'}`, colBreaks[4], cursorPosition);
    doc.text(lane?.laneNumber || 'No Lane', colBreaks[5], cursorPosition);

    // Packaging Status Title
    addCursorOffset(10);
    doc.setFontSize(subHeaderSize);
    doc.text('PACKAGING STATUS', padding, cursorPosition);
    hLine(padding, cursorPosition + 1, docWidth - padding);

    // Packaging Status Details
    addCursorOffset(7);
    doc.setFontSize(defTextSize);
    const rowsCount = Math.ceil(cargo.length / divisionLessCount);

    cargo.map(cargo => ({
        code: cargo.packaging.serialNumber,
        status: cargo.temperatureCheckResult.excursionTimestamp ? 'EXCURSION' : 'OK',
    })).forEach((dummy, i) => {
        const col = i % divisionLessCount;
        const row = Math.floor(i / divisionLessCount);

        doc.setTextColor('#000000');
        doc.text(`${dummy.code} |`, colBreaks4[col], cursorPosition + (row * 3));
        if (dummy.status === 'EXCURSION') {
            doc.setTextColor('#ff0000');
        }
        doc.text(dummy.status, colBreaks4[col] + 12, cursorPosition + (row * 3));
        doc.setTextColor('#000000');
    });
    addCursorOffset(rowsCount * 3);

    // ORIGIN Title
    addCursorOffset(10);
    doc.setFontSize(subHeaderSize);
    doc.text('ORIGIN', padding, cursorPosition);
    hLine(padding, cursorPosition + 1, (docWidth - padding) / 2 - padding);

    // DESTINATION Title
    doc.setFontSize(subHeaderSize);
    doc.text('DESTINATION', (docWidth - padding) / 2, cursorPosition);
    hLine((docWidth - padding) / 2, cursorPosition + 1, docWidth - padding);

    addCursorOffset(7);
    // ORIGIN Details Headers
    const iconPadding = 4;

    doc.setFontSize(defTexHeader);
    doc.addImage(
        LocationIcon,
        'PNG',
        padding,
        cursorPosition - (iconPadding - 1) / 2,
        iconPadding - 1,
        iconPadding - 1,
    );
    doc.text(
        'Origin Location',
        padding + iconPadding,
        cursorPosition,
    );
    // doc.addImage(
    //     GroupIcon,
    //     'PNG',
    //     padding + (docWidth - padding) / 4,
    //     cursorPosition - (iconPadding - 1) / 2,
    //     iconPadding - 1,
    //     iconPadding - 1,
    // );
    // doc.text('Handover Point Contact(s)', padding + iconPadding + (docWidth - padding) / 4, cursorPosition);
    // DESTINATION Details Headers
    doc.setFontSize(defTexHeader);
    doc.addImage(
        LocationIcon,
        'PNG',
        (docWidth - padding) / 2,
        cursorPosition - (iconPadding - 1) / 2,
        iconPadding - 1,
        iconPadding - 1,
    );
    doc.text(
        'Destination Location',
        iconPadding + (docWidth - padding) / 2,
        cursorPosition,
    );
    // doc.addImage(
    //     GroupIcon,
    //     'PNG',
    //     (docWidth - padding) / 2 + padding + (docWidth - padding) / 4,
    //     cursorPosition - (iconPadding - 1) / 2,
    //     iconPadding - 1,
    //     iconPadding - 1,
    // );
    // doc.text(
    //     'Collection/Drop-off Point Contact(s)',
    //     padding + iconPadding + (docWidth - padding) / 4 + (docWidth - padding) / 2,
    //     cursorPosition,
    // );

    addCursorOffset(3);
    // ORIGIN AND DESTINATION Details Definitions
    doc.setFontSize(defTextSize);
    const maxTitleSizeForOD = ((docWidth - padding * 2) / 4) - iconPadding - padding * 2;
    const wrapTextHandoverPoint = doc.splitTextToSize(
        shipment.customerSpecificInformation.handoverPoint, maxTitleSizeForOD,
    );
    const wrapTextHandoverPointContacts = doc.splitTextToSize(
        'Meinrad Kleinschmitt meinrad.kleinschmitt@expres… +49 5945 789 546', maxTitleSizeForOD,
    );
    const wrapTextCollectionDropoff = doc.splitTextToSize(
        shipment.customerSpecificInformation.collectionDropoffPoint, maxTitleSizeForOD,
    );
    const wrapTextCollectionDropoffContacts = doc.splitTextToSize(
        'John Wang john.wang@heng-sho.com +352 654 5544 544', maxTitleSizeForOD,
    );

    doc.text(
        wrapTextHandoverPoint,
        padding + iconPadding,
        cursorPosition,
    );
    // doc.text(
    //     wrapTextHandoverPointContacts,
    //     padding + iconPadding + (docWidth - padding) / 4,
    //     cursorPosition,
    // );
    doc.text(
        wrapTextCollectionDropoff,
        iconPadding + (docWidth - padding) / 2,
        cursorPosition,
    );
    // doc.text(
    //     wrapTextCollectionDropoffContacts,
    //     padding + iconPadding + (docWidth - padding) / 4 + (docWidth - padding) / 2,
    //     cursorPosition,
    // );

    addCursorOffset(Math.max(...[
        wrapTextHandoverPoint,
        wrapTextHandoverPointContacts,
        wrapTextCollectionDropoff,
        wrapTextCollectionDropoffContacts,
    ].map((wrapText) => wrapText.length)) * 3 + 5);

    Object.keys(chartDataToExport).forEach((serialNumber) => {
        const chartData = chartDataToExport[serialNumber];
        const imageAspectRatio = chartData?.chartAspectRatio || 3.14;

        const imageWidth = docWidth - (padding * 2);
        const imageHeight = imageWidth / imageAspectRatio;
        const packaging = cargo.find((cargoItem) => cargoItem.packaging.serialNumber === serialNumber);
        const minTemp = packaging.temperatureCheckResult.temperatureMin;
        const maxTemp = packaging.temperatureCheckResult.temperatureMax;

        addCursorOffset(10);
        // Packaging Title
        doc.setFontSize(subHeaderSize);
        doc.text(`PACKAGING ${serialNumber} | MIN: ${minTemp}°C, MAX: ${maxTemp}°C`, padding, cursorPosition);
        hLine(padding, cursorPosition + 2, docWidth - padding);

        // PackagingPNG
        addCursorOffset(7, imageHeight);
        doc.addImage(chartData.base64, 'PNG', padding, cursorPosition, imageWidth, imageHeight);
        addCursorOffset(imageHeight + 5);

        const ambientIndex = chartData.labels.positions.indexOf('AMBIENT');
        const internalIndex = chartData
            .labels
            .positions
            .findIndex((it, i) => it === 'INTERNAL'
                && chartData.labels.dataTypes[i] === 'TEMPERATURE');
        const internalPredictedIndex = chartData
            .labels
            .positions
            .findIndex((it, i) => it === 'INTERNAL'
                && chartData.labels.dataTypes[i] === 'TEMPERATURE_PREDICTED');
        const latitudeIndex = chartData.labels.dataTypes.indexOf('LOCATION_LATITUDE');
        const longitudeIndex = chartData.labels.dataTypes.indexOf('LOCATION_LONGITUDE');

        // Latest data table
        const tablePadding = 30;
        const tableBreaks = new Array(4)
            .fill(0)
            .map((_, i) => tablePadding + ((docWidth - tablePadding * 2) / 4) * i);

        doc.setFontSize(tableHeaderSize);
        doc.setFont('helvetica', 'bold');
        doc.text('Measurement [UTC Date & Time]', tableBreaks[0], cursorPosition);
        doc.text('Internal Temperature [°C]', tableBreaks[1], cursorPosition);
        doc.text('Ambient Temperature [°C]', tableBreaks[2], cursorPosition);
        doc.text('Location [Latitude, Longitude]', tableBreaks[3], cursorPosition);
        hLine(tablePadding, cursorPosition + 2, docWidth - tablePadding, '#707070', 0.4);
        doc.setFont('helvetica', 'normal');
        // Fill table with data
        addCursorOffset(5);

        const trimmedTemp = removeTrailingNulls(chartData.latestTemp, internalIndex);

        trimmedTemp.forEach(({ t, d }) => {
            const tableData = [
                moment.utc(t).format(DATE_FORMAT),
                (d[internalIndex] || d[internalPredictedIndex])?.toString() || 'N/A',
                d[ambientIndex]?.toString() || 'N/A',
                d[latitudeIndex] ? `${d[latitudeIndex]}, ${d[longitudeIndex]}` : 'N/A',
            ];

            doc.setFontSize(tableCellSize);
            tableData.forEach((text, j) => {
                doc.text(text, tableBreaks[j], cursorPosition);
            });
            doc.setDrawColor('#737373');
            hLine(tablePadding, cursorPosition + 1, docWidth - tablePadding, '#707070');
            doc.setDrawColor('black');
            addCursorOffset(5);
        });
        addCursorOffset(2);

        // Adding a notice
        doc.setFontSize(tableCellSize);
        doc.text(
            'Please be aware that the timestamp for sensor data is in UTC (Universal Coordinated Time),'
            + ' not reflecting the local date/time of the origin or destination.\n'
            + 'The provided logger numbers correspond to the primary loggers utilized in the packaging.'
            + ' In the event that primary logger data is unavailable,'
            + ' data from secondary backup loggers will be displayed.',
            padding,
            cursorPosition,
        );
        addCursorOffset(7);
    });
    for (let i = 1; i <= doc.getNumberOfPages(); i++) {
        doc.setPage(i);

        hLine(padding, 5, docWidth - padding, '#393939');
        doc.setFontSize(8);
        doc.text(`Page ${i} of ${doc.getNumberOfPages()}`, docWidth - padding - 20, 4);
    }

    doc.save(`Shipment_${shipment.externalId}_report_${moment().format('DD_MMM_YYYY_hh.mm')} .pdf`);
};
