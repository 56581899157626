import React, { useState, ReactElement } from 'react';
import BreadcrumbsContext, {
    BreadcrumbsNamespace,
    KeyType,
    StoredDataType,
} from './BreadcrumbsContext';

type Props = {
    children: ReactElement
}

const BreadcrumbsProvider = ({ children }: Props) => {
    const [breadcrumbsNamespace, setBreadcrumbsNamespace] = useState<BreadcrumbsNamespace>({});

    const setBreadcrumbData = (key: KeyType, value: StoredDataType) => {
        setBreadcrumbsNamespace(prevState => ({
            ...prevState,
            [key]: value,
        }));
    };

    const getBreadcrumbData = (key: KeyType) => {
        return breadcrumbsNamespace[key] || '';
    };

    return (
        <BreadcrumbsContext.Provider
            value={{
                getBreadcrumbData,
                setBreadcrumbData,
            }}
        >
            {children}
        </BreadcrumbsContext.Provider>
    );
};

export default BreadcrumbsProvider;
