import React, {
    CSSProperties,
    useCallback,
    useState,
} from 'react';
import Button from '@mui/material/Button';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { QmApprovalInfo } from 'dataTypes/SecureBackend/processedData';
import ApprovalReviseModal from 'shared-components/ApprovalReviseModal';

const buttonStyle: CSSProperties = {
    textTransform: 'uppercase',
    width: '75px',
    height: '35px',
};

type Props = {
    qmApprovalStatusIcon: string,
    qmApproval: QmApprovalInfo,
    dataUpdateTrigger: (requiredDataUpdate: boolean) => void,
    evidenceId: number,
    orderNumber: string,
}

const QMApprovalReviseCell = ({
    qmApprovalStatusIcon,
    qmApproval,
    dataUpdateTrigger,
    evidenceId,
    orderNumber,
} : Props) => {
    const [openApprovalModal, setOpenApprovalModal] = useState(false);
    const { t } = useCustomTranslation();
    const handleClick = useCallback(() => setOpenApprovalModal(true), []);

    return (
        <div style={{ display: 'flex' }}>
            <img
                alt="Container Status"
                src={qmApprovalStatusIcon}
                style={{ width: '22px', marginRight: '6px' }}
            />
            <Button
                color="primary"
                variant="contained"
                onClick={handleClick}
                style={buttonStyle}
            >
                {t('PRODUCT_RELEASE.REVISE')}
            </Button>
            <ApprovalReviseModal
                open={openApprovalModal}
                setOpen={setOpenApprovalModal}
                shipmentNumber={orderNumber}
                productReleaseId={evidenceId}
                qmApproval={qmApproval}
                dataUpdateTrigger={dataUpdateTrigger}
            />
        </div>
    );
};

export default QMApprovalReviseCell;
