import React from 'react';
import Card from 'shared-components/Card';
import useCustomTranslation from 'hooks/useCustomTranslation';
import useStyles from './TemperatureReadout.style';

type Props = {
    approvalInfo: {
        icon: string,
        statusMessage: string,
    },
}

const TemperatureReadout = ({
    approvalInfo = {
        icon: '',
        statusMessage: '',
    },
} : Props) => {
    const classes = useStyles();
    const { t } = useCustomTranslation();

    return (
        <Card fullHeight>
            <div className={classes.title}>{t('PRODUCT_RELEASE.TEMPERATURE_READOUT')}</div>
            <div className={classes.container}>
                <div className={classes.flex}>
                    <img
                        key="locationIconTemperatureReadout"
                        className={classes.icon}
                        src={approvalInfo.icon}
                        alt="Icon Temperature Readout"
                    />
                </div>
                <div className={classes.statusMessage}>{approvalInfo.statusMessage}</div>
            </div>
        </Card>
    );
};

export default TemperatureReadout;
