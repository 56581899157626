import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    link: {
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        textDecoration: 'none !important',
        color: '#4C9BB5',
    },
}));

export default useStyles;
