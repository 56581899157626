import moment from 'moment';
import { Geolocation } from 'dataTypes/SecureBackend/apiResponse';

export interface TimeZoneInfo {
    gmtOffsetInMs: number,
    timeZoneStr: string,
}

export const initialValue = { gmtOffsetInMs: null, timeZoneStr: '' };

export const getGeolocationRequestLink = (geolocation: Geolocation, time: string = '0', utc: boolean) => {
    const { latitude: lat, longitude: lng } = geolocation;
    const epoch = moment(time).utc(utc).valueOf();

    return `?lat=${lat}&lng=${lng}&time=${epoch}`;
};

const oneHour = 3600;
const oneMinute = 60;

export const getTimeZoneStr = (gmtOffset: number) => {
    if (gmtOffset === 0) {
        return '00:00';
    }

    const gmtOffsetAbsolute = Math.abs(gmtOffset);
    const hours = Math.trunc(gmtOffsetAbsolute / oneHour);
    const minutes = (gmtOffsetAbsolute - hours * 3600) / oneMinute;

    const rate = gmtOffset < 0 ? '-' : '+';
    const hoursString = hours.toString().padStart(2, '0');
    const minutesString = minutes.toString().padStart(2, '0');

    return `${rate}${hoursString}:${minutesString}`;
};
