import React, {
    useEffect, useMemo,
    useState,
} from 'react';
import { ServiceProviderSite } from 'dataTypes/SecureBackend/apiResponse';
import Table, { useTableSideFilter } from 'shared-components/Table';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';

import useCustomTranslation from 'hooks/useCustomTranslation';
import useGetTranslationGroup from 'hooks/useGetTranslationGroup';
import { fetchTableData } from './lib';
import tableColumns from './tableColumns';
import { PageWithFilter } from '../Layout';

type Props = {
    showTitle?: boolean,
    rowLinkTemplate?: string,
}

type Response = { resultList: ServiceProviderSite[], totalElements: number }

const ServiceProviders = ({
    rowLinkTemplate = '',
} : Props) => {
    const { GetAll: getSites } = useSecureBackendEndpoints('service-provider-sites').requests;
    const labels = useGetTranslationGroup('SERVICE_PROVIDERS');
    const [sites, setSites] = useState([]);
    const [filteredSites, setFilteredSites] = useState([]);
    const { t } = useCustomTranslation();
    const [maskForHighlight, setMaskForHighlight] = useState('');

    useEffect(() => {
        (async () => {
            try {
                const { resultList = [] }: Response = (await getSites()).data;

                setSites(fetchTableData(resultList, labels));
            } catch (e) {
                global.console.log(e);
            }
        })();
    }, [labels]);

    const columns = useMemo(() => tableColumns(t, maskForHighlight)
        .filter(it => !it.hide), [t, maskForHighlight]);
    const {
        component: FilterComponent,
        selectedFilterOptions,
    } = useTableSideFilter({
        columns,
        data: sites,
        setFilteredData: setFilteredSites,
    });

    useEffect(() => {
        // Logic to compute maskForHighlight from selectedFilterOptions
        const newMaskForHighlight = `${(selectedFilterOptions?.serviceProviderName?.[0]?.toString()?.length > 2
            && selectedFilterOptions?.serviceProviderName?.[0])
        || (selectedFilterOptions?.name?.[0]?.toString()?.length > 2
            && selectedFilterOptions?.name?.[0])}`;

        setMaskForHighlight(newMaskForHighlight);
    }, [selectedFilterOptions]);

    return (
        <PageWithFilter>
            {FilterComponent}
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: '10px' }}>
                <Table
                    columns={columns}
                    data={filteredSites}
                    maskForHighlight={`${(selectedFilterOptions?.serviceProviderName?.[0]?.toString()?.length > 2
                        && selectedFilterOptions?.serviceProviderName?.[0])
                        || (selectedFilterOptions?.name?.[0]?.toString()?.length > 2
                        && selectedFilterOptions?.name?.[0])}`}
                    rowLinkTemplate={rowLinkTemplate}
                    tableMaxHeight="100%"
                    title={t('MENU_ITEMS.SERVICE_PROVIDERS')}
                />
            </div>
        </PageWithFilter>
    );
    /* return (
        <div className={classes.pageServiceProviders}>
            {
                showTitle && <TitleHeader>{t('MENU_ITEMS.SERVICE_PROVIDERS')}</TitleHeader>
            }
            <FilterComponent
                commonSearchFieldSample={commonSearchFieldSample}
                offerings={offerings}
                setCommonSearchFieldSample={setCommonSearchFieldSample}
                setOfferings={setOfferings}
            />
            <div className={classes.totalElements}>
                {`${totalElements} ${
                    totalElements > 1 ? t('SERVICE_PROVIDERS.SITES_FOUND') : t('SERVICE_PROVIDERS.SITE_FOUND')
                }`}
            </div>
            <Table
                columns={tableColumns(t).filter(it => !it.hide)}
                data={filteredSites}
                rowLinkTemplate={rowLinkTemplate}
                tableMaxHeight="80vh"
                classNames={{
                    tableContainerClassName: classes.paddedTable,
                }}
            />
        </div>
    ); */
};

export default ServiceProviders;
